<template>
  <div class="col p-0">
    <div
      slot="table-busy"
      class="text-center text-danger my-2">
      <coozzy-spinner
        v-if="loading" />
    </div>
    <div
      class="row">
      <b-modal
        :id="'editDevice'"
        ref="editDevice"
        :key="internalKey"
        no-close-on-backdrop
        :title="$t('message.onBoarding.buildings.objects.renovation.editDevice.title')"
        hide-footer>
        <div class="col">
          <device
            ref="object-device"
            :target="'object'"
            :from-handover="true"
            :handover-rooms="preSelectedRooms"
            :device="deviceToEdit"
            :hide-room-selection="checkRoomSelection"
            :expand-by-default="true"
            :owner-id="object?.ownerId"
            @device-update="deviceUpdated" />
        </div>
        <div class="col mt-2">
          <coozzy-button
            size="small"
            class="float-left mb-0 border"
            design="transparent"
            @click="hideEditDeviceModal">
            {{ $t('message.generic.cancel') }}
          </coozzy-button>
          <coozzy-button
            design="green"
            class="float-right ml-3"
            @click="saveDevice()">
            {{ $t('message.generic.form.save') }}
          </coozzy-button>
        </div>
      </b-modal>
      <b-modal
        v-if="selectedRoomModal"
        :id="'addDevice'"
        ref="addDevice"
        no-close-on-backdrop
        :title="selectedRoomModal.name"
        hide-footer
        @hide="hideAddDeviceModal">
        <div class="row mx-1">
          <div class="col-12">
            <div class="row">
              <div
                v-if="object.category !== 'COMMERCIAL'"
                class="col-3 offset-9">
                <label for="room">{{ $t('message.onBoarding.buildings.objects.type.title') }}</label>
                <coozzy-form-select
                  id="room"
                  v-model="roomAvailable"
                  class="select-entries float-right"
                  name="room">
                  <option value="">
                    -
                  </option>
                  <option
                    v-for="(room, indexOption) in availableRooms"
                    :key="indexOption"
                    :value="room">
                    {{ room.value }}
                  </option>
                </coozzy-form-select>
              </div>
            </div>
          </div>
          <div
            v-if="selectedRoomModal"
            class="col-md-5">
            <h3>
              {{ $t('message.processHandover.rooms.existingDevices') }}
              <coozzy-info-circle-icon
                v-b-tooltip.hover.html="$t('message.processHandover.rooms.existingDevicesTooltip')"
                class="ml-2 mb-2 tooltip-message" />
            </h3>
            <p>
              {{ $t('message.processHandover.rooms.existingDevicesWithinRoom') }}
            </p>
            <div class="device-list">
              <div
                v-for="device in selectedRoomModal.devices"
                :key="(device.id || device.internalId) + '-' + device.type"
                :class="['list-group-item device-list']">
                {{ device.value }}
                <coozzy-button
                  design="transparent"
                  class="p-0 ml-1 mb-2 float-right"
                  @click="showDeleteConfirmation(device, $event)">
                  <coozzy-delete-icon />
                </coozzy-button>
                <coozzy-button
                  design="transparent"
                  class="p-0 mb-2 float-right ml-2"
                  @click="editDevice(device, selectedRoomModal)">
                  <coozzy-edit-icon />
                </coozzy-button>
              </div>
            </div>
            <coozzy-button
              class="col-sm-12 col-lg-4 col-md-4 float-md-right mt-3"
              design="prop-green"
              @click="addDevice($event)">
              {{ $t('message.onBoarding.devices.addDevices') }}
            </coozzy-button>
          </div>
          <div class="col-md-5 offset-2">
            <h3>
              {{ $t('message.processHandover.rooms.suggestionsDevices') }}
              <coozzy-info-circle-icon
                v-b-tooltip.hover.html="$t('message.processHandover.rooms.suggestionsDevicesTooltip')"
                class="ml-2 mb-2 tooltip-message" />
            </h3>
            <p>
              {{ $t('message.processHandover.rooms.suggestionsDevicesDescription') }}
            </p>
            <coozzy-button
              class="float-right modal-add-all"
              design="prop-green"
              :disabled="!selectedRoomModal"
              @click="addAllDevices(roomAvailable, $event)">
              {{ $t('message.onBoarding.devices.addAll') }}
            </coozzy-button>
            <div
              v-if="roomAvailable"
              class="device-list">
              <div
                v-for="device in roomAvailable.devices"
                :key="device.id || device.internalId"
                :class="['list-group-item', 'with-add-button']">
                <span>{{ device.value }}</span>
                <coozzy-button
                  design="prop-green"
                  class="p-0 ml-1 float-right px-2 add-to-available"
                  @click="addDeviceMethod(device)">
                  +
                </coozzy-button>
              </div>
            </div>
          </div>
        </div>
        <div class="col mt-2">
          <coozzy-button
            size="small"
            class="float-left mb-0 border"
            design="transparent"
            @click="$bvModal.hide('addDevice')">
            {{ $t('message.generic.cancel') }}
          </coozzy-button>
          <coozzy-button
            design="green"
            class="float-right ml-3"
            @click="saveEditDeviceOfRoom()">
            {{ $t('message.processHandover.devices.finish') }}
          </coozzy-button>
        </div>
      </b-modal>
      <div
        v-for="(room, index) in selectedRooms"
        :id="room.id"
        :key="room.id"
        class="room-container col-md-5 mr-auto"
        @click="selectSelectedRoom(room)">
        <coozzy-card
          class="list-group">
          <div
            @click="toggleSelectedRoomCollapse(index)">
            {{ room.name }}
            <coozzy-button
              class="float-right center-add-all"
              design="default"
              @click="openAddDeviceModal(room, $event)">
              {{ $t('message.onBoarding.devices.addDevices') }}
            </coozzy-button>
          </div>
          <div
            v-if="!collapsedSelectedRooms[index]"
            class="device-list mt-2">
            <div
              v-for="device in room.devices"
              :key="(device.id || device.internalId) + '-' + device.type"
              :class="['list-group-item']">
              {{ device.value }}
              <coozzy-button
                design="transparent"
                class="p-0 ml-1 mb-2 float-right"
                @click="showDeleteConfirmation(device, $event, room)">
                <coozzy-delete-icon />
              </coozzy-button>
              <coozzy-button
                design="transparent"
                class="p-0 mb-2 float-right ml-2"
                @click="editDevice(device, room)">
                <coozzy-edit-icon />
              </coozzy-button>
            </div>
          </div>
        </coozzy-card>
      </div>
      <div class="room-container col-md-5 mr-auto">
        <coozzy-card
          class="list-group">
          <div
            @click="toggleUnassignedDeviceCollapseComputed = !toggleUnassignedDeviceCollapseComputed">
            {{ $t('message.processHandover.devices.devicesWithoutRoom') }}
            <coozzy-button
              class="float-right center-add-all"
              design="default"
              @click="addDevice($event)">
              {{ $t('message.onBoarding.devices.addDevices') }}
            </coozzy-button>
          </div>
          <div
            v-if="!toggleUnassignedDeviceCollapseComputed"
            class="device-list mt-2">
            <div
              v-for="device in sortedDevicesWithoutRooms"
              :key="(device.id || device.internalId) + '-' + device.type"
              :class="['list-group-item']">
              {{ device.value }}
              <coozzy-button
                design="transparent"
                class="p-0 ml-1 mb-2 float-right"
                @click="showDeleteConfirmation(device, $event)">
                <coozzy-delete-icon />
              </coozzy-button>
              <coozzy-button
                design="transparent"
                class="p-0 mb-2 float-right ml-2"
                @click="editDevice(device, null)">
                <coozzy-edit-icon />
              </coozzy-button>
            </div>
          </div>
        </coozzy-card>
      </div>
    </div>
    <b-modal
      :id="'confirmationModalDelete_' + object.id"
      :ref="'confirmationModalDelete_' + object.id"
      no-close-on-backdrop
      hide-footer
      :title="$t('message.processHandover.confirmationDeletion.title')">
      <template>
        <div class="col p-0">
          <p>{{ $t('message.processHandover.confirmationDeletion.body') }}</p>
        </div>
        <div class="col p-0">
          <coozzy-button
            size="small"
            class="mb-0 border"
            design="transparent"
            @click="$bvModal.hide('confirmationModalDelete_' + object.id)">
            {{ $t('message.generic.cancel') }}
          </coozzy-button>
          <coozzy-button
            size="small"
            class="float-right mb-0"
            design="green"
            @click="selectedRoomModal ? deleteDeviceModal() : deleteDevice()">
            {{ $t('message.generic.delete') }}
          </coozzy-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>

import { formatting } from '@/mixins/formatting'
import { deviceUtils } from '@/mixins/deviceUtils'
import { obj } from '@/mixins/object'
import { onboarding } from '@/mixins/onboarding'
import { routeChecks } from '@/mixins/routeChecks'
import { algolia } from '@/mixins/algolia'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner'
import { mapActions, mapGetters } from 'vuex'
import CoozzyEditIcon from '@/framework/components/icons/CoozzyEditIcon.vue'
import CoozzyButton from '@/framework/components/button/CoozzyButton.vue'
import Device from '@/building/tabs/components/Device.vue'
import CoozzyDeleteIcon from '@/framework/components/icons/CoozzyDeleteIcon.vue'
import CoozzyCard from '@/framework/components/card/CoozzyCard.vue'
import { helper } from '@/types/helper'
import CoozzyFormSelect from '@/framework/components/form/select/CoozzyFormSelect.vue'
import CoozzyInfoCircleIcon from '@/framework/components/icons/CoozzyInfoCircleIcon.vue'

export default {
  name: 'HandoverDevicesStep',
  components: {
    CoozzyInfoCircleIcon,
    CoozzyFormSelect,
    CoozzyCard,
    CoozzyDeleteIcon,
    Device,
    CoozzyButton,
    CoozzyEditIcon,
    CoozzySpinner
  },
  mixins: [formatting, obj, onboarding, routeChecks, algolia, deviceUtils],
  props: {
    object: {
      type: Object,
      required: true
    },
    preSelectedRooms: {
      type: Array,
      default: () => []
    },
    devices: {
      type: Array,
      default: () => []
    },
    devicesDeleted: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      deviceToEditInit: null,
      roomAvailable: null,
      loading: true,
      internalKey: 0,
      availableRooms: [],
      selectedRooms: [],
      deletedDevices: [],
      selectedEntries: [],
      filteredObjects: [],
      collapsedRooms: [],
      collapsedSelectedRooms: [],
      collapsedUnassignedDevices: false,
      selectedDevice: null,
      usedContacts: [],
      deviceToEdit: null,
      selectedRoom: null,
      selectedRoomModal: null,
      selectedEditedDevice: null,
      devicesWithoutRooms: [],
      mappingKeys: [
        { word: 'Küche', mapping: 'kitchen' },
        { word: 'cuisine', mapping: 'kitchen' },
        { word: 'Bad', mapping: 'wetRoom' },
        { word: 'Badezimmer', mapping: 'wetRoom' },
        { word: 'Nasszelle', mapping: 'wetRoom' },
        { word: 'Dusche', mapping: 'wetRoom' },
        { word: 'WC', mapping: 'wetRoom' },
        { word: 'Wohnzimmer', mapping: 'room' },
        { word: 'Stube', mapping: 'room' },
        { word: 'Innenraum', mapping: 'room' },
        { word: 'Zimmer', mapping: 'room' },
        { word: 'Schlafzimmer', mapping: 'room' },
        { word: 'Kinderzimmer', mapping: 'room' },
        { word: 'Umkleidezimmer', mapping: 'room' },
        { word: 'Spielzimmer', mapping: 'room' },
        { word: 'Raumlichkeit', mapping: 'room' },
        { word: 'Korridor', mapping: 'corridor_entrance' },
        { word: 'Gang', mapping: 'corridor_entrance' },
        { word: 'Durchgang', mapping: 'corridor_entrance' },
        { word: 'Flur', mapping: 'corridor_entrance' },
        { word: 'Eingangsbereich', mapping: 'corridor_entrance' },
        { word: 'Eingang', mapping: 'corridor_entrance' },
        { word: 'Balkon', mapping: 'balcony_seatingArea' },
        { word: 'Sitzplatz', mapping: 'balcony_seatingArea' },
        { word: 'Terrasse', mapping: 'balcony_seatingArea' },
        { word: 'Veranda', mapping: 'balcony_seatingArea' },
        { word: 'Loggia', mapping: 'balcony_seatingArea' },
        { word: 'Keller', mapping: 'cellar' },
        { word: 'Tiefgeschoss', mapping: 'cellar' },
        { word: 'Souterrain', mapping: 'cellar' },
        { word: 'Abstellraum', mapping: 'reduit_storage' },
        { word: 'Besenkammer', mapping: 'reduit_storage' },
        { word: 'Putzkammer', mapping: 'reduit_storage' },
        { word: 'Putzraum', mapping: 'reduit_storage' },
        { word: 'Stauraum', mapping: 'reduit_storage' },
        { word: 'Abstellkammer', mapping: 'reduit_storage' },
        { word: 'Cucina', mapping: 'kitchen' },
        { word: 'Bagno', mapping: 'wetRoom' },
        { word: 'Camera umida', mapping: 'wetRoom' },
        { word: 'Doccia', mapping: 'wetRoom' },
        { word: 'Soggiorno', mapping: 'room' },
        { word: 'Salotto', mapping: 'room' },
        { word: 'Interno', mapping: 'room' },
        { word: 'La camera', mapping: 'room' },
        { word: 'Camera da letto', mapping: 'room' },
        { word: 'Camera dei bambini', mapping: 'room' },
        { word: 'Spogliatoio', mapping: 'room' },
        { word: 'Sala giochi', mapping: 'room' },
        { word: 'Spazio', mapping: 'room' },
        { word: 'Corridoio', mapping: 'corridor_entrance' },
        { word: 'Ingranaggio', mapping: 'corridor_entrance' },
        { word: 'Passaggio', mapping: 'corridor_entrance' },
        { word: "Area d'ingresso", mapping: 'corridor_entrance' },
        { word: 'Ingresso', mapping: 'corridor_entrance' },
        { word: 'Balcone', mapping: 'balcony_seatingArea' },
        { word: 'Sedile', mapping: 'balcony_seatingArea' },
        { word: 'Terrazza', mapping: 'balcony_seatingArea' },
        { word: 'Cantina', mapping: 'cellar' },
        { word: 'Piano interrato', mapping: 'cellar' },
        { word: 'Seminterrato', mapping: 'cellar' },
        { word: 'Magazzino', mapping: 'reduit_storage' },
        { word: 'Armadio per le scope', mapping: 'reduit_storage' },
        { word: 'Stanza delle pulizie', mapping: 'reduit_storage' },
        { word: 'Locale per le pulizie', mapping: 'reduit_storage' },
        { word: 'Ripostiglio', mapping: 'reduit_storage' },
        { word: 'Magazzino', mapping: 'reduit_storage' }
      ]
    }
  },
  computed: {
    sortedDevicesWithoutRooms() {
      return this.devicesWithoutRooms.slice().sort((a, b) => {
        if (a.type === 'DEVICE_TYPE_LOCKING_KEY') return 1
        if (b.type === 'DEVICE_TYPE_LOCKING_KEY') return -1
        return 0
      })
    },
    toggleUnassignedDeviceCollapseComputed: {
      get() {
        return this.collapsedUnassignedDevices
      },
      set(value) {
        this.collapsedUnassignedDevices = value
      }
    },
    employeeContacts() {
      let empContacts = this.contacts.filter(c => c.types.includes('EMPLOYEE'))
      if (this.getEmployees().length > 0) {
        empContacts = empContacts.filter(contact => {
          const matchingEmployee = this.getEmployees()
            .filter(employee => !employee.blocked)
            .find(employee => employee?.email?.toLowerCase()?.trim() === contact?.email?.toLowerCase()?.trim())

          return matchingEmployee !== undefined && matchingEmployee !== null
        })
      }
      return empContacts
    },
    checkRoomSelection() {
      if (this.deviceToEdit) {
        return this.devicesWithoutRooms.some(d => this.deviceToEdit.internalId && d.internalId ? d.internalId === this.deviceToEdit.internalId : d.id === this.deviceToEdit.id)
      }
      return false
    }
  },
  watch: {
    deletedDevices(newVal) {
      this.$emit('deleted-devices', newVal, this.object)
    },
    selectedRooms(newVal) {
      this.$emit('selected-rooms', newVal, this.object, 'device')
    },
    devicesWithoutRooms(newVal) {
      this.$emit('devices-without-rooms', newVal, this.object)
    },
    devices(newVal, oldVal) {
      if (JSON.stringify(newVal) === JSON.stringify(oldVal)) {
        return
      }
      this.checkDevices()
    },
    devicesDeleted(newVal, oldVal) {
      if (newVal.length === 0 && oldVal.length > 0) {
        this.deletedDevices = []
      }
    },
    preSelectedRooms: {
      handler(newVal, oldVal) {
        if (JSON.stringify(newVal) === JSON.stringify(this.selectedRooms) || JSON.stringify(newVal) === JSON.stringify(oldVal)) {
          return
        }
        this.selectedRooms = []
        newVal.forEach(room => {
          room.devices = []
          const selectedRoom = this.availableRooms.find(r => r.id === room.id)
          if (selectedRoom) {
            this.selectedRooms.push(selectedRoom)
            this.availableRooms = this.availableRooms.filter(r => r.id !== room.id)
            this.selectedRooms = this.selectedRooms.filter(r => r.id.startsWith('room_'))
          } else {
            this.selectedRooms.push({ ...room })
            this.selectedRooms = this.selectedRooms.filter(r => typeof room.id === 'string' && r.id.startsWith('room_'))
          }
          this.checkDevices()
        })
        // remove duplicates
        this.selectedRooms = this.selectedRooms.filter((room, index, self) =>
            index === self.findIndex((t) => (
              t.id === room.id
            ))
        )
      },
      deep: true
    }
  },
  mounted() {
    if (JSON.stringify(this.preSelectedRooms) !== JSON.stringify(this.selectedRooms)) {
      this.preSelectedRooms.forEach(room => {
        room.devices = []
        const selectedRoom = this.availableRooms.find(r => r.id === room.id)
        if (selectedRoom) {
          this.selectedRooms.push(selectedRoom)
          this.availableRooms = this.availableRooms.filter(r => r.id !== room.id)
          this.selectedRooms = this.selectedRooms.filter(r => r.id.startsWith('room_'))
        } else {
          this.selectedRooms.push({ ...room })
          this.selectedRooms = this.selectedRooms.filter(r => typeof room.id === 'string' && r.id.startsWith('room_'))
        }
        this.checkDevices()
      })
    } else {
      this.checkDevices()
    }
    this.loadAvailableRooms()
  },
  methods: {
    ...mapActions('employee', ['loadEmployees']),
    ...mapGetters('employee', ['getEmployees']),
    openAddDeviceModal(room, event) {
      event.stopPropagation()
      this.selectedRoomModal = JSON.parse(JSON.stringify(room))
      console.log(room)
      if (this.object.category !== 'COMMERCIAL') {
        // do something
        console.log('ddd')
        this.roomAvailable = this.mappingKeys.find(k => k.word.toLowerCase().includes(room.name.toLowerCase()) || room.name.toLowerCase().includes(k.word.toLowerCase())) ? this.availableRooms.find(w => w.name === this.mappingKeys.find(k => k.word.toLowerCase().includes(room.name.toLowerCase()) || room.name.toLowerCase().includes(k.word.toLowerCase())).mapping) : null
      } else {
        this.roomAvailable = this.availableRooms.find(w => w.name === 'commercial')
      }
      this.$nextTick(() => {
        this.$refs.addDevice.show()
      })
    },
    hideAddDeviceModal() {
      this.$refs.addDevice.hide()
      this.selectedRoomModal = null
      this.roomAvailable = null
    },
    checkDevices() {
      this.devicesWithoutRooms = []
      if (this.devices.length > 0) {
        this.devices.forEach(device => {
          const room = this.selectedRooms.find(r => r.id === device.roomId)
          device.value = device.type === '' || device.type === null || device.type === 'DEVICE_TYPE_UNDEFINED' ? '-' : this.$t('message.onBoarding.devices.typeValues.' + device.type)
          // if room.devices already contains the device, do nothing
          if (room && !room.devices.find(d => (d.internalId && d.internalId === device.internalId) || d.id === device.id)) {
            room.devices.push(device)
          } else {
            // add the device to the unassigned devices card but only if id is not already in it
            if (!device.roomId && !this.devicesWithoutRooms.find(d => d.id === device.id)) {
              this.devicesWithoutRooms.push(device)
            }
          }
        })
      }
      // check if there is a device without roomId and has type DEVICE_TYPE_OTHER_INTERIOR2_LETTER_BOX
      if (!this.devicesWithoutRooms.find(x => x.type === 'DEVICE_TYPE_OTHER_INTERIOR2_LETTER_BOX' && x.referenceId === this.object.id)) {
        if (this.object.category === 'APARTMENT' || this.object.category === 'HOUSE' || this.object.category === 'COMMERCIAL') {
          this.devicesWithoutRooms.push({
            internalId: Date.now() + '_DEVICE_TYPE_OTHER_INTERIOR2_LETTER_BOX',
            ownerId: this.object.ownerId,
            numericId: 99999,
            brand: '',
            referenceId: this.object.id,
            installation: null,
            uninstallation: null,
            measureUntil: null,
            measure: 'DEVICE_MEASURE_UNDEFINED',
            type: 'DEVICE_TYPE_OTHER_INTERIOR2_LETTER_BOX',
            system: undefined,
            facilityPlant: undefined,
            bkpH: undefined,
            lifeSpan: -1,
            amount: -1,
            value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_LOCKING_KEY'),
            acquisitionCosts: -1,
            unit: 'DEVICE_UNIT_UNDEFINED',
            cataloguePrice: -1,
            maintenance: {
              contact: {
                userReference: '',
                accountId: -1
              },
              costs: {
                currency: 'CHF',
                interval: 'UNDEFINED_INTERVAL'
              },
              lastMaintenance: null,
              nextMaintenance: null,
              subscriptionStart: null,
              subscriptionEnd: null,
              note: ''
            },
            inspection: {
              contact: {
                userReference: '',
                accountId: -1
              },
              costs: {
                currency: 'CHF',
                interval: 'UNDEFINED_INTERVAL'
              },
              lastOfficialControl: null,
              nextOfficialControl: null,
              subscriptionStart: null,
              subscriptionEnd: null,
              note: ''
            },
            revision: {
              contact: {
                userReference: '',
                accountId: -1
              },
              costs: {
                currency: 'CHF',
                interval: 'UNDEFINED_INTERVAL'
              },
              lastOfficialRevision: null,
              nextOfficialRevision: null,
              subscriptionStart: null,
              subscriptionEnd: null,
              note: ''
            },
            condition: 'DEVICE_CONDITION_NORMAL_WEAR',
            note: '',
            documentIds: [],
            images: [],
            isNewItem: true,
            lockingKey: {
              combinedKeys: false,
              combinedKeysNote: '',
              description: this.$t('message.processHandover.devices.letterbox'),
              lockingPlanNumber: ''
            }
          })
        }
      }
    },
    editDevice(device, room) {
      if (room) {
        device.roomId = room.id
      }
      this.deviceToEdit = JSON.parse(JSON.stringify(device))
      this.deviceToEditInit = JSON.parse(JSON.stringify(device))
      this.internalKey++
      this.selectedEditedDevice = device
      this.$nextTick(() => {
        this.$refs.editDevice.show()
      })
    },
    hideEditDeviceModal() {
      this.$refs.editDevice.hide()
      this.deviceToEdit = null
    },
    deviceUpdated(device) {
      device.edited = true
      this.selectedEditedDevice = JSON.parse(JSON.stringify(device))
    },
    saveDevice() {
      if (!this.selectedEditedDevice) {
        this.hideEditDeviceModal()
        return
      }
      if (this.selectedEditedDevice.roomId) {
        if (this.selectedRoomModal) {
          const deviceIndex = this.selectedRoomModal.devices.findIndex(d => d.internalId && this.selectedEditedDevice.internalId ? d.internalId === this.selectedEditedDevice.internalId : d.id === this.selectedEditedDevice.id)
          if (deviceIndex > -1) {
            this.$set(this.selectedRoomModal.devices, deviceIndex, JSON.parse(JSON.stringify(this.selectedEditedDevice)))
          }
          this.$nextTick(() => {
            this.selectedRoomModal.devices[deviceIndex].value = this.$t('message.onBoarding.devices.typeValues.' + this.selectedRoomModal.devices[deviceIndex].type)
          })
          const newRoomId = this.selectedEditedDevice.roomId
          const oldRoomId = this.deviceToEditInit.roomId
          if (oldRoomId !== newRoomId) {
            // Remove device from the old room
            const oldRoomIndex = this.selectedRooms.findIndex(r => r.id === oldRoomId)
            if (oldRoomIndex > -1) {
              this.selectedRooms[oldRoomIndex].devices = this.selectedRooms[oldRoomIndex].devices.filter(d => d.id !== this.selectedEditedDevice.id)
              this.selectedRoomModal.devices = this.selectedRoomModal.devices.filter(d => d.id !== this.selectedEditedDevice.id)
            }

            // Add device to the new room
            const newRoomIndex = this.selectedRooms.findIndex(r => r.id === newRoomId)
            if (newRoomIndex > -1) {
              this.selectedRooms[newRoomIndex].devices.push(this.selectedEditedDevice)
            } else {
              // If the new room is not in the selectedRooms, add it
              const newRoom = this.availableRooms.find(r => r.id === newRoomId)
              if (newRoom) {
                newRoom.devices = [this.selectedEditedDevice]
                this.selectedRooms.push(newRoom)
              }
            }
          }
        } else {
          const roomIndex = this.selectedRooms.findIndex(r => r.devices.some(x => x.internalId && this.selectedEditedDevice.internalId ? x.internalId === this.selectedEditedDevice.internalId : x.id === this.selectedEditedDevice.id))
          if (roomIndex > -1) {
            const deviceIndex = this.selectedRooms[roomIndex].devices.findIndex(d => d.internalId && this.selectedEditedDevice.internalId ? d.internalId === this.selectedEditedDevice.internalId : d.id === this.selectedEditedDevice.id)
            if (deviceIndex > -1) {
              this.$set(this.selectedRooms[roomIndex].devices, deviceIndex, JSON.parse(JSON.stringify(this.selectedEditedDevice)))
              this.$nextTick(() => {
                this.selectedRooms[roomIndex].devices[deviceIndex].value = this.$t('message.onBoarding.devices.typeValues.' + this.selectedRooms[roomIndex].devices[deviceIndex].type)
              })
            }
          }
          const newRoomId = this.selectedEditedDevice.roomId
          const oldRoomId = this.deviceToEditInit.roomId
          if (oldRoomId !== newRoomId) {
            // Remove device from the old room
            const oldRoomIndex = this.selectedRooms.findIndex(r => r.id === oldRoomId)
            if (oldRoomIndex > -1) {
              this.selectedRooms[oldRoomIndex].devices = this.selectedRooms[oldRoomIndex].devices.filter(d => d.id !== this.selectedEditedDevice.id)
            }

            // Add device to the new room
            const newRoomIndex = this.selectedRooms.findIndex(r => r.id === newRoomId)
            if (newRoomIndex > -1) {
              this.selectedRooms[newRoomIndex].devices.push(this.selectedEditedDevice)
            } else {
              // If the new room is not in the selectedRooms, add it
              const newRoom = this.availableRooms.find(r => r.id === newRoomId)
              if (newRoom) {
                newRoom.devices = [this.selectedEditedDevice]
                this.selectedRooms.push(newRoom)
              }
            }
          }
        }
      } else {
        const deviceIndex = this.devicesWithoutRooms.findIndex(d => d.internalId && this.selectedEditedDevice.internalId ? d.internalId === this.selectedEditedDevice.internalId : d.id === this.selectedEditedDevice.id)
        if (deviceIndex > -1) {
          this.$set(this.devicesWithoutRooms, deviceIndex, JSON.parse(JSON.stringify(this.selectedEditedDevice)))
          this.$emit('devices-without-rooms', this.devicesWithoutRooms, this.object)
          this.$nextTick(() => {
            this.devicesWithoutRooms[deviceIndex].value = this.$t('message.onBoarding.devices.typeValues.' + this.devicesWithoutRooms[deviceIndex].type)
          })
        }
      }
      this.selectedEditedDevice = null
      this.$refs.editDevice.hide()
    },
    addDevice(event) {
      event.stopPropagation()
      const newDevice = JSON.parse(JSON.stringify(helper.emptyDevice))
      newDevice.internalId = Date.now()
      newDevice.ownerId = this.object.ownerId
      newDevice.referenceId = this.object.id
      newDevice.value = this.$t('message.onBoarding.devices.newDevice')
      if (this.selectedRoomModal) {
        this.selectedRoomModal.devices.push(newDevice)
      } else {
        this.devicesWithoutRooms.push(newDevice)
        this.editDevice(newDevice, null)
      }
      this.clearSelection()
    },
    deleteDevice() {
      this.deletedDevices.push(this.selectedDevice.id || this.selectedDevice.internalId)
      const selectedRoom = this.selectedRooms.find(r => (r.id && r.id === this.selectedRoom?.id) || (r.internalId && r.internalId === this.selectedRoom?.internalId))
      if (selectedRoom) {
        // selectedRoom.devices = selectedRoom.devices.filter(d => (d.internalId && this.selectedDevice.internalId && d.internalId !== this.selectedDevice.internalId) || (d.id && this.selectedDevice.id && d.id !== this.selectedDevice.id))
        selectedRoom.devices = selectedRoom.devices.filter(d => {
          return (d.internalId && d.internalId !== this.selectedDevice.internalId) || (d.id && d.id !== this.selectedDevice.id)
        })
      } else {
        this.devicesWithoutRooms = this.devicesWithoutRooms.filter(d => (d.internalId && this.selectedDevice.internalId && d.internalId !== this.selectedDevice.internalId) || d.id !== this.selectedDevice.id)
      }
      this.selectedRoom = null
      this.$bvModal.hide('confirmationModalDelete_' + this.object.id)
      this.clearSelection()
    },
    deleteDeviceModal() {
      this.selectedRoomModal.devices = this.selectedRoomModal.devices.filter(d => d.internalId !== this.selectedDevice.internalId || d.id !== this.selectedDevice.id)
      this.$bvModal.hide('confirmationModalDelete_' + this.object.id)
      this.clearSelection()
    },
    saveEditDeviceOfRoom() {
      const roomIndex = this.selectedRooms.findIndex(r => r.id === this.selectedRoomModal.id)
      if (roomIndex > -1) {
        this.selectedRooms[roomIndex].devices = []
        this.selectedRooms[roomIndex].devices = this.selectedRooms[roomIndex].devices.concat(this.selectedRoomModal.devices)
      }
      this.$emit('selected-rooms', this.selectedRooms, this.object, 'device')
      this.$refs.addDevice.hide()
      this.selectedRoomModal = null
      this.clearSelection()
    },
    clearSelection() {
      this.selectedDevice = null
    },
    toggleCollapse(index) {
      this.$set(this.collapsedRooms, index, !this.collapsedRooms[index])
    },
    loadAvailableRooms() {
      this.loading = true
      if (this.object.category === 'APARTMENT' || this.object.category === 'HOUSE') {
      this.availableRooms = [{
        name: 'kitchen',
        value: this.$t('message.processHandover.rooms.kitchen'),
        devices: [
          { internalId: 1, type: 'DEVICE_TYPE_FLOORING', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_FLOORING') },
          { internalId: 2, type: 'DEVICE_TYPE_FLOORING_SKIRTING', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_FLOORING_SKIRTING') },
          { internalId: 3, type: 'DEVICE_TYPE_WALL_COVERING', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_WALL_COVERING') },
          { internalId: 4, type: 'DEVICE_TYPE_CEILING_REGULAR', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_CEILING_REGULAR') },
          { internalId: 5, type: 'DEVICE_TYPE_CARPENTER_DOOR', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_CARPENTER_DOOR') },
          { internalId: 6, type: 'DEVICE_TYPE_LOCKING_KEY', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_LOCKING_KEY') },
          { internalId: 7, type: 'DEVICE_TYPE_WINDOW', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_WINDOW') },
          { internalId: 8, type: 'DEVICE_TYPE_WINDOW_WINDOW_FRAME', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_WINDOW_WINDOW_FRAME') },
          { internalId: 9, type: 'DEVICE_TYPE_WINDOW_WINDOW_SILL', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_WINDOW_WINDOW_SILL') },
          { internalId: 10, type: 'DEVICE_TYPE_OUTER_FINISHES', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_OUTER_FINISHES') },
          { internalId: 11, type: 'DEVICE_TYPE_OTHER_INTERIOR2_CRANK', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_OTHER_INTERIOR2_CRANK') },
          { internalId: 12, type: 'DEVICE_TYPE_HEAT_GENERATION_HEATING_ELEMENT', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_HEAT_GENERATION_HEATING_ELEMENT') },
          { internalId: 13, type: 'DEVICE_TYPE_LOW_CURRENT_INSTALLATION_SWITCH', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_LOW_CURRENT_INSTALLATION_SWITCH') },
          { internalId: 14, type: 'DEVICE_TYPE_OTHER_ELECTRICITY_SOCKET', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_OTHER_ELECTRICITY_SOCKET') },
          { internalId: 15, type: 'DEVICE_TYPE_LIGHTS', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_LIGHTS') },
          { internalId: 16, type: 'DEVICE_TYPE_KITCHEN_EQUIPMENT_TOP_CABINET', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_KITCHEN_EQUIPMENT_TOP_CABINET') },
          { internalId: 17, type: 'DEVICE_TYPE_KITCHEN_EQUIPMENT_UNDER_CABINET', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_KITCHEN_EQUIPMENT_UNDER_CABINET') },
          { internalId: 18, type: 'DEVICE_TYPE_KITCHEN_EQUIPMENT_DRAWER', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_KITCHEN_EQUIPMENT_DRAWER') },
          { internalId: 19, type: 'DEVICE_TYPE_KITCHEN_EQUIPMENT_WASTE', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_KITCHEN_EQUIPMENT_WASTE') },
          { internalId: 20, type: 'DEVICE_TYPE_KITCHEN_EQUIPMENT_KITCHEN_COVER', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_KITCHEN_EQUIPMENT_KITCHEN_COVER') },
          { internalId: 21, type: 'DEVICE_TYPE_KITCHEN_EQUIPMENT_FLUSHING_BASIN', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_KITCHEN_EQUIPMENT_FLUSHING_BASIN') },
          { internalId: 22, type: 'DEVICE_TYPE_OTHER_INTERIOR2_ARMATURE', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_OTHER_INTERIOR2_ARMATURE') },
          { internalId: 23, type: 'DEVICE_TYPE_OTHER_INTERIOR2_OPERATING_GARNITURE', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_OTHER_INTERIOR2_OPERATING_GARNITURE') },
          { internalId: 24, type: 'DEVICE_TYPE_KITCHEN_EQUIPMENT_HOB', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_KITCHEN_EQUIPMENT_HOB') },
          { internalId: 25, type: 'DEVICE_TYPE_KITCHEN_EQUIPMENT_STEAM_EXHAUST_HOOD', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_KITCHEN_EQUIPMENT_STEAM_EXHAUST_HOOD') },
          { internalId: 26, type: 'DEVICE_TYPE_KITCHEN_EQUIPMENT_FILTER', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_KITCHEN_EQUIPMENT_FILTER') },
          { internalId: 27, type: 'DEVICE_TYPE_KITCHEN_EQUIPMENT_OVEN', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_KITCHEN_EQUIPMENT_OVEN') },
          { internalId: 28, type: 'DEVICE_TYPE_KITCHEN_EQUIPMENT_OVEN_ACCESSORIES', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_KITCHEN_EQUIPMENT_OVEN_ACCESSORIES') },
          { internalId: 29, type: 'DEVICE_TYPE_KITCHEN_EQUIPMENT_STEAMER', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_KITCHEN_EQUIPMENT_STEAMER') },
          { internalId: 30, type: 'DEVICE_TYPE_KITCHEN_EQUIPMENT_STEAMER_ACCESSORIES', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_KITCHEN_EQUIPMENT_STEAMER_ACCESSORIES') },
          { internalId: 31, type: 'DEVICE_TYPE_KITCHEN_EQUIPMENT_REFRIGERATOR', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_KITCHEN_EQUIPMENT_REFRIGERATOR') },
          { internalId: 32, type: 'DEVICE_TYPE_KITCHEN_EQUIPMENT_REFRIGERATOR_ACCESSORIES', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_KITCHEN_EQUIPMENT_REFRIGERATOR_ACCESSORIES') },
          { internalId: 33, type: 'DEVICE_TYPE_KITCHEN_EQUIPMENT_FREEZER', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_KITCHEN_EQUIPMENT_FREEZER') },
          { internalId: 34, type: 'DEVICE_TYPE_KITCHEN_EQUIPMENT_DISH_WASHER', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_KITCHEN_EQUIPMENT_DISH_WASHER') },
          { internalId: 35, type: 'DEVICE_TYPE_KITCHEN_EQUIPMENT_DISH_WASHER_CUTLERY_TRAY', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_KITCHEN_EQUIPMENT_DISH_WASHER_CUTLERY_TRAY') }]
      },
        {
          name: 'wetRoom',
          value: this.$t('message.processHandover.rooms.wetRoom'),
          devices: [
            { internalId: 1, type: 'DEVICE_TYPE_FLOORING', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_FLOORING') },
            { internalId: 2, type: 'DEVICE_TYPE_FLOORING_SKIRTING', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_FLOORING_SKIRTING') },
            { internalId: 3, type: 'DEVICE_TYPE_WALL_COVERING', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_WALL_COVERING') },
            { internalId: 4, type: 'DEVICE_TYPE_CEILING_REGULAR', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_CEILING_REGULAR') },
            { internalId: 5, type: 'DEVICE_TYPE_CARPENTER_DOOR', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_CARPENTER_DOOR') },
            { internalId: 6, type: 'DEVICE_TYPE_LOCKING_KEY', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_LOCKING_KEY') },
            { internalId: 7, type: 'DEVICE_TYPE_WINDOW', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_WINDOW') },
            { internalId: 8, type: 'DEVICE_TYPE_WINDOW_WINDOW_FRAME', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_WINDOW_WINDOW_FRAME') },
            { internalId: 9, type: 'DEVICE_TYPE_WINDOW_WINDOW_SILL', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_WINDOW_WINDOW_SILL') },
            { internalId: 10, type: 'DEVICE_TYPE_OUTER_FINISHES', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_OUTER_FINISHES') },
            { internalId: 11, type: 'DEVICE_TYPE_OTHER_INTERIOR2_CRANK', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_OTHER_INTERIOR2_CRANK') },
            { internalId: 12, type: 'DEVICE_TYPE_HEAT_GENERATION_HEATING_ELEMENT', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_HEAT_GENERATION_HEATING_ELEMENT') },
            { internalId: 13, type: 'DEVICE_TYPE_LOW_CURRENT_INSTALLATION_SWITCH', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_LOW_CURRENT_INSTALLATION_SWITCH') },
            { internalId: 14, type: 'DEVICE_TYPE_OTHER_ELECTRICITY_SOCKET', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_OTHER_ELECTRICITY_SOCKET') },
            { internalId: 15, type: 'DEVICE_TYPE_LIGHTS', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_LIGHTS') },
            { internalId: 16, type: 'DEVICE_TYPE_SANITARY_EQUIPMENT_BATHROOM_FURNITURE', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_SANITARY_EQUIPMENT_BATHROOM_FURNITURE') },
            { internalId: 17, type: 'DEVICE_TYPE_SANITARY_EQUIPMENT_MIRROR_CABINETS', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_SANITARY_EQUIPMENT_MIRROR_CABINETS') },
            { internalId: 18, type: 'DEVICE_TYPE_SANITARY_WASHBASIN', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_SANITARY_WASHBASIN') },
            { internalId: 19, type: 'DEVICE_TYPE_OTHER_INTERIOR2_ARMATURE', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_OTHER_INTERIOR2_ARMATURE') },
            { internalId: 20, type: 'DEVICE_TYPE_OTHER_INTERIOR2_SETS', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_OTHER_INTERIOR2_SETS') },
            { internalId: 21, type: 'DEVICE_TYPE_OTHER_INTERIOR2_OPERATING_GARNITURE', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_OTHER_INTERIOR2_OPERATING_GARNITURE') },
            { internalId: 22, type: 'DEVICE_TYPE_OTHER_INTERIOR2_SETS_BATH_TOWEL_HOLDER', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_OTHER_INTERIOR2_SETS_BATH_TOWEL_HOLDER') },
            { internalId: 23, type: 'DEVICE_TYPE_SANITARY_TOILET', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_SANITARY_TOILET') },
            { internalId: 24, type: 'DEVICE_TYPE_SANITARY_TOILET_COVER', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_SANITARY_TOILET_COVER') },
            { internalId: 25, type: 'DEVICE_TYPE_SANITARY_FLUSH_TANK', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_SANITARY_FLUSH_TANK') },
            { internalId: 26, type: 'DEVICE_TYPE_OTHER_INTERIOR2_SETS_PAPER_HOLDER', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_OTHER_INTERIOR2_SETS_PAPER_HOLDER') },
            { internalId: 27, type: 'DEVICE_TYPE_VENTILATION_EXHAUST_AIR_FAN', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_VENTILATION_EXHAUST_AIR_FAN') },
            { internalId: 28, type: 'DEVICE_TYPE_SANITARY_BATHTUB', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_SANITARY_BATHTUB') },
            { internalId: 29, type: 'DEVICE_TYPE_SANITARY_SHOWER', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_SANITARY_SHOWER') },
            { internalId: 30, type: 'DEVICE_TYPE_SANITARY_SHOWER_HEAD_HOSE', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_SANITARY_SHOWER_HEAD_HOSE') },
            { internalId: 31, type: 'DEVICE_TYPE_SANITARY_SHOWER_ROD', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_SANITARY_SHOWER_ROD') },
            { internalId: 32, type: 'DEVICE_TYPE_SANITARY_SHOWER_CABIN', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_SANITARY_SHOWER_CABIN') }
          ]
},
        {
          name: 'room',
          value: this.$t('message.processHandover.rooms.room'),
          devices: [
            { internalId: '1room', type: 'DEVICE_TYPE_FLOORING', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_FLOORING') },
            { internalId: '2room', type: 'DEVICE_TYPE_FLOORING_SKIRTING', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_FLOORING_SKIRTING') },
            { internalId: '3room', type: 'DEVICE_TYPE_WALL_COVERING', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_WALL_COVERING') },
            { internalId: '4room', type: 'DEVICE_TYPE_CEILING_REGULAR', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_CEILING_REGULAR') },
            { internalId: '5room', type: 'DEVICE_TYPE_CARPENTER_DOOR', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_CARPENTER_DOOR') },
            { internalId: '6room', type: 'DEVICE_TYPE_LOCKING_KEY', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_LOCKING_KEY') },
            { internalId: '7room', type: 'DEVICE_TYPE_WINDOW', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_WINDOW') },
            { internalId: '8room', type: 'DEVICE_TYPE_WINDOW_WINDOW_FRAME', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_WINDOW_WINDOW_FRAME') },
            { internalId: '9room', type: 'DEVICE_TYPE_WINDOW_WINDOW_SILL', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_WINDOW_WINDOW_SILL') },
            { internalId: '10room', type: 'DEVICE_TYPE_OUTER_FINISHES', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_OUTER_FINISHES') },
            { internalId: '11room', type: 'DEVICE_TYPE_OTHER_INTERIOR2_CRANK', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_OTHER_INTERIOR2_CRANK') },
            { internalId: '12room', type: 'DEVICE_TYPE_HEAT_GENERATION_HEATING_ELEMENT', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_HEAT_GENERATION_HEATING_ELEMENT') },
            { internalId: '13room', type: 'DEVICE_TYPE_LOW_CURRENT_INSTALLATION_SWITCH', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_LOW_CURRENT_INSTALLATION_SWITCH') },
            { internalId: '14room', type: 'DEVICE_TYPE_OTHER_ELECTRICITY_SOCKET', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_OTHER_ELECTRICITY_SOCKET') },
            { internalId: '15room', type: 'DEVICE_TYPE_LIGHTS', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_LIGHTS') },
            { internalId: '16room', type: 'DEVICE_TYPE_CARPENTER_BUILT_IN_CLOSETS', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_CARPENTER_BUILT_IN_CLOSETS') },
            { internalId: '17room', type: 'DEVICE_TYPE_CARPENTER_CLOSET', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_CARPENTER_CLOSET') },
            { internalId: '18room', type: 'DEVICE_TYPE_LOW_CURRENT_INSTALLATION_MULTIMEDIA', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_LOW_CURRENT_INSTALLATION_MULTIMEDIA') },
            { internalId: '19room', type: 'DEVICE_TYPE_INSULATION_CHIMNEY', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_INSULATION_CHIMNEY') },
            { internalId: '20room', type: 'DEVICE_TYPE_SUPERSTRUCTURE_RAILING', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_SUPERSTRUCTURE_RAILING') }
          ]
        },
        {
          name: 'corridor_entrance',
          value: this.$t('message.processHandover.rooms.corridor_entrance'),
          devices: [
            { internalId: '1_corridor_entrance', type: 'DEVICE_TYPE_FLOORING', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_FLOORING') },
            { internalId: '2_corridor_entrance', type: 'DEVICE_TYPE_FLOORING_SKIRTING', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_FLOORING_SKIRTING') },
            { internalId: '3_corridor_entrance', type: 'DEVICE_TYPE_WALL_COVERING', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_WALL_COVERING') },
            { internalId: '4_corridor_entrance', type: 'DEVICE_TYPE_CEILING_REGULAR', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_CEILING_REGULAR') },
            { internalId: '5_corridor_entrance', type: 'DEVICE_TYPE_CARPENTER_DOOR', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_CARPENTER_DOOR') },
            { internalId: '6_corridor_entrance', type: 'DEVICE_TYPE_LOCKING_KEY', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_LOCKING_KEY') },
            { internalId: '7_corridor_entrance', type: 'DEVICE_TYPE_WINDOW', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_WINDOW') },
            { internalId: '8_corridor_entrance', type: 'DEVICE_TYPE_WINDOW_WINDOW_FRAME', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_WINDOW_WINDOW_FRAME') },
            { internalId: '9_corridor_entrance', type: 'DEVICE_TYPE_WINDOW_WINDOW_SILL', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_WINDOW_WINDOW_SILL') },
            { internalId: '10_corridor_entrance', type: 'DEVICE_TYPE_OUTER_FINISHES', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_OUTER_FINISHES') },
            { internalId: '11_corridor_entrance', type: 'DEVICE_TYPE_OTHER_INTERIOR2_CRANK', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_OTHER_INTERIOR2_CRANK') },
            { internalId: '12_corridor_entrance', type: 'DEVICE_TYPE_HEAT_GENERATION_HEATING_ELEMENT', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_HEAT_GENERATION_HEATING_ELEMENT') },
            { internalId: '13_corridor_entrance', type: 'DEVICE_TYPE_LOW_CURRENT_INSTALLATION_SWITCH', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_LOW_CURRENT_INSTALLATION_SWITCH') },
            { internalId: '14_corridor_entrance', type: 'DEVICE_TYPE_OTHER_ELECTRICITY_SOCKET', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_OTHER_ELECTRICITY_SOCKET') },
            { internalId: '15_corridor_entrance', type: 'DEVICE_TYPE_LIGHTS', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_LIGHTS') },
            { internalId: '16_corridor_entrance', type: 'DEVICE_TYPE_CARPENTER_BUILT_IN_CLOSETS', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_CARPENTER_BUILT_IN_CLOSETS') },
            { internalId: '17_corridor_entrance', type: 'DEVICE_TYPE_CARPENTER_CLOSET', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_CARPENTER_CLOSET') },
            { internalId: '18_corridor_entrance', type: 'DEVICE_TYPE_LOW_CURRENT_INSTALLATION_INTERPHONE', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_LOW_CURRENT_INSTALLATION_INTERPHONE') }
          ]
},
        {
          name: 'reduit_storage',
          value: this.$t('message.processHandover.rooms.reduit_storage'),
          devices: [
            { internalId: '1_reduit_storage', type: 'DEVICE_TYPE_FLOORING', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_FLOORING') },
            { internalId: '2_reduit_storage', type: 'DEVICE_TYPE_FLOORING_SKIRTING', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_FLOORING_SKIRTING') },
            { internalId: '3_reduit_storage', type: 'DEVICE_TYPE_WALL_COVERING', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_WALL_COVERING') },
            { internalId: '4_reduit_storage', type: 'DEVICE_TYPE_CEILING_REGULAR', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_CEILING_REGULAR') },
            { internalId: '5_reduit_storage', type: 'DEVICE_TYPE_CARPENTER_DOOR', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_CARPENTER_DOOR') },
            { internalId: '6_reduit_storage', type: 'DEVICE_TYPE_LOCKING_KEY', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_LOCKING_KEY') },
            { internalId: '7_reduit_storage', type: 'DEVICE_TYPE_WINDOW', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_WINDOW') },
            { internalId: '8_reduit_storage', type: 'DEVICE_TYPE_WINDOW_WINDOW_FRAME', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_WINDOW_WINDOW_FRAME') },
            { internalId: '9_reduit_storage', type: 'DEVICE_TYPE_WINDOW_WINDOW_SILL', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_WINDOW_WINDOW_SILL') },
            { internalId: '10_reduit_storage', type: 'DEVICE_TYPE_OUTER_FINISHES', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_OUTER_FINISHES') },
            { internalId: '11_reduit_storage', type: 'DEVICE_TYPE_OTHER_INTERIOR2_CRANK', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_OTHER_INTERIOR2_CRANK') },
            { internalId: '12_reduit_storage', type: 'DEVICE_TYPE_HEAT_GENERATION_HEATING_ELEMENT', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_HEAT_GENERATION_HEATING_ELEMENT') },
            { internalId: '13_reduit_storage', type: 'DEVICE_TYPE_LOW_CURRENT_INSTALLATION_SWITCH', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_LOW_CURRENT_INSTALLATION_SWITCH') },
            { internalId: '14_reduit_storage', type: 'DEVICE_TYPE_OTHER_ELECTRICITY_SOCKET', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_OTHER_ELECTRICITY_SOCKET') },
            { internalId: '15_reduit_storage', type: 'DEVICE_TYPE_LIGHTS', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_LIGHTS') }
          ]
        },
        {
          name: 'balcony_seatingArea',
          value: this.$t('message.processHandover.rooms.balcony_seatingArea'),
          devices: [
            { internalId: '1_balcony_seatingArea', type: 'DEVICE_TYPE_FLOORING', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_FLOORING') },
            { internalId: '13_balcony_seatingArea', type: 'DEVICE_TYPE_LOW_CURRENT_INSTALLATION_SWITCH', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_LOW_CURRENT_INSTALLATION_SWITCH') },
            { internalId: '14_balcony_seatingArea', type: 'DEVICE_TYPE_OTHER_ELECTRICITY_SOCKET', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_OTHER_ELECTRICITY_SOCKET') },
            { internalId: '15_balcony_seatingArea', type: 'DEVICE_TYPE_LIGHTS', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_LIGHTS') },
            { internalId: '19_balcony_seatingArea', type: 'DEVICE_TYPE_FACADE_PLASTER', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_FACADE_PLASTER') },
            { internalId: '20_balcony_seatingArea', type: 'DEVICE_TYPE_OUTER_FINISHES_PRIVACY_SCREEN', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_OUTER_FINISHES_PRIVACY_SCREEN') },
            { internalId: '21_balcony_seatingArea', type: 'DEVICE_TYPE_OUTER_FINISHES_SUN_BLINDS', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_OUTER_FINISHES_SUN_BLINDS') },
            { internalId: '22_balcony_seatingArea', type: 'DEVICE_TYPE_SPECIAL_SANITARY_WATER_TAP', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_SPECIAL_SANITARY_WATER_TAP') }
          ]
        },
        {
          name: 'cellar',
          value: this.$t('message.processHandover.rooms.cellar'),
          devices: [
            { internalId: '1_cellar', type: 'DEVICE_TYPE_FLOORING', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_FLOORING') },
            { internalId: '2_cellar', type: 'DEVICE_TYPE_WALL_COVERING', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_WALL_COVERING') },
            { internalId: '3_cellar', type: 'DEVICE_TYPE_CARPENTER_CELLAR_DOOR', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_CARPENTER_CELLAR_DOOR') },
            { internalId: '4_cellar', type: 'DEVICE_TYPE_CARPENTER_SHELF', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_CARPENTER_SHELF') },
            { internalId: '5_cellar', type: 'DEVICE_TYPE_LOW_CURRENT_INSTALLATION_SWITCH', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_LOW_CURRENT_INSTALLATION_SWITCH') },
            { internalId: '6_cellar', type: 'DEVICE_TYPE_OTHER_ELECTRICITY_SOCKET', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_OTHER_ELECTRICITY_SOCKET') },
            { internalId: '7_cellar', type: 'DEVICE_TYPE_LIGHTS', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_LIGHTS') },
            { internalId: '8_cellar', type: 'DEVICE_TYPE_WINDOW', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_WINDOW') },
            { internalId: '9_cellar', type: 'DEVICE_TYPE_WINDOW_WINDOW_FRAME', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_WINDOW_WINDOW_FRAME') }
          ]
        }]
    } else if (this.object.category === 'PARKING_SPACE') {
      this.availableRooms = [{
      name: 'parking',
      value: this.$t('message.processHandover.rooms.parking'),
      devices: [
        { internalId: '1_parking', type: 'DEVICE_TYPE_FLOORING', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_FLOORING') },
        { internalId: '2_parking', type: 'DEVICE_TYPE_GARDENING_TRANSITION_POSITION_GROUND_MARKING', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_GARDENING_TRANSITION_POSITION_GROUND_MARKING') },
        { internalId: '3_parking', type: 'DEVICE_TYPE_WINDOW_DOOR_GARAGE_DOOR', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_WINDOW_DOOR_GARAGE_DOOR') },
        { internalId: '4_parking', type: 'DEVICE_TYPE_CARPENTER_CLOSET', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_CARPENTER_CLOSET') },
        { internalId: '5_parking', type: 'DEVICE_TYPE_SPECIAL_SANITARY_WATER_TAP', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_SPECIAL_SANITARY_WATER_TAP') }
      ]
      }]
    } else if (this.object.category === 'SECONDARY_ROOM') {
      this.availableRooms = [{
      name: 'secondaryRoom',
      value: this.$t('message.processHandover.rooms.secondaryRoom'),
      devices: [
        { internalId: '1_secondaryRoom', type: 'DEVICE_TYPE_FLOORING', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_FLOORING') },
        { internalId: '2_secondaryRoom', type: 'DEVICE_TYPE_FLOORING_SKIRTING', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_FLOORING_SKIRTING') },
        { internalId: '3_secondaryRoom', type: 'DEVICE_TYPE_WALL_COVERING', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_WALL_COVERING') },
        { internalId: '4_secondaryRoom', type: 'DEVICE_TYPE_CEILING_REGULAR', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_CEILING_REGULAR') },
        { internalId: '5_secondaryRoom', type: 'DEVICE_TYPE_CARPENTER_DOOR', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_CARPENTER_DOOR') },
        { internalId: '6_secondaryRoom', type: 'DEVICE_TYPE_LOCKING_KEY', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_LOCKING_KEY') },
        { internalId: '7_secondaryRoom', type: 'DEVICE_TYPE_WINDOW', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_WINDOW') },
        { internalId: '8_secondaryRoom', type: 'DEVICE_TYPE_WINDOW_WINDOW_FRAME', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_WINDOW_WINDOW_FRAME') },
        { internalId: '9_secondaryRoom', type: 'DEVICE_TYPE_WINDOW_WINDOW_SILL', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_WINDOW_WINDOW_SILL') },
        { internalId: '10_secondaryRoom', type: 'DEVICE_TYPE_LOW_CURRENT_INSTALLATION_SWITCH', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_LOW_CURRENT_INSTALLATION_SWITCH') },
        { internalId: '11_secondaryRoom', type: 'DEVICE_TYPE_OTHER_ELECTRICITY_SOCKET', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_OTHER_ELECTRICITY_SOCKET') },
        { internalId: '12_secondaryRoom', type: 'DEVICE_TYPE_LIGHTS', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_LIGHTS') }
      ]
      }]
    } else if (this.object.category === 'COMMERCIAL') {
      this.availableRooms = [{
      name: 'commercial',
      value: this.$t('message.processHandover.rooms.commercial'),
      devices: [
        { internalId: '1_commercial', type: 'DEVICE_TYPE_OTHER_INTERIOR2_SHUTTER', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_OTHER_INTERIOR2_SHUTTER') },
        { internalId: '2_commercial', type: 'DEVICE_TYPE_OTHER_INTERIOR2_BELT', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_OTHER_INTERIOR2_BELT') },
        { internalId: '3_commercial', type: 'DEVICE_TYPE_CARPENTER_DOOR_FRAME', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_CARPENTER_DOOR_FRAME') },
        { internalId: '4_commercial', type: 'DEVICE_TYPE_CARPENTER_DOOR', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_CARPENTER_DOOR') },
        { internalId: '5_commercial', type: 'DEVICE_TYPE_FLOORING_RAW', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_FLOORING_RAW') },
        { internalId: '6_commercial', type: 'DEVICE_TYPE_LIGHTS_CEILING', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_LIGHTS_CEILING') },
        { internalId: '7_commercial', type: 'DEVICE_TYPE_WINDOW_WINDOW_SILL', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_WINDOW_WINDOW_SILL') },
        { internalId: '8_commercial', type: 'DEVICE_TYPE_OTHER_ELECTRICITY_SOCKET', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_OTHER_ELECTRICITY_SOCKET') },
        { internalId: '9_commercial', type: 'DEVICE_TYPE_FLOORING_SKIRTING', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_FLOORING_SKIRTING') },
        { internalId: '10_commercial', type: 'DEVICE_TYPE_FLOORING_RAW', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_FLOORING_RAW') },
        { internalId: '11_commercial', type: 'DEVICE_TYPE_WALL_COVERING', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_WALL_COVERING') },
        { internalId: '12_commercial', type: 'DEVICE_TYPE_LOCKING_KEY', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_LOCKING_KEY') },
        { internalId: '13_commercial', type: 'DEVICE_TYPE_KITCHEN_EQUIPMENT_KITCHEN_COVER', value: this.$t('message.onBoarding.devices.typeValues.DEVICE_TYPE_KITCHEN_EQUIPMENT_KITCHEN_COVER') }
      ]
      }]
    }
       this.addPropertiesToDevices()
      this.loading = false
    },
    addDeviceMethod(device) {
      if (!this.selectedRoomModal) return
      this.$nextTick(() => {
        this.moveSelectedDeviceToSelected(device)
      })
    },
    moveSelectedDeviceToSelected(device) {
      if (!this.selectedRoomModal) return
      if (device) {
        if (this.selectedRoomModal?.devices) {
          // clone the device object to avoid reference issues
          device = JSON.parse(JSON.stringify(device))
          device.internalId = `tempdevice_${Date.now()}`
          this.selectedRoomModal.devices.push(device)
        } else {
          this.selectedRoomModal.devices = [device]
        }
        this.clearSelection()
      }
    },
    addAllDevices(room) {
      if (!this.selectedRoomModal || !room) return
      room.devices.forEach(device => {
        device.roomId = this.selectedRoomModal.id
        this.selectedRoomModal.devices.push(device)
      })
    },
    toggleSelectedRoomCollapse(index) {
      this.$set(this.collapsedSelectedRooms, index, !this.collapsedSelectedRooms[index])
    },
    showDeleteConfirmation(device, e, room = null) {
      e.preventDefault()
      this.selectedDevice = device
      if (room) {
        this.selectedRoom = room
      }
      this.$bvModal.show('confirmationModalDelete_' + this.object.id)
    },
    addPropertiesToDevices() {
      this.availableRooms.forEach(room => {
        room.devices.forEach(device => {
          this.availableSuggestions.filter(suggestion => suggestion.type === device.type)
          Object.assign(device, {
            internalId: Date.now() + '_' + device.type + '_' + room.name,
            ownerId: this.object.ownerId,
            numericId: 99999,
            brand: '',
            referenceId: this.object.id,
            room: room.name,
            installation: null,
            uninstallation: null,
            measureUntil: null,
            measure: 'DEVICE_MEASURE_UNDEFINED',
            type: device.type,
            system: this.availableSuggestions.find(suggestion => suggestion.type === device.type)?.system || 'DEVICE_SYSTEM_UNDEFINED',
            facilityPlant: this.availableSuggestions.find(suggestion => suggestion.type === device.type)?.facilityPlant || 'DEVICE_FACILITY_PLANT_UNDEFINED',
            bkpH: this.availableSuggestions.find(suggestion => suggestion.type === device.type)?.bkp || '',
            lifeSpan: this.availableSuggestions.find(suggestion => suggestion.type === device.type)?.lifeSpan || -1,
            amount: -1,
            value: device.value,
            acquisitionCosts: -1,
            unit: this.availableSuggestions.find(suggestion => suggestion.type === device.type)?.unit || 'DEVICE_UNIT_UNDEFINED',
            cataloguePrice: this.availableSuggestions.find(suggestion => suggestion.type === device.type)?.cataloguePrice || -1,
            maintenance: {
              contact: {
                userReference: '',
                accountId: -1
              },
              costs: {
                currency: 'CHF',
                interval: 'UNDEFINED_INTERVAL'
              },
              lastMaintenance: null,
              nextMaintenance: null,
              subscriptionStart: null,
              subscriptionEnd: null,
              note: ''
            },
            inspection: {
              contact: {
                userReference: '',
                accountId: -1
              },
              costs: {
                currency: 'CHF',
                interval: 'UNDEFINED_INTERVAL'
              },
              lastOfficialControl: null,
              nextOfficialControl: null,
              subscriptionStart: null,
              subscriptionEnd: null,
              note: ''
            },
            revision: {
              contact: {
                userReference: '',
                accountId: -1
              },
              costs: {
                currency: 'CHF',
                interval: 'UNDEFINED_INTERVAL'
              },
              lastOfficialRevision: null,
              nextOfficialRevision: null,
              subscriptionStart: null,
              subscriptionEnd: null,
              note: ''
            },
            condition: 'DEVICE_CONDITION_NORMAL_WEAR',
            note: '',
            documentIds: [],
            images: [],
            isNewItem: true,
            lockingKey: {
              combinedKeys: false,
              combinedKeysNote: '',
              description: '',
              lockingPlanNumber: ''
            }
          })
        })
      })
    },
    selectSelectedRoom(room) {
      this.selectedRoom = room
    },
    getContactName(contactId) {
      const contact = this.usedContacts.concat(this.contacts).find(function (contact) {
        return contact.id === contactId || contact.objectID === contactId
      })
      return contact ? ', ' + contact.name : '-'
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep #editDevice .modal-dialog {
  max-width: 80% !important;
}
::v-deep #addDevice .modal-dialog {
  max-width: 80% !important;
}
.room-container {
  margin-bottom: 20px;
}

.room-title {
  padding: 10px;
  background-color: #e9ecef;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.device-list {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 3px;
  background-color: #fff;
  margin-top: 5px;
}
.arrows {
  display: flex;
  flex-direction: column;
  justify-content: initial;
  margin-top: 10rem;
  align-items: center;
}
.center-add-all {
  margin-top: -5px;
}

.arrows button {
  margin: 5px;
}
.tooltip-message {
  width: 15px;
}

.list-group-item {
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ddd;
  border-radius: 3px;
  background-color: #f5f5f5;
}
#addDevice .list-group-item.with-add-button {
  &:first-child {
    display: inline-block;
    width: 100%;
    margin: 0;
    margin-top: -13px;
  }
}

.add-device-btn {
  width: 100%;
  padding: 8px;
  margin-top: 10px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.add-device-btn:hover {
  background-color: #218838;
}
.list-group-item.selected, .room-title.selected {
  background-color: #d3e2ff;
  border-color: #007bff;
}
.modal-add-all {
  position: relative;
  top: -2.4rem;
}
</style>
