<template>
  <section
    id="HandoverList">
    <div>
      <coozzy-page-title
        :title="$t('message.handoverProtocol.title')"
        :count="filteredHandovers.length"
        :count-text="$t('message.handoverProtocol.title')"
        :clear-filter="isFiltered"
        @clear-filter="initFilter" />
      <div class="row mb-2">
        <div class="col-12 col-md mb-2 mb-md-0">
          <coozzy-form-input
            ref="searchText"
            v-model="searchText"
            :placeholder="$t('message.generic.search')"
            @keyup="searchInputText()" />
        </div>
        <div class="col-12 col-md-2 mb-2 mb-md-0">
          <coozzy-multiselect
            v-model="searchStatus"
            class="filter-inputs"
            :options="sortedFilterValues(availableStatusOptions, customLabelStatus, 'value')"
            :multiple="true"
            :placeholder="$t('message.generic.status')"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :custom-label="customLabelStatus"
            :track-by="'value'"
            :preselect-first="false"
            :taggable="false"
            @select="filterHandoverList"
            @remove="filterHandoverList" />
        </div>
        <div class="col-12 col-md-2 mb-2 mb-md-0">
          <coozzy-multiselect
            v-model="searchProperty"
            :options="sortedFilterValues(availablePropertyOptions, customLabel)"
            :multiple="true"
            :placeholder="$t('message.propertiesView.tabs.property')"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :custom-label="customLabel"
            :track-by="'value'"
            :preselect-first="false"
            :taggable="false"
            @select="filterHandoverList"
            @remove="filterHandoverList" />
        </div>
        <div class="col-12 col-md-2 mb-2 mb-md-0">
          <coozzy-multiselect
            v-model="searchBuilding"
            :options="sortedFilterValues(availableBuildingOptions, customLabel)"
            :multiple="true"
            :placeholder="$t('message.onBoardingTabs.building')"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :custom-label="customLabel"
            :track-by="'value'"
            :preselect-first="false"
            :taggable="false"
            @select="filterHandoverList"
            @remove="filterHandoverList" />
        </div>
        <div
          class="col-12 col-md-2 mb-2">
          <coozzy-multiselect
            v-model="searchZip"
            :options="sortedFilterValues(availableZipOptions, customLabelValue)"
            :multiple="true"
            :placeholder="$t('message.generic.zip')"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :custom-label="customLabelValue"
            :track-by="'value'"
            :preselect-first="false"
            :taggable="false"
            @select="filterHandoverList"
            @remove="filterHandoverList" />
        </div>
        <div
          class="col-12 col-md-2 mb-2">
          <coozzy-multiselect
            v-model="searchCity"
            :options="sortedFilterValues(availableCityOptions, customLabelValue)"
            :multiple="true"
            :placeholder="$t('message.generic.city')"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :custom-label="customLabelValue"
            :track-by="'value'"
            :preselect-first="false"
            :taggable="false"
            @select="filterHandoverList"
            @remove="filterHandoverList" />
        </div>
        <div class="col-12 col-md-2">
          <coozzy-assignee-multiselect
            v-model="searchAssignee"
            class="filter-inputs"
            :options="sortedFilterValues(assigneeFilter, customLabelAssignee, 'value')"
            :multiple="true"
            :placeholder="$t('message.tableColumnHeadings.assignee')"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :custom-label="customLabelAssignee"
            :track-by="'value'"
            :preselect-first="false"
            :taggable="false"
            @select="filterHandoverList"
            @remove="filterHandoverList" />
        </div>
        <div
          class="col-12 col-md-2 mb-2">
          <coozzy-multiselect
            v-model="searchTypes"
            :options="sortedFilterValues(availableTypeOptions, customLabelCategory)"
            :multiple="true"
            :placeholder="$t('message.generic.type')"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :custom-label="customLabelCategory"
            :track-by="'value'"
            :preselect-first="false"
            :taggable="false"
            @select="filterHandoverList"
            @remove="filterHandoverList" />
        </div>
        <div class="col-12 col-md-2 mb-2 mb-md-0">
          <coozzy-multiselect
            v-model="searchOwner"
            :options="sortedFilterValues(availableOwnersOptions, customLabel)"
            :multiple="true"
            :placeholder="$t('message.tableColumnHeadings.owner')"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :custom-label="customLabel"
            :track-by="'value'"
            label="name"
            :preselect-first="false"
            :taggable="false"
            @select="filterHandoverList"
            @remove="filterHandoverList" />
        </div>
      </div>
      <div
        id="rowConfig"
        class="row mb-2 align-items-center">
        <div class="col-md-2 col-sm-6 col-12">
          <coozzy-dropdown
            design="green"
            size="sm"
            class="w-100 sort"
            :text="selectedEntriesText">
            <div
              v-if="isCompanyAdmin"
              class="row m-0">
              <coozzy-dropdown-item
                class="col pr-0 pl-0"
                :disabled="selectedEntries.length === 0"
                @click="selectedEntries.length !== 0 ? openConfirmationModal() : ''">
                {{ $t('message.generic.delete') }}
              </coozzy-dropdown-item>
            </div>
          </coozzy-dropdown>
        </div>
        <div class="col-md-2 col-sm-6 col-12">
          <template v-if="selectAllVisibleEntries && nbrTotalOfHits > nbrPerPage && nbrTotalOfHits <= 1000">
            <coozzy-form-checkbox
              v-model="selectAllEntries"
              :initial="selectAllEntries"
              :class="'p-0 w-100 btn-height-auto'"
              name="selectAllEntries"
              button>
              {{ selectAllEntries ? $t('message.generic.unselectHiddenEntries', { amount: nbrTotalOfHits }) : $t('message.generic.selectHiddenEntries', { amount: nbrTotalOfHits }) }}
            </coozzy-form-checkbox>
          </template>
        </div>
        <div
          v-if="isCompanyAdmin === true"
          class="offset-md-5 col-md-1 d-flex justify-content-end align-items-center">
          <div
            @click="openAssembleListModal()">
            <coozzy-filters-icon
              v-b-tooltip="{container: '#rowConfig', title: $t('message.generic.tooltipAssembleList')}" />
          </div>
        </div>
        <div
          class="col-md-2 col-sm-6 col-12 mt-2 mt-sm-0"
          :class="isCompanyAdmin !== true ? 'offset-md-8' : ''">
          <coozzy-form-select
            v-model="nbrPerPage"
            name="numberEntries"
            class="select-entries float-right">
            <option value="5">
              5 {{ $t('message.generic.entries') }}
            </option>
            <option value="10">
              10 {{ $t('message.generic.entries') }}
            </option>
            <option value="15">
              15 {{ $t('message.generic.entries') }}
            </option>
            <option value="20">
              20 {{ $t('message.generic.entries') }}
            </option>
            <option value="all">
              {{ $t('message.manageInterestedParties.filter.allEntries') }}
            </option>
          </coozzy-form-select>
        </div>
      </div>
    </div>
    <b-table
      id="table-groups"
      ref="table-groups"
      hover
      bordered
      responsive="true"
      stacked="md"
      selectable
      select-mode="single"
      class="mb-4 overflow-auto shadow"
      :table-class="elementSelected ? 'b-table-selecting' : ''"
      :busy="isBusy"
      :fields="fields"
      :items="HandoversPage"
      @row-clicked="handoverClicked"
      @row-middle-clicked="onMiddlelicked">
      <!-- Busy state -->
      <div
        slot="table-busy"
        class="text-center text-danger my-2">
        <coozzy-spinner />
      </div>

      <!-- Headings -->
      <template #head(checkbox)>
        <coozzy-form-checkbox
          ref="header_checkbox"
          @change="(value) => { handleCheckboxesInsideTable(value) }" />
      </template>
      <template #head(owner)>
        {{ $t('message.tableColumnHeadings.owner') }}
      </template>
      <template #head(status)>
        {{ $t('message.tableColumnHeadings.status') }}
      </template>
      <template #head(previousTenancy)>
        {{ $t('message.tableColumnHeadings.previousTenancy') }}
      </template>
      <template #head(newTenancy)>
        {{ $t('message.tableColumnHeadings.newTenancy') }}
      </template>
      <template #head(area)>
        {{ $t('message.tableColumnHeadings.area') }}
      </template>
      <template #head(address)>
        {{ $t('message.tableColumnHeadings.address') }}
      </template>
      <template #head(objects)>
        {{ $t('message.tableColumnHeadings.objects') }}
      </template>
      <template #head(caution)>
        {{ $t('message.tableColumnHeadings.caution') }}
      </template>
      <template #head(type)>
        {{ $t('message.tableColumnHeadings.type') }}
      </template>
      <template #head(floor)>
        {{ $t('message.tableColumnHeadings.floor') }}
      </template>
      <template #head(handoverDate)>
        {{ $t('message.tableColumnHeadings.handoverDate') }}
      </template>
      <template #head(assignee)>
        {{ $t('message.tableColumnHeadings.assignee') }}
      </template>
      <!-- Body -->
      <template #cell(checkbox)="data">
        <coozzy-form-checkbox
          :id="'check_' + data.item.id"
          @change="(value) => { itemSelected(data.item, value) }" />
      </template>
      <template #cell(status)="data">
        <div
          class="color-box mr-2 mt-2"
          :class="{ 'color-box-green': data.item.status === 'PROTOCOL_STATUS_SENT_TO_APP', 'color-box-yellow': data.item.status === 'PROTOCOL_STATUS_DRAFT' }"
          :style="{ 'background-color' : data.item.status === 'PROTOCOL_STATUS_SENT_TO_APP' ? '#7ed957;' : '#ffde59;' }" />
        {{ $t('message.handoverProtocol.status.' + data.item.status) }}
      </template>
      <template #cell(previousTenancy)="data">
        <template v-if="getParticipant(data.item.participants, 'PARTICIPANT_TYPE_TENANT_MOVE_OUT').length > 0">
          <router-link
            v-for="contact in getParticipant(data.item.participants, 'PARTICIPANT_TYPE_TENANT_MOVE_OUT')"
            :key="contact.contactId"
            :to="redirectToDetailPage(contact.contactId)">
            {{ getContactName(contact.contactData) }}<br>
          </router-link>
        </template>
        <template v-else>
          -
        </template>
      </template>
      <template #cell(newTenancy)="data">
        <template v-if="getParticipant(data.item.participants, 'PARTICIPANT_TYPE_TENANT_MOVE_IN').length > 0">
          <router-link
            v-for="contact in getParticipant(data.item.participants, 'PARTICIPANT_TYPE_TENANT_MOVE_IN')"
            :key="contact.contactId"
            :to="redirectToDetailPage(contact.contactId)">
            {{ getContactName(contact.contactData) }}<br>
          </router-link>
        </template>
        <template v-else>
          -
        </template>
      </template>
      <template
        #cell(area)="data">
        {{ getArea(getObjectData(data.item).areas) | formatArea }}
      </template>
      <template #cell(objects)="data">
        {{ getObjectData(data.item).name | displayOptionalValue }}
      </template>
      <template #cell(address)="data">
        <address-text
          v-if="getObjectData(data.item)"
          :address="getObjectData(data.item).address" />
      </template>
      <template #cell(type)="data">
        <template v-if="getObjectData(data.item).category !== 'UNDEFINED_CATEGORY'">
          {{ $t('message.onBoarding.buildings.objects.category.' + getObjectData(data.item).category.toLowerCase()) }}
        </template>
        <template v-else>
          -
        </template>
      </template>
      <template #cell(floor)="data">
        <div class="text-center">
          {{ formatFloor(getObjectData(data.item).decimalFloor) }}
        </div>
      </template>
      <template
        #cell(caution)="data">
        {{ (getTenancyData(data.item) ? getTenancyData(data.item).depositAmount : '-1') | formatPrice }}
      </template>
      <template #cell(handoverDate)="data">
        {{ data.item.scheduledDate | objectToDate }}
        <br>
        {{ data.item.scheduledTime | objectToTime }}
      </template>
      <template #cell(owner)="data">
        {{ data.item.ownerData.name }}
      </template>
      <template #cell(assignee)="data">
        <template v-if="getParticipant(data.item.participants, 'PARTICIPANT_TYPE_ADMINISTRATOR').length > 0">
          <span
            v-for="contact in getParticipant(data.item.participants, 'PARTICIPANT_TYPE_ADMINISTRATOR')"
            :key="contact.contactId">
            {{ getContactName(contact.contactData) }}<br>
          </span>
        </template>
        <template v-else>
          -
        </template>
      </template>
    </b-table>
    <b-pagination
      v-if="filteredHandovers.length > 0"
      v-model="currentPage"
      align="center"
      class="mt-3 mb-3"
      :total-rows="filteredHandovers.length"
      :per-page="nbrPerPage === 'all' ? filteredHandovers.length : nbrPerPage" />
    <b-modal
      :id="'confirmationModal'"
      ref="confirmationModal"
      no-close-on-backdrop
      hide-footer
      :title="$t('message.handoverProtocol.deletion.confirmationTitle')">
      <div
        v-if="loadingDeletion"
        class="row">
        <div class="col text-center">
          <coozzy-spinner class="m-3" />
        </div>
      </div>
      <template v-else>
        <div class="col p-0">
          <p>{{ $t('message.handoverProtocol.deletion.confirmationText') }}</p>
        </div>
        <div class="col p-0">
          <coozzy-button
            size="small"
            class="mb-0 border"
            design="transparent"
            @click="$bvModal.hide('confirmationModal')">
            {{ $t('message.generic.cancel') }}
          </coozzy-button>
          <coozzy-button
            size="small"
            class="float-right mb-0"
            design="green"
            @click="deleteHandover()">
            {{ $t('message.generic.delete') }}
          </coozzy-button>
        </div>
      </template>
    </b-modal>
    <assemble-list-modal
      ref="assembleListModal"
      modal-id="assembleListModal"
      :fields="selectedFields"
      :default-fields="dataFields"
      @save-fields="saveFields" />
  </section>
</template>

<script>

import { formatting } from '@/mixins/formatting'
import { user } from '@/mixins/user'
import AddressText from '@/framework/components/misc/AddressText'
import ObjectApi from '@/misc/apis/ObjectApi'
import ContactApi from '@/misc/apis/ContactApi'
import CoozzyFormSelect from '@/framework/components/form/select/CoozzyFormSelect'
import CoozzyButton from '@/framework/components/button/CoozzyButton'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner'
import CoozzyMultiselect from '@/framework/components/multiselect/CoozzyMultiselect'
import CoozzyAssigneeMultiselect from '@/framework/components/multiselect/CoozzyAssigneeMultiselect'
import Vue from 'vue'
import debounce from 'debounce'
import { tables } from '@/mixins/tables'
import CoozzyFormInput from '@/framework/components/form/input/CoozzyFormInput'
import CoozzyDropdown from '@/framework/components/dropdown/CoozzyDropdown'
import CoozzyDropdownItem from '@/framework/components/dropdown/CoozzyDropdownItem'
import CoozzyFormCheckbox from '@/framework/components/form/checkbox/CoozzyFormCheckbox'
import { routeChecks } from '@/mixins/routeChecks'
import { obj } from '@/mixins/object'
import CoozzyFiltersIcon from '@/framework/components/icons/CoozzyFiltersIcon'
import AssembleListModal from '@/contact/components/AssembleListModal'
import SearchApi from '@/misc/apis/SearchApi'
import { v4 as uuidv4 } from 'uuid'
import HandoverProtocolApi from '@/misc/apis/HandoverProtocolApi'
import CoozzyPageTitle from '@/framework/layout/CoozzyPageTitle.vue'

export default {
  name: 'ProcessHandoverView',
  components: {
    CoozzyPageTitle,
    CoozzyFormCheckbox,
    AddressText,
    CoozzyDropdownItem,
    CoozzyDropdown,
    CoozzyFormInput,
    CoozzyMultiselect,
    CoozzyAssigneeMultiselect,
    CoozzyFormSelect,
    CoozzySpinner,
    CoozzyButton,
    CoozzyFiltersIcon,
    AssembleListModal
  },
  mixins: [user, obj, formatting, tables, routeChecks],
  props: {
    elementSelected: {
      type: String,
      default: ''
    },
    handoverFilters: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      handoverList: [],
      nbrPerPage: 5,
      currentPage: 0,
      isBusy: false,
      selectedEntries: [],
      filteredHandovers: [],
      searchText: '',
      searchFloor: [],
      searchZip: [],
      searchTypes: [],
      searchCity: [],
      searchProperty: [],
      searchBuilding: [],
      searchOwner: [],
      searchTags: [],
      searchAssignee: [],
      searchStatus: [],
      selectedObjectIds: null,
      loading: false,
      loadingDeletion: false,
      isLoaded: false,
      fields: [
        {
          key: 'checkbox',
          label: '',
          thClass: 'th-checkbox',
          tdClass: 'align-middle border-right-0 checkbox-cell'
        }
      ],
      dataFields: [
        {
          key: 'status',
          label: this.$t('message.tableColumnHeadings.status'),
          thClass: 'align-middle alignFoot',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'previousTenancy',
          label: this.$t('message.tableColumnHeadings.previousTenancy'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'newTenancy',
          label: this.$t('message.tableColumnHeadings.newTenancy'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'address',
          label: this.$t('message.tableColumnHeadings.address'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'objects',
          label: this.$t('message.tableColumnHeadings.objects'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'area',
          label: this.$t('message.tableColumnHeadings.area'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'type',
          label: this.$t('message.tableColumnHeadings.type'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'floor',
          label: this.$t('message.tableColumnHeadings.floor'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center',
          selected: true
        },
        {
          key: 'handoverDate',
          label: this.$t('message.tableColumnHeadings.handoverDate'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'caution',
          label: this.$t('message.tableColumnHeadings.caution'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center',
          selected: true
        },
        {
          key: 'assignee',
          label: this.$t('message.tableColumnHeadings.assignee'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'owner',
          label: this.$t('message.tableColumnHeadings.owner'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        }
      ],
      selectedFields: [],
      responsiveTableItems: [
        { totalWithoutVacancy: '', netRent: this.totalNetRent, grossRent: this.totalGrossRent }
      ],
      selectAllVisibleEntries: false,
      selectAllEntries: false,
      allEntries: []
    }
  },
  computed: {
    nbrTotalOfHits() {
      return this.filteredHandovers.length
    },
    getLang() {
      return this.$store.getters['internationalization/getCurrentLanguage']
    },
    isFiltered() {
      return this.searchText !== '' || this.searchFloor.length > 0 || this.searchZip.length > 0 || this.searchTypes.length > 0 || this.searchCity.length > 0 || this.searchProperty.length > 0 || this.searchBuilding.length > 0 || this.searchOwner.length > 0 || this.searchTags.length > 0 || this.searchAssignee.length > 0 || this.searchStatus.length > 0
    },
    notAllStatusNotActivated() {
      const listEntries = this.selectAllEntries ? this.allEntries : this.selectedEntries
      return listEntries.length !== this.ObjectWithCondominiums.length || this.ObjectWithCondominiums.filter(x => this.getStatus(x) !== 'not_activated').length > 0
    },
    availableBuildingOptions() {
      const counts = []
      const current = this
      this.handoverList.forEach(element => {
        const buildingId = element.movingInTenancyReferences.length > 0 ? element.movingInTenancyReferences[0].buildingData.numericId : element.movingOutTenancyReferences[0].buildingData.numericId
        const building = element.movingInTenancyReferences.length > 0 ? element.movingInTenancyReferences[0].buildingData : element.movingOutTenancyReferences[0].buildingData
        const existingCount = counts.filter(e => e.value === buildingId)
        if (existingCount.length === 0) {
          const sameBuilding = current.getBuildings(current.handoverList).filter(b => b === buildingId)
          if (sameBuilding.length > 1) {
            counts.push({
              count: sameBuilding.length,
              value: buildingId,
              label: building?.name || ''
            })
          } else {
            counts.push({
              count: 1,
              value: buildingId,
              label: building?.name || ''
            })
          }
        }
      })
      return counts
    },
    availablePropertyOptions() {
      const counts = []
      const current = this
      this.handoverList.forEach(element => {
        const propertyId = element.movingInTenancyReferences.length > 0 ? element.movingInTenancyReferences[0].propertyData.numericId : element.movingOutTenancyReferences[0].propertyData.numericId
        const property = element.movingInTenancyReferences.length > 0 ? element.movingInTenancyReferences[0].propertyData : element.movingOutTenancyReferences[0].propertyData
        const existingCount = counts.filter(e => e.value === propertyId)
        if (existingCount.length === 0) {
          const sameProperty = current.getProperties(current.handoverList).filter(p => p === propertyId)
          if (sameProperty.length > 1) {
            counts.push({
              count: sameProperty.length,
              value: property,
              label: property?.name || ''
            })
          } else {
            counts.push({
              count: 1,
              value: property,
              label: property?.name || ''
            })
          }
        }
      })
      return counts.filter((v, i, a) => a.findIndex(t => (t.value.numericId === v.value.numericId)) === i)
    },
    availableTypeOptions() {
      const counts = []
      const current = this
      this.handoverList.forEach(element => {
        const category = element.movingInTenancyReferences.length > 0 ? element.movingInTenancyReferences[0].objectData.category : element.movingOutTenancyReferences[0].objectData.category
        const existingCount = counts.filter(e => e.value === category)
        if (existingCount.length === 0) {
          const sameCategory = current.getTypes(current.handoverList).filter(c => c === category)
          if (sameCategory.length > 1) {
            counts.push({
              count: sameCategory.length,
              value: category
            })
          } else {
            counts.push({
              count: 1,
              value: category
            })
          }
        }
      })
      return counts
    },
    availableOwnersOptions() {
      const counts = []
      const current = this
      this.handoverList.forEach(element => {
        const ownerId = element.ownerId
        const owner = element.ownerData

        const existingCount = counts.filter(e => e.value === ownerId)
        if (existingCount.length === 0) {
          const sameOwner = current.handoverList.map(contact => contact.ownerId).filter(z => z === ownerId)
          if (sameOwner.length > 1) {
            counts.push({
              count: sameOwner.length,
              value: ownerId,
              label: owner?.name

            })
          } else {
            counts.push({
              count: 1,
              value: ownerId,
              label: owner?.name
            })
          }
        }
      })
      return counts
    },
    availableCityOptions() {
      const counts = []
      const current = this
      this.handoverList.forEach(element => {
        const city = element.movingInTenancyReferences.length > 0 ? element.movingInTenancyReferences[0].objectData.address.city : element.movingOutTenancyReferences[0].objectData.address.city
        const existingCount = counts.filter(e => e.value === city)
        if (existingCount.length === 0) {
          const sameCity = current.getCities(current.handoverList).filter(c => c === city)
          if (sameCity.length > 1) {
            counts.push({
              count: sameCity.length,
              value: city
            })
          } else {
            counts.push({
              count: 1,
              value: city
            })
          }
        }
      })
      return counts
    },
    availableZipOptions() {
      const counts = []
      const current = this
      this.handoverList.forEach(element => {
        const zip = element.movingInTenancyReferences.length > 0 ? element.movingInTenancyReferences[0].objectData.address.zip : element.movingOutTenancyReferences[0].objectData.address.zip
        const existingCount = counts.filter(e => e.value === zip)
        if (existingCount.length === 0) {
          const sameZip = current.getZips(current.handoverList).filter(c => c === zip)
          if (sameZip.length > 1) {
            counts.push({
              count: sameZip.length,
              value: zip
            })
          } else {
            counts.push({
              count: 1,
              value: zip
            })
          }
        }
      })
      return counts
    },
    availableStatusOptions() {
      const counts = []
      const current = this
      this.handoverList.forEach(element => {
        const status = element.status
        const existingCount = counts.filter(e => e.value === status)
        if (existingCount.length === 0) {
          const sameStatus = current.handoverList.filter(z => z.status === status)
          if (sameStatus.length > 1) {
            counts.push({
              count: sameStatus.length,
              value: status
            })
          } else {
            counts.push({
              count: 1,
              value: status
            })
          }
        }
      })
      return counts
    },
    selectedEntriesIds() {
      return this.selectAllEntries ? this.allEntries.map(x => x.id) : this.selectedEntries.map(x => x.id)
    },
    assigneeFilter() {
      const counts = []
      const current = this
      this.handoverList.forEach(element => {
        const assignee = element.participants.filter(p => p.type === 'PARTICIPANT_TYPE_ADMINISTRATOR')
        if (assignee.length > 0) {
          const existingCount = counts.filter(e => e.value === assignee[0].contactId)
          if (existingCount.length === 0) {
            const sameAssignee = current.handoverList.map(obj => obj.participants.filter(p => p.type === 'PARTICIPANT_TYPE_ADMINISTRATOR')).filter(z => z[0].contactId === assignee[0].contactId)
            if (sameAssignee.length > 1) {
              counts.push({
                count: sameAssignee.length,
                value: assignee[0].contactId,
                label: assignee[0].contactData.firstName + ' ' + assignee[0].contactData.lastName
              })
            } else {
              counts.push({
                count: 1,
                value: assignee[0].contactId,
                label: assignee[0].contactData.firstName + ' ' + assignee[0].contactData.lastName
              })
            }
          }
        }
      })
      return counts
    },
    HandoversPage() {
      if (this.nbrPerPage === 'all') {
        return this.filteredHandovers
      } else {
        return this.filteredHandovers.slice((this.currentPage - 1) * this.nbrPerPage, this.currentPage * this.nbrPerPage)
      }
    },
    selectedEntriesText() {
      const count = this.selectAllEntries && this.selectAllVisibleEntries ? this.nbrTotalOfHits : this.selectedEntries.length
      return this.$t('message.generic.selectedEntries') + ':  ' + count
    },
    isAdmin() {
      if (this.isOwnerModule || this.isAssetModule) {
        return this.$store.getters['user/getCurrentUser']['https://coozzy.ch/roles'].includes('company_admin')
      }
      return true
    }
  },
  watch: {
    nbrPerPage: function (newVal) {
      localStorage.setItem('handoverView-nbrPerPage', newVal)
      this.selectedEntries = []
      this.handleCheckboxesInsideTable(false)
      if (this.$refs.header_checkbox) {
        this.$refs.header_checkbox.checked = false
      }
    },
    currentPage: function () {
      this.selectedEntries = []
      this.handleCheckboxesInsideTable(false)
      this.$refs.header_checkbox.checked = false
    },
    searchStatus: function() {
      if (this.isLoaded) {
        this.$emit('change-filter', this.allFilters(), 'handoverFilters')
      }
    },
    searchAssignee: function() {
      if (this.isLoaded) {
        this.$emit('change-filter', this.allFilters(), 'handoverFilters')
      }
    },
    searchZip: function() {
      if (this.isLoaded) {
        this.$emit('change-filter', this.allFilters(), 'handoverFilters')
      }
    },
    searchFloor: function() {
      if (this.isLoaded) {
        this.$emit('change-filter', this.allFilters(), 'handoverFilters')
      }
    },
    searchTypes: function() {
      if (this.isLoaded) {
        this.$emit('change-filter', this.allFilters(), 'handoverFilters')
      }
    },
    searchText: function() {
      if (this.isLoaded) {
        this.$emit('change-filter', this.allFilters(), 'handoverFilters')
      }
    },
    searchCity: function() {
      if (this.isLoaded) {
        this.$emit('change-filter', this.allFilters(), 'handoverFilters')
      }
    },
    searchProperty: function() {
      if (this.isLoaded) {
        this.$emit('change-filter', this.allFilters(), 'handoverFilters')
      }
    },
    searchBuilding: function() {
      if (this.isLoaded) {
        this.$emit('change-filter', this.allFilters(), 'handoverFilters')
      }
    },
    searchOwner: function() {
      if (this.isLoaded) {
        this.$emit('change-filter', this.allFilters(), 'handoverFilters')
      }
    },
    selectAllEntries: async function (newValue) {
      if (newValue) {
        this.handleCheckboxesInsideTable(newValue)
        this.allEntries = this.filteredHandovers
      }
    }
  },
  created() {
    // Initialize the debounced function in the created lifecycle hook
    this.debouncedFilterObjectsList = debounce(() => {
      this.filterHandoverList()
    }, 300)
  },
  mounted: function () {
    this.$refs.searchText?.setFocusInput()

    if (this.handoverFilters !== '') {
      const handFilter = JSON.parse(this.handoverFilters)
      this.searchZip = handFilter.searchZip
      this.searchFloor = handFilter.searchFloor
      this.searchArea = handFilter.searchArea
      this.searchPreviousTenancy = handFilter.searchPreviousTenancy
      this.searchNewTenancy = handFilter.searchNewTenancy
      this.searchAddress = handFilter.searchAddress
      this.searchCaution = handFilter.searchCaution
      this.searchAssignee = handFilter.searchAssignee
      this.searchOwner = handFilter.searchOwner
      this.searchStatus = handFilter.searchStatus
      this.searchText = handFilter.searchText
    }
    this.$nextTick(function () {
      this.getHandoverList()
    })
    this.nbrPerPage = localStorage.getItem('handoverView-nbrPerPage') ? localStorage.getItem('handoverView-nbrPerPage') === 'all' ? localStorage.getItem('handoverView-nbrPerPage') : parseInt(localStorage.getItem('handoverView-nbrPerPage')) : 5
    this.isLoaded = true
    this.getFieldsFromApi()
  },
  methods: {
    getParticipant(paticipants, type) {
      return paticipants.filter(x => x.type === type)
    },
    getBuildings(handovers) {
      const buildings = []
      handovers.forEach(handover => {
        let building = null
        // Collect from movingInTenancyReferences
        handover.movingInTenancyReferences.forEach(ref => {
          if (ref.buildingData?.numericId) {
            building = ref.buildingData.numericId
          }
        })

        // Collect from movingOutTenancyReferences
        handover.movingOutTenancyReferences.forEach(ref => {
          if (ref.buildingData?.numericId) {
            if (building !== ref.buildingData.numericId) {
              buildings.push(building)
            }
            building = ref.buildingData.numericId
          }
        })
        buildings.push(building)
      })

      return buildings
    },
    getProperties(handovers) {
      const properties = []
      handovers.forEach(handover => {
        let property = null
        // Collect from movingInTenancyReferences
        handover.movingInTenancyReferences.forEach(ref => {
          if (ref.propertyData?.numericId) {
            property = ref.propertyData.numericId
          }
        })

        // Collect from movingOutTenancyReferences
        handover.movingOutTenancyReferences.forEach(ref => {
          if (ref.propertyData?.numericId) {
            if (property !== ref.propertyData.numericId) {
              properties.push(property)
            }
            property = ref.propertyData.numericId
          }
        })
        properties.push(property)
      })
      return properties
    },
    getCities(handovers) {
      const cities = []
      handovers.forEach(handover => {
        let city = null
        // Collect from movingInTenancyReferences
        handover.movingInTenancyReferences.forEach(ref => {
          if (ref.objectData?.address?.city) {
            city = ref.objectData.address.city
          }
        })

        // Collect from movingOutTenancyReferences
        handover.movingOutTenancyReferences.forEach(ref => {
          if (ref.objectData?.address?.city) {
            if (city !== ref.objectData.address.city) {
              cities.push(city)
            }
            city = ref.objectData.address.city
          }
        })
        cities.push(city)
      })

      return cities
    },
    getZips(handovers) {
      const zips = []
      handovers.forEach(handover => {
        let zip = null
        // Collect from movingInTenancyReferences
        handover.movingInTenancyReferences.forEach(ref => {
          if (ref.objectData?.address?.zip) {
            zip = ref.objectData.address.zip
          }
        })

        // Collect from movingOutTenancyReferences
        handover.movingOutTenancyReferences.forEach(ref => {
          if (ref.objectData?.address?.zip) {
            if (zip !== ref.objectData.address.zip) {
              zips.push(zip)
            }
            zip = ref.objectData.address.zip
          }
        })
        zips.push(zip)
      })

      return zips
    },
    getTypes(handovers) {
      const types = []
      handovers.forEach(handover => {
        let type = null
        // Collect from movingInTenancyReferences
        handover.movingInTenancyReferences.forEach(ref => {
          if (ref.objectData?.category) {
            type = ref.objectData.category
          }
        })

        // Collect from movingOutTenancyReferences
        handover.movingOutTenancyReferences.forEach(ref => {
          if (ref.objectData?.category) {
            if (type !== ref.objectData?.category) {
              types.push(type)
            }
            type = ref.objectData.category
          }
        })
        types.push(type)
      })

      return types
    },
    dateToTimestamp(list, type) {
      const timeStampList = []
      if (type === 'start' && list.length > 0) {
        list.forEach(item => {
          const dateArray = item.split('/')
          const date = new Date(dateArray[1], dateArray[0] - 1, 1, 0, 0, 0)
          timeStampList.push(date.getTime() / 1000)
        })
        timeStampList.sort(function(x, y) {
          return x - y
        })
        return timeStampList[0]
      }
      if (type === 'end' && list.length > 0) {
        list.forEach(item => {
          const dateArray = item.split('/')
          const date = new Date(dateArray[1], dateArray[0], 0, 23, 59, 59)
          timeStampList.push((date.getTime() / 1000))
        })
        timeStampList.sort(function(x, y) {
          return y - x
        })
        return timeStampList[0]
      }
      return 0
    },
    getHandoverList() {
      HandoverProtocolApi.listAllProtocols(this.currentPage, this.nbrPerPage, ['PROTOCOL_STATUS_DRAFT', 'PROTOCOL_STATUS_SENT_TO_APP']).then(response => {
        this.handoverList = response.protocols
        this.filterHandoverList()
      }).catch(e => {
        console.log(e)
      })
    },
    getObjectId(handover) {
      // search the objectId from movingInTenancyReferences if exist or movingOutTenancyReferences if exist
      return handover.movingInTenancyReferences.length > 0 ? handover.movingInTenancyReferences[0].objectId : handover.movingOutTenancyReferences[0].objectId
    },
    getObjectData(handover) {
      // search the objectData from movingInTenancyReferences if exist or movingOutTenancyReferences if exist
      return handover.movingInTenancyReferences.length > 0 ? handover.movingInTenancyReferences[0].objectData : handover.movingOutTenancyReferences[0].objectData
    },
    getTenancyData(handover) {
      // search the objectId from movingInTenancyReferences if exist or movingOutTenancyReferences if exist
      return handover.movingInTenancyReferences.length > 0 ? handover.movingInTenancyReferences[0].tenancyData : handover.movingOutTenancyReferences[0].tenancyData
    },
    getFieldsFromApi() {
      // Load fields for Rental Objects
      SearchApi.loadSettings(this.accountId).then(response => {
        const settings = response.tableSettings
        let hasOldList = false
        if (settings.length > 0) {
          settings.forEach(setting => {
            if (setting.columns.length > 0 && setting.table === 'handover_overview') {
              hasOldList = true
              this.loadFields(setting)
            }
          })
        }
        if (hasOldList === false) {
          this.fields.push(...this.dataFields.filter(x => x.selected))
          this.selectedFields = JSON.parse(JSON.stringify(this.dataFields))
        }
      }).catch(e => {
        console.log(e)
      })
    },
    loadFields(setting) {
      const result = this.getFields(this.dataFields, setting)
      this.fields = result?.fields
      this.selectedFields = result?.selectedFields
      this.$refs.HandoverList?.refresh()
    },
    saveFields(data) {
      const columns = data.filter(x => x.selected === true).map(y => y.key)
      // Save Rental Objects Fields
      SearchApi.saveSettings(this.accountId, columns, 'handover_overview').then(response => {
        const setting = response.tableSettings
        if (setting && setting.columns.length > 0) {
          this.loadFields(setting)
        }
        this.$bvModal.hide('assembleListModal')
      })
    },
    openAssembleListModal() {
      this.$bvModal.show('assembleListModal')
    },
    createTicket(type) {
      const defaultAssignee = this.selectedEntries.every((val, i, arr) => this.getNetworkPartners(val.id).length > 0 && this.getNetworkPartners(arr[0].id).length > 0 && this.getNetworkPartners(val.id).find(element => element.scope === 'ASSET_SCOPE') && this.getNetworkPartners(arr[0].id).find(element => element.scope === 'ASSET_SCOPE') && this.getNetworkPartners(val.id).find(element => element.scope === 'ASSET_SCOPE').id === this.getNetworkPartners(arr[0].id).find(element => element.scope === 'ASSET_SCOPE').id)
        ? this.getNetworkPartners(this.selectedEntries[0].id).find(element => element.scope === 'ASSET_SCOPE').id
        : this.selectedEntries.every((val, i, arr) => this.getNetworkPartners(val.id).length > 0 && this.getNetworkPartners(arr[0].id).length > 0 && this.getNetworkPartners(val.id).find(element => element.scope === 'MANAGEMENT_SCOPE') && this.getNetworkPartners(arr[0].id).find(element => element.scope === 'MANAGEMENT_SCOPE') && this.getNetworkPartners(val.id).find(element => element.scope === 'MANAGEMENT_SCOPE').id === this.getNetworkPartners(arr[0].id).find(element => element.scope === 'MANAGEMENT_SCOPE').id) ? this.getNetworkPartners(this.selectedEntries[0].id).find(element => element.scope === 'MANAGEMENT_SCOPE').id : ''
      if (this.isOwnerModule) {
        this.$router.push({ name: 'OwnerTicketCreation', query: { fromBulk: true, ids: this.selectedEntriesIds, defaultAssignee: defaultAssignee, ticketType: type } })
      } else if (this.isAdminModule) {
        this.$router.push({ name: 'AdminTicketCreation', query: { fromBulk: true, ids: this.selectedEntriesIds, defaultAssignee: defaultAssignee, ticketType: type } })
      } else if (this.isAssetModule) {
        this.$router.push({ name: 'AssetTicketCreation', query: { fromBulk: true, ids: this.selectedEntriesIds, defaultAssignee: defaultAssignee, ticketType: type } })
      } else if (this.isAccountingModule) {
        this.$router.push({ name: 'AccountingTicketCreation', query: { fromBulk: true, ids: this.selectedEntriesIds, defaultAssignee: defaultAssignee, ticketType: type } })
      }
    },
    navigateToCreateOrder() {
      const currentRoute = { name: this.$router.currentRoute.name, params: this.$router.currentRoute.params, query: this.$router.currentRoute.query }
      localStorage.setItem('LS_ROUTE_KEY', JSON.stringify(currentRoute))
      const objectIds = this.selectedEntriesIds.join(',')
      if (this.isOwnerModule) {
        this.$router.push({ name: 'OwnerCreateOrderView', query: { objectIds: objectIds, source: 'object' } }, () => {})
      } else if (this.isAdminModule) {
        this.$router.push({ name: 'AdminCreateOrderView', query: { objectIds: objectIds, source: 'object' } }, () => {})
      } else if (this.isAssetModule) {
        this.$router.push({ name: 'AssetCreateOrderView', query: { objectIds: objectIds, source: 'object' } }, () => {})
      } else if (this.isAccountingModule) {
        this.$router.push({ name: 'AccountingCreateOrderView', query: { objectIds: objectIds, source: 'object' } }, () => {})
      }
    },
    sortedLocalFloor(items) {
      if (items) {
        const posFloor = []
        const negFloor = []
        const floorZero = []
        for (const item of items) {
          if (parseInt(item.value) > 0) {
            posFloor.push(item)
          }
          if (parseInt(item.value) < 0) {
            negFloor.push(item)
          }
          if (parseInt(item.value) === 0) {
            floorZero.push(item)
          }
        }
        posFloor.sort(function(a, b) {
          return parseInt(a.value) - parseInt(b.value)
        })
        negFloor.sort(function(a, b) {
          return parseInt(b.value) - parseInt(a.value)
        })
        return floorZero.concat(posFloor).concat(negFloor)
      }
      return 0
    },
    clearAllSelectedObject() {
      this.selectedEntries = []
      this.handleCheckboxesInsideTable(false)
      if (this.$refs.header_checkbox) {
        this.$refs.header_checkbox.checked = false
      }
    },
    serialLetter() {
      let nameOfRoute = ''
      let source = 'tenancy'
      if (this.isOwnerModule) {
        nameOfRoute = 'OwnerSerialLetterProcessView'
      } else if (this.isAdminModule) {
        nameOfRoute = 'AdminSerialLetterProcessView'
      } else if (this.isAssetModule) {
        nameOfRoute = 'AssetSerialLetterProcessView'
      } else {
        nameOfRoute = 'AccountingSerialLetterProcessView'
      }
      if (this.property && this.property.type !== 'PROPERTY_TYPE_CONDOMINIUM_OWNERSHIP') {
        source = 'tenancy'
      } else {
        source = 'condominium'
      }
      const uniqKey = uuidv4()
      localStorage.setItem(uniqKey, this.selectedEntriesIds)
      this.$nextTick(() => {
        this.$router.push({
          name: nameOfRoute,
          query: {
            source: source,
            uuid: uniqKey,
            previousRoute: this.$route.fullPath
          }
        })
      })
    },
    allFilters() {
      return JSON.stringify({
        searchStatus: this.searchStatus,
        searchAssignee: this.searchAssignee,
        searchFloor: this.searchFloor,
        searchTypes: this.searchTypes,
        searchText: this.searchText,
        searchCity: this.searchCity,
        searchZip: this.searchZip,
        searchProperty: this.searchProperty,
        searchBuilding: this.searchBuilding,
        searchOwner: this.searchOwner
      })
    },
    selectObject(objectId, emit = true, listobject) {
      if (objectId) {
        this.clearRowVariants()
        for (let i = 0; i < listobject.length; i++) {
          if (listobject[i].id === objectId) {
            this.currentPage = (Math.floor(i / this.nbrPerPage)) + 1
            listobject[i]._rowVariant = 'table-dynamic-clicked table-b-table-row-selected b-table-row-selected table-primary'
            if (this.$refs['table-groups']) {
              this.$refs['table-groups'].refresh()
            }

            if (emit) {
              this.$emit('selected-object', listobject[i], i)
            }
          }
        }
      }
    },
    clearRowVariants() {
      for (const object of this.handoverList) {
        if (object._rowVariant === 'table-dynamic-clicked table-b-table-row-selected b-table-row-selected table-primary') {
          object._rowVariant = ''
        }
      }
    },
    clearCheckbox() {
      this.selectedEntries = []
      this.$nextTick(() => {
        if (this.$refs.header_checkbox) {
          this.$refs.header_checkbox.checked = false
        }
      })
      this.handleCheckboxesInsideTable(false)
    },
    customLabel(item) {
      return item.label + ' (' + item.count + ')'
    },
    customLabelValue(item) {
      return item.value + ' (' + item.count + ')'
    },
    customLabelAssignee(item) {
      if (item.value === '') {
        return `${this.$t('message.generic.unassigned')} (${item.count})`
      }
      return `${item.label} (${item.count})`
    },
    customLabelCategory(item) {
      return this.$t('message.onBoarding.buildings.objects.category.' + item.value.toLowerCase()) + ' (' + item.count + ')'
    },
    customLabelStatus(status) {
      return this.$t('message.handoverProtocol.status.' + status.value) + ' (' + status.count + ')'
    },
    customLabelFloor(item) {
      return this.formatFloor(item.value) + ' (' + item.count + ')'
    },
    customLabelMarketingStatus(status) {
      return this.$t('message.onBoarding.buildings.objects.inMarketingStatus.' + status.value) + ' (' + status.count + ')'
    },
    deleteHandover() {
      const promises = []
      this.selectedEntries.forEach(element => {
        promises.push(HandoverProtocolApi.handoverProtocolServiceDelete(element.id))
      })
      Promise.all(promises)
        .then(() => {
          Vue.toasted.show(this.$t('message.successMessages.handoverProtocolDelete').toString(), { type: 'success' })
          this.clearCheckbox()
          this.getHandoverList()
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.deleteErrors.handoverProtocolDelete').toString(), { type: 'error' })
        })
        .finally(() => {
          if (this.$refs.confirmationModal) {
            this.$refs.confirmationModal.hide()
          }
        })
    },
    filterHandoverList() {
      this.$nextTick(function () {
        // sort by status in "PROTOCOL_STATUS_DRAFT" then “PROTOCOL_STATUS_SENT_TO_APP” and after handover date / the next handover should be at the top
        this.filteredHandovers = this.handoverList
          .filter(item => {
            const previousTenancy = this.getParticipant(item.participants, 'PARTICIPANT_TYPE_TENANT_MOVE_OUT').map(x => x.contactData) || []
            const newTenancy = this.getParticipant(item.participants, 'PARTICIPANT_TYPE_TENANT_MOVE_IN').map(x => x.contactData) || []
            let cdtSearch
            let cdtSearchPreviousTenant = []
            let cdtSearchNewTenant = []
            if (previousTenancy.length > 0) {
              const currentTenancyTenant = previousTenancy.map((element) => this.getContactName(element))
              cdtSearchPreviousTenant = currentTenancyTenant.map((element) => element.toLowerCase().indexOf(this.searchText.toLowerCase()) !== -1)
            }
            if (newTenancy.length > 0) {
              const newTenancyTenant = newTenancy.map((element) => this.getContactName(element))
              cdtSearchNewTenant = newTenancyTenant.map((element) => element.toLowerCase().indexOf(this.searchText.toLowerCase()) !== -1)
            }
            // search with search text on tenant names and object name and object id and object address
            cdtSearch = this.getObjectData(item)?.name.toLowerCase().includes(this.searchText.toLowerCase()) || cdtSearchNewTenant.includes(true) || cdtSearchPreviousTenant.includes(true) || this.getObjectData(item)?.numericId.toString().includes(this.searchText.toLowerCase()) || this.getObjectData(item)?.address?.street.toLowerCase().includes(this.searchText.toLowerCase()) || this.getObjectData(item)?.address?.city.toLowerCase().includes(this.searchText.toLowerCase()) || this.getObjectData(item)?.address?.zip.toString().includes(this.searchText.toLowerCase())
            const cdtStatus = (this.searchStatus.map(x => x.value).includes(item.status)) || this.searchStatus.length === 0
            const propertyData = item.movingOutTenancyReferences.length > 0 ? item.movingOutTenancyReferences.map(x => x.propertyData) : item.movingInTenancyReferences.map(x => x.propertyData)
            console.log('propertyData', propertyData, this.searchProperty.flatMap(x => x.value))
            const cdtProperty = (this.searchProperty.flatMap(x => x.value).map(x => x.numericId).includes(propertyData[0].numericId)) || this.searchProperty.length === 0
            const buildingData = item.movingOutTenancyReferences.length > 0 ? item.movingOutTenancyReferences.map(x => x.buildingData) : item.movingInTenancyReferences.map(x => x.buildingData)
            const cdtBuilding = (this.searchBuilding.flatMap(x => x.value).includes(buildingData[0].numericId)) || this.searchBuilding.length === 0
            const cdtZip = (this.searchZip.map(x => x.value).includes(this.getObjectData(item)?.address?.zip)) || this.searchZip.length === 0
            const cdtCity = (this.searchCity.map(x => x.value).includes(this.getObjectData(item)?.address?.city)) || this.searchCity.length === 0
            const cdtType = (this.searchTypes.map(x => x.value).includes(this.getObjectData(item)?.category)) || this.searchTypes.length === 0
            const cdtAssignee = (this.searchAssignee.map(x => x.value).includes(this.getParticipant(item.participants, 'PARTICIPANT_TYPE_ADMINISTRATOR').map(x => x.contactId)[0])) || this.searchAssignee.length === 0
            const cdtOwner = this.searchOwner.map(x => x.value).includes(item.ownerId) || this.searchOwner.length === 0
            return cdtStatus && cdtSearch && cdtProperty && cdtBuilding && cdtZip && cdtCity && cdtType && cdtAssignee && cdtOwner
        }).sort((a, b) => {
          // scheduledDate DESC
          if (a.status === 'PROTOCOL_STATUS_DRAFT' && b.status !== 'PROTOCOL_STATUS_DRAFT') {
            return -1
          }
          if (a.status !== 'PROTOCOL_STATUS_DRAFT' && b.status === 'PROTOCOL_STATUS_DRAFT') {
            return 1
          }
          if (a.status === 'PROTOCOL_STATUS_SENT_TO_APP' && b.status !== 'PROTOCOL_STATUS_SENT_TO_APP') {
            return -1
          }
          if (a.status !== 'PROTOCOL_STATUS_SENT_TO_APP' && b.status === 'PROTOCOL_STATUS_SENT_TO_APP') {
            return 1
          }
          if (a.status === 'PROTOCOL_STATUS_DRAFT' && b.status === 'PROTOCOL_STATUS_DRAFT') {
            return new Date(b.scheduledDate) - new Date(a.scheduledDate)
          }
          if (a.status === 'PROTOCOL_STATUS_SENT_TO_APP' && b.status === 'PROTOCOL_STATUS_SENT_TO_APP') {
            return new Date(b.scheduledDate) - new Date(a.scheduledDate)
          }
          return 0
        })
      })
    },
    getContactName(contactData) {
      return contactData.firstName + ' ' + contactData.lastName
    },
    handleCheckboxesInsideTable(value) {
      this.$nextTick(() => {
        if (this.$refs['table-groups'] && this.$refs['table-groups'].$children[1]) {
          const allCheckboxesComponents = this.$refs['table-groups'].$children[1].$children
          this.handleCheckbox(allCheckboxesComponents, value)
          this.selectAllVisibleEntries = value
          if (value === false) {
            this.selectAllEntries = false
          }
        }
      })
    },
    getArea(area) {
      return area.effectiveArea !== -1 ? area.effectiveArea : area.livingArea ? area.livingArea : ''
    },
    itemSelected(item, checked) {
      if (checked) {
        this.selectedEntries.push(item)
        if (this.selectedEntries.length === this.nbrPerPage) {
          this.selectAllVisibleEntries = true
        }
      } else {
        this.selectedEntries = this.selectedEntries.filter(entry => entry.id !== item.id)
        this.selectAllEntries = false
        this.selectAllVisibleEntries = false
      }
    },
    handoverClicked(item) {
      console.log(item)
      // redirect to edit handover
      if (item) {
        let route = null
        if (this.isOwnerModule) {
          route = {
            name: 'OwnerEditHandover',
            params: { objectId: this.getObjectId(item), handoverId: item.id }
          }
        } else if (this.isAdminModule) {
          route = {
            name: 'AdminEditHandover',
            params: { objectId: this.getObjectId(item), handoverId: item.id }
          }
        } else if (this.isAssetModule) {
          route = {
            name: 'AssetEditHandover',
            params: { objectId: this.getObjectId(item), handoverId: item.id }
          }
        } else if (this.isAccountingModule) {
          route = {
            name: 'AccountingEditHandover',
            params: { objectId: this.getObjectId(item), handoverId: item.id }
          }
        }
        console.log(route)
        this.$router.push(route)
      }
    },
    onMiddlelicked(item, index, event) {
      const element = event.srcElement
      if (element.tagName !== 'A') {
        if (item) {
          let route = null
          if (this.isOwnerModule) {
            route = this.$router.resolve({
              name: 'OwnerEditHandover',
              params: { objectId: this.getObjectId(item), handoverId: item.id }
            })
          } else if (this.isAdminModule) {
            route = this.$router.resolve({
              name: 'AdminEditHandover',
              params: { objectId: this.getObjectId(item), handoverId: item.id }
            })
          } else if (this.isAssetModule) {
            route = this.$router.resolve({
              name: 'AssetEditHandover',
              params: { objectId: this.getObjectId(item), handoverId: item.id }
            })
          } else if (this.isAccountingModule) {
            route = this.$router.resolve({
              name: 'AccountingEditHandover',
              params: { objectId: this.getObjectId(item), handoverId: item.id }
            })
          }
          window.open(route?.href)
        }
      }
    },
    openConfirmationModal() {
      this.$refs.confirmationModal.show()
    },
    redirectToDetailPage(contactId) {
      if (contactId !== null) {
        if (this.isOwnerModule) {
          return { name: 'OwnerContactDetailsView', params: { id: contactId } }
        } else if (this.isAssetModule) {
          return { name: 'AssetContactDetailsView', params: { id: contactId } }
        } else if (this.isAccountingModule) {
          return { name: 'AccountingContactDetailsView', params: { id: contactId } }
        } else {
          return { name: 'AdminContactDetailsView', params: { id: contactId } }
        }
      }
    },
    searchInputText() {
      this.debouncedFilterObjectsList()
    },
    initFilter() {
      this.searchText = ''
      this.searchFloor = []
      this.searchZip = []
      this.searchAssignee = []
      this.searchStatus = []
      this.searchCity = []
      this.searchProperty = []
      this.searchBuilding = []
      this.searchOwner = []
      this.searchTypes = []
      this.filterHandoverList()
    },
    setAssignee(newEmployee) {
      this.$refs.setAssigneeModal.setModalDisabled = true
      if (this.isOwnerModule) {
        ObjectApi.setOwnerAssignee(newEmployee ? newEmployee.id : null, this.selectedObjectIds)
          .then(() => {
            this.$refs.setAssigneeModal.setModalShow = false
            this.$refs.setAssigneeModal.setModalDisabled = false
            const indexObject = this.filteredHandovers.findIndex(e => e.id === this.selectedObjectIds[0])
            if (indexObject !== -1) {
              if (!this.filteredHandovers[indexObject].contacts) {
                this.filteredHandovers[indexObject].contacts = {
                  contactIdAssigneeAdministration: '',
                  contactIdAssigneeMarketing: '',
                  contactIdViewingAppointment: '',
                  contactIdAssigneeAsset: '',
                  contactIdAssigneeAccounting: ''
                }
              }
              if (newEmployee) {
                this.filteredHandovers[indexObject].contacts.contactIdAssigneeOwner = newEmployee.id
              } else {
                this.filteredHandovers[indexObject].contacts.contactIdAssigneeOwner = ''
              }
            }
            this.clearCheckbox()
            Vue.toasted.show(this.$t('message.successMessages.setMarketingObjectAssignee'), { type: 'success' })
          })
          .catch(e => {
            console.log(e)
            Vue.toasted.show(this.$t('message.savingErrors.setMarketingObjectAssignee'), { type: 'error' })
          })
      } else if (this.isAssetModule) {
        ObjectApi.setAssetAssignee(newEmployee ? newEmployee.id : null, this.selectedObjectIds)
          .then(() => {
            this.$refs.setAssigneeModal.setModalShow = false
            this.$refs.setAssigneeModal.setModalDisabled = false
            const indexObject = this.filteredHandovers.findIndex(e => e.id === this.selectedObjectIds[0])
            if (indexObject !== -1) {
              if (!this.filteredHandovers[indexObject].contacts) {
                this.filteredHandovers[indexObject].contacts = {
                  contactIdAssigneeAdministration: '',
                  contactIdAssigneeMarketing: '',
                  contactIdViewingAppointment: '',
                  contactIdAssigneeAsset: '',
                  contactIdAssigneeAccounting: ''
                }
              }
              if (newEmployee) {
                this.filteredHandovers[indexObject].contacts.contactIdAssigneeAsset = newEmployee.id
              } else {
                this.filteredHandovers[indexObject].contacts.contactIdAssigneeAsset = ''
              }
            }
            this.clearCheckbox()
            Vue.toasted.show(this.$t('message.successMessages.setMarketingObjectAssignee'), { type: 'success' })
          })
          .catch(e => {
            console.log(e)
            Vue.toasted.show(this.$t('message.savingErrors.setMarketingObjectAssignee'), { type: 'error' })
          })
      } else if (this.isAccountingModule) {
        ObjectApi.setAccountingAssignee(newEmployee ? newEmployee.id : null, this.selectedObjectIds)
          .then(() => {
            this.$refs.setAssigneeModal.setModalShow = false
            this.$refs.setAssigneeModal.setModalDisabled = false
            const indexObject = this.filteredHandovers.findIndex(e => e.id === this.selectedObjectIds[0])
            if (indexObject !== -1) {
              if (!this.filteredHandovers[indexObject].contacts) {
                this.filteredHandovers[indexObject].contacts = {
                  contactIdAssigneeAdministration: '',
                  contactIdAssigneeMarketing: '',
                  contactIdViewingAppointment: '',
                  contactIdAssigneeAsset: '',
                  contactIdAssigneeAccounting: ''
                }
              }
              if (newEmployee) {
                this.filteredHandovers[indexObject].contacts.contactIdAssigneeAccounting = newEmployee.id
              } else {
                this.filteredHandovers[indexObject].contacts.contactIdAssigneeAccounting = ''
              }
            }
            this.clearCheckbox()
            Vue.toasted.show(this.$t('message.successMessages.setMarketingObjectAssignee'), { type: 'success' })
          })
          .catch(e => {
            console.log(e)
            Vue.toasted.show(this.$t('message.savingErrors.setMarketingObjectAssignee'), { type: 'error' })
          })
      } else {
        ObjectApi.setAdminAssignee(newEmployee ? newEmployee.id : null, this.selectedObjectIds)
          .then(() => {
            this.$refs.setAssigneeModal.setModalShow = false
            this.$refs.setAssigneeModal.setModalDisabled = false
            const indexObject = this.filteredHandovers.findIndex(e => e.id === this.selectedObjectIds[0])
            if (indexObject !== -1) {
              if (!this.filteredHandovers[indexObject].contacts) {
                this.filteredHandovers[indexObject].contacts = {
                  contactIdAssigneeAdministration: '',
                  contactIdAssigneeMarketing: '',
                  contactIdViewingAppointment: '',
                  contactIdAssigneeAsset: '',
                  contactIdAssigneeAccounting: ''
                }
              }
              if (newEmployee) {
                this.filteredHandovers[indexObject].contacts.contactIdAssigneeAdministration = newEmployee.id
              } else {
                this.filteredHandovers[indexObject].contacts.contactIdAssigneeAdministration = ''
              }
            }
            this.clearCheckbox()
            Vue.toasted.show(this.$t('message.successMessages.setMarketingObjectAssignee'), { type: 'success' })
          })
          .catch(e => {
            console.log(e)
            Vue.toasted.show(this.$t('message.savingErrors.setMarketingObjectAssignee'), { type: 'error' })
          })
      }
    },
    availableContact(search) {
      ContactApi.contactResolve(search)
        .then(response => {
          const contacts = response.persons.concat(response.companies)
          const usedContacts = this.objectOwnerContacts.concat(contacts)
          this.setObjectOwnerContacts(usedContacts)
        })
        .catch(e => {
          console.log(e)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@media (min-width: 768px) {
  #responsiveFooterTable {
    display: none;
  }
}
:deep(tfoot tr) {
  position: relative;
}
:deep(tfoot .alignFoot) {
  height: 100%;
  position: absolute;
  box-shadow: none;
  display: flex;
  align-items: center;
}
:deep(#date-picker-container) {
  position: relative;
  #date-picker-input {
    height: 30px;
    position: absolute;
    width: 100%;
    opacity: 0;
    top:0;
    z-index: 1;
  }
}
.tooltip.b-tooltip {
  z-index: 2003;
}
.color-box-green {
  background-color: #7ed957;
}
.color-box-yellow {
  background-color: #ffde59;
}
</style>
