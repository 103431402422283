export default {
  namespaced: true,
  state: {
    digitalDepositEnabled: false
  },
  getters: {
    getDigitalDepositStatus: (state) => {
      return state.digitalDepositEnabled
    }
  },
  mutations: {
    setDigitalDepositStatus: (state, value) => {
      state.digitalDepositEnabled = value
    }
  },
  actions: {
    setDigitalDepositStatus: ({ commit }, value) => {
      commit('setDigitalDepositStatus', value)
    }
  }
}
