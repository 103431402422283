// @ts-nocheck
import network from './network'
import networkTS from '@/misc/apis/networkTS'
import { ReportingSetDefaultReportingsRequest, ReportingReportingUrl } from '@/types/api'

export default {
  getReportingUrls(accountId) {
    return network.get(`/web/reporting/getReportingUrls/${accountId}`)
      .then(response => {
        return response.data
      })
  },
  setReportingUrls(accountId, urls) {
    return network.post(`/web/reporting/setReportingUrls/${accountId}`, urls)
      .then(response => {
        return response.data
      })
  },
  getBuildingRentReports(buildingIds) {
    return network.post('/web/reporting/getBuildingRentReports', {
      buildingIds: buildingIds
    })
      .then(response => {
        return response.data
      })
  },
  async getDefaultReportings() {
    return networkTS.reportingServiceGetDefaultReportings().then(response => {
      return response.data
    })
  },
  async setDefaultReportings(DefaultReportings: ReportingSetDefaultReportingsRequest) {
    return networkTS.reportingServiceSetDefaultReportings(DefaultReportings).then(response => {
      return response.data
    })
  },
  async getAdditionalReportings(accountId: string) {
    return networkTS.reportingServiceGetAdditionalReportings(accountId).then(response => {
      return response.data
    })
  },
  async setAdditionalReportings(accountId: string, additionalReportings: ReportingReportingUrl[]) {
    return networkTS.reportingServiceSetAdditionalReportings(accountId, { additionalReportings: additionalReportings }).then(response => {
      return response.data
    })
  },
  async getMyReportings() {
    return networkTS.reportingServiceGetMyReportings().then(response => {
      return response.data
    })
  }
}
