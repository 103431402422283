<template>
  <section :class="readOnly ? '' : 'no-alternate-backgrounds'">
    <div
      v-b-toggle.coozzy-card-advertising
      class="collapse-title">
      <h5 class="cursor-pointer">
        {{ $t('message.onBoarding.createObjectView.advertising') }}
      </h5>
    </div>
    <div class="col-12 pl-0">
      <b-collapse
        :id="'coozzy-card-advertising'"
        ref="collapse"
        :visible="expandByDefault || openedCollapses.includes('coozzy-card-advertising')"
        class="row">
        <div class="col">
          <div class="row details-row-bg-1 mt-3">
            <div class="col-12">
              <coozzy-form-input
                v-model="title"
                :is-read-only="readOnly"
                :limit="titleLength"
                :name="$t('message.onBoarding.buildings.objects.title') + (isMarketingPath ? '* ' + $t('message.onBoarding.buildings.objects.titleMaxLength') : '')"
                :state="isMarketingPath && $v.title.$dirty && $v.title.$error ? false : null"
                @input="checkLenght()" />
            </div>
          </div>
          <div class="row details-row-bg-2">
            <div class="col-12 mt-3">
              <coozzy-form-textarea
                ref="description"
                v-model="description"
                :check-valide="isMarketingPath && $v.description.$dirty && $v.description.$error ? 'is-invalid' : ''"
                :initial="description"
                :is-read-only="readOnly"
                :placeholder="$t('message.advertisement.description')"
                :rows="5"
                :value-text-area="description"
                name="description">
                {{ $t('message.onBoarding.buildings.objects.description') }} {{ (isMarketingPath ? '*' : '') }}
              </coozzy-form-textarea>
            </div>
          </div>
          <div class="row details-row-bg-1">
            <div
              v-if="!readOnly"
              class="d-flex col-12 mb-3">
              <coozzy-form-checkbox
                id="publishAddress"
                v-model="publishAddress"
                :initial="publishAddress"
                class="align-self-center mt-3">
                {{ $t('message.onBoarding.buildings.objects.publishAddress') }}
              </coozzy-form-checkbox>
            </div>
            <div
              v-else
              class="col-12 my-1">
              <label>
                {{ $t('message.onBoarding.buildings.objects.publishAddress') }}
              </label>
              <span class="show-data">
                {{ publishAddress ? $t('message.generic.selectField.yes') : $t('message.generic.selectField.no') }}
              </span>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col">
              <h5 class="cursor-pointer">
                {{ $t('message.onBoarding.buildings.objects.pricing.titleMarketing') }}
              </h5>
            </div>
          </div>
          <div class="row details-row-bg-1">
            <div class="col-3">
              <label for="currency">{{ $t('message.onBoarding.buildings.objects.pricing.currency') }}
                {{ (isMarketingPath ? '*' : '') }}</label>
              <coozzy-form-select
                v-if="!readOnly"
                id="currency"
                v-model="currency"
                :state="isMarketingPath && $v.currency.$dirty && $v.currency.$error ? false : null">
                <option value="CHF">
                  CHF
                </option>
              </coozzy-form-select>
              <span
                v-else
                class="show-data">{{ currency === '' || currency === null ? '-' : currency }}</span>
            </div>
            <div class="col-3">
              <label for="unit">{{ $t('message.onBoarding.buildings.objects.pricing.unit.title') }}</label>
              <coozzy-form-select
                v-if="!readOnly"
                id="unit"
                v-model="unit">
                <option value="ON_REQUEST">
                  {{ $t('message.onBoarding.buildings.objects.pricing.unit.on_request') }}
                </option>
                <option value="FIXED">
                  {{ $t('message.onBoarding.buildings.objects.pricing.unit.fixed') }}
                </option>
                <option value="M2_YEAR">
                  {{ $t('message.onBoarding.buildings.objects.pricing.unit.m2_year') }}
                </option>
                <option value="M2_MONTH">
                  {{ $t('message.onBoarding.buildings.objects.pricing.unit.m2_month') }}
                </option>
              </coozzy-form-select>
              <span
                v-else
                class="show-data">{{
                  unit === '' || unit === null ? '-' : $t(`message.onBoarding.buildings.objects.pricing.unit.${unit.toLowerCase()}`)
                }}</span>
            </div>
          </div>
          <div
            v-if="type === 'RENT' && unit !== 'ON_REQUEST'"
            class="row details-row-bg-2">
            <div class="col-3 mt-3">
              <coozzy-form-input-numeric
                v-model="coldRent"
                :check-valide="$v.coldRent.$dirty && $v.coldRent.$error ? 'is-invalid' : ''"
                :filter="'formatNumber'"
                :is-decimal="true"
                :is-read-only="readOnly"
                :name="$t('message.onBoarding.buildings.objects.pricing.coldRent') + (isMarketingPath ? '*' : '')"
                min="0"
                type="number" />
            </div>
            <div class="col-3 mt-3">
              <coozzy-form-input-numeric
                v-model="sumAdditionalHeating"
                :check-valide="$v.sumAdditionalHeating.$dirty && $v.sumAdditionalHeating.$error ? 'is-invalid' : ''"
                :disabled="canEditAdditionnalCosts"
                :filter="'formatNumber'"
                :is-decimal="true"
                :is-read-only="readOnly"
                :name="$t('message.onBoarding.buildings.objects.pricing.additionalAndHeatingCostsAdvertising')"
                :tooltip-text="canEditAdditionnalCosts ? $t('message.onBoarding.buildings.objects.pricing.additionalAndHeatingCostsTooltip') : ''"
                min="0"
                type="number" />
            </div>
            <div class="col-3 mt-3">
              <coozzy-form-input-numeric
                v-model="grossRent"
                :disabled="true"
                :filter="'formatNumber'"
                :is-decimal="true"
                :is-read-only="readOnly"
                :name="$t('message.tableColumnHeadings.grossRent')"
                min="0"
                type="number" />
            </div>
          </div>
          <div
            v-if="type === 'SALE' && unit !== 'ON_REQUEST'"
            class="row details-row-bg-2">
            <div class="col-3 mt-3">
              <coozzy-form-input-numeric
                v-model="sellingPrice"
                :check-valide="$v.sellingPrice.$dirty && $v.sellingPrice.$error ? 'is-invalid' : ''"
                :filter="'formatNumber'"
                :is-decimal="true"
                :is-read-only="readOnly"
                :name="$t('message.onBoarding.buildings.objects.pricing.sellingPrice')"
                min="0"
                type="number" />
            </div>
            <div class="col-3 mt-3">
              <coozzy-form-input-numeric
                v-model="sellingPriceM2"
                :check-valide="$v.sellingPriceM2.$dirty && $v.sellingPriceM2.$error ? 'is-invalid' : ''"
                :filter="'formatNumber'"
                :is-decimal="true"
                :is-read-only="readOnly"
                :name="$t('message.onBoarding.buildings.objects.pricing.sellingPriceM2')"
                min="0"
                type="number" />
            </div>
          </div>
          <div
            v-if="unit !== 'ON_REQUEST'"
            class="row mt-3">
            <div class="col-12">
              <h5
                v-if="advancePaymentValues.length > 0 || flatRateValues.length > 0 || additionalValues.length > 0"
                class="mt-3 mb-1">
                {{ $t('message.onBoarding.buildings.objects.tenancies.rentComponents') }}
              </h5>
            </div>
            <div class="col-12">
              <div
                v-for="(j, k) in getModulo(advancePaymentValues)"
                :key="k">
                <div
                  :class="j % 2 || !readOnly ? 'details-row-bg-2' : 'details-row-bg-1'"
                  class="row">
                  <div
                    v-for="(v, i) in advancePaymentValues.slice(j * 4, j * 4 + 4)"
                    :key="'advancePaymentValues'+i"
                    class="col-sm-12 col-md-3">
                    <coozzy-form-input-numeric
                      :filter="'formatPriceDecimal'"
                      :is-decimal="true"
                      :is-read-only="readOnly"
                      :name="$t('message.propertiesView.rentComponents.advancePaymentRentComponents.' + v.type) + ' (' +$t('message.propertiesView.rentComponents.advancePaymentRentComponents.advancePayment').charAt(0).toUpperCase() + ')'"
                      :value="v.value !== -1 ? v.value : null"
                      min="0"
                      type="number"
                      @input="advancePaymentValueChanged(v, $event)" />
                  </div>
                </div>
              </div>
              <div
                v-for="(y, z) in getModulo(flatRateValues)"
                :key="'globalflatRateValues'+z">
                <div
                  :class="(y + getModulo(advancePaymentValues).length) % 2 || !readOnly ? 'details-row-bg-2' : 'details-row-bg-1'"
                  class="row">
                  <div
                    v-for="(v, i) in flatRateValues.slice(y * 4, y * 4 + 4)"
                    :key="'flatRateValues'+i"
                    class="col-sm-12 col-md-3">
                    <coozzy-form-input-numeric
                      :filter="'formatPriceDecimal'"
                      :is-decimal="true"
                      :is-read-only="readOnly"
                      :name="$t('message.propertiesView.rentComponents.flatRateRentComponents.' + v.type) + ' (' +$t('message.propertiesView.rentComponents.flatRateRentComponents.flatRate').charAt(0).toUpperCase() + ')'"
                      :value="v.value !== -1 ? v.value : null"
                      min="0"
                      type="number"
                      @input="flatRateValueChanged(v, $event)" />
                  </div>
                </div>
              </div>
              <div
                v-for="(y, z) in getModulo(additionalValues)"
                :key="'globaladditionalValues'+z">
                <div
                  :class="(y + (getModulo(advancePaymentValues).length + getModulo(flatRateValues).length)) % 2 || !readOnly ? 'details-row-bg-2' : 'details-row-bg-1'"
                  class="row">
                  <div
                    v-for="(v, i) in additionalValues.slice(y * 4, y * 4 + 4)"
                    :key="'additionalValues'+i"
                    class="col-sm-12 col-md-3">
                    <coozzy-form-input-numeric
                      :filter="'formatPriceDecimal'"
                      :is-decimal="true"
                      :is-read-only="readOnly"
                      :name="$t('message.propertiesView.rentComponents.additionalRentComponents.' + v.type) + ' (' +$t('message.propertiesView.rentComponents.additionalRentComponents.title').charAt(0).toUpperCase() + ')'"
                      :value="v.value !== -1 ? v.type === 'ADDITIONAL_RENT_COMPONENT_RENT_REDUCTION' ? -Math.abs(v.value) : v.value : null"
                      max="0"
                      type="number"
                      @input="additionalValuesChanged(v, $event)" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col">
              <h5 class="cursor-pointer">
                {{ $t('message.onBoarding.buildings.objects.availability.title') }}
              </h5>
            </div>
          </div>
          <div class="row details-row-bg-1">
            <div class="col-3">
              <label for="unit">{{ $t('message.onBoarding.buildings.objects.availability.type') }}</label>
              <coozzy-form-select
                v-if="!readOnly"
                id="type"
                v-model="availabilityType">
                <option value="IMMEDIATELY">
                  {{ $t('message.onBoarding.buildings.objects.availability.IMMEDIATELY') }}
                </option>
                <option value="BY_ARRANGEMENT">
                  {{ $t('message.onBoarding.buildings.objects.availability.BY_ARRANGEMENT') }}
                </option>
                <option value="FROM_DATE">
                  {{ $t('message.onBoarding.buildings.objects.availability.FROM_DATE') }}
                </option>
                <option value="TEMPORARY_LIVING">
                  {{ $t('message.onBoarding.buildings.objects.availability.TEMPORARY_LIVING') }}
                </option>
              </coozzy-form-select>
              <span
                v-else
                class="show-data">{{
                  availabilityType === null ? '-' : $t('message.onBoarding.buildings.objects.availability.' + availabilityType)
                }}</span>
            </div>
            <div
              v-show="availableType === 'FROM_DATE' || availableType === 'TEMPORARY_LIVING'"
              class="col-3">
              <coozzy-form-input
                v-model="availability.from"
                :filter="'formatDate'"
                :is-read-only="readOnly"
                :name="$t('message.onBoarding.buildings.objects.availability.By')"
                type="date"
                @change="dateToTimestampFrom()" />
            </div>
            <div
              v-show="availableType === 'TEMPORARY_LIVING'"
              class="col-3">
              <coozzy-form-input
                v-model="availability.to"
                :filter="'formatDate'"
                :is-read-only="readOnly"
                :name="$t('message.onBoarding.buildings.objects.availability.until')"
                type="date"
                @blur="dateToTimestampTo()" />
            </div>
          </div>
          <div class="row mt-3">
            <div class="col">
              <h5 class="cursor-pointer">
                {{ $t('message.onBoarding.buildings.objects.parkingSpaces.title') }}
              </h5>
            </div>
          </div>
          <div class="row">
            <div
              v-if="parking.length === 0"
              class="col-12">
              {{ $t('message.generic.noParkings') }}
            </div>
            <div
              v-for="(park, index) in parking"
              :key="index"
              class="col-12">
              <div class="row">
                <div class="col-3">
                  <label :for="`parkingType-${index}`">
                    {{ $t('message.onBoarding.buildings.objects.parkingSpaces.parkingType.title') }}
                  </label>
                  <coozzy-form-select
                    v-if="!readOnly"
                    :id="`parkingType-${index}`"
                    v-model="park.parkingType">
                    <option value="UNDEFINED_PARKING_TYPE">
                      -
                    </option>
                    <option
                      v-for="(parkingTypeValue, parkingTypeIndex) in availableParkingType"
                      :key="parkingTypeIndex"
                      :value="parkingTypeValue">
                      {{ $t('message.onBoarding.buildings.objects.parkingSpaces.parkingType.' + parkingTypeValue) }}
                    </option>
                  </coozzy-form-select>
                  <span
                    v-else
                    class="show-data">
                    {{
                      park.parkingType === 'UNDEFINED_PARKING_TYPE' ? '-' : $t('message.onBoarding.buildings.objects.parkingSpaces.parkingType.' + park.parkingType)
                    }}
                  </span>
                </div>
                <div class="col-3">
                  <coozzy-form-input-numeric
                    v-model="park.costs"
                    :filter="'formatPrice'"
                    :is-decimal="true"
                    :is-read-only="readOnly"
                    :name="$t('message.onBoarding.buildings.objects.parkingSpaces.costs')"
                    min="0"
                    placeholder="CHF"
                    type="number" />
                </div>
                <div class="col-3">
                  <coozzy-button
                    v-if="!readOnly"
                    class="mt-4"
                    design="red"
                    @click="removeParking(index)">
                    {{ $t('message.onBoarding.buildings.objects.parkingSpaces.removeParking') }}
                  </coozzy-button>
                </div>
              </div>
            </div>
            <div
              v-if="!readOnly"
              class="col-sm-12 col-md-12 mt-1">
              <coozzy-button
                class="col-sm-4 col-lg-2"
                design="prop-green"
                @click="addParking()">
                {{ $t('message.onBoarding.buildings.objects.parkingSpaces.addParking') }}
              </coozzy-button>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <hr>
            </div>
            <div class="col-12">
              <h6 class="d-inline-block font-weight-bold mt-3">
                {{ $t('message.generic.pictures') }}
              </h6>
            </div>
            <coozzy-spinner v-if="selectedObjectImageMedia === null" />
            <template v-else>
              <div
                v-if="selectedObjectImageMedia.length === 0"
                class="col-sm-12 col-md-3">
                {{ $t('message.generic.noPictures') }}
              </div>
              <draggable
                v-model="selectedObjectImageMedia"
                :disabled="readOnly"
                class="col-sm-12 col-md-12 row"
                @end="endMoving">
                <div
                  v-for="(image, index) in selectedObjectImageMedia"
                  :key="image.media.id"
                  class="col-sm-12 col-md-2 mt-3">
                  <div
                    class="square">
                    <div
                      :class="['object-image-remove-container', 'cursor-pointer', 'hover', readOnly ? 'd-none' : '']"
                      @click="removeImageClicked(image.media)">
                      <coozzy-close-icon class="object-image-remove bg" />
                      <coozzy-close-icon class="object-image-remove" />
                    </div>
                    <coozzy-thumbnail
                      :key="image.media.id"
                      :original="image.media.url"
                      :size-restriction="false"
                      :src="getMediaUrl(image.media)"
                      class="object-image cursor-pointer"
                      @imageClicked="imageClicked(index)" />
                  </div>
                  <coozzy-form-input
                    v-model="image.title"
                    :is-read-only="readOnly"
                    :name="$t('message.advertisement.description')"
                    @keyup="updateTitleImage()" />
                </div>
              </draggable>
              <div
                v-if="!readOnly && selectedObjectImageMedia.length < 20"
                class="col-sm-12 col-md-12 mt-1">
                <coozzy-spinner v-if="imageUploadProcessing" />
                <coozzy-button
                  class="col-sm-4 col-lg-2"
                  design="prop-green"
                  @click="uploadImageClicked">
                  {{ $t('message.generic.addPicture') }}
                </coozzy-button>
              </div>

              <coozzy-form-file-input
                v-if="!readOnly"
                ref="imageUpload"
                accept="image/png,image/jpeg,image/jpg"
                class="d-none"
                multiple
                @change="addFile($event, 'imageUpload')" />
            </template>
            <div class="col-12">
              <h6 class="d-inline-block font-weight-bold mt-3">
                {{ $t('message.generic.document') }}
              </h6>
            </div>
            <div class="col-12">
              <template>
                <div class="row mx-0">
                  <div
                    v-if="publicDocuments.length === 0"
                    class="col-sm-12 col-md-3 pl-0">
                    {{ $t('message.generic.noDocument') }}
                  </div>
                  <div
                    v-if="!readOnly"
                    class="col-sm-12 col-md-12 mt-1 pl-0">
                    <add-document-modal
                      :no-add-another="true"
                      :owner-id="item.ownerId"
                      :suffix="'publicDocument'"
                      @document-created="documentCreated" />
                    <coozzy-button
                      v-if="publicDocuments.length === 0"
                      class="col-sm-4 col-lg-2"
                      design="prop-green"
                      @click="addDocumentClicked('publicDocument')">
                      {{ $t('message.manageDocuments.addDocument') }}
                    </coozzy-button>
                  </div>
                  <div class="col-12 px-0">
                    <document
                      v-for="doc in publicDocuments"
                      :key="doc.id"
                      :document="doc"
                      :is-read-only="readOnly"
                      @version-added="versionAdded"
                      @delete-document="deleteDocument" />
                  </div>
                </div>
              </template>
            </div>
            <div class="col-12">
              <h6 class="d-inline-block font-weight-bold mt-3">
                {{ $t('message.generic.movie') }}
              </h6>
            </div>
            <div class="col-12">
              <coozzy-spinner v-if="selectedObjectVideo === null" />
              <template v-else>
                <div class="row">
                  <div
                    v-if="selectedObjectVideo.length === 0"
                    class="col-sm-12 col-md-3">
                    {{ $t('message.generic.noVideos') }}
                  </div>
                  <div
                    v-for="video in selectedObjectVideo"
                    :key="video.media.id"
                    class="col-sm-12 col-md-2 mt-3 square">
                    <div
                      :class="['object-image-remove-container', 'cursor-pointer', 'hover', readOnly ? 'd-none' : '']"
                      @click="removeVideoClicked(video.media)">
                      <coozzy-close-icon class="object-image-remove bg" />
                      <coozzy-close-icon class="object-image-remove" />
                    </div>
                    <coozzy-thumbnail
                      v-if="getMediaUrl(video.media) !== null"
                      :key="video.media.id"
                      :video-src="getMediaUrl(video.media)"
                      :video-url="video.media.url"
                      class="object-image px-2 cursor-pointer"
                      @imageClicked="openVideo()" />
                    <coozzy-thumbnail
                      v-else
                      :src="'/img/video-placeholder.png'"
                      alt="Placeholder"
                      class="object-image px-2 cursor-pointer"
                      @imageClicked="openVideo(index)" />
                  </div>
                  <div
                    v-if="!readOnly && selectedObjectVideo.length < 1"
                    class="col-sm-12 col-md-12 mt-1">
                    <coozzy-spinner v-if="videoProcessing" />
                    <coozzy-button
                      class="col-sm-4 col-lg-2"
                      design="prop-green"
                      @click="uploadVideoClicked">
                      {{ $t('message.generic.addVideo') }}
                    </coozzy-button>
                  </div>

                  <coozzy-form-file-input
                    v-if="!readOnly"
                    ref="videoUpload"
                    accept="video/*"
                    class="d-none"
                    @change="addFile($event, 'videoUpload')" />
                </div>
              </template>
            </div>
            <div class="col-12">
              <h6 class="d-inline-block font-weight-bold mt-3">
                {{ $t('message.generic.virtualTour') }}
              </h6>
            </div>
            <div class="col-12">
              <coozzy-form-input
                v-if="!readOnly"
                v-model="virtualTourUrl"
                :is-read-only="readOnly" />
              <span
                v-else
                class="show-data">
                {{ virtualTourUrl && virtualTourUrl !== '' ? virtualTourUrl : $t('message.generic.noVirtualTour') }}
              </span>
            </div>
          </div>
        </div>
      </b-collapse>
    </div>
    <b-modal
      v-if="selectedObjectVideo !== null && selectedObjectVideo.length > 0"
      ref="show-video"
      hide-footer
      hide-header>
      <div class="row mb-2">
        <div class="col">
          <b-embed
            controls
            type="video">
            <source
              :src="selectedObjectVideo[0].media.url"
              type="video/webm">
            <source
              :src="selectedObjectVideo[0].media.url"
              type="video/mp4">
          </b-embed>
        </div>
      </div>
    </b-modal>
  </section>
</template>

<script>

import { onboarding } from '@/mixins/onboarding'
import { formatting } from '@/mixins/formatting'
import { maxLength, minValue, required } from 'vuelidate/lib/validators'
import CoozzyFormSelect from '../../../framework/components/form/select/CoozzyFormSelect'
import CoozzyFormInput from '../../../framework/components/form/input/CoozzyFormInput'
import MediaApi from '../../../misc/apis/MediaApi'
import CoozzyFormCheckbox from '../../../framework/components/form/checkbox/CoozzyFormCheckbox'
import CoozzyFormTextarea from '../../../framework/components/form/textarea/CoozzyFormTextarea'
import CoozzyFormInputNumeric from '../../../framework/components/form/input/CoozzyFormInputNumeric'
import CoozzySpinner from '../../../framework/components/misc/CoozzySpinner'
import CoozzyFormFileInput from '../../../framework/components/form/fileInput/CoozzyFormFileInput'
import CoozzyThumbnail from '../../../framework/components/img/CoozzyThumbnail'
import AddDocumentModal from '../../../properties/components/AddDocumentModal'
import Document from '@/properties/components/Document'
import Vue from 'vue'
import draggable from 'vuedraggable'
import { validation } from '@/mixins/validation'
import CoozzyButton from '@/framework/components/button/CoozzyButton'
import CoozzyCloseIcon from '@/framework/components/icons/CoozzyCloseIcon'
import { media } from '@/mixins/media'
import { user } from '@/mixins/user'

export default {
  name: 'ObjectAdvertising',
  components: {
    CoozzyCloseIcon,
    CoozzyButton,
    draggable,
    CoozzyFormInput,
    CoozzyFormSelect,
    CoozzyThumbnail,
    CoozzyFormFileInput,
    CoozzySpinner,
    CoozzyFormInputNumeric,
    CoozzyFormTextarea,
    CoozzyFormCheckbox,
    AddDocumentModal,
    Document
  },
  mixins: [onboarding, formatting, validation, media, user],
  props: {
    readOnly: {
      type: Boolean,
      default: false
    },
    objectSelected: {
      type: Object,
      default: null
    },
    expandByDefault: {
      type: Boolean,
      default: false
    },
    property: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      item: this.objectSelected,
      selectedObjectImageMedia: null,
      selectedObjectVideo: null,
      imageUpload: [],
      imageUploadProcessing: false,
      videoUpload: null,
      videoProcessing: null,
      availableParkingType: ['PARKING_TYPE_PARKING_SPACE', 'PARKING_TYPE_CARPORT', 'PARKING_TYPE_DOUBLE_CARPORT', 'PARKING_TYPE_GARAGE', 'PARKING_TYPE_DOUBLE_GARAGE', 'PARKING_TYPE_DUPLEX', 'PARKING_TYPE_CAR_PARK', 'PARKING_TYPE_UNDERGROUND_GARAGE', 'PARKING_TYPE_ELECTRIC'],
      availableType: this.objectSelected.availability ? this.objectSelected.availability.type : '',
      availability: {
        from: null,
        to: null
      },
      listDocument: [],
      titleLength: 70
    }
  },
  computed: {
    isMarketingPath() {
      return this.$route.name.startsWith('Marketing')
    },
    galleryImages() {
      return this.selectedObjectImageMedia.map(element => element.media.url)
    },
    type: {
      get() {
        return this.item.type
      },
      set(value) {
        const object = this.item
        object.type = value
        this.$store.dispatch('onboarding/updateObject', object)
        this.$emit('update-object-advertising', object)
      }
    },
    title: {
      get() {
        return this.item.title
      },
      set(value) {
        const object = this.item
        object.title = value
        this.$store.dispatch('onboarding/updateObject', object)
        this.$emit('update-object-advertising', object)
      }
    },
    description: {
      get() {
        return this.item.description
      },
      set(value) {
        const object = this.item
        object.description = value
        this.$store.dispatch('onboarding/updateObject', object)
        this.$emit('update-object-advertising', object)
      }
    },
    publishAddress: {
      get() {
        return this.item.publishAddress
      },
      set(value) {
        const object = this.item
        object.publishAddress = value
        this.$store.dispatch('onboarding/updateObject', object)
        this.$emit('update-object-advertising', object)
      }
    },
    currency: {
      get() {
        return this.item.pricing.currency
      },
      set(value) {
        const object = this.item
        object.pricing.currency = value
        this.$store.dispatch('onboarding/updateObject', object)
        this.$emit('update-object-advertising', object)
      }
    },
    unit: {
      get() {
        return this.item.pricing.unit
      },
      set(value) {
        const object = this.item
        object.pricing.unit = value
        this.$store.dispatch('onboarding/updateObject', object)
        this.$emit('update-object-advertising', object)
      }
    },
    coldRent: {
      get() {
        return this.item.pricing.coldRent !== -1 ? this.item.pricing.coldRent : null
      },
      set(value) {
        const object = this.item
        object.pricing.coldRent = value === null ? -1 : value
        this.$store.dispatch('onboarding/updateObject', object)
        this.$emit('update-object-advertising', object)
      }
    },
    sumAdditionalHeating: {
      get() {
        const additionaal = this.item.pricing.additional !== -1 ? this.item.pricing.additional : 0
        const heating = this.item.pricing.heating !== -1 ? this.item.pricing.heating : 0
        return additionaal + heating
      },
      set(value) {
        const object = this.item
        object.pricing.additional = value === null ? -1 : value
        object.pricing.heating = -1
        this.$store.dispatch('onboarding/updateObject', object)
        this.$emit('update-object-advertising', object)
      }
    },
    grossRent() {
      const total = (this.sumAdditionalHeating !== null ? this.sumAdditionalHeating : 0) +
        (this.coldRent !== null ? this.coldRent : 0)

      return total !== 0 ? total : null
    },
    sellingPrice: {
      get() {
        return this.item.pricing.sellingPrice !== -1 ? this.item.pricing.sellingPrice : null
      },
      set(value) {
        const object = this.item
        object.pricing.sellingPrice = value === null ? -1 : value
        this.$store.dispatch('onboarding/updateObject', object)
        this.$emit('update-object-advertising', object)
      }
    },
    sellingPriceM2: {
      get() {
        return this.item.pricing.sellingPriceM2 !== -1 ? this.item.pricing.sellingPriceM2 : null
      },
      set(value) {
        const object = this.item
        object.pricing.sellingPriceM2 = value === null ? -1 : value
        this.$store.dispatch('onboarding/updateObject', object)
        this.$emit('update-object-advertising', object)
      }
    },
    virtualTourUrl: {
      get() {
        return this.item.virtualTourUrl
      },
      set(value) {
        const object = this.item
        object.virtualTourUrl = value
        this.$store.dispatch('onboarding/updateObject', object)
        this.$emit('update-object-advertising', object)
      }
    },
    availabilityType: {
      get() {
        return this.item.availability && this.item.availability.type ? this.item.availability.type : 'IMMEDIATELY'
      },
      set(value) {
        const object = this.item
        this.availableType = value
        if (!object.availability) {
          object.availability = {}
        }
        if (this.availableType === 'IMMEDIATELY' || this.availableType === 'BY_ARRANGEMENT') {
          this.item.availability.availableFrom = -1
          this.item.availability.availableTo = -1
        } else if (this.availableType === 'FROM_DATE') {
          this.item.availability.availableTo = -1
        }
        object.availability.type = value
        this.$store.dispatch('onboarding/updateObject', object)
        this.$emit('update-object-advertising', object)
      }
    },
    availabilityFrom: {
      get() {
        return this.item.availability && this.item.availability.availableFrom !== '-1' ? this.item.availability.availableFrom : null
      },
      set(value) {
        const object = this.item
        if (!object.availability) {
          object.availability = {}
        }
        object.availability.availableFrom = value
        this.$store.dispatch('onboarding/updateObject', object)
        this.$emit('update-object-advertising', object)
      }
    },
    availabilityTo: {
      get() {
        return this.item.availability && this.item.availability.availableTo !== '-1' ? this.item.availability.availableTo : null
      },
      set(value) {
        const object = this.item
        if (!object.availability) {
          object.availability = {}
        }
        object.availability.availableTo = value
        this.$store.dispatch('onboarding/updateObject', object)
        this.$emit('update-object-advertising', object)
      }
    },
    parking: function () {
      return this.item.parking
    },
    publicDocuments: function () {
      return this.listDocument.length > 0 ? this.listDocument : this.getFilteredObjectDocuments(this.item.documents.filter(doc => (doc.isFloorPlan === false || doc.isFloorPlan === null) && doc.isPublic === true).map(d => d.documentId), this.documentType, this.documentName).sort((a, b) => (a.name > b.name ? 1 : -1))
    },
    advancePaymentValues: {
      get() {
        const list = []
        const objectAdvancePaymentValues = this.item.pricing && this.item.pricing.advancePaymentValues.length > 0 ? this.item.pricing.advancePaymentValues : []
        this.property?.advancePaymentRentComponents.forEach(element => {
          if (element.inactive === false) {
            const item = objectAdvancePaymentValues.find(e => e.type === element.rentComponent)
            list.push({
              type: element.rentComponent,
              value: item ? item.value : null
            })
          }
        })
        return list
      },
      set(value) {
        const object = this.item
        object.pricing.advancePaymentValues = value
        this.$emit('update-object-advertising', object)
      }
    },
    flatRateValues: {
      get() {
        const list = []
        const objectFlatRateValues = this.item.pricing && this.item.pricing.flatRateValues.length > 0 ? this.item.pricing.flatRateValues.filter(x => x.type !== 'FLAT_RATE_RENT_COMPONENT_UNDEFINED') : []
        this.property?.flatRateRentComponents.forEach(element => {
          if (element.inactive === false) {
            const item = objectFlatRateValues.find(e => e.type === element.rentComponent)
            list.push({
              type: element.rentComponent,
              value: item ? item.value : null
            })
          }
        })
        return list
      },
      set(value) {
        const object = this.item
        object.pricing.flatRateValues = value
        this.$emit('update-object-advertising', object)
      }
    },
    additionalValues: {
      get() {
        const list = []
        const objectAdditionalValues = this.item.pricing && this.item.pricing.additionalValues.length > 0 ? this.item.pricing.additionalValues : []
        this.property?.additionalRentComponents.forEach(element => {
          if (element.inactive === false) {
            const item = objectAdditionalValues.find(e => e.type === element.rentComponent)
            list.push({
              type: element.rentComponent,
              value: item ? item.value : null
            })
          }
        })
        return list
      },
      set(value) {
        const object = this.item
        object.pricing.additionalValues = value
        this.$emit('update-object-advertising', object)
      }
    },
    canEditAdditionnalCosts: function () {
      let hasValue = false
      this.advancePaymentValues.forEach(element => {
        if (element.value) {
          hasValue = true
        }
      })
      this.flatRateValues.forEach(element => {
        if (element.value) {
          hasValue = true
        }
      })
      this.additionalValues.forEach(element => {
        if (element.value) {
          hasValue = true
        }
      })
      return hasValue
    }
  },
  watch: {
    property: function (newValue) {
      if (newValue) {
        this.loadRentComponents(newValue)
      }
    }
  },
  mounted() {
    this.selectedObject(this.item)
    if (!this.item.availability) {
      this.item.availability = {
        availableFrom: -1,
        availableTo: -1,
        type: 'IMMEDIATELY'
      }
    } else {
      this.availability.from = this.$options.filters.timestampToDateInput(this.availabilityFrom)
      this.availability.to = this.$options.filters.timestampToDateInput(this.availabilityTo)
    }
    if (this.item && this.item.documents.length > 0) {
      MediaApi.listDocuments(this.item.documents.filter(doc => (doc.isFloorPlan === false || doc.isFloorPlan === null) && doc.isPublic === true).map(x => x.documentId).filter(y => y !== ''))
        .then(documents => {
          if (documents.documents.length > 0) {
            this.listDocument = documents.documents
          }
        })
    }
  },
  methods: {
    async addFile(event, source) {
      const files = await this.getFilesFromEvent(event)
      let notSupportedFiles = []
      if (files.length > 0) {
        for (let index = 0; index < files.length; index++) {
          const element = files[index]
          if ((source === 'imageUpload' && !['image/png', 'image/jpeg', 'image/jpg'].includes(element.type)) || (source === 'videoUpload' && !element.type.includes('video'))) {
            notSupportedFiles.push(index)
          }
          if (notSupportedFiles.filter(x => x === index).length === 0) {
            if (source === 'imageUpload') {
              this.imageUpload.push(element)
            } else {
              this.videoUpload = element
            }
          }
        }
      }

      if (this.imageUpload.length > 0 || this.videoUpload !== null) {
        if (source === 'videoUpload') {
          await this.sendVideo()
        } else {
          await this.sendFile()
        }
      }
      if (notSupportedFiles.length > 0) {
        Vue.toasted.show(this.$tc('message.savingErrors.fileNotSupported'), { type: 'error' })
      }
    },
    async sendFile() {
      this.imageUploadProcessing = true
      const response = await this.uploadMedia(this.imageUpload)
      if (response.length > 0) {
        // Update vuex store
        const object = this.item
        for (const media of response) {
          this.selectedObjectImageMedia.push({
            media: media,
            mediaId: media.id,
            order: this.selectedObjectImageMedia.length,
            title: ''
          })
          object.images.push({
            mediaId: media.id,
            order: this.selectedObjectImageMedia.length,
            title: ''
          })
        }
        this.$store.dispatch('onboarding/updateObject', object)
      }
      this.$nextTick(() => {
        this.$refs.imageUpload.resetPlaceholder()
        this.imageUpload = []
        this.imageUploadProcessing = false
      })
    },
    async sendVideo() {
      const video = this.videoUpload
      this.videoProcessing = true
      const response = await this.uploadMedia(video, this.item.ownerId)
      if (response.length > 0) {
        // Update vuex store
        const video = response[0]
        this.selectedObjectVideo.push({ media: video })
        const object = this.item
        object.videos.push({ mediaId: video.id })
        this.$store.dispatch('onboarding/updateObject', object)
      }
      this.$nextTick(() => {
        this.$refs.videoUpload.resetPlaceholder()
        this.videoUpload = null
        this.videoProcessing = false
      })
    },
    getModulo(array) {
      let foo = []
      for (let i = 0; i < array.length / 4; i++) {
        foo.push(i)
      }
      return foo
    },
    advancePaymentValueChanged(element, val) {
      const object = this.item
      object.pricing.advancePaymentValues.find(x => x.type === element.type).value = val
      this.$emit('update-object-advertising', object)
      this.calculateAdditionalHeatingCosts()
    },
    flatRateValueChanged(element, val) {
      const object = this.item
      object.pricing.flatRateValues.find(x => x.type === element.type).value = val
      this.$emit('update-object-advertising', object)
      this.calculateAdditionalHeatingCosts()
    },
    additionalValuesChanged(element, val) {
      const object = this.item
      object.pricing.additionalValues.find(x => x.type === element.type).value = val
      this.$emit('update-object-advertising', object)
      this.calculateAdditionalHeatingCosts()
    },
    calculateAdditionalHeatingCosts() {
      if (this.canEditAdditionnalCosts) {
        this.sumAdditionalHeating = 0
        if (this.item.pricing.advancePaymentValues) {
          this.item.pricing.advancePaymentValues.forEach(element => {
            if (element.value && element.value > 0) {
              this.sumAdditionalHeating += element.value
            }
          })
        }
        if (this.item.pricing.flatRateValues) {
          this.item.pricing.flatRateValues.forEach(element => {
            if (element.value && element.value > 0) {
              this.sumAdditionalHeating += element.value
            }
          })
        }
        if (this.item.pricing.additionalValues) {
          this.item.pricing.additionalValues.forEach(element => {
            if (element.type !== 'ADDITIONAL_RENT_COMPONENT_RENT_REDUCTION' && element.value && element.value > 0) {
              this.sumAdditionalHeating += element.value
            }
          })
        }
      }
    },
    loadRentComponents(element) {
      element.advancePaymentRentComponents.forEach(element => {
        if (!this.advancePaymentValues?.find(x => x.type === element.rentComponent)) {
          this.advancePaymentValues.push({
            type: element.rentComponent,
            value: null
          })
        }
      })
      element.flatRateRentComponents.forEach(element => {
        if (!this.flatRateValues?.find(x => x.type === element.rentComponent)) {
          this.flatRateValues.push({
            type: element.rentComponent,
            value: null
          })
        }
      })
      element.additionalRentComponents.forEach(element => {
        if (!this.additionalValues?.find(x => x.type === element.rentComponent)) {
          this.additionalValues.push({
            type: element.rentComponent,
            value: null
          })
        }
      })
      this.$nextTick(() => {
        const object = this.item
        object.pricing.advancePaymentValues = this.advancePaymentValues
        object.pricing.flatRateValues = this.flatRateValues
        object.pricing.additionalValues = this.additionalValues
        this.$emit('update-object-advertising', object)
      })
    },
    checkLenght() {
      if (this.title.length > this.titleLength) {
        this.$nextTick(() => {
          this.title = this.title.substring(0, this.titleLength)
        })
      }
    },
    dateToTimestampFrom() {
      this.availabilityFrom = this.toTimestamp(this.availability.from)
    },
    dateToTimestampTo() {
      this.availabilityTo = this.toTimestamp(this.availability.to)
    },
    toTimestamp(strDate) {
      return Date.parse(strDate) / 1000
    },
    openModalDelete() {
      this.$refs.deleteModal.show()
    },
    async removeConfirmed() {
      this.removeObjects([this.item])
        .then(() => {
          this.item = null
          this.$refs.deleteModal.hide()
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.deleteErrors.object'), { type: 'error' })
        })
    },
    async selectedObject(object) {
      this.selectedObjectImageMedia = null
      this.selectedObjectVideo = null
      // Load media
      const promises = []
      for (const image of object.images) {
        promises.push(MediaApi.getMediaByID(image.mediaId))
      }
      if (object.videos.length > 0) {
        for (const video of object.videos) {
          promises.push(MediaApi.getMediaByID(video.mediaId))
        }
      }
      const listmedia = await Promise.all(promises)
      for (let index = 0; index < (listmedia.length - object.videos.length); index++) {
        listmedia[index].mediaId = object.images[index].mediaId
        listmedia[index].order = object.images[index].order
        listmedia[index].title = object.images[index].title
      }
      this.selectedObjectImageMedia = listmedia.filter(med => med.media?.mimeType?.includes('image')).sort(function (a, b) {
        return a.order - b.order
      })
      this.selectedObjectVideo = listmedia.filter(med => med.media?.mimeType.includes('video'))
    },
    getMediaUrl(media) {
      if (media.thumbnails === undefined || media.thumbnails === null || typeof media.thumbnails === 'undefined') {
        return null
      }
      for (const thumbnail of media.thumbnails) {
        if (thumbnail.type === 1 || thumbnail.type === 'M') {
          return thumbnail.url
        }
      }

      return media.url
    },
    uploadImageClicked() {
      if (!this.readOnly) {
        const btn = this.$refs.imageUpload.$el.children
        btn[0].click()
      }
    },
    uploadVideoClicked() {
      if (!this.readOnly) {
        const btn = this.$refs.videoUpload.$el.children
        btn[0].click()
      }
    },
    removeImageClicked(media) {
      this.selectedObjectImageMedia = this.selectedObjectImageMedia.filter(m => {
        return m.media.id !== media.id
      })

      const object = this.item
      object.images = object.images.filter(i => {
        return i.mediaId !== media.id
      })
      for (const [index, image] of object.images.entries()) {
        image.order = index + 1
      }
      this.$store.dispatch('onboarding/updateObject', object)
    },
    removeVideoClicked(media) {
      this.selectedObjectVideo = this.selectedObjectVideo.filter(m => {
        return m.media.id !== media.id
      })

      const object = this.item
      object.videos = object.videos.filter(i => {
        return i.mediaId !== media.id
      })
      this.$store.dispatch('onboarding/updateObject', object)
    },
    addDocumentClicked(suffix = '') {
      this.$bvModal.show('add-document-modal' + suffix)
    },
    versionAdded(updatedDocument) {
      this.objectDocuments.forEach((doc, index) => {
        if (doc.id === updatedDocument.id) {
          this.objectDocuments.splice(index, 1, updatedDocument)
        }
      }, this)
    },
    deleteDocument(document) {
      this.item.documents.forEach((doc, index) => {
        if (doc.documentId === document.id) {
          this.item.documents.splice(index, 1, document)
        }
      }, this)
      this.listDocument = this.listDocument.filter(x => x.id !== document.id)
      this.$store.dispatch('onboarding/updateObject', this.item)
      this.activateChanged()
    },
    documentCreated(document, suffix) {
      const object = this.item
      if (suffix === 'publicDocument') {
        object.documents.push({
          documentId: document.id,
          isFloorPlan: false,
          isPublic: true
        })
      } else {
        object.documents.push({ documentId: document.id })
      }
      // Update object in vuex store with new documentId
      this.$store.dispatch('onboarding/updateObject', object)
      this.$emit('update-object-advertising', object)

      // Add whole new document to vuex store to display it
      this.objectDocuments.push(document)
    },
    addParking() {
      this.item.parking.push({
        costs: null,
        parkingType: null
      })
      const object = this.item
      this.$store.dispatch('onboarding/updateObject', object)
    },
    removeParking(index) {
      this.item.parking.splice(index, 1)
      const object = this.item
      this.$store.dispatch('onboarding/updateObject', object)
    },
    imageClicked(index) {
      const images = [this.galleryImages[index]]
      this.$viewerApi({
        images: images,
        options: this.viewerOptions
      })
    },
    openVideo() {
      this.$refs['show-video'].show()
    },
    isInvalid() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.scrollToError()
      }
      return this.$v.$invalid
    },
    isCollapseOpen() {
      return this.$refs.collapse.visible
    },
    endMoving() {
      for (let index = 0; index < this.selectedObjectImageMedia.length; index++) {
        const indexElement = this.item.images.findIndex(image => image.mediaId === this.selectedObjectImageMedia[index].media.id)
        if (this.item.images[indexElement]) {
          this.item.images[indexElement].order = index + 1
        }
      }
      this.$emit('update-object-advertising', this.item)
      this.$store.dispatch('onboarding/updateObject', this.item)
    },
    updateTitleImage() {
      for (let index = 0; index < this.selectedObjectImageMedia.length; index++) {
        const indexElement = this.item.images.findIndex(image => image.mediaId === this.selectedObjectImageMedia[index].media.id)
        if (this.item.images[indexElement]) {
          this.item.images[indexElement].title = this.selectedObjectImageMedia[index].title
        }
      }
      this.$emit('update-object-advertising', this.item)
      this.$store.dispatch('onboarding/updateObject', this.item)
    }
  },
  validations() {
    const validationObject = {
      coldRent: {
        minValue: minValue(0)
      },
      sumAdditionalHeating: {
        minValue: minValue(0)
      },
      sellingPrice: {
        minValue: minValue(0)
      },
      sellingPriceM2: {
        minValue: minValue(0)
      }
    }
    if (this.isMarketingPath) {
      validationObject.title = {
        required,
        maxLength: maxLength(70)
      }
      validationObject.description = {
        required
      }
      validationObject.currency = {
        required
      }
      if (this.type === 'RENT' && this.unit !== 'ON_REQUEST') {
        validationObject.coldRent = {
          required,
          minValue: minValue(0)
        }
      }
    }
    return validationObject
  }
}
</script>

<style lang="scss" scoped>
.object-image-remove-container {
  position: absolute;
  z-index: 2;
  width: 16px;
  height: 16px;
  margin: 8px;
}

.object-image-remove {
  position: absolute;
  z-index: 3;
  width: 17px;
  height: 16px;
  color: red;

  &.bg {
    left: 0;
    top: -3px;
    width: 14px;
    height: 22px;
    color: white;
  }
}

.square {
  position: relative;
}

.square:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.object-image {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.no-alternate-backgrounds :deep(.details-row-bg-1) {
  background-color: $color-table-row-1;
}
</style>
