<template>
  <coozzy-card
    :title="$t('message.marketingSettings.export.title')">
    <b-card-sub-title>
      {{ $t('message.marketingSettings.export.subTitle') }}
      <coozzy-info-circle-icon v-b-tooltip.hover.html="$t('message.export.info')" />
    </b-card-sub-title>
    <div class="row mt-3">
      <div
        v-if="loading"
        class="col-12">
        <coozzy-spinner />
      </div>
      <template v-else>
        <div class="col-12 col-md-3">
          <coozzy-list-group>
            <export-settings-platform-item
              :selected-platform="selectedPlatform"
              platform="NEWHOME_PLATFORM"
              :run="latestExportRuns.newhome"
              @selected="platformSelected">
              <img
                class="logo"
                alt="newhome"
                src="@/assets/exportCard/newhome.png">
            </export-settings-platform-item>
            <export-settings-platform-item
              :selected-platform="selectedPlatform"
              platform="IMMOSCOUT_PLATFORM"
              :run="latestExportRuns.immoscout"
              @selected="platformSelected">
              <img
                class="logo"
                alt="Immoscout24"
                src="@/assets/exportCard/Immoscout.png">
            </export-settings-platform-item>
            <export-settings-platform-item
              :selected-platform="selectedPlatform"
              platform="HOMEGATE_PLATFORM"
              :run="latestExportRuns.homegate"
              @selected="platformSelected">
              <img
                class="logo"
                alt="homegate"
                src="@/assets/exportCard/homegate.png">
            </export-settings-platform-item>
            <export-settings-platform-item
              :selected-platform="selectedPlatform"
              platform="CASAONE_PLATFORM"
              :run="latestExportRuns.casaone"
              @selected="platformSelected">
              <img
                v-if="showCompanyLogo && stateLogoUrl"
                class="logo"
                :src="stateLogoUrl"
                alt="casaone"
                @error="onErrorImage()">
              <img
                v-else
                class="logo"
                alt="casaone"
                src="@/assets/exportCard/casasoft.svg">
            </export-settings-platform-item>
            <export-settings-platform-item
              :selected-platform="selectedPlatform"
              platform="FLATFOX_PLATFORM"
              :run="latestExportRuns.flatfox"
              @selected="platformSelected">
              <img
                class="logo"
                alt="flatox"
                src="@/assets/exportCard/flatfox.svg">
            </export-settings-platform-item>
            <export-settings-platform-item
              :selected-platform="selectedPlatform"
              platform="URBANHOME_PLATFORM"
              :run="latestExportRuns.urbanhome"
              @selected="platformSelected">
              <img
                class="logo"
                alt="urbanhome"
                src="@/assets/exportCard/urbanhome.png">
            </export-settings-platform-item>
          </coozzy-list-group>
        </div>
        <div class="col-12 col-md-9 mt-2 mt-md-0">
          <export-settings-run-details :run="selectedRun" />
          <export-settings-config
            :platform="selectedPlatform"
            :export-config="selectedExport"
            @export-deleted="onExportDeleted"
            @export-saved="onExportSaved" />
        </div>
      </template>
    </div>
  </coozzy-card>
</template>

<script>
import ExportApi from '../../misc/apis/ExportApi'
import ExportSettingsConfig from './ExportSettingsConfig'
import ExportSettingsRunDetails from './ExportSettingsRunDetails'
import ExportSettingsPlatformItem from './ExportSettingsPlatformItem'
import Vue from 'vue'
import CoozzyCard from '@/framework/components/card/CoozzyCard'
import CoozzyInfoCircleIcon from '@/framework/components/icons/CoozzyInfoCircleIcon'
import CoozzyListGroup from '@/framework/components/list/CoozzyListGroup'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner'

export default {
  name: 'ExportSettings',
  components: {
    CoozzySpinner,
    CoozzyListGroup,
    CoozzyInfoCircleIcon,
    CoozzyCard,
    ExportSettingsPlatformItem,
    ExportSettingsRunDetails,
    ExportSettingsConfig
  },
  data() {
    return {
      loading: false,
      showCompanyLogo: true,
      exportConfigs: [],
      selectedPlatform: 'NEWHOME_PLATFORM',
      latestExportRuns: {
        newhome: null,
        immoscout: null,
        homegate: null,
        casaone: null,
        flatfox: null,
        urbanhome: null
      },
      interval: null
    }
  },
  computed: {
    stateLogoUrl() {
      const stateLogoUrl = this.$store.getters['user/getLogoUrl']
      if (stateLogoUrl) {
        return stateLogoUrl
      } else {
        return null
      }
    },
    selectedExport() {
      return this.exportConfigs.find(e => {
        return e.platform === this.selectedPlatform
      }, this)
    },
    selectedRun() {
      if (this.selectedPlatform === 'NEWHOME_PLATFORM') {
        return this.latestExportRuns.newhome
      } else if (this.selectedPlatform === 'IMMOSCOUT_PLATFORM') {
        return this.latestExportRuns.immoscout
      } else if (this.selectedPlatform === 'HOMEGATE_PLATFORM') {
        return this.latestExportRuns.homegate
      } else if (this.selectedPlatform === 'CASAONE_PLATFORM') {
        return this.latestExportRuns.casaone
      } else if (this.selectedPlatform === 'FLATFOX_PLATFORM') {
        return this.latestExportRuns.flatfox
      } else if (this.selectedPlatform === 'URBANHOME_PLATFORM') {
        return this.latestExportRuns.urbanhome
      }

      return null
    }
  },
  mounted() {
    this.loading = true
    ExportApi.getExports(this.$store.getters['user/getAccountId'])
      .then(response => {
        if (response && Array.isArray(response.exports)) {
          this.exportConfigs = response.exports
        } else {
          Vue.toasted.show(this.$t('message.loadingErrors.exports'), { type: 'error' })
        }
      })
      .catch(e => {
        console.log(e)
        Vue.toasted.show(this.$t('message.loadingErrors.exports'), { type: 'error' })
      })
      .finally(() => {
        this.loading = false
      })

    this.loadLatestExportRuns()
    // Poll for latest export run updates
    this.interval = setInterval(() => this.loadLatestExportRuns(), 15000)
  },
  beforeDestroy() {
    // Stop polling for latest export run updates
    clearInterval(this.interval)
  },
  methods: {
    onErrorImage() {
      this.showCompanyLogo = false
    },
    platformSelected(platform) {
      this.selectedPlatform = platform
    },
    loadLatestExportRuns() {
      ExportApi.getLatestExportRuns(this.$store.getters['user/getAccountId'])
        .then(response => {
          this.latestExportRuns = response
        })
    },
    onExportSaved(exportConfig) {
      this.exportConfigs.push(exportConfig)
    },
    onExportDeleted(exportId) {
      this.exportConfigs = this.exportConfigs.filter(e => e.id !== exportId)
    }
  }
}
</script>

<style lang="scss" scoped>
.logo {
  width: auto;
  height: auto;
  max-width: 80%;
  max-height: 30px;
  padding-left: 5px;
}

:deep(.list-group-item) {
  height: 50px;
  padding: 0.5rem 0.25rem 0.5rem 0.5rem;
}
</style>
