<template>
  <coozzy-card class="lower-height">
    <div class="row">
      <div class="col-sm-12 col-md-8">
        <h6 class="title-card mb-0">
          <coozzy-badge
            size="large"
            class="mt-1">
            {{ $t(`message.manageDocuments.type.${document.type.toLowerCase()}`) }}
          </coozzy-badge>
          <span> - </span>
          {{ document.name }}
          <a
            class="ml-1"
            target="_BLANK"
            @click="downloadFile()">
            <coozzy-file-download-icon />
          </a>
          <a
            v-if="imagesType.includes(getDocExt)"
            class="ml-2"
            @click="showImage(latestVersionUrl)">
            <coozzy-watcher-icon />
          </a>
          <a
            v-if="videoType.includes(getDocExt)"
            class="ml-2"
            @click="openVideo(latestVersionUrl)">
            <coozzy-watcher-icon />
          </a>
          <span v-if="useLabel">
            <br>
            <coozzy-badge
              size="large"
              design="green"
              class="mt-1 mr-2">
              {{ document.label }}
            </coozzy-badge>
          </span>
        </h6>
        <coozzy-badge
          v-for="(ownerName, index) in owner"
          :key="`ownerName-${ index }`"
          size="large"
          design="green"
          class="mt-1 mr-2">
          {{ ownerName }}
        </coozzy-badge>
        <a
          class="link"
          @click="showAllVersion">{{ isShow === true ? $t('message.manageDocuments.version.showCurrentVersion') : $t('message.manageDocuments.version.showAllVersion') }}</a>
      </div>
      <div class="col-sm-12 col-md-4">
        <coozzy-button
          v-if="hasDocumentRoles && !isReadOnly"
          class="float-md-right"
          design="transparent"
          @click="deleteClick(null)">
          <coozzy-delete-icon />
        </coozzy-button>
        <coozzy-button
          v-if="isEditor"
          class="float-md-right sort"
          design="transparent"
          @click="editDocument">
          <coozzy-edit-icon />
        </coozzy-button>
      </div>
    </div>
    <template v-if="isShow">
      <div class="row">
        <div class="col-sm-12 col-md-6 mt-3">
          <h6>
            {{ $t('message.manageDocuments.version.all') }}
          </h6>
        </div>
        <div class="d-flex justify-content-md-end sort col-sm-12 col-md-6 add-version-block">
          <add-version-modal
            :document-id="document.id"
            :owner-id="document.ownerId"
            @version-added="versionAdded" />
          <coozzy-button
            v-if="!isReadOnly || isEditor || isCompanyAdmin"
            class="align-self-end mb-2"
            size="small"
            @click="$bvModal.show(`add-version-modal-${document.id}`)">
            {{ $t('message.manageDocuments.version.add') }}
          </coozzy-button>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <b-table
            :ref="'versionTable' + document.id"
            :busy="busy"
            striped
            bordered
            :responsive="true"
            stacked="md"
            hover
            class="mb-0"
            :items="formattedMedia"
            :fields="mediaFields">
            <!-- Busy state -->
            <div
              slot="table-busy"
              class="text-center text-danger my-2">
              <coozzy-spinner />
            </div>

            <template #head(name)>
              {{ $t('message.manageDocuments.name') }}
            </template>
            <template #head(user)>
              {{ $t('message.manageDocuments.user') }}
            </template>
            <template #head(version)>
              {{ $t('message.manageDocuments.version.title') }}
            </template>
            <template #head(uploaded)>
              {{ $t('message.manageDocuments.uploaded') }}
            </template>
            <template #head(action)>
              {{ $t('message.calendar.event.action') }}
            </template>

            <template #cell(filename)="data">
              <a
                class="link"
                target="_blank"
                :href="data.item.url"
                :draggable="true"
                @click="$emit('downloaded', {name: document.name, version: data.item.version, versions: document.versions})"
                @dragstart="handleDragStart(data.item)">
                {{ truncateText(data.item.filename) }}
              </a>
            </template>
            <template #cell(user)="data">
              <user-fullname-text
                v-if="data.item.userId !== ''"
                :user-id="data.item.userId" />
              <span v-else>-</span>
            </template>
            <template
              v-if="hasDocumentRoles"
              #cell(action)="data">
              <coozzy-button
                v-if="!isReadOnly"
                design="transparent"
                @click="deleteClick(data)">
                <coozzy-delete-icon />
              </coozzy-button>
            </template>
          </b-table>
        </div>
      </div>
    </template>
    <b-modal
      :id="'deleteDocumentModal'"
      ref="deleteDocumentModal"
      no-close-on-backdrop
      hide-footer
      :title="$t('message.onBoarding.deleteModals.titleDocument')">
      <div class="col pl-0">
        <p>{{ $t('message.onBoarding.deleteModals.bodyDocument') }}</p>
      </div>
      <div class="col">
        <coozzy-button
          size="small"
          class="mb-0 border"
          design="transparent"
          @click="$bvModal.hide('deleteDocumentModal')">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
        <coozzy-button
          size="small"
          class="float-right mb-0"
          design="green"
          @click="removeConfirmed">
          {{ $t('message.generic.delete') }}
        </coozzy-button>
      </div>
    </b-modal>
    <b-modal
      v-if="selectedObjectVideo !== ''"
      ref="show-video"
      hide-footer
      hide-header>
      <div class="row mb-2">
        <div class="col">
          <b-embed
            type="video"
            controls>
            <source
              :src="selectedObjectVideo"
              type="video/webm">
            <source
              :src="selectedObjectVideo"
              type="video/mp4">
          </b-embed>
        </div>
      </div>
    </b-modal>
    <add-document-modal
      v-if="showModalEditDocument && document && isEditor"
      :id="'editDocumentModal' + document.id"
      :ref="'editDocumentModal' + document.id"
      :suffix="'edit-document' + document.id"
      :add-document="false"
      :document="document"
      @hide-modal="closeEditDocument"
      @document-updated="updateDocument" />
  </coozzy-card>
</template>
<script>
import MediaApi from '../../misc/apis/MediaApi'
import UserFullnameText from '../../framework/components/misc/UserFullnameText'
import AddVersionModal from './AddVersionModal'
import CoozzyCard from '../../framework/components/card/CoozzyCard'
import Vue from 'vue'
import CoozzyFileDownloadIcon from '@/framework/components/icons/CoozzyFileDownloadIcon'
import CoozzyButton from '@/framework/components/button/CoozzyButton'
import CoozzyDeleteIcon from '@/framework/components/icons/CoozzyDeleteIcon'
import CoozzyWatcherIcon from '@/framework/components/icons/CoozzyWatcherIcon'
import CoozzyBadge from '@/framework/components/badge/CoozzyBadge'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner'
import { user } from '@/mixins/user'
import { saveAs } from 'file-saver'
import CoozzyEditIcon from '@/framework/components/icons/CoozzyEditIcon.vue'
import AddDocumentModal from '@/properties/components/AddDocumentModal.vue'

export default {
  name: 'Document',
  components: { AddDocumentModal, CoozzyEditIcon, CoozzySpinner, CoozzyBadge, CoozzyDeleteIcon, CoozzyButton, CoozzyFileDownloadIcon, CoozzyCard, AddVersionModal, UserFullnameText, CoozzyWatcherIcon },
  mixins: [user],
  props: {
    document: {
      type: Object,
      default: () => {
      }
    },
    owner: {
      type: Array,
      default() {
        return []
      }
    },
    isTenant: {
      type: Boolean,
      default: false
    },
    isReadOnly: {
      type: Boolean,
      default: false
    },
    useLabel: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      mediaFields: [
        {
          key: 'filename',
          label: this.$t('message.manageDocuments.name'),
          thClass: 'col-5 min-width-360',
          tdClass: 'col-5 min-width-360'
        },
        {
          key: 'user',
          label: this.$t('message.manageDocuments.user'),
          thClass: 'col-3',
          tdClass: 'col-3'
        },
        {
          key: 'version',
          label: this.$t('message.manageDocuments.version.title'),
          thClass: 'col-3',
          tdClass: 'col-3'
        },
        {
          key: 'uploadedAt',
          label: this.$t('message.manageDocuments.uploaded'),
          thClass: 'col min-width-140',
          tdClass: 'col min-width-140'
        }
      ],
      media: [],
      isShow: false,
      busy: false,
      selectedVersion: null,
      galleryImages: [],
      selectedObjectVideo: null,
      imagesType: ['jpg', 'jpeg', 'png', 'gif'],
      videoType: ['mp4', 'webm'],
      showModalEditDocument: false
    }
  },
  computed: {
    hasDocumentRoles() {
      if (this.$store.getters['user/getCurrentUser']['https://coozzy.ch/roles'].includes('company_admin') ||
        this.$store.getters['user/getCurrentUser']['https://coozzy.ch/roles'].includes('manage_documents') || this.isTenant) {
        this.updateField(true)
        return true
      }
      this.updateField(false)
      return false
    },
    formattedMedia() {
      const items = this.removeDuplicatedItems(this.media)
      return items.map(m => {
        return {
          filename: m.filename,
          url: m.url,
          userId: this.getVersionUploaderId(m.id),
          version: this.getVersionNumber(m.id),
          uploadedAt: this.$options.filters.timestampToTime(m.uploadedAt),
          mediaId: m.id
        }
      })
    },
    latestVersionUrl() {
      if (this.formattedMedia.length > 0) {
        return this.formattedMedia[0].url
      } else {
        return ''
      }
    },
    latestVersion() {
      if (this.formattedMedia.length > 0) {
        return this.formattedMedia[0].version
      } else {
        return this.document.version
      }
    },
    getDocExt() {
      if (this.document && this.media.length > 0) {
        return this.media[0].filename.split('.').pop()
      } else {
        return null
      }
    }
  },
  watch: {
    document: function () {
      this.loadMedia()
    },
    isShow: function (newVal) {
      if (this.media.length === 0 && newVal) {
        this.loadMedia()
      }
    }
  },
  mounted() {
    this.loadMedia()
  },
  methods: {
    editDocument() {
      this.showModalEditDocument = true
      this.$nextTick(() => {
        this.$refs['editDocumentModal' + this.document.id].show()
      })
    },
    closeEditDocument() {
      this.showModalEditDocument = false
    },
    updateDocument(document) {
      MediaApi.updateDocument(document.id, document.name, document.type, document.showInPortal)
        .then(() => {
          this.showModalEditDocument = false
          this.$emit('version-added', document)
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.savingErrors.file'), { type: 'error' })
        })
    },
    downloadFile() {
      saveAs(this.latestVersionUrl, this.formattedMedia[0].filename)
      this.$emit('downloaded', { name: this.document.name, version: this.latestVersion, versions: this.document.versions })
    },
    removeDuplicatedItems(items) {
      const uniqueIds = []
      const list = items.filter(element => {
        const isDuplicate = uniqueIds.includes(element.id)
        if (!isDuplicate) {
          uniqueIds.push(element.id)
          return true
        }
        return false
      })
      return list
    },
    showImage(link) {
      if (link) {
        this.galleryImages = [link]
        this.$nextTick(function () {
          this.$viewerApi({
            images: this.galleryImages,
            options: this.viewerOptions
          })
        })
      }
    },
    openVideo(link) {
      this.selectedObjectVideo = link
      this.$nextTick(() => {
        this.$refs['show-video'].show()
      })
    },
    updateField(check) {
      if (check === true && this.mediaFields.indexOf({ key: 'Action', label: '' }) === -1 && this.isReadOnly === false) {
        this.mediaFields.push({ key: 'Action', label: '' })
      } else if (check === false && this.mediaFields.indexOf({ key: 'Action', label: '' }) === -1) {
        this.mediaFields.pop()
      }
    },
    loadMedia() {
      if (this.document) {
        this.media = []
        this.busy = true
        const listIds = this.document.versions.map(x => x.mediaId)
        if (listIds.length > 0) {
          MediaApi.getListMedia(listIds)
            .then(response => {
              for (const medium of response.media) {
                this.media.push(medium)
              }

              this.media.sort((a, b) => {
                return this.getVersionNumber(b.id) - this.getVersionNumber(a.id)
              })
            })
            .catch(e => {
              console.log(e)
              Vue.toasted.show(this.$t('message.loadingErrors.documents'), { type: 'error' })
            })
            .finally(() => {
              this.busy = false
            })
        }
      }
    },
    showAllVersion() {
      this.isShow = this.isShow !== true
    },
    getVersionUploaderId(mediaId) {
      for (const version of this.document.versions) {
        if (version.mediaId === mediaId) {
          return version.userId
        }
      }

      return ''
    },
    getVersionNumber(mediaId) {
      for (const version of this.document.versions) {
        if (version.mediaId === mediaId) {
          return version.version
        }
      }

      return '-'
    },
    versionAdded(document) {
      this.$emit('version-added', document)
    },
    deleteClick(item) {
      this.selectedVersion = item
      this.$store.dispatch('user/setChangedModal', false)
      this.$refs.deleteDocumentModal.show()
    },
    removeConfirmed() {
      if (this.media.length < 2) {
        this.selectedVersion = null
      }
      if (this.selectedVersion) {
        MediaApi.removeVersion(this.document.id, this.selectedVersion.item.version)
          .then(() => {
            this.media.splice((this.selectedVersion.index), 1)
          })
          .catch(e => {
            console.log(e)
            Vue.toasted.show(this.$t('message.savingErrors.file'), { type: 'error' })
          })
          .finally(() => {
            this.$refs.deleteDocumentModal.hide()
          })
      } else {
        this.$emit('delete-document', this.document)
        this.$refs.deleteDocumentModal.hide()
      }
    },
    truncateText(value) {
      if (value === null || value === '' || value === undefined || value === -1 || value === '-1') {
        return '-'
      } else {
        if (value.length > 60) {
          return value.substring(0, 57) + '...'
        }
        return value
      }
    },
    handleDragStart(item) {
      // Set the dragged item's ID in the data transfer object
      event.dataTransfer.setData('itemId', item.mediaId)
    }
  }
}
</script>

<style lang="scss" scoped>
  .title-card {
    font-weight: bold !important;
    margin-bottom: 0;
    line-height: 2rem;
  }
  .current-version {
    font-size: 0.9rem;
  }
  :deep(.green.badge-secondary) {
        color: #fff !important;
    }
  .lower-height {
    margin-bottom: 0.75rem;
  }
  .lower-height :deep(.card-body) {
    padding: 0.5rem 1.25rem 0.75rem 1.25rem;
  }
  :deep(.min-width-360) {
    min-width: 360px;
  }
  :deep(.min-width-140) {
    min-width: 140px;
  }
  :deep(.close) {
    color: #fff !important;
  }
  :deep(.close:hover) {
    color: #fff !important;
  }
</style>
