<template>
  <div class="w-100">
    <div class="row">
      <div class="col-12">
        <h5 class="ml-3 mt-2 mb-0">
          {{ $t('message.notifications.title') }}
        </h5>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-right">
        <a
          class="mr-3"
          @click="markAllAsRead">
          <small>{{ $t('message.notifications.markAllAsRead') }}</small>
        </a>
      </div>
    </div>
    <div
      v-if="!streamClient"
      class="row">
      <div class="col text-center">
        <coozzy-spinner class="m-3" />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <b-list-group
          v-if="streamClient && employeeList.length > 0"
          class="scrollable pl-3"
          @scroll="handleScroll">
          <b-list-group-item
            v-for="(notification, index) in supportedNotifications"
            :key="index"
            class="row d-flex m-0 p-0"
            :class="hasClickAction(notification.verb) ? 'cursor-pointer' : ''"
            @click="notificationClicked(notification)"
            @click.middle="notificationClickedMiddle(notification)">
            <div class="col-11 p-0">
              <div class="d-flex w-100 align-items-center">
                <img
                  :src="getNotificationInfo(notification).logo !== '' ? getNotificationInfo(notification).logo : '/avatar.png'"
                  alt=""
                  :class="[
                    getNotificationInfo(notification).is_system ? 'notification-system-image' : 'notification-avatar-image',
                    getNotificationInfo(notification).is_company ? '' : 'circle'
                  ]">
                <p
                  v-if="notification.actor === 'system' && notification.verb === 'ticket.created'"
                  class="ml-1 mb-1">
                  {{ $t('message.generic.system') }}
                </p>
              </div>
              <p class="mb-0 notification-message">
                {{ getNotificationInfo(notification).message }}
              </p>
              <p class="notification-date mb-0">
                {{ getTimeSince(notification.time+'Z', false) }}
              </p>
            </div>
            <div class="col-1 p-0 vertical-align m-auto text-center">
              <small v-if="!getNotificationInfo(notification).is_read">
                <font-awesome-icon
                  icon="circle"
                  class="fa" />
              </small>
            </div>
            <hr class="col-10 my-2">
          </b-list-group-item>
        </b-list-group>
      </div>
    </div>
    <reminder-details-modal
      v-if="selectedReminder !== null"
      ref="detail-reminder-modal"
      :key="selectedReminder.id"
      :reminder-detail="selectedReminder"
      @create-reminder-click="createReminderClicked" />
    <reminder-creation-modal
      v-if="selectedReminder !== null"
      ref="modal-creation"
      :key="'modal-creation'+selectedReminder.id"
      :reminder-detail="selectedReminder"
      :available-employees="employeeList" />
    <event-creation-modal
      v-if="selectedEvent"
      ref="event-modal-creation"
      :key="selectedEvent !== null ? 'event-modal-creation-'+selectedEvent.id : 'event-modal-creation-'+uniqueId"
      :event-detail="selectedEvent"
      :available-employees="employeeList" />
    <b-modal
      id="system-updated-modal"
      ref="system-updated-modal"
      no-close-on-backdrop
      hide-footer>
      <div slot="modal-title">
        Update
      </div>
      <!-- eslint-disable vue/no-v-html -->
      <p v-html="updateMessage" />
      <!-- eslint-enable vue/no-v-html -->
      <a
        href="#"
        class="float-right"
        @click="$router.go()">
        {{ $t('message.generic.updateNow') }}
      </a>
    </b-modal>
  </div>
</template>

<script>
import { user } from '@/mixins/user'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import ReminderDetailsModal from '@/calendar/components/ReminderDetailsModal'
import ReminderCreationModal from '@/calendar/components/ReminderCreationModal'
import CalendarApi from '@/misc/apis/CalendarApi'
import { notification } from '@/mixins/notification'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner'
import { mapActions, mapGetters } from 'vuex'
import EventCreationModal from '@/calendar/components/EventCreationModal'
import { routeChecks } from '@/mixins/routeChecks'
import { dateUtils } from '@/mixins/dateUtils'
import ObjectApi from '@/misc/apis/ObjectApi'
import { eventBus } from '@/mixins/mail'

library.add(faCircle)

const numberOfNotifications = 8

export default {
  name: 'CoozzyNotification',
  components: {
    CoozzySpinner,
    ReminderDetailsModal,
    ReminderCreationModal,
    EventCreationModal
  },
  mixins: [user, notification, routeChecks, dateUtils],
  props: {
    init: {
      type: String,
      default: 'false'
    }
  },
  data() {
    return {
      visibleListNotification: [],
      listNotification: [],
      notificationUnread: null,
      selectedReminder: null,
      page: 0,
      feed: null,
      selectedEvent: null,
      uniqueId: '',
      updateMessage: '',
      objectsInMarketing: [],
      lastUsedCalendarView: 'week'
    }
  },
  computed: {
    employeeList() {
      if (this.getEmployees().length > 0) {
        const employees = this.getEmployees()
        const array = []
        if (employees && employees.length > 0) {
          employees.forEach((emp) => {
            if (typeof (emp) !== 'undefined') {
              array.push(emp)
            }
          })
        }
        return array
      }
      return []
    },
    supportedNotifications() {
      return this.visibleListNotification.filter(notification => {
        return [
          'system.updated',
          'share.changed',
          'reminder.created',
          'reminder.employee-changed',
          'event.created',
          'event.alarm.triggered',
          'invitation.accepted',
          'invitation.declined',
          'object.marked-for-marketing',
          'ticket.created',
          'ticket.updated',
          'ticket.deleted',
          'ticket.statusChanged',
          'ticket.assigned',
          'ticket.commentCreated',
          'ticket.commentUpdated',
          'ticket.commentDeleted',
          'sign-request.completed',
          'object.marketing-contact-assigned',
          'ticket.overdue'
        ].includes(notification.verb)
      })
    },
    getCurrentLanguage() {
      return this.$store.getters['internationalization/getCurrentLanguage']
    }
  },
  created () {
    if (localStorage.getItem('lastUsedCalendarView')) {
      this.lastUsedCalendarView = localStorage.getItem('lastUsedCalendarView')
    }
    eventBus.$on('lastUsedCalendarView', (value) => {
      if (value !== '') {
        this.lastUsedCalendarView = value
      }
    })
  },
  async mounted() {
    setTimeout(async () => {
      await this.loadGetStreamio()
      this.getNotification()
      this.listIdsInMarketing()
      await this.loadEmployees()
    }, 300, this)
  },
  methods: {
    ...mapActions('employee', ['loadEmployees']),
    ...mapGetters('employee', ['getEmployees']),
    listIdsInMarketing() {
      ObjectApi.listIdsInMarketing(this.accountId).then(response => {
        this.objectsInMarketing = response.ids
      })
    },
    callback(data) {
      data.new.forEach((a) => {
        a.is_read = false
        this.listNotification.unshift(a)
        this.visibleListNotification.unshift(a)
      })
      const unreadInvitation = data.new.filter(x => !x.is_read && ['invitation.accepted', 'invitation.declined'].includes(x.verb))
      if (unreadInvitation.length > 0) {
        eventBus.$emit('updateEventsList')
      }
      this.notificationUnread = this.notificationUnread + data.new.length
      this.$emit('count-unread', this.notificationUnread)
      this.$emit('event-reminder-list', this.listNotification.filter(x => x.verb === 'event.alarm.triggered' && !x.is_read && this.isInFuture(x)))
      // this.feed.get({
      //   limit: data.new.length
      // }).then(response => {
      //   console.log('response', JSON.stringify(response.results))
      //   response.results.forEach((a) => {
      //     a.activities.forEach((b) => {
      //       b.group = a.id
      //       b.is_read = a.is_read
      //       this.listNotification.unshift(b)
      //       this.visibleListNotification.unshift(b)
      //     })
      //   })
      //   const unreadInvitation = response.results.filter(x => !x.is_read && ['invitation.accepted', 'invitation.declined'].includes(x.verb))
      //   if (unreadInvitation.length > 0) {
      //     eventBus.$emit('updateEventsList')
      //   }
      //   this.$emit('event-reminder-list', this.listNotification.filter(x => x.verb === 'event.alarm.triggered' && !x.is_read && this.isInFuture(x)))
      // })
      // this.notificationUnread = this.notificationUnread + data.new.length
      // this.$emit('count-unread', this.notificationUnread)
    },
    createReminderClicked(reminder) {
      this.remainderForEdit = reminder
      this.$nextTick(function () {
        this.$refs['modal-creation'].show()
      })
    },
    getNotification() {
      if (this.streamClient) {
        const entryArray = this.$store.getters['user/getUserId'].split('|')
        this.feed = this.streamClient.feed('notification', entryArray[1], this.$store.getters['notification/getToken'])
        this.feed.get({
          limit: numberOfNotifications,
          offset: this.page * numberOfNotifications
        }).then(response => {
          this.notificationUnread = response.unread
          this.$emit('count-unread', response.unread)
          response.results.forEach((a) => {
            a.activities.forEach((b) => {
              b.group = a.id
              b.is_read = a.is_read
              this.listNotification.push(b)
            })
          })
          this.$nextTick(function () {
            this.$emit('event-reminder-list', this.listNotification.filter(x => x.verb === 'event.alarm.triggered' && !x.is_read && this.isInFuture(x)))
          })
          this.visibleListNotification = this.listNotification.slice(0, numberOfNotifications)
        })
        this.feed.subscribe(this.callback)
      }
    },
    getNotificationInfo(notification) {
      const employeeActor = this.employeeList.find(employee => employee.id.includes(notification.actor))
      switch (notification.verb) {
        case 'system.updated':
          return {
            logo: `${process.env.VUE_APP_BASE_API_URL}/img/prop-base.png`,
            message: this.$t('message.notifications.messages.systemUpdated'),
            is_read: notification.is_read,
            is_system: 'true'
          }
        case 'event.alarm.triggered':
          return {
            logo: `${process.env.VUE_APP_BASE_API_URL}/img/prop-base.png`,
            message: this.$t('message.notifications.messages.eventReminder', { title: notification.eventTitle, startDate: this.getDateFormat(notification.eventStartDate), startTime: this.getTimeFormat(notification.eventStarttime) }),
            is_read: notification.is_read,
            is_system: 'true'
          }
        case 'share.changed':
          if (notification.scope === 'marketing') {
            return {
              logo: notification.ownerLogoUrl,
              message: this.$t('message.notifications.messages.shareChangedMarketing', {
                name: notification.owner,
                objectCount: notification.objectCount
              }),
              is_read: notification.is_read,
              is_company: true
            }
          } else if (notification.scope === 'management') {
            return {
              logo: notification.ownerLogoUrl,
              message: this.$t('message.notifications.messages.shareChangedManagement', {
                name: notification.owner,
                objectCount: notification.objectCount
              }),
              is_read: notification.is_read,
              is_company: true
            }
          } else if (notification.scope === 'accounting') {
            return {
              logo: notification.ownerLogoUrl,
              message: this.$t('message.notifications.messages.shareChangedAccounting', {
                name: notification.owner,
                objectCount: notification.objectCount
              }),
              is_read: notification.is_read,
              is_company: true
            }
          } else if (notification.scope === 'asset') {
            return {
              logo: notification.ownerLogoUrl,
              message: this.$t('message.notifications.messages.shareChangedAsset', {
                name: notification.owner,
                objectCount: notification.objectCount
              }),
              is_read: notification.is_read,
              is_company: true
            }
          } else {
            return {
              logo: notification.ownerLogoUrl,
              message: this.$t('message.notifications.messages.shareChangedUndefined', {
                name: notification.owner,
                objectCount: notification.objectCount
              }),
              is_read: notification.is_read,
              is_company: true
            }
          }
        case 'reminder.created':
          return {
            logo: employeeActor ? employeeActor.profile.pictureUrl : '',
            message: this.$t('message.notifications.messages.reminderCreated', { name: notification.createdBy }),
            is_read: notification.is_read,
            is_company: false
          }
        case 'reminder.employee-changed':
          return {
            logo: employeeActor ? employeeActor.profile.pictureUrl : '',
            message: this.$t('message.notifications.messages.reminderEmployeeChanged', { name: notification.createdBy }),
            is_read: notification.is_read,
            is_company: false
          }
        case 'event.created':
          return {
            logo: employeeActor ? employeeActor.profile.pictureUrl : '',
            message: this.$t('message.notifications.messages.eventCreated', { name: notification.createdBy }),
            is_read: notification.is_read,
            is_company: false
          }
        case 'invitation.accepted':
          return {
            message: this.$t('message.notifications.messages.invitationAccepted', {
              name: notification.actor,
              title: notification.title,
              date: notification.startDate && notification.startDate.year
                ? `${notification.startDate.day.toString().padStart(2, '0')}.${notification.startDate.month.toString().padStart(2, '0')}.${notification.startDate.year}`
                : 'n/a'
            }),
            is_read: notification.is_read,
            is_company: false
          }
        case 'invitation.declined':
          return {
            message: this.$t('message.notifications.messages.invitationDeclined', {
              name: notification.actor,
              title: notification.title,
              date: notification.startDate && notification.startDate.year
                ? `${notification.startDate.day.toString().padStart(2, '0')}.${notification.startDate.month.toString().padStart(2, '0')}.${notification.startDate.year}`
                : 'n/a'
            }),
            is_read: notification.is_read,
            is_company: false
          }
        case 'object.marked-for-marketing': {
          const sameAccount = notification.sameAccount
          return {
            logo: sameAccount ? employeeActor.profile.pictureUrl : notification.managementAccountLogoUrl,
            message: this.$t('message.notifications.messages.objectMarkedForMarketing', { name: sameAccount ? notification.createdBy : notification.managementAccountName }),
            is_read: notification.is_read,
            is_company: !sameAccount
          }
        }
        case 'ticket.created':
        case 'ticket.deleted':
        case 'ticket.updated':
        case 'ticket.statusChanged':
        case 'ticket.assigned':
        case 'ticket.commentCreated':
        case 'ticket.commentUpdated':
        case 'ticket.commentDeleted':
        case 'ticket.overdue': {
          return {
            logo: notification.actorProfilePictureUrl || '',
            message: this.currentLanguage === 'en' && notification.subjectEn ? notification.subjectEn : this.currentLanguage === 'it' && notification.subjectIt ? notification.subjectIt : notification.subject,
            is_read: notification.is_read,
            is_company: false
          }
        }
        case 'sign-request.completed':
        case 'object.marketing-contact-assigned': {
          return {
            logo: notification.actorProfilePictureUrl || '',
            message: this.currentLanguage === 'en' && notification.messageEn ? notification.messageEn : this.currentLanguage === 'it' && notification.messageIt ? notification.messageIt : notification.message,
            is_read: notification.is_read,
            is_company: false
          }
        }
      }
    },
    handleScroll({
      target: {
        scrollTop,
        clientHeight,
        scrollHeight
      }
    }) {
      if (scrollTop + clientHeight >= scrollHeight) this.loadMoreNotification()
    },
    loadMoreNotification() {
      this.page = this.page + 1
      if ((this.visibleListNotification.length + numberOfNotifications) < this.listNotification.length) {
        this.visibleListNotification = this.visibleListNotification.concat(this.listNotification.slice(this.visibleListNotification.length, this.visibleListNotification.length + numberOfNotifications))
      } else {
        this.feed.get({
          limit: numberOfNotifications,
          offset: this.page * numberOfNotifications
        }).then(response => {
          response.results.forEach((a) => {
            a.activities.forEach((b) => {
              b.is_read = a.is_read
              b.group = a.id
              this.listNotification.push(b)
            })
          })
          this.$nextTick(function () {
            this.$emit('event-reminder-list', this.listNotification.filter(x => x.verb === 'event.alarm.triggered' && !x.is_read))
          })
          this.visibleListNotification = this.visibleListNotification.concat(this.listNotification.slice(this.visibleListNotification.length, this.visibleListNotification.length + numberOfNotifications))
        })
      }
    },
    getDateFormat(dateObject) {
      if (!dateObject.day) {
        return ''
      }
      return dateObject.day.toString().padStart(2, '0') + '.' + dateObject.month.toString().padStart(2, '0') + '.' + dateObject.year
    },
    getTimeFormat(timeObject) {
      if (!timeObject.hours) {
        return ''
      }
      return timeObject.hours.toString().padStart(2, '0') + ':' + timeObject.minutes.toString().padStart(2, '0')
    },
    isInFuture(notification) {
      if (!notification.eventStartDate?.array && !notification.eventStarttime?.array) {
        return false
      } else if (!notification.eventStarttime.array) {
        return (Date.parse((notification.eventStartDate.array[0] + '-' + notification.eventStartDate.array[1] + '-' + notification.eventStartDate.array[2] + ' ' + '00' + ':' + '00' + ':00')) / 1000) > new Date().getTime() / 1000
      } else {
        return (Date.parse((notification.eventStartDate.array[0] + '-' + notification.eventStartDate.array[1] + '-' + notification.eventStartDate.array[2] + ' ' + (notification.eventStarttime?.array[0] || '00') + ':' + (notification.eventStarttime?.array[1] || '00') + ':00')) / 1000) > new Date().getTime() / 1000
      }
    },
    markAllAsRead() {
      const params = { mark_read: true }
      this.feed.get(params)
      this.notificationUnread = 0
      this.$emit('count-unread', 0)
      this.listNotification.forEach((notification) => {
        notification.is_read = true
      })
      this.visibleListNotification.forEach((notification) => {
        notification.is_read = true
      })
    },
    hasClickAction(notificationVerb) {
      return [
        'system.updated',
        'reminder.created',
        'reminder.employee-changed',
        'event.created',
        'invitation.accepted',
        'invitation.declined',
        'object.marked-for-marketing',
        'ticket.created',
        'ticket.updated',
        'ticket.deleted',
        'ticket.statusChanged',
        'ticket.assigned',
        'ticket.commentCreated',
        'ticket.commentUpdated',
        'ticket.commentDeleted',
        'sign-request.completed',
        'object.marketing-contact-assigned',
        'ticket.overdue'
      ].includes(notificationVerb)
    },
    notificationClickedMiddle(notification) {
      const listVerbsTicket = ['ticket.created', 'ticket.deleted', 'ticket.updated', 'ticket.statusChanged', 'ticket.assigned', 'ticket.commentCreated', 'ticket.commentUpdated', 'ticket.commentDeleted', 'ticket.overdue']
      if (listVerbsTicket.includes(notification.verb)) {
        if (!notification.key || (this.$route.params.id === notification.key)) {
          return
        }
        const routeData = this.$router.resolve({
          name: this.moduleRoutePrefix + 'TicketDetailsView',
          params: { id: notification.key }
        })
        window.open(routeData.href, '_blank')
      } else if (notification.verb === 'sign-request.completed') {
        let route = ''
        if (this.hasAccessMarketBase && notification.objectId && notification.requestId) {
          if (this.objectsInMarketing.includes(notification.objectId)) {
            route = this.$router.resolve({
              name: 'MarketingObjectDetailsView',
              params: { id: notification.objectId },
              query: {
                view: 0,
                elementSelected: notification.requestId
              }
            })
            window.open(route.href, '_blank')
          }
        } else {
          if (notification.buildingId && notification.tenancyId) {
            route = this.$router.resolve({
              name: this.moduleRoutePrefix + 'BuildingDetailsView',
              params: { id: notification.buildingId },
              query: {
                view: 'tenants',
                elementSelected: notification.tenancyId
              }
            })
            window.open(route.href, '_blank')
          }
        }
      } else if (notification.verb === 'object.marketing-contact-assigned') {
        const routeData = this.$router.resolve({
          name: 'MarketingObjectDetailsView',
          params: { id: notification.object },
          query: {
            view: 0
          }
        })
        window.open(routeData.href, '_blank')
      }
    },
    notificationClicked(notification, onlyMarkAsRead = false) {
      if (!notification.is_read) {
        notification.is_read = true
        if (this.notificationUnread === 1) {
          this.notificationUnread = 0
          this.$emit('count-unread', this.notificationUnread)
        } else {
          if (this.notificationUnread > 0) {
            this.notificationUnread = this.notificationUnread - 1
            this.$emit('count-unread', this.notificationUnread)
          }
        }
      }
      this.feed.get({ mark_read: [notification.group] })
      if (onlyMarkAsRead) {
        return
      }
      switch (notification.verb) {
        case 'system.updated':
          if (this.currentLanguage === 'it' && notification.messageIt) {
            this.updateMessage = notification.messageIt
          } else if (this.currentLanguage === 'en' && notification.messageEn) {
            this.updateMessage = notification.messageEn
          } else {
            this.updateMessage = notification.message
          }
          this.$nextTick(function () {
            if (this.$refs['system-updated-modal']) {
              this.$refs['system-updated-modal'].show()
            }
          })
          break
        case 'reminder.created':
          CalendarApi.getReminderById(notification.object)
            .then((data) => {
              this.selectedReminder = data.reminder
              this.$nextTick(function () {
                this.$refs['detail-reminder-modal'].show()
              })
            })
            .catch(e => {
              console.log(e)
            })
          break
        case 'reminder.employee-changed':
          CalendarApi.getReminderById(notification.object)
            .then((data) => {
              this.selectedReminder = data.reminder
              this.$nextTick(function () {
                this.$refs['detail-reminder-modal'].show()
              })
            })
            .catch(e => {
              console.log(e)
            })
          break
        case 'event.created':
        case 'event.alarm.triggered':
          this.$router.replace({ name: this.isAdminModule ? 'AdminCalendarOverview' : this.isOwnerModule ? 'OwnerCalendarOverview' : this.isAccountingModule ? 'AccountingCalendarOverview' : this.isAssetModule ? 'AssetCalendarOverview' : 'MarketingCalendarOverview', params: { viewCalendar: 'week' }, query: { elementSelected: notification.eventId } })
          break
        case 'invitation.accepted':
        case 'invitation.declined':
          this.$router.push({
            name: this.moduleRoutePrefix + 'CalendarOverview',
            params: { viewCalendar: this.lastUsedCalendarView },
            query: { elementSelected: notification.object, startDate: this.dateObjectToTimeStamp(notification.startDate) }
          })
          break
        case 'object.marked-for-marketing':
          this.$router.push({
            name: 'MarketingObjectDetailsView',
            params: { id: notification.object }
          })
          break
        case 'ticket.created':
        case 'ticket.deleted':
        case 'ticket.updated':
        case 'ticket.statusChanged':
        case 'ticket.assigned':
        case 'ticket.commentCreated':
        case 'ticket.commentUpdated':
        case 'ticket.commentDeleted':
        case 'ticket.overdue':
          if (!notification.key) {
            return
          }
          if (this.$route.params.id === notification.key) {
            location.reload()
            return
          }
          this.$router.push({
            name: this.moduleRoutePrefix + 'TicketDetailsView',
            params: { id: notification.key }
          })
          break
        case 'sign-request.completed': {
          if (this.hasAccessMarketBase && notification.objectId && notification.requestId) {
            if (this.objectsInMarketing.includes(notification.objectId)) {
              this.$router.push({
                name: 'MarketingObjectDetailsView',
                params: { id: notification.objectId },
                query: {
                  view: 0,
                  elementSelected: notification.requestId
                }
              })
            }
          } else {
            if (notification.buildingId && notification.tenancyId) {
              this.$router.push({
                name: this.moduleRoutePrefix + 'BuildingDetailsView',
                params: { id: notification.buildingId },
                query: {
                  view: 'tenants',
                  elementSelected: notification.tenancyId
                }
              })
            }
          }
          break
        }
        case 'object.marketing-contact-assigned': {
          this.$router.push({
            name: 'MarketingObjectDetailsView',
            params: { id: notification.object },
            query: {
              view: 0
            }
          })
          break
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(.list-group-item) {
  border: 0;
  padding-bottom: 0;
  padding-top: 5px;
}

:deep(.list-group-item:first-child) {
  padding-top: 20px !important;
}

.scrollable {
  height: 400px;
  overflow-y: scroll;
}

.notification-system-image {
  max-width: 100px;
}

.notification-avatar-image {
  max-width: 30px;

  &.circle {
    border-radius: 50%;
  }
}

.notification-date {
  font-size: 80%;
  font-weight: 400;
}

.notification-message {
  white-space: normal;
  line-height: 18px;
}

.fa {
  color: red;
}
</style>
