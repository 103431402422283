<template>
  <section>
    <coozzy-card class="global-card no-top-border table-card">
      <div class="row justify-content-between pb-1">
        <div class="col pl-0">
          <h5 class="ml-3 pt-1">
            {{ landRegisterCount }} {{ $t('message.propertiesView.createLandRegister.title') }}
          </h5>
        </div>
        <div
          v-if="isEditor"
          class="col-12 col-md-2">
          <coozzy-button
            design="prop-green"
            class="w-100"
            @click="readOnly ? addLandRegister() : createLandRegister()">
            {{ $t('message.propertiesView.createLandRegister.add') }}
          </coozzy-button>
        </div>
      </div>
      <div class="row">
        <div
          class="col-md-2 col-sm-6 col-12 offset-md-10 mt-2 mb-2 mt-sm-0">
          <coozzy-form-select
            v-model="nbrPerPage"
            name="numberEntries"
            class="select-entries float-right">
            <option value="5">
              5 {{ $t('message.generic.entries') }}
            </option>
            <option value="10">
              10 {{ $t('message.generic.entries') }}
            </option>
            <option value="15">
              15 {{ $t('message.generic.entries') }}
            </option>
            <option value="20">
              20 {{ $t('message.generic.entries') }}
            </option>
            <option value="all">
              {{ $t('message.manageInterestedParties.filter.allEntries') }}
            </option>
          </coozzy-form-select>
        </div>
      </div>
      <div
        v-if="isLandRegisterLoading"
        class="text-center">
        <coozzy-spinner />
      </div>
      <b-table
        v-else
        ref="landRegisterTable"
        bordered
        hover
        responsive="true"
        stacked="md"
        selectable
        select-mode="single"
        :fields="fields"
        :items="plotList"
        :busy="isLandRegisterLoading"
        :table-class="'b-table-selecting'"
        @row-clicked="changeSelectedLandRegister">
        <template #head(landRegisterNumber)>
          {{ $t('message.propertiesView.landRegisterNumber') }}
        </template>
        <template #head(buildingNames)>
          {{ $t('message.generic.building') }}
        </template>
        <template #head(egrid)>
          {{ $t('message.propertiesView.egrid') }}
        </template>
        <template #head(plotArea)>
          {{ $t('message.advertisement.plotArea') }}
        </template>
        <template #head(constructionZone)>
          {{ $t('message.propertiesView.constructionZone') }}
        </template>
        <template #head(plotType)>
          {{ $t('message.propertiesView.createLandRegister.plotType') }}
        </template>
        <template #head(cadastralValue)>
          {{ $t('message.propertiesView.createLandRegister.cadastralValue') }}
        </template>
        <template #head(purchasePrice)>
          {{ $t('message.propertiesView.createLandRegister.plotPurchasePrice') }}
        </template>
        <template #head(utilizationZone)>
          {{ $t('message.propertiesView.createLandRegister.utilizationZone') }}
        </template>
        <template #cell(landRegisterNumber)="data">
          {{ data.item.landRegisterNumber | displayOptionalValue }}
        </template>
        <template #cell(buildingNames)="data">
          <router-link
            v-for="(building, buildingIndex) in buildingsByPlotid(data.item.id)"
            :key="buildingIndex"
            :to="redirectToBuildingDetail(building.id)">
            {{ building.name }}<br>
          </router-link>
        </template>
        <template #cell(egrid)="data">
          {{ data.item.egrid | displayOptionalValue }}
        </template>
        <template #cell(plotArea)="data">
          {{ data.item.plotArea | formatArea }}
        </template>
        <template #cell(constructionZone)="data">
          {{ data.item.constructionZone | displayOptionalValue }}
        </template>
        <template #cell(plotType)="data">
          {{ data.item.plotType | displayOptionalValue }}
        </template>
        <template #cell(cadastralValue)="data">
          {{ data.item.cadastralValue | formatPrice }}
        </template>
        <template #cell(purchasePrice)="data">
          {{ data.item.purchasePrice | formatPrice }}
        </template>
        <template #cell(utilizationZone)="data">
          {{ data.item.utilizationZone | displayOptionalValue }}
        </template>
      </b-table>

      <b-pagination
        v-if="nbrPerPage !== 'all'"
        v-model="currentPage"
        align="center"
        class="mt-3 mb-3"
        :total-rows="listLandRegister ? listLandRegister.length : 0"
        :per-page="nbrPerPage" />
    </coozzy-card>
    <div
      v-if="selectedLandRegister">
      <coozzy-card class="global-card">
        <div class="row">
          <div class="col">
            <land-register
              ref="landRegister"
              :key="`landRegister-${selectedLandRegister.id || selectedLandRegister.internalId}`"
              :index="-1"
              :property="property"
              :building-list="buildingList"
              :land-register="selectedLandRegister"
              :read-only="readOnly && !selectedLandRegister.isNewItem"
              @change-plots="updateLandRegister"
              @change-buildings="updateBuildings"
              @remove-confirmed="removeRegister" />
          </div>
        </div>
      </coozzy-card>
    </div>
  </section>
</template>

<script>
import CoozzyCard from '../../framework/components/card/CoozzyCard'
import CoozzyButton from '../../framework/components/button/CoozzyButton'
import CoozzyFormSelect from '@/framework/components/form/select/CoozzyFormSelect'
import { tables } from '@/mixins/tables'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner'
import { routeChecks } from '@/mixins/routeChecks'
import { dateUtils } from '@/mixins/dateUtils'
import { user } from '@/mixins/user'
import ObjectApi from '@/misc/apis/ObjectApi'
import LandRegister from '@/property/LandRegister'
import Vue from 'vue'

export default {
  name: 'LandRegisterTab',
  components: {
    CoozzySpinner,
    CoozzyFormSelect,
    CoozzyButton,
    LandRegister,
    CoozzyCard
  },
  mixins: [dateUtils, tables, routeChecks, user],
  props: {
    property: {
      type: Object,
      default: null
    },
    elementSelected: {
      type: String,
      default: ''
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    addNewItem: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      nbrPerPage: 5,
      currentPage: 1,
      selectedLandRegister: null,
      deviceToDuplicate: null,
      isBusy: false,
      isLandRegisterLoading: false,
      deletedPlotsIds: [],
      buildingList: [],
      fields: [
        {
          key: 'landRegisterNumber',
          label: this.$t('message.propertiesView.landRegisterNumber'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'buildingNames',
          label: this.$t('message.generic.building'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'egrid',
          label: this.$t('message.propertiesView.egrid'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'plotArea',
          label: this.$t('message.advertisement.plotArea'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'constructionZone',
          label: this.$t('message.propertiesView.constructionZone'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'plotType',
          label: this.$t('message.propertiesView.createLandRegister.plotType'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'cadastralValue',
          label: this.$t('message.propertiesView.createLandRegister.cadastralValue'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'purchasePrice',
          label: this.$t('message.propertiesView.createLandRegister.plotPurchasePrice'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'utilizationZone',
          label: this.$t('message.propertiesView.createLandRegister.utilizationZone'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        }
      ],
      listLandRegister: []
    }
  },
  computed: {
    landRegisterCount() {
      return this.listLandRegister !== null ? this.listLandRegister.length : 0
    },
    plotList() {
      if (this.nbrPerPage === 'all') {
        return this.listLandRegister
      } else {
        return this.listLandRegister.slice((this.currentPage - 1) * this.nbrPerPage, this.currentPage * this.nbrPerPage)
      }
    }
  },
  watch: {
    nbrPerPage: function (newVal) {
      localStorage.setItem('createDevicesTab-nbrPerPage', newVal)
    }
  },
  async mounted() {
    this.nbrPerPage = localStorage.getItem('createDevicesTab-nbrPerPage') ? localStorage.getItem('createDevicesTab-nbrPerPage') === 'all' ? 'all' : parseInt(localStorage.getItem('createDevicesTab-nbrPerPage')) : 5
    this.isLandRegisterLoading = true
    ObjectApi.listByPropertyId(this.property.id)
      .then(response => {
        this.listLandRegister = response.plots
      })
    this.getBuildingList()
    this.$nextTick(function () {
      this.isLandRegisterLoading = false
    })
    this.$nextTick(function () {
      if (this.elementSelected) {
        setTimeout(() => {
          this.changeSelectedLandRegister(this.listLandRegister.find(l => l.id === this.elementSelected || l.internalId === this.elementSelected))
          const indexOfPlot = this.listLandRegister.map(function(e) { return e.id }).indexOf(this.elementSelected)
          if (indexOfPlot !== -1) {
            this.currentPage = (Math.floor((indexOfPlot) / this.nbrPerPage)) + 1
            this.listLandRegister[indexOfPlot]._rowVariant = 'table-dynamic-clicked table-b-table-row-selected b-table-row-selected table-primary'
          }
        }, 1000)
      }
      if (this.addNewItem) {
        setTimeout(() => {
          this.createLandRegister()
        }, 1000)
      }
    })
  },
  methods: {
    addLandRegister() {
      this.$router.push({
        name: this.isOwnerModule ? 'OwnerPropertyEditingView' : this.isAssetModule ? 'AssetPropertyEditingView' : this.isAccountingModule ? 'AccountingPropertyEditingView' : 'AdminPropertyEditingView',
        params: { id: this.property.id },
        query: {
          view: 'landRegister',
          elementSelected: '',
          addNewItemLandRegister: true
        }
      })
    },
    createLandRegister() {
      this.isBusy = true
      this.clearRowVariants()
      this.listLandRegister.push({
        documentIds: [],
        landRegisterNumber: '',
        plotArea: null,
        egrid: '',
        constructionZone: '',
        note: '',
        cadastralValue: null,
        easements: '',
        inventories: '',
        loads: '',
        noiseSensitivity: '',
        plotType: '',
        priorityNotices: '',
        ownerId: this.property.ownerId,
        propertyId: this.property.id,
        purchasePrice: null,
        realEstateRights: '',
        utilizationZone: '',
        internalId: Date.now(),
        isNewItem: true
      })
      this.$nextTick(function () {
        const max = this.listLandRegister.filter(x => x.internalId).reduce(function(prev, current) {
          return prev.internalId > current.internalId ? prev : current
        })
        this.selectedLandRegister = max
        this.$emit('selected-land-register', max)
        this.clearRowVariants()
        const i = this.listLandRegister.findIndex(x => x.internalId && x.internalId === max.internalId)
        this.currentPage = (Math.floor(i / this.nbrPerPage)) + 1
        this.listLandRegister[i]._rowVariant = 'table-dynamic-clicked table-b-table-row-selected b-table-row-selected table-primary'
        if (this.$refs['land-register-table']) {
          this.$refs['land-register-table'].refresh()
        }
        this.isBusy = false
      })
    },
    updateLandRegister(plot) {
      const id = plot.id || plot.internalId
      const index = this.listLandRegister.findIndex(pl => pl.id === id || pl.internalId === id)
      if (index !== -1) {
        this.$set(this.listLandRegister, index, plot)
      }
      this.$emit('change-land-register', true)
    },
    changeSelectedLandRegister(item) {
      for (let i = 0; i < this.listLandRegister.length; i++) {
        if (this.listLandRegister[i]._rowVariant === 'table-dynamic-clicked table-b-table-row-selected b-table-row-selected table-primary') {
          this.listLandRegister[i]._rowVariant = ''
        }
      }
      this.selectedLandRegister = item
      this.$emit('selected-land-register', item)
    },
    clearRowVariants() {
      for (const land of this.listLandRegister) {
        if (land._rowVariant === 'table-dynamic-clicked table-b-table-row-selected b-table-row-selected table-primary') {
          land._rowVariant = ''
        }
      }
    },
    removeRegister(id) {
      const index = this.listLandRegister.findIndex(pl => pl.id === id || pl.internalId === id)
      if (index >= 0) {
        this.$emit('has-change', true)
        if (!('internalId' in this.listLandRegister[index]) && ('id' in this.listLandRegister[index])) {
          if (this.listLandRegister[index].id !== undefined) {
            this.deletedPlotsIds.push(this.listLandRegister[index].id)
          }
        }
        this.$delete(this.listLandRegister, index)
        this.selectedLandRegister = null
        this.$nextTick(function () {
          this.$emit('remove-land-register', this.listLandRegister[index])
        })
      }
    },
    async saveData() {
      this.isLandRegisterLoading = true
      this.$emit('set-loading', true)
      const promises = []
      const promisesObject = []
      const arrayAdd = []
      const arrayUpdate = []
      for (const plot of this.listLandRegister) {
        if (plot.isNewItem) {
          arrayAdd.push(plot)
        } else {
          arrayUpdate.push(plot)
        }
      }
      if (arrayAdd.length > 0) {
        promises.push(ObjectApi.createPlots(arrayAdd))
      }
      if (arrayUpdate.length > 0) {
        promises.push(ObjectApi.updatePlots(arrayUpdate))
      }
      for (let index = 0; index < this.deletedPlotsIds.length; index++) {
        promises.push(ObjectApi.deletePlots(this.deletedPlotsIds[index]))
      }
      let selectedPlot = this.elementSelected || ''
      return Promise.all(promises)
        .then((response) => {
          if (arrayAdd.length > 0) {
            response[0].plots.forEach(element => {
              this.listLandRegister.find(x => x.internalId).id = element.id
              if (this.listLandRegister.find(x => x.internalId)._rowVariant !== '') {
                selectedPlot = element.id
                this.selectedLandRegister = this.listLandRegister.find(x => x.internalId)
                this.$emit('selected-land-register', this.listLandRegister.find(x => x.internalId))
              }
            })
          }
          for (let index = 0; index < this.buildingList.length; index++) {
            for (let i = 0; i < this.buildingList[index].plotIds.length; i++) {
              this.listLandRegister.filter(x => x.internalId).forEach(land => {
                if (land.internalId === this.buildingList[index].plotIds[i]) {
                  this.buildingList[index].plotIds[i] = land.id
                }
              })
            }
            promisesObject.push(ObjectApi.updateBuilding(
              this.buildingList[index].id,
              this.buildingList[index].name,
              this.buildingList[index].objectIds,
              this.buildingList[index].egid,
              this.buildingList[index].number,
              this.buildingList[index].imageMediaId,
              this.buildingList[index].realEstateNumber,
              this.buildingList[index].characteristics,
              this.buildingList[index].constructionYear,
              this.buildingList[index].renovationYear,
              this.buildingList[index].buildingZone,
              this.buildingList[index].plotIds,
              this.buildingList[index].plotArea,
              this.buildingList[index].buildingArea,
              this.buildingList[index].volume,
              this.buildingList[index].floors,
              this.buildingList[index].locationQuality,
              this.buildingList[index].constructionTypes,
              this.buildingList[index].sia,
              this.buildingList[index].distances,
              this.buildingList[index].energySources,
              this.buildingList[index].documentIds,
              this.buildingList[index].acquisitionDate,
              this.buildingList[index].address,
              this.buildingList[index].note,
              this.buildingList[index].contractAmendments,
              this.buildingList[index].rating,
              this.buildingList[index].buildingStrategy,
              this.buildingList[index].buildingValue
            ))
          }
          return Promise.all(promisesObject)
            .then(() => {
              Vue.toasted.show(this.$t('message.successMessages.plotSaved'), { type: 'success' })
              this.isLandRegisterLoading = false
              this.deletedPlotsIds = []
              this.$router.push({
                name: this.isOwnerModule ? 'OwnerPropertyEditingView' : this.isAssetModule ? 'AssetPropertyEditingView' : this.isAccountingModule ? 'AccountingPropertyEditingView' : 'AdminPropertyEditingView',
                params: { id: this.property.id },
                query: {
                  view: 'landRegister',
                  elementSelected: selectedPlot
                }
              })
            })
            .catch(e => {
              console.log(e)
              this.$emit('set-loading', false)
              this.isLandRegisterLoading = false
              Vue.toasted.show(this.$t('message.savingErrors.building'), { type: 'error' })
            })
        })
        .catch(e => {
          console.log(e)
          this.$emit('set-loading', false)
          this.isLandRegisterLoading = false
          Vue.toasted.show(this.$t('message.savingErrors.plot'), { type: 'error' })
        })
    },
    getBuildingList() {
      ObjectApi.getBuildingsListByIds(this.property.buildingIds)
        .then(async response => {
          this.buildingList = response.buildings
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.building'), { type: 'error' })
        })
    },
    buildingsByPlotid(plotId) {
      return this.buildingList.filter(x => x.plotIds.includes(plotId))
    },
    updateBuildings(values) {
      for (let index = 0; index < this.buildingList.length; index++) {
        if (values.buildings.includes(this.buildingList[index].id)) {
          if (this.buildingList[index].plotIds.filter(x => x === values.landregister.id || x === values.landregister.internalId).length === 0) {
            this.buildingList[index].plotIds.push(values.landregister.id || values.landregister.internalId)
          }
        } else {
          this.buildingList[index].plotIds = this.buildingList[index].plotIds.filter(x => x !== values.landregister.id)
        }
      }
      this.$emit('change-land-register', true)
    },
    redirectToBuildingDetail(buildingId) {
      let UrlName = 'OwnerBuildingDetailsView'
      if (this.isOwnerModule) {
        UrlName = 'OwnerBuildingDetailsView'
      } else if (this.isAdminModule) {
        UrlName = 'AdminBuildingDetailsView'
      } else if (this.isAssetModule) {
        UrlName = 'AssetBuildingDetailsView'
      } else if (this.isAccountingModule) {
        UrlName = 'AccountingBuildingDetailsView'
      }
      return ({
        name: UrlName,
        params: { id: buildingId },
        query: { view: 'building' }
      })
    }
  }

}
</script>
<style lang="scss" scoped>

</style>
