<template>
  <div>
    <div
      v-if="loading"
      class="text-center">
      <coozzy-spinner />
    </div>
    <b-collapse
      v-if="!loading && lastTenancy.id"
      :visible="true"
      class="row col-12 p-0">
      <div
        id="titleObject"
        ref="titleObject"
        class="collapse-title col-12 -mb-3">
        <h5 class="cursor-pointer">
          {{ $t('message.enterTermination.terminationDetails') + ' ' + object.number + ' - ' + object.name }}
        </h5>
      </div>
      <object-tenancy
        v-if="lastTenancy.tenant"
        :ref="`object-last-tenancy-${lastTenancy.id}`"
        :enable-tenant-selection="false"
        :expand-by-default="false"
        :hide-actions="true"
        :index="lastTenancy.id"
        :object="object"
        :read-only="true"
        :tenancy="lastTenancy"
        mode="adding" />
    </b-collapse>
    <div class="col-12 p-0">
      <h6 class="d-inline-block font-weight-bold mt-2 mb-1">
        {{ $t('message.onBoarding.buildings.objects.tenancies.terminationTenant') }}
      </h6>
      <div class="row details-row-bg-1 py-01rem">
        <div class="col-sm-12 col-md-3">
          <coozzy-form-input-numeric
            v-if="lastTenancy.terminationTenant"
            v-model="tenantPeriodInMonths"
            :disabled="true"
            :is-integer="true"
            :name="$t('message.onBoarding.buildings.objects.tenancies.noticePeriod')"
            min="0"
            type="number" />
        </div>
        <div class="col-sm-12 col-md-3">
          <coozzy-form-input
            v-if="lastTenancy.terminationTenant"
            v-model="dates.tenantEarliestDate"
            :disabled="true"
            :filter="'formatDate'"
            :is-read-only="false"
            :name="$t('message.onBoarding.buildings.objects.tenancies.earliestQuit')"
            type="date"
            @blur="dateToObject()" />
        </div>
        <div
          class="col-sm-12 col-md-6">
          <coozzy-multiselect
            v-if="lastTenancy.terminationTenant"
            id="tenantPossibleMonths"
            v-model="tenantPossibleMonths"
            :disabled="true"
            :options="translatedMonths"
            label="label"
            track-by="label">
            {{ $t('message.onBoarding.buildings.objects.tenancies.noticeToQuit') }}
          </coozzy-multiselect>
        </div>
      </div>
      <h6 class="d-inline-block font-weight-bold mt-2 mb-1">
        {{ $t('message.onBoarding.buildings.objects.tenancies.terminationLandlord') }}
      </h6>
      <div class="row details-row-bg-1 py-01rem">
        <div class="col-sm-12 col-md-3">
          <coozzy-form-input-numeric
            v-if="lastTenancy.terminationLandlord"
            v-model="landlordPeriodInMonths"
            :disabled="true"
            :is-integer="true"
            :name="$t('message.onBoarding.buildings.objects.tenancies.noticePeriod')"
            min="0"
            type="number" />
        </div>
        <div class="col-sm-12 col-md-3">
          <coozzy-form-input
            v-if="lastTenancy.terminationLandlord"
            v-model="dates.landlordEarliestDate"
            :disabled="true"
            :is-read-only="false"
            :name="$t('message.onBoarding.buildings.objects.tenancies.earliestQuit')"
            type="date"
            @blur="dateToObject()" />
        </div>
        <div
          class="col-sm-12 col-md-6">
          <coozzy-multiselect
            v-if="lastTenancy.terminationLandlord"
            id="landlordPossibleMonths"
            v-model="landlordPossibleMonths"
            :disabled="true"
            :options="translatedMonths"
            label="label"
            track-by="label">
            {{ $t('message.onBoarding.buildings.objects.tenancies.noticeToQuit') }}
          </coozzy-multiselect>
        </div>
      </div>
      <div class="row details-row-bg-1 py-01rem">
        <div
          class="col-sm-12 col-md-3">
          <label for="terminatedBy">
            {{ $t('message.onBoarding.buildings.objects.tenancies.terminationResponsible') + '*' }}
          </label>
          <coozzy-form-select
            id="terminatedBy"
            v-model="tenancy.terminatedBy"
            :state="$v.tenancy.terminatedBy.$dirty && $v.tenancy.terminatedBy.$error ? false : null">
            <option value="TERMINATED_BY_LANDLORD">
              {{ $t('message.interestedParty.reference.landlord.landlord') }}
            </option>
            <option value="TERMINATED_BY_TENANT">
              {{ $t('message.contact.types.TENANT') }}
            </option>
          </coozzy-form-select>
        </div>
        <div
          class="col-sm-12 col-md-3">
          <label for="terminationType">
            {{ $t('message.enterTermination.terminationType') + '*' }}
            <coozzy-info-circle-icon
              v-if="tenancy.terminatedBy"
              v-b-tooltip.hover.html="tenancy.terminatedBy === 'TERMINATED_BY_LANDLORD' ? $t('message.enterTermination.reasonOfTerminationTooltipLandlord') : $t('message.enterTermination.reasonOfTerminationTooltipTenant')" />
          </label>
          <coozzy-form-select
            id="terminationType"
            v-model="tenancy.terminationType"
            :state="$v.tenancy.terminationType.$dirty && $v.tenancy.terminationType.$error ? false : null">
            <option value="TERMINATION_TYPE_TERM">
              {{ $t('message.enterTermination.types.term') }}
            </option>
            <option value="TERMINATION_TYPE_OFF_TERM">
              {{ $t('message.enterTermination.types.offTerm') }}
            </option>
            <option value="TERMINATION_TYPE_EXCEPTIONAL">
              {{ $t('message.enterTermination.types.exceptional') }}
            </option>
          </coozzy-form-select>
        </div>
        <div
          class="col-sm-12 col-md-3">
          <coozzy-multiselect
            v-model="terminationReason"
            :check-valide="$v.tenancy.terminationReason.$dirty && $v.tenancy.terminationReason.$error ? 'is-invalid' : ''"
            :clear-on-select="false"
            :close-on-select="false"
            :multiple="true"
            :options="reasonOptions"
            :placeholder="''"
            :preselect-first="false"
            :preserve-search="true"
            :taggable="false"
            label="label"
            track-by="label">
            {{ $t('message.enterTermination.reasonOfTermination') + '*' }}
          </coozzy-multiselect>
        </div>
        <div
          class="col-sm-12 col-md-3">
          <coozzy-form-textarea
            v-model="terminationJustification"
            :initial="terminationJustification">
            {{ $t('message.enterTermination.terminationJustification') }}
          </coozzy-form-textarea>
        </div>
      </div>
      <div class="row details-row-bg-1 py-01rem">
        <div class="col-sm-12 col-md-3">
          <coozzy-form-input
            v-model="dates.terminationCreatedAt"
            :filter="'formatDate'"
            :name="$t('message.enterTermination.terminationCreatedOn')"
            type="date"
            @blur="dateToObject()" />
        </div>
        <div
          v-if="terminatedBy === 'TERMINATED_BY_TENANT'"
          class="col-sm-12 col-md-3">
          <coozzy-form-input
            v-model="dates.terminationSentByTenantAt"
            :filter="'formatDate'"
            :name="$t('message.enterTermination.terminationSentByTenantAt')"
            type="date"
            @blur="dateToObject()" />
        </div>
        <div class="col-sm-12 col-md-3">
          <coozzy-form-input
            v-model="dates.terminatedOn"
            :filter="'formatDate'"
            :name="$t('message.enterTermination.terminatedOn') + '*'"
            :state="$v.tenancy.terminatedOn.$dirty && $v.tenancy.terminatedOn.$error ? false : null"
            type="date"
            @blur="dateToObject()" />
        </div>
        <div class="col-sm-12 col-md-3">
          <coozzy-form-input
            v-model="dates.terminateAt"
            :filter="'formatDate'"
            :name="$t('message.onBoarding.buildings.objects.tenancies.endAt') + '*'"
            :state="$v.tenancy.terminateAt.$dirty && $v.tenancy.terminateAt.$error ? false : null"
            type="date"
            @blur="dateToObject()" />
        </div>
      </div>
      <div class="row details-row-bg-1 py-01rem">
        <div class="col-sm-12 col-md-3">
          <coozzy-form-input
            v-model="dates.movedOutAt"
            :filter="'formatDate'"
            :name="$t('message.enterTermination.moveOutDate') + '*'"
            :state="$v.tenancy.movedOutAt.$dirty && $v.tenancy.movedOutAt.$error ? false : null"
            type="date"
            @blur="dateToObject()" />
        </div>
        <div class="col-sm-12 col-md-3">
          <coozzy-form-input
            v-model="dates.liabilityEndsAt"
            :filter="'formatDate'"
            :name="$t('message.onBoarding.buildings.objects.tenancies.liabilityEndsAt') + '*'"
            :state="$v.tenancy.liabilityEndsAt.$dirty && $v.tenancy.liabilityEndsAt.$error ? false : null"
            type="date"
            @blur="dateToObject()" />
        </div>
        <div class="col-sm-12 col-md-3">
          <coozzy-form-input
            v-model="dates.nextPossibleMoveInAt"
            :filter="'formatDate'"
            :name="$t('message.enterTermination.possibleMoveInDateNextTenant')"
            :state="$v.tenancy.nextPossibleMoveInAt.$dirty && $v.tenancy.nextPossibleMoveInAt.$error ? false : null"
            type="date"
            @blur="dateToObject()" />
        </div>
      </div>
      <div class="row mb-3 mt-1">
        <div class="col">
          <label>
            {{ $t('message.onBoardingTabs.documents') }}
          </label>
          <div
            class="row">
            <div class="col-12 mt-2">
              <add-document-modal
                :owner-id="tenancy.ownerId"
                :suffix="`termination-document-${lastTenancy.id}`"
                @document-created="documentCreated" />
              <coozzy-button
                design="prop-green"
                @click="addDocumentClicked">
                {{ $t('message.manageDocuments.addDocument') }}
              </coozzy-button>
            </div>
            <div
              class="col mt-2">
              <document
                v-for="doc in documents"
                :key="doc.id"
                :document="doc"
                @version-added="versionAdded"
                @delete-document="deleteDocument" />
            </div>
          </div>
          <div
            v-if="canCopyData"
            class="row">
            <div class="col-12 text-align-end">
              <coozzy-button
                class="mb-0 border"
                design="transparent"
                size="small"
                @click="copyDataClicked">
                {{ $t('message.enterTermination.takeData') }}
              </coozzy-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import ObjectApi from '@/misc/apis/ObjectApi'
import { onboarding } from '@/mixins/onboarding'
import { validation } from '@/mixins/validation'
import { required } from 'vuelidate/lib/validators'
import AddDocumentModal from '@/properties/components/AddDocumentModal'
import Document from '@/properties/components/Document'
import CoozzyMultiselect from '@/framework/components/multiselect/CoozzyMultiselect'
import ObjectTenancy from '@/building/tabs/components/ObjectTenancy'
import { dateUtils } from '@/mixins/dateUtils'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner.vue'
import CoozzyFormInputNumeric from '@/framework/components/form/input/CoozzyFormInputNumeric.vue'
import CoozzyFormInput from '@/framework/components/form/input/CoozzyFormInput.vue'
import CoozzyFormSelect from '@/framework/components/form/select/CoozzyFormSelect.vue'
import CoozzyInfoCircleIcon from '@/framework/components/icons/CoozzyInfoCircleIcon.vue'
import CoozzyButton from '@/framework/components/button/CoozzyButton.vue'
import CoozzyFormTextarea from '@/framework/components/form/textarea/CoozzyFormTextarea.vue'

function moreThanStarting(value, vm) {
  if (!value) {
    return true
  }
  if (!this.lastTenancy.startingAt) {
    return true
  }
  return new Date(this.$options.filters.objectToDateInput(value)) >= new Date(this.$options.filters.objectToDateInput(this.lastTenancy.startingAt))
}

function moreThanMoveOutDate(value, vm) {
  if (!value) {
    return true
  }
  if (!vm.movedOutAt) {
    return true
  }
  return new Date(this.$options.filters.objectToDateInput(value)) > new Date(this.$options.filters.objectToDateInput(vm.movedOutAt))
}

function moreThanEnding(value, vm) {
  if (!value) {
    return true
  }
  if (!vm.terminateAt) {
    return true
  }
  return new Date(this.$options.filters.objectToDateInput(value)) >= new Date(this.$options.filters.objectToDateInput(vm.terminateAt))
}

function lessMoreThanSubTenant(value, vm) {
  if (!value) {
    return true
  }
  if (vm.subTenants.length === 0) {
    return true
  }
  return vm.subTenants.filter(x => x.endingAt && new Date(this.$options.filters.objectToDateInput(x.endingAt)) > new Date(this.$options.filters.objectToDateInput(value)))?.length === 0
}

function moreThanDebitUntil(value, vm) {
  if (!value) {
    return true
  }
  if (!this.object.debitUntil) {
    return true
  }
  return new Date(this.$options.filters.objectToDateInput(value)) >= new Date(this.$options.filters.objectToDateInput(this.object.debitUntil))
}

export default {
  name: 'EnterTerminationDetailsTenant',
  components: {
    CoozzyFormTextarea,
    CoozzyButton,
    CoozzyInfoCircleIcon,
    CoozzyFormSelect,
    CoozzyFormInput,
    CoozzyFormInputNumeric,
    CoozzySpinner,
    AddDocumentModal,
    Document,
    CoozzyMultiselect,
    ObjectTenancy
  },
  mixins: [onboarding, validation, dateUtils],
  props: {
    object: {
      type: Object,
      required: true
    },
    arrayDetailsOfTenants: {
      type: Array,
      required: true
    },
    canCopyData: {
      type: Boolean,
      default: false
    },
    terminationData: {
      type: Object,
      default: () => null
    }
  },
  data(props) {
    return {
      availableTerminationMonths: ['all', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
      dates: {
        terminationCreatedAt: '',
        terminationSentByTenantAt: '',
        terminateAt: '',
        terminatedOn: '',
        movedOutAt: '',
        liabilityEndsAt: '',
        nextPossibleMoveInAt: ''
      },
      documents: [],
      documentIds: [],
      lastTenancy: {},
      loading: false,
      tenancy: this.arrayDetailsOfTenants.length === 0
        ? {
          documentIds: [],
          id: '',
          ownerId: '',
          objectId: props.object.id,
          terminationCreatedAt: null,
          terminationSentByTenantAt: null,
          terminateAt: null,
          terminatedOn: null,
          desiredMoveOutAt: null,
          liabilityEndsAt: null,
          nextPossibleMoveInAt: null,
          terminatedBy: 'TERMINATED_BY_UNDEFINED',
          terminationReason: [],
          terminationJustification: '',
          terminationType: 'TERMINATION_TYPE_UNDEFINED',
          movedOutAt: null
        }
        : this.arrayDetailsOfTenants.find(x => x.objectId === props.object.id),
        allowCalculationDates: true
    }
  },
  computed: {
    biggestEndDateSubTenant() {
      const maxEndingAt = this.tenancy.subTenants.reduce((max, current) => {
        const currentEndingAt = new Date(
          current.endingAt.year,
          current.endingAt.month - 1, // months are zero-based in JavaScript
          current.endingAt.day
        )

        const maxEndingAtDate = new Date(
          max.endingAt.year,
          max.endingAt.month - 1,
          max.endingAt.day
        )

        return currentEndingAt > maxEndingAtDate ? current : max
      }, this.tenancy.subTenants[0]) // Initialize with the first element

      return maxEndingAt
    },
    currentObject() {
      return this.objects ? (this.objects.filter(x => x.id === this.tenancy.objectId).length > 0 ? this.objects.find(x => x.id === this.tenancy.objectId) : this.object) : this.object
    },
    objectCategory() {
      return this.currentObject ? this.currentObject.category : ''
    },
    tenantPeriodInMonths: {
      get() {
        return this.lastTenancy.terminationTenant.periodInMonths !== -1 ? this.lastTenancy.terminationTenant.periodInMonths : null
      },
      set() {
      }
    },
    tenantEarliestDate: {
      get() {
        return this.lastTenancy.terminationTenant && this.lastTenancy.terminationTenant.earliestDate !== null ? this.lastTenancy.terminationTenant.earliestDate : ''
      },
      set() {
      }
    },
    tenantPossibleMonths: {
      get() {
        const tempMonths = this.lastTenancy.terminationTenant.possibleMonths
        tempMonths.sort(function (a, b) {
          return a - b
        })
        return tempMonths.map(month => {
          return {
            label: this.$t('message.generic.months.' + month),
            value: month
          }
        })
      },
      set() {
      }
    },
    landlordPeriodInMonths: {
      get() {
        return this.lastTenancy.terminationLandlord.periodInMonths !== -1 ? this.lastTenancy.terminationLandlord.periodInMonths : null
      },
      set() {
      }
    },
    landlordEarliestDate: {
      get() {
        return this.lastTenancy.terminationLandlord && this.lastTenancy.terminationLandlord.earliestDate !== null ? this.lastTenancy.terminationLandlord.earliestDate : ''
      },
      set() {
      }
    },
    landlordPossibleMonths: {
      get() {
        const tempMonths = this.lastTenancy.terminationLandlord.possibleMonths
        tempMonths.sort(function (a, b) {
          return a - b
        })
        return tempMonths.map(month => {
          return {
            label: this.$t('message.generic.months.' + month),
            value: month
          }
        })
      },
      set() {
      }
    },
    translatedMonths() {
      const array = []
      for (const month of this.availableTerminationMonths) {
        array.push({
          label: this.$t('message.generic.months.' + month),
          value: month
        })
      }
      return array
    },
    terminatedBy: {
      get() {
        return this.tenancy.terminatedBy
      },
      set(value) {
        this.tenancy.terminatedBy = value
        this.$emit('tenancy-details-updated', this.tenancy, this.object.id)
      }
    },
    terminationType: {
      get() {
        return this.tenancy.terminationType
      },
      set(value) {
        this.tenancy.terminationType = value
        this.$emit('tenancy-details-updated', this.tenancy, this.object.id)
      }
    },
    terminationReason: {
      get() {
        return this.tenancy.terminationReason.map(terminationReason => {
          return {
            label: this.$t('message.enterTermination.reasons.' + terminationReason),
            value: terminationReason
          }
        })
      },
      set(value) {
        this.tenancy.terminationReason = value.map(v => v.value)
        this.$emit('tenancy-details-updated', this.tenancy, this.object.id)
      }
    },
    terminationJustification: {
      get() {
        return this.tenancy.terminationJustification
      },
      set(value) {
        this.tenancy.terminationJustification = value
        this.$emit('tenancy-details-updated', this.tenancy, this.object.id)
      }
    },
    terminatedOn: {
      get() {
        return this.tenancy.terminatedOn !== '-1' ? this.tenancy.terminatedOn : ''
      },
      set(value) {
        this.tenancy.terminatedOn = value
        this.$emit('tenancy-details-updated', this.tenancy, this.object.id)
      }
    },
    terminationCreatedAt: {
      get() {
        return this.tenancy.terminationCreatedAt !== '-1' ? this.tenancy.terminationCreatedAt : null
      },
      set(value) {
        this.tenancy.terminationCreatedAt = value
        this.$emit('tenancy-details-updated', this.tenancy, this.object.id)
      }
    },
    terminationSentByTenantAt: {
      get() {
        return this.tenancy.terminationSentByTenantAt !== '-1' ? this.tenancy.terminationSentByTenantAt : null
      },
      set(value) {
        this.tenancy.terminationSentByTenantAt = value
        this.$emit('tenancy-details-updated', this.tenancy, this.object.id)
      }
    },
    terminateAt: {
      get() {
        return this.tenancy.terminateAt !== '-1' ? this.tenancy.terminateAt : null
      },
      set(value) {
        this.tenancy.terminateAt = value
        this.$emit('tenancy-details-updated', this.tenancy, this.object.id)
      }
    },
    movedOutAt: {
      get() {
        return this.tenancy.movedOutAt !== '-1' ? this.tenancy.movedOutAt : null
      },
      set(value) {
        this.tenancy.movedOutAt = value
        this.$emit('tenancy-details-updated', this.tenancy, this.object.id)
      }
    },
    liabilityEndsAt: {
      get() {
        return this.tenancy.liabilityEndsAt !== '-1' ? this.tenancy.liabilityEndsAt : null
      },
      set(value) {
        this.tenancy.liabilityEndsAt = value
        this.$emit('tenancy-details-updated', this.tenancy, this.object.id)
      }
    },
    nextPossibleMoveInAt: {
      get() {
        return this.tenancy.nextPossibleMoveInAt !== '-1' ? this.tenancy.nextPossibleMoveInAt : null
      },
      set(value) {
        this.tenancy.nextPossibleMoveInAt = value
        this.$emit('tenancy-details-updated', this.tenancy, this.object.id)
      }
    },
    reasonOptions() {
      const reasonOptions = []
      reasonOptions.push({
        label: this.$t('message.enterTermination.reasons.TERMINATION_REASON_POSTSCRIPT'),
        value: 'TERMINATION_REASON_POSTSCRIPT'
      })
      if (this.tenancy.terminationType === 'TERMINATION_TYPE_EXCEPTIONAL') {
        reasonOptions.push({
          label: this.$t('message.enterTermination.reasons.TERMINATION_REASON_IMPORTANT_REASONS'),
          value: 'TERMINATION_REASON_IMPORTANT_REASONS'
        })
        reasonOptions.push({
          label: this.$t('message.enterTermination.reasons.TERMINATION_REASON_DEATH'),
          value: 'TERMINATION_REASON_DEATH'
        })
      }
      if (this.tenancy.terminationType === 'TERMINATION_TYPE_EXCEPTIONAL' && this.tenancy.terminatedBy === 'TERMINATED_BY_LANDLORD') {
        reasonOptions.push({
          label: this.$t('message.enterTermination.reasons.TERMINATION_REASON_ARREARS'),
          value: 'TERMINATION_REASON_ARREARS'
        })
        reasonOptions.push({
          label: this.$t('message.enterTermination.reasons.TERMINATION_REASON_BANKRUPTCY_OF_TENANT'),
          value: 'TERMINATION_REASON_BANKRUPTCY_OF_TENANT'
        })
        reasonOptions.push({
          label: this.$t('message.enterTermination.reasons.TERMINATION_REASON_VIOLATION_OF_DUE_DILIGENCE'),
          value: 'TERMINATION_REASON_VIOLATION_OF_DUE_DILIGENCE'
        })
      }
      if (this.tenancy.terminationType !== 'TERMINATION_TYPE_EXCEPTIONAL' && this.tenancy.terminatedBy === 'TERMINATED_BY_TENANT') {
        reasonOptions.push({
          label: this.$t('message.enterTermination.reasons.TERMINATION_REASON_UNDEFINED'),
          value: 'TERMINATION_REASON_UNDEFINED'
        })
        reasonOptions.push({
          label: this.$t('message.enterTermination.reasons.TERMINATION_REASON_IMMISSIONS_TOO_HIGH'),
          value: 'TERMINATION_REASON_IMMISSIONS_TOO_HIGH'
        })
        if ((this.objectCategory !== 'COMMERCIAL' && this.objectCategory !== 'GASTRONOMY')) {
          reasonOptions.push({
            label: this.$t('message.enterTermination.reasons.TERMINATION_REASON_JOB'),
            value: 'TERMINATION_REASON_JOB'
          })
          reasonOptions.push({
            label: this.$t('message.enterTermination.reasons.TERMINATION_REASON_FAMILY'),
            value: 'TERMINATION_REASON_FAMILY'
          })
          reasonOptions.push({
            label: this.$t('message.enterTermination.reasons.TERMINATION_REASON_TOO_EXPENSIVE'),
            value: 'TERMINATION_REASON_TOO_EXPENSIVE'
          })
          reasonOptions.push({
            label: this.$t('message.enterTermination.reasons.TERMINATION_REASON_TOO_BIG'),
            value: 'TERMINATION_REASON_TOO_BIG'
          })
          reasonOptions.push({
            label: this.$t('message.enterTermination.reasons.TERMINATION_REASON_TOO_SMALL'),
            value: 'TERMINATION_REASON_TOO_SMALL'
          })
        }
      }
      if (this.tenancy.terminationType !== 'TERMINATION_TYPE_EXCEPTIONAL' && this.tenancy.terminatedBy === 'TERMINATED_BY_LANDLORD') {
        reasonOptions.push({
          label: this.$t('message.enterTermination.reasons.TERMINATION_REASON_RENOVATION'),
          value: 'TERMINATION_REASON_RENOVATION'
        })
        reasonOptions.push({
          label: this.$t('message.enterTermination.reasons.TERMINATION_REASON_OWN_NEED'),
          value: 'TERMINATION_REASON_OWN_NEED'
        })
      }
      if (this.tenancy.terminationType !== 'TERMINATION_TYPE_EXCEPTIONAL') {
        reasonOptions.push({
          label: this.$t('message.enterTermination.reasons.TERMINATION_REASON_TEMPORARY_CONTRACT'),
          value: 'TERMINATION_REASON_TEMPORARY_CONTRACT'
        })
      }
      if ((this.object.category === 'COMMERCIAL' || this.object.category === 'GASTRONOMY') && this.tenancy.terminatedBy === 'TERMINATED_BY_TENANT' && this.tenancy.terminationType !== 'TERMINATION_TYPE_EXCEPTIONAL') {
        reasonOptions.push({
          label: this.$t('message.enterTermination.reasons.TERMINATION_REASON_ECONOMIC_REASONS'),
          value: 'TERMINATION_REASON_ECONOMIC_REASONS'
        })
        reasonOptions.push({
          label: this.$t('message.enterTermination.reasons.TERMINATION_REASON_AREA_TOO_SMALL'),
          value: 'TERMINATION_REASON_AREA_TOO_SMALL'
        })
        reasonOptions.push({
          label: this.$t('message.enterTermination.reasons.TERMINATION_REASON_AREA_TOO_LARGE'),
          value: 'TERMINATION_REASON_AREA_TOO_LARGE'
        })
      }
      if (this.tenancy.terminatedBy === 'TERMINATED_BY_LANDLORD' && this.tenancy.terminationType !== 'TERMINATION_TYPE_EXCEPTIONAL') {
        reasonOptions.push({
          label: this.$t('message.enterTermination.reasons.TERMINATION_REASON_SALE'),
          value: 'TERMINATION_REASON_SALE'
        })
      }
      if (this.tenancy.terminatedBy === 'TERMINATED_BY_TENANT' && (this.objectCategory === 'HOUSE' || this.objectCategory === 'APARTMENT')) {
        reasonOptions.push({
          label: this.$t('message.enterTermination.reasons.TERMINATION_REASON_HOME_PURCHASE'),
          value: 'TERMINATION_REASON_HOME_PURCHASE'
        })
      }
      if (this.tenancy.terminationType === 'TERMINATION_TYPE_TERM' && this.tenancy.terminatedBy === 'TERMINATED_BY_LANDLORD') {
        reasonOptions.push({
          label: this.$t('message.enterTermination.reasons.TERMINATION_REASON_UNDEFINED'),
          value: 'TERMINATION_REASON_UNDEFINED'
        })
        reasonOptions.push({
          label: this.$t('message.enterTermination.reasons.TERMINATION_REASON_UNAUTHORISED_SUBLETTING'),
          value: 'TERMINATION_REASON_UNAUTHORISED_SUBLETTING'
        })
      }
      if (this.tenancy.terminatedBy === 'TERMINATED_BY_LANDLORD') {
        reasonOptions.push({
          label: this.$t('message.enterTermination.reasons.TERMINATION_REASON_PAYMENT_DEFAULT'),
          value: 'TERMINATION_REASON_PAYMENT_DEFAULT'
        })
      }
      return reasonOptions
    }
  },
  watch: {
    terminationData: {
      handler(val) {
        if (val) {
          this.copyDataFromFirstTenant(val)
        }
      },
      deep: true
    },
    tenancy: {
      handler(val) {
        this.dateToInputs()
      },
      deep: true
    },
    terminateAt: function (val, oldVal) {
      if (val && this.$options.filters.objectToDateInput(val) !== this.$options.filters.objectToDateInput(oldVal)) {
        this.$nextTick(() => {
          this.dates.liabilityEndsAt = this.dates.terminateAt
          this.dates.movedOutAt = this.dates.terminateAt
          const dateObject = new Date(this.dates.terminateAt)
          dateObject.setDate(dateObject.getDate() + 1)
          this.dates.nextPossibleMoveInAt = `${dateObject.getFullYear()}-${(dateObject.getMonth() + 1).toString().padStart(2, '0')}-${dateObject.getDate().toString().padStart(2, '0')}`
          this.dateToObject()
        })
      }
    },
    'tenancy.terminationCreatedAt': function (val, oldVal) {
      if (val && this.$options.filters.objectToDateInput(val) !== this.$options.filters.objectToDateInput(oldVal) && this.allowCalculationDates) {
        this.calculateEndOfContract()
      }
    },
    'tenancy.terminatedBy': function (val, oldVal) {
      if (val !== oldVal && this.allowCalculationDates) {
        this.tenancy.terminationType = 'TERMINATION_TYPE_UNDEFINED'
      }
      if (this.allowCalculationDates) {
        this.calculateEndOfContract()
      }
    },
    'tenancy.terminationType': function (val, oldVal) {
      if (val !== oldVal && this.allowCalculationDates) {
        this.tenancy.terminationReason = []
      }
    }
  },
  mounted() {
    this.loading = true
    const t = this
    ObjectApi.getTenancies([this.object.id]).then(response => {
      if (response.tenancies.length > 0) {
        if (response.tenancies.find(x => x.status === 'TENANCY_STATUS_CURRENT' && !x.endingAt)) {
          this.lastTenancy = response.tenancies.find(x => x.status === 'TENANCY_STATUS_CURRENT' && !x.endingAt)
        } else {
          this.lastTenancy = response.tenancies.sort(function (a, b) {
            return new Date(t.$options.filters.objectToDateInput(b.endingAt)).getTime() - new Date(t.$options.filters.objectToDateInput(a.endingAt)).getTime()
          })[0]
        }
        this.tenancy.id = this.lastTenancy.id
        this.tenancy.ownerId = this.lastTenancy.ownerId
        this.tenancy.documentIds = this.lastTenancy.documentIds
        this.tenancy.subTenants = this.lastTenancy.subTenants
        this.dateToInputs()
        this.getDatesFromObjects()
        this.loading = false
      }
    }).catch(e => {
      this.loading = false
      console.log(e)
      Vue.toasted.show(this.$t('message.loadingErrors.object'), { type: 'error' })
      this.$router.back()
    })
  },
  methods: {
    copyDataFromFirstTenant(tenancy) {
      if (tenancy) {
        this.allowCalculationDates = false
        this.tenancy.terminatedBy = tenancy.terminatedBy
        this.tenancy.terminationType = tenancy.terminationType
        this.tenancy.terminationReason = tenancy.terminationReason
        this.tenancy.terminatedOn = tenancy.terminatedOn
        this.tenancy.terminationSentByTenantAt = tenancy.terminationSentByTenantAt
        this.tenancy.terminateAt = tenancy.terminateAt
        this.tenancy.movedOutAt = tenancy.movedOutAt
        this.tenancy.liabilityEndsAt = tenancy.liabilityEndsAt
        this.tenancy.terminationCreatedAt = tenancy.terminationCreatedAt
        this.tenancy.nextPossibleMoveInAt = tenancy.nextPossibleMoveInAt
        this.$nextTick(() => {
          this.$emit('stop-changes')
          this.allowCalculationDates = true
        })
      }
    },
    copyDataClicked() {
      this.$emit('copy-data-from-first-tenant', this.tenancy)
    },
    dateToInputs() {
      this.$nextTick(() => {
        this.dates.terminationCreatedAt = this.$options.filters.objectToDateInput(this.terminationCreatedAt)
        this.dates.terminationSentByTenantAt = this.$options.filters.objectToDateInput(this.terminationSentByTenantAt)
        this.dates.tenantEarliestDate = this.$options.filters.objectToDateInput(this.tenantEarliestDate)
        this.dates.landlordEarliestDate = this.$options.filters.objectToDateInput(this.landlordEarliestDate)
        this.dates.terminateAt = this.$options.filters.objectToDateInput(this.terminateAt)
        this.dates.terminatedOn = this.$options.filters.objectToDateInput(this.terminatedOn)
        this.dates.liabilityEndsAt = this.$options.filters.objectToDateInput(this.liabilityEndsAt)
        this.dates.nextPossibleMoveInAt = this.$options.filters.objectToDateInput(this.nextPossibleMoveInAt)
        this.dates.movedOutAt = this.$options.filters.objectToDateInput(this.movedOutAt)
      })
    },
    isInvalid() {
      if (this.movedOutAt && this.nextPossibleMoveInAt) {
        if (this.movedOutAt > this.nextPossibleMoveInAt) {
          Vue.toasted.show(this.$t('message.enterTermination.moveInBeforeMoveOut'), { type: 'error' })
          return true
        }
      }
      let valid = true
      this.$v.tenancy.$touch()
      if (this.$v.tenancy.$invalid) {
        if (this.$v.tenancy.terminateAt.$dirty && this.$v.tenancy.terminateAt.$error && !this.$v.tenancy.terminateAt.moreThanDebitUntil) {
          Vue.toasted.show(this.$t('message.savingErrors.endDateBeforeDebit', { debitUntil: this.$options.filters.objectToDate(this.object.debitUntil) }), { type: 'error' })
          return true
        }
        if (this.$v.tenancy.terminateAt.$dirty && this.$v.tenancy.terminateAt.$error && !this.$v.tenancy.terminateAt.lessMoreThanSubTenant) {
          Vue.toasted.show(this.$t('message.savingErrors.endDateAfterEndDateSubTenant', { subTenant: this.$options.filters.objectToDate(this.biggestEndDateSubTenant.endingAt) }), { type: 'error' })
          return true
        }
        if (this.$v.tenancy.nextPossibleMoveInAt.$dirty && this.$v.tenancy.nextPossibleMoveInAt.$error && !this.$v.tenancy.nextPossibleMoveInAt.moreThanMoveOutDate) {
          Vue.toasted.show(this.$t('message.savingErrors.nextPossibleMoveInBeforeMoveOutAt'), { type: 'error' })
          return true
        }
        if ((this.$v.tenancy.terminatedOn?.$dirty && this.$v.tenancy.terminatedOn.$error) || (this.$v.movedOutAt?.$dirty && this.$v.movedOutAt.$error) || (this.$v.tenancy.liabilityEndsAt?.$dirty && this.$v.tenancy.liabilityEndsAt.$error) ||
          (this.$v.tenancy.terminateAt?.$dirty && this.$v.tenancy.terminateAt.$error) || (this.$v.tenancy.terminationReason?.$dirty && this.$v.tenancy.terminationReason.$error) || (this.$v.tenancy.terminatedBy?.$dirty && this.$v.tenancy.terminatedBy.$error) || (this.$v.tenancy.terminationType?.$dirty && this.$v.tenancy.terminationType.$error)) {
          Vue.toasted.show(this.$t('message.savingErrors.mandatoryMissing'), { type: 'error' })
        }
        this.scrollToError()
        valid = false
      }
      return !valid
    },
    dateToObject() {
      this.tenantEarliestDate = this.toObject(this.dates.tenantEarliestDate)
      this.landlordEarliestDate = this.toObject(this.dates.landlordEarliestDate)
      this.terminateAt = this.toObject(this.dates.terminateAt)
      this.terminationCreatedAt = this.toObject(this.dates.terminationCreatedAt)
      this.terminatedOn = this.toObject(this.dates.terminatedOn)
      this.movedOutAt = this.toObject(this.dates.movedOutAt)
      this.liabilityEndsAt = this.toObject(this.dates.liabilityEndsAt)
      this.nextPossibleMoveInAt = this.toObject(this.dates.nextPossibleMoveInAt)
      this.terminationSentByTenantAt = this.toObject(this.dates.terminationSentByTenantAt)
    },
    getDatesFromObjects: function () {
      this.dates.tenantEarliestDate = this.$options.filters.objectToDateInput(this.tenantEarliestDate)
      this.dates.landlordEarliestDate = this.$options.filters.objectToDateInput(this.landlordEarliestDate)
    },
    documentCreated(document) {
      this.documentIds.push(document.id)
      this.documents.push(document)
      this.tenancy.documentIds.push(document.id)
    },
    addDocumentClicked() {
      this.$bvModal.show(`add-document-modaltermination-document-${this.lastTenancy.id}`)
    },
    deleteDocument(document) {
      this.tenancy.documentIds = this.tenancy.documentIds.filter(obj => obj !== document.id)
      this.documentIds = this.documentIds.filter(obj => obj !== document.id)
      this.documents = this.documents.filter(obj => obj.id !== document.id)
    },
    versionAdded(updatedDocument) {
      this.documents.forEach((doc, index) => {
        if (doc.id === updatedDocument.id) {
          this.documents.splice(index, 1, updatedDocument)
        }
      }, this)
    },
    calculateEndOfContract() {
      if (!this.tenancy.terminationCreatedAt || !this.tenancy.terminationCreatedAt.month || !this.tenancy.terminationCreatedAt.year || this.tenancy.terminatedBy === 'TERMINATED_BY_UNDEFINED') {
        return 0
      }
      const dateObject = this.tenancy.terminationCreatedAt
      let nextYear = 0
      let startMonth
      let possibleMonths

      if (this.tenancy.terminatedBy === 'TERMINATED_BY_TENANT') {
        const terminateWithPeriod = new Date(dateObject.year, (dateObject.month + (this.tenantPeriodInMonths ? this.tenantPeriodInMonths : 0)), 0)
        if (new Date(this.$options.filters.objectToDateInput(this.tenantEarliestDate)).getTime() / 1000 > this.toTimestamp(terminateWithPeriod)) {
          startMonth = this.tenantEarliestDate.month
          nextYear = this.calculateDifferenceYears(this.tenantEarliestDate, this.tenancy.terminationCreatedAt)
        } else {
          startMonth = dateObject.month + (this.tenantPeriodInMonths ? this.tenantPeriodInMonths : 0)
        }
        possibleMonths = this.lastTenancy.terminationTenant.possibleMonths
      } else if (this.tenancy.terminatedBy === 'TERMINATED_BY_LANDLORD') {
        const terminateWithPeriod = new Date(dateObject.year, (dateObject.month + (this.landlordPeriodInMonths ? this.landlordPeriodInMonths : 0)), 0)
        if (new Date(this.$options.filters.objectToDateInput(this.landlordEarliestDate)).getTime() / 1000 > this.toTimestamp(terminateWithPeriod)) {
          startMonth = this.landlordEarliestDate.month
          nextYear = this.calculateDifferenceYears(this.landlordEarliestDate, this.tenancy.terminationCreatedAt)
        } else {
          startMonth = dateObject.month + (this.landlordPeriodInMonths ? this.landlordPeriodInMonths : 0)
        }
        possibleMonths = this.lastTenancy.terminationLandlord.possibleMonths
      }

      let possibleMonth = 0
      for (let index = 0; index < 120; index += 1) {
        if (startMonth + possibleMonth > 12) {
          possibleMonth = possibleMonth - 12
          nextYear += 1
          index -= 1
          continue
        }
        if (!possibleMonths.length) {
          break
        }
        if (possibleMonths.includes(startMonth + possibleMonth)) {
          break
        } else {
          possibleMonth += 1
        }
      }

      const firstPossibleMonth = startMonth + possibleMonth
      const calculatedDate = new Date(dateObject.year + nextYear, firstPossibleMonth, 0)
      this.dates.terminateAt = `${calculatedDate.getFullYear()}-${(calculatedDate.getMonth() + 1).toString().padStart(2, '0')}-${calculatedDate.getDate().toString().padStart(2, '0')}`
      this.dates.terminatedOn = this.dates.terminateAt
      this.dateToObject()
    },
    toTimestamp(strDate) {
      if (strDate === null || strDate === '') {
        return ''
      }
      return Date.parse(strDate) / 1000
    },
    calculateDifferenceYears(date1, date2) {
      const difference = date1.year - date2.year
      return difference
    }
  },
  validations: {
    tenancy: {
      liabilityEndsAt: {
        required,
        moreThanStarting,
        moreThanEnding
      },
      movedOutAt: {
        required,
        moreThanStarting
      },
      terminationReason: {
        required
      },
      terminationType: {
        sourceRequired: (value) => {
          return (value !== '' && value !== 'TERMINATION_TYPE_UNDEFINED')
        }
      },
      terminatedBy: {
        sourceRequired: (value) => {
          return (value !== '' && value !== 'TERMINATED_BY_UNDEFINED')
        }
      },
      terminatedOn: {
        required,
        moreThanStarting
      },
      terminateAt: {
        required,
        moreThanStarting,
        moreThanDebitUntil,
        lessMoreThanSubTenant
      },
      nextPossibleMoveInAt: {
        moreThanStarting,
        moreThanMoveOutDate
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.-mb-3 {
  margin-bottom: -1rem;
}

:deep(.tooltip-inner) {
  max-width: 800px !important;
}

.text-align-end {
  text-align: end;
}
</style>
