
const digitalDeposit = {
  data() {
    return {
      countOpenSignRequests: '-'
    }
  },
  computed: {
    digitalDepositEnabled() {
      return this.$store.getters['digitalDeposit/getDigitalDepositStatus']
    }
  }
}
export { digitalDeposit }
