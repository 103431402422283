import networkTS from '@/misc/apis/networkTS'
import { CredittrustOrderCertificateRequest } from '@/types/api'

export default {
  async getCertificate(orderId: string) {
    return networkTS.credittrustServiceGetCertificate({ orderId: orderId }, { format: 'arraybuffer' })
      .then(response => {
        return response.data
      })
  },
  async verifyCertificate(certificate: string) {
    return networkTS.credittrustServiceVerifyCertificate({ certificate: certificate })
      .then(response => {
        return response.data
      })
  },
  async orderCertificate(data: CredittrustOrderCertificateRequest) {
    return networkTS.credittrustServiceOrderCertificate(data)
      .then(response => {
        return response.data
      })
  }
}
