<template>
  <section>
    <div
      class="row">
      <div class="col">
        <form-wizard
          id="wizard"
          :ref="'wizard'"
          :hide-buttons="renderContractLoading"
          color="rgb(0, 68, 95)"
          step-size="xs"
          @on-change="handleTabChange">
          <span slot="title" />
          <template>
            <tab-content :title="$t('message.serialLetterProcess.defineLetterContent')">
              <div
                v-if="renderContractLoading"
                class="text-center">
                <coozzy-spinner />
              </div>
              <div v-else>
                <div class="row">
                  <div class="col-6">
                    <h2>{{ $t('message.serialLetterProcess.defineLetterContent') }}</h2>
                    <p>{{ $t('message.serialLetterProcess.defineLetterContentHeadline') }}</p>
                    <div class="row">
                      <div class="col-12 mb-1">
                        <label>{{ $t('message.settings.template.textTemplate') }}</label>
                        <coozzy-form-select
                          v-model="selectedTemplate"
                          name="numberEntries">
                          <option
                            value="">
                            -
                          </option>
                          <option
                            v-for="template in sortedTemplate"
                            :key="template.id"
                            :value="template.id">
                            {{ template.name }}
                          </option>
                        </coozzy-form-select>
                      </div>
                      <div class="col-12 mb-1">
                        <label>{{ $t('message.serialLetterProcess.subject') }}</label>
                        <coozzy-form-input
                          id="title"
                          v-model="title" />
                      </div>
                      <div class="col-12 mb-2">
                        <label>{{ $t('message.serialLetterProcess.letterText') }}</label>
                        <coozzy-form-textarea
                          id="description"
                          v-model="description"
                          :initial="description"
                          :rows="20"
                          :show-label="false"
                          :text="description"
                          :value-text-area="description" />
                      </div>
                    </div>
                  </div>
                  <div class="col-6 container-preview-img">
                    <img
                      alt="preview-pdf"
                      class="w-100"
                      src="/preview-pdf.jpg">
                  </div>
                </div>
              </div>
            </tab-content>
            <tab-content :title="$t('message.serialLetterProcess.chooseTemplate')">
              <div
                v-if="renderContractLoading"
                class="text-center">
                <coozzy-spinner />
              </div>
              <div>
                <h2>{{ $t('message.serialLetterProcess.chooseTemplate') }}</h2>
                <template-table
                  :default-category="'TEMPLATE_CATEGORY_LETTER'"
                  :render-contract-loading="renderContractLoading"
                  :source="'objectTenancy'"
                  @contracts-loaded="contractsLoaded"
                  @selected-contract="itemSelected" />
              </div>
            </tab-content>
            <tab-content
              :title="listOfReferenceIds.length === 1 ? $t('message.generic.oneSerialLetter') : $t('message.generic.serialLetter')">
              <div
                v-if="renderContractLoading"
                class="text-center">
                <coozzy-spinner />
              </div>
              <div v-else>
                <div class="step3">
                  <div class="row">
                    <div class="col-6">
                      <template
                        v-if="listOfReferenceIds.length === 1">
                        <div>
                          <div class="row">
                            <div class="col-12">
                              <label
                                v-if="source === 'contact'"
                                for="question1">{{
                                  $t('message.serialLetterProcess.step3.documentAssignedToOneContact')
                                }}</label>
                              <label
                                v-else
                                for="question1">{{
                                  $t('message.serialLetterProcess.step3.documentAssignedToOneTenancy')
                                }}</label>
                              <div class="row mt-2">
                                <div class="col-2">
                                  <coozzy-form-radio
                                    v-model="addDocumentToContact"
                                    :disabled="mergeDocuments"
                                    :select-value="true"
                                    class="p-0 btn likeBtn"
                                    name="addDocumentToContact">
                                    {{ $t('message.generic.selectField.yes') }}
                                  </coozzy-form-radio>
                                </div>
                                <div class="col-2">
                                  <coozzy-form-radio
                                    v-model="addDocumentToContact"
                                    :disabled="mergeDocuments"
                                    :select-value="false"
                                    class="p-0 btn likeBtn"
                                    name="addDocumentToContact">
                                    {{ $t('message.generic.selectField.no') }}
                                  </coozzy-form-radio>
                                </div>
                              </div>
                            </div>
                            <div
                              v-if="$store.getters['user/isEditor'] && $store.getters['user/hasDocumentsPortal'] && addDocumentToContact"
                              class="col-12">
                              <label
                                for="questionPortal">
                                {{ $t('message.serialLetterProcess.step3.showDocumentInPortal') }}
                                <coozzy-info-circle-icon
                                  v-if="isAllPortalLinkDisable"
                                  v-b-tooltip.hover.html="$t('message.manageDocuments.tooltipShowInPortal')" />
                                <coozzy-info-circle-icon
                                  v-else-if="!allPortalLinkEnabled"
                                  v-b-tooltip.hover.html="$t('message.serialLetterProcess.step3.showDocumentInPortalTooltip')" />
                              </label>
                              <div class="row mt-2">
                                <div class="col-2">
                                  <coozzy-form-radio
                                    v-model="showInPortal"
                                    :default-model="showInPortal"
                                    :select-value="true"
                                    class="p-0 btn likeBtn"
                                    name="showInPortal">
                                    {{ $t('message.generic.selectField.yes') }}
                                  </coozzy-form-radio>
                                </div>
                                <div class="col-2">
                                  <coozzy-form-radio
                                    v-model="showInPortal"
                                    :default-model="showInPortal"
                                    :select-value="false"
                                    class="p-0 btn likeBtn"
                                    name="showInPortal">
                                    {{ $t('message.generic.selectField.no') }}
                                  </coozzy-form-radio>
                                </div>
                              </div>
                            </div>

                            <div
                              v-if="activeTab === 2"
                              class="col-12">
                              <label
                                for="questiondocumentNamed">
                                {{ $t('message.serialLetterProcess.step3.documentNamed') }}
                              </label>
                              <template v-for="item in onlySelectedTemplate">
                                <div
                                  :key="item.id"
                                  class="row mt-2">
                                  <div class="col-3 title-center">
                                    {{ item.title }}
                                  </div>
                                  <div class="col-4">
                                    <coozzy-form-input
                                      v-model="item.documentName"
                                      :name="$t('message.serialLetterProcess.step3.documentName')"
                                      @change="randomKey++" />
                                  </div>
                                </div>
                              </template>
                            </div>
                          </div>
                        </div>
                      </template>
                      <template
                        v-else>
                        <div>
                          <div class="row mb-3">
                            <div class="col-12">
                              <label for="question1">{{
                                $t('message.serialLetterProcess.step3.createAcollectionDocument')
                              }}</label>
                              <div class="row mt-2">
                                <div class="col-2">
                                  <coozzy-form-radio
                                    v-model="mergeDocuments"
                                    :select-value="true"
                                    class="p-0 btn likeBtn"
                                    name="mergeDocuments">
                                    {{ $t('message.generic.selectField.yes') }}
                                  </coozzy-form-radio>
                                </div>
                                <div class="col-2">
                                  <coozzy-form-radio
                                    v-model="mergeDocuments"
                                    :select-value="false"
                                    class="p-0 btn likeBtn"
                                    name="mergeDocuments">
                                    {{ $t('message.generic.selectField.no') }}
                                  </coozzy-form-radio>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row mb-3">
                            <div
                              v-if="mergeDocuments && contractTemplateSelected.length > 1"
                              class="col">
                              <p>
                                {{
                                  $t('message.serialLetterProcess.step3.NdocumentsAreGenerated', {
                                    x:
                                      contractTemplateSelected.length
                                  })
                                }}
                              </p>
                            </div>
                            <div
                              v-else-if="mergeDocuments && contractTemplateSelected.length === 1"
                              class="col">
                              <p>{{ $t('message.serialLetterProcess.step3.oneDocumentisCreated') }}</p>
                            </div>
                            <div
                              v-else-if="!mergeDocuments"
                              class="col">
                              <p v-if="source === 'contact'">
                                {{
                                  $t('message.serialLetterProcess.step3.NdocumentsAreGenerated', {
                                    x:
                                      (contractTemplateSelected.length * contacts.length)
                                  })
                                }}
                              </p>
                              <p v-else>
                                {{
                                  $t('message.serialLetterProcess.step3.NdocumentsAreGenerated', {
                                    x:
                                      (contractTemplateSelected.length * countGeneratedDocs)
                                  })
                                }}
                              </p>
                            </div>
                          </div>
                          <div class="row mb-3">
                            <div class="col-12">
                              <label
                                v-if="source === 'contact'"
                                for="question22">
                                {{ $t('message.serialLetterProcess.step3.documentAssignedToContacts') }}
                              </label>
                              <label
                                v-else
                                for="question2">
                                {{ $t('message.serialLetterProcess.step3.documentAssignedToTenancies') }}
                                <!--                            <coozzy-info-circle-icon-->
                                <!--                              v-if="mergeDocuments"-->
                                <!--                              v-b-tooltip.hover.html="$t('message.serialLetterProcess.step3.documentAssignedToTenanciesTooltip')" />-->
                              </label>
                              <div class="row mt-2">
                                <div class="col-2">
                                  <coozzy-form-radio
                                    v-model="addDocumentToContact"
                                    :default-model="addDocumentToContact"
                                    :select-value="true"
                                    class="p-0 btn likeBtn"
                                    name="addDocumentToContact">
                                    {{ $t('message.generic.selectField.yes') }}
                                  </coozzy-form-radio>
                                </div>
                                <div class="col-2">
                                  <coozzy-form-radio
                                    v-model="addDocumentToContact"
                                    :default-model="addDocumentToContact"
                                    :select-value="false"
                                    class="p-0 btn likeBtn"
                                    name="addDocumentToContact">
                                    {{ $t('message.generic.selectField.no') }}
                                  </coozzy-form-radio>
                                </div>
                              </div>
                            </div>
                            <div
                              v-if="$store.getters['user/isEditor'] && $store.getters['user/hasDocumentsPortal'] && addDocumentToContact"
                              class="col-12">
                              <label
                                for="questionPortal">
                                {{ $t('message.serialLetterProcess.step3.showDocumentInPortal') }}
                                <coozzy-info-circle-icon
                                  v-if="isAllPortalLinkDisable"
                                  v-b-tooltip.hover.html="$t('message.manageDocuments.tooltipShowInPortal')" />
                                <coozzy-info-circle-icon
                                  v-else-if="!allPortalLinkEnabled"
                                  v-b-tooltip.hover.html="$t('message.serialLetterProcess.step3.showDocumentInPortalTooltip')" />
                              </label>
                              <div class="row mt-2">
                                <div class="col-2">
                                  <coozzy-form-radio
                                    v-model="showInPortal"
                                    :default-model="showInPortal"
                                    :select-value="true"
                                    class="p-0 btn likeBtn"
                                    name="showInPortal">
                                    {{ $t('message.generic.selectField.yes') }}
                                  </coozzy-form-radio>
                                </div>
                                <div class="col-2">
                                  <coozzy-form-radio
                                    v-model="showInPortal"
                                    :default-model="showInPortal"
                                    :select-value="false"
                                    class="p-0 btn likeBtn"
                                    name="showInPortal">
                                    {{ $t('message.generic.selectField.no') }}
                                  </coozzy-form-radio>
                                </div>
                              </div>
                            </div>
                            <div
                              v-if="activeTab === 2"
                              class="col-12">
                              <label
                                for="questiondocumentNamed">
                                {{ $t('message.serialLetterProcess.step3.documentNamed') }}
                              </label>
                              <template v-for="item in onlySelectedTemplate">
                                <div
                                  :key="item.id"
                                  class="row mt-2">
                                  <div class="col-3 title-center">
                                    {{ item.title }}
                                  </div>
                                  <div class="col-4">
                                    <coozzy-form-input
                                      v-model="item.documentName"
                                      :name="$t('message.serialLetterProcess.step3.documentName')"
                                      @change="randomKey++" />
                                  </div>
                                </div>
                              </template>
                            </div>
                          </div>
                        </div>
                      </template>
                      <div>
                        <div
                          v-if="source === 'condominium'"
                          class="row mb-3">
                          <div class="col-12">
                            <label for="question2">{{ $t('message.serialLetterProcess.step3.includeRoommate') }}
                            </label>
                            <div class="row mt-2">
                              <div class="col-2">
                                <coozzy-form-radio
                                  v-model="includeRoommates"
                                  :default-model="includeRoommates"
                                  :select-value="true"
                                  class="p-0 btn likeBtn"
                                  name="includeRoommates">
                                  {{ $t('message.generic.selectField.yes') }}
                                </coozzy-form-radio>
                              </div>
                              <div class="col-2">
                                <coozzy-form-radio
                                  v-model="includeRoommates"
                                  :default-model="includeRoommates"
                                  :select-value="false"
                                  class="p-0 btn likeBtn"
                                  name="includeRoommates">
                                  {{ $t('message.generic.selectField.no') }}
                                </coozzy-form-radio>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!--        Sorting          -->
                      <div
                        v-if="mergeDocuments">
                        <div
                          :id="`collapse-sorting`"
                          v-b-toggle="`collapse-sorting`"
                          class="pt-3 mb-0">
                          <h5
                            class="cursor-pointer hover width-only-content">
                            {{ $t('message.serialLetterProcess.step3.sorting.title') }}
                          </h5>
                        </div>
                        <b-collapse
                          :id="`collapse-sorting`"
                          ref="collapse"
                          :visible="true"
                          class="">
                          <div class="row details-row-bg-1 py-01rem pr-3">
                            <div class="col-sm-12 col-md-6">
                              <label>{{ $t('message.serialLetterProcess.step3.sorting.desiredSortingLetter') }}</label>
                              <coozzy-form-select
                                v-model="sortingType"
                                class="mb-2">
                                <option :value="'RENDER_ORDER_DEFAULT'">
                                  {{ $t('message.serialLetterProcess.step3.sorting.sortPropbaseStandard') }}
                                </option>
                                <option :value="'RENDER_ORDER_NUMERIC_ID'">
                                  {{ $t('message.serialLetterProcess.step3.sorting.sortByAddressNumber') }}
                                </option>
                              </coozzy-form-select>
                            </div>
                          </div>
                        </b-collapse>
                      </div>
                      <div
                        v-if="$store.getters['user/getIsBriefButlerEnabled']"
                        class="row mb-3">
                        <div class="col-12">
                          <label for="question2">{{ $t('message.serialLetterProcess.step3.sendToBriefbutler') }}
                          </label>
                          <div class="row mt-2">
                            <div class="col-2">
                              <coozzy-form-radio
                                v-model="sendToBriefbutler"
                                :default-model="sendToBriefbutler"
                                name="sendToBriefbutler"
                                class="p-0 btn likeBtn"
                                :select-value="true">
                                {{ $t('message.generic.selectField.yes') }}
                              </coozzy-form-radio>
                            </div>
                            <div class="col-2">
                              <coozzy-form-radio
                                v-model="sendToBriefbutler"
                                :default-model="sendToBriefbutler"
                                name="sendToBriefbutler"
                                class="p-0 btn likeBtn"
                                :select-value="false">
                                {{ $t('message.generic.selectField.no') }}
                              </coozzy-form-radio>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="sendToBriefbutler"
                        class="row mb-3">
                        <div class="col-12">
                          <label for="question2">{{ $t('message.briefButler.modal.howSendDoc') }} *
                          </label>
                          <div class="col-sm-12 col-md-12 mb-2">
                            <template
                              v-for="(item, itemIndex) in profiles">
                              <div
                                :key="`item_${itemIndex}`"
                                class="col-6 pl-0 pr-0">
                                <coozzy-form-radio
                                  v-model="selectedProfile"
                                  :default-model="selectedProfile"
                                  :select-value="item.briefbutlerId"
                                  :name="item.briefbutlerId"
                                  :state="$v.selectedProfile.$dirty && $v.selectedProfile.$error ? false : null"
                                  class="mt-2">
                                  {{ item.description }}
                                </coozzy-form-radio>
                              </div>
                            </template>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <document-preview-table
                        v-if="contractTemplateSelected.length > 0"
                        :key="randomKey"
                        :list-templates="contractTemplate"
                        :selected-template="contractTemplateSelected"
                        :list-tenancies="listTenancies"
                        :list-objects="listObjects"
                        :contacts="contacts"
                        :merge-documents="mergeDocuments"
                        :source="source"
                        :title="title"
                        :description="description"
                        :sorting-type="sortingType"
                        :show-in-portal="showInPortal" />
                    </div>
                  </div>
                </div>
              </div>
            </tab-content>
          </template>
          <template
            slot="footer"
            slot-scope="props">
            <template v-if="!renderContractLoading">
              <div class="wizard-footer-left">
                <wizard-button
                  :style="props.fillButtonStyle"
                  class="mr-2"
                  @click.native="$router.back()">
                  {{ $t('message.generic.cancel') }}
                </wizard-button>
                <wizard-button
                  v-if="props.activeTabIndex > 0"
                  :style="props.fillButtonStyle"
                  @click.native="backClicked(props)">
                  {{ $t('message.generic.back') }}
                </wizard-button>
              </div>
              <div class="wizard-footer-right">
                <template v-if="!props.isLastStep">
                  <wizard-button
                    v-if="!props.isLastStep"
                    :style="props.fillButtonStyle"
                    class="wizard-footer-right"
                    @click.native="nextStep(props)">
                    <template>
                      {{ $t('message.generic.next') }}
                    </template>
                  </wizard-button>
                </template>
                <wizard-button
                  v-else
                  :style="props.fillButtonStyle"
                  class="wizard-footer-right"
                  @click.native="renderContract">
                  {{ $t('message.generic.create') }}
                </wizard-button>
              </div>
            </template>
          </template>
        </form-wizard>
      </div>
    </div>
  </section>
</template>

<script>
import Vue from 'vue'
import TemplateTable from '@/settings/components/TemplateTable'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner'
import ObjectApi from '@/misc/apis/ObjectApi'
import MediaApi from '@/misc/apis/MediaApi'
import ContactApi from '@/misc/apis/ContactApi'
import { tables } from '@/mixins/tables'
import { obj } from '@/mixins/object'
import { routeChecks } from '@/mixins/routeChecks'
import { saveAs } from 'file-saver'
import CoozzyFormRadio from '@/framework/components/form/radio/CoozzyFormRadio.vue'
import CoozzyFormTextarea from '@/framework/components/form/textarea/CoozzyFormTextarea.vue'
import CoozzyFormSelect from '@/framework/components/form/select/CoozzyFormSelect.vue'
import { mapState } from 'vuex'
import CoozzyFormInput from '@/framework/components/form/input/CoozzyFormInput.vue'
import { required } from 'vuelidate/lib/validators'
import { validation } from '@/mixins/validation'
import BriefButlerApi from '@/misc/apis/BriefButlerApi'
import PortalApi from '@/misc/apis/PortalApi'
import DocumentPreviewTable from '@/addTenancy/documentPreviewTable.vue'
import CoozzyInfoCircleIcon from '@/framework/components/icons/CoozzyInfoCircleIcon.vue'

export default {
  name: 'SerialLetterProcessView',
  components: {
    CoozzyInfoCircleIcon,
    DocumentPreviewTable,
    CoozzyFormInput,
    CoozzyFormSelect,
    CoozzyFormTextarea,
    CoozzyFormRadio,
    CoozzySpinner,
    TemplateTable
  },
  mixins: [tables, routeChecks, obj, validation],
  beforeRouteLeave(to, from, next) {
    if (to.fullPath !== from.fullPath) {
      localStorage.removeItem(this.uuid)
      next()
    } else {
      next(false)
    }
  },
  props: {
    source: {
      type: String,
      default: 'tenancy'
    },
    uuid: {
      type: [Array, String],
      default() {
        return []
      }
    },
    previousRoute: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      randomKey: 0,
      activeTab: 0,
      isBusy: true,
      listObjects: [],
      addDocumentToContact: false,
      showInPortal: false,
      isPortalLinkEnabled: false,
      allPortalLinkEnabled: false,
      isAllPortalLinkDisable: false,
      ownerProperties: [],
      includeRoommates: false,
      mergeDocuments: false,
      contractTemplate: [],
      contacts: [],
      contractTemplateSelected: [],
      listTenancies: [],
      tenancies: [],
      objectIds: [],
      tenantObject: [],
      renderContractLoading: true,
      blobs: [],
      title: '',
      description: '',
      selectedTemplate: '',
      listOfReferenceIds: '',
      sortingType: 'RENDER_ORDER_DEFAULT',
      profiles: [],
      sendToBriefbutler: false,
      selectedProfile: null
    }
  },
  computed: {
    ...mapState('message', ['templates']),
    onlySelectedTemplate() {
      return this.contractTemplate.filter(x => this.contractTemplateSelected.includes(x.id))
    },
    sortedTemplate() {
      return this.templates.filter(x => x.type === 'TEMPLATE_TYPE_LETTER').slice().sort((a, b) => {
        if (a.name > b.name) {
          return 1
        }
        if (b.name > a.name) {
          return -1
        }
        return 0
      })
    },
    countGeneratedDocs() {
      const tenanciesToProcess = []
      if (this.source === 'tenancy') {
        this.listTenancies.forEach(element => {
          if (!element.vacancy && tenanciesToProcess.filter(elem => JSON.stringify(elem.tenant.contactIds.sort()) === JSON.stringify(element.tenant.contactIds.sort())).length === 0) {
            tenanciesToProcess.push(element)
          }
        })
      } else if (this.source === 'condominium') {
        this.listTenancies.forEach(element => {
          if (tenanciesToProcess.filter(elem => JSON.stringify(elem.owner.contactIds.sort()) === JSON.stringify(element.owner.contactIds.sort())).length === 0) {
            tenanciesToProcess.push(element)
          }
        })
      }
      return tenanciesToProcess.length
    }
  },
  watch: {
    selectedTemplate: function (newVal) {
      if (newVal !== '') {
        const template = this.sortedTemplate.find(x => x.id === newVal)
        this.title = template.subject
        this.description = template.message
      } else {
        this.title = ''
        this.description = ''
      }
    },
    sendToBriefbutler: function (newVal) {
      if (!newVal) {
        this.selectedProfile = null
      }
    },
    activeTab(newVal) {
      if (newVal === 2) {
        for (let i = 0; i < this.onlySelectedTemplate.length; i++) {
          if (!this.onlySelectedTemplate[i].documentName) {
            this.onlySelectedTemplate[i].documentName = this.onlySelectedTemplate[i].title
          }
        }
      }
    }
  },
  mounted() {
    this.addDocumentToContact = false
    this.includeRoommates = false
    this.mergeDocuments = false
    this.title = ''
    this.description = ''
    this.listTenancies = []
    this.tenantObject = []
    let contactIds = []
    this.listOfReferenceIds = localStorage.getItem(this.uuid)?.split(',')
    if (this.listOfReferenceIds.length > 0) {
      let ObjectIds = []
      if (this.listOfReferenceIds[0].startsWith('tenancy_')) {
        ObjectApi.getTenanciesByIds([...new Set(this.listOfReferenceIds)])
          .then(tenancyResponse => {
            this.tenancies = tenancyResponse.tenancies
            if (this.tenancies.length > 0) {
              this.tenancies.forEach(element => {
                if (element && element.tenant && element.tenant.contactIds.length > 0) {
                  contactIds = contactIds.concat(element.tenant.contactIds)
                  ObjectIds.push(element.obj?.id || element.objectId)
                  ObjectApi.getObject(element.obj?.id || element.objectId)
                    .then(response => {
                      Object.assign(element, { objectNumericId: response.object?.numericId ? response.object.numericId : '' })
                      this.listTenancies.push(element)
                    }).catch(e => {
                    console.log(e)
                    this.listTenancies.push(element)
                  })
                }
              })
            }
          })
          .finally(() => {
            this.renderContractLoading = false
            this.loadContacts(contactIds)
          })
      } else if (this.listOfReferenceIds[0].startsWith('condominium_')) {
        ObjectApi.getCondominiumByIds([...new Set(this.listOfReferenceIds)])
          .then(tenancyResponse => {
            this.tenancies = tenancyResponse.condominiums
            if (this.tenancies.length > 0) {
              this.tenancies.forEach(element => {
                if (element && element.owner && element.owner.contactIds.length > 0) {
                  contactIds = contactIds.concat(element.owner.contactIds)
                  ObjectIds.push(element.obj?.id || element.objectId)
                  ObjectApi.getObject(element.obj?.id || element.objectId)
                    .then(response => {
                      Object.assign(element, { objectNumericId: response.object?.numericId ? response.object.numericId : '' })
                      this.listTenancies.push(element)
                    }).catch(e => {
                    console.log(e)
                    this.listTenancies.push(element)
                  })
                }
              })
            }
          })
          .finally(() => {
            this.renderContractLoading = false
            this.loadContacts(contactIds)
          })
      } else if (this.listOfReferenceIds[0].startsWith('obj_')) {
        ObjectIds = ObjectIds.concat([...new Set(this.listOfReferenceIds)])
        ObjectApi.listObjectsByIds([...new Set(this.listOfReferenceIds)])
          .then(response => {
            this.listObjects = response.objects
            const loadedObjectIds = response.objects.map(obj => obj.id)
            const contactIdsToLoad = []
            if (this.source === 'tenancy') {
              ObjectApi.listCurrentByObjectIds(loadedObjectIds)
                .then(responseTenancy => {
                  for (const tenancy of responseTenancy.tenancies) {
                    this.objectIds.push(tenancy.objectId)
                    contactIdsToLoad.push(...tenancy.tenant.contactIds)
                    this.listTenancies.push(tenancy)
                  }
                  this.loadContacts(contactIdsToLoad)
                })
            } else if (this.source === 'condominium') {
              ObjectApi.listCurrentCondominiumsByObjectIds(loadedObjectIds)
                .then(responseCondominium => {
                  for (const condo of responseCondominium.condominiums) {
                    this.objectIds.push(condo.objectId)
                    contactIdsToLoad.push(...condo.owner.contactIds)
                    this.listTenancies.push(condo)
                  }
                  this.loadContacts(contactIdsToLoad)
                })
            }
          }).finally(() => {
          this.renderContractLoading = false
        })
      } else if (this.listOfReferenceIds[0].startsWith('contact_') && this.source === 'contact') {
        this.loadContacts(this.listOfReferenceIds)
      }
      ObjectApi.getBuildingByObjectIds(ObjectIds)
        .then(response => {
          ObjectApi.getPropertiesByBuildingIds(response.buildings.map(building => building.id))
            .then(propertyResponse => {
              // get all ownerId of the properties
              const ownerIds = propertyResponse.properties.map(property => property.ownerId)
              const promises = []
              this.ownerProperties = ownerIds.filter((v, i, a) => a.indexOf(v) === i)
              this.ownerProperties.filter((v, i, a) => a.indexOf(v) === i).forEach(ownerId => {
                promises.push(PortalApi.getPortalStatus(ownerId))
                Promise.all(promises)
                  .then(response => {
                    this.allPortalLinkEnabled = response.every(item => item.enabled === true)
                    this.isPortalLinkEnabled = response.some(item => item.enabled === true)
                    this.isAllPortalLinkDisable = response.every(item => item.enabled === false)
                  })
              })
            })
        })
      BriefButlerApi.loadSettings(this.$store.getters['user/getAccountId'])
        .then(response => {
          this.profiles = response.profiles
        })
        .catch(e => {
          console.error(e)
          Vue.toasted.show('Die Buchhaltungsstatistiken konnten nicht geladen werden', { type: 'error' })
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  methods: {
    handleTabChange(prevIndex, nextIndex) {
      this.activeTab = nextIndex
    },
    loadContacts(contactIds) {
      ContactApi.contactResolve(contactIds)
        .then(response => {
          this.contacts = (response.persons.concat(response.companies)).filter(c => contactIds.includes(c.id))
        })
        .finally(() => {
          this.renderContractLoading = false
        })
    },
    nextStep(props) {
      props.nextTab()
      this.scrollToTop()
    },
    scrollToTop() {
      this.$nextTick(() => {
        const domRect = this.$el.querySelector('#wizard')
        domRect.scrollIntoView(true)
      })
    },
    itemSelected(ids, contracts) {
      this.contractTemplateSelected = ids
      this.contractTemplate = contracts
    },
    contractsLoaded(contracts) {
      this.contractTemplate = contracts
    },
    redirectToPreviousPage() {
      this.$router.push(this.previousRoute)
    },
    redirectToDetailPage(object) {
      if (object !== null) {
        let routerHref = ''
        if (this.isOwnerModule) {
          routerHref = this.$router.resolve({
            name: 'OwnerBuildingDetailsView',
            params: { id: object.buildingId },
            query: {
              view: 'objects',
              elementSelected: object.objectID || object.id
            }
          })
        } else if (this.isAssetModule) {
          routerHref = this.$router.resolve({
            name: 'AssetBuildingDetailsView',
            params: { id: object.buildingId },
            query: {
              view: 'objects',
              elementSelected: object.objectID || object.id
            }
          })
        } else if (this.isAccountingModule) {
          routerHref = this.$router.resolve({
            name: 'AccountingBuildingDetailsView',
            params: { id: object.buildingId },
            query: {
              view: 'objects',
              elementSelected: object.objectID || object.id
            }
          })
        } else {
          routerHref = this.$router.resolve({
            name: 'AdminBuildingDetailsView',
            params: { id: object.buildingId },
            query: {
              view: 'objects',
              elementSelected: object.objectID || object.id
            }
          })
        }
        window.open(routerHref.href, '_blank')
      }
    },
    async renderContract() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.scrollToError()
        return
      }
      this.renderContractLoading = true
      let countResult = 0
      const array = []
      let tenanciesToProcess = []
      const promises = []
      const listTemplatePdf = []
      // const vacancies = this.listOfReferenceIds.filter(id => !this.listTenancies.some(tenancy => tenancy.id === id) && id.startsWith('tenancy_'))
      if (this.mergeDocuments) {
        for (let index = 0; index < this.contractTemplateSelected.length; index++) {
          const signatories = []
          const contract = this.contractTemplate.find(x => x.id === this.contractTemplateSelected[index])
          if (contract && !contract.mediaId.includes('.pdf')) {
            array.push({
              contract: this.contractTemplateSelected[index]
            })
            if (contract && contract.firstSignature !== '') {
              signatories.push(contract.firstSignature)
            }
            if (contract && contract.secondSignature) {
              if (contract && contract.firstSignature === '') {
                signatories.push('')
              }
              signatories.push(contract.secondSignature)
            }

            if (this.listTenancies.length > 0) {
              const id = this.listTenancies[0].id || this.listTenancies[0].objectID
              if (id.startsWith('condominium_')) {
                this.listTenancies.forEach(element => {
                  if (tenanciesToProcess.filter(elem => JSON.stringify(elem.owner.contactIds.sort()) === JSON.stringify(element.owner.contactIds.sort())).length === 0) {
                    tenanciesToProcess.push(element)
                  }
                })
                promises.push(ObjectApi.renderContractCondominiums(this.contractTemplateSelected[index], tenanciesToProcess.map(condominium => condominium.id || condominium.objectID), this.title, this.description, contract.documentName, this.includeRoommates, this.selectedProfile, signatories, this.addDocumentToContact, this.showInPortal, this.sortingType, this.selectedProfile !== null))
              } else {
                this.listTenancies.forEach(element => {
                  if (!element.vacancy && tenanciesToProcess.filter(elem => JSON.stringify(elem.tenant.contactIds.sort()) === JSON.stringify(element.tenant.contactIds.sort())).length === 0) {
                    tenanciesToProcess.push(element)
                  }
                })
                promises.push(ObjectApi.renderTenanciesTemplate(this.contractTemplateSelected[index], tenanciesToProcess.map(x => x.id || x.objectID), this.title, this.description, contract.documentName, signatories, this.selectedProfile, this.addDocumentToContact, this.showInPortal, this.sortingType, this.selectedProfile !== null))
              }
            } else if (this.source === 'contact') {
              if (this.contacts.length) {
                promises.push(ObjectApi.renderContractContacts(this.contractTemplateSelected[index], this.contacts.map(contact => contact.id || contact.objectID), this.title, this.description, contract.documentName, this.selectedProfile, signatories, this.addDocumentToContact, this.sortingType, this.selectedProfile !== null))
              }
            // } else if (vacancies.length > 0) {
            //   promises.push(ObjectApi.renderTenanciesTemplate(this.contractTemplateSelected[index], vacancies, this.title, this.description, signatories, this.addDocumentToContact))
            }
          } else if (contract) {
            listTemplatePdf.push(contract.mediaId)
          }
        }
      } else {
        for (let index = 0; index < this.contractTemplateSelected.length; index++) {
          const signatories = []
          const contract = this.contractTemplate.find(x => x.id === this.contractTemplateSelected[index])
          if (contract && !contract.mediaId.includes('.pdf')) {
            if (contract && contract.firstSignature !== '') {
              signatories.push(contract.firstSignature)
            }
            if (contract && contract.secondSignature) {
              if (contract && contract.firstSignature === '') {
                signatories.push('')
              }
              signatories.push(contract.secondSignature)
            }
            if (this.source === 'contact') {
              this.contacts.forEach(element => {
                array.push({
                  contract: this.contractTemplateSelected[index],
                  contactId: element.id || element.objectID
                })
                promises.push(ObjectApi.renderContractContact(this.contractTemplateSelected[index], element.id || element.objectID, this.addDocumentToContact, this.showInPortal, this.title, this.description, contract.documentName, this.selectedProfile, signatories, this.selectedProfile !== null))
              })
            } else {
              if (this.listTenancies.length > 0) {
                const id = this.listTenancies[0].id || this.listTenancies[0].objectID
                this.listTenancies.forEach(element => {
                  if (id.startsWith('condominium_')) {
                    if (tenanciesToProcess.filter(elem => JSON.stringify(elem.owner.contactIds.sort()) === JSON.stringify(element.owner.contactIds.sort())).length === 0) {
                      array.push({
                        contract: this.contractTemplateSelected[index],
                        condominiumId: element.id || element.objectID
                      })
                      tenanciesToProcess.push(element)
                      promises.push(ObjectApi.renderContractCondominium(this.contractTemplateSelected[index], element.id || element.objectID, this.addDocumentToContact, this.showInPortal, this.title, this.description, contract.documentName, this.includeRoommates, this.selectedProfile, signatories, this.selectedProfile !== null))
                    }
                  } else {
                    if (!element.vacancy && tenanciesToProcess.filter(elem => JSON.stringify(elem.tenant.contactIds.sort()) === JSON.stringify(element.tenant.contactIds.sort())).length === 0) {
                      array.push({
                        contract: this.contractTemplateSelected[index],
                        tenancyId: element.id || element.objectID
                      })
                      tenanciesToProcess.push(element)
                      promises.push(ObjectApi.RenderTenancyTemplate(this.contractTemplateSelected[index], element.id || element.objectID, this.addDocumentToContact, this.showInPortal, this.title, this.description, contract.documentName, this.selectedProfile, signatories, false, this.selectedProfile !== null))
                    }
                  }
                })
              }
              // vacancies.forEach(element => {
              //   array.push({
              //     contract: this.contractTemplateSelected[index],
              //     tenancyId: element
              //   })
              //   promises.push(ObjectApi.RenderTenancyTemplate(this.contractTemplateSelected[index], element, this.addDocumentToContact, this.title, this.description, signatories))
              // })
            }
          } else if (contract) {
            listTemplatePdf.push(contract.mediaId)
          }
          tenanciesToProcess = []
        }
      }
      if (listTemplatePdf.length > 0) {
          MediaApi.getListMedia(listTemplatePdf).then(response => {
            for (const media of response.media) {
              saveAs(media.url, media.filename)
            }
          })
      }
      return Promise.all(promises)
        .then((response) => {
          if (this.selectedProfile === null) {
            countResult = response.length
            const renderId = []
            response.forEach((element, index) => {
              if (countResult > 10) {
                const contract = this.contractTemplate.find(x => x.id === array[index].contract)
                const contractName = contract.documentName || contract.title
                let nameBlob
                if (this.mergeDocuments) {
                  nameBlob = contractName
                } else {
                  if (this.source === 'contact') {
                    const currentContact = this.contacts.find(x => x.id === array[index].contactId || x.objectID === array[index].contactId)
                    const tenantName = currentContact && 'name' in currentContact && currentContact.name ? currentContact.name + '_' + currentContact.numericId : ''
                    nameBlob = contractName + '_' + tenantName
                  } else if (this.source === 'condominium') {
                    const currentCondo = this.listTenancies.find(x => x.id === array[index].condominiumId || x.objectID === array[index].condominiumId)
                    const tenantName = currentCondo && 'name' in currentCondo && currentCondo.name
                      ? currentCondo.name
                      : this.contacts.length > 0
                        ? this.contacts.filter(x => currentCondo.owner.contactIds.includes(x.id)).map(contact => contact.firstName && contact.lastName ? contact.firstName + ' ' + contact.lastName : contact.name).toString().replaceAll(',', '_')
                        : currentCondo.condominiumOwners && currentCondo.condominiumOwners.length > 0
                          ? currentCondo.condominiumOwners[0].name
                          : this.condominiumObject.length > 0
                            ? this.condominiumObject.find(x => currentCondo.tenant.contactIds.includes(x.contactId)).map(x => x.name).toString().replaceAll(',', '_')
                            : ''
                    const numberObject = currentCondo.numericId ? currentCondo.numericId : currentCondo.objectNumericId
                    nameBlob = contractName + '_' + tenantName + (numberObject ? '_' + numberObject : '')
                  } else {
                    const currentTenancy = this.listTenancies.find(x => x.id === array[index].tenancyId || x.objectID === array[index].tenancyId)
                    const tenantName = currentTenancy && 'name' in currentTenancy && currentTenancy.name
                      ? currentTenancy.name
                      : this.tenantObject.length > 0
                        ? this.tenantObject.find(x => currentTenancy?.tenant.contactIds.includes(x.contactId)).map(x => x.name).toString().replaceAll(',', '_')
                        : this.contacts && this.contacts.length > 0
                          ? this.contacts.filter(x => currentTenancy.tenant.contactIds.includes(x.id)).map(contact => contact.firstName && contact.lastName ? contact.firstName + ' ' + contact.lastName : contact.name).toString().replaceAll(',', '_')
                          : currentTenancy?.tenants.map(x => x.name).toString().replaceAll(',', '_')
                    const numberObject = currentTenancy?.numericId ? currentTenancy?.numericId : currentTenancy?.objectNumericId
                    nameBlob = contractName + '_' + tenantName + (numberObject ? '_' + numberObject : '')
                  }
                }
                const item = {
                  renderId: element.renderId + '_' + nameBlob + '-/-' + contract.enforcePdf,
                  contract: contract
                }
                renderId.push(item)
              } else {
                const contract = this.contractTemplate.find(x => x.id === array[index].contract)
                const contractName = contract.documentName || contract.title
                let downloadName = ''
                if (this.mergeDocuments) {
                  downloadName = contractName
                } else {
                  if (this.source === 'contact') {
                    const currentContact = this.contacts.find(x => x.id === array[index].contactId || x.objectID === array[index].contactId)
                    const tenantName = currentContact && 'name' in currentContact && currentContact.name ? currentContact.name + '_' + currentContact.numericId : ''
                    downloadName = contractName + '_' + tenantName
                  } else {
                    if (this.source === 'condominium') {
                      const currentCondo = this.listTenancies.find(x => x.id === array[index].condominiumId || x.objectID === array[index].condominiumId)
                      const tenantName = currentCondo && 'name' in currentCondo && currentCondo.name
                        ? currentCondo.name
                        : this.contacts.length > 0
                          ? this.contacts.filter(x => currentCondo.owner.contactIds.includes(x.id)).map(contact => contact.firstName && contact.lastName ? contact.firstName + ' ' + contact.lastName : contact.name).toString().replaceAll(',', '_')
                          : currentCondo.condominiumOwners && currentCondo.condominiumOwners.length > 0
                            ? currentCondo.condominiumOwners[0].name
                            : this.condominiumObject.length > 0
                              ? this.condominiumObject.find(x => currentCondo.tenant.contactIds.includes(x.contactId)).map(x => x.name).toString().replaceAll(',', '_')
                              : ''

                      const numberObject = currentCondo.numericId ? currentCondo.numericId : currentCondo.objectNumericId
                      downloadName = contractName + '_' + tenantName + (numberObject ? '_' + numberObject : '')
                    } else {
                      const currentTenancy = this.listTenancies.find(x => x.id === array[index].tenancyId || x.objectID === array[index].tenancyId)
                      const tenantName = currentTenancy && 'name' in currentTenancy && currentTenancy.name
                        ? currentTenancy.name
                        : this.tenantObject.length > 0
                          ? this.tenantObject.find(x => currentTenancy?.tenant.contactIds.includes(x.contactId)).map(x => x.name).toString().replaceAll(',', '_')
                          : this.contacts && this.contacts.length > 0 && this.contacts.filter(x => currentTenancy?.tenant.contactIds.includes(x.id)).length > 0
                            ? this.contacts.filter(x => currentTenancy?.tenant.contactIds.includes(x.id))?.map(contact => contact.firstName && contact.lastName ? contact.firstName + ' ' + contact.lastName : contact.name).toString().replaceAll(',', '_')
                            : currentTenancy?.tenants ? currentTenancy?.tenants?.map(x => x.name).toString().replaceAll(',', '_') : ''
                      const numberObject = currentTenancy?.numericId ? currentTenancy?.numericId : currentTenancy?.objectNumericId ? currentTenancy?.objectNumericId : this.listObjects.find(x => x.id === currentTenancy?.objectId) ? this.listObjects.find(x => x.id === currentTenancy?.objectId).numericId : null
                      downloadName = contractName + '_' + tenantName + (numberObject ? '_' + numberObject : '')
                    }
                  }
                }
                const item = {
                  renderId: element.renderId + '_' + downloadName + '-/-' + contract.enforcePdf,
                  contract: contract
                }
                renderId.push(item)
              }
              localStorage.setItem('renderId', JSON.stringify(renderId))
              Vue.toasted.show(this.$t('message.successMessages.serialLetterCreated'), { type: 'success' })
            })
          }
        })
        .catch(e => {
          console.log(e)
          if (e.response?.data?.message === 'Lack of brief tag') {
            Vue.toasted.show(this.$t('message.savingErrors.lackOfBriefTag'), { type: 'error' })
          } else if (e.response?.data?.message === 'Lack of data loop') {
            Vue.toasted.show(this.$t('message.savingErrors.lackOfDataLoop'), { type: 'error' })
          } else {
            Vue.toasted.show(this.$t('message.savingErrors.renderingContractError'), { type: 'error' })
          }
          this.loading = false
        })
        .finally(() => {
          this.renderContractLoading = false
          this.mergeDocuments = false
          this.selectedProfile = null
          this.title = ''
          this.description = ''
          localStorage.removeItem(this.uuid)
          this.redirectToPreviousPage()
        })
    },
    checkDocumentName(renderId) {
      const renderIdsWithData = [renderId]
      const renderContractName = (renderIdsWithData.find(w => w.startsWith(renderId)).substring(renderIdsWithData.find(w => w.startsWith(renderId)).indexOf('_') + 1)).split('-/-')[0]
      const renderContractEnforcePdf = (renderIdsWithData.find(w => w.startsWith(renderId)).substring(renderIdsWithData.find(w => w.startsWith(renderId)).indexOf('_') + 1)).split('-/-')[1]
      return { renderContractName, renderContractEnforcePdf }
    },
    backClicked(props) {
      props.prevTab()
      this.scrollToTop()
    },
    getFilteredTenancies(objectId) {
      return this.tenancies.filter(ten => {
        return ten.objectId === objectId
      })
    },
    getCurrentTenancy(tenancies) {
      // We got an object from our backend
      const startOfDayTimestamp = this.getStartOfDayTimestamp()

      return tenancies
        .sort((a, b) => new Date(this.$options.filters.objectToDateInput(a.startingAt)).getTime() / 1000 > new Date(this.$options.filters.objectToDateInput(b.startingAt)).getTime() / 1000 ? -1 : 1)
        .find(t => t.startingAt && new Date(this.$options.filters.objectToDateInput(t.startingAt)).getTime() / 1000 <= startOfDayTimestamp && (!t.endingAt || (new Date(this.$options.filters.objectToDateInput(t.endingAt)).getTime() / 1000 < 0 || new Date(this.$options.filters.objectToDateInput(t.endingAt)).getTime() / 1000 >= startOfDayTimestamp)))
    },
    getCurrentCondominium(condominiums) {
      // We got an object from our backend
      const startOfDayTimestamp = this.getStartOfDayTimestamp()
      if (condominiums.length > 0) {
        return condominiums
          .sort((a, b) => new Date(this.$options.filters.objectToDateInput(a.startingAt)).getTime() / 1000 > new Date(this.$options.filters.objectToDateInput(b.startingAt)).getTime() / 1000 ? -1 : 1)
          .find(t => t.startingAt && new Date(this.$options.filters.objectToDateInput(t.startingAt)).getTime() / 1000 <= startOfDayTimestamp && (!t.endingAt || (new Date(this.$options.filters.objectToDateInput(t.endingAt)).getTime() / 1000 < 0 || new Date(this.$options.filters.objectToDateInput(t.endingAt)).getTime() / 1000 >= startOfDayTimestamp)))
      }
      return null
    },
    getListTenancies() {
      if (this.objectIds.length) {
        ObjectApi.listCurrentByObjectIds(this.objectIds.map(obj => obj)).then(responseTenancy => {
          const promises = []
          for (let index = 0; index < responseTenancy.tenancies.length; index++) {
            promises.push(ObjectApi.getObject(responseTenancy.tenancies[index].objectId))
          }
          Promise.all(promises)
            .then((responseObject) => {
              this.listTenancies = []
              for (let index = 0; index < responseTenancy.tenancies.length; index++) {
                Object.assign(responseTenancy.tenancies[index], { objectNumericId: responseObject[index].object?.numericId ? responseObject[index].object.numericId : '' })
                this.listTenancies.push(responseTenancy.tenancies[index])
              }
              this.renderContractLoading = false
            })
            .catch(e => {
              console.log(e)
              this.renderContractLoading = false
            })
        })
      }
    }
  },
  validations() {
    if (this.sendToBriefbutler) {
      return {
        selectedProfile: {
          required
        }
      }
    }

    return {}
  }
}
</script>

<style lang="scss" scoped>
.container-preview-img {
  max-height: 40rem;
  overflow: hidden;
  padding: 60px;
}

.step3 {
  min-height: 40vh;
}
.title-center {
  align-content: center;
}
</style>
