import Vue from 'vue'
import store from '../store'
import HandoverProtocolApi from '@/misc/apis/HandoverProtocolApi'

interface StateType {
  countHandovers: Number;
  loading: boolean;
}

interface ContextType {
  commit: Function;
  state: StateType;
}
export default {
  namespaced: true,
  state: {
    countHandovers: 0
  },
  getters: {
    getCountHandovers(state: StateType) {
      return state.countHandovers
    }
  },
  mutations: {
    setCountHandovers(state: StateType, value: Number) {
      Vue.set(state, 'countHandovers', value)
    },
    setLoading: (state: StateType) => {
      state.loading = true
    }
  },
  actions: {
    setCountHandovers: ({ commit }: ContextType, value: Number) => {
      commit('setCountHandovers', value)
    },
    loadCountHandovers: async ({ commit, state }: ContextType, force: Boolean) => {
      return new Promise((resolve, reject) => {
        // Setup watcher for accountId if not yet available
        if (!store.getters['user/getUserId']) {
          commit('setLoading')
          store.watch((watchedState, getters) => getters['user/getUserId'], () => {
            HandoverProtocolApi.countMyDrafts().then((response) => {
                commit('setCountHandovers', response.count)
                resolve(state.countHandovers)
              })
              .catch(e => {
                console.error(e)
                reject(e)
              })
          })
        } else if (force) {
          // If force OR loading is not already triggered and stored tickets count are older than 30 seconds -> Load new data
          commit('setLoading')
          // Load and sort users
          HandoverProtocolApi.countMyDrafts().then((response) => {
            commit('setCountHandovers', response.count)
            resolve(state.countHandovers)
          })
          .catch(e => {
            console.error(e)
            reject(e)
          })
        } else {
          resolve(state.countHandovers)
        }
      })
    }
  }
}
