import networkTS from '@/misc/apis/networkTS'

export default {
  async enableEvorest(accountId: string, enable: boolean, apiKey: string) {
    return networkTS.evorestServiceEnable(accountId, { enable: enable, apiKey: apiKey })
      .then((response) => {
        return response.data
      })
  },
  async getEvorestAccountSettings(accountId: string) {
    return networkTS.evorestServiceGetAccountSettings(accountId)
      .then(response => {
        return response.data
      })
  },
  async hasEvorestEnabled(accountId: string) {
    return networkTS.evorestServiceHasEnabled(accountId)
      .then((response) => {
        return response.data
      })
  },
  async getOwnerConfig(accountId: string) {
    return networkTS.evorestServiceGetOwnerConfig(accountId)
      .then((response) => {
        return response.data
      })
  },
  async setOwnerConfig(accountId: string, allowInvestment: boolean) {
    return networkTS.evorestServiceSetOwnerConfig(accountId, { allowInvestment: allowInvestment })
      .then((response) => {
        return response.data
      })
  },
  async openDeposit(objectId: string, tenancyId: string, allowInvestment: boolean) {
    return networkTS.evorestServiceOpenDeposit({
      objectId: objectId,
      tenancyId: tenancyId,
      allowInvestment: allowInvestment
    })
      .then((response) => {
        return response.data
      })
  }
}
