<template>
  <coozzy-card :title="$t('message.settings.template.titleContract')">
    <template-table
      :source="'tenancy'"
      :by-owners-ids="[parseInt(ownerId)]"
      :default-category="'TEMPLATE_CATEGORY_CONTRACTS'"
      @selected-contract="selectedContract" />
    <coozzy-form-checkbox
      v-if="isEditor && hasDocumentsPortal && addDocumentToContact"
      v-model="showInPortal"
      class="float-right pr-3 mt-1 mr-0 display-none-xs font-weight-lighter">
      {{ $t('message.generic.showInPortal') }}
      <coozzy-info-circle-icon
        v-if="!isPortalLinkEnabled"
        v-b-tooltip.hover.html="$t('message.manageDocuments.tooltipShowInPortal')"
        class="ml-1" />
    </coozzy-form-checkbox>
    <coozzy-form-checkbox
      v-model="addDocumentToContact"
      class="float-right pr-3 mt-1 mr-0 display-none-xs font-weight-lighter">
      {{ $t('message.generic.addDocumentToTenancy') }}
    </coozzy-form-checkbox>
  </coozzy-card>
</template>

<script>
import TemplateTable from '@/settings/components/TemplateTable'
import CoozzyCard from '@/framework/components/card/CoozzyCard.vue'
import CoozzyFormCheckbox from '@/framework/components/form/checkbox/CoozzyFormCheckbox.vue'
import { user } from '@/mixins/user'
import CoozzyInfoCircleIcon from '@/framework/components/icons/CoozzyInfoCircleIcon.vue'

export default {
  name: 'AddTenancyContractTab',
  components: {
    CoozzyInfoCircleIcon,
    CoozzyFormCheckbox,
    CoozzyCard,
    TemplateTable
  },
  mixins: [user],
  props: {
    isPortalLinkEnabled: {
      type: Boolean,
      required: true,
      default: false
    },
    ownerId: {
      type: [String, Number],
      required: true
    }
  },
  data() {
    return {
      contractTemplate: [],
      addDocumentToContact: false,
      showInPortal: false
    }
  },
  watch: {
    addDocumentToContact: function (newValue) {
      this.$emit('attach-tenancy', newValue)
    },
    showInPortal: function (newValue) {
      this.$emit('show-in-portal', newValue)
    }
  },
  methods: {
    selectedContract(value, contracts) {
      this.$emit('selected-contract', value, contracts)
    }
  }
}
</script>

<style lang="scss" scoped>
.readonly-wrapper {
  .inputs-container {
    overflow-y: scroll;
    height: calc(100vh - 100px);
  }

  .item {
    cursor: pointer;
  }

  :deep(.details-row-bg-1) {
    background-color: $color-table-row-2;
  }

  :deep(.details-row-bg-2) {
    background-color: $color-table-row-1;
  }

  :deep(label) {
    width: 100%;
  }

  :deep(.is-disabled) {
    .sort button {
      display: block !important;
    }

    button:not(.sort):not(.page-link) {
      display: none;
    }

    .multiselect, .custom-checkbox {
      pointer-events: none;

      .multiselect__select, .multiselect__placeholder {
        display: none;
      }
    }

    .filter-inputs .multiselect, .custom-checkbox {
      pointer-events: auto !important;

      .multiselect__select, .multiselect__placeholder {
        display: block !important;
      }
    }

    .basic {
      input {
        opacity: 1;
        border: thin solid #ced4da;
        -webkit-box-shadow: inherit;
        box-shadow: inherit;
        margin-bottom: 0;
        pointer-events: initial;
        padding-left: 0.2rem;
        background: none;
      }
    }

    .multi-select-value {
      line-height: 1.5;
    }
  }

  :deep(.collapse-title) {
    &.collapsed {
      h5 {
        background-image: url('../assets/arrow_down.svg');
      }
    }

    h5 {
      background-image: url('../assets/arrow_up.svg');
      background-repeat: no-repeat;
      font-size: large;
      font-weight: 500 !important;
      height: 1.2rem;
      margin-top: 0.2rem;
      margin-bottom: 0.2rem;
      background-position: right;
    }
  }

  :deep(.global-card) {
    margin-bottom: 0;

    & > .card-body {
      -webkit-box-shadow: inset 0px -16px 25px -15px rgba(238, 238, 238, 1);
      -moz-box-shadow: inset 0px -16px 25px -15px rgba(238, 238, 238, 1);
      box-shadow: inset 0px -16px 25px -15px rgba(238, 238, 238, 1);
    }

    border-left: 0;
    border-right: 0;
    border-bottom: 0;

    &.no-top-border {
      border-top: 0;
    }
  }
}

</style>
