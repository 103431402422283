<template>
  <section>
    <div>
      <h1
        v-if="!loading">
        {{ $t('message.onBoarding.buildings.objects.tenancies.termination') }}
      </h1>
    </div>
    <div class="row">
      <div class="col">
        <form-wizard
          id="wizard"
          :hide-buttons="loading || loadingCreateDocuments"
          step-size="xs"
          color="rgb(0, 68, 95)"
          @on-change="handleTabChange">
          <span slot="title" />
          <tab-content :title="$t('message.enterTermination.otherTenancies')">
            <div
              v-if="loading"
              class="text-center">
              <coozzy-spinner />
            </div>
            <enter-termination-other-tenancies
              v-else-if="!loading && objectsWithSameTenants.length > 0 && sameTenanciesByObject.length > 0"
              :objects-with-same-tenants="objectsWithSameTenants"
              :same-tenancies-by-object="sameTenanciesByObject"
              :object-id="objectId"
              @selected-object="selectedObject" />
          </tab-content>
          <tab-content :title="$t('message.enterTermination.reviewTermination')">
            <div
              v-if="loading"
              class="text-center">
              <coozzy-spinner />
            </div>
            <enter-termination-review
              v-else-if="!loading && selectedObjectToProcess.length > 0"
              :selected-object-to-process="selectedObjectToProcess"
              @selected-entries-review="itemSelectedReview" />
          </tab-content>
          <tab-content :title="$t('message.enterTermination.terminationDetails')">
            <div
              v-if="loading"
              class="text-center">
              <coozzy-spinner />
            </div>
            <div v-else-if="!loading && selectedObjectToProcess.length > 0 && lastTenancy">
              <enter-termination-details
                ref="enter-termination-details"
                :selected-object-to-process="selectedObjectToProcess"
                :tenants="lastTenancy"
                :array-details-of-tenants="detailsOfTenants"
                @tenancy-details-updated="tenancyDetailsUpdated" />
            </div>
          </tab-content>
          <tab-content :title="$t('message.enterTermination.createDocument')">
            <div
              v-if="loading || loadingCreateDocuments"
              class="text-center">
              <coozzy-spinner />
            </div>
            <div v-else-if="!loading && !loadingCreateDocuments">
              <enter-termination-create-document
                :selected-object-to-process="selectedObjectToProcess"
                :is-portal-link-enabled="isPortalLinkEnabled"
                @attach-tenancy="addDocumentToTenancy"
                @show-in-portal="showInPortalMethod"
                @selected-contract="contractSelectedAction" />
            </div>
          </tab-content>
          <tab-content
            :title="$t('message.enterTermination.addVacancy')">
            <div
              v-if="loading"
              class="text-center">
              <coozzy-spinner />
            </div>
            <enter-termination-add-tenancy
              v-if="!loading && selectedObjectToProcess.length > 0"
              ref="enter-termination-add-tenancy"
              :selected-object-to-process="selectedObjectToProcess"
              :details-of-termination="detailsOfTenants"
              @all-vacancy-added="allVacancyAdded" />
          </tab-content>
          <template
            slot="footer"
            slot-scope="props">
            <template v-if="!loading && !loadingCreateDocuments">
              <div class="wizard-footer-left">
                <wizard-button
                  v-if="props.activeTabIndex === 0"
                  :style="props.fillButtonStyle"
                  @click.native="$router.back()">
                  {{ $t('message.generic.cancel') }}
                </wizard-button>
                <wizard-button
                  v-else
                  :style="props.fillButtonStyle"
                  @click.native="backClicked(props)">
                  {{ $t('message.generic.back') }}
                </wizard-button>
              </div>
              <div class="wizard-footer-right">
                <wizard-button
                  v-if="!props.isLastStep && props.activeTabIndex !== 2 && !(props.activeTabIndex === 3 && anyContract)"
                  :disabled="contactIds.length !== 0"
                  class="wizard-footer-right"
                  :style="props.fillButtonStyle"
                  @click.native="nextStep(props)">
                  {{ $t('message.generic.next') }}
                </wizard-button>
                <wizard-button
                  v-else-if="props.activeTabIndex === 2"
                  :disabled="contactIds.length !== 0"
                  class="wizard-footer-right"
                  :style="props.fillButtonStyle"
                  @click.native="setTermination(props)">
                  {{ $t('message.enterTermination.saveAndNext') }}
                </wizard-button>
                <wizard-button
                  v-else-if="props.activeTabIndex === 3 && anyContract"
                  :disabled="contactIds.length !== 0"
                  class="wizard-footer-right"
                  :style="props.fillButtonStyle"
                  @click.native="createDocumentAndNextStep(props)">
                  {{ $t('message.enterTermination.createDocument') }}
                </wizard-button>
                <wizard-button
                  v-else
                  class="wizard-footer-right"
                  :style="props.fillButtonStyle"
                  @click.native="addVacancyInTermination">
                  <template>
                    {{ $t('message.generic.form.save') }}
                  </template>
                </wizard-button>
              </div>
            </template>
            <b-modal
              ref="confirmation-modal-review-contacts"
              no-close-on-backdrop
              hide-footer
              :title="$t('message.enterTermination.noAllTerminationReceived.title')">
              <div class="col pl-0">
                <p>{{ $t('message.enterTermination.noAllTerminationReceived.text') }}</p>
              </div>
              <div class="col pl-0">
                <coozzy-button
                  size="small"
                  class="mb-0 border"
                  design="transparent"
                  @click="hideModal">
                  {{ $t('message.generic.cancel') }}
                </coozzy-button>
                <coozzy-button
                  size="small"
                  class="float-right mb-0"
                  design="green"
                  @click="confirmReviewContacts(props)">
                  {{ $t('message.enterTermination.noAllTerminationReceived.continue') }}
                </coozzy-button>
              </div>
            </b-modal>
          </template>
        </form-wizard>
      </div>
    </div><b-modal
      id="confirmationModal"
      ref="confirmationModal"
      no-close-on-backdrop
      :title="$t('message.onBoardingTabs.confirmModal.confirmationTitle')"
      hide-footer>
      <p>{{ $t('message.onBoardingTabs.confirmModal.confirmationText') }}</p>
      <div class="col">
        <coozzy-button
          size="small"
          class="border mb-0"
          design="transparent"
          @click="$bvModal.hide('confirmationModal')">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
        <coozzy-button
          size="small"
          class="float-right mb-0"
          design="green"
          @click="confirmCancellation">
          {{ $t('message.onBoardingTabs.confirmModal.confirmationButton') }}
        </coozzy-button>
      </div>
    </b-modal>
  </section>
</template>

<script>
import Vue from 'vue'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner'
import ContactApi from '@/misc/apis/ContactApi'
import ObjectApi from '@/misc/apis/ObjectApi'
import { formatting } from '@/mixins/formatting'
import { obj } from '@/mixins/object'
import { onboarding } from '@/mixins/onboarding'
import { routeChecks } from '@/mixins/routeChecks'
import EnterTerminationAddTenancy from '@/enterTermination/EnterTerminationAddTenancy'
import EnterTerminationCreateDocument from '@/enterTermination/EnterTerminationCreateDocument'
import EnterTerminationDetails from '@/enterTermination/EnterTerminationDetails'
import EnterTerminationOtherTenancies from '@/enterTermination/EnterTerminationOtherTenancies'
import EnterTerminationReview from '@/enterTermination/EnterTerminationReview'
import CoozzyButton from '@/framework/components/button/CoozzyButton.vue'
import { user } from '@/mixins/user'

export default {
  name: 'EnterTerminationView',
  components: {
    CoozzyButton,
    CoozzySpinner,
    EnterTerminationAddTenancy,
    EnterTerminationCreateDocument,
    EnterTerminationDetails,
    EnterTerminationOtherTenancies,
    EnterTerminationReview
  },
  mixins: [formatting, obj, onboarding, routeChecks, user],
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
  beforeRouteLeave (to, from, next) {
    this.nextToPath = to
    if (!this.confirmCancellationProcess) {
      this.$refs.confirmationModal.show()
    } else {
      next()
    }
  },
  props: {
    objectId: {
      type: String,
      required: true
    },
    tenancyId: {
      type: [String, Number],
      default: ''
    },
    buildingId: {
      type: [String, Number],
      default: ''
    }
  },
  metaInfo() {
    return {
      title: this.$t('message.onBoarding.buildings.objects.tenancies.termination')
    }
  },
  data() {
    return {
      anyContract: false,
      addDocumentToContact: false,
      contactIds: [],
      detailsOfTenants: [],
      lastTenancy: null,
      loading: false,
      loadingCreateDocuments: false,
      object: null,
      objectsWithSameTenants: [],
      sameTenanciesByObject: [],
      selectedEntriesContract: [],
      selectedEntriesReview: [],
      selectedObjectToProcess: [],
      preventChangesInSelectedObjectToProcess: false,
      tenancy: null,
      visibleModal: false,
      confirmationReviewContacts: false,
      confirmCancellationProcess: false,
      nextToPath: null,
      showInPortal: false
    }
  },
  computed: {
    isPortalLinkEnabled() {
      return this.$store.getters['onboarding/getIsPortalLinkEnabled']
    },
    onlyOneTenant() {
      return this.sameTenanciesByObject.map(x => x.tenant.contactIds.length === 1).every(Boolean)
    },
    prevRoutePath() {
      return this.prevRoute ? this.prevRoute.path : '/'
    },
    prevRouteQuery() {
      return this.prevRoute ? this.prevRoute.query : null
    }
  },
  mounted() {
    this.loading = true
    const t = this
    ObjectApi.getObject(this.objectId).then(values => {
      this.object = values.object
      ObjectApi.getTenancies([this.object.id]).then(response => {
        if (response.tenancies.length > 0) {
          if (response.tenancies.find(x => x.status === 'TENANCY_STATUS_CURRENT' && !x.endingAt)) {
            this.lastTenancy = response.tenancies.find(x => x.status === 'TENANCY_STATUS_CURRENT' && !x.endingAt)
          } else {
            this.lastTenancy = response.tenancies.sort(function (a, b) {
              return new Date(t.$options.filters.objectToDateInput(b.endingAt)).getTime() - new Date(t.$options.filters.objectToDateInput(a.endingAt)).getTime()
            })[0]
          }
          const promises = []
          if (this.lastTenancy && this.lastTenancy.tenant.contactIds) {
            promises.push(ObjectApi.getTenanciesByTenant(this.lastTenancy.ownerId, this.lastTenancy.tenant.contactIds[0]).then((response) => {
              const allReceivedTenancies = response.tenancies
              for (let index = 0; index < allReceivedTenancies.length; index++) {
                if (allReceivedTenancies[index].status === 'TENANCY_STATUS_CURRENT') {
                  this.sameTenanciesByObject.push(allReceivedTenancies[index])
                }
              }
              ObjectApi.listObjectsByIds(this.sameTenanciesByObject.map(x => x.objectId)).then((response) => {
                const allReceivedObjects = response.objects
                for (let index = 0; index < allReceivedObjects.length; index++) {
                  this.objectsWithSameTenants.push(allReceivedObjects[index])
                }
                this.loading = false
              })
            }))
          }
        }
      })
    }).catch(e => {
      this.loading = false
      console.log(e)
      Vue.toasted.show(this.$t('message.loadingErrors.object'), { type: 'error' })
      this.$router.back()
    })
  },
  methods: {
    confirmCancellation() {
      this.confirmCancellationProcess = true
      if (this.nextToPath !== null) {
        this.$router.push({ name: this.nextToPath.name, params: this.nextToPath.params, query: this.nextToPath.query }, () => {
        })
      } else {
        this.$router.push({ path: this.prevRoutePath, query: this.prevRouteQuery }, () => {
        })
      }
    },
    handleTabChange(prevIndex, nextIndex) {
      if (nextIndex !== 0) {
        this.preventChangesInSelectedObjectToProcess = true
      } else {
        this.preventChangesInSelectedObjectToProcess = false
      }
    },
    setTermination(props) {
      this.loading = true
      for (let index = 0; index < this.$refs['enter-termination-details'].$refs['enter-termination-details-tenant-items'].length; index++) {
        if (this.$refs['enter-termination-details'].$refs['enter-termination-details-tenant-items'][index].isInvalid()) {
          this.loading = false
          return 0
        }
      }
      const promises = []
      for (let index = 0; index < this.detailsOfTenants.length; index++) {
        const termination = {
          terminateAt: this.detailsOfTenants[index].terminateAt ? this.detailsOfTenants[index].terminateAt : null,
          terminatedOn: this.detailsOfTenants[index].terminatedOn ? this.detailsOfTenants[index].terminatedOn : null,
          liabilityEndsAt: this.detailsOfTenants[index].liabilityEndsAt,
          nextPossibleMoveInAt: this.detailsOfTenants[index].nextPossibleMoveInAt ? this.detailsOfTenants[index].nextPossibleMoveInAt : null,
          movedOutAt: this.detailsOfTenants[index].movedOutAt,
          terminationCreatedAt: this.detailsOfTenants[index].terminationCreatedAt,
          terminationJustification: this.detailsOfTenants[index].terminationJustification,
          terminatedBy: this.detailsOfTenants[index].terminatedBy,
          terminationReason: this.detailsOfTenants[index].terminationReason,
          terminationType: this.detailsOfTenants[index].terminationType,
          documentIds: this.detailsOfTenants[index].documentIds,
          terminationSentByTenantAt: this.detailsOfTenants[index].terminationSentByTenantAt
        }
        promises.push(ObjectApi.setTermination(this.detailsOfTenants[index].id, termination))
      }
      Promise.all(promises)
        .then(response => {
          ObjectApi.updateTenancy(response[0].tenancy)
            .then(response => {
              this.loading = false
              props.nextTab()
              this.scrollToTop()
            })
        })
        .catch(e => {
          this.loading = false
          Vue.toasted.show(this.$t('message.savingErrors.enterTerminationError'), { type: 'error' })
          console.log(e)
        })
    },
    addVacancyInTermination() {
      this.$refs['enter-termination-add-tenancy'].validateBeforeSaveAddVacancy()
    },
    allVacancyAdded(value) {
      this.confirmCancellationProcess = true
      const nextView = localStorage.getItem('termination-page')
      const selectedItem = nextView === 'tenants' ? this.lastTenancy.id : this.lastTenancy.objectId
      localStorage.removeItem('termination-page')
      localStorage.setItem('latestCreatedTenancy', nextView === 'tenants' ? this.lastTenancy.id : this.lastTenancy.objectId)
      if (value === false) {
        this.loading = false
      } else {
        this.loading = false
        if (this.isOwnerModule) {
          this.$router.push({ name: 'OwnerBuildingDetailsView', params: { id: this.buildingId ? this.buildingId : this.building.id }, query: { view: nextView, elementSelected: selectedItem } }, () => {})
        } else if (this.isAdminModule) {
          this.$router.push({ name: 'AdminBuildingDetailsView', params: { id: this.buildingId ? this.buildingId : this.building.id }, query: { view: nextView, elementSelected: selectedItem } }, () => {})
        } else if (this.isAssetModule) {
          this.$router.push({ name: 'AssetBuildingDetailsView', params: { id: this.buildingId ? this.buildingId : this.building.id }, query: { view: nextView, elementSelected: selectedItem } }, () => {})
        } else if (this.isAccountingModule) {
          this.$router.push({ name: 'AccountingBuildingDetailsView', params: { id: this.buildingId ? this.buildingId : this.building.id }, query: { view: nextView, elementSelected: selectedItem } }, () => {})
        }
      }
    },
    tenancyDetailsUpdated(tenantsDetails) {
      this.detailsOfTenants = tenantsDetails
    },
    selectedObject(object) {
      if (!this.preventChangesInSelectedObjectToProcess) {
        this.selectedObjectToProcess = object
        this.isAllTenantsReviewed()
      }
    },
    itemSelectedReview(selected) {
      this.selectedEntriesReview = selected
      this.isAllTenantsReviewed()
    },
    isAllTenantsReviewed() {
      this.confirmationReviewContacts = true
      Object.keys(this.selectedEntriesReview).forEach(key => {
        Object.keys(this.selectedObjectToProcess).forEach(key2 => {
          if (key === this.selectedObjectToProcess[key2].id) {
            if (!this.selectedEntriesReview[key]) {
              this.confirmationReviewContacts = false
            }
          }
        })
      })
      if (Object.keys(this.selectedEntriesReview).length === 0) {
        this.confirmationReviewContacts = false
      }
    },
    nextStep(props) {
      if (props.activeTabIndex === 0 && this.onlyOneTenant) {
        props.nextTab()
        this.scrollToTop()
      }
      if (props.activeTabIndex === 1) {
        if (this.confirmationReviewContacts === false) {
          this.showModal()
        } else {
          props.nextTab()
          this.scrollToTop()
        }
      } else {
        props.nextTab()
        this.scrollToTop()
      }
    },
    backClicked(props) {
      if (props.activeTabIndex === 2) {
        this.isAllTenantsReviewed()
      }
      props.prevTab()
      this.scrollToTop()
    },
    scrollToTop() {
      this.$nextTick(() => {
        const domRect = this.$el.querySelector('#wizard')
        domRect.scrollIntoView(true)
      })
    },
    addDocumentToTenancy(value) {
      this.addDocumentToContact = value
    },
    showInPortalMethod(value) {
      this.showInPortal = value
    },
    contractSelectedAction(value, contracts, objectId) {
      const item = {}
      item.value = value
      item.contracts = contracts
      item.objectId = objectId
      const index = this.selectedEntriesContract.findIndex(c => c.objectId === objectId)
      if (index !== -1) {
        this.selectedEntriesContract[index] = item
      } else {
        this.selectedEntriesContract.push(item)
      }
      this.isAnyContract()
    },
    isAnyContract() {
      this.anyContract = false
      this.selectedEntriesContract.forEach((entry) => {
        if (entry.value.length > 0) {
          this.anyContract = true
        }
      })
    },
    async createDocumentAndNextStep(props) {
      this.loadingCreateDocuments = true
      const current = this
      const promises = []
      const promises2 = []
      const argumentsForPromises2 = []
      for (let index = 0; index < this.selectedEntriesContract.length; index++) {
        promises.push(ObjectApi.getTenancies([this.selectedEntriesContract[index].objectId]).then(response => {
          if (response.tenancies.length > 0) {
            this.selectedEntriesContract[index].tenancy = response.tenancies.sort(function (a, b) {
              return new Date(current.$options.filters.objectToDateInput(b.endingAt)).getTime() - new Date(current.$options.filters.objectToDateInput(a.endingAt)).getTime()
            })[0]
          }
        }))
      }
      await Promise.all(promises)
        .then(() => {
          for (let index = 0; index < this.selectedEntriesContract.length; index++) {
            for (let index2 = 0; index2 < this.selectedEntriesContract[index].value.length; index2++) {
              const signatories = []
              const contract = this.selectedEntriesContract[index].contracts.find(x => x.id === this.selectedEntriesContract[index].value[index2])
              if (contract && contract.firstSignature !== '') {
                signatories.push(contract.firstSignature)
              }
              if (contract && contract.secondSignature) {
                if (contract && contract.firstSignature === '') {
                  signatories.push('')
                }
                signatories.push(contract.secondSignature)
              }
              promises2.push(ObjectApi.RenderTenancyTemplate(this.selectedEntriesContract[index].value[index2], this.selectedEntriesContract[index].tenancy.id, this.addDocumentToContact, this.showInPortal, '', '', '', '', signatories))
              argumentsForPromises2.push(
                {
                  selected: this.selectedEntriesContract[index].value[index2],
                  contract: this.selectedEntriesContract[index].contracts.find(x => x.id === this.selectedEntriesContract[index].value[index2]),
                  tenancy: this.selectedEntriesContract[index].tenancy
                })
            }
          }
        })
      await Promise.all(promises2)
        .then(response => {
          const renderId = []
          response.forEach(async (element, index) => {
            const contract = argumentsForPromises2[index].contract
            let downloadName = ''
            const objectNumber = this.objectsWithSameTenants.find(x => x.id === argumentsForPromises2[index].tenancy.objectId).number
            if (argumentsForPromises2[index].tenancy.vacancy === false && argumentsForPromises2[index].tenancy.tenant.billingContactId) {
              const billingContact = await ContactApi.contactResolve([argumentsForPromises2[index].tenancy.tenant.billingContactId])
                .then(response => {
                  return response.persons.concat(response.companies).find(x => x.id === argumentsForPromises2[index].tenancy.tenant.billingContactId)
                })
              downloadName = contract.title + '_' + billingContact.name + '_' + objectNumber
            } else {
              downloadName = contract.title + '_' + objectNumber
            }
            const item = { renderId: element.renderId + '_' + downloadName + '-/-' + contract.enforcePdf, contract: contract }
            renderId.push(item)
            localStorage.setItem('renderId', JSON.stringify(renderId))
          })
        })
        .catch(e => {
          this.loadingCreateDocuments = false
          console.log(e)
          this.loading = false
          Vue.toasted.show(this.$t('message.applicationForm.creditTrust.certificateOrderError'), { type: 'error' })
        })
      this.loadingCreateDocuments = false
      this.selectedEntriesContract = []
      this.isAnyContract()
      this.nextStep(props)
    },
    confirmReviewContacts(props) {
      this.confirmationReviewContacts = true
      this.hideModal()
      this.nextStep(props)
    },
    showModal() {
      this.visibleModal = true
      this.$refs['confirmation-modal-review-contacts'].show()
    },
    hideModal() {
      this.$refs['confirmation-modal-review-contacts'].hide()
      this.visibleModal = false
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
