<template>
  <div
    class="">
    <h5>{{ $t('message.generic.preview') }}</h5>
    <b-table
      ref="logsTable"
      :fields="fields"
      :items="paginatedDocuments"
      bordered
      responsive="true"
      stacked="md">
      <!-- Headings -->
      <template #head(documentName)>
        {{ $t('message.manageDocuments.documentName') }}
      </template>

      <!-- Columns -->
      <template
        #cell(documentName)="data">
        <a
          @click="documentPreview(data.item)">
          <span
            v-if="data.item.loading"
            class="text-center mr-2">
            <coozzy-spinner />
          </span>
          <span>
            {{ data.item.filename }}
          </span>
        </a>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :per-page="nbrPerPage"
      :total-rows="allDocuments.length"
      align="center"
      class="mt-3 mb-3" />
  </div>
</template>

<script>

import ObjectApi from '@/misc/apis/ObjectApi'
import Vue from 'vue'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner.vue'
import MediaApi from '@/misc/apis/MediaApi'

export default {
  name: 'documentPreviewTable',
  components: {
    CoozzySpinner
  },
  mixins: [],
  props: {
    listTemplates: {
      type: Array,
      default: () => []
    },
    listTenancies: {
      type: Array,
      default: () => []
    },
    contacts: {
      type: Array,
      default: () => []
    },
    listObjects: {
      type: Array,
      default: () => []
    },
    selectedTemplate: {
      type: Array,
      default: () => []
    },
    mergeDocuments: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    sortingType: {
      type: String,
      default: 'RENDER_ORDER_DEFAULT'
    },
    source: {
      type: String,
      default: ''
    },
    showInPortal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      fields: [
        {
          key: 'documentName',
          label: this.$t('message.manageDocuments.documentName')
        }
      ],
      nbrPerPage: 5,
      currentPage: 1,
      documentList: [],
      pdfList: []
    }
  },
  computed: {
    paginatedDocuments() {
      return this.allDocuments.slice((this.currentPage - 1) * this.nbrPerPage, this.currentPage * this.nbrPerPage)
    },
    allDocuments() {
      const list = []
      this.documentList.forEach(doc => {
        const document = {
          internalId: this.getRandomKey(),
          renderId: '',
          url: '',
          loading: false,
          ...doc
        }
        list.push(document)
      })
      this.pdfList.forEach(pdf => {
        const document = {
          internalId: this.getRandomKey(),
          url: '',
          loading: false,
          ...pdf
        }
        list.push(document)
      })
      return list
    }
  },
  watch: {
    selectedTemplate: {
      immediate: true,
      handler() {
        this.generateDocumentsName()
      }
    },
    mergeDocuments: {
      immediate: true,
      handler() {
        this.generateDocumentsName()
      }
    }
  },
  methods: {
    getRandomKey() {
      return Math.random().toString(36).substring(2, 15)
    },
    generateDocumentsName() {
      let listDocNames = []
      let tenanciesToProcess = []
      this.selectedTemplate.forEach((id) => {
        const contract = this.listTemplates.find(x => x.id === id)
        const contractName = contract.documentName || contract.title
        if (contract && !contract.mediaId.includes('.pdf')) {
          let downloadName = ''
          if (this.mergeDocuments) {
            let ids = []
            downloadName = contractName
            if (this.source === 'contact') {
              ids = this.contacts.map(contact => contact.id || contact.objectID)
            } else {
              if (this.listTenancies.length > 0) {
                const id = this.listTenancies[0].id || this.listTenancies[0].objectID
                if (id.startsWith('condominium_')) {
                  this.listTenancies.forEach(element => {
                    if (tenanciesToProcess.filter(elem => JSON.stringify(elem.owner.contactIds.sort()) === JSON.stringify(element.owner.contactIds.sort())).length === 0) {
                      tenanciesToProcess.push(element)
                    }
                    ids = tenanciesToProcess.map(condominium => condominium.id || condominium.objectID)
                  })
                } else {
                  this.listTenancies.forEach(element => {
                    if (!element.vacancy && tenanciesToProcess.filter(elem => JSON.stringify(elem.tenant.contactIds.sort()) === JSON.stringify(element.tenant.contactIds.sort())).length === 0) {
                      tenanciesToProcess.push(element)
                    }
                  })
                  ids = tenanciesToProcess.map(tenancy => tenancy.id || tenancy.objectID)
                }
              }
            }
            listDocNames.push({
              contract: contract,
              filename: downloadName,
              ids: ids
            })
          } else {
            if (this.source === 'contact') {
              this.contacts.forEach(contact => {
                const tenantName = contact && 'name' in contact && contact.name ? contact.name + '_' + contact.numericId : ''
                downloadName = contractName + '_' + tenantName
                listDocNames.push({
                  contract: contract,
                  filename: downloadName,
                  contactId: contact.id
                })
              })
            } else {
              if (this.listTenancies.length > 0) {
                const id = this.listTenancies[0].id || this.listTenancies[0].objectID
                this.listTenancies.forEach(item => {
                  if (id.startsWith('condominium_')) {
                    if (tenanciesToProcess.filter(elem => JSON.stringify(elem.owner.contactIds.sort()) === JSON.stringify(item.owner.contactIds.sort())).length === 0) {
                      const tenantName = item && 'name' in item && item.name
                        ? item.name
                        : this.contacts.length > 0
                          ? this.contacts.filter(x => item.owner.contactIds.includes(x.id)).map(contact => contact.firstName && contact.lastName ? contact.firstName + ' ' + contact.lastName : contact.name).toString().replaceAll(',', '_')
                          : item.condominiumOwners && item.condominiumOwners.length > 0
                            ? item.condominiumOwners[0].name
                            : ''

                      const numberObject = item.numericId ? item.numericId : item.objectNumericId
                      downloadName = contractName + '_' + tenantName + (numberObject ? '_' + numberObject : '')
                      listDocNames.push({
                        contract: contract,
                        filename: downloadName,
                        condominiumId: item.id
                      })
                      tenanciesToProcess.push(item)
                    }
                  } else {
                    if (!item.vacancy && tenanciesToProcess.filter(elem => JSON.stringify(elem.tenant.contactIds.sort()) === JSON.stringify(item.tenant.contactIds.sort())).length === 0) {
                      const tenantName = item && 'name' in item && item.name
                        ? item.name
                        : this.contacts && this.contacts.length > 0 && this.contacts.filter(x => item?.tenant.contactIds.includes(x.id)).length > 0
                          ? this.contacts.filter(x => item?.tenant.contactIds.includes(x.id))?.map(contact => contact.firstName && contact.lastName ? contact.firstName + ' ' + contact.lastName : contact.name).toString().replaceAll(',', '_')
                          : item?.tenants ? item?.tenants?.map(x => x.name).toString().replaceAll(',', '_') : ''
                      const numberObject = item?.numericId ? item?.numericId : item?.objectNumericId ? item?.objectNumericId : this.listObjects.find(x => x.id === item?.objectId) ? this.listObjects.find(x => x.id === item?.objectId).numericId : null
                      downloadName = contractName + '_' + tenantName + (numberObject ? '_' + numberObject : '')
                      listDocNames.push({
                        contract: contract,
                        filename: downloadName,
                        tenancyId: item.id
                      })
                      tenanciesToProcess.push(item)
                    }
                  }
                })
                tenanciesToProcess = []
              }
            }
          }
        } else {
          this.pdfList.push({
            filename: contractName,
            contract: contract
          })
        }
      })
      this.documentList = listDocNames
    },
    documentPreview(item) {
      if (item.url !== '') {
        window.open(item.url, '_blank')
      } else {
        if (this.allDocuments.find(e => e.internalId === item.internalId)?.loading === false) {
          this.generateTemplates(item)
        }
      }
    },
    generateTemplates(item) {
      this.allDocuments.forEach(element => {
        if (element.internalId === item.internalId) {
          element.loading = true
        }
      })
      const contract = item.contract
      const promises = []
      const signatories = []
      if (contract && !contract.mediaId.includes('.pdf')) {
        if (contract && contract.firstSignature !== '') {
          signatories.push(contract.firstSignature)
        }
        if (contract && contract.secondSignature) {
          if (contract && contract.firstSignature === '') {
            signatories.push('')
          }
          signatories.push(contract.secondSignature)
        }
        if (this.mergeDocuments) {
          if (this.source === 'contact') {
            promises.push(ObjectApi.renderContractContacts(contract.id, item.ids, this.title, this.description, '', '', signatories, false, this.showInPortal, this.sortingType, true))
          }
          if (this.source === 'tenancy') {
            promises.push(ObjectApi.renderTenanciesTemplate(contract.id, item.ids, this.title, this.description, '', signatories, '', false, this.showInPortal, 'RENDER_ORDER_DEFAULT', true))
          }
          if (this.source === 'condominium') {
            promises.push(ObjectApi.renderContractCondominiums(contract.id, item.ids, this.title, this.description, '', false, '', signatories, false, this.showInPortal, this.sortingType, true))
          }
        } else {
          if (this.source === 'contact') {
            promises.push(ObjectApi.renderContractContact(contract.id, item.contactId, false, this.showInPortal, this.title, this.description, '', '', signatories, true))
          }
          if (this.source === 'tenancy') {
            promises.push(ObjectApi.RenderTenancyTemplate(contract.id, item.tenancyId, false, this.showInPortal, this.title, this.description, '', '', signatories, false, true))
          }
          if (this.source === 'condominium') {
            promises.push(ObjectApi.renderContractCondominium(contract.id, item.condominiumId, false, this.showInPortal, this.title, this.description, '', false, '', signatories, true))
          }
        }
        Promise.all(promises).then(response => {
          if (response.length > 0) {
            const renderId = response[0].renderId
            const recheckDocs = setInterval(() => {
              ObjectApi.listRenderStatus([renderId])
                .then(response => {
                  const stillInProgress = response.status.filter(function (v) {
                    return v.finished === false && v.failed === false
                  })
                  if (stillInProgress.length === 0) {
                    const isFinished = response.status.every(function (v) {
                      return v.finished !== false || v.failed !== false
                    })
                    if (isFinished) {
                      const onlyFinished = response.status.filter(x => x.finished)
                      const onlyFailed = response.status.filter(x => x.failed)
                      clearInterval(recheckDocs)
                      if (onlyFailed.length > 0) {
                        this.allDocuments.forEach(element => {
                          if (element.internalId === item.internalId) {
                            element.loading = false
                          }
                        })
                        Vue.toasted.show(this.$t('message.savingErrors.renderingContractError') + onlyFailed.map(x => x.renderId).toString() + ' ' + onlyFailed.map(x => x.errorMessage).toString(), { type: 'error' })
                      }
                      if (onlyFinished.length > 0) {
                        ObjectApi.getRenderFile(renderId)
                          .then(element => {
                            // decode base64 string
                            const binary = window.atob(element.contract)
                            const len = binary.length
                            const buffer = new ArrayBuffer(len)
                            const view = new Uint8Array(buffer)
                            for (let i = 0; i < len; i++) {
                              view[i] = binary.charCodeAt(i)
                            }
                            let blob = ''
                            blob = new Blob([view], { type: 'application/pdf' })
                            const link = document.createElement('a')
                            link.href = window.URL.createObjectURL(blob)
                            this.allDocuments.forEach(element => {
                              if (element.internalId === item.internalId) {
                                element.url = link.href
                                element.loading = false
                                element.blob = blob
                              }
                            })
                            window.open(link.href, '_blank')
                          })
                      }
                    }
                  }
                })
            }, 3000)
          }
        })
      } else {
        MediaApi.getListMedia([contract.mediaId]).then(response => {
          for (const media of response.media) {
            this.allDocuments.forEach(element => {
              if (element.internalId === item.internalId) {
                element.url = media.url
                element.loading = false
                element.blob = ''
              }
            })
            window.open(media.url, '_blank')
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(.spinner-border) {
  width: 16px !important;
  height: 16px !important;
}
</style>
