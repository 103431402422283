<template>
  <section :class="[readOnly ? 'is-disabled' : '']">
    <div class="row">
      <div class="col-12 col-md-12">
        <coozzy-card class="global-card no-top-border">
          <building-object-list
            v-if="!onboardingLoading"
            ref="objectList"
            :element-selected="currentElement"
            :object-filters="objectFilters"
            @selected-object="selectedObject"
            @change-filter="changeFilter" />
        </coozzy-card>
      </div>
      <div
        v-if="item !== null"
        class="col-12 col-md-12">
        <coozzy-card class="global-card">
          <object-basic
            ref="object-basic"
            :key="`basic-${item.id}`"
            :object-selected="item"
            :show-delete-action="getFilteredTenancies(item.id).filter(tenancy => tenancy.activated === true).length === 0"
            :read-only="readOnly" />
        </coozzy-card>
        <coozzy-card
          v-if="!item.condominiumOwnership && !isJanitor"
          class="global-card">
          <object-pricing
            ref="object-pricing"
            :key="`pricing-${item.id}`"
            :object-selected="item"
            :read-only="readOnly"
            :expand-by-default="false" />
        </coozzy-card>
        <coozzy-card
          v-if="!item.condominiumOwnership && !isJanitor"
          class="global-card">
          <div
            id="Tenant"
            v-b-toggle.coozzy-card-tenancy
            class="collapse-title">
            <h5 class="cursor-pointer">
              {{ $t('message.onBoarding.buildings.objects.tenancies.title') }} ({{ getFilteredTenancies(objectId).length }})
            </h5>
          </div>
          <div class="col-12 p-0">
            <b-collapse
              id="coozzy-card-tenancy"
              :visible="source !== '' || openedCollapses.includes('coozzy-card-tenancy')">
              <div
                v-if="getFilteredTenancies(objectId).length === 0 && readOnly"
                class="row mt-3">
                <div class="col-12">
                  <h6>
                    {{ $t('message.nonExistent.tenancies') }}
                  </h6>
                </div>
              </div>
              <div
                v-for="(tenancy, tenancyIndex) in getFilteredTenancies(objectId)"
                :id="`tenancy-${tenancyIndex}`"
                :ref="`tenancy-${tenancyIndex}`"
                :key="`tenancy-${tenancyIndex}`"
                class="row">
                <!-- Display actual tenancies -->
                <object-tenancy
                  v-if="tenancy.vacancy === false"
                  ref="object-tenancy"
                  :key="`tenancy-` + tenancy.id ? tenancy.id : tenancy.internalId"
                  :index="tenancyIndex"
                  :object="item"
                  :tenancy="tenancy"
                  :read-only="readOnly"
                  :target="'objects'"
                  :is-first="tenancy.id && tenancyIndex === 0"
                  :expand-by-default="tenancyCollapseStatus(tenancy)"
                  @vacancy-updated-startingAt="updateEndingDateVacancy" />
                <!-- Display vacancies -->
                <object-vacancy
                  v-else
                  ref="object-vacancy"
                  :key="`vacancy -` + tenancy.id ? tenancy.id : tenancy.internalId"
                  :index="tenancy.id ? tenancy.id : tenancy.internalId"
                  :tenancy="tenancy"
                  :object-category="item.category"
                  :read-only="readOnly"
                  :target="'objects'"
                  :init-ending="initEnding"
                  :is-first="tenancy.id && tenancyIndex === 0"
                  :expand-by-default="tenancyCollapseStatus(tenancy)" />
              </div>
              <div class="row mt-3">
                <div class="col-2 mt-1">
                  <coozzy-button
                    class="w-100"
                    design="prop-green"
                    :disabled="item.inactiveBy || tenanciesHasInternalId > 0 || (getStatus(item) === 'current_terminated' && getFutureStatus(item) === 'reserved' && isAdminModule) || (getMarketingStatus(item) !== 'no' && (isAdminModule || isOwnerModule))"
                    @click="createTenancy(item.id)">
                    {{ $t('message.onBoarding.buildings.objects.tenancies.add') }}
                  </coozzy-button>
                </div>
                <div class="col-2 mt-1">
                  <coozzy-button
                    class="w-100"
                    design="prop-green"
                    :disabled="item.inactiveBy || tenanciesHasInternalId > 0"
                    @click="createVacancy(item.id)">
                    {{ $t('message.onBoarding.buildings.objects.tenancies.addVacancy') }}
                  </coozzy-button>
                </div>
              </div>
            </b-collapse>
          </div>
        </coozzy-card>
        <coozzy-card
          v-if="item.condominiumOwnership && !isJanitor"
          class="global-card">
          <div
            id="condominium"
            v-b-toggle.coozzy-card-condominium
            class="collapse-title">
            <h5 class="cursor-pointer">
              {{ $t('message.onBoarding.buildings.objects.ownerAgreement.title') }} ({{ getFilteredCondominium(objectId).length }})
            </h5>
          </div>
          <div class="col-12 p-0">
            <b-collapse
              id="coozzy-card-condominium"
              :visible="source !== '' || openedCollapses.includes('coozzy-card-condominium')">
              <div
                v-if="getFilteredCondominium(objectId).length === 0 && readOnly"
                class="row mt-3">
                <div class="col-12">
                  <h6>
                    {{ $t('message.nonExistent.condominium') }}
                  </h6>
                </div>
              </div>
              <div
                v-for="(condominium, condominiumIndex) in condominiums"
                :id="`condominium-${condominiumIndex}`"
                :ref="`condominium-${condominiumIndex}`"
                :key="`condominium-${condominiumIndex}`"
                class="row">
                <!-- Display actual condominium -->
                <object-condominium
                  ref="object-condominium"
                  :key="`condominium-` + condominium.id ? condominium.id : condominium.internalId"
                  :index="condominiumIndex"
                  :object-category="item.category"
                  :condominium="condominium"
                  :read-only="readOnly"
                  :target="'objects'"
                  :is-first="checkLastCondo(condominium)"
                  :expand-by-default="tenancyCollapseStatus(condominium)" />
              </div>
              <div class="row mt-3">
                <div class="col-2 mt-1">
                  <coozzy-button
                    class="w-100"
                    design="prop-green"
                    @click="createCondominiumOwnersAgreements(item.id)">
                    {{ $t('message.onBoarding.buildings.objects.ownerAgreement.add') }}
                  </coozzy-button>
                </div>
              </div>
            </b-collapse>
          </div>
        </coozzy-card>
        <coozzy-card
          v-if="!item.condominiumOwnership && !isJanitor"
          class="global-card">
          <object-advertising
            ref="object-advertising"
            :key="`advertising-${item.id}`"
            :object-selected="item"
            :read-only="readOnly"
            :expand-by-default="false" />
        </coozzy-card>
        <coozzy-card
          v-if="!item.condominiumOwnership && !isJanitor"
          class="global-card">
          <object-possible-deposits
            ref="object-possible-deposits"
            :key="`possible-deposits-${item.id}`"
            :item="item"
            :read-only="readOnly"
            :expand-by-default="false" />
        </coozzy-card>
        <coozzy-card class="global-card">
          <object-features
            ref="object-features"
            :key="`features-${item.id}`"
            :title="$t('message.onBoarding.buildings.objects.features.title.adminBase')"
            :object-selected="item"
            :read-only="readOnly"
            :expand-by-default="false" />
        </coozzy-card>
        <coozzy-card class="global-card">
          <div
            id="rooms"
            v-b-toggle.coozzy-card-rooms
            class="collapse-title">
            <h5 class="cursor-pointer">
              {{ $t('message.onBoarding.room.title') }} ({{ getFilteredObjectRooms(item.id).length }})
            </h5>
          </div>
          <div class="col-12 p-0">
            <b-collapse
              id="coozzy-card-rooms"
              :visible="openedCollapses.includes('coozzy-card-rooms') || expandObjectRoomId !== ''">
              <div
                v-if="getFilteredObjectRooms(item.id).length === 0 && readOnly"
                class="row mt-3">
                <div class="col-12">
                  <h6>
                    {{ $t('message.nonExistent.room') }}
                  </h6>
                </div>
              </div>
              <template>
                <div
                  v-for="(room, roomIndex) in getFilteredObjectRooms(item.id)"
                  :id="`room-${roomIndex}`"
                  :key="`room-${room.id || room.internalId}`"
                  class="row">
                  <room
                    :id="`room_${room.id || room.internalId}`"
                    ref="object-room"
                    :index="roomIndex"
                    :target="'object'"
                    :room="room"
                    :read-only="readOnly"
                    :add-new-document="room.id && roomIdNewDocument !== '' && roomIdNewDocument === room.id"
                    :expand-by-default="(('isNewItem' in room) && ((roomIndex + 1) === getFilteredObjectRooms(item.id).length)) || expandObjectRoomId === room.id" />
                </div>
              </template>
              <div class="row mt-3">
                <div class="col-2 mt-1">
                  <coozzy-button
                    class="w-100"
                    design="prop-green"
                    @click="scrollToRoom(item)">
                    {{ $t('message.onBoarding.room.add') }}
                  </coozzy-button>
                </div>
              </div>
            </b-collapse>
          </div>
        </coozzy-card>
        <coozzy-card class="global-card">
          <div
            id="devices"
            v-b-toggle.coozzy-card-devices
            class="collapse-title">
            <h5 class="cursor-pointer">
              {{ $t('message.onBoarding.devices.title') }} ({{ getFilteredObjectDevices(item.id).length }})
            </h5>
          </div>
          <div class="col-12 p-0">
            <b-collapse
              id="coozzy-card-devices"
              :visible="openedCollapses.includes('coozzy-card-devices')"
              @show="renderDevicesAnimation=true"
              @shown="renderDevices=true"
              @hidden="renderDevices = false">
              <div
                v-if="getFilteredObjectDevices(item.id).length === 0 && readOnly"
                class="row mt-3">
                <div class="col-12">
                  <h6>
                    {{ $t('message.nonExistent.device') }}
                  </h6>
                </div>
              </div>
              <template v-if="getFilteredObjectDevices(item.id).length > 0 && renderDevicesAnimation === true">
                <coozzy-spinner />
              </template>
              <template v-if="renderDevices">
                <div
                  v-for="(device, deviceIndex) in getFilteredObjectDevices(item.id)"
                  :id="`device-${deviceIndex}`"
                  :key="`device-${device.id || device.internalId}`"
                  class="row">
                  <device
                    ref="object-device"
                    :index="deviceIndex"
                    :target="'object'"
                    :device="device"
                    :read-only="readOnly"
                    :expand-by-default="(('isNewItem' in device) && ((deviceIndex + 1) === getFilteredObjectDevices(item.id).length))"
                    :add-new-document="device.id && deviceIdNewDocument !== '' && deviceIdNewDocument === device.id"
                    :owner-id="building?.ownerId" />
                </div>
              </template>
              <div class="row mt-3">
                <div class="col-2 mt-1">
                  <coozzy-button
                    class="w-100"
                    design="prop-green"
                    @click="scrollToDevice(item)">
                    {{ $t('message.onBoarding.devices.add') }}
                  </coozzy-button>
                </div>
              </div>
            </b-collapse>
          </div>
        </coozzy-card>
        <coozzy-card class="global-card">
          <div
            v-b-toggle.collapse-energySource
            class="collapse-title">
            <h5 class="cursor-pointer">
              {{ $t('message.onBoarding.createBuilding.energySource') }} ({{ energySources.length }})
            </h5>
          </div>
          <div
            class="col-12 p-0"
            :class="readOnly && energySources.length > 0? 'px-0': ''">
            <b-collapse
              id="collapse-energySource"
              class="row"
              :visible="openedCollapses.includes('collapse-energySource')">
              <div class="col">
                <div
                  v-if="energySources.length === 0 && readOnly"
                  class="row mt-3">
                  <div class="col-12">
                    <h6>
                      {{ $t('message.nonExistent.energySource') }}
                    </h6>
                  </div>
                </div>
                <div
                  v-for="(energySource, index) in energySources"
                  :id="`energySource-${ index }`"
                  :key="`energySource-${ index }`"
                  class="row">
                  <energy-source
                    ref="energy-source"
                    :key="`energySource-object-${index}`"
                    :read-only="readOnly"
                    :index="index"
                    :energy-source="energySource"
                    :target="'object'"
                    :expand-by-default="('isNewItem' in energySource) && ((index + 1) === energySources.length)"
                    @energy-source-document-deleted="energySourceDocumentDeleted" />
                </div>
                <div class="row mt-3">
                  <div class="col-2 mt-1">
                    <coozzy-button
                      class="w-100"
                      design="prop-green"
                      @click="addEnergySource">
                      {{ $t('message.onBoarding.buildings.energySource.add') }}
                    </coozzy-button>
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>
        </coozzy-card>
        <coozzy-card
          v-if="item && item.administrationType === 'CONDOMINIUM_OWNERSHIP' && !isJanitor"
          class="global-card">
          <div
            v-b-toggle.collapse-mortgages
            class="collapse-title">
            <h5 class="cursor-pointer">
              {{ $t('message.onBoarding.createBuilding.mortgage') }} ({{ currentObjectMortgages.length }})
            </h5>
          </div>
          <div class="col-12 p-0">
            <b-collapse
              id="collapse-mortgages"
              :visible="openedCollapses.includes('collapse-mortgages')">
              <div class="row" />
              <div
                v-if="currentObjectMortgages.length === 0 && readOnly"
                class="row mt-3">
                <div class="col-12">
                  <h6>
                    {{ $t('message.nonExistent.mortgage') }}
                  </h6>
                </div>
              </div>
              <div
                v-for="(obj, index) in currentObjectMortgages"
                :key="`objectMortgages-${obj.id || obj.internalId}`"
                class="row">
                <building-mortgage
                  ref="building-mortgage"
                  :key="`mortgage-${obj.id || obj.internalId}`"
                  :index="index"
                  :read-only="readOnly"
                  :mortgage="obj"
                  :target="'object'"
                  :expand-by-default="('isNewItem' in obj) && ((index + 1) === currentObjectMortgages.length)" />
              </div>
              <div class="row mt-3">
                <div class="col-2 mt-1">
                  <coozzy-button
                    class="w-100"
                    design="prop-green"
                    @click="addNewMortgageBtn">
                    {{ $t('message.onBoarding.mortgage.newMortgage') }}
                  </coozzy-button>
                </div>
              </div>
            </b-collapse>
          </div>
        </coozzy-card>
        <!-- <coozzy-card
          v-if="!item.condominiumOwnership"
          class="global-card">
          <div
            v-b-toggle.collapse-renovation
            class="collapse-title">
            <h5 class="cursor-pointer">
              {{ $t('message.onBoarding.buildings.objects.renovation.title') }} ({{ renovations.length }})
            </h5>
          </div>
          <div
            class="col-12 p-0"
            :class="readOnly && renovations.length > 0? 'px-0': ''">
            <b-collapse
              id="collapse-renovation"
              ref="collapse-renovation"
              class="row"
              :visible="openedCollapses.includes('collapse-renovation')">
              <div class="col">
                <div
                  v-if="renovations.length === 0 && readOnly"
                  class="row mt-3">
                  <div class="col-12">
                    <h6>
                      {{ $t('message.nonExistent.renovation') }}
                    </h6>
                  </div>
                </div>
                <div
                  v-for="(elementRen, index) in renovations"
                  :key="`renovation-${index}`"
                  class="row details-row-bg-1 py-01rem mt-3">
                  <div class="col-sm-12 col-md-9  mt-1">
                    <object-renovation
                      ref="renovation"
                      :key="`renovation-${index}-${randomId()}`"
                      :renovation="elementRen"
                      :index="index"
                      :read-only="readOnly"
                      @change-renovation="isChanged" />
                  </div>
                  <div class="col-sm-12 col-md-3 px-1 mt-1">
                    <coozzy-button
                      size="small"
                      design="red"
                      class="float-left mt-4"
                      @click="confirmationRenovation(index)">
                      {{ $t('message.generic.delete') }}
                    </coozzy-button>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-2 mt-1">
                    <coozzy-button
                      class="w-100"
                      design="prop-green"
                      @click="addNewRenovation">
                      {{ $t('message.onBoarding.buildings.objects.renovation.add') }}
                    </coozzy-button>
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>
        </coozzy-card> -->
        <coozzy-card class="global-card">
          <div
            v-b-toggle.coozzy-card-documents
            class="collapse-title">
            <h5 class="cursor-pointer">
              {{ $t('message.onBoarding.createObjectView.generalObjectDocuments') }} ({{ filteredDocuments.length }})
            </h5>
          </div>
          <div class="col-12 p-0">
            <b-collapse
              id="coozzy-card-documents"
              :visible="openedCollapses.includes('coozzy-card-documents')">
              <span
                v-if="filteredDocuments.length > 0"
                class="link downloadAllDocuments"
                @click="downloadAllDocuments($event)">{{ $t('message.marketingMessages.mail.downloadAll') }} <coozzy-file-download-icon /></span>
              <div
                class="row mt-2">
                <div class="col-12">
                  <documents-list
                    :documents-list="filteredDocuments"
                    :is-read-only="readOnly"
                    @version-added="versionAdded"
                    @document-deleted="deleteDocument" />
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-12 mt-1 pr-0">
                  <add-document-modal
                    :suffix="'generalObjectDocuments'"
                    :owner-id="building.ownerId"
                    @document-created="documentCreated" />
                </div>
                <div class="col-2 mt-1">
                  <coozzy-button
                    class="w-100 d-block"
                    design="prop-green"
                    @click="readOnly ? goToEditMode() : addDocumentClicked('generalObjectDocuments')">
                    {{ $t('message.manageDocuments.addDocument') }}
                  </coozzy-button>
                </div>
              </div>
            </b-collapse>
          </div>
        </coozzy-card>
        <coozzy-card
          v-if="!isJanitor"
          class="global-card">
          <object-assignees
            :object-id="item.id"
            :assignees="item.contacts" />
        </coozzy-card>
        <b-modal
          :id="'confirmationModal'"
          ref="confirmationModal"
          no-close-on-backdrop
          :title="$t('message.onBoarding.buildings.objects.renovation.deleteConfirmation.title')"
          hide-footer>
          <div class="col">
            <p>{{ $t('message.onBoarding.buildings.objects.renovation.deleteConfirmation.text') }}</p>
          </div>
          <div class="col">
            <coozzy-button
              size="small"
              class="float-left mb-0 border"
              design="transparent"
              @click="$bvModal.hide('confirmationModal')">
              {{ $t('message.generic.cancel') }}
            </coozzy-button>
            <coozzy-button
              design="green"
              class="float-right ml-3"
              @click="removeRenovation()">
              {{ $t('message.generic.delete') }}
            </coozzy-button>
          </div>
        </b-modal>
      </div>
    </div>
  </section>
</template>

<script>

import BuildingObjectList from './components/BuildingObjectList'
import ObjectTenancy from './components/ObjectTenancy'
import ObjectVacancy from '@/building/tabs/components/ObjectVacancy'
import ObjectCondominium from './components/ObjectCondominium'
import ObjectFeatures from './components/ObjectFeatures'
import ObjectPossibleDeposits from './components/ObjectPossibleDeposits'
import ObjectAdvertising from './components/ObjectAdvertising'
// import ObjectRenovation from './components/ObjectRenovation'
import BuildingMortgage from './components/BuildingMortgage'
import Device from './components/Device'
import Room from './components/Room'
import AddDocumentModal from '../../properties/components/AddDocumentModal'
import CoozzyCard from '../../framework/components/card/CoozzyCard'
import CoozzyButton from '../../framework/components/button/CoozzyButton'
import { onboarding } from '@/mixins/onboarding'
import { toastError } from '@/mixins/toastError'
import Vue from 'vue'
import ObjectPricing from './components/ObjectPricing'
import ObjectBasic from './components/ObjectBasic'
import EnergySource from './components/EnergySource'
// import ObjectEnergySource from './components/ObjectEnergySource'
import { mapState } from 'vuex'
import ObjectAssignees from '@/building/tabs/components/ObjectAssignees'
import { validation } from '@/mixins/validation'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner'
import { obj } from '@/mixins/object'
import { routeChecks } from '@/mixins/routeChecks'
import { dateUtils } from '@/mixins/dateUtils'
import { user } from '@/mixins/user'
import DocumentsList from '@/properties/components/DocumentsList.vue'
import CoozzyFileDownloadIcon from '@/framework/components/icons/CoozzyFileDownloadIcon.vue'
import { media } from '@/mixins/media'

export default {
  name: 'CreateObjectsTab',
  components: {
    CoozzyFileDownloadIcon,
    DocumentsList,
    CoozzySpinner,
    ObjectAssignees,
    ObjectCondominium,
    ObjectPricing,
    CoozzyButton,
    CoozzyCard,
    BuildingObjectList,
    ObjectBasic,
    ObjectTenancy,
    ObjectVacancy,
    ObjectAdvertising,
    ObjectFeatures,
    ObjectPossibleDeposits,
    // ObjectRenovation,
    Device,
    Room,
    EnergySource,
    AddDocumentModal,
    BuildingMortgage
  },
  mixins: [onboarding, toastError, validation, obj, routeChecks, dateUtils, user, media],
  props: {
    elementSelected: {
      type: String,
      default: ''
    },
    objectFilters: {
      type: String,
      default: ''
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    source: {
      type: String,
      default: ''
    },
    expandObjectRoomId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      tempDocuments: [],
      initEnding: null,
      renderDevices: false,
      renderDevicesAnimation: true,
      documentName: '',
      documentType: '',
      item: null,
      dates: {
        firstTimeOccupancy: null
      },
      selectedIndex: -1,
      indexRenovation: null,
      indexEnergySource: null,
      objectToDuplicate: null,
      duplicateCount: 0,
      availableTypes: [
        // Changes need to be implemented also in mobile apps
        'OTHER',
        'APPLICATION_FORM',
        'HANDOVER_PROTOCOL',
        'CONTRACT',
        'BUILDING_PLAN',
        'INVOICE',
        'HOUSE_REGULATION',
        'INSTRUCTION_MANUAL',
        'CREDIT_CHECK',
        'OWNERS_MEETING',
        'PAY_IN_SLIP',
        'ENERGY_CERTIFICATE',
        'WARRANTY_CERTIFICATE',
        'BANK_STATEMENT',
        'ADDITIONAL_COSTS_BILL',
        'SEPA_DIRECT_DEBIT_MANDATE',
        'AGREEMENT',
        'DEPOSIT_CONFIRMATION',
        'BUILDING_INSURANCE_POLICY',
        'KEY_LIST',
        'TERMINATION_DOC_TYPE',
        'RENT_ADJUSTMENT_DOC_TYPE',
        'REFERENCES_DOC_TYPE',
        'MAINTENANCE_CONTRACT_DOC_TYPE',
        'REGULATIONS',
        'REGULATIONS_FOR_USE_AND_MANAGEMENT',
        'ANNUAL_FINANCIAL_STATEMENT',
        'PURCHASE_CONTRACT',
        'LAND_REGISTER',
        'CONDOMINIUM_OWNERSHIP',
        'RENEWAL_FOND',
        'MORTGAGE',
        'ORDER',
        'OFFER',
        'IDENTITY',
        'EXTRACT_FROM_DEBT_COLLECTION_REGISTER',
        'BANK_ACCOUNT_DETAILS',
        'SITUATION_PLAN',
        'CONSTRUCTION_LAW_AGREEMENT',
        'EASEMENT_AGREEMENT',
        'CADASTRAL_ESTIMATE',
        'LETTER',
        'TRADE_REGISTER_EXCERPT',
        'PAYMENT_REMINDER',
        'PROTOCOL',
        'COURT_DOC_TYPE',
        'HONORARIUM',
        'BUDGET',
        'OFFICIAL_ESTIMATE'
      ],
      deviceIdNewDocument: '',
      roomIdNewDocument: ''
    }
  },
  computed: {
    ...mapState('onboarding', ['errors']),
    filteredDocuments() {
      return this.documents.filter(doc => this.item.documents.some(d => d.documentId === doc.id))
    },
    currentObjectMortgages() {
      return this.objectMortgages.filter(x => x.referenceId === this.objectId)
    },
    condominiums() {
      return this.getFilteredCondominium(this.objectId).sort((a, b) => !a.internalId && new Date(this.$options.filters.objectToDateInput(a.startingAt)).getTime() / 1000 > new Date(this.$options.filters.objectToDateInput(b.startingAt)).getTime() / 1000 ? -1 : 1)
    },
    objectId() {
      return this.item ? this.item.id : ''
    },
    currentElement() {
      return this.$route.query?.elementSelected !== '' ? this.$route.query.elementSelected : this.elementSelected
    },
    translatedAvailableTypes() {
      const array = this.availableTypes
      array.sort((a, b) => {
        if (this.$t(`message.manageDocuments.type.${a.toLowerCase()}`) < this.$t(`message.manageDocuments.type.${b.toLowerCase()}`)) {
          return -1
        }
        if (this.$t(`message.manageDocuments.type.${a.toLowerCase()}`) > this.$t(`message.manageDocuments.type.${b.toLowerCase()}`)) {
          return 1
        }
        return 0
      })
      return array
    },
    documents() {
      return this.getFilteredObjectDocuments(this.item.documents.filter(doc => (doc.isFloorPlan === false || doc.isFloorPlan === null) && doc.isPublic === false).map(d => d.documentId), this.documentType, this.documentName).concat(this.tempDocuments).sort((a, b) => (a.name > b.name ? 1 : -1))
    },
    renovations: function () {
      return this.item.renovations
    },
    energySources: function () {
      return this.objectEnergySources
    },
    tenanciesHasInternalId: function () {
      return this.tenancies.filter(x => x.internalId).length
    }
  },
  watch: {
    openedCollapses: function (newVal) {
      this.renderDevices = newVal.includes('coozzy-card-devices')
    },
    renderDevices: function (newVal) {
      if (newVal === true) {
        this.$nextTick(function () {
          this.renderDevicesAnimation = false
        })
      }
    },
    tenancies: function () {
      if (this.item) {
        if (typeof (this.item.tenancies) === 'undefined') {
          this.item.tenancies = []
        }
        this.item.tenancies.push({ internalId: Date.now() })
      }
    },
    errors: function (newVal) {
      if (newVal.length > 0 && this.errors[0].entityType === 'tenancy') {
        this.$nextTick(() => {
          this.$root.$emit('bv::toggle::collapse', 'coozzy-card-tenancy')
          this.$root.$emit('bv::toggle::collapse', 'collapse-tenancy-' + this.errors[0].id)
        })
      }
    }
  },
  mounted() {
    if (this.currentElement) {
      const checker1 = this.objects.find(obj => obj.id === this.currentElement)
      const checker2 = this.objects.findIndex(obj => obj.id === this.currentElement)
      if (checker1 !== undefined && checker1 !== null && checker2 !== undefined && checker2 !== null) {
        this.selectedObject(checker1, checker2)
      }
    }
    if (this.item && this.item.characteristics) {
      this.dates.firstTimeOccupancy =
        this.$options.filters.timestampToDateInput(this.item.characteristics.firstTimeOccupancy)
    }
    this.renderDevices = this.openedCollapses.includes('coozzy-card-devices')
    if (this.expandObjectRoomId !== '' || this.roomIdNewDocument !== '') {
      const roomId = this.expandObjectRoomId !== '' ? this.expandObjectRoomId : this.roomIdNewDocument
      setTimeout(() => {
        const element = document.getElementById('room_' + roomId)
        if (element) {
          element.scrollIntoView(true)
          window.scrollBy(0, -50)
        }
      }, 1200)
    }
    this.$nextTick(() => {
      if (localStorage.getItem('deviceIdNewDocument')) {
        this.deviceIdNewDocument = localStorage.getItem('deviceIdNewDocument')
      }
      if (localStorage.getItem('roomIdNewDocument')) {
        this.roomIdNewDocument = localStorage.getItem('roomIdNewDocument')
      }
      if (localStorage.getItem('addGeneralObjectDocument') === 'true') {
        this.addDocumentClicked('generalObjectDocuments')
      }
    })
  },
  methods: {
    downloadAllDocuments(e) {
      e.preventDefault()
      const idsAndNames = this.documents.map(x => ({ id: x.versions[x.versions.length - 1].mediaId, mediumName: x.name }))
      const nameOfMergedFile = this.$tc('message.onBoarding.createObjectView.generalObjectDocuments') + ' - ' + this.item.name
      this.mergeDownloadAllDocuments(idsAndNames, nameOfMergedFile)
    },
    goToEditMode() {
      let routeLink = null
      localStorage.setItem('addGeneralObjectDocument', 'true')
          routeLink = {
            name: this.moduleRoutePrefix + 'OnboardingView',
            params: { id: this.$route.params.id },
            query: {
              view: 'objects',
              elementSelected: this.$route.query.elementSelected
            }
          }
      this.$router.push(routeLink)
    },
    checkLastCondo(condo) {
      let internalId = null
      if ('internalId' in condo) {
        internalId = condo.internalId
      }
      const items = this.condominiums.filter(x => x && 'internalId' in x && x.internalId)
      const list = items.map(y => y.internalId)
      if (list.length > 0 && 'id' in condo && 'id' in this.condominiums[0] && condo.id === this.condominiums[0].id) {
        if (items.find(x => x.endingAt && new Date(this.$options.filters.objectToDateInput(condo.startingAt)) > new Date(this.$options.filters.objectToDateInput(x.endingAt)))) {
          return false
        }
        return true
      }
      if (internalId && list.length === 1 && list[0] === internalId) {
        return false
      } else if (internalId && list.length > 1) {
        const index = list.indexOf(internalId)
        if (index === list.length - 1) {
          return false
        } else {
          return true
        }
      }
    },
    addNewMortgageBtn() {
      this.addNewMortgage(this.item.id)
    },
    addEnergySource() {
      this.addNewEnergySource(this.item.id)
    },
    addDocumentClicked(suffix = '') {
      localStorage.removeItem('addGeneralObjectDocument')
      this.$bvModal.show('add-document-modal' + suffix)
    },
    energySourceDocumentDeleted() {
      const object = this.item
      this.$store.dispatch('onboarding/updateObject', object)
    },
    addNewRenovation() {
      this.item.renovations.push({ start: '-1', end: '-1', text: '' })
      const object = this.item
      this.$store.dispatch('onboarding/updateObject', object)
    },
    documentCreated(document, suffix) {
      const object = this.item
      if (suffix === 'floorplan') {
        object.documents.push({ documentId: document.id, isFloorPlan: true, isPublic: true })
      } else if (suffix === 'publicDocument') {
        object.documents.push({ documentId: document.id, isFloorPlan: false, isPublic: true })
      } else {
        object.documents.push({ documentId: document.id })
      }
      // Update object in vuex store with new documentId
      this.$store.dispatch('onboarding/updateObject', object)

      // Add whole new document to vuex store to display it
      this.objectDocuments.push(document)
      // Add new document TO AN ARRAY BEFORE saving
      this.tempDocuments.push(document)
    },
    deleteDocument(document) {
      const documentExists = this.item.documents.some(doc => doc.documentId === document.id)

      if (!documentExists) {
        return
      }

      const newItem = {
        ...this.item,
        documents: this.item.documents.filter(doc => doc.documentId !== document.id)
      }

      this.item = newItem
      this.$store.dispatch('onboarding/updateObject', newItem)

      const docIndex = this.objectDocuments.findIndex(d => d.id === document.id)
      if (docIndex > -1) {
        this.objectDocuments.splice(docIndex, 1)
      }
      const tempIndex = this.tempDocuments.findIndex(d => d.id === document.id)
      if (tempIndex > -1) {
        this.tempDocuments.splice(tempIndex, 1)
      }
      this.activateChanged()
    },
    tenancyCollapseStatus(tenancy) {
      return ('isNewItem' in tenancy && tenancy.isNewItem === true) || tenancy.isLatest
    },
    isChanged() {
      const object = this.item
      this.$store.dispatch('onboarding/updateObject', object)
    },
    changeEnergySource() {
      const object = this.item
      this.$store.dispatch('onboarding/updateObject', object)
    },
    isSameDate(date1, date2) {
      if (date1 === null || date2 === null) {
        if (!date1 && !date2) {
          return true
        } else {
          return false
        }
      } else {
        if (date1.day === date2.day && date1.month === date2.month && date1.year === date2.year) {
          return true
        } else {
          return false
        }
      }
    },
    isInvalid() {
      let valid = true
      const errorArray = []
      if (this.item !== null && !this.readOnly) {
        if (!this.readOnly && this.$refs['object-basic'].isChanged && this.$refs['object-basic'].isInvalid()) {
          if (!this.$refs['object-basic'].isCollapseOpen()) {
            if (this.$refs['object-basic'].inactive && this.$refs['object-basic'].$v.inactiveBy.$dirty && this.$refs['object-basic'].$v.inactiveBy.$error) {
              errorArray.push(this.$t('message.savingErrors.objectInactive'))
            } else {
              errorArray.push(this.$t('message.savingErrors.mandatoryMissing'))
            }
          }
          console.log('Object basics invalid')
          valid = false
        }
        if (!this.item.condominiumOwnership && this.$refs['object-tenancy']) {
          const afterwardsTenanciesErrorArray = []
          const sameDateTenanciesErrorArray = []
          this.$refs['object-tenancy'].forEach(element => {
            if (element.tenancy.endingAt === null) {
              const startingAt = new Date(this.$options.filters.objectToDateInput(element.tenancy.startingAt))
              const afterwardsTenancies = this.tenancies.filter(c => c.objectId === element.tenancy.objectId && ((startingAt < new Date(this.$options.filters.objectToDateInput(c.startingAt)) && !element.tenancy.isNewItem) || (startingAt < new Date(this.$options.filters.objectToDateInput(c.startingAt)) && element.tenancy.isNewItem)))
              const sameDateOfTenancies = this.tenancies.filter(c => element.tenancy.id !== c.id && c.objectId === element.tenancy.objectId && (c.vacancy === element.vacancy) && (startingAt.getTime() === new Date(this.$options.filters.objectToDateInput(c.startingAt)).getTime()))
              const sameDateTenancies = this.tenancies.filter(c => element.tenancy.id !== c.id && c.objectId === element.tenancy.objectId && ((this.isSameDate(element.tenancy.startingAt, c.startingAt) && !element.tenancy.isNewItem) || (this.isSameDate(element.tenancy.startingAt, c.startingAt) && element.tenancy.isNewItem)) && ((c.activated && new Date(this.$options.filters.objectToDateInput(this.item.debitUntil)) > startingAt)))
              if (sameDateTenancies.length > 0) {
                sameDateTenanciesErrorArray.push(this.$t('message.savingErrors.sameDateTenanciesError'))
                valid = false
                Vue.toasted.show(this.$t('message.savingErrors.sameDateTenanciesError'), { type: 'error' })
              }
              if (sameDateOfTenancies.length > 0) {
                sameDateTenanciesErrorArray.push(this.$t('message.savingErrors.sameDateOfTenanciesError'))
                valid = false
              }
              if (afterwardsTenancies.length > 0) {
                afterwardsTenanciesErrorArray.push(this.$t('message.savingErrors.tenancyEndAtCanNotBeDeleted'))
                valid = false
              }
            }
            if (element.isInvalid()) {
              if (!element.isCollapseOpen()) {
                errorArray.push(this.$t('message.savingErrors.validationError') + this.$t('message.onBoarding.buildings.objects.tenancies.tenancy') + this.$t('message.savingErrors.validationError2'))
              }
              console.log('Object tenancy invalid')
              valid = false
            }
          })
          if (sameDateTenanciesErrorArray.concat(afterwardsTenanciesErrorArray).length > 0) {
            this.showManyToast(sameDateTenanciesErrorArray.concat(afterwardsTenanciesErrorArray))
          }
        }
        if (!this.item.condominiumOwnership && this.$refs['object-vacancy']) {
          const afterwardsTenanciesErrorArray = []
          const sameDateTenanciesErrorArray = []
          this.$refs['object-vacancy'].forEach(element => {
            // show error if there is a vacancy with startingAt date before tenancy endingAt date
            const startingAt = new Date(this.$options.filters.objectToDateInput(element.tenancy.startingAt))
            const vacancyBeforeEndingAt = []
            this.tenancies.forEach(c => {
              if (c.id !== element.tenancy.id && c.objectId === element.tenancy.objectId && element.internalId) {
                if (startingAt.getTime() <= new Date(this.$options.filters.objectToDateInput(c.endingAt)).getTime()) {
                  vacancyBeforeEndingAt.push(c)
                }
              }
            })
            if (vacancyBeforeEndingAt.length > 0) {
              afterwardsTenanciesErrorArray.push(this.$t('message.addVacancy.startsBeforePrevious'))
              valid = false
            }
            if (element.tenancy.endingAt === null) {
              const startingAt = new Date(this.$options.filters.objectToDateInput(element.tenancy.startingAt))
              const afterwardsTenancies = this.tenancies.filter(c => c.objectId === element.tenancy.objectId && (startingAt < new Date(this.$options.filters.objectToDateInput(c.startingAt))))
              const sameDateOfTenancies = this.tenancies.filter(c => element.tenancy.id !== c.id && c.objectId === element.tenancy.objectId && (c.vacancy === element.vacancy) && (startingAt.getTime() === new Date(this.$options.filters.objectToDateInput(c.startingAt)).getTime()))
              const sameDateTenancies = this.tenancies.filter(c => element.tenancy.id !== c.id && c.objectId === element.tenancy.objectId && !element.tenancy.internalId && (this.isSameDate(element.tenancy.startingAt, c.startingAt)) && (element.activated && (new Date(this.$options.filters.objectToDateInput(this.item.debitUntil)) > new Date(this.$options.filters.objectToDateInput(c.startingAt)))))
              if (sameDateTenancies.length > 0) {
                sameDateTenanciesErrorArray.push(this.$t('message.savingErrors.sameDateTenanciesError'))
                valid = false
              }
              if (sameDateOfTenancies.length > 0) {
                sameDateTenanciesErrorArray.push(this.$t('message.savingErrors.sameDateOfTenanciesError'))
                valid = false
              }
              if (afterwardsTenancies.length && !element.tenancy.internalId) {
                afterwardsTenanciesErrorArray.push(this.$t('message.savingErrors.vacancyEndAtCanNotBeDeleted'))
                valid = false
              }
            }
            if (element.isInvalid()) {
              console.log('Object vacancy invalid')
              valid = false
            }
          })
          if (sameDateTenanciesErrorArray.concat(afterwardsTenanciesErrorArray).length > 0) {
            this.showManyToast(sameDateTenanciesErrorArray.concat(afterwardsTenanciesErrorArray))
          }
        }
        if (this.item.condominiumOwnership && this.$refs['object-condominium']) {
          const afterwardsTenanciesErrorArray = []
          this.$refs['object-condominium'].forEach(element => {
            if (element.condominium.endingAt === null) {
              const startingAt = new Date(this.$options.filters.objectToDateInput(element.condominium.startingAt))
              const afterwardsCondominium = []
              let isChecked = false
              const t = this
              this.condominiumOwnership.filter(x => x.objectId === element.condominium.objectId).sort(function (a, b) {
                return new Date(t.$options.filters.objectToDateInput(b.startingAt)).getTime() - new Date(t.$options.filters.objectToDateInput(a.startingAt)).getTime()
              }).forEach(co => {
                if (co.objectId === element.condominium.objectId && JSON.stringify(co) !== JSON.stringify(element.condominium)) {
                  const endingDate = new Date(this.$options.filters.objectToDateInput(co.endingAt))
                  const endPlusDay = endingDate.getTime() + 86400000
                  if (startingAt <= new Date(this.$options.filters.objectToDateInput(co.endingAt))) {
                    afterwardsCondominium.push(co)
                  } else if (co.endingAt !== null && startingAt.getTime() > endPlusDay && !isChecked) {
                    afterwardsCondominium.push(co)
                  }
                  isChecked = true
                }
              })
              if (afterwardsCondominium.length > 0) {
                afterwardsTenanciesErrorArray.push(this.$t('message.savingErrors.condominiumGap'))
                valid = false
              }
            }
            if (element.isInvalid()) {
              if (!element.isCollapseOpen()) {
                errorArray.push(this.$t('message.savingErrors.validationError') + this.$t('message.onBoarding.buildings.objects.ownerAgreement.title') + this.$t('message.savingErrors.validationError2'))
              }
              console.log('Object ownerAgreement invalid')
              valid = false
            }
          })
          if (afterwardsTenanciesErrorArray.length > 0) {
            this.showManyToast(afterwardsTenanciesErrorArray)
          }
        }
        const tenancies = this.getFilteredTenancies(this.currentElement).filter(x => x.vacancy === false).map(x => ({ start: x.startingAt, end: x.endingAt }))
        const isOverlap = this.overlap(tenancies)
        if (isOverlap.overlap) {
          errorArray.push(this.$t('message.savingErrors.tenancyStartingOverLap'))
          console.log('Tenancies overlap')
          valid = false
        }
        const allTenancies = this.getFilteredTenancies(this.currentElement).sort((a, b) => new Date(this.$options.filters.objectToDateInput(a.startingAt)).getTime() / 1000 > new Date(this.$options.filters.objectToDateInput(b.startingAt)).getTime() / 1000 ? -1 : 1)
        for (let index = 0; index < allTenancies.length; index++) {
          const element = allTenancies[index]
          if (element.vacancy === true) {
            if ((allTenancies[index + 1] && allTenancies[index + 1].vacancy === true && allTenancies[index + 1].vacancyReason === element.vacancyReason)) {
              errorArray.push(this.$t('message.savingErrors.reasonVacancyOnTwoVacancies'))
              console.log('Reason for vacancy cannot be the same on two vacancies in a row.')
              valid = false
            }
          }
        }
        if (!this.item.condominiumOwnership && this.$refs['object-advertising'] && this.$refs['object-advertising'].isInvalid()) {
          if (!this.$refs['object-advertising'].isCollapseOpen()) {
            errorArray.push(this.$t('message.savingErrors.validationError') + this.$t('message.onBoarding.createObjectView.advertising') + this.$t('message.savingErrors.validationError2'))
          }
          console.log('Object advertising invalid')
          valid = false
        }
        if (this.$refs['object-features'].isInvalid()) {
          if (!this.$refs['object-features'].isCollapseOpen()) {
            errorArray.push(this.$t('message.savingErrors.validationError') + this.$t('message.onBoarding.buildings.objects.features.title.adminBase') + this.$t('message.savingErrors.validationError2'))
          }
          console.log('Object features invalid')
          valid = false
        }
        if (this.$refs['building-mortgage']) {
          this.$refs['building-mortgage'].forEach(element => {
            if (element.isInvalid()) {
              if (!element.isCollapseOpen()) {
                errorArray.push(this.$t('message.savingErrors.validationError') + this.$t('message.onBoarding.createBuilding.mortgage') + this.$t('message.savingErrors.validationError2'))
              }
              console.log('Object mortgage invalid')
              valid = false
            }
          })
        }
        // if (this.$refs.renovation) {
        //   this.$refs.renovation.forEach(element => {
        //     if (element.isInvalid()) {
        //       if (this.$refs['collapse-renovation'].visible) {
        //         errorArray.push(this.$t('message.savingErrors.validationError') + this.$t('message.onBoarding.buildings.objects.renovation.title') + this.$t('message.savingErrors.validationError2'))
        //       }
        //       console.log('Object renovation invalid')
        //       valid = false
        //     }
        //   })
        // }
        if (this.$refs.energySources) {
          this.$refs.energySources.forEach(element => {
            if (element.isInvalid()) {
              if (!element.isCollapseOpen()) {
                errorArray.push(this.$t('message.savingErrors.validationError') + this.$t('message.onBoarding.createBuilding.energySource') + this.$t('message.savingErrors.validationError2'))
              }
              console.log('Object energy sources invalid')
              valid = false
            }
          })
        }
        if (this.$refs['object-device']) {
          this.$refs['object-device'].forEach(element => {
            if (element.isInvalid()) {
              if (!element.isCollapseOpen()) {
                errorArray.push(this.$t('message.savingErrors.validationError') + this.$t('message.onBoarding.devices.title') + this.$t('message.savingErrors.validationError2'))
              }
              console.log('Object device invalid')
              valid = false
            }
          })
        }
        if (errorArray.length > 0) {
          this.showManyToast(errorArray)
        }
      }
      return !valid
    },
    openModalDelete() {
      this.$refs.deleteModal.show()
    },
    randomId() {
      return Math.random().toString(36).substr(2, 9)
    },
    async removeConfirmed() {
      this.removeObjects([this.item])
        .then(() => {
          this.item = null
          this.$refs.deleteModal.hide()
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.deleteErrors.object'), { type: 'error' })
        })
    },
    removeDevice(index) {
      this.$delete(this.item.devices, index)
    },
    // confirmationRenovation(index) {
    //   this.indexRenovation = index
    //   this.$refs.confirmationModal.show()
    // },
    // removeRenovation() {
    //   this.item.renovations.splice(this.indexRenovation, 1)
    //   const object = this.item
    //   this.$store.dispatch('onboarding/updateObject', object)
    //   this.$refs.confirmationModal.hide()
    //   this.indexRenovation = null
    // },
    removeEnergySource() {
      this.item.energySources.splice(this.indexEnergySource, 1)
      const object = this.item
      this.$store.dispatch('onboarding/updateObject', object)
      this.$refs.confirmationModal.hide()
      this.indexEnergySource = null
    },
    searchDocumentName() {

    },
    selectedObject(object, index) {
      if (!this.isInvalid()) {
        this.item = object
        this.selectedIndex = index
        this.$emit('selected-object', object)
      }
    },
    changeFilter(newVal, field) {
      this.$emit('change-filter', newVal, field)
    },
    updateDevice(item) {
      this.item.devices[item.index] = item.device
    },
    versionAdded(updatedDocument) {
      this.objectDocuments.forEach((doc, index) => {
        if (doc.id === updatedDocument.id) {
          this.objectDocuments.splice(index, 1, updatedDocument)
        }
      }, this)
    },
    createCondominiumOwnersAgreements(id) {
      this.addNewCondominiumOwnership(id)
      this.setDefaultValuesFromPreviousOwnerAgreement(id)
      this.$nextTick(() => {
        const indexTenant = this.getFilteredCondominium(this.objectId).length - 1
        const domRect = this.$el.querySelector('#condominium-' + indexTenant)
        if (domRect) {
          domRect.scrollIntoView(true)
        }
      })
    },
    createTenancy(id) {
      this.addNewTenancy(id)
      this.setDefaultValuesFromPreviousTenancy(id)
      this.$nextTick(() => {
        const indexTenant = this.getFilteredTenancies(this.objectId).length - 1
        const domRect = this.$el.querySelector('#tenancy-' + indexTenant)
        if (domRect) {
          domRect.scrollIntoView(true)
        }
      })
    },
    createVacancy(id) {
      this.addNewVacancy(id)
      this.setDefaultValuesFromPreviousTenancy(id, 'vacancy')
      this.$nextTick(() => {
        const indexTenant = this.getFilteredTenancies(this.objectId).length - 1
        const domRect = this.$el.querySelector('#tenancy-' + indexTenant)
        if (domRect) {
          domRect.scrollIntoView(true)
        }
      })
    },
    setDefaultValuesFromPreviousTenancy(id, source = null) {
      if (!this.tenancies.length) {
        return 0
      }
      const createdTenancy = this.tenancies[this.tenancies.length - 1]
      let lastTenancy
      let lastVacancy
      if (this.tenancies.find(x => x.status === 'TENANCY_STATUS_CURRENT' && x.endingAt < 0 && x.objectId === id)) {
        lastTenancy = JSON.parse(JSON.stringify(this.tenancies.find(x => x.status === 'TENANCY_STATUS_CURRENT' && x.endingAt < 0 && x.objectId === id)))
      } else {
        lastTenancy = JSON.parse(JSON.stringify(this.tenancies.filter(x => x.objectId === id).sort(function (a, b) {
          return b.endingAt - a.endingAt
        })[0]))
      }
      if (source === 'vacancy') {
        lastVacancy = this.tenancies.filter(x => x.objectId === id && x.vacancy).sort(function (a, b) {
          return b.endingAt - a.endingAt
        })[0]
      }
      if (lastTenancy) {
        const current = this
        createdTenancy.contract.creationDate = this.toObject(new Date())
        if (lastTenancy.rent.adjustments.length > 0) {
          const previousAdjustment = lastTenancy.rent.adjustments.length > 1
? lastTenancy.rent.adjustments.sort(function (a, b) {
            return (b.byDate ? new Date(current.$options.filters.objectToDateInput(b.byDate)) : 0) - (a.byDate ? new Date(current.$options.filters.objectToDateInput(a.byDate)) : 0)
          })[0]
: lastTenancy.rent.adjustments[0]
          if (previousAdjustment) {
            this.$nextTick(() => {
              createdTenancy.rent.adjustments[0].advancePaymentValues = []
              createdTenancy.rent.adjustments[0].flatRateValues = []
              createdTenancy.rent.adjustments[0].additionalValues = []
              this.properties[0].advancePaymentRentComponents.forEach(element => {
                if (element.inactive === false) {
                  const item = previousAdjustment.advancePaymentValues.find(e => e.type === element.rentComponent)
                  createdTenancy.rent.adjustments[0].advancePaymentValues.push({
                    type: element.rentComponent,
                    value: item ? item.value : null
                  })
                }
              })
              this.properties[0].flatRateRentComponents.forEach(element => {
                if (element.inactive === false) {
                  const item = previousAdjustment.flatRateValues.find(e => e.type === element.rentComponent)
                  createdTenancy.rent.adjustments[0].flatRateValues.push({
                    type: element.rentComponent,
                    value: item ? item.value : null
                  })
                }
              })
              this.properties[0].additionalRentComponents.forEach(element => {
                if (element.inactive === false) {
                  const item = previousAdjustment.additionalValues.find(e => e.type === element.rentComponent)
                  createdTenancy.rent.adjustments[0].additionalValues.push({
                    type: element.rentComponent,
                    value: item ? item.value : null
                  })
                }
              })
            })
            if (source === 'vacancy') {
              createdTenancy.rent.adjustments[0].referenceInterestRate.rate = previousAdjustment.referenceInterestRate.rate
              createdTenancy.rent.adjustments[0].referenceInterestRate.date = previousAdjustment.referenceInterestRate.date
              createdTenancy.rent.adjustments[0].countryIndex.date = previousAdjustment.countryIndex.date
              createdTenancy.rent.adjustments[0].countryIndex.baseDate = previousAdjustment.countryIndex.baseDate
              createdTenancy.rent.adjustments[0].costIncreaseType = previousAdjustment.costIncreaseType
              createdTenancy.rent.adjustments[0].costIncreaseDate = previousAdjustment.costIncreaseDate
              createdTenancy.rent.adjustments[0].costIncreaseValue = previousAdjustment.costIncreaseValue
              createdTenancy.rent.adjustments[0].dueDates = previousAdjustment.dueDates
              createdTenancy.rent.adjustments[0].countryIndex.inflation = previousAdjustment.countryIndex.inflation
              if (lastVacancy) {
                createdTenancy.opted = lastVacancy.opted
                createdTenancy.taxable = lastVacancy.taxable
              }
            }
            createdTenancy.rent.adjustments[0].coldRentPerMonth = previousAdjustment.coldRentPerMonth
            createdTenancy.rent.adjustments[0].coldRent = previousAdjustment.coldRent
            createdTenancy.rent.adjustments[0].coldRentM2PerYear = previousAdjustment.coldRentM2PerYear
            createdTenancy.rent.adjustments[0].calculationBasis = previousAdjustment.calculationBasis
            createdTenancy.rent.adjustments[0].additionalCostsBillingType = previousAdjustment.additionalCostsBillingType
            const lastestTenant = this.tenancies.filter(x => x.objectId === id && !x.internalId).sort(function (a, b) {
              return b.endingAt - a.endingAt
            })
            if (lastTenancy.vacancy === true && lastestTenant.length > 1 && lastestTenant[1].terminationType === 'TERMINATION_TYPE_OFF_TERM') {
              const lastAdjustement = lastestTenant[1].rent.adjustments.sort(function (a, b) {
                return b.byDate - a.byDate
              })[0]
              const lastTenant = lastestTenant[1]
              createdTenancy.rent.adjustments[0].coldRentPerMonth = lastAdjustement.coldRentPerMonth
              createdTenancy.rent.adjustments[0].coldRentM2PerYear = lastAdjustement.coldRentM2PerYear
              createdTenancy.rent.adjustments[0].coldRent = lastAdjustement.coldRent
              createdTenancy.rent.adjustments[0].calculationBasis = lastAdjustement.calculationBasis
              createdTenancy.rent.adjustments[0].additionalCostsBillingType = lastAdjustement.additionalCostsBillingType

              createdTenancy.rent.adjustments[0].retentionOfRents = lastAdjustement.retentionOfRents

              createdTenancy.rent.adjustments[0].referenceInterestRate.rate = lastAdjustement.referenceInterestRate.rate
              createdTenancy.rent.adjustments[0].referenceInterestRate.date = lastAdjustement.referenceInterestRate.date
              createdTenancy.rent.adjustments[0].referenceInterestRate.ratePossible = lastAdjustement.referenceInterestRate.ratePossible
              createdTenancy.rent.adjustments[0].countryIndex.date = lastAdjustement.countryIndex.date
              createdTenancy.rent.adjustments[0].countryIndex.baseDate = lastAdjustement.countryIndex.baseDate
              createdTenancy.rent.adjustments[0].costIncreaseType = lastAdjustement.costIncreaseType
              createdTenancy.rent.adjustments[0].costIncreaseDate = lastAdjustement.costIncreaseDate
              createdTenancy.rent.adjustments[0].costIncreaseValue = lastAdjustement.costIncreaseValue
              createdTenancy.rent.adjustments[0].dueDates = lastAdjustement.dueDates
              createdTenancy.rent.adjustments[0].countryIndex.inflation = lastAdjustement.countryIndex.inflation
              createdTenancy.terminationLandlord = lastTenant?.terminationLandlord
              createdTenancy.terminationTenant = lastTenant?.terminationTenant
            }
            if (lastTenancy.terminationType === 'TERMINATION_TYPE_OFF_TERM') {
              createdTenancy.rent.adjustments[0].coldRentPerMonth = previousAdjustment.coldRentPerMonth
              createdTenancy.rent.adjustments[0].coldRent = previousAdjustment.coldRent
              createdTenancy.rent.adjustments[0].coldRentM2PerYear = previousAdjustment.coldRentM2PerYear
              createdTenancy.rent.adjustments[0].calculationBasis = previousAdjustment.calculationBasis
              createdTenancy.rent.adjustments[0].additionalCostsBillingType = previousAdjustment.additionalCostsBillingType

              createdTenancy.rent.adjustments[0].retentionOfRents = previousAdjustment.retentionOfRents

              createdTenancy.rent.adjustments[0].referenceInterestRate.rate = previousAdjustment.referenceInterestRate.rate
              createdTenancy.rent.adjustments[0].referenceInterestRate.date = previousAdjustment.referenceInterestRate.date
              createdTenancy.rent.adjustments[0].referenceInterestRate.ratePossible = previousAdjustment.referenceInterestRate.ratePossible
              createdTenancy.rent.adjustments[0].countryIndex.date = previousAdjustment.countryIndex.date
              createdTenancy.rent.adjustments[0].countryIndex.baseDate = previousAdjustment.countryIndex.baseDate
              createdTenancy.rent.adjustments[0].costIncreaseType = previousAdjustment.costIncreaseType
              createdTenancy.rent.adjustments[0].costIncreaseDate = previousAdjustment.costIncreaseDate
              createdTenancy.rent.adjustments[0].costIncreaseValue = previousAdjustment.costIncreaseValue
              createdTenancy.rent.adjustments[0].dueDates = previousAdjustment.dueDates
              createdTenancy.rent.adjustments[0].countryIndex.inflation = previousAdjustment.countryIndex.inflation
              createdTenancy.terminationLandlord = lastTenancy?.terminationLandlord
              createdTenancy.terminationTenant = lastTenancy?.terminationTenant
            }
            if (lastTenancy.vacancy === true && lastestTenant.length > 1) {
              createdTenancy.terminationLandlord = lastestTenant[1]?.terminationLandlord
              createdTenancy.terminationLandlord.earliestDate = null
              createdTenancy.terminationTenant = lastestTenant[1]?.terminationTenant
              createdTenancy.terminationTenant.earliestDate = null
            } else {
              createdTenancy.terminationLandlord = lastTenancy?.terminationLandlord
              createdTenancy.terminationLandlord.earliestDate = null
              createdTenancy.terminationTenant = lastTenancy?.terminationTenant
              createdTenancy.terminationTenant.earliestDate = null
            }
          }
        }
        if (source === 'vacancy') {
          createdTenancy.terminationLandlord.possibleMonths = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
          createdTenancy.terminationTenant.possibleMonths = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
        }
        createdTenancy.rent.additionalCostsBillingType = lastTenancy.rent.additionalCostsBillingType
        if (lastTenancy.contract.rentType) {
          createdTenancy.contract.rentType = lastTenancy.contract.rentType
        }
        this.$nextTick(() => {
          if (lastTenancy.contract.typeOfUse) {
            createdTenancy.contract.typeOfUse = lastTenancy.contract.typeOfUse
            if (lastTenancy.contract.typeOfUse === 'TYPE_OF_USE_PARKING_EXTERNAL') {
              createdTenancy.taxable = !this.properties[0].opted
            }
          }
        })
        if (lastTenancy.vacancy === false && lastTenancy.endingAt !== null && lastTenancy.endingAt !== '-1') {
          const lastTenancyEndingDate = new Date(this.$options.filters.objectToDateInput(lastTenancy.endingAt))
          lastTenancyEndingDate.setDate(lastTenancyEndingDate.getDate() + 1)
          createdTenancy.startingAt = lastTenancyEndingDate instanceof Date && isFinite(lastTenancyEndingDate)
? {
            day: lastTenancyEndingDate.getDate(),
            month: lastTenancyEndingDate.getMonth() + 1,
            year: lastTenancyEndingDate.getFullYear()
          }
: null

          createdTenancy.rent.adjustments[0].byDate = lastTenancyEndingDate instanceof Date && isFinite(lastTenancyEndingDate)
? {
            day: lastTenancyEndingDate.getDate(),
            month: lastTenancyEndingDate.getMonth() + 1,
            year: lastTenancyEndingDate.getFullYear()
          }
: null
        }
        this.$store.dispatch('onboarding/updateTenancy', createdTenancy)
      }
    },
    setDefaultValuesFromPreviousOwnerAgreement(id) {
      const t = this
      if (!this.condominiumOwnership.length) {
        return 0
      }
      const createdCondo = this.condominiumOwnership[this.condominiumOwnership.length - 1]
      let lastCondo
      if (this.condominiumOwnership.find(x => x.status === 'CONDOMINIUM_STATUS_CURRENT' && x.objectId === id && !x.endingAt)) {
        lastCondo = this.condominiumOwnership.find(x => x.status === 'CONDOMINIUM_STATUS_CURRENT' && x.objectId === id && !x.endingAt)
      } else {
        lastCondo = this.condominiumOwnership.filter(x => x.objectId === id).sort(function (a, b) {
          return new Date(t.$options.filters.objectToDateInput(b.endingAt)).getTime() - new Date(t.$options.filters.objectToDateInput(a.endingAt)).getTime()
        })[0]
      }
      if (lastCondo) {
        const current = this
        if (lastCondo.endingAt) {
          const tempDate = new Date(this.$options.filters.objectToDateInput(lastCondo.endingAt)).getTime() / 1000
          const startDate = this.$options.filters.timestampToDateInput(tempDate + 86400)
          createdCondo.startingAt = this.toObject(startDate)
        }
        if (lastCondo.rent.adjustments.length > 0) {
          const previousAdjustment = lastCondo.rent.adjustments.length > 1
? lastCondo.rent.adjustments.sort(function (a, b) {
            return (b.byDate ? new Date(current.$options.filters.objectToDateInput(b.byDate)) : 0) - (a.byDate ? new Date(current.$options.filters.objectToDateInput(a.byDate)) : 0)
          })[0]
: lastCondo.rent.adjustments[0]
          if (previousAdjustment) {
            this.$nextTick(() => {
              createdCondo.rent.adjustments[0].flatRateValues = []
              createdCondo.rent.adjustments[0].additionalValues = []
              this.properties[0].flatRateRentComponents.forEach(element => {
                if (element.inactive === false) {
                  const item = previousAdjustment.flatRateValues.find(e => e.type === element.rentComponent)
                  createdCondo.rent.adjustments[0].flatRateValues.push({
                    type: element.rentComponent,
                    value: item ? item.value : null
                  })
                }
              })
              this.properties[0].additionalRentComponents.forEach(element => {
                if (element.inactive === false) {
                  const item = previousAdjustment.additionalValues.find(e => e.type === element.rentComponent)
                  createdCondo.rent.adjustments[0].additionalValues.push({
                    type: element.rentComponent,
                    value: item ? item.value : null
                  })
                }
              })
            })
            createdCondo.rent.adjustments[0].dueDates = previousAdjustment.dueDates
          }
        }
        this.$store.dispatch('onboarding/updateCondominiumOwnership', createdCondo)
      }
    },
    updateEndingDateVacancy(newVal) {
      let tempDate = new Date(this.$options.filters.objectToDateInput(newVal))
      tempDate = tempDate.setDate(tempDate.getDate() - 1)
      const endingDate = this.toObject(tempDate)
      this.initEnding = newVal ? endingDate : null
    },
    scrollToDevice(item) {
      this.addNewObjectDevice(item.id)
      this.$nextTick(() => {
        const indexDevice = this.getFilteredObjectDevices(item.id).length - 1
        const domRect = this.$el.querySelector('#device-' + indexDevice)
        if (domRect) {
          domRect.scrollIntoView(true)
          // add scrollBy for the header menue size
          window.scrollBy(0, -42)
        }
      })
    },
    scrollToRoom(item) {
      this.addNewObjectRooms(item.id)
      this.$nextTick(() => {
        const indexRoom = this.getFilteredObjectRooms(item.id).length - 1
        const domRect = this.$el.querySelector('#room-' + indexRoom)
        if (domRect) {
          domRect.scrollIntoView(true)
          // add scrollBy for the header menue size
          window.scrollBy(0, -42)
        }
      })
    }
    // addEnergySource() {
    //   this.item.energySources.push({
    //     type: 'UNDEFINED_ENERGY_SOURCE_TYPE',
    //     tankSize: -1,
    //     orderQuantity: -1,
    //     hasStorage: false,
    //     unit: 'ENERGY_SOURCE_UNIT_UNDEFINED',
    //     orderDate: -1,
    //     tankContent: -1,
    //     documentIds: [],
    //     tankReadings: [{
    //       date: null,
    //       documentIds: [],
    //       value: null
    //     }],
    //     isNewItem: true
    //   })
    //   const object = this.item
    //   this.$store.dispatch('onboarding/updateObject', object)
    // }
  }
}
</script>

<style lang="scss" scoped>
  h5 > span {
      font-size: 18px !important;
  }

  .add-object-image {
    position: absolute;
    width: 100%;
    height: 100%;
  }
</style>
