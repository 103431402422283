// @ts-nocheck
import network from './network'
import networkTS from '@/misc/apis/networkTS'

export default {
  async uploadMedia(formData): Promise<{
    id: string;
    ownerId: number;
    filename: string;
    mimeType: string;
    size: number;
    url: string,
    validUntil: number;
    thumbnails: {
      type: string;
      url: string;
    }[];
    uploadedAt: number;
  }> {
    return network.post('/web/media', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      timeout: 150000
    }).then(response => {
      return response.data
    })
  },
  getMediaByID(mediaId) {
    return network.get(`/web/media/${mediaId}`)
      .then(response => {
        return response.data
      })
  },
  getListMedia(mediaIds) {
    return network.post('/web/media/list', {
      ids: mediaIds
    })
      .then(response => {
        return response.data
      })
  },
  listDocuments(ids) {
    return network.post('/web/media/documents/list', {
      documentIds: ids
    }).then(response => {
      return response.data
    })
  },
  createDocument(ownerId, name, type, mediaId, showInPortal) {
    return network.post('/web/media/documents', {
      ownerId: ownerId,
      name: name,
      mediaId: mediaId,
      type: type,
      showInPortal: showInPortal
    }).then(response => {
      return response.data
    })
  },
  updateDocument(id, name, type, showInPortal) {
    const query = {
      name: name,
      type: type,
      showInPortal: showInPortal
    }
    return networkTS.documentServiceUpdate(id, query)
      .then(response => {
      return response.data
    })
  },
  addVersion(documentId, mediaId) {
    return network.post(`/web/media/documents/${documentId}/version`, {
      mediaId: mediaId
    }).then(response => {
      return response.data
    })
  },
  removeVersion(id, version) {
    return network.delete(`web/media/documents/${id}/version/${version}`)
      .then(response => {
        return response.data
      })
  },
  mergeAllDocuments (media, mediumName) {
    return network.post('/web/media/documents/mergeAllDocuments', {
      media: media,
      mergeMediumName: mediumName
    },
    {
      timeout: 300000
    }).then(response => {
      return response.data
    })
  }
}
