<template>
  <b-modal
    id="modal-edit-employee"
    ref="modal-edit-employee"
    no-close-on-backdrop
    :title="$t('message.employeesSettings.editEmployee')">
    <template v-if="loading">
      <div class="col d-flex justify-content-center m-3">
        <coozzy-spinner />
      </div>
    </template>
    <template v-else-if="user">
      <div class="row">
        <div class="col-sm-12 col-md-6 justify-content-center">
          <coozzy-spinner
            v-if="mediaUploadProcessing"
            class="align-self-center" />
          <img
            v-if="!mediaUploadProcessing"
            :class="['align-self-center', 'avatar-image', 'cursor-pointer']"
            :src="[user.profile.pictureUrl === '' ? '/avatar.png' : user.profile.pictureUrl]"
            alt="Placeholder"
            @click="uploadImageClicked">
          <coozzy-form-file-input
            ref="mediaUpload"
            class="d-none"
            accept="image/png,image/jpeg,image/jpg"
            @change="onFileSelect($event, 'profile')" />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-3 mb-2">
          <!--suppress XmlInvalidId -->
          <label for="gender">{{ $t('message.generic.gender.title') }}</label>
          <coozzy-form-select
            v-model="user.profile.gender"
            name="gender">
            <option :value="null" />
            <option value="MALE">
              {{ $t('message.generic.gender.male') }}
            </option>
            <option value="FEMALE">
              {{ $t('message.generic.gender.female') }}
            </option>
          </coozzy-form-select>
        </div>
        <div class="col-sm-12 col-md-3 mb-2">
          <coozzy-form-input
            v-model="user.profile.firstName"
            type="text"
            :name="$t('message.generic.firstName') + '*'"
            :state="$v.user.profile.firstName.$dirty && $v.user.profile.firstName.$error ? false : null" />
        </div>
        <div class="col-sm-12 col-md-6 mb-2">
          <coozzy-form-input
            v-model="user.profile.lastName"
            type="text"
            :name="$t('message.generic.lastName') + '*'"
            :state="$v.user.profile.lastName.$dirty && $v.user.profile.lastName.$error ? false : null" />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-6 mb-2">
          <coozzy-form-input
            v-model="user.email"
            type="email"
            :name="$t('message.generic.email')"
            :disabled="!allowEmailChange" />
        </div>
        <div class="col-sm-12 col-md-6 mb-2">
          <coozzy-form-input
            v-model="user.profile.phoneNumber"
            type="text"
            :name="$t('message.generic.phoneNumber')" />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-6">
          <coozzy-form-input
            v-model="user.profile.job"
            type="text"
            :name="$t('message.generic.job')"
            class="mb-2" />
        </div>

        <div class="col-sm-12 col-md-6 mb-3">
          <!--suppress XmlInvalidId -->
          <label
            for="language"
            class="mb-0">
            {{ $t('message.language.title') }}*
          </label>
          <coozzy-form-select
            id="language"
            v-model="user.profile.language">
            <option value="de">
              {{ $t('message.language.german') }}
            </option>
            <option value="en">
              {{ $t('message.language.english') }}
            </option>
            <option value="it">
              {{ $t('message.language.italian') }}
            </option>
          </coozzy-form-select>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-6 mb-3">
          <label
            for="signature"
            class="mb-0">
            {{ $t('message.employeesSettings.signature.title') }}
          </label>
          <coozzy-form-select
            id="signature"
            v-model="user.profile.signatureAuthorization">
            <option value="SIGNATURE_AUTHORIZATION_UNDEFINED">
              <span>-</span>
            </option>
            <option value="SIGNATURE_AUTHORIZATION_SOLE">
              {{ $t('message.employeesSettings.signature.individual') }}
            </option>
            <option value="SIGNATURE_AUTHORIZATION_COLLECTIVELY_BY_TWO">
              {{ $t('message.employeesSettings.signature.collective') }}
            </option>
          </coozzy-form-select>
        </div>
        <div
          v-if="isCompanyAdmin"
          class="col-sm-12 col-md-6 mb-3">
          <coozzy-multiselect
            v-model="currentRole"
            :options="availableRoles"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :preselect-first="false"
            :searchable="false"
            :taggable="false"
            :custom-label="customLabel"
            label="label"
            track-by="value">
            {{ $t('message.employeesSettings.administrator') }}
          </coozzy-multiselect>
        </div>
        <div class="col-sm-12 col-md-6">
          <label>{{ $t('message.generic.color') }}</label>
          <chrome v-model="color" />
        </div>
        <div class="col-sm-12 col-md-6">
          <template
            v-if="user.profile.signatureMediaId === '' && (isCompanyAdmin || userId === userToEdit?.id)">
            <coozzy-button
              size="normal"
              design="prop-green"
              class="mt-3"
              @click="showUploadSignatureModal">
              {{ $t('message.generic.uploadSignature') }}
            </coozzy-button>
            <coozzy-form-file-input
              ref="uploadSignature"
              class="d-none"
              accept="image/png,image/jpeg,image/jpg"
              @change="onFileSelect($event, 'signature')" />
          </template>
          <div
            v-else-if="signatureMediaUrl !== ''"
            class="uploaded-file my-2"
            style="background-repeat: no-repeat;background-size: contain;background-position: center center;"
            :style="{ 'background-image': 'url(' + signatureMediaUrl + ')' }">
            <div
              v-if="isCompanyAdmin || userId === userToEdit?.id"
              class="object-image-remove-container bg cursor-pointer"
              @click="deleteMedia()">
              <coozzy-close-icon class="object-image-remove" />
            </div>
          </div>
        </div>
      </div>
    </template>
    <div
      slot="modal-footer"
      class="w-100 float-right">
      <coozzy-button
        size="normal"
        design="green"
        class="float-right"
        :disabled="loading"
        @click="editEmployee()">
        {{ $t('message.generic.form.save') }}
      </coozzy-button>
      <coozzy-button
        size="normal"
        class="float-left mt-sm-0 mt-4"
        :disabled="loading"
        @click="hide()">
        {{ $t('message.generic.cancel') }}
      </coozzy-button>
    </div>
    <cropper-modal
      v-show="selectedFile"
      ref="cropperModal"
      :selected-file="selectedFile"
      :warning-message="sourceFile === 'signature' ? 'uploadSignature' : ''"
      :default-ratio="sourceFile === 'signature' ? 2/1 : 1/1"
      @save-image="saveImage" />
  </b-modal>
</template>
<script>
import { required } from 'vuelidate/lib/validators'
import { user } from '@/mixins/user'
import { Chrome } from 'vue-color'
import Vue from 'vue'
import UserApi from '@/misc/apis/UserApi'
import MediaApi from '@/misc/apis/MediaApi'
import EmployeeApi from '@/misc/apis/EmployeeApi'
import CoozzySpinner from '../../framework/components/misc/CoozzySpinner'
import CoozzyFormSelect from '../../framework/components/form/select/CoozzyFormSelect'
import CoozzyFormInput from '../../framework/components/form/input/CoozzyFormInput'
import CoozzyButton from '../../framework/components/button/CoozzyButton'
import CoozzyMultiselect from '@/framework/components/multiselect/CoozzyMultiselect'
import CropperModal from '../../framework/components/CropperModal'
import { mapMutations } from 'vuex'
import CoozzyFormFileInput from '@/framework/components/form/fileInput/CoozzyFormFileInput.vue'
import { media } from '@/mixins/media'
import router from '@/router/router'
import CoozzyCloseIcon from '@/framework/components/icons/CoozzyCloseIcon.vue'
// import { filter } from 'vue/types/umd'

export default {
  name: 'EditEmployeeModal',
  components: {
    CoozzyCloseIcon,
    CoozzyFormFileInput,
    CoozzyButton,
    CoozzyFormInput,
    CoozzyFormSelect,
    CoozzySpinner,
    Chrome,
    CropperModal,
    CoozzyMultiselect
  },
  mixins: [user, media],
  props: {
    userToEdit: {
      type: Object,
      default: null
    },
    allowEmailChange: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      oldEmail: '',
      user: this.userToEdit,
      loading: false,
      isAdmin: this.user && this.user.roles.includes('company_admin'),
      currentRole: [],
      initRole: [],
      roles: ['company_admin', 'manage_documents', 'manage_templates', 'manage_mails', 'editor', 'ticket_editor', 'ticket_delete', 'janitor', 'janitor_editor', 'ticket_reader', 'ticket_creator', 'digital_signature', 'digital_deposit', 'documents_portal'],
      selectedFile: null,
      mediaUploadProcessing: false,
      newUploadedImage: false,
      sourceFile: '',
      signatureMediaUrl: ''
    }
  },
  computed: {
    color: {
      get() {
        if (this.user.profile.color) {
          return {
            r: this.user.profile.color.red,
            g: this.user.profile.color.green,
            b: this.user.profile.color.blue,
            a: this.user.profile.color.alpha === null ? 1 : this.user.profile.color.alpha
          }
        } else {
          return {
            r: '',
            g: '',
            b: '',
            a: ''
          }
        }
      },
      set(value) {
        this.user.profile.color = {
          red: value.rgba.r,
          green: value.rgba.g,
          blue: value.rgba.b,
          alpha: value.rgba.a
        }
      }
    },
    availableRoles() {
      const array = []
      this.roles.forEach(role => {
        array.push({
          label: this.$t('message.employeesSettings.roles.' + role),
          value: role
        })
      })
      return array
    }
  },
  watch: {
    currentRole: function (val) {
      if (val.some(e => e.value === 'company_admin')) {
        this.roles = ['company_admin']
        if (val.length > 1) {
          this.currentRole = [{
            label: this.$t('message.employeesSettings.roles.company_admin'),
            value: 'company_admin'
          }]
        }
      } else {
        this.roles = ['company_admin', 'manage_documents', 'manage_templates', 'manage_mails', 'editor', 'ticket_editor', 'ticket_delete', 'janitor', 'janitor_editor', 'ticket_reader', 'ticket_creator', 'digital_signature', 'documents_portal', 'digital_deposit']
        if (this.isPlatformEmployee) {
          this.roles.push('publish_update_message', 'generate_data_sets')
        }
        if (this.hasAccessMarketBase) {
          this.roles.push('marketing')
        }
        if (this.hasAccessAssetBase) {
          this.roles.push('asset')
        }
        if (this.hasAccessAdminBase) {
          this.roles.push('management')
        }
        if (this.hasAccessAccountingBase) {
          this.roles.push('accounting')
        }
      }
    }
  },
  mounted() {
    if (this.isPlatformEmployee) {
      this.roles = this.roles.concat(['publish_update_message', 'generate_data_sets', 'marketing', 'asset', 'management', 'accounting'])
    }
    if (this.hasAccessMarketBase) {
      this.roles.push('marketing')
    }
    if (this.hasAccessAssetBase) {
      this.roles.push('asset')
    }
    if (this.hasAccessAdminBase) {
      this.roles.push('management')
    }
    if (this.hasAccessAccountingBase) {
      this.roles.push('accounting')
    }
    // Remove duplicates
    this.roles = this.roles.filter((item, index) => this.roles.indexOf(item) === index)

    if (this.userToEdit) {
      this.user = JSON.parse(JSON.stringify(this.userToEdit))
      this.oldEmail = this.user.email
      this.initRole = this.user.roles
      this.user.roles.forEach(role => {
        if (role !== 'company_default') {
          this.currentRole.push({
            label: this.$t('message.employeesSettings.roles.' + role),
            value: role
          })
        }
      })
      if (this.user.profile.signatureMediaId) {
        MediaApi.getMediaByID(this.user.profile.signatureMediaId)
          .then(response => {
            this.signatureMediaUrl = response.media.thumbnails?.find(t => t.type === 'M')?.url || response.media.url
          })
          .catch(e => {
            console.log(e)
          })
      }
    }
  },
  methods: {
    ...mapMutations('employee', ['updateEmployee']),
    showUploadSignatureModal() {
      const btn = this.$refs.uploadSignature.$el.children
      btn[0].click()
    },
    uploadImageClicked() {
      const btn = this.$refs.mediaUpload.$el.children
      btn[0].click()
    },
    deleteMedia() {
      this.user.profile.signatureMediaId = ''
      this.signatureMediaUrl = ''
    },
    onFileSelect(e, source) {
      const file = e.target.files[0]
      if (file.size === 0) {
        Vue.toasted.show(this.$tc('message.uploadingErrors.zipFileNotAccepted'), { type: 'error' })
        if (source === 'profile' && this.$refs.mediaUpload) {
          this.$refs.mediaUpload.resetPlaceholder()
        } else if (source === 'signature' && this.$refs.uploadSignature) {
          this.$refs.uploadSignature.resetPlaceholder()
        }
        return
      }
      if (file) {
        this.mime_type = file.type
        if (typeof FileReader === 'function') {
          const reader = new FileReader()
          reader.onload = (event) => {
            this.selectedFile = event.target.result
            this.sourceFile = source
            this.$nextTick(function () {
              this.$refs.cropperModal.show()
            })
          }
          reader.readAsDataURL(file)
        } else {
          alert('Sorry, FileReader API not supported')
        }
      }
    },
    saveImage(cropper) {
      cropper.getCroppedCanvas().toDataURL()
      cropper.getCroppedCanvas().toBlob((blob) => {
        const formData = new FormData()
        formData.append('file', blob, this.sourceFile === 'signature' ? 'signature_picture' : 'profile_picture')
        formData.append('filename', this.sourceFile === 'signature' ? 'signature_picture' : 'profile_picture')
        formData.append('public', 'true')
        MediaApi.uploadMedia(formData)
          .then(response => {
            this.selectedFile = null
            if (this.sourceFile === 'signature') {
              this.user.profile.signatureMediaId = response.id
              this.signatureMediaUrl = response.thumbnails?.find(t => t.type === 'M')?.url || response.url
            } else {
              this.user.profile.pictureUrl = response.thumbnails?.find(t => t.type === 'M')?.url || response.url
              this.newUploadedImage = true
            }
          })
          .catch(e => {
            console.log(e)
            if (e.response && e.response.data && e.response.data.message === 'Not supported MIME type') {
              Vue.toasted.show(this.$t('message.savingErrors.fileNotSupported'), { type: 'error' })
            } else {
              Vue.toasted.show(this.$t('message.savingErrors.file'), { type: 'error' })
            }
          })
          .finally(() => {
            this.mediaUploadProcessing = false
            this.sourceFile = ''
          })
      }, this.mime_type)
    },
    editEmployee() {
      this.$v.user.$touch()
      if (!this.$v.user.$invalid) {
        this.loading = true
        this.user.roles = []
        if (this.allowEmailChange && this.oldEmail !== this.user.email) {
          UserApi.changeEmail(this.user.id, this.user.email)
            .catch(e => {
              console.log(e)
              Vue.toasted.show('Email konnte nicht geändert werden: ' + e, { type: 'error' })
            })
        }

        UserApi.updateUserProfile(this.user.id, this.user)
          .then(async () => {
            if (this.newUploadedImage && this.user.id === this.$store.getters['user/getUserId']) {
              await this.$auth.renewTokens()
                .catch(() => {
                  // If we are not logged in we cannot renew anything. Redirect to login.
                  this.$router.app.$auth.login('', {
                    language: router.app.$store.getters['internationalization/getCurrentLanguage']
                  })
                })
              this.newUploadedImage = false
            }
            for await (const element of this.currentRole.flatMap(x => x.value)) {
              if (element !== 'company_default' && !this.initRole.includes(element)) {
                await EmployeeApi.addRole(this.user.id, element)
                  .then(() => {
                    this.initRole.push(element)
                    this.$v.$reset()
                    this.hide()
                  })
                  .catch(e => {
                    console.log(e)
                    this.loading = false
                  })
              }
            }
            for await (const element of this.initRole) {
              if (element !== 'company_default' && !this.currentRole.flatMap(x => x.value).includes(element)) {
                await EmployeeApi.removeRole(this.user.id, element)
                  .then(() => {
                    this.initRole = this.initRole.filter(x => x !== element)

                    this.$v.$reset()
                    this.hide()
                  })
                  .catch(e => {
                    console.log(e)
                    this.loading = false
                  })
              }
            }
            this.$emit('endEdit')
            this.updateEmployee(this.user)
            this.hide()
            this.loading = false
            this.$v.$reset()
          })
          .catch(e => {
            this.loading = false
            console.log(e)
          })
      }
    },
    show() {
      this.$refs['modal-edit-employee'].show()
    },
    hide() {
      this.$refs['modal-edit-employee'].hide()
    },
    customLabel(item) {
      return this.$t(`message.employeesSettings.roles.${item.value}`)
    }
  },
  validations: {
    user: {
      profile: {
        firstName: {
          required
        },
        lastName: {
          required
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.avatar-image {
  width: 130px;
  height: 130px;
  border-radius: 50%;
}
.uploaded-file {
  height: 10rem;
  width: 12rem;
  position: relative;
}
.object-image-remove-container {
  position: absolute;
  z-index: 2;
  width: 16px;
  height: 16px;
  margin: 4px 12px;
  &.bg {
    right: 0;
    top: -3px;
    width: 14px;
    height: 22px;
    color: white;
  }
}
.object-image-remove {
  position: absolute;
  z-index: 3;
  width: 17px;
  height: 16px;
  color: red;
}
</style>
