<template>
  <section>
    <div
      v-if="source !== 'modal'"
      class="d-flex"
      style="justify-content: space-between; align-items: center;">
      <h5 class="modal-title mb-3">
        {{ $t('message.marketingMessages.detailEmail') }}
      </h5>
      <div>
        <a
          v-if="ticket"
          class="d-inline-block linkTicket mr-3"
          @click="routeToDetail(ticket)"
          @click.middle="openMiddleDetail(ticket)">
          <span
            :id="'tooltip-title-' + source + ticket.key">
            <img
              height="20px"
              class="mr-1 mb-1"
              :src="`/img/ticket/${ticket.type.split('TICKET_TYPE_')[1].toLowerCase()}.svg`"
              alt="type">{{ $t('message.ticketOverview.ticket.type.' + ticket.type) }} {{ ticket.key }}
          </span>
          <b-tooltip
            :target="'tooltip-title-' + source + ticket.key"
            triggers="hover">
            {{ ticket.title }}
          </b-tooltip>
        </a>
        <coozzy-form-checkbox
          v-model="confidentialEmail"
          :disabled="!canChangeMailConfidential"
          :initial="confidentialEmail"
          class="d-inline-block mr-4 pr-0 align-self-center"
          name="confidentialEmail"
          @change="(value) => { markAsConfidential(value) }">
          {{ $t('message.settings.confidentialEmails.confidential') }}
        </coozzy-form-checkbox>
      </div>
    </div>
    <div
      v-if="loading"
      class="text-center">
      <coozzy-spinner />
    </div>
    <template v-else>
      <div class="row">
        <!-- Action buttons -->
        <div class="col clearfix">
          <coozzy-button
            v-if="email.spam === false || (email.spam === true && email.inTrash === true)"
            class="col-auto mr-3 mb-2"
            @click="reply">
            <coozzy-reply-icon class="mb-1" />
            <span class="ml-1">{{ $t('message.marketingMessages.reply') }} </span>
          </coozzy-button>
          <coozzy-button
            v-if="email.spam === false || (email.spam === true && email.inTrash === true)"
            class="col-auto mr-3 mb-2"
            @click="replyAll">
            <coozzy-reply-all-icon class="mb-1" />
            <span class="ml-1">{{ $t('message.marketingMessages.replyAll') }} </span>
          </coozzy-button>
          <coozzy-button
            v-if="email.spam === false || (email.spam === true && email.inTrash === true)"
            class="col-auto mr-3 mb-2"
            @click="forwardMail">
            <coozzy-forward-icon class="mb-1" />
            <span class="ml-1">{{ $t('message.marketingMessages.forward') }} </span>
          </coozzy-button>
          <template
            v-if="email.spam === false || (email.spam === true && email.inTrash === true)">
            <coozzy-button
              v-if="email.archived"
              class="col-auto mr-3 mb-2"
              @click="restoreMailClick()">
              <coozzy-file-icon class="mb-1" />
              <span class="ml-1">{{ $t('message.generic.restore') }} </span>
            </coozzy-button>
            <coozzy-button
              v-else
              class="col-auto mr-3 mb-2"
              @click="archiveMailClick()">
              <coozzy-file-icon class="mb-1" />
              <span class="ml-1">{{ $t('message.generic.archive') }} </span>
            </coozzy-button>
          </template>
          <coozzy-button
            v-if="email.spam === true && !email.inTrash"
            class="col-auto  mr-3 mb-2"
            @click="markAsNoSpam()">
            <coozzy-spam-icon />
            <span class="ml-1">{{ $t('message.marketingMessages.markAsNoSpam') }} </span>
          </coozzy-button>
          <coozzy-button
            v-if="!email.inTrash"
            class="col-auto  mr-3 mb-2"
            @click="deleteMailClick()">
            <coozzy-delete-icon />
            <span class="ml-1">{{ $t('message.generic.delete') }} </span>
          </coozzy-button>
          <coozzy-dropdown
            v-if="email.spam === false || (email.spam === true && email.inTrash === true)"
            :text="$t('message.generic.more')"
            class="col-auto mr-3 mb-2 pl-0 more-btn"
            design="light"
            size="sm">
            <!--       SubMenu Create       -->
            <coozzy-dropdown
              :text="$t('message.generic.create')"
              class="w-100 ticket-dropdown-bulk-action enabled"
              design="transparent"
              dropright
              size="sm">
              <coozzy-dropdown-item
                v-if="isTicketingEnabled && (isEditor || isTicketEditor || isTicketCreator || isCompanyAdmin) && !isOwnerModule && !isAssetModule"
                @click="redirectToCreateOrder">
                {{ $t('message.ticketOverview.createOrder') }}
              </coozzy-dropdown-item>
              <coozzy-dropdown-item
                v-if="isTicketingEnabled && (isEditor || isTicketEditor || isTicketCreator || isCompanyAdmin) && !isOwnerModule && !isAssetModule"
                @click="showAddTicketConnectionsModal('TICKET_TYPE_TASK')">
                {{ $t('message.marketingMessages.createTask') }}
              </coozzy-dropdown-item>
              <coozzy-dropdown-item
                @click="$refs['reminder-creation'].show()">
                {{ $t('message.calendar.reminder.create') }}
              </coozzy-dropdown-item>
            </coozzy-dropdown>
            <!--       SubMenu Link       -->
            <coozzy-dropdown
              :text="$t('message.generic.link')"
              class="w-100 ticket-dropdown-bulk-action enabled"
              design="transparent"
              dropright
              size="sm">
              <coozzy-dropdown-item
                v-if="!hasAccessUserBase"
                @click="linkToContact">
                {{ $t('message.marketingMessages.linkToContact') }}
              </coozzy-dropdown-item>
              <coozzy-dropdown-item
                v-if="!hasAccessUserBase && hasAccessMarketBase"
                @click="linkToRequest">
                {{ $t('message.marketingMessages.linkToRequest') }}
              </coozzy-dropdown-item>
              <coozzy-dropdown-item
                @click="linkToTicket">
                {{ $t('message.marketingMessages.linkToTicket') }}
              </coozzy-dropdown-item>
            </coozzy-dropdown>
            <coozzy-dropdown-item
              v-if="!hasAccessUserBase && (getManuallyAssignedContactId(email, email.referenceIds) || (firstContactReferenceId && firstContactReferenceId.length)) && !($route.name && $route.name.endsWith('ContactDetailsView'))"
              @click="showContactDetail(firstContactReferenceId)">
              {{ $t('message.marketingMessages.toContact') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-item
              @click="generateEmailPDF">
              {{ $t('message.marketingMessages.forwardAsAttachement') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-item
              v-if="email.spam === false"
              @click="markAsSpam">
              {{ $t('message.marketingMessages.markAsSpam') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-item
              @click="printMessage">
              {{ $t('message.marketingMessages.print') }}
            </coozzy-dropdown-item>
          </coozzy-dropdown>
          <div
            v-if="!email.inbound"
            class="col-auto mr-3 mb-2 mt-2 deliver-status">
            <label> {{ $t('message.tableColumnHeadings.receivedOpened') }}: &nbsp;</label>
            <template v-if="email.deliverData.status === 'DELIVER_STATUS_DELIVERED'">
              <coozzy-success-icon />
            </template>
            <template v-else-if="email.deliverData.status === 'DELIVER_STATUS_PENDING'">
              <coozzy-pending-icon />
            </template>
            <template v-else>
              <coozzy-fail-icon
                v-b-tooltip.hover.left
                :title="email.deliverData.errorMessage" />
            </template>
            /
            <template v-if="email.engagementData.status === 'ENGAGEMENT_STATUS_OPENED'">
              <coozzy-success-icon />
            </template>
            <template v-else>
              <coozzy-fail-icon />
            </template>
          </div>
        </div>
      </div>
      <print-message
        v-if="emailUpdated"
        :key="dynamicId"
        :email="emailUpdated"
        :medias-list="mediasList"
        :print-allow="printAllow"
        :ticket="ticket" />
    </template>
    <b-modal
      :id="'confirmationModal'"
      ref="confirmationModal"
      :title="$t('message.marketingMessages.confirmModal.confirmationTitle')"
      hide-footer
      no-close-on-backdrop>
      <div
        v-if="loadDeleting"
        class="text-center">
        <coozzy-spinner />
      </div>
      <template v-else>
        <div
          class="col p-0">
          <p>{{ $t('message.marketingMessages.confirmModal.confirmationTextDeleteOneMail') }}</p>
        </div>
        <div class="col p-0">
          <coozzy-button
            class="mb-0 border"
            design="default"
            size="small"
            :disabled="loadDeleting"
            @click="$bvModal.hide('confirmationModal')">
            {{ $t('message.generic.cancel') }}
          </coozzy-button>
          <coozzy-button
            class="float-right mb-0"
            design="green"
            size="small"
            :disabled="loadDeleting"
            @click="deleteMail()">
            {{ $t('message.generic.delete') }}
          </coozzy-button>
        </div>
      </template>
    </b-modal>
    <b-modal
      :id="'archiveConfirmationModal'"
      ref="archiveConfirmationModal"
      :title="$t('message.marketingMessages.confirmModal.confirmationTitle')"
      hide-footer
      no-close-on-backdrop>
      <div
        v-if="loading"
        class="text-center">
        <coozzy-spinner />
      </div>
      <template v-else>
        <div
          class="col p-0">
          <p>{{ $t('message.marketingMessages.confirmModal.confirmationTextArchive') }}</p>
        </div>
        <div class="col p-0">
          <coozzy-button
            class="mb-0 border"
            design="default"
            size="small"
            :disabled="loadArchiving"
            @click="$bvModal.hide('archiveConfirmationModal')">
            {{ $t('message.generic.cancel') }}
          </coozzy-button>
          <coozzy-button
            class="float-right mb-0"
            design="green"
            size="small"
            :disabled="loadArchiving"
            @click="archiveMail()">
            {{ $t('message.generic.archive') }}
          </coozzy-button>
        </div>
      </template>
    </b-modal>
    <b-modal
      :id="'restoreConfirmationModal'"
      ref="restoreConfirmationModal"
      :title="$t('message.marketingMessages.confirmModal.confirmationTitle')"
      hide-footer
      no-close-on-backdrop>
      <div
        v-if="loading"
        class="text-center">
        <coozzy-spinner />
      </div>
      <template v-else>
        <div
          class="col p-0">
          <p>{{ $t('message.marketingMessages.confirmModal.confirmationTextRestoreFromArchive') }}</p>
        </div>
        <div class="col p-0">
          <coozzy-button
            class="mb-0 border"
            design="transparent"
            size="small"
            :disabled="loadRestoring"
            @click="$bvModal.hide('restoreConfirmationModal')">
            {{ $t('message.generic.cancel') }}
          </coozzy-button>
          <coozzy-button
            class="float-right mb-0"
            design="green"
            size="small"
            :disabled="loadRestoring"
            @click="restoreMail()">
            {{ $t('message.generic.restore') }}
          </coozzy-button>
        </div>
      </template>
    </b-modal>
    <reminder-creation-modal
      ref="reminder-creation"
      :references-ids="[email.id || email.objectID]"
      :source="'message'"
      @refresh-list="refreshEmailList" />
    <vue-html2pdf
      ref="html2Pdf"
      :enable-download="false"
      :float-layout="true"
      :html-to-pdf-options="{ margin: 10 ,html2canvas: { scale: 1, useCORS: true } }"
      :manual-pagination="false"
      :paginate-elements-by-height="1500"
      :pdf-quality="2"
      :preview-modal="true"
      :show-layout="false"
      pdf-content-width="730px"
      pdf-format="a4"
      pdf-orientation="portrait"
      @hasDownloaded="generatePDFBodyEmail">
      <section
        slot="pdf-content"
        class="section-to-print-bg">
        <print-message
          v-if="emailUpdated"
          :key="dynamicId"
          :email="emailUpdated"
          :medias-list="mediasList"
          :ticket="ticket"
          :print-allow="printAllow"
          :printing="printing" />
      </section>
    </vue-html2pdf>
    <add-ticket-connections-modal
      v-if="addTicketConnectionsModal"
      ref="addTicketConnectionsModal"
      :ticket-type="ticketType"
      :email="email"
      @close-modal="closeAddTicketConnectionsModal" />
  </section>
</template>

<script>

import ReminderCreationModal from '@/calendar/components/ReminderCreationModal'
import CoozzyButton from '@/framework/components/button/CoozzyButton'
import { user } from '@/mixins/user'
import { media } from '@/mixins/media'
import { contact } from '@/mixins/contact'
import PrintMessage from '@/contact/components/PrintMessage'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import MediaApi from '../../misc/apis/MediaApi'
import CoozzyFileIcon from '../../framework/components/icons/CoozzyFileIcon'
import CoozzyReplyIcon from '@/framework/components/icons/CoozzyReplyIcon'
import CoozzyReplyAllIcon from '@/framework/components/icons/CoozzyReplyAllIcon'
import CoozzyForwardIcon from '@/framework/components/icons/CoozzyForwardIcon'
import Vue from 'vue'
import MessageApi from '@/misc/apis/MessageApi'
import { routeChecks } from '@/mixins/routeChecks'
import { request } from '@/mixins/request'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner'
import CoozzyDeleteIcon from '@/framework/components/icons/CoozzyDeleteIcon'
import CoozzySpamIcon from '@/framework/components/icons/CoozzySpamIcon'
import { mail } from '@/mixins/mail'
import { algolia } from '@/mixins/algolia'
import printJS from 'print-js'
import VueHtml2pdf from 'vue-html2pdf'
import CoozzyDropdownItem from '@/framework/components/dropdown/CoozzyDropdownItem.vue'
import CoozzyDropdown from '@/framework/components/dropdown/CoozzyDropdown.vue'
import CoozzyFormCheckbox from '@/framework/components/form/checkbox/CoozzyFormCheckbox.vue'
import AddTicketConnectionsModal from '@/management/components/ticket/addTicketConnectionsModal.vue'
import PDFMerger from 'pdf-merger-js'
import CoozzyFailIcon from '@/framework/components/icons/CoozzyFailIcon.vue'
import CoozzyPendingIcon from '@/framework/components/icons/CoozzyPendingIcon.vue'
import CoozzySuccessIcon from '@/framework/components/icons/CoozzySuccessIcon.vue'

export default {
  name: 'MessageDetailContent',
  components: {
    CoozzySuccessIcon,
    CoozzyPendingIcon,
    CoozzyFailIcon,
    AddTicketConnectionsModal,
    CoozzyFormCheckbox,
    CoozzyDropdown,
    CoozzyDropdownItem,
    ReminderCreationModal,
    CoozzyDeleteIcon,
    CoozzySpinner,
    CoozzyReplyIcon,
    CoozzyButton,
    CoozzyFileIcon,
    CoozzyReplyAllIcon,
    CoozzyForwardIcon,
    CoozzySpamIcon,
    VueHtml2pdf,
    PrintMessage
  },
  mixins: [algolia, routeChecks, user, mail, media, contact, request],
  props: {
    contact: {
      type: Object,
      default() {
        return null
      }
    },
    email: {
      type: Object,
      default() {
        return null
      }
    },
    ticket: {
      type: Object,
      default() {
        return null
      }
    },
    source: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      mediasList: [],
      printAllow: false,
      loading: true,
      loadArchiving: false,
      loadRestoring: false,
      loadDeleting: false,
      emailForForward: false,
      confidentialEmail: false,
      documentDirection: '',
      ticketType: '',
      dynamicId: '',
      emailUpdated: null,
      addTicketConnectionsModal: false,
      printing: false
    }
  },
  computed: {
    ...mapState('request', ['marketplace']),
    ...mapState('message', ['confidentialMailDomain']),
    ...mapGetters('message', ['getMailboxesForUserId']),
    firstContactReferenceId() {
      return this.email.referenceIds.find(referenceId => referenceId.startsWith('contact_')) || []
    },
    mailboxesForCurrentUser() {
      return this.getMailboxesForUserId(this.userId)
    },
    selectedEntriesText() {
      return this.$t('message.generic.selectedEntries') + ':  ' + this.selectedEntries.length
    },
    mailId() {
      return this.email.id || this.email.objectID
    },
    canChangeMailConfidential() {
      const outboundEmails = this.mailboxesForCurrentUser.filter(mailbox => mailbox.mainUserId === this.userId).map(mailbox => mailbox.outboundEmail)
      const emailFrom = this.email.from ? [this.email.from.email] : []
      const emailTo = this.email.to ? this.email.to.flatMap(x => x.email) : []
      const emailBcc = (this.email.bcc && this.email.bcc[0]) ? this.email.bcc[0].email : ''
      const emailCc = this.email.cc ? this.email.cc.flatMap(x => x.email) : []
      const recipientsMails = emailFrom.concat(emailTo).concat(emailCc).concat(emailBcc)
      return recipientsMails.some(x => outboundEmails.includes(x))
    }
  },
  watch: {
    printAllow: function (newVal) {
      this.$emit('print-message', newVal)
    },
    loading: function (newVal) {
      this.$emit('loading-message', newVal)
    }
  },
  mounted() {
    if (!this.email?.readEntries?.some(entry => entry.accountId === this.accountId) || this.getUnreadMail().find(entry => entry.accountId === this.accountId && entry.mailId === this.mailId)) {
      const outboundEmails = this.mailboxesForCurrentUser
        .filter(mailbox => mailbox.mainUserId === this.userId)
        .map(mailbox => mailbox.outboundEmail)
      const emailTo = this.email.to ? this.email.to.flatMap(x => x.email) : []
      const emailBcc = (this.email.bcc && this.email.bcc[0]) ? this.email.bcc[0].email : ''
      const emailCc = this.email.cc ? this.email.cc.flatMap(x => x.email) : []
      if (outboundEmails.includes(emailBcc) || emailCc.some(x => outboundEmails.includes(x)) || emailTo.some(x => x.startsWith('req_') || outboundEmails.includes(x))) {
        MessageApi.mailMarkRead([this.mailId], this.accountId).then(() => {
          this.addReadMail({
            mailId: this.mailId,
            accountId: this.accountId
          })
        })
      }
    }
    this.loadConfidentialMailDomain(this.accountId)
    this.checkConfidentialMail()
      if (this.email.missingHtml) {
      MessageApi.listMailsByIds([this.email.id])
        .then(response => {
          if (response.mails?.length === 1) {
            this.dynamicId = Math.random().toString(36).substr(2, 9)
            this.emailUpdated = response.mails[0]
          }
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.generic.error'), { type: 'error' })
        })
        .finally(() => {
          this.loading = false
        })
    } else {
      this.loading = false
      this.emailUpdated = this.email
    }
    if (this.email.attachments?.length) {
      for (let index = 0; index < this.email.attachments.length; index++) {
        MediaApi.getMediaByID(this.email.attachments[index].mediaId)
          .then(response => {
            this.mediasList.push(response)
          })
          .catch(() => {
            Vue.toasted.show(this.$t('message.loadingErrors.media'), { type: 'error' })
          })
      }
    } else if (this.email.attachmentMediaIds?.length) {
      MediaApi.getListMedia(this.email.attachmentMediaIds)
        .then(response => {
          for (const media of response.media) {
            this.mediasList.push(media)
          }
        })
        .catch(() => {
          Vue.toasted.show(this.$t('message.loadingErrors.media'), { type: 'error' })
        })
    } else {
      this.mediasList = []
    }
  },
  methods: {
    ...mapGetters('message', ['getUnreadMail']),
    ...mapMutations('message', ['addReadMail', 'setChangingStatusMails']),
    ...mapActions('message', ['loadMailboxesForUserId', 'loadConfidentialMailDomain']),
    showAddTicketConnectionsModal(type) {
      this.ticketType = type
      this.addTicketConnectionsModal = true
      this.$nextTick(function () {
        this.$refs.addTicketConnectionsModal?.show()
      })
    },
    closeAddTicketConnectionsModal() {
      this.addTicketConnectionsModal = false
    },
    checkConfidentialMail() {
      if (this.email.confidential) {
        this.confidentialEmail = true
        return
      }
      if (this.confidentialMailDomain.length > 0) {
        let isConfidential = false
        if (this.from) {
          isConfidential = this.from.confidential
        }
        this.confidentialMailDomain.forEach((element) => {
          if (isConfidential) {
            return
          }
          isConfidential = (this.email.to.length > 0 && this.email.to.some((item) => item.email.includes(element) || (item.workEmail?.use === true && item.workEmail?.includes(element)))) || (this.email.cc.length > 0 && this.email.cc.some((item) => item.email.includes(element) || (item.workEmail?.use === true && item.workEmail?.includes(element)))) || (this.email.bcc.length > 0 && this.email.bcc.some((item) => item.email.includes(element) || (item.workEmail?.use === true && item.workEmail?.includes(element))))
        })
        this.confidentialEmail = isConfidential
      }
    },
    markAsConfidential(bool) {
      MessageApi.setConfidential([this.email.id], bool)
        .then(() => {
          this.email.confidential = bool
          this.$emit('refresh-list-after-close', this.email.id || this.email.objectID)
        })
        .catch(() => {
          Vue.toasted.show(this.$t('message.generic.error'), { type: 'error' })
        })
    },
    refreshEmailList() {
      this.$emit('refresh-reminders')
    },
    hide() {
      this.$emit('hide')
    },
    async markAsSpam() {
      this.loading = true
      this.setChangingStatusMails([this.email.id || this.email.objectID])
      MessageApi.markAsSpam([this.email.id || this.email.objectID])
        .then(async () => {
          this.hide()
          this.$emit('refresh-list', this.email.id || this.email.objectID)
        })
        .catch(e => {
          console.log(e)
        })
        .finally(() => {
          this.loading = false
        })
    },
    async markAsNoSpam() {
      this.loading = true
      this.setChangingStatusMails([this.email.id || this.email.objectID])
      MessageApi.markAsNoSpam([this.email.id || this.email.objectID])
        .then(async () => {
          this.hide()
          this.$emit('refresh-list', this.email.id || this.email.objectID)
        })
        .catch(e => {
          console.log(e)
        })
        .finally(() => {
          this.loading = false
        })
    },
    deleteMailClick() {
      this.$refs.confirmationModal.show()
    },
    archiveMailClick() {
      this.$refs.archiveConfirmationModal.show()
    },
    restoreMailClick() {
      this.$refs.restoreConfirmationModal.show()
    },
    async generatePDFBodyEmail(file) {
      const merger = new PDFMerger()
      this.$nextTick(() => {
        document.getElementsByClassName('pdf-preview')[0]?.remove()
      })
      if (this.emailForForward === false) {
        await merger.add(file)
        if (this.mediasList.length > 0) {
          for (let i = 0; i < this.mediasList.length; i++) {
            if (this.mediasList[i].url && this.mediasList[i].url.endsWith('.pdf')) {
              await merger.add(this.mediasList[i].url)
            }
          }
        }

        const mergedPdf = await merger.saveAsBlob()
        const url = URL.createObjectURL(mergedPdf)
        printJS({
          printable: url,
          type: 'pdf'
        })
      } else {
        this.emailForForward = false
        this.sendFile(file)
      }
      this.printing = false
    },
    printMessage() {
      this.printing = true
      this.$nextTick(() => {
        this.$refs.html2Pdf.generatePdf()
      })
    },
    generateEmailPDF() {
      this.emailForForward = true
      this.documentDirection = 'email'
      this.$refs.html2Pdf.generatePdf()
    },
    forwardAsAttachement() {
      this.hide()
      this.$emit('forward-as-attachement', this.mediasList, this.email)
      this.documentDirection = ''
    },
    sendFile(file) {
      const formData = new FormData()
      formData.append('file', file)
      formData.append('filename', 'Email.pdf')
      formData.append('public', 'true')
      MediaApi.uploadMedia(formData).then(response => {
        this.mediasList.push(response)
        if (this.documentDirection === 'email') {
          this.forwardAsAttachement()
        }
      })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.savingErrors.file'), { type: 'error' })
        })
    },
    async deleteMail() {
      this.loadDeleting = true
      this.setChangingStatusMails([this.email.id || this.email.objectID])
      MessageApi.deleteMails([this.email.id || this.email.objectID])
        .then(async () => {
          this.hide()
          this.$emit('delete-mail', this.email.id || this.email.objectID)
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.deleteErrors.mail'), { type: 'error' })
        })
        .finally(() => {
          this.loadDeleting = false
          if (this.$refs.confirmationModal) {
            this.$refs.confirmationModal.hide()
          }
        })
    },
    async archiveMail() {
      this.loadArchiving = true
      this.setChangingStatusMails([this.email.id || this.email.objectID])
      MessageApi.archiveMails([this.email.id || this.email.objectID])
        .then(() => {
          this.$emit('archive-mail', this.email.id || this.email.objectID)
          this.hide()
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.deleteErrors.archive'), { type: 'error' })
        })
        .finally(() => {
          this.loadArchiving = false
          if (this.$refs.archiveConfirmationModal) {
            this.$refs.archiveConfirmationModal.hide()
          }
        })
    },
    async restoreMail() {
      this.loadRestoring = true
      this.setChangingStatusMails([this.email.id || this.email.objectID])
      MessageApi.unarchiveMails([this.email.id || this.email.objectID])
        .then(async () => {
          this.hide()
          this.$emit('restore-mail', this.email.id || this.email.objectID)
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.deleteErrors.archive'), { type: 'error' })
        })
        .finally(() => {
          this.loadRestoring = false
          if (this.$refs.restoreConfirmationModal) {
            this.$refs.restoreConfirmationModal.hide()
          }
        })
    },
    reply() {
      this.hide()
      this.$emit('reply-mail', this.email)
    },
    replyAll() {
      this.hide()
      this.$emit('reply-all-mail', this.email)
    },
    forwardMail() {
      this.hide()
      this.$emit('forward-mail', this.email)
    },
    linkToContact() {
      const mailboxesForCurrentUser = this.mailboxesForCurrentUser.map(x => x.outboundEmail)
      let emailToLink = ''
      if (mailboxesForCurrentUser.includes(this.email.from.email)) {
        emailToLink = this.email.to[0].email
      } else {
        emailToLink = this.email.from.email
      }
      if (this.isOwnerModule) {
        this.$router.push({
          name: 'OwnerContactOverview',
          query: {
            linkMail: this.email.objectID || this.email.id,
            email: emailToLink
          }
        })
      } else if (this.isMarketingModule) {
        this.$router.push({
          name: 'MarketingContactOverview',
          query: {
            linkMail: this.email.objectID || this.email.id,
            email: emailToLink
          }
        })
      } else if (this.isAccountingModule) {
        this.$router.push({
          name: 'AccountingContactOverview',
          query: {
            linkMail: this.email.objectID || this.email.id,
            email: emailToLink
          }
        })
      } else if (this.isAdminModule) {
        this.$router.push({
          name: 'AdminContactOverview',
          query: {
            linkMail: this.email.objectID || this.email.id,
            email: emailToLink
          }
        })
      } else if (this.isAssetModule) {
        this.$router.push({
          name: 'AssetContactOverview',
          query: {
            linkMail: this.email.objectID || this.email.id,
            email: emailToLink
          }
        })
      }
    },
    linkToTicket() {
      const query = {
        nameLinked: this.getMailConnectedWithContacts(this.email) ? this.getMailConnectedWithContacts(this.email).from.name === '' && this.getMailConnectedWithContacts(this.email).from.contactId === null ? this.getMailConnectedWithContacts(this.email).from.email : this.getMailConnectedWithContacts(this.email).from.name : this.email.from.name ? this.email.from.name : this.email.from.email,
        linkEmail: this.email.objectID || this.email.id
      }
      if (this.isOwnerModule) {
        this.$router.push({
          name: 'OwnerTicketOverview',
          query: query
        })
      } else if (this.isMarketingModule) {
        this.$router.push({
          name: 'MarketingTicketOverview',
          query: query
        })
      } else if (this.isAdminModule) {
        this.$router.push({
          name: 'AdminTicketOverview',
          query: query
        })
      } else if (this.isAccountingModule) {
        this.$router.push({
          name: 'AccountingTicketOverview',
          query: query
        })
      } else if (this.isAssetModule) {
        this.$router.push({
          name: 'AssetTicketOverview',
          query: query
        })
      }
    },
    linkToRequest() {
      const mailboxesForCurrentUser = this.mailboxesForCurrentUser.map(x => x.outboundEmail)
      let email = ''
      if (mailboxesForCurrentUser.includes(this.email.from.email)) {
        email = this.email.to[0].email
      } else {
        email = this.email.from.email
      }
      this.$router.push({
        name: 'MarketingObjectsView',
        query: {
          linkMail: this.email.objectID || this.email.id,
          linkEmail: email,
          linkContactId: this.email.referenceIds.find(id => id.startsWith('contact_'))
        }
      })
    },
    showContactDetail(contactId) {
      if (contactId !== null) {
        if (this.isOwnerModule) {
          this.$router.push({
            name: 'OwnerContactDetailsView',
            params: { id: contactId }
          })
        } else if (this.isMarketingModule) {
          this.$router.push({
            name: 'MarketingContactDetailsView',
            params: { id: contactId }
          })
        } else if (this.isAdminModule) {
          this.$router.push({
            name: 'AdminContactDetailsView',
            params: { id: contactId }
          })
        } else if (this.isAccountingModule) {
          this.$router.push({
            name: 'AccountingContactDetailsView',
            params: { id: contactId }
          })
        }
      }
    },
    redirectToCreateOrder() {
      const query = {
        source: this.email ? 'email' : 'ticket'
      }
      if (this.email) {
        query.emailsData = JSON.stringify({ id: this.email.id || this.email.objectID, referenceIds: this.email.referenceIds })
        query.contactIds = this.email.referenceIds.join(',')
      }
        const currentRoute = { name: this.$router.currentRoute.name, params: this.$router.currentRoute.params, query: this.$router.currentRoute.query }
        localStorage.setItem('LS_ROUTE_KEY', JSON.stringify(currentRoute))
        if (this.isOwnerModule) {
          this.$router.push({
            name: 'OwnerCreateOrderView',
            query: query
          }, () => {
          })
        } else if (this.isAdminModule) {
          this.$router.push({
            name: 'AdminCreateOrderView',
            query: query
          }, () => {
          })
        } else if (this.isAssetModule) {
          this.$router.push({
            name: 'AssetCreateOrderView',
            query: query
          }, () => {
          })
        } else if (this.isAccountingModule) {
          this.$router.push({
            name: 'AccountingCreateOrderView',
            query: query
          }, () => {
          })
        }
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(.more-btn button) {
  border-radius: 0.2rem;
  color: #515151;
  font-size: .875rem;
  line-height: 1.5;
  background-color: #fff;
  border: 1px solid #ced4da;
}

p {
  white-space: pre-wrap;
}

.media-block {
  max-width: 33%;
  margin-right: 0.5%;
}

.close-img {
  width: 15px;
}
.deliver-status {
  position: absolute;
  right: 0;
  top: 0;
}

.section-to-print {
  opacity: 1;
  background-color: #FFF;
  position: fixed;
  overflow: auto;
  width: 100%;
  height: 100%;
  min-height: 200px;
  top: 0;
  left: 0;
  padding: 15px;
  margin: 0;
  z-index: 1000;
}

.section-to-print-bg {
  background-color: transparent !important;
}

:deep(.ticket-dropdown-bulk-action button) {
  border: 0;
}

.linkTicket {
  cursor: pointer;
  color: #007bff !important;
}
</style>
