<template>
  <section>
    <div
      v-if="settingsLoading"
      class="col-12 text-center">
      <coozzy-spinner class="m-3" />
    </div>
    <div
      v-else
      class="row">
      <div class="col-12 custumeTabs mb-3">
        <b-tabs
          v-model="tabIndex"
          lazy>
          <b-tab
            v-if="isCompanyAdmin">
            <template #title>
              <router-link
                :to="getSettingRoute(0)">
                <span>{{ $t('message.settings.general') }}</span>
              </router-link>
            </template>
            <company-profile-settings v-if="isCompanyAccount || isPlatformEmployee" />
            <individual-profile-settings v-else />
            <edit-region-settings />
          </b-tab>
          <b-tab
            v-if="(hasAccessMarketBase || isPlatformEmployee) && isEditor">
            <template #title>
              <router-link
                :to="getSettingRoute(1)">
                <span>MarketBase</span>
              </router-link>
            </template>
            <export-settings />
            <application-form-settings />
          </b-tab>
          <b-tab>
            <template #title>
              <router-link
                :to="getSettingRoute(2)">
                <span>{{ $t('message.settings.employees') }}</span>
              </router-link>
            </template>
            <employee-settings v-if="isEditor" />
            <edit-password-settings />
          </b-tab>
          <b-tab
            v-if="isEditor || (isCompanyAdmin && (hasAccessPropBase || hasAccessMarketBase || hasAccessAdminBase || hasAccessAssetBase || hasAccessAccountingBase || isPlatformEmployee))">
            <template #title>
              <router-link
                :to="getSettingRoute(3)">
                <span>{{ $t('message.settings.messages') }}</span>
              </router-link>
            </template>
            <domain-settings
              v-if="(isCompanyAdmin && (hasAccessPropBase || hasAccessMarketBase || hasAccessAdminBase || hasAccessAssetBase || hasAccessAccountingBase || isPlatformEmployee))"
              @domain-deleted="refreshMailboxes" />
            <mailbox-settings
              v-if="isEditor"
              ref="mailboxSettings" />
            <confidential-email-list />
            <email-font-settings
              v-if="(isCompanyAdmin && (hasAccessPropBase || hasAccessMarketBase || hasAccessAdminBase || hasAccessAssetBase || hasAccessAccountingBase || isPlatformEmployee))" />
            <signature-settings
              v-if="(isCompanyAdmin && (hasAccessPropBase || hasAccessMarketBase || hasAccessAdminBase || hasAccessAssetBase || hasAccessAccountingBase || isPlatformEmployee))" />
            <sender-email-settings
              v-if="(isCompanyAdmin && isTicketEnabled && (hasAccessPropBase || hasAccessMarketBase || hasAccessAdminBase || hasAccessAssetBase || hasAccessAccountingBase || isPlatformEmployee))" />
          </b-tab>
          <b-tab
            v-if="isEditor && (isCompanyAdmin || hasManageTemplatesRole) && !hasAccessUserBase">
            <template #title>
              <router-link
                :to="getSettingRoute(4)">
                <span>{{ $t('message.settings.templates') }}</span>
              </router-link>
            </template>
            <template-settings />
            <letter-template-settings />
            <ticket-template-settings v-if="isTicketEnabled" />
            <deep-sign-template-settings v-if="deepSignEnabled" />
            <export-template-settings />
            <contract-template-settings />
          </b-tab>
          <b-tab
            v-if="isCompanyAdmin && isAdminModule && isTicketEnabled">
            <template #title>
              <router-link
                :to="getSettingRoute(5)">
                <span>{{ $t('message.settings.ticketing.title') }}</span>
              </router-link>
            </template>
            <ticketing-settings />
          </b-tab>
          <b-tab
            v-if="isTicketEnabled">
            <template #title>
              <router-link
                :to="getSettingRoute(6)">
                <span>{{ $t('message.settings.notifications') }}</span>
              </router-link>
            </template>
            <notifications-settings />
          </b-tab>
          <b-tab
            v-if="isCompanyAdmin">
            <template #title>
              <router-link
                :to="getSettingRoute(7)">
                <span>{{ $t('message.settings.deepsign.integration') }}</span>
              </router-link>
            </template>
            <integrations-settings />
          </b-tab>
          <b-tab
            v-if="isCompanyAdmin">
            <template #title>
              <router-link
                :to="getSettingRoute(8)">
                <span>{{ $t('message.settings.synchronisation.title') }}</span>
              </router-link>
            </template>
            <synchronisation-settings />
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </section>
</template>

<script>
import DomainSettings from './domains/DomainSettings'
import CompanyProfileSettings from './components/CompanyProfileSettings'
import EditRegionSettings from './components/EditRegionSettings'
import EditPasswordSettings from './components/EditPasswordSettings'
import EmployeeSettings from './components/EmployeeSettings'
import ExportSettings from './marketing/ExportSettings'
import MailboxSettings from './mailBox/MailboxSettings'
import SignatureSettings from './components/SignatureSettings'
import senderEmailSettings from './components/SenderEmailSettings'
import TemplateSettings from './components/TemplateSettings'
import ContractTemplateSettings from '@/settings/components/ContractTemplateSettings'
import { user } from '@/mixins/user'
import IndividualProfileSettings from '@/settings/components/IndividualProfileSettings'
import ApplicationFormSettings from '@/settings/marketing/ApplicationFormSettings'
import NotificationsSettings from '@/settings/components/NotificationsSettings'
import TicketApi from '@/misc/apis/TicketApi'
import { deepSign } from '@/mixins/deepSign'
import { routeChecks } from '@/mixins/routeChecks'
import ExportTemplateSettings from '@/settings/components/ExportTemplateSettings'
import LetterTemplateSettings from '@/settings/components/LetterTemplateSettings.vue'
import TicketTemplateSettings from '@/settings/components/TicketTemplateSettings.vue'
import DeepSignTemplateSettings from '@/settings/components/DeepSignTemplateSettings'
import ConfidentialEmailList from '@/settings/components/ConfidentialEmailList.vue'
import TicketingSettings from '@/settings/components/TicketingSettings.vue'
import EmailFontSettings from '@/settings/components/EmailFontSettings.vue'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner.vue'
import SynchronisationSettings from '@/settings/components/SynchronisationSettings.vue'
import IntegrationsSettings from '@/settings/components/IntegrationsSettings.vue'

export default {
  name: 'SettingsView',
  components: {
    IntegrationsSettings,
    SynchronisationSettings,
    CoozzySpinner,
    TicketingSettings,
    ConfidentialEmailList,
    TicketTemplateSettings,
    DeepSignTemplateSettings,
    LetterTemplateSettings,
    NotificationsSettings,
    ApplicationFormSettings,
    IndividualProfileSettings,
    ContractTemplateSettings,
    DomainSettings,
    TemplateSettings,
    EmailFontSettings,
    SignatureSettings,
    MailboxSettings,
    ExportSettings,
    EmployeeSettings,
    EditPasswordSettings,
    EditRegionSettings,
    CompanyProfileSettings,
    ExportTemplateSettings,
    senderEmailSettings
  },
  mixins: [user, deepSign, routeChecks],
  props: {
    view: {
      type: String,
      default: '0'
    }
  },
  metaInfo() {
    return {
      title: this.$t('message.navigation.settings.title')
    }
  },
  data: () => {
    return {
      tabIndex: 0,
      isTicketEnabled: false,
      forceUpdate: false,
      initView: 0,
      settingsLoading: true
    }
  },
  watch: {
    tabIndex: function (newVal, oldVal) {
      if ((!this.$route.query || parseInt(this.$route.query.view || 0) !== parseInt(newVal)) && this.forceUpdate) {
        if (this.$route.name === 'MarketingSettingsView') {
          this.$router.replace({ name: 'MarketingSettingsView', query: { view: '' + newVal } })
        } else if (this.$route.name === 'AdminSettingsView') {
          this.$router.replace({ name: 'AdminSettingsView', query: { view: '' + newVal } })
        } else if (this.$route.name === 'AssetSettingsView') {
          this.$router.replace({ name: 'AssetSettingsView', query: { view: '' + newVal } })
        } else if (this.$route.name === 'AccountingSettingsView') {
          this.$router.replace({ name: 'AccountingSettingsView', query: { view: '' + newVal } })
        } else {
          this.$router.replace({ name: 'OwnerSettingsView', query: { view: '' + newVal } })
        }
      }
    }
  },
  mounted() {
    TicketApi.hasTicketingEnabled(this.accountId)
      .then(response => {
        this.isTicketEnabled = response.enabled
        this.forceUpdate = true
        this.initView = Number(this.view)
        setTimeout(() => {
          this.tabIndex = this.initView
          this.settingsLoading = false
        }, 700)
      })
  },
  methods: {
    refreshMailboxes() {
      this.$refs.mailboxSettings.getMailBoxList()
    },
    getSettingRoute(index) {
      if (this.isOwnerModule) {
        return { name: 'OwnerSettingsView', query: { view: '' + index } }
      } else if (this.isAdminModule) {
        return { name: 'AdminSettingsView', query: { view: '' + index } }
      } else if (this.isAssetModule) {
        return { name: 'AssetSettingsView', query: { view: '' + index } }
      } else if (this.isAccountingModule) {
        return { name: 'AccountingSettingsView', query: { view: '' + index } }
      } else {
        return { name: 'MarketingSettingsView', query: { view: '' + index } }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  :deep(.tabs .nav-tabs .nav-link a.router-link-active), :deep(.tabs .nav-tabs .nav-link a) {
    color: inherit !important;
    text-decoration: none !important;
  }
  :deep(.custumeTabs .tabs .nav-tabs .nav-item[role=presentation]) {
    margin-right: 0.2rem;
  }
  :deep(.custumeTabs .tabs .nav-tabs .nav-item > a.nav-link) {
    margin-right: 0;
    padding: 0;
    box-shadow: none;
    border: 0;
    z-index: -1;
    height: 26.5px;
  }
  :deep(.custumeTabs .tabs .nav-tabs .nav-item[role=presentation] .nav-link.active > a) {
    font-weight: bold;
    background-color: #fff;
  }
  :deep(.custumeTabs .tabs .nav-tabs .nav-item[role=presentation] .nav-link:hover > a) {
    font-weight: bold;
    cursor: pointer;
  }
  :deep(.custumeTabs .tabs .nav-tabs .nav-item[role=presentation] .nav-link > a) {
    color: rgb(0, 68, 95) !important;
    border-color: #c8ced3 !important;
    border-radius: 0 !important;
    border-bottom: 0 !important;
    margin-right: 0;
    padding: 0.55rem 1.5rem;
    font-weight: 300;
    -webkit-box-shadow: inset 0 16px 20px -15px rgb(238 238 238), 3px 0 2px -2px #ccc;
    box-shadow: inset 0 16px 20px -15px rgb(238 238 238), 3px 0 2px -2px #ccc;
    cursor: default;
    border: 1px solid transparent;
    height: 36.5px;
  }
  :deep(.card.shadow) {
    box-shadow: none !important;
  }
  .tab-content>.active {
    background: #fff;
    position: relative;
  }
</style>
