<template>
  <section>
    <div class="col">
      <div
        v-if="contact"
        class="row mt-2">
        <div class="col-12 col-md-5">
          <div class="row">
            <div class="col-12 col-md-12">
              <div
                v-if="loading"
                class="p-0 text-center">
                <coozzy-spinner class="m-3" />
              </div>
              <div v-else>
                <coozzy-detail-card
                  v-for="contactItem in sortedContactsList"
                  :key="contactItem.id"
                  :title="$tc('message.contact.title')"
                  :is-editable="true"
                  :is-linked="contactsList.length > 1"
                  :is-data-differente="isSomeDataDifferent"
                  :text-updated="textUpdated"
                  @onAccept="trigger = trigger + 1; isSomeDataDifferent = false"
                  @onEdit="editContactClicked(contactItem)"
                  @onUnlink="openUnlinkModal(contactItem)">
                  <contact-detail-card
                    v-if="contactItem"
                    :ref="'contact-detail-card-' + contactItem.id"
                    :key="randomKey"
                    :contact="contactItem"
                    :application-form-contact="getApplicationFormContact(contactItem)"
                    :mail-reference-ids="[request.id]"
                    :must-change-data="trigger"
                    @is-some-data-different="isSomeDataDifferentAction"
                    @application-form-applied="applicationForm = null"
                    @contact-updated="editContact"
                    @contact-edited="contactEdited" />
                </coozzy-detail-card>
              </div>
            </div>
            <div
              v-if="countContactPerson !== 0 && typeOfContact === 'company'"
              class="col-12 col-md-12">
              <coozzy-detail-card
                :title="$t('message.tableColumnHeadings.contactPersons') + ' (' + countContactPerson +')'">
                <div class="col p-0">
                  <b-table
                    ref="contactPersonTable"
                    bordered
                    hover
                    class="m-0"
                    :busy="loading"
                    :fields="fieldsContactPersons"
                    :items="countContactPerson <= 5 || showAllContacts ? contact.contactPersons : contact.contactPersons.slice(0, 5)">
                    <!-- Busy state -->
                    <div
                      slot="table-busy"
                      class="text-center text-danger my-2">
                      <coozzy-spinner />
                    </div>

                    <!-- Headings -->
                    <template
                      #head(name)>
                      {{ $t('message.generic.name') }}
                    </template>
                    <template
                      #head(phoneNumber)>
                      {{ $t('message.generic.phoneNumber') }}
                    </template>
                    <template
                      #head(email)>
                      {{ $t('message.generic.email') }}
                    </template>

                    <!-- Columns -->
                    <template
                      #cell(name)="data">
                      <template>
                        <template v-if="data.item.gender === 'MALE'">
                          {{ $t('message.generic.title.mr') }}
                        </template>
                        <template v-if="data.item.gender === 'FEMALE'">
                          {{ $t('message.generic.title.mrs') }}
                        </template>
                        <template v-if="data.item.gender === 'OTHER'">
                          {{ $t('message.generic.title.divers') }}
                        </template>
                      </template>
                      <template v-if="data.item.title && data.item.title !== ''">
                        {{ data.item.title }}
                      </template>
                      {{ data.item.name | displayOptionalValue }}
                      <div v-if="data.item.employment && data.item.employment.companyPosition !== ''">
                        {{ data.item.employment.companyPosition }}
                      </div>
                    </template>
                    <template
                      #cell(phoneNumber)="data">
                      <div
                        v-if="getPhoneNumbers(data.item.phoneNumbers, 'WORK') !== '-'"
                        class="row"
                        :class="[isDeath(data.item.dateOfDeath) ? 'text-gray' : '']">
                        <div class="col-1">
                          <coozzy-phone-icon />
                        </div>
                        <div class="col">
                          <a
                            class="link"
                            :href="'tel:' + getPhoneNumbers(data.item.phoneNumbers, 'WORK')">
                            {{ getPhoneNumbers(data.item.phoneNumbers, 'WORK') }}
                          </a>
                        </div>
                      </div>
                      <div
                        v-if="getPhoneNumbers(data.item.phoneNumbers, 'MOBILE') !== '-'"
                        class="row"
                        :class="[isDeath(data.item.dateOfDeath) ? 'text-gray' : '']">
                        <div class="col-1">
                          <coozzy-mobile-alt-icon />
                        </div>
                        <div class="col">
                          <a
                            class="link"
                            :href="'tel:' + getPhoneNumbers(data.item.phoneNumbers, 'MOBILE')">
                            {{ getPhoneNumbers(data.item.phoneNumbers, 'MOBILE') }}
                          </a>
                        </div>
                      </div>
                      <div
                        v-if="getPhoneNumbers(data.item.phoneNumbers, 'HOME') !== '-'"
                        class="row"
                        :class="[isDeath(data.item.dateOfDeath) ? 'text-gray' : '']">
                        <div class="col-1">
                          <coozzy-phone-icon />
                        </div>
                        <div class="col">
                          <a
                            class="link"
                            :href="'tel:' + getPhoneNumbers(data.item.phoneNumbers, 'HOME')">
                            {{ getPhoneNumbers(data.item.phoneNumbers, 'HOME') }}
                          </a>
                        </div>
                      </div>
                      <div
                        v-if="getPhoneNumbers(data.item.phoneNumbers, 'FAX') !== '-'"
                        class="row"
                        :class="[isDeath(data.item.dateOfDeath) ? 'text-gray' : '']">
                        <div class="col-1">
                          <coozzy-fax-icon />
                        </div>
                        <div class="col">
                          <a
                            class="link"
                            :href="'tel:' + getPhoneNumbers(data.item.phoneNumbers, 'FAX')">
                            {{ getPhoneNumbers(data.item.phoneNumbers, 'FAX') }}
                          </a>
                        </div>
                      </div>
                    </template>
                    <template
                      #cell(email)="data">
                      <div class="row">
                        <div class="col-1">
                          <img
                            class="email-icon-img"
                            src="@/assets/icon/arroba.svg"
                            alt="Placeholder">
                        </div>
                        <div class="col">
                          <a
                            class="link"
                            :href="'mailto:' + data.item.email">{{ data.item.email | displayOptionalValue }}</a>
                        </div>
                      </div>
                    </template>
                    <template
                      #cell(action)="data">
                      <coozzy-button
                        class="col-1"
                        design="transparent"
                        @click="createContactPersonClicked(data.item)">
                        <coozzy-edit-icon />
                      </coozzy-button>
                    </template>
                  </b-table>
                  <a
                    v-if="countContactPerson > 5"
                    class="link ml-2"
                    @click="showAllContacts = !showAllContacts">{{ showAllContacts === true ?
                      $t('message.generic.showLess') : $t('message.generic.showAll') }}</a>
                </div>
              </coozzy-detail-card>
            </div>
            <div
              v-if="request"
              class="col-12 col-md-12">
              <coozzy-detail-card
                :title="$t('message.onBoarding.interestedParties.checklist.title') + ' (' + countChecklist +'/'+ Object.keys(request.checklist).length +')'">
                <div class="col">
                  <div class="row">
                    <div class="col-4 col-md-4 align-middle format-checklist-item p-0">
                      <coozzy-button
                        :design="request.checklist.viewingAppointmentContactShared ? 'light-green' : 'default'"
                        class="p-1 w-100 m-0"
                        @click="toggleState('viewingAppointmentContactShared')">
                        {{ $t('message.onBoarding.interestedParties.checklist.viewingAppointmentContactShared') }}
                      </coozzy-button>
                    </div>
                    <div class="col-4 col-md-4 align-middle format-checklist-item p-0">
                      <coozzy-button
                        :design="request.checklist.viewingAppointmentArranged ? 'light-green' : 'default'"
                        class="p-1 w-100 m-0"
                        @click="toggleState('viewingAppointmentArranged')">
                        {{ $t('message.onBoarding.interestedParties.checklist.viewingAppointmentArranged') }}
                      </coozzy-button>
                    </div>
                    <div class="col-4 col-md-4 align-middle format-checklist-item p-0">
                      <coozzy-button
                        :design="request.checklist.viewingAppointmentDone ? 'light-green' : 'default'"
                        class="p-1 w-100 m-0"
                        @click="toggleState('viewingAppointmentDone')">
                        {{ $t('message.onBoarding.interestedParties.checklist.viewingAppointmentDone') }}
                      </coozzy-button>
                    </div>
                    <div class="col-4 col-md-4 align-middle format-checklist-item p-0">
                      <coozzy-button
                        :design="request.checklist.applicationFormRequested ? 'light-green' : 'default'"
                        class="p-1 w-100 m-0"
                        @click="toggleState('applicationFormRequested')">
                        {{ $t('message.onBoarding.interestedParties.checklist.applicationFormRequested') }}
                      </coozzy-button>
                    </div>
                    <div class="col-4 col-md-4 align-middle format-checklist-item p-0">
                      <coozzy-button
                        :design="request.checklist.applicationFormReceived ? 'light-green' : 'default'"
                        class="p-1 w-100 m-0"
                        @click="toggleState('applicationFormReceived')">
                        {{ $t('message.onBoarding.interestedParties.checklist.applicationFormReceived') }}
                      </coozzy-button>
                    </div>
                    <div class="col-4 col-md-4 align-middle format-checklist-item p-0">
                      <coozzy-button
                        :design="request.checklist.referencesObtained ? 'light-green' : 'default'"
                        class="p-1 w-100 m-0"
                        @click="toggleState('referencesObtained')">
                        {{ $t('message.onBoarding.interestedParties.checklist.referencesObtained') }}
                      </coozzy-button>
                    </div>
                    <div class="col-4 col-md-4 align-middle format-checklist-item p-0">
                      <coozzy-button
                        :design="request.checklist.creditCheckDone ? 'light-green' : 'default'"
                        class="p-1 w-100 m-0"
                        @click="toggleState('creditCheckDone')">
                        {{ $t('message.onBoarding.interestedParties.checklist.creditCheckDone') }}
                      </coozzy-button>
                    </div>
                    <div class="col-4 col-md-4 align-middle format-checklist-item p-0">
                      <coozzy-button
                        :design="request.checklist.contractSent ? 'light-green' : 'default'"
                        class="p-1 w-100 m-0"
                        @click="toggleState('contractSent')">
                        {{ $t('message.onBoarding.interestedParties.checklist.contractSent') }}
                      </coozzy-button>
                    </div>
                    <div class="col-4 col-md-4 align-middle format-checklist-item p-0">
                      <coozzy-button
                        :design="request.checklist.contractReceived ? 'light-green' : 'default'"
                        class="p-1 w-100 m-0"
                        @click="toggleState('contractReceived')">
                        {{ $t('message.onBoarding.interestedParties.checklist.contractReceived') }}
                      </coozzy-button>
                    </div>
                  </div>
                </div>
              </coozzy-detail-card>
            </div>
            <div
              v-if="countDocument !== 0"
              class="col-12 col-md-12">
              <coozzy-detail-card
                :title="$t('message.generic.documents') + ' (' + countDocument +')'"
                :download-all="true"
                @download-all-documents="downloadContactDocuments($event)">
                <div class="col p-0">
                  <document
                    v-for="doc in (countDocument <= 5 || showAllDocuments ? listDocuments : listDocuments.slice(0,5))"
                    :key="doc.id"
                    :document="doc"
                    class="no-lower-height"
                    :owner="getDocumentOwner(doc.id)"
                    @downloaded="documentDownloaded"
                    @version-added="versionAdded"
                    @delete-document="deleteDocument" />
                  <a
                    v-if="countDocument > 5"
                    class="link ml-2"
                    @click="showAllDocuments = !showAllDocuments">
                    {{ showAllDocuments === true ? $t('message.generic.showLess') : $t('message.generic.showAll') }}
                  </a>
                </div>
              </coozzy-detail-card>
            </div>
            <div
              v-if="listRentalDocuments.length !== 0"
              class="col-12 col-md-12">
              <coozzy-detail-card
                :title="$t('message.generic.rentalDocuments') + ' (' + listRentalDocuments.length +')'"
                :download-all="true"
                @download-all-documents="downloadRentalDocuments($event)">
                <div class="col p-0">
                  <document
                    v-for="doc in (listRentalDocuments.length <= 5 || showAllRentalDocuments ? listRentalDocuments : listRentalDocuments.slice(0,5))"
                    :key="doc.id"
                    class="no-lower-height"
                    :document="doc"
                    :is-read-only="true"
                    @downloaded="rentalDocumentDownloaded" />
                  <div class="text-center">
                    <a
                      v-if="listRentalDocuments.length > 5"
                      class="col"
                      @click="showAllRentalDocuments = !showAllRentalDocuments">
                      <coozzy-angle-double-up-icon v-if="showAllRentalDocuments === true" />
                      <coozzy-angle-double-down-icon v-else />
                    </a>
                  </div>
                </div>
              </coozzy-detail-card>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-7">
          <div class="row">
            <div
              v-if="$route.name !== 'OwnerContactDetailsView'"
              class="col-12 col-md-12">
              <coozzy-detail-card
                :title="$t('message.contact.actions.title')">
                <div class="col-12 col-md-12 mt-3 d-flex flex-wrap">
                  <coozzy-button
                    class="mr-3 mb-3"
                    @click="openSendMailModal('request-application-form')">
                    <coozzy-email-icon class="mb-1" />
                    <span class="ml-1">{{ $t('message.contact.actions.requestApplicationForm') }} </span>
                  </coozzy-button>

                  <coozzy-button
                    class="mr-3 mb-3"
                    @click="openSendMailModal()">
                    <img
                      class="email-icon-img"
                      src="@/assets/icon/arroba.svg"
                      alt="Placeholder">
                    <span class="ml-1">{{ $t('message.contact.actions.email') }} </span>
                  </coozzy-button>

                  <coozzy-button
                    class="mr-3 mb-3"
                    @click="createEventClicked()">
                    <coozzy-calendar-check-icon class="mb-1" />
                    <span class="ml-1">{{ $t('message.contact.actions.event') }} </span>
                  </coozzy-button>

                  <coozzy-button
                    class="mr-3 mb-3"
                    @click="createReminderClicked(null)">
                    <coozzy-reminder-icon class="mb-1" />
                    <span class="ml-1">{{ $t('message.contact.actions.reminder') }} </span>
                  </coozzy-button>

                  <coozzy-button
                    class="mr-3 mb-3"
                    @click="editActivity(null)">
                    <coozzy-activity-icon class="mb-1" />
                    <span class="ml-1">{{ $t('message.contact.actions.activity') }} </span>
                  </coozzy-button>
                  <coozzy-button
                    v-if="isTicketingEnabled"
                    class="mr-3 mb-3"
                    @click="createTicket">
                    <coozzy-ticket-icon
                      class="mb-1"
                      name="interestedParties"
                      :active="true" />
                    <span class="ml-1">{{ $t('message.ticketOverview.createTicket') }}</span>
                  </coozzy-button>
                  <coozzy-button
                    class="mr-3 mb-3"
                    @click="addDocumentClicked('contactDocument')">
                    <coozzy-document-icon class="mb-1" />
                    <span class="ml-1">{{ $t('message.contact.actions.document') }} </span>
                  </coozzy-button>

                  <coozzy-button
                    v-if="!('contactPersons' in contact)"
                    class="mr-3 mb-3"
                    :design="isReferenceFinished(request.requesters, 'employee') !== false ? 'prop-green' : 'default'"
                    @click="openReferenceModal('employer')">
                    <coozzy-contact-person-icon
                      :default-color="isReferenceFinished(request.requesters, 'employee')!==false"
                      class="mb-1" />
                    <span class="ml-1">{{ $t('message.interestedParty.reference.employer.title') }} </span>
                  </coozzy-button>

                  <coozzy-button
                    class="mr-3 mb-3"
                    :design="isReferenceFinished(request.requesters, 'landlord')? 'prop-green' : 'default'"
                    @click="openReferenceModal('landlord')">
                    <coozzy-contact-person-icon
                      :default-color="isReferenceFinished(request.requesters, 'landlord')!==false"
                      class="mb-1" />
                    <span class="ml-1">{{ $t('message.interestedParty.reference.landlord.title') }} </span>
                  </coozzy-button>

                  <coozzy-button
                    class="mr-3 mb-3"
                    @click="setRequestsFavorite()">
                    <coozzy-favorite-icon
                      :statefavorite="favoriteState"
                      class="mb-1" />
                    <span class="ml-1">{{ $t('message.interestedParty.action.addFavorite') }} </span>
                  </coozzy-button>
                  <coozzy-button
                    class=" mr-3 mb-3"
                    @click="addRequesterClicked()">
                    <coozzy-contact-person-icon class="mb-1" />
                    <span class="pb-1 ml-1">{{ $t('message.contact.actions.contact') }} </span>
                  </coozzy-button>
                  <template>
                    <coozzy-button
                      :disabled="request.tenancyId !== '' || listRequest.filter(req => req.id !== request.id && req.tenancyId !== '').length > 0 || isReserved"
                      class="mr-3 mb-3"
                      @click="addAsTenantClicked()">
                      <coozzy-activity-icon class="mb-1" />
                      <span
                        class="ml-1">
                        {{ $t('message.interestedParty.action.addAsTenant') }}
                      </span>
                    </coozzy-button>
                  </template>
                  <!--                  <template>-->
                  <!--                    <coozzy-button-->
                  <!--                      :disabled="request.tenancyId !== '' || listRequest.filter(req => req.id !== request.id && req.tenancyId !== '').length > 0"-->
                  <!--                      class="mr-3 mb-3"-->
                  <!--                      @click="createMassRental()">-->
                  <!--                      <coozzy-activity-icon class="mb-1" />-->
                  <!--                      <span-->
                  <!--                        class="ml-1">-->
                  <!--                        {{ $t('message.massRental.title') }}-->
                  <!--                      </span>-->
                  <!--                    </coozzy-button>-->
                  <!--                  </template>-->
                  <template v-if="listRequest && listRequest.filter(req => req.id !== request.id && req.tenancyId !== '').length === 0 && request.tenancyId">
                    <coozzy-button
                      class="mr-3 mb-3"
                      :disabled="request.tenancyId !== '' && isTenancyActivated"
                      @click="addAsTenantClicked()">
                      <coozzy-activity-icon class="mb-1" />
                      <span
                        class="ml-1">
                        {{ $t('message.interestedParty.action.editTenancy') }}
                      </span>
                    </coozzy-button>
                  </template>
                  <coozzy-button
                    v-if="hasDigitalSignatureRole && deepSignEnabled"
                    :disabled="request.tenancyId === ''"
                    class="mr-3 mb-3"
                    @click="showDigitalSignatureModalEmpty()">
                    <coozzy-deep-sign-icon
                      name="deepsign"
                      :active="false" />
                    <span
                      class="ml-1">
                      {{ $t('message.deepSign.titleAction') }}
                    </span>
                  </coozzy-button>
                  <coozzy-button
                    v-if="tenancy && hasDigitalDepositRole && digitalDepositEnabled && isAllTenantsIndividualContacts && tenancyStatusDigitalDeposit"
                    :disabled="request.tenancyId === ''"
                    class="mr-3 mb-3"
                    @click="showDigitalDepositModal()">
                    <coozzy-evorest-icon
                      name="evorest"
                      :active="false" />
                    <span
                      class="ml-1">
                      {{ $t('message.digitalDeposit.title') }}
                    </span>
                  </coozzy-button>
                  <coozzy-button
                    :disabled="request.tenancyId && isTenancyActivated"
                    class="mr-3 mb-3"
                    @click="activateTenancyClicked()">
                    <img
                      width="20px"
                      height="20px"
                      src="@/assets/icon/activate-tenancy-house.svg"
                      :alt="$t('message.interestedParty.action.activateTenancy')">
                    <span
                      class="ml-1">
                      {{ $t('message.interestedParty.action.activateTenancy') }}
                    </span>
                  </coozzy-button>
                  <coozzy-button
                    v-if="request.tenancyId && !isTenancyActivated"
                    class="mr-3 mb-3"
                    @click="deleteTenancyClicked()">
                    <img
                      width="20px"
                      height="20px"
                      src="@/assets/icon/cancel-house.svg"
                      :alt="$t('message.interestedParty.action.deleteTenancy')">
                    <span
                      class="ml-1">
                      {{ $t('message.interestedParty.action.deleteTenancy') }}
                    </span>
                  </coozzy-button>
                  <span
                    v-b-tooltip.hover.html="request.tenancyId !== '' && isTenancyActivated && $t('message.onBoarding.interestedParties.moveRequest.tootltip')">
                    <coozzy-button
                      :id="'btn_move_request-'+ request.tenancyId"
                      :ref="'btn_move_request-'+ request.tenancyId"
                      class="mr-3 mb-3"
                      :disabled="request.tenancyId !== '' && isTenancyActivated"
                      @click="moveRequest">
                      <img
                        width="20px"
                        height="20px"
                        src="@/assets/icon/swap.svg"
                        :alt="$t('message.generic.moveRequest')">
                      <span class="ml-1">{{ $t('message.generic.moveRequest') }} </span>
                    </coozzy-button>
                  </span>
                  <coozzy-button
                    v-if="!request.rejected"
                    :disabled="request.tenancyId !== ''"
                    class="mr-3 mb-3"
                    @click="reject">
                    <coozzy-reject-icon
                      :default-color="true"
                      class="mb-1" />
                    <span class="ml-1">{{ $t('message.generic.reject') }} </span>
                  </coozzy-button>
                  <coozzy-button
                    v-else
                    :disabled="request.tenancyId !== ''"
                    class="mr-3 mb-3"
                    @click="reactivate">
                    <coozzy-reactivate-icon
                      :default-color="true"
                      class="mb-1" />
                    <span class="ml-1">{{ $t('message.generic.reactivate') }} </span>
                  </coozzy-button>
                  <coozzy-button
                    :disabled="request.tenancyId !== ''"
                    class="mr-3 mb-3"
                    @click="$bvModal.show('confirmationModalRequest')">
                    <coozzy-delete-icon
                      :default-color="true"
                      class="mb-1" />
                    <span class="ml-1">{{ $t('message.onBoarding.interestedParties.deleteConfirmation.title') }} </span>
                  </coozzy-button>
                  <coozzy-button
                    v-if="typeOfContact === 'company'"
                    class=" mr-3 mb-3"
                    @click="createContactPersonClicked(null)">
                    <coozzy-contact-person-icon class="mb-1" />
                    <span class="pb-1 ml-1">{{ $t('message.contact.actions.contactPerson') }} </span>
                  </coozzy-button>
                </div>
              </coozzy-detail-card>
            </div>
            <div
              v-if="countSignRequests !== 0"
              class="col-12 col-md-12">
              <coozzy-detail-card
                :title="$t('message.deepSign.title') + ' (' + countSignRequests + ')'">
                <div class="col p-0">
                  <b-table
                    ref="signRequestTable"
                    bordered
                    hover
                    class="m-0"
                    :busy="isBusySignature"
                    :fields="fieldsSignature"
                    :items="countSignRequests <= 5 || showAllSignatures ? signatureRequests : signatureRequests.slice(0,5)"
                    @row-hovered="mouseEnterEvents($event)"
                    @row-unhovered="mouseLeaveEvents($event)"
                    @row-clicked="onRowClicked">
                    <!-- Busy state -->
                    <div
                      slot="table-busy"
                      class="text-center text-danger my-2">
                      <coozzy-spinner />
                    </div>

                    <!-- Headings -->
                    <template #head(created)>
                      {{ $t('message.deepSign.created') }}
                    </template>
                    <template #head(contacts)>
                      {{ $t('message.deepSign.contacts') }}
                    </template>
                    <template #head(documentType)>
                      {{ $t('message.deepSign.documentType') }}
                    </template>
                    <template #head(signers)>
                      {{ $t('message.deepSign.signers') }}
                    </template>
                    <template #head(status)>
                      {{ $t('message.deepSign.status.title') }}
                    </template>

                    <!-- Data cells -->
                    <template
                      #cell(created)="data">
                      {{ data.item.created / 1000 | timestampToDate }}
                    </template>
                    <!-- name column -->
                    <template
                      #cell(contacts)="data">
                      <template v-for="item in data.item.signers">
                        <p
                          :key="item.contactId"
                          class="mb-0">
                          <a
                            class="link contactName"
                            @click="redirectToDetailPage(item.contactId)"
                            @click.middle="redirectToDetailPage(item.contactId, true)"
                            @mousedown.middle.prevent.stop>
                            {{ getSignerName(item) }}
                          </a>
                        </p>
                      </template>
                    </template>
                    <template
                      #cell(documentType)="data">
                      {{
                        data.item.documentType ? $t('message.manageDocuments.type.' + data.item.documentType.toLowerCase()) : '-'
                      }}
                    </template>
                    <!-- signers name  -->
                    <template
                      #cell(signers)="data">
                      <div
                        id="link-button"
                        class="fieldToShow d-inline"
                        @mousemove="onMouseMove($event)"
                        @mouseleave="showPopover = false">
                        {{ data.item.signers.filter(signer => signer.signed).length }}/{{ data.item.signers.length }}
                      </div>
                    </template>
                    <!-- contactPerson Phone -->
                    <template
                      #cell(status)="data">
                      {{ $t('message.deepSign.status.' + getStatus(data.item)) }}
                    </template>
                  </b-table>
                  <div class="text-center">
                    <a
                      v-if="countSignRequests > 5"
                      class="link"
                      @click="showAllSignatures = !showAllSignatures">
                      <coozzy-angle-double-up-icon v-if="showAllSignatures === true" />
                      <coozzy-angle-double-down-icon v-else />
                    </a>
                  </div>
                  <div
                    v-if="showPopover"
                    id="eventPopover"
                    class="eventPopoverSignRequest"
                    :style="{ left: page.left + 'px', top: page.top + 'px' }">
                    <div
                      v-if="selectedPopOverEvent"
                      class="row">
                      <div class="col employees-calendar">
                        <div class="row">
                          <div
                            v-for="item in selectedPopOverEvent.signers"
                            :key="item.id"
                            class="col-12">
                            {{ getSignerName(item) }}
                            <img
                              v-if="!item.signed"
                              width="15px"
                              height="15px"
                              src="@/assets/icon/hourglass.svg"
                              :alt="'hourglass'">
                            <coozzy-success-icon v-if="item.signed" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </coozzy-detail-card>
            </div>
            <div
              class="col-12 col-md-12">
              <contact-event-card
                v-if="contactsList.length > 0"
                :ref="'contact-event-card'"
                :contacts-list="contactsList"
                :source="'request'"
                :available-attendees="availableAttendees"
                :contact-id="contact.id"
                :contact="contact" />
            </div>
            <div
              v-if="countReminder !== 0"
              class="col-12 col-md-12">
              <coozzy-detail-card
                :title="$t('message.calendar.reminder.title') + ' (' + countReminder + ')'">
                <div class="col p-0">
                  <b-table
                    ref="reminderTable"
                    bordered
                    hover
                    class="m-0"
                    :busy="isBusyReminder"
                    :fields="fieldsReminder"
                    :items="countReminder <= 5 || showAllReminders ? reminderList : reminderList.slice(0,5)"
                    @row-clicked="showReminderDetails">
                    <!-- Headings -->
                    <template
                      #head(date)>
                      {{ $t('message.calendar.reminder.date') }}
                    </template>
                    <template
                      #head(subject)>
                      {{ $t('message.calendar.reminder.subject') }}
                    </template>
                    <template
                      #head(assignee)>
                      {{ $t('message.generic.assignee') }}
                    </template>

                    <!-- Columns -->
                    <template
                      #cell(date)="data">
                      <span :class="[data.item.done !== '0' ? 'text-gray' : '']">
                        {{ data.item.date | timestampToDateTime }}
                      </span>
                    </template>
                    <template
                      #cell(subject)="data">
                      <span :class="[data.item.done !== '0' ? 'text-gray' : '']">
                        {{ data.item.subject | displayOptionalValue }}
                      </span>
                    </template>
                    <template
                      #cell(assignee)="data">
                      <span :class="[data.item.done !== '0' ? 'text-gray' : '']">
                        <employee-name-text
                          :user-id="data.item.employeeUserId" />
                      </span>
                    </template>
                  </b-table>
                  <a
                    v-if="countReminder > 5"
                    class="link ml-2"
                    @click="showAllReminders = !showAllReminders">{{ showAllReminders === true ?
                      $t('message.generic.showLess') : $t('message.generic.showAll') }}</a>
                </div>
              </coozzy-detail-card>
            </div>
            <div
              v-if="countActivity !== 0"
              class="col-12 col-md-12">
              <coozzy-detail-card
                :title="$t('message.ticketOverview.activities.title') + ' (' + countActivity + ')'">
                <div class="col p-0">
                  <b-table
                    ref="activityTable"
                    bordered
                    hover
                    class="m-0"
                    :busy="isBusyActivity"
                    :fields="fieldsActivity"
                    :items="countActivity <= 5 || showAllActivities ? activityList : activityList.slice(0,5)"
                    @row-clicked="editActivity">
                    <!-- Busy state -->
                    <div
                      slot="table-busy"
                      class="text-center text-danger my-2">
                      <coozzy-spinner />
                    </div>

                    <!-- Headings -->
                    <template #head(subject)>
                      {{ $t('message.calendar.reminder.subject') }}
                    </template>
                    <template #head(text)>
                      {{ $t('message.tableColumnHeadings.text') }}
                    </template>
                    <template #head(duration)>
                      {{ $t('message.tableColumnHeadings.duration') }}
                    </template>

                    <!-- Columns -->
                    <template
                      #cell(subject)="data">
                      <p class="m-0 auto-size">
                        <coozzy-paper-clip-icon v-if="data.item.mediaIds.length > 0" />
                        {{ data.item.subject | displayOptionalValue }}
                      </p>
                    </template>
                    <template
                      #cell(text)="data">
                      {{ data.item.text | displayOptionalValue }}
                    </template>
                    <template
                      #cell(duration)="data">
                      {{ getDuration(data.item.timestampStart, data.item.timestampEnd) }}
                    </template>
                  </b-table>
                  <div class="text-center">
                    <a
                      v-if="countActivity > 5"
                      class="link"
                      @click="showAllActivities = !showAllActivities">
                      <coozzy-angle-double-up-icon v-if="showAllActivities === true" />
                      <coozzy-angle-double-down-icon v-else />
                    </a>
                  </div>
                </div>
              </coozzy-detail-card>
            </div>
            <small-messages-table
              v-if="mailList.length > 0 || filterFolder !== 'FILTER_FOLDER_ALL' || hasEmails"
              :mail-list="mailList"
              :total-page-mail-count="totalPageMailCount"
              :total-mails-count="totalMailsCount"
              :has-emails="hasEmails"
              :contact="contact"
              @show-email="showEmail"
              @search-emails="searchEmails" />
          </div>
        </div>
      </div>
    </div>
    <b-modal
      :id="'confirmationModalRequest'"
      ref="confirmation-modal-request"
      no-close-on-backdrop
      :title="$t('message.onBoarding.interestedParties.deleteConfirmation.title')"
      hide-footer
      @ok="deleteRequest()">
      <div class="col pl-0">
        <p>{{ $t('message.onBoarding.interestedParties.deleteConfirmation.text') }}</p>
      </div>
      <div class="col pl-0">
        <coozzy-button
          size="small"
          class="float-left mb-0 border"
          design="transparent"
          @click="$bvModal.hide('confirmationModalRequest')">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
        <coozzy-button
          design="green"
          class="float-right ml-3"
          @click="deleteRequest()">
          {{ $t('message.generic.delete') }}
        </coozzy-button>
      </div>
    </b-modal>
    <create-contact-modal
      v-if="request"
      :key="request.id"
      :for-owner-id="parseInt(request.ownerId)"
      :show-search="!contactToEdit"
      :show-request-sources="false"
      :show-roles-input="false"
      :set-interested-party-role="true"
      :show-add-another="false"
      :contact-to-edit="contactToEdit"
      :enable-delete="false"
      @contact-created="contactCreated"
      @contact-updated="contactUpdated" />
    <message-details-modal
      v-if="selectedEmail"
      ref="detail-mail"
      :key="'detail-mail-' + selectedEmail.id"
      :email="selectedEmail"
      @reply-mail="replyMail"
      @forward-mail="forwardMail"
      @reply-all-mail="replyAllMail"
      @delete-mail="reloadSmallMessagesData"
      @archive-mail="reloadSmallMessagesData"
      @restore-mail="reloadSmallMessagesData" />
    <reference-modal
      ref="modal-reference"
      :key="'modal-reference-'+requestType"
      :reference-type="requestType"
      :request="request"
      :object="object"
      :requester-contacts="contactsList"
      @new-employer-references="newEmployerReferences"
      @new-landlord-references="newLandlordReferences"
      @refresh-documents="refreshDocuments" />
    <reminder-creation-modal
      ref="modal-creation"
      :key="remainderForEdit !== null ? 'modal-creation'+remainderForEdit.id+availableContact.length : 'modal-creation'+availableContact.length"
      :reminder-detail="remainderForEdit"
      :available-contacts="contactsListReminder"
      :contact-id="contact.id"
      source="request"
      :available-employees="availableEmployees"
      :references-ids="[request.id]"
      @new-reminder="afterAction('reminder')"
      @update-reminder="afterAction('reminder')" />
    <reminder-details-modal
      v-if="selectedReminder !== null"
      ref="detail-reminder-modal"
      :key="selectedReminder.id"
      :reminder-detail="selectedReminder"
      :contact-id="contact.id"
      :available-contacts="availableContact"
      :available-employees="availableEmployees"
      @create-reminder-click="createReminderClicked" />
    <add-document-modal
      :suffix="'contactDocument'"
      :owner-id="request.ownerId"
      :contact-persons="contactsList"
      @document-created="documentCreated" />
    <create-activity-modal
      v-if="contact"
      ref="create-activity"
      :key="keyActivity + (selectedActivity !== null ? 'activity-creation'+selectedActivity.id : 'activity-creation'+uniqueId)"
      :activity-detail="selectedActivity"
      :contact="contact"
      :contacts="contactsList"
      :contact-persons="typeOfContact === 'company' ? contact.contactPersons : []"
      :ids="referencesContactIds"
      @activity-created="afterAction('activity')"
      @activity-updated="afterAction('activity')" />
    <activate-tenancy-modal
      v-if="showActivateTenancyModal"
      ref="activateTenancyModal"
      :owner-id="request.ownerId"
      :selected-entries-ids="[request.tenancyId]"
      :is-same-tenant="false"
      :source="'request'"
      :object="object"
      :tenancy="tenancy"
      @hide="hideActivateTenancyModal"
      @tenancy-activated="updateOnlineStatus" />
    <contact-person-creation
      ref="contact-person-modal"
      :key="selectedPerson !== null ? 'modal-person'+selectedPerson.id : 'modal-person'"
      :company-contact="contact"
      :contact-type="typeOfContact"
      :selected-contact="selectedPerson"
      @new-contact-person="contactPersonCreated"
      @edit-contact-person="contactPersonEdited"
      @delete-contact-person="contactPersonDeleted" />
    <b-modal
      id="requestRejectConfirmationModal"
      ref="requestRejectConfirmationModal"
      no-close-on-backdrop
      :title="$t('message.onBoarding.interestedParties.rejectConfirmation.title')"
      hide-footer>
      <div class="col mb-2 p-0">
        {{ $t('message.onBoarding.interestedParties.rejectConfirmation.body') }}
      </div>
      <div class="col p-0">
        <coozzy-button
          size="small"
          design="default"
          @click="hideRequestRejectModal()">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
        <coozzy-button
          size="small"
          class="float-right mb-0"
          design="prop-green"
          :disabled="loading"
          @click="rejectWithoutMail">
          {{ $t('message.generic.rejectWithoutMail') }}
        </coozzy-button>
        <coozzy-button
          size="small"
          class="float-right mb-0 mr-3"
          design="prop-green"
          :disabled="loading"
          @click="rejectMail">
          {{ $t('message.generic.rejectWithMail') }}
        </coozzy-button>
      </div>
    </b-modal>
    <b-modal
      :id="'confirmationModalLinkContact'"
      ref="confirmationModalLinkContact"
      no-close-on-backdrop
      hide-footer
      :title="$t('message.contact.linkContact.title')">
      <div class="col">
        <p>{{ $t('message.contact.linkContact.unlink') }}</p>
      </div>
      <div class="col">
        <coozzy-button
          size="small"
          class="mb-0 border"
          design="transparent"
          @click="$bvModal.hide('confirmationModalLinkContact')">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
        <coozzy-button
          size="small"
          class="float-right mb-0"
          design="green"
          :disabled="disabledLinkBtn"
          @click="unlinkPersons()">
          {{ $t('message.generic.confirm') }}
        </coozzy-button>
      </div>
    </b-modal>
    <b-modal
      id="deleteTenancyConfirmationModal"
      ref="deleteTenancyConfirmationModal"
      no-close-on-backdrop
      :title="$t('message.interestedParty.action.deleteTenancy')"
      hide-footer>
      <p
        v-if="hasSignatureRequestIncomplete">
        {{ $t('message.interestedParty.confirmModal.tenancyWithSignConfirmationTextDelete') }}
      </p>
      <p
        v-else>
        {{ $t('message.interestedParty.confirmModal.tenancyConfirmationTextDelete') }}
      </p>
      <div class="col">
        <coozzy-button
          size="small"
          design="default"
          @click="$bvModal.hide('deleteTenancyConfirmationModal')">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
        <coozzy-button
          size="small"
          class="float-right mb-0"
          design="prop-green"
          @click="deleteTenancy(hasSignatureRequestIncomplete)">
          {{ $t('message.generic.delete') }}
        </coozzy-button>
        <coozzy-button
          v-if="hasSignatureRequestIncomplete"
          size="small"
          class="float-right mr-2 mb-0"
          design="prop-green"
          @click="deleteTenancy">
          {{ $t('message.interestedParty.confirmModal.deleteTenancyOnly') }}
        </coozzy-button>
      </div>
    </b-modal>
    <b-modal
      id="requestDeactivateAdvertModal"
      ref="requestDeactivateAdvertModal"
      no-close-on-backdrop
      :title="$t('message.onBoarding.interestedParties.deactivateAdvert.title')"
      hide-footer>
      <div class="col">
        <p>{{ $t('message.onBoarding.interestedParties.deactivateAdvert.body') }}</p>
        <coozzy-button
          size="small"
          design="default"
          @click="rejectOtherRequest()">
          {{ $t('message.generic.selectField.no') }}
        </coozzy-button>
        <coozzy-button
          size="small"
          class="float-right mb-0"
          design="prop-green"
          @click="unPublishClicked()">
          {{ $t('message.generic.selectField.yes') }}
        </coozzy-button>
      </div>
    </b-modal>
    <b-modal
      id="unpublishAdvert"
      ref="unpublishAdvert"
      no-close-on-backdrop
      :title="$t('message.onBoarding.interestedParties.unpublishAdvert.title')"
      hide-footer>
      <div class="col">
        <p>{{ $t('message.onBoarding.interestedParties.unpublishAdvert.body') }}</p>
        <coozzy-button
          size="small"
          design="default"
          @click="rejectAfterActivateTenancy()">
          {{ $t('message.generic.selectField.no') }}
        </coozzy-button>
        <coozzy-button
          size="small"
          class="float-right mb-0"
          design="prop-green"
          @click="unpublishAdvert()">
          {{ $t('message.generic.selectField.yes') }}
        </coozzy-button>
      </div>
    </b-modal>
    <digital-signature-modal
      v-if="showDigitalSignature"
      ref="digitalSignatureModal"
      :request-id="request.id"
      :tenancy="tenancy ? tenancy : null"
      :contacts="tenancyContacts"
      :read-only="readOnlyDigitSign"
      :signature-request="selectedSignRequest"
      @cancel-signature="signatureRequestCanceled"
      @contact-updated="contactUpdated"
      @hide="hideDigitalSignatureModal" />
    <digital-deposit-modal
      v-if="showDigitalDeposit"
      ref="digitalDepositModal"
      :tenancy="tenancy ? tenancy : null"
      :object="object ? object : null"
      :contacts="contactsList"
      :account-id="accountId.toString()"
      @contact-updated="contactUpdated"
      @hide="hideDigitalDepositModal" />
  </section>
</template>

<script>
import ContactApi from '@/misc/apis/ContactApi'
import ReferenceModal from './ReferenceModal'
import MediaApi from '@/misc/apis/MediaApi'
import MessageApi from '@/misc/apis/MessageApi'
import RequestApi from '@/marketingBasic/api/RequestApi'
import AddDocumentModal from '@/properties/components/AddDocumentModal'
import Document from '@/properties/components/Document'
import ReminderDetailsModal from '@/calendar/components/ReminderDetailsModal'
import EmployeeNameText from '@/framework/components/misc/EmployeeNameText'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner'
import CalendarApi from '@/misc/apis/CalendarApi'
import CreateActivityModal from '@/contact/components/CreateActivityModal'
import ReminderCreationModal from '@/calendar/components/ReminderCreationModal'
import MessageDetailsModal from '@/contact/components/MessageDetailsModal'
import CoozzyDetailCard from '@/framework/components/card/CoozzyDetailCard'
import ContactDetailCard from '@/contact/components/ContactDetailCard'
import SmallMessagesTable from '@/messages/SmallMessagesTable'
import ContactEventCard from '../contact/components/ContactEventCard'
import CoozzyActivityIcon from '@/framework/components/icons/CoozzyActivityIcon'
import CoozzyContactPersonIcon from '@/framework/components/icons/CoozzyContactPersonIcon'
import CoozzyCalendarCheckIcon from '@/framework/components/icons/CoozzyCalendarCheckIcon'
import CoozzyDocumentIcon from '@/framework/components/icons/CoozzyDocumentIcon'
import CoozzyReminderIcon from '@/framework/components/icons/CoozzyReminderIcon'
import Vue from 'vue'
import { formatting } from '@/mixins/formatting'
import CoozzyButton from '@/framework/components/button/CoozzyButton'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import CoozzyDeleteIcon from '@/framework/components/icons/CoozzyDeleteIcon'
import CoozzyRejectIcon from '@/framework/components/icons/CoozzyRejectIcon'
import CoozzyFavoriteIcon from '@/framework/components/icons/CoozzyFavoriteIcon'
import CoozzyPaperClipIcon from '@/framework/components/icons/CoozzyPaperClipIcon'
import CoozzyAngleDoubleUpIcon from '@/framework/components/icons/CoozzyAngleDoubleUpIcon'
import CoozzyAngleDoubleDownIcon from '@/framework/components/icons/CoozzyAngleDoubleDownIcon'
import { user } from '@/mixins/user'
import { request } from '@/mixins/request'
import { mail } from '@/mixins/mail'
import ActivateTenancyModal from '@/addTenancy/ActivateTenancyModal'
import ObjectApi from '../misc/apis/ObjectApi'
import ContactPersonCreation from '@/contact/components/ContactPersonCreation'
import CreateContactModal from '@/contact/components/CreateContactModal'
import CoozzyTicketIcon from '@/framework/components/icons/CoozzyTicketIcon'
import DigitalSignatureModal from '@/user/DigitalSignatureModal.vue'
import CoozzyDeepSignIcon from '@/framework/components/icons/CoozzyDeepSignIcon.vue'
import { deepSign } from '@/mixins/deepSign'
import CoozzyPhoneIcon from '@/framework/components/icons/CoozzyPhoneIcon.vue'
import CoozzyMobileAltIcon from '@/framework/components/icons/CoozzyMobileAltIcon.vue'
import CoozzyFaxIcon from '@/framework/components/icons/CoozzyFaxIcon.vue'
import CoozzyEditIcon from '@/framework/components/icons/CoozzyEditIcon.vue'
import CoozzyEmailIcon from '@/framework/components/icons/CoozzyEmailIcon.vue'
import CoozzyReactivateIcon from '@/framework/components/icons/CoozzyReactivateIcon.vue'
import CoozzySuccessIcon from '@/framework/components/icons/CoozzySuccessIcon.vue'
import { responsivity } from '@/mixins/responsivity'
import DeepSignApi from '@/misc/apis/DeepSignApi'
import { media } from '@/mixins/media'
import CoozzyEvorestIcon from '@/framework/components/icons/CoozzyEvorestIcon.vue'
import { digitalDeposit } from '@/mixins/digitalDeposit'
import DigitalDepositModal from '@/user/DigitalDepositModal.vue'

export default {
  name: 'InterestedPartyDetails',
  components: {
    DigitalDepositModal,
    CoozzyEvorestIcon,
    CoozzySuccessIcon,
    CoozzyReactivateIcon,
    CoozzyEmailIcon,
    CoozzyEditIcon,
    CoozzyFaxIcon,
    CoozzyMobileAltIcon,
    CoozzyPhoneIcon,
    CoozzyDeepSignIcon,
    DigitalSignatureModal,
    CreateContactModal,
    CoozzyAngleDoubleDownIcon,
    CoozzyAngleDoubleUpIcon,
    CoozzyPaperClipIcon,
    CoozzyDeleteIcon,
    CoozzyButton,
    ContactEventCard,
    SmallMessagesTable,
    MessageDetailsModal,
    ContactDetailCard,
    ReferenceModal,
    ReminderCreationModal,
    ReminderDetailsModal,
    AddDocumentModal,
    Document,
    EmployeeNameText,
    CoozzyDetailCard,
    CoozzyCalendarCheckIcon,
    CoozzyReminderIcon,
    CoozzyDocumentIcon,
    CoozzyActivityIcon,
    CoozzyContactPersonIcon,
    CoozzySpinner,
    CreateActivityModal,
    CoozzyFavoriteIcon,
    CoozzyRejectIcon,
    ActivateTenancyModal,
    ContactPersonCreation,
    CoozzyTicketIcon
  },
  mixins: [user, formatting, request, mail, deepSign, responsivity, media, digitalDeposit],
  props: {
    request: {
      type: Object,
      default: null
    },
    object: {
      type: Object,
      default: null
    },
    listRequest: {
      type: Array,
      default: () => {
        return []
      }
    },
    isTheTenancyActivated: {
      type: [Boolean, String],
      default: false
    },
    requestBuilding: {
      type: Object,
      default: null
    },
    isReserved: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      listSignRequests: [],
      keyActivity: '',
      isSomeDataDifferent: false,
      textUpdated: '',
      trigger: 0,
      loading: false,
      isActivated: false,
      contact: {},
      otherContactsList: [],
      contactsList: [],
      contactToEdit: null,
      requestType: '',
      isBusyReminder: false,
      isBusyActivity: false,
      isBusySignature: false,
      activityList: [],
      activityEmployeeList: [],
      reminderList: [],
      mailList: [],
      filterFolder: 'FILTER_FOLDER_ALL',
      totalPageMailCount: 1,
      totalMailsCount: 0,
      applicationForm: null,
      showAllContacts: false,
      showAllActivities: false,
      showAllReminders: false,
      showAllDocuments: false,
      showAllSignatures: false,
      contactDocuments: [],
      selectedReminder: null,
      uniqueId: '',
      selectedActivity: null,
      selectedEmail: null,
      emailToReply: null,
      remainderForEdit: null,
      typeOfContact: '',
      randomKey: null,
      contactToUnlink: null,
      disabledLinkBtn: false,
      fromApplicationForm: false,
      fieldsActivity: [
        {
          key: 'subject',
          label: 'Subject',
          tdClass: 'align-middle max-w-subject'
        },
        {
          key: 'text',
          label: 'Text',
          tdClass: 'align-middle'
        },
        {
          key: 'duration',
          label: 'duration',
          tdClass: 'align-middle'
        }
      ],
      fieldsSignature: [
        {
          key: 'created',
          label: this.$t('message.deepSign.created'),
          thClass: 'th-active',
          tdClass: 'align-middle border-right-0'
        },
        {
          key: 'contacts',
          label: this.$t('message.deepSign.contacts'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'documentType',
          label: this.$t('message.deepSign.documentType'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'Signers',
          label: this.$t('message.deepSign.Signers'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'status',
          label: this.$t('message.deepSign.status.title'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        }
      ],
      fieldsContactPersons: [
        {
          key: 'name',
          label: 'Name',
          tdClass: 'align-middle'
        },
        {
          key: 'phoneNumber',
          label: 'phoneNumber',
          tdClass: 'align-middle'
        },
        {
          key: 'email',
          label: 'Email',
          tdClass: 'align-middle'
        },
        {
          key: 'action',
          label: '',
          tdClass: 'align-middle'
        }
      ],
      fieldsReminder: [
        {
          key: 'date',
          label: 'Date',
          tdClass: 'align-middle'
        },
        {
          key: 'subject',
          label: 'Subject',
          tdClass: 'align-middle'
        },
        {
          key: 'assignee',
          label: 'Assignee',
          tdClass: 'align-middle'
        }
      ],
      favoriteState: this.request.favorite,
      requestIds: [],
      tenancy: null,
      selectedPerson: null,
      hasEmails: false,
      debounceTimeout: null,
      tenancyContacts: [],
      showDigitalSignature: false,
      rentalDocuments: [],
      showAllRentalDocuments: false,
      hasSignatureRequestIncomplete: false,
      signatureRequests: [],
      showPopover: false,
      selectedPopOverEvent: null,
      page: {
        left: 0,
        top: 0
      },
      selectedSignRequest: null,
      tenancySignRequest: null,
      readOnlyDigitSign: false,
      showActivateTenancyModal: false,
      property: null,
      showDigitalDeposit: false
    }
  },
  computed: {
    ...mapGetters('message', ['getSentMails']),
    ...mapState('message', ['readMails']),
    ...mapState('contact', ['updatedContacts']),
    availableAttendees() {
      const attendees = []

      for (const contact of this.contactsList) {
        attendees.push({
          firstName: contact.firstName,
          lastName: contact.lastName,
          email: contact.email
        })
      }
      if (this.employeeList.length !== 0) {
        this.employeeList.forEach(employee => {
          attendees.push({
            firstName: employee.profile.firstName,
            lastName: employee.profile.lastName,
            email: employee.email
          })
        })
      }
      return attendees
    },
    countActivity() {
      return this.activityList.length
    },
    countSignRequests() {
      return this.signatureRequests.length
    },
    referencesContactIds() {
      return [this.request.id].concat(this.request.requesters.map(req => req.contactId))
    },
    availableEmployees() {
      const array = []
      if (this.employeeList.length !== 0) {
        this.employeeList.forEach((emp) => {
          if (typeof (emp) !== 'undefined') {
            array.push({
              label: (emp.profile.firstName ? emp.profile.firstName.substr(0, 1) + '. ' : '') + emp.profile.lastName + (emp.deleted ? ` (${this.$t('message.generic.deleted')})` : ''),
              value: emp.id
            })
          }
        })
      }
      return array
    },
    availableContact() {
      const array = []
      if (this.contact !== null && this.contact.persons !== undefined) {
        if (this.contact.persons.length !== 0) {
          this.contact.persons.forEach((emp) => {
            if (typeof (emp) !== 'undefined') {
              array.push({
                name: (emp.firstName ? emp.firstName.substr(0, 1) + '. ' : '') + emp.lastName,
                id: emp.id
              })
            }
          })
        }
        array.push({
          name: this.contact.name,
          id: this.contact.id
        })
      }
      return array
    },
    countReminder() {
      return this.reminderList.length
    },
    countContactPerson() {
      if (this.contact !== null && this.contact.contactPersons) {
        return this.contact.contactPersons.length
      }
      return 0
    },
    countChecklist() {
      return Object.values(this.request.checklist).reduce((op, inp) => op + inp, 0)
    },
    countDocument() {
      if (this.contact !== null) {
        return this.contactDocuments.length
      }
      return 0
    },
    listDocuments() {
      const docs = this.contactDocuments
      return docs.sort((a, b) => (a.name > b.name ? 1 : -1))
    },
    getListRole() {
      if (this.contact !== null && this.contact.types !== undefined) {
        if (this.contact.types && this.contact.types.length === 1 && this.contact.types[0] === 'NONE') {
          return '-'
        } else {
          const array = []
          this.contact.types.forEach(role => {
            if (role !== 'NONE') {
              array.push(this.$t('message.contact.types.' + role))
            }
          })
          return array.join(', ')
        }
      }
      return '-'
    },
    employeeList() {
      if (this.getEmployees().length > 0) {
        return this.getEmployees()
      }
      return []
    },
    isTenancyActivated() {
      if (this.tenancy) {
        return this.tenancy.activated
      }
      return false
    },
    contactsListReminder() {
      return this.contactsList
    },
    hasUnreadMails() {
      let hasUnread = false
      for (const mail of this.mailList) {
        if (mail.readEntries.filter(entry => parseInt(entry.accountId) === parseInt(this.accountId)).length === 0) {
          hasUnread = true
        }
      }

      return hasUnread
    },
    sortedContactsList() {
      if (this.request) {
        const requestersIds = this.request.requesters.map(x => x.contactId)
        const sortedList = this.contactsList.slice()
        return sortedList.sort(function(a, b) {
          return requestersIds.indexOf(a.id) - requestersIds.indexOf(b.id)
        })
      }
      return this.contactsList
    },
    listRentalDocuments() {
      const docs = this.rentalDocuments
      return docs.sort((a, b) => b.contractNumber - a.contractNumber)
    },
    isAllTenantsIndividualContacts() {
      return !this.tenancy.tenant.contactIds.some((contactId) => {
        const contact = this.contactsList.find(x => x.id === contactId)
        if (contact && 'contactPersons' in contact) {
          return true
        }
      })
    },
    tenancyStatusDigitalDeposit() {
      const status = ['TENANCY_STATUS_NOT_ACTIVATED', 'TENANCY_STATUS_CURRENT', 'TENANCY_STATUS_FUTURE']
      return this.tenancy?.id && status.includes(this.tenancy.status)
    }
  },
  watch: {
    getSentMails: function (newVal) {
      if (this.contactsWithoutEmail?.length > 0) {
        this.$store.dispatch('request/setRequests', this.listRequest)
      }
      if (newVal.length > 0 && this.fromApplicationForm) {
        this.requestApplicationForm()
      }
      if (this.isActivated && newVal.length > 0 && this.requestIds.length > 0) {
        this.rejectRequests(this.listRequest, this.listRequest.filter(r => r.id !== this.request.id).map(x => x.id))
        this.$emit('request-updated', this.request)
        RequestApi.markRead(this.listRequest.filter(r => r.id !== this.request.id).map(x => x.id))
          .catch(e => {
            console.log(e)
          })
      } else if (newVal.length > 0 && this.requestIds.length > 0) {
        this.rejectRequests([this.request], [this.request.id])
        const req = this.request
        req.rejected = true
        this.$emit('request-updated', req)
      } else if (newVal.length > 0 && this.requestIds.length === 0) {
        this.mailList.unshift(newVal[newVal.length - 1])
      }
      this.totalMailsCount = this.mailList.length
      this.$bvModal.hide('requestRejectConfirmationModal')
    },
    readMails: function (newVal) {
      if (newVal.length > 0) {
        const newEntry = newVal[newVal.length - 1]
        for (let index = 0; index < this.mailList.length; index++) {
          if (this.mailList[index].id === newEntry.mailId) {
            this.mailList[index].readEntries.push({ accountId: newEntry.accountId })
          }
        }
      }

      this.updateUnreadStatus()
    },
    updatedContacts: function (newVal) {
      if (newVal.length > 0) {
        const updatedContact = newVal[newVal.length - 1]
        const updatedId = updatedContact.person ? updatedContact.person.id : updatedContact.company.id
        const index = this.contactsList.findIndex(c => c.id === updatedId)

        if (index >= 0) {
          this.$set(this.contactsList, index, updatedContact.person ? updatedContact.person : updatedContact.company)
          if (this.contact.id === updatedId) {
            this.$set(this, 'contact', updatedContact.person ? updatedContact.person : updatedContact.company)
          }
        } else {
          this.contactsList.push(updatedContact.person ? updatedContact.person : updatedContact.company)
          const newRequester = updatedContact.person ? updatedContact.person : updatedContact.company
          this.request.requesters.push({ contactId: newRequester.id, accountId: 0, userId: '', employerReference: null, landlordReference: null })
          this.$emit('request-updated', this.request)
        }
      }
    },
    requestBuilding: function (newVal) {
      if (newVal) {
        this.loadProperty()
      }
    }
  },
  mounted: function () {
    this.$root.$on('bv::modal::hidden', (bvEvent, modalId) => {
      if (modalId === 'requestRejectConfirmationModal' && this.isActivated) {
        this.$emit('tenancy-activated', this.request.id)
      }
    })
    this.loading = true
    this.setMarketplace(this.request.marketplaceName)
    this.loadRequesterContacts()
    this.loadApplicationForm()
    this.loadEmployees()
    this.loadTenancy()
    this.loadContactsOfOtherRequests()
    if (this.isTheTenancyActivated) {
      if (this.object.marketingStatus && this.object.marketingStatus.published) {
        this.$bvModal.show('requestDeactivateAdvertModal')
      } else {
        this.$bvModal.show('requestRejectConfirmationModal')
      }
    }
    this.$nextTick(function () {
      this.reloadSmallMessagesData()
      this.loadProperty()
    })
  },
  methods: {
    ...mapMutations('request', ['setMarketplace']),
    ...mapMutations('message', [
      'setSendMailContacts',
      'setSendMailContactsWithoutEmail',
      'setSendMailPreselectedContact',
      'setSendMailReferenceIds',
      'setSendMailEmailToReply',
      'setSubject',
      'setAdditionalText',
      'setUseBcc',
      'setSendMailSecondaryTitle',
      'setBccReferenceIds',
      'setRenderWithObjectId'
    ]),
    ...mapActions('employee', ['loadEmployees']),
    ...mapGetters('employee', ['getEmployees']),
    createMassRental() {
      if (this.property) {
        const ids = this.property.buildingIds.join()
        const requestersIds = this.request.requesters.map(x => x.contactId)
        this.$router.push({
          name: 'MarketingCreateMassRentalView',
          query: { source: 'request', buildingIds: ids, tenantIds: requestersIds.join(), requestId: this.request.id, requestObjectId: this.request.objectId }
          })
      }
    },
    async loadProperty() {
      try {
        if (this.requestBuilding.id) {
          const response = await ObjectApi.getPropertiesByBuildingIds(this.requestBuilding.id)
          this.property = response.properties[0]
        }
      } catch (e) {
        console.log(e)
      }
    },
    downloadContactDocuments() {
      const idsAndNames = this.listDocuments.map(x => ({ id: x.versions[x.versions.length - 1].mediaId, mediumName: x.name }))
      const nameOfMergedFile = this.contact.name
      this.mergeDownloadAllDocuments(idsAndNames, nameOfMergedFile)
    },
    downloadRentalDocuments() {
      const idsAndNames = this.listRentalDocuments.map(x => ({ id: x.versions[x.versions.length - 1].mediaId, mediumName: x.name }))
      const nameOfMergedFile = this.contact.name
      this.mergeDownloadAllDocuments(idsAndNames, nameOfMergedFile)
    },
    hideActivateTenancyModal() {
      this.showActivateTenancyModal = false
    },
    onRowClicked(item, index, event) {
      if (event.srcElement.classList.contains('contactName') || event.srcElement.classList.contains('contactName') || event.srcElement.classList.contains('contractNumber') || event.srcElement.classList.contains('contractNumber')) {
        event.preventDefault()
      } else {
        this.selectedSignRequest = item
        this.readOnlyDigitSign = true
        this.showDigitalSignatureModal()
      }
    },
    signatureRequestCanceled(id) {
      this.signatureRequests.map((item) => {
        if (item.id === id) {
          item.cancelled = true
        }
        return true
      })
    },
    redirectToDetailPage(contactId, newOnglet = false) {
      let name = ''
      if (this.isOwnerModule) {
        name = 'OwnerContactDetailsView'
      } else if (this.isAssetModule) {
        name = 'AssetContactDetailsView'
      } else if (this.isAdminModule) {
        name = 'AdminContactDetailsView'
      } else if (this.isAccountingModule) {
        name = 'AccountingContactDetailsView'
      } else {
        name = 'MarketingContactDetailsView'
      }
      if (newOnglet) {
        const routerHref = this.$router.resolve({
          name: name,
          params: { id: contactId }
        }, () => {
        })
        window.open(routerHref.href, '_blank')
      } else {
        this.$router.push({
          name: name,
          params: { id: contactId }
        }, () => {
        })
      }
    },
    getSignerName(signer) {
      const contact = this.tenancyContacts.find(c => c.id === signer.contactId)
      if (contact) {
        return contact.name
      }
      return ''
    },
    onMouseMove(e) {
      this.showPopover = this.hasSomeParentTheClass(e.srcElement, 'fieldToShow')
      if (this.showPopover && document.getElementById('eventPopover')) {
        this.page.left = e.layerX
        this.page.top = e.layerY
      }
    },

    mouseLeaveEvents() {
      this.selectedPopOverEvent = null
    },
    mouseEnterEvents(event) {
      this.selectedPopOverEvent = event
    },
    getStatus(item) {
      if (item.cancelled) {
        return 'cancelled'
      } else if (item.signers.every((x) => x.signed) || item.completed) {
        return 'complete'
      } else if (item.signers.some(signer => !signer.signed)) {
        return 'incomplete'
      }
    },
    loadSignatureRequest() {
        this.isBusySignature = true
        const refIds = []
        if (this.request?.id) {
          refIds.push(this.request.id)
        }
        if (this.tenancy?.id) {
          refIds.push(this.tenancy.id)
        }
        if (refIds.length > 0) {
          DeepSignApi.listSignRequestsByAttachToReferenceIds(refIds).then((response) => {
            this.signatureRequests = response.signRequests
            this.isBusySignature = false
            if (response.signRequests.length > 0) {
              let contactIds = []
              response.signRequests.forEach((signRequest) => {
                if (signRequest.completed === false && signRequest.cancelled === false) {
                  this.hasSignatureRequestIncomplete = true
                }
                contactIds = contactIds.concat(signRequest.signers.map((signer) => {
                  return signer.contactId
                }))
              })
              contactIds = [...new Set(contactIds)]
              contactIds = contactIds.filter((id) => {
                return !this.tenancyContacts.some((contact) => {
                  return contact.id === id
                })
              })
              ContactApi.contactResolve(contactIds)
                .then(responseCon => {
                  this.tenancyContacts = this.tenancyContacts.concat(responseCon.persons.concat(responseCon.companies))
                })
                .catch(e => {
                  console.error(e)
                })
            }
          }).catch((e) => {
            console.log(e)
            this.isBusySignature = false
          })
        } else {
          this.isBusySignature = false
        }
    },
    cancelSignRequest() {
      const promises = []
      this.signatureRequests.forEach(signRequest => {
        if (signRequest.completed === false && signRequest.cancelled === false) {
          promises.push(DeepSignApi.cancelRequest(signRequest.id))
        }
      })
      Promise.all(promises)
        .catch(e => {
          console.log(e)
          Vue.toasted.show(e.message, { type: 'error' })
        })
    },
    showDigitalSignatureModal() {
      this.showDigitalSignature = true
      this.readOnlyDigitSign = true
      this.$nextTick(() => {
        this.$refs.digitalSignatureModal?.show()
      })
    },
    showDigitalSignatureModalEmpty() {
      this.showDigitalSignature = true
      this.readOnlyDigitSign = false
      this.selectedSignRequest = null
      this.tenancySignRequest = null
      this.$nextTick(() => {
        this.$refs.digitalSignatureModal?.show()
      })
    },
    showDigitalDepositModal() {
      this.showDigitalDeposit = true
      this.$nextTick(() => {
        this.$refs.digitalDepositModal?.show()
      })
    },
    hideDigitalDepositModal() {
      this.showDigitalDeposit = false
    },
    loadRentaleDocuments(DocumentIds) {
        MediaApi.listDocuments(DocumentIds)
          .then(response => {
            this.rentalDocuments = response.documents.reverse()
          })
    },
    hideDigitalSignatureModal() {
      this.showDigitalSignature = false
      this.readOnlyDigitSign = false
      this.selectedSignRequest = null
      this.tenancySignRequest = null
    },
    searchEmails(filterFolder, mailBoxId, more, query) {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }
      this.debounceTimeout = setTimeout(() => {
        this.debounceTimeout = null
        this.loadMessages(filterFolder, mailBoxId, more, query)
      }, 300)
    },
    updateOnlineStatus() {
      const lastPublication = this.object?.marketingStatus?.publications[this.object?.marketingStatus?.publications.length - 1] || null
      this.requestContractReceived()
      if (lastPublication && lastPublication.published !== '0' && lastPublication.unPublished === '0') {
        this.$bvModal.show('unpublishAdvert')
      } else {
        this.rejectAfterActivateTenancy()
      }
    },
    unpublishAdvert() {
      this.unPublishClicked()
    },
    reloadSmallMessagesData() {
      this.loadMessages()
    },
    createTicket() {
      this.$router.push({ name: 'MarketingTicketCreation', query: { fromBulk: true, ids: this.request.id, defaultAssignee: this.contact.ownerId } })
    },
    afterAction(type) {
      if (type === 'reminder') {
        this.loadReminders()
      } else if (type === 'activity') {
        this.loadActivities()
      }
      this.updateUnreadStatus()
    },
    unPublishClicked() {
      this.loading = true
      const id = this.id || this.object?.id
      ObjectApi.unPublishObject([id])
        .then(() => {
          this.loading = false
          this.$bvModal.hide('requestDeactivateAdvertModal')
          Vue.toasted.show(this.$t('message.successMessages.unPublishObject'), { type: 'success' })
          this.rejectAfterActivateTenancy()
        })
        .catch(e => {
          console.log(e)
          this.loading = false
          Vue.toasted.show(this.$t('message.savingErrors.unPublishObject'), { type: 'error' })
        })
    },
    publishClicked() {
      if (this.canBePublished) {
        this.loading = true
        ObjectApi.publishObject([this.id])
          .then(() => {
            Vue.toasted.show(this.$t('message.successMessages.publishObject'), { type: 'success' })
          })
          .catch(e => {
            console.log(e)
            Vue.toasted.show(this.$t('message.savingErrors.publishObject'), { type: 'error' })
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    createEventClicked() {
      let referenceIds = [this.request.id, this.request.objectId]
      if (this.request.requesters.length > 0) {
        referenceIds = referenceIds.concat(this.request.requesters.map(requester => requester.contactId))
      }
      this.updateUnreadStatus()
      this.$router.push({ name: 'MarketingCalendarOverview', params: { viewCalendar: 'week' }, query: { objectId: this.request.objectId, referenceIds: referenceIds.join(',') } })
    },
    addRequesterClicked() {
      this.contactToEdit = null
      this.$bvModal.show('create-contact-modal')
    },
    editContactClicked(contact) {
      this.contactToEdit = contact
      this.$bvModal.show('create-contact-modal')
    },
    editContact(item) {
      const index = this.contactsList.findIndex(x => x.id === item.id)
      if (index !== -1) {
        this.contactsList[index] = item
      }
    },
    contactEdited() {
      this.loadRequesterContacts()
    },
    contactCreated(data) {
      if (this.request && data.contact) {
        this.addNewRequester(data.contact)
      }
      this.updateUnreadStatus()
    },
    contactUpdated(data) {
      if (data.contact && this.contact.id === data.contact.id) {
        this.contact = data.contact
      }

      const index = this.contactsList.findIndex(c => c.id === data.contact.id)

      // Existing requester was updated
      if (index >= 0) {
        this.contactsList.forEach((value, index) => {
          if (value.id === data.contact.id) {
            Vue.set(this.contactsList, index, data.contact)
          }
        })
      } else {
        // New requester was added
        this.addNewRequester(data.contact)
      }
      this.updateUnreadStatus()
    },
    addNewRequester(newRequesterContact) {
      RequestApi.addRequesters(this.request.id, [{ contactId: newRequesterContact.id }])
        .then(() => {
          console.log(this.contactsList)
          this.contactsList.unshift(newRequesterContact)
          console.log(this.contactsList)
          this.request.requesters.push({ contactId: newRequesterContact.id, accountId: 0, userId: '', employerReference: null, landlordReference: null })
          this.$emit('request-updated', this.request)
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.savingErrors.request'), { type: 'error' })
        })
    },
    createContactPersonClicked(item) {
      this.selectedPerson = item
      this.$nextTick(function () {
        this.$refs['contact-person-modal']?.show()
      })
    },
    rejectWithoutMail() {
      if (this.isTheTenancyActivated === 'true' || this.isTheTenancyActivated === true || this.isActivated) {
        this.rejectRequests(this.listRequest, this.listRequest.filter(r => r.id !== this.request.id).map(x => x.id))
      } else {
        this.rejectRequests([this.request], [this.request.id])
      }
      const req = this.request
      req.rejected = true
      this.$emit('request-updated', req)
    },
    rejectMail() {
      if (this.tenancyActivated === true || this.tenancyActivated === 'true' || this.isActivated) {
        this.rejectRequestMail(this.listRequest, this.listRequest.filter(r => r.id !== this.request.id && !r.rejected).map(x => x.id), this.contactsList.concat(this.otherContactsList), this.object, false)
      } else {
        this.rejectRequestMail([this.request], [this.request.id], this.contactsList.concat(this.otherContactsList), this.object, false)
      }
      this.updateUnreadStatus()
    },
    rejectOtherRequest() {
      if (this.isTheTenancyActivated === 'true' || this.isTheTenancyActivated === true) {
        this.$bvModal.hide('requestDeactivateAdvertModal')
      }
      this.$bvModal.show('requestRejectConfirmationModal')
    },
    // randomKeyGenerate() {
    //   this.randomKey = Math.random().toString(36).substr(2, 5)
    // },
    loadContactsOfOtherRequests() {
      const allContact = [...new Set(this.listRequest.flatMap(x => x.requesters.map(z => z.contactId)))]
      ContactApi.contactResolve(allContact)
        .then(response => {
          this.otherContactsList = response.persons
            .concat(response.companies)
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.contacts'), { type: 'error' })
        })
    },
    loadRequesterContacts() {
      this.loading = true
      ContactApi.contactResolve(this.request.requesters.map(requester => requester.contactId))
        .then(response => {
          if (response.persons.length > 0) {
            this.typeOfContact = 'person'
            this.contact = response.persons[0]
          } else if (response.companies.length > 0) {
            this.typeOfContact = 'company'
            this.contact = response.companies[0]
          }
          this.contactsList = response.persons
            .concat(response.companies)
            .filter(contact => this.request.requesters.map(requester => requester.contactId).includes(contact.id))

          this.changeUniqueId()
          this.loadDocuments()
          this.loadReminders()
          this.loadActivities()
          this.loadMessages()
        })
        .catch(e => {
          this.loading = false
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.contacts'), { type: 'error' })
        })
        .finally(() => {
          this.loading = false
        })
    },
    loadTenancyContacts() {
      const contactIds = this.tenancy.tenant.contactIds.concat(this.tenancy.tenant.guarantorContactIds)
      ContactApi.contactResolve(contactIds)
        .then(response => {
          this.tenancyContacts = response.persons
            .concat(response.companies)
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.contacts'), { type: 'error' })
        })
        .finally(() => {
          this.loading = false
        })
    },
    loadDocuments() {
      const uniqueDocumentIds = []
      for (const contact of this.contactsList) {
        for (const documentId of contact.documentIds) {
          if (!uniqueDocumentIds.includes(documentId)) {
            uniqueDocumentIds.push(documentId)
          }
        }
      }
      if (uniqueDocumentIds.length > 0) {
        MediaApi.listDocuments(uniqueDocumentIds)
          .then(response => {
            this.contactDocuments = response.documents.reverse()
          })
      }
    },
    loadReminders() {
      CalendarApi.getRemindersByReferenceIds([this.request.id], this.accountId)
        .then(response => {
          this.reminderList = response.reminders
        })
        .catch(e => {
          console.log(e)
          alert(this.$t('message.loadingErrors.reminders'))
        })
    },
    loadTenancy() {
      ObjectApi.getTenanciesByIds([this.request.tenancyId])
        .then(tenancyResponse => {
          if (tenancyResponse.tenancies && tenancyResponse.tenancies.length > 0 && !tenancyResponse.tenancies[0].vacancy) {
            this.tenancy = tenancyResponse.tenancies[0]
            DeepSignApi.listSignRequestsByAttachToReferenceIds([this.tenancy.id])
            .then(response => {
              this.listSignRequests = response.signRequests
            })
            this.loadTenancyContacts()
            this.loadSignatureRequest()
            if (this.tenancy.documentIds.length > 0) {
              this.loadRentaleDocuments(this.tenancy.documentIds)
            }
          }
        })
    },
    loadMessages(filterFolder = 'FILTER_FOLDER_ALL', mailboxIds = this.mailboxesForCurrentUser.map(mailbox => mailbox.id), more = false, query = '') {
      MessageApi.listMailsByReferenceIds([this.request.id], filterFolder, mailboxIds, 1, more ? null : 5, query)
        .then(async response => {
          this.mailList = response.mails
          this.filterFolder = filterFolder
          this.totalPageMailCount = response.totalPageCount
          this.totalMailsCount = response.totalMailsCount
          if (response.mails.length > 0) {
            this.hasEmails = true
          }
        }).catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.message'), { type: 'error' })
        })
    },
    getDocumentOwner(documentId) {
      let ownerName = []
      if (this.contact && this.contactsList.length > 0) {
        const owners = this.contactsList.filter(obj => obj && obj.documentIds?.includes(documentId))
        if (owners.length > 0) {
          ownerName = ownerName.concat(owners.map(x => x.name))
        }
        return ownerName
      } else {
        return []
      }
    },
    loadActivities() {
      const referenceIds = [this.request.id].concat(this.request.requesters.map(d => d.contactId))
      ContactApi.getActivitiesByReferenceIdsV3(referenceIds)
        .then(async response => {
          this.activityList = response.activities
          this.activityList.sort(function (a, b) {
            return b.timestamp - a.timestamp
          })
          this.isBusyActivity = false
          const employeeIds = this.activityList.flatMap(d => d.employeeContactIds)
          if (employeeIds.length !== 0) {
            const ids = [...new Set(employeeIds)]
            ContactApi.contactResolve(ids)
              .then(response => {
                this.activityEmployeeList = response
              }).catch(e => {
                console.log(e)
                Vue.toasted.show(this.$t('message.loadingErrors.contacts'), { type: 'error' })
              })
          }
        }).catch(e => {
          console.log(e)
          this.isBusyActivity = false
          Vue.toasted.show(this.$t('message.loadingErrors.activity'), { type: 'error' })
        })
        .finally(() => {
          this.isBusyActivity = false
        })
    },
    loadApplicationForm() {
      if (this.request.applicationId) {
        RequestApi.getApplicationFormById(this.request.applicationId)
          .then(response => {
            this.applicationForm = response.application
          })
          .catch(e => {
            console.log(e)
          })
      }
    },
    getApplicationFormContact(contact) {
      if (this.applicationForm) {
        if (!contact.gender && this.applicationForm.company) {
          return this.applicationForm.company
        }
        for (const individual of this.applicationForm.individuals) {
          if (contact.gender && individual.email.toLowerCase() === contact.email.toLowerCase()) {
            return individual
          }
        }
      }

      return null
    },
    addDocumentClicked(suffix = '') {
      this.$bvModal.show('add-document-modal' + suffix)
    },
    documentCreated(document, suffix, contacts) {
      const promises = []
      if (contacts.length === 0) {
        promises.push(ContactApi.addDocuments(this.contact.id, [document.id]))
      } else {
        contacts.forEach(element => {
          promises.push(ContactApi.addDocuments(element.id, [document.id]))
        })
      }

      Promise.all(promises)
        .then(() => {
          if (contacts.length === 0) {
            this.contact.documentIds.push(document.id)
          } else {
            contacts.forEach(element => {
              const index = this.contactsList.findIndex(c => c.id === element.id)
              if (index !== -1) {
                element.documentIds.push(document.id)
                this.$nextTick(function () {
                  this.$set(this.contactsList, index, element)
                })
              }
            })
          }
          this.contactDocuments.unshift(document)
          this.updateUnreadStatus()
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.savingErrors.document'), { type: 'error' })
        })
    },
    updateUnreadStatus() {
      if (!this.hasUnreadMails && this.request.readApplicationForm === true) {
        this.callReadApi()
      }
    },
    callReadApi() {
      RequestApi.markRead([this.request.id])
        .catch(e => {
          console.log(e)
        })
    },
    documentDownloaded(item) {
      if (item.name === 'Anmeldeformular') {
        this.request.readApplicationForm = true
        this.updateUnreadStatus()
      }
    },
    rentalDocumentDownloaded(item) {
      // check if one of the signed documents is completed and is a mediaId of version of rentalDocument
      if (this.listSignRequests.some(e => item.versions.map(x => x.mediaId).includes(e.signedMediaId) && e.completed)) {
        this.callReadApi()
      }
    },
    versionAdded(updatedDocument) {
      this.contactDocuments.forEach((doc, index) => {
        if (doc.id === updatedDocument.id) {
          this.contactDocuments.splice(index, 1, updatedDocument)
        }
      }, this)
    },
    deleteRequest() {
      RequestApi.deleteRequests(this.request.id)
        .then(() => {
          Vue.toasted.show(this.$t('message.onBoarding.interestedParties.request') + ' ' + this.$t('message.successMessages.deletion'), { type: 'success' })
          this.$router.push({ name: 'MarketingObjectDetailsView', params: { id: this.object.id }, query: { view: 0 } })
        })
        .catch(e => {
          if (e.response && e.response.data && e.response.data.code === 9) {
            Vue.toasted.show(this.$t('message.deleteErrors.reservedRequest'), { type: 'error' })
          } else {
            console.log(e)
            Vue.toasted.show(this.$t('message.deleteErrors.request'), { type: 'error' })
          }
        })
        .finally(() => {
          this.$refs['confirmation-modal-request']?.hide()
        })
    },
    deleteDocument(document) {
      const documentContactId = this.contactsList.find(x => x.documentIds.includes(document.id)) ? this.contactsList.find(x => x.documentIds.includes(document.id)).id : this.contact.id
      ContactApi.deleteDocuments(documentContactId, document.id)
        .then(() => {
          this.contact.documentIds.forEach((doc, index) => {
            if (doc === document.id) {
              this.contact.documentIds.splice(index, 1)
            }
          }, this)
          this.contactDocuments.forEach((doc, index) => {
            if (doc.id === document.id) {
              this.contactDocuments.splice(index, 1)
            }
          }, this)
        }).catch(e => {
          Vue.toasted.show(this.$t('message.savingErrors.document'), { type: 'error' })
          console.log(e)
        })
    },
    getPhoneNumbers(phoneNumbers, type) {
      if (phoneNumbers && phoneNumbers.length > 0) {
        if (phoneNumbers.find(element => element.type === type)) {
          return phoneNumbers.find(element => element.type === type).number
        }
      }
      return '-'
    },
    changeUniqueId() {
      this.uniqueId = Math.random().toString(36).substr(2, 9)
    },
    editActivity(item) {
      this.selectedActivity = item
      this.keyActivity = Math.random().toString(36).substr(2, 9)
      this.changeUniqueId()
      this.$nextTick(function () {
        this.$refs['create-activity']?.show()
      })
    },
    getEmployeeById(employeeId) {
      if (this.activityEmployeeList.length === 0) return '-'
      return this.activityEmployeeList.persons.find(object => object.id === employeeId) || '-'
    },
    createReminderClicked(reminder = null) {
      if (reminder !== null) {
        this.remainderForEdit = reminder
      } else {
        this.remainderForEdit = null
      }
      this.$nextTick(function () {
        this.$refs['modal-creation']?.show()
      })
    },
    toTimestamp(strDate) {
      return Date.parse(strDate) / 1000
    },
    showReminderDetails(item) {
      this.selectedReminder = item
      this.$nextTick(function () {
        this.$refs['detail-reminder-modal']?.show()
      })
    },
    openSendMailModal(type = '') {
      this.setSendMailContacts(this.contactsList)
      // this.setSendMailPreselectedContact(this.contact)
      this.setSendMailReferenceIds([this.request.id])
      this.setSendMailEmailToReply(null)
      if (type === 'request-application-form') {
        this.fromApplicationForm = true
        ObjectApi.getApplicationLink(this.request.objectId)
          .then(response => {
            this.setSubject(this.$t('message.marketingMessages.applicationForm'))
            this.setAdditionalText(this.$t('message.marketingMessages.linkToApplicationForm') + ' ' + response.link)
            this.setRenderWithObjectId(this.request.objectId)
            this.$nextTick(function () {
              this.callShowSendMailModal()
            })
          })
          .catch(e => {
            console.log(e)
            Vue.toasted.show(this.$t('message.onBoarding.loadingErrors.applicationLink'), { type: 'error' })
          })
      } else {
        this.fromApplicationForm = false
        this.$nextTick(function () {
          this.setRenderWithObjectId(this.request.objectId)
          this.callShowSendMailModal()
        })
      }
    },
    openReferenceModal(requestType) {
      this.requestType = ''
      this.requestType = requestType
      this.$nextTick(function () {
        this.$refs['modal-reference']?.show()
      })
    },
    requestApplicationForm() {
      RequestApi.partiallySetChecklist([this.request.id], { applicationFormRequested: true })
        .then(() => {
          this.request.checklist.applicationFormRequested = true
        })
        .catch(e => {
          console.log(e)
        })
    },
    requestContractReceived() {
      RequestApi.partiallySetChecklist([this.request.id], { contractReceived: true })
        .then(() => {
          this.request.checklist.contractReceived = true
        })
        .catch(e => {
          console.log(e)
        })
    },
    replyMail(email) {
      this.setRenderWithObjectId(this.request.objectId)
      this.fromApplicationForm = false
      this.prepareReplyMail(email, [this.contact])
      this.setSendMailReferenceIds([this.request.id])
    },
    replyAllMail(email) {
      this.setRenderWithObjectId(this.request.objectId)
      this.fromApplicationForm = false
      this.prepareReplyAll(email)
      this.setSendMailReferenceIds([this.request.id])
    },
    showEmail(item) {
      this.selectedEmail = item
      this.emailToReply = null
      this.$nextTick(function () {
        this.$refs['detail-mail']?.show()
      })
    },
    toggleState(status) {
      this.request.checklist[status] = !this.request.checklist[status]
      RequestApi.setChecklist(this.request.checklist, [this.request.id])
        .catch(e => {
          console.log(e)
        })
    },
    getContactById(contactId) {
      if (this.contact.id === contactId) return this.contact
      if (!this.contact || (this.contact.contactPersons && this.contact.contactPersons.length === 0)) return '-'
      if (!this.contact || (this.contact.relations)) return this.contactsList ? this.contactsList.find(object => object.id === contactId) || '-' : '-'
      return this.contact.contactPersons.find(object => object.id === contactId) || '-'
    },
    newEmployerReferences(references) {
      for (const requester of this.request.requesters) {
        requester.employerReference = references[requester.contactId]
      }
      this.checkForReferences()
      this.updateUnreadStatus()
    },
    newLandlordReferences(references) {
      for (const requester of this.request.requesters) {
        requester.landlordReference = references[requester.contactId]
      }
      this.checkForReferences()
      this.updateUnreadStatus()
    },
    refreshDocuments() {
      this.loadRequesterContacts()
    },
    getDuration(timestampStart, timestampEnd) {
      if (timestampStart && timestampEnd) {
        const start = new Date(parseInt(timestampStart) * 1000)
        const end = new Date(parseInt(timestampEnd) * 1000)
        const diff = end - start
        const filterInstance = this.$options.filters
        return `${filterInstance.timestampToTimeOnly(diff / 1000)}`
      }
      return null
    },
    // checkEmpty(arr, type) {
    //   if (type === 'landlord') {
    //     const landl = arr.filter(o => o.landlordReference !== null)
    //     if (landl.length < arr.length) {
    //       return false
    //     }
    //     return landl && landl.map(land => land.landlordReference.complaints !== '' || land.landlordReference.contactPerson !== '' || land.landlordReference.costs !== '' || land.landlordReference.duration !== '' || land.landlordReference.name !== '' || land.landlordReference.notes !== '' || land.landlordReference.punctualPayment !== '').every(val => val === true)
    //   } else if (type === 'employee') {
    //     const emp = arr.filter(o => o.employerReference !== null)
    //     if (emp.length < arr.length) {
    //       return false
    //     }
    //     return emp && emp.map(empl => empl.employerReference.contactPerson !== '' || empl.employerReference.duration !== '' || empl.employerReference.name !== '' || empl.employerReference.notes !== '' || empl.employerReference.probation !== '' || empl.employerReference.salary !== '' || empl.employerReference.temporalEmployment !== '').every(val => val === true)
    //   }
    //   return false
    // },
    isReferenceFinished(arr, type) {
      if (type === 'landlord') {
        const landl = arr.filter(o => o.landlordReference !== null).map(empl => empl.landlordReference)
        return landl && landl.filter(x => x.finalized).length > 0
      } else if (type === 'employee') {
        const emp = arr.filter(o => o.employerReference !== null).map(empl => empl.employerReference)
        return emp && emp.filter(x => x.finalized).length > 0
      }
      return false
    },
    checkForReferences() {
      const finalizedLand = this.request.requesters.filter(o => o.landlordReference?.finalized)
      const finalizedEmp = this.request.requesters.filter(o => o.employerReference?.finalized)
      if (((finalizedEmp.length > 0 && !('contactPersons' in this.contact)) || (finalizedEmp.length === 0 && ('contactPersons' in this.contact))) && finalizedLand.length > 0) {
        RequestApi.partiallySetChecklist([this.request.id], { referencesObtained: true })
          .then(() => {
            this.request.checklist.referencesObtained = true
          })
      }
    },
    setRequestsFavorite() {
      if (this.favoriteState) {
        RequestApi.unsetRequestFavorite([this.request.id])
          .catch(() => {
            this.favoriteState = !this.favoriteState
            Vue.toasted.show(this.$t('message.savingErrors.favorite'), { type: 'error' })
          })
      } else {
        RequestApi.setRequestFavorite([this.request.id])
          .catch(() => {
            this.favoriteState = !this.favoriteState
            Vue.toasted.show(this.$t('message.savingErrors.favorite'), { type: 'error' })
          })
      }
      this.favoriteState = !this.favoriteState
    },
    isSomeDataDifferentAction(check, text) {
      this.isSomeDataDifferent = check
      this.textUpdated = text
    },
    addAsTenantClicked() {
      this.$router.push({
        name: 'MarketingAddTenancyView',
        params: {
          objectId: this.request.objectId
        },
        query: {
          requestId: this.request.id,
          tenancyId: this.request.tenancyId || undefined
        }
      })
    },
    deleteTenancyClicked() {
      this.$bvModal.show('deleteTenancyConfirmationModal')
    },
    deleteTenancy(cancelRequest = false) {
      ObjectApi.deleteTenancy(this.request.tenancyId)
        .then(() => {
          this.request.tenancyId = ''
          if (cancelRequest) {
            this.cancelSignRequest()
          }
          this.$emit('delete-tenancy')
          this.$bvModal.hide('deleteTenancyConfirmationModal')
          Vue.toasted.show(this.$t('message.onBoarding.buildings.objects.tenancies.tenancy') + ' ' + this.$t('message.successMessages.deletion'), { type: 'success' })
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.deleteErrors.tenancies'), { type: 'error' })
        })
    },
    reject() {
      const isRejected = this.checkIfRejected([this.request], [this.request.id])
      const isReserved = this.checkIfReserved([this.request], [this.request.id])
      if (isRejected) {
        Vue.toasted.show(this.$t('message.loadingErrors.rejectRequestsOnce'), { type: 'error' })
      } else if (isReserved) {
        if (this.isTenancyActivated) {
          Vue.toasted.show(this.$t('message.loadingErrors.rejectReservedRequestsActiveTenant'), { type: 'error' })
        } else {
          Vue.toasted.show(this.$t('message.loadingErrors.rejectReservedRequests'), { type: 'error' })
        }
      } else {
        this.$bvModal.show('requestRejectConfirmationModal')
      }
    },
    reactivate() {
      this.reactivateRequests(this.listRequest, [this.request.id])
      const req = this.request
      req.rejected = false
      this.$emit('request-updated', req)
    },
    activateTenancyClicked() {
      this.showActivateTenancyModal = true
      this.$nextTick(() => {
        this.$refs.activateTenancyModal?.show()
      })
    },
    rejectAfterActivateTenancy() {
      if (this.listRequest.filter(r => r.id !== this.request.id && !r.rejected).length > 0) {
        this.isActivated = true
        this.$bvModal.show('requestRejectConfirmationModal')
      } else {
        this.$emit('tenancy-activated', this.request.id)
        this.updateUnreadStatus()
      }
    },
    hideRequestRejectModal() {
      this.$bvModal.hide('requestRejectConfirmationModal')
      this.isActivated = false
      this.updateUnreadStatus()
    },
    openUnlinkModal(element) {
      this.contactToUnlink = element.id
      this.$refs.confirmationModalLinkContact?.show()
    },
    unlinkPersons() {
      this.disabledLinkBtn = true
      RequestApi.removeRequesters(this.request.id, [this.contactToUnlink])
        .then(() => {
          this.$refs.confirmationModalLinkContact.hide()
          this.disabledLinkBtn = false
          const t = this
          this.contactsList = this.contactsList.filter(function (obj) {
            return obj.id !== t.contactToUnlink
          })
          Vue.toasted.show(this.$t('message.successMessages.contactUnLinked'), { type: 'success' })
        })
        .catch(e => {
          this.disabledLinkBtn = false
          console.log(e)
          Vue.toasted.show(this.$t('message.deleteErrors.contact'), { type: 'error' })
        })
    },
    contactPersonCreated(item) {
      this.contact.contactPersons.push(item)
      this.updateUnreadStatus()
    },
    contactPersonEdited(item) {
      this.selectedPerson = null
      for (let index = 0; index < this.contact.contactPersons.length; index++) {
        if (this.contact.contactPersons[index].id === item.id) {
          this.contact.contactPersons[index] = item
          break
        }
      }
      this.$refs.contactPersonTable.refresh()
      this.updateUnreadStatus()
    },
    contactPersonDeleted(id) {
      for (let index = 0; index < this.contact.contactPersons.length; index++) {
        if (this.contact.contactPersons[index].id === id) {
          this.contact.contactPersons.splice(index, 1)
        }
      }
      this.updateUnreadStatus()
    },
    isDeath(date) {
      return !!(date && new Date().getTime() >= new Date(date.year + '-' + date.month + '-' + date.day).getTime())
    },
    moveRequest() {
      this.$router.push({ name: 'MarketingObjectsView', query: { linkRequest: this.request.id, originalObjectId: this.request.objectId } })
    },
    forwardMail(email) {
      this.prepareForwardMail(email)
    },
    deletedMail(mailId) {
      if (mailId) {
        const index = this.mailList.findIndex(x => x.id === mailId)
        if (index > -1) {
          this.mailList.splice(index, 1)
        }
      }
    },
    archivedMail(mailId) {
      if (mailId) {
        const index = this.mailList.findIndex(x => x.id === mailId)
        if (index > -1) {
          this.mailList[index].archived = true
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>

  .card-data {
    p {
      padding: 0.7rem 0.7rem;
    }

    & > .row.m-0:nth-child(odd) {
      background-color: $color-bg-white;
    }

    & > .row.m-0:nth-child(even) {
      background-color: $color-bg-white;
    }

    a:hover {
      text-decoration: none;
    }
  }

  .pointer-link {
    cursor: pointer;
    text-decoration: underline;
    color: #333;
  }

  :deep(table.table) {
    border-radius: 0;
    box-shadow: none;

    :deep(tbody > .b-table-row-selected td) {
      color: inherit !important;
    }

    :deep(thead > tr > th) {
      background-color: #f6f6f6 !important;
    }

    :deep(tbody > tr td) {
      background-color: $color-bg-white !important;
    }

    :deep(tbody > tr:hover td) {
      background-color: rgba(0, 0, 0, 0.04) !important;
    }
  }
  :deep(.no-lower-height.lower-height) {
    margin-bottom: 0;
    & > .card-body {
      padding: 1.25rem !important;
    }
  }
  .text-gray {
    color: $color-text-grey-light;
    a {
    color: inherit;
    &:hover{
      text-decoration: underline;
    }
    &.link {
      color: inherit !important;
    }
  }
  }
  .format-checklist-item {
    button {
      height: 4rem;
      &.default:hover, &.default:focus, &.default:active {
        background-color: initial;
      }
    }
  }

  .green-link {
    svg, p {
      color: #8cc46e;
    }
  }
  button.light-green {
    background-color: #e2f0da !important;
  }
  button.default {
    background-color: #fff !important;
  }
</style>
