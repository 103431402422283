<template>
  <section>
    <div
      v-if="loading"
      class="row">
      <div class="col text-center">
        <coozzy-spinner class="m-3" />
      </div>
    </div>
    <div
      v-else>
      <!-- Heading -->
      <div class="row justify-content-between align-items-center">
        <div class="col-auto">
          <h1 class="wrap">
            {{ pageTitle }}
          </h1>
        </div>
      </div>
      <div
        v-if="showTicketPreview">
        <ticket-details-view
          :order-ticket="ticketPreview" />
      </div>
      <coozzy-card
        v-if="!showTicketPreview">
        <div class="row">
          <div class="col-12 col-md-8 mb-3">
            <div class="row mb-2">
              <div class="col-12 col-md-3">
                <label>{{ $t('message.ticketOverview.ticket.type.title') }}*</label>
                <coozzy-form-select
                  v-model="ticket.type"
                  :disabled="ticket.id?.startsWith('ticket_')"
                  :state="$v.ticket.type.$dirty && $v.ticket.type.$error ? false : null"
                  class="bg-init">
                  <option
                    v-for="item in availableTypes"
                    :key="item"
                    :value="item">
                    {{ $t('message.ticketOverview.ticket.type.' + item) }}
                  </option>
                </coozzy-form-select>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-12">
                <coozzy-form-input
                  id="title"
                  v-model="ticket.title"
                  :name="$t('message.ticketOverview.ticket.title') + '*'"
                  :state="$v.ticket.title.$dirty && $v.ticket.title.$error ? false : null"
                  type="text" />
              </div>
            </div>
            <div class="row">
              <div class="col-12 mb-2">
                <label>{{ $t('message.settings.template.titleTicket') }}
                  <span class="ml-1">
                    <coozzy-info-circle-icon
                      v-b-tooltip.hover.html="$t('message.settings.template.selectConnectionsFirst')" />
                  </span>
                </label>
                <coozzy-form-select
                  v-model="selectedTemplate"
                  name="numberEntries">
                  <option
                    value="">
                    -
                  </option>
                  <option
                    v-for="template in sortedTemplate"
                    :key="template.id"
                    :value="template.id">
                    {{ template.name }}
                  </option>
                </coozzy-form-select>
              </div>
              <div class="col-12 mb-2">
                <label for="title">{{ $t('message.ticketOverview.ticket.description') }}</label>
                <coozzy-form-textarea
                  id="description"
                  ref="newTicketTextArea"
                  v-model="ticket.description"
                  :initial="ticket.description"
                  :placeholder="$t('message.ticketOverview.ticket.description')"
                  :rows="13"
                  :show-label="false"
                  :text="ticket.description"
                  :value-text-area="ticket.description"
                  name="create-ticket-description" />
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-12">
                <label for="attachment"> {{ $t('message.ticketOverview.ticket.attachment') }}
                  ({{ filteredMediaList.length }})</label>
                <coozzy-form-file-input
                  id="file"
                  ref="coozzy-file-input"
                  :disabled="uploading"
                  accept="text/plain, application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel ,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
      ,application/vnd.ms-powerpoint,  application/vnd.openxmlformats-officedocument.presentationml.presentation, application/pdf,   image/*, video/*, .doc, .docx, .xls, .xlsx, .ppt, .pptx .pdf"
                  multiple
                  @change="addFile" />
                <div class="row mt-2 m-0">
                  <div class="col-12 p-0">
                    <div
                      v-if="countDocs > 0"
                      class="row mb-2">
                      <label
                        class="col-12 subtitle"> {{ $t('message.ticketOverview.attachement.document') }} ({{
                          countDocs
                        }})</label>
                      <template
                        v-for="media in filteredMediaList">
                        <div
                          v-if="!(['image/jpeg', 'image/png', 'image/gif', 'video/webm', 'video/mp4'].indexOf(media.mimeType) !== -1)"
                          :key="media.id"
                          class="col-12 square">
                          <div
                            class="file-block">
                            <a
                              :href="media.url"
                              data-v-09485260=""
                              tabindex="0"
                              target="_blank">
                              {{ media.filename }}
                            </a>
                            <coozzy-button
                              class="delete-icon"
                              design="transparent"
                              @click="deleteMedia($event, media.id)">
                              <coozzy-delete-icon />
                            </coozzy-button>
                          </div>
                        </div>
                      </template>
                    </div>
                    <div
                      v-if="listRequestsMedia.length > 0"
                      class="row mb-2">
                      <label
                        class="col-12 subtitle"> {{ $t('message.ticketOverview.attachement.document') }}
                        ({{ listRequestsMedia.length }})</label>
                      <template
                        v-for="media in listRequestsMedia">
                        <div
                          :key="media.id"
                          class="col-12 square">
                          <div
                            class="file-block">
                            <p
                              class="link m-0"
                              @click="downloadRequestsFile(media.blob, media.filename)">
                              {{ media.filename }}
                            </p>
                            <coozzy-button
                              class="delete-icon"
                              design="transparent"
                              @click="deleteRequestMedia($event, media.requestId)">
                              <coozzy-delete-icon />
                            </coozzy-button>
                          </div>
                        </div>
                      </template>
                    </div>
                    <div
                      v-if="countImg > 0"
                      class="row m-0 mb-2">
                      <label class="col-12 p-0 subtitle"> {{ $t('message.ticketOverview.attachement.image') }}
                        ({{ countImg }})</label>
                      <template
                        v-for="media in filteredMediaList">
                        <div
                          v-if="['image/jpeg', 'image/png', 'image/gif'].indexOf(media.mimeType) !== -1"
                          :key="media.id"
                          class="media-block col-2 square">
                          <div
                            :style="{ 'background-image': 'url(' + media.url + ')' }"
                            class="uploaded-file w-100"
                            style="background-repeat: no-repeat;background-size: cover;background-position: center center;"
                            @click="imageClicked(media)">
                            <coozzy-button
                              class="delete-icon image"
                              design="transparent"
                              @click="deleteMedia($event, media.id)">
                              <coozzy-delete-icon />
                            </coozzy-button>
                          </div>
                        </div>
                      </template>
                    </div>
                    <div
                      v-if="countVideo > 0"
                      class="row m-0">
                      <label class="col-12 p-0 subtitle"> {{ $t('message.ticketOverview.attachement.video') }}
                        ({{ countVideo }})</label>
                      <template
                        v-for="media in filteredMediaList">
                        <div
                          v-if="['video/webm', 'video/mp4'].indexOf(media.mimeType) !== -1"
                          :key="media.id"
                          class="media-block col-2 square">
                          <coozzy-thumbnail
                            v-if="getMediaUrl(media) !== null"
                            :key="media.id"
                            :video-src="getMediaUrl(media)"
                            :video-url="media.url"
                            class="uploaded-file cursor-pointer"
                            @imageClicked="openVideo(media)" />
                          <coozzy-button
                            class="delete-icon video"
                            design="transparent"
                            @click="deleteMedia($event, media.id)">
                            <coozzy-delete-icon />
                          </coozzy-button>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="list-connections"
              class="row mb-2 connection">
              <label
                class="col-12"
                for="connections"> {{ $t('message.ticketOverview.ticket.connections') }}</label>
              <div
                v-for="(ref, index) in references"
                :key="index"
                class="col-12 d-flex align-items-center">
                <coozzy-button
                  class="border mb-2"
                  design="transparent"
                  size="small"
                  @click="removeReference(index)">
                  <coozzy-delete-icon />
                </coozzy-button>
                <div class="containerCircles">
                  <div
                    v-if="ref.tenancy"
                    v-b-tooltip="{container: '#list-connections', placement : 'left', title : $t('message.ticketOverview.ticket.connection_items.tenancy')}"
                    class="color-box mt-0 ml-2 mr-2 tenancy" />
                  <div
                    v-if="ref.condominium"
                    v-b-tooltip="{container: '#list-connections', placement : 'left', title : $t('message.ticketOverview.ticket.connection_items.condo_owner')}"
                    class="color-box mt-0 ml-2 mr-2 condo_owner" />
                  <div
                    v-if="ref.object && ref.object.condominiumOwnership === true && !ref.tenancy && !ref.condominium && !ref.device && !ref.request"
                    v-b-tooltip="{container: '#list-connections', placement : 'left', title : $t('message.ticketOverview.ticket.connection_items.condominium')}"
                    class="color-box mt-0 ml-2 mr-2 condominium" />
                  <div
                    v-if="ref.object && ref.object.condominiumOwnership === false && !ref.tenancy && !ref.condominium && !ref.device && !ref.request"
                    v-b-tooltip="{container: '#list-connections', placement : 'left', title : $t('message.ticketOverview.ticket.connection_items.object')}"
                    class="color-box mt-0 ml-2 mr-2 object" />
                  <div
                    v-if="ref.device"
                    v-b-tooltip="{container: '#list-connections',placement : 'left', title : $t('message.ticketOverview.ticket.connection_items.device')}"
                    class="color-box mt-0 ml-2 mr-2 device" />
                  <div
                    v-if="ref.mortgage"
                    v-b-tooltip="{container: '#collapse-connections',placement : 'left', title : $t('message.ticketOverview.ticket.connection_items.mortgage')}"
                    class="color-box mt-0 ml-2 mr-2 mortgage" />
                  <div
                    v-if="ref.insurance"
                    v-b-tooltip="{container: '#collapse-connections',placement : 'left', title : $t('message.ticketOverview.ticket.connection_items.insurance')}"
                    class="color-box mt-0 ml-2 mr-2 insurance" />
                  <div
                    v-if="ref.building && !ref.object && !ref.device && !ref.mortgage && !ref.insurance"
                    v-b-tooltip="{container: '#list-connections',placement : 'left', title : $t('message.ticketOverview.ticket.connection_items.building')}"
                    class="color-box mt-0 ml-2 mr-2 not_activated" />
                  <div
                    v-if="ref.property && !ref.building && !ref.device && !ref.mortgage && !ref.insurance"
                    v-b-tooltip="{container: '#list-connections',placement : 'left', title : $t('message.ticketOverview.ticket.connection_items.property')}"
                    class="color-box mt-0 ml-2 mr-2 current" />
                  <div
                    v-if="ref.contact"
                    v-b-tooltip="{container: '#list-connections',placement : 'left', title : $t('message.ticketOverview.ticket.connection_items.contact')}"
                    class="color-box mt-0 ml-2 mr-2 terminated" />
                  <div
                    v-if="ref.request"
                    v-b-tooltip="{container: '#list-connections',placement : 'left', title : $t('message.ticketOverview.ticket.connection_items.request')}"
                    class="color-box mt-0 ml-2 mr-2 request" />
                </div>
                <span v-if="ref.property">
                  <router-link
                    v-if="!isMarketingModule"
                    :to="redirectToPropertyDetail(ref.property.objectID || ref.property.id)">
                    {{ ref.property.name }}
                  </router-link>
                  <span v-else>{{ ref.property.name }}</span>
                </span>
                <span v-if="ref.building">
                  <span v-if="ref.property"> > </span>
                  <router-link
                    v-if="!isMarketingModule"
                    :to="redirectToBuildingDetail(ref.building.objectID || ref.building.id)">
                    {{ ref.building.name }}
                  </router-link>
                  <span v-else>{{ ref.building.name }}</span>
                </span>
                <span v-if="ref.object">
                  <span v-if="ref.building"> > </span>
                  <router-link
                    v-if="!isMarketingModule"
                    :to="redirectToDetail(ref.object.buildingId, ref.object.objectID || ref.object.id, 'objects')">
                    {{
                      ref.object.name !== '' ? ref.object.name : ref.object.number !== '' ? ref.object.number : ref.object.numericId
                    }}
                  </router-link>
                  <span v-else> {{
                    ref.object.name !== '' ? ref.object.name : ref.object.number !== '' ? ref.object.number : ref.object.numericId
                  }}</span>
                </span>

                <span v-if="ref.mortgage">
                  <template v-if="ref.building">
                    <span> > </span>
                    <span
                      v-if="!isMarketingModule"
                      @click="setMortgageId('building', ref.mortgage.id)"
                      @click.middle="setMortgageId('building', ref.mortgage.id)">
                      <router-link
                        :to="redirectToBuildingDetail(ref.building.id)">
                        {{
                          ref.mortgage.bankName + ' - ' + $t('message.onBoarding.mortgage.mortgageDesignation.' + ref.mortgage.mortgageDesignation)
                        }}
                      </router-link>
                    </span>
                    <span v-else>{{
                      ref.mortgage.bankName + ' - ' + $t('message.onBoarding.mortgage.mortgageDesignation.' + ref.mortgage.mortgageDesignation)
                    }}</span>
                  </template>
                  <template v-if="ref.property && !ref.building">
                    <span> > </span>
                    <span
                      v-if="!isMarketingModule"
                      @click="setMortgageId('property', ref.mortgage.id)"
                      @click.middle="setMortgageId('property', ref.mortgage.id)">
                      <router-link
                        v-if="!isMarketingModule"
                        :to="redirectToPropertyDetail(ref.property.id)">
                        {{
                          ref.mortgage.bankName + ' - ' + $t('message.onBoarding.mortgage.mortgageDesignation.' + ref.mortgage.mortgageDesignation)
                        }}
                      </router-link>
                    </span>
                    <span v-else>{{
                      ref.mortgage.bankName + ' - ' + $t('message.onBoarding.mortgage.mortgageDesignation.' + ref.mortgage.mortgageDesignation)
                    }}</span>
                  </template>
                </span>
                <span v-if="ref.insurance">
                  <template v-if="ref.building">
                    <span> > </span>
                    <span
                      v-if="!isMarketingModule"
                      @click="setInsuranceId('building', ref.insurance.id)"
                      @click.middle="setInsuranceId('building', ref.insurance.id)">
                      <router-link
                        v-if="!isMarketingModule"
                        :to="redirectToBuildingDetail(ref.building.id)">
                        {{ $t('message.onBoarding.buildings.insurances.type.' + ref.insurance.type) }}
                      </router-link>
                    </span>
                    <span v-else>{{ $t('message.onBoarding.buildings.insurances.type.' + ref.insurance.type) }}</span>
                  </template>
                  <template v-if="ref.property && !ref.building">
                    <span> > </span>
                    <span
                      v-if="!isMarketingModule"
                      @click="setInsuranceId('property', ref.insurance.id)"
                      @click.middle="setInsuranceId('property', ref.insurance.id)">
                      <router-link
                        v-if="!isMarketingModule"
                        :to="redirectToPropertyDetail(ref.property.id)">
                        {{ $t('message.onBoarding.buildings.insurances.type.' + ref.insurance.type) }}
                      </router-link>
                    </span>
                    <span v-else>{{ $t('message.onBoarding.buildings.insurances.type.' + ref.insurance.type) }}</span>
                  </template>
                </span>
                <span v-if="ref.device">
                  <span v-if="ref.object || ref.building || ref.property"> > </span>
                  <router-link
                    v-if="redirectToDeviceDetail(ref.device.referenceId, ref.device.objectID || ref.device.id) && !isMarketingModule"
                    :to="redirectToDeviceDetail(ref.device.referenceId, ref.device.objectID || ref.device.id)">
                    <template v-if="ref.device.type && ref.device.type !== 'DEVICE_TYPE_UNDEFINED'">
                      {{
                        $t('message.onBoarding.devices.typeValues.' + ref.device.type).length > 50 ? $t('message.onBoarding.devices.typeValues.' + ref.device.type).slice(0, 50) + '...' : $t('message.onBoarding.devices.typeValues.' + ref.device.type)
                      }}
                    </template>
                    <template v-else>
                      -
                    </template>
                  </router-link>
                  <span v-else>
                    <template v-if="ref.device.type && ref.device.type !== 'DEVICE_TYPE_UNDEFINED'">
                      {{
                        $t('message.onBoarding.devices.typeValues.' + ref.device.type).length > 50 ? $t('message.onBoarding.devices.typeValues.' + ref.device.type).slice(0, 50) + '...' : $t('message.onBoarding.devices.typeValues.' + ref.device.type)
                      }}
                    </template>
                    <template v-else>
                      -
                    </template>
                  </span>
                </span>
                <span v-if="ref.object && ref.tenancy">
                  <span v-if="ref.object"> > </span>
                  <router-link
                    v-if="!isMarketingModule"
                    :to="redirectToDetail(ref.object.buildingId, ref.tenancy.objectID || ref.tenancy.id, 'tenants')">
                    <template v-if="ref.tenancy.tenants">
                      <span
                        v-for="(contact, j) in ref.tenancy.tenants"
                        :key="j">
                        <span v-if="j > 0">- </span>{{ contact.name }}
                      </span>
                    </template>
                    <template v-else>
                      <span
                        v-for="(contact, j) in ref.listContact"
                        :key="j">
                        <span v-if="j > 0">- </span>{{ contact.name }}
                      </span>
                    </template>
                  </router-link>
                  <span v-else>
                    <template v-if="ref.tenancy.tenants">
                      <span
                        v-for="(contact, j) in ref.tenancy.tenants"
                        :key="j">
                        <span v-if="j > 0">- </span>{{ contact.name }}
                      </span>
                    </template>
                    <template v-else>
                      <span
                        v-for="(contact, j) in ref.listContact"
                        :key="j">
                        <span v-if="j > 0">- </span>{{ contact.name }}
                      </span>
                    </template>
                  </span>
                </span>
                <span v-if="ref.object && ref.condominium">
                  <span v-if="ref.object"> > </span>
                  <router-link
                    v-if="!isMarketingModule"
                    :to="redirectToDetail(ref.object.buildingId, ref.condominium.objectID || ref.condominium.id, 'condominium')">
                    <template v-if="ref.condominium.condominiumOwners">
                      <span
                        v-for="(contact, j) in ref.condominium.condominiumOwners"
                        :key="j">
                        {{ contact.name }}
                      </span>
                    </template>
                    <template v-else>
                      <span
                        v-for="(contact, j) in ref.listContact"
                        :key="j">
                        {{ contact.name }}
                      </span>
                    </template>
                  </router-link>
                  <span v-else>
                    <template v-if="ref.condominium.condominiumOwners">
                      <span
                        v-for="(contact, j) in ref.condominium.condominiumOwners"
                        :key="j">
                        {{ contact.name }}
                      </span>
                    </template>
                    <template v-else>
                      <span
                        v-for="(contact, j) in ref.listContact"
                        :key="j">
                        {{ contact.name }}
                      </span>
                    </template>
                  </span>
                </span>
                <span v-if="ref.room">
                  <template v-if="ref.object">
                    <span> > </span>
                    <span
                      v-if="!isMarketingModule"
                      @click="setRoomId('object', ref.room.id)"
                      @click.middle="setRoomId('object', ref.room.id)">
                      <router-link
                        :to="redirectToDetail(ref.object.buildingId, ref.object.objectID || ref.object.id, 'objects')">
                        {{ ref.room.name }}
                      </router-link>
                    </span>
                    <span v-else>{{ ref.room.name }}</span>
                  </template>
                  <template v-if="ref.building && !ref.object">
                    <span> > </span>
                    <span
                      v-if="!isMarketingModule"
                      @click="setRoomId('building', ref.room.id)"
                      @click.middle="setRoomId('building', ref.room.id)">
                      <router-link
                        :to="redirectToBuildingDetail(ref.building.id)">
                        {{ ref.room.name }}
                      </router-link>
                    </span>
                    <span v-else>{{ ref.room.name }}</span>
                  </template>
                </span>
                <span v-if="ref.contact">
                  <router-link
                    v-if="!isMarketingModule"
                    :to="redirectToContactDetail(ref.contact.objectID || ref.contact.id)">
                    {{ ref.contact.name }}
                  </router-link>
                  <span v-else>{{ ref.contact.name }}</span>
                </span>
                <span v-if="ref.request">
                  <span> > </span>
                  <router-link
                    :to="redirectToRequestDetail(ref.request.objectId, ref.request.id)">
                    <span
                      v-for="(contact, j) in ref.listContact"
                      :key="j">
                      <span v-if="j > 0">- </span>{{ contact.name }}
                    </span>
                  </router-link>
                </span>
              </div>
              <div class="col-12">
                <div class="row">
                  <div class="col-3">
                    <coozzy-form-select
                      v-model="typeOfConnection">
                      <option
                        :value="null">
                        {{ $t('message.generic.all') }}
                      </option>
                      <option
                        v-for="item in availableConnectionTypes"
                        :key="item.key"
                        :value="item">
                        {{ $t('message.ticketOverview.ticket.connection_items.' + item.key) }}
                      </option>
                    </coozzy-form-select>
                  </div>
                  <div class="col-9">
                    <ais-instant-search
                      v-if="searchClient"
                      :index-name="typeOfConnection ? typeOfConnection.value : 'object'"
                      :routing="routing"
                      :search-client="searchClient"
                      class="w-100">
                      <!-- eslint-disable vue/attribute-hyphenation -->
                      <ais-configure
                        :filters="query"
                        :hitsPerPage="10" />
                      <template v-if="typeOfConnection === null">
                        <ais-index index-name="building" />
                        <ais-index index-name="tenancy" />
                        <ais-index index-name="property" />
                        <ais-index index-name="condominium" />
                        <ais-index index-name="contact" />
                      </template>
                      <!-- eslint-disable vue/attribute-hyphenation -->
                      <ais-autocomplete
                        v-if="!indexNameChanging"
                        :key="refrecheAutosuggestKey">
                        <template #default="{ indices, refine }">
                          <vue-autosuggest
                            :key="refrecheAutosuggestKey"
                            ref="autoSuggest_connections"
                            v-model="emailSuggestion"
                            :input-props="{...inputProps, placeholder: $t('message.generic.search')}"
                            :suggestions="indicesToSuggestions(indices)"
                            @input="onChange(refine,emailSuggestion)"
                            @selected="onSelect">
                            <template #default="{ suggestion }">
                              <span
                                :id="'tooltip-target_' + suggestion.item.objectID">
                                <template
                                  v-if="suggestion.item.objectID.startsWith('tenancy')">
                                  <div
                                    :class="checkSelectedElement(suggestion.item.objectID)"
                                    class="row m-0">
                                    <div
                                      class="p-0">
                                      <div
                                        v-b-tooltip="{placement : 'left', title : $t('message.ticketOverview.ticket.connection_items.tenancy')}"
                                        class="color-box ml-2 mr-2 tenancy" />
                                    </div>

                                    <div
                                      class="col p-0">
                                      <p
                                        v-if="suggestion.item.objectID.startsWith('tenancy')"
                                        class="mb-0">
                                        <strong v-if="suggestion.item.tenants.length > 0">
                                          <span
                                            v-for="(contact, j) in suggestion.item.tenants"
                                            :key="j">
                                            {{ contact.name }} <span
                                              v-if="j < suggestion.item.tenants.length - 1">-</span>
                                          </span>
                                        </strong>
                                        <strong v-else>-</strong>
                                      </p>
                                      <p class="mb-0">
                                        {{ suggestion.item.startingAt | objectToDate }} -
                                        {{ suggestion.item.endingAt | objectToDate }}
                                      </p>
                                    </div>
                                    <span class="showSelected">
                                      {{ $t('message.ticketOverview.ticket.connection_items.selected') }}
                                    </span>
                                  </div>
                                </template>
                                <template
                                  v-if="suggestion.item.objectID.startsWith('condominium')">
                                  <div
                                    :class="checkSelectedElement(suggestion.item.objectID)"
                                    class="row m-0">
                                    <div
                                      class="p-0">
                                      <div
                                        v-b-tooltip="{placement : 'left', title : $t('message.ticketOverview.ticket.connection_items.condo_owner')}"
                                        class="color-box ml-2 mr-2 condo_owner" />
                                    </div>

                                    <div class="col p-0">
                                      <p
                                        v-if="suggestion.item.objectID.startsWith('condominium')"
                                        class="mb-0">
                                        <strong>
                                          {{
                                            suggestion.item.condominiumOwners.length > 0 ? suggestion.item.condominiumOwners[0].name : '-'
                                          }}
                                        </strong>
                                      </p>
                                      <p class="mb-0">
                                        {{ suggestion.item.startingAt | objectToDate }} -
                                        {{ suggestion.item.endingAt | objectToDate }}
                                      </p>
                                    </div>
                                    <span class="showSelected">
                                      {{ $t('message.ticketOverview.ticket.connection_items.selected') }}
                                    </span>
                                  </div>
                                </template>
                                <template
                                  v-else-if="suggestion.item.objectID.startsWith('obj')">
                                  <div
                                    :class="checkSelectedElement(suggestion.item.objectID)"
                                    class="row m-0">
                                    <div
                                      class="p-0">
                                      <div
                                        v-b-tooltip="{placement : 'left', title : $t('message.ticketOverview.ticket.connection_items.' + [suggestion.item.condominiumOwnership === true ? 'condominium' : 'object'])}"
                                        :class="[suggestion.item.condominiumOwnership === true ? 'condominium' : 'object']"
                                        class="color-box ml-2 mr-2" />
                                    </div>
                                    <div class="col p-0">
                                      <p class="mb-0">
                                        <b>{{ suggestion.item.name }}</b>
                                      </p>
                                      <p class="mb-0">
                                        <address-text
                                          :address="suggestion.item.address"
                                          :hide-if-empty="true"
                                          :show-short-country="true" />
                                      </p>
                                    </div>
                                    <span class="showSelected">
                                      {{ $t('message.ticketOverview.ticket.connection_items.selected') }}
                                    </span>
                                  </div>
                                </template>
                                <template
                                  v-else-if="suggestion.item.objectID.startsWith('prop')">
                                  <div
                                    :class="checkSelectedElement(suggestion.item.objectID)"
                                    class="row m-0">
                                    <div
                                      class="p-0">
                                      <div
                                        v-b-tooltip="{placement : 'left', title : $t('message.ticketOverview.ticket.connection_items.property')}"
                                        class="color-box ml-2 mr-2 current" />
                                    </div>
                                    <div class="col p-0">
                                      <p class="mb-0">
                                        <b>{{ suggestion.item.name }}</b>
                                      </p>
                                      <p class="mb-0">
                                        <address-text
                                          :address="suggestion.item.address"
                                          :hide-if-empty="true"
                                          :show-short-country="true" />
                                      </p>
                                    </div>
                                    <span class="showSelected">
                                      {{ $t('message.ticketOverview.ticket.connection_items.selected') }}
                                    </span>
                                  </div>
                                </template>
                                <template
                                  v-else-if="suggestion.item.objectID.startsWith('building')">
                                  <div
                                    :class="checkSelectedElement(suggestion.item.objectID)"
                                    class="row m-0">
                                    <div
                                      class="p-0">
                                      <div
                                        v-b-tooltip="{placement : 'left', title : $t('message.ticketOverview.ticket.connection_items.building')}"
                                        class="color-box ml-2 mr-2 not_activated" />
                                    </div>
                                    <div class="col p-0">
                                      <p class="mb-0">
                                        <b>{{ suggestion.item.name }}</b>
                                      </p>
                                      <p class="mb-0">
                                        <address-text
                                          :address="suggestion.item.address"
                                          :hide-if-empty="true"
                                          :show-short-country="true" />
                                      </p>
                                    </div>
                                    <span class="showSelected">
                                      {{ $t('message.ticketOverview.ticket.connection_items.selected') }}
                                    </span>
                                  </div>
                                </template>
                                <template v-else-if="suggestion.item.objectID.startsWith('contact')">
                                  <div
                                    :class="checkSelectedElement(suggestion.item.objectID)"
                                    class="row m-0">
                                    <div
                                      class="p-0">
                                      <div
                                        v-b-tooltip="{placement : 'left', title : $t('message.ticketOverview.ticket.connection_items.contact')}"
                                        class="color-box ml-2 mr-2 terminated" />
                                    </div>

                                    <div class="col p-0">
                                      <strong>{{ suggestion.item.lastName }} {{ suggestion.item.firstName }}</strong>
                                      <br>
                                      <address-text
                                        :address="suggestion.item.address"
                                        :hide-if-empty="true"
                                        :show-short-country="true" />
                                      <br>
                                      <span v-if="getListRole(suggestion.item).length > 0">
                                        {{ $t('message.generic.roles') }}: {{ getListRole(suggestion.item) }}
                                      </span>
                                    </div>
                                    <span class="showSelected">
                                      {{ $t('message.ticketOverview.ticket.connection_items.selected') }}
                                    </span>
                                  </div>
                                </template>
                                <b-tooltip
                                  v-if="checkSelectedElement(suggestion.item.objectID) === 'selected'"
                                  :container="'#list-connections'"
                                  :target="'tooltip-target_' + suggestion.item.objectID"
                                  placement="left"
                                  triggers="hover">
                                  {{ $t('message.generic.alreadySelected') }}
                                </b-tooltip>
                              </span>
                            </template>
                          </vue-autosuggest>
                        </template>
                      </ais-autocomplete>
                    </ais-instant-search>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="linkedTickets"
              class="row mb-2">
              <div class="col-12">
                <label for="linkedTickets">{{ $t('message.ticketOverview.ticket.linkTickets') }}</label>
                <div
                  v-if="isEditor || isTicketEditor"
                  class="row">
                  <div class="col-12 col-md-3 mb-2">
                    <coozzy-form-select
                      v-model="typeOfLink">
                      <option
                        v-for="item in availableLinkTypes"
                        :key="item"
                        :value="item">
                        {{ $t('message.ticketOverview.ticket.linkType.' + item) }}
                      </option>
                    </coozzy-form-select>
                  </div>
                  <div class="col-12 col-md-9">
                    <vue-autosuggest
                      :key="refrecheAutosuggestKey"
                      v-model="searchForTickets"
                      :get-suggestion-value="getSuggestionValue"
                      :input-props="{...inputProps, placeholder: $t('message.ticketOverview.ticket.search')}"
                      :suggestions="resultTicketsForLink"
                      @input="getTickets()"
                      @selected="onSelectLinked"
                      @item-changed="itemChanged">
                      <template #default="{suggestion}">
                        <span
                          :class="checkIsDisabled(suggestion.item) ? 'disableOptionHover' : ''"
                          class="my-suggestion-item">
                          <img
                            :src="`/img/ticket/${suggestion.item.type.split('TICKET_TYPE_')[1].toLowerCase()}.svg`"
                            alt="type"
                            class="mr-1 mb-1"
                            height="20px">
                          {{ suggestion.item.key }} {{ suggestion.item.title }}</span>
                      </template>
                    </vue-autosuggest>
                  </div>
                </div>
                <template v-if="filteredTicketLinked('TICKET_LINK_TYPE_BLOCKS').length > 0">
                  <div class="row">
                    <div class="col">
                      <label
                        class="subtitle"
                        for="blocks">
                        {{ $t('message.ticketOverview.ticket.linkType.TICKET_LINK_TYPE_BLOCKS') }}
                      </label>
                      <div
                        v-for="item in filteredTicketLinked('TICKET_LINK_TYPE_BLOCKS')"
                        :key="item.ticketId"
                        class="row mx-0 mb-2 p-1 linked-row">
                        <template v-if="listTickets.find(x => x.id === item.ticketId)">
                          <div class="col-8 p-0 label">
                            <img
                              v-b-tooltip="{placement : 'left', title : $t('message.ticketOverview.ticket.type.' + listTickets.find(x => x.id === item.ticketId).type)}"
                              :src="`/img/ticket/${listTickets.find(x => x.id === item.ticketId).type.split('TICKET_TYPE_')[1].toLowerCase()}.svg`"
                              alt="type"
                              class="mr-1"
                              height="20px"> {{ listTickets.find(x => x.id === item.ticketId).key }}
                            {{ listTickets.find(x => x.id === item.ticketId).title }}
                          </div>
                          <div class="col d-inline pr-1">
                            <coozzy-button
                              v-if="isEditor || isTicketEditor"
                              class="border float-right mb-0 ml-2"
                              design="transparent"
                              size="small"
                              @click="removeTicketLinked(item)">
                              <coozzy-delete-icon />
                            </coozzy-button>
                            <div
                              :class="`d-inline float-right status-design ${(listTickets.find(x => x.id === item.ticketId).status.split('TICKET_STATUS_')[1]).toLowerCase()}`">
                              {{
                                $t('message.ticketOverview.status.' + listTickets.find(x => x.id === item.ticketId).status)
                              }}
                            </div>
                            <img
                              v-b-tooltip="{container: '#linkedTickets', title: listTickets.find(x => x.id === item.ticketId).assignee ? listTickets.find(x => x.id === item.ticketId).assignee.name : ''}"
                              :src="listTickets.find(x => x.id === item.ticketId).assignee ? listTickets.find(x => x.id === item.ticketId).assignee.profilePictureUrl ? listTickets.find(x => x.id === item.ticketId).assignee.profilePictureUrl : '/avatar.png' : '/avatar.png'"
                              alt="assignee"
                              class="align-self-center float-right avatar mr-2">
                            <img
                              :src="`/img/priority/prio-${listTickets.find(x => x.id === item.ticketId).priority}.svg`"
                              alt="priority"
                              class="mr-2 float-right ml-2"
                              height="20px">
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-if="filteredTicketLinked('TICKET_LINK_TYPE_RELATES').length > 0">
                  <div class="row">
                    <div class="col">
                      <label
                        class="subtitle"
                        for="blocks">
                        {{ $t('message.ticketOverview.ticket.linkType.TICKET_LINK_TYPE_RELATES') }}
                      </label>
                      <div
                        v-for="item in filteredTicketLinked('TICKET_LINK_TYPE_RELATES')"
                        :key="item.ticketId"
                        class="row mx-0 mb-2 p-1 linked-row">
                        <template v-if="listTickets.find(x => x.id === item.ticketId)">
                          <div class="col-8 p-0 label">
                            <img
                              v-b-tooltip="{placement : 'left', title : $t('message.ticketOverview.ticket.type.' + listTickets.find(x => x.id === item.ticketId).type)}"
                              :src="`/img/ticket/${listTickets.find(x => x.id === item.ticketId).type.split('TICKET_TYPE_')[1].toLowerCase()}.svg`"
                              alt="type"
                              class="mr-1"
                              height="20px"> {{ listTickets.find(x => x.id === item.ticketId).key }}
                            {{ listTickets.find(x => x.id === item.ticketId).title }}
                          </div>
                          <div class="col d-inline pr-1">
                            <coozzy-button
                              v-if="isEditor || isTicketEditor"
                              class="border float-right mb-0 ml-2"
                              design="transparent"
                              size="small"
                              @click="removeTicketLinked(item)">
                              <coozzy-delete-icon />
                            </coozzy-button>
                            <div
                              :class="`d-inline float-right status-design ${(listTickets.find(x => x.id === item.ticketId).status.split('TICKET_STATUS_')[1]).toLowerCase()}`">
                              {{
                                $t('message.ticketOverview.status.' + listTickets.find(x => x.id === item.ticketId).status)
                              }}
                            </div>
                            <img
                              v-b-tooltip="{container: '#linkedTickets', title: listTickets.find(x => x.id === item.ticketId).assignee ? listTickets.find(x => x.id === item.ticketId).assignee.name : ''}"
                              :src="listTickets.find(x => x.id === item.ticketId).assignee ? listTickets.find(x => x.id === item.ticketId).assignee.profilePictureUrl ? listTickets.find(x => x.id === item.ticketId).assignee.profilePictureUrl : '/avatar.png' : '/avatar.png'"
                              alt="assignee"
                              class="align-self-center float-right avatar mr-2">
                            <img
                              :src="`/img/priority/prio-${listTickets.find(x => x.id === item.ticketId).priority}.svg`"
                              alt="priority"
                              class="mr-2 float-right ml-2"
                              height="20px">
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-if="filteredTicketLinked('TICKET_LINK_TYPE_IS_BLOCKED_BY').length > 0">
                  <div class="row">
                    <div class="col">
                      <label
                        class="subtitle"
                        for="blocks">
                        {{ $t('message.ticketOverview.ticket.linkType.TICKET_LINK_TYPE_IS_BLOCKED_BY') }}
                      </label>
                      <div
                        v-for="item in filteredTicketLinked('TICKET_LINK_TYPE_IS_BLOCKED_BY')"
                        :key="item.ticketId"
                        class="row mx-0 mb-2 p-1 linked-row">
                        <template v-if="listTickets.find(x => x.id === item.ticketId)">
                          <div class="col-8 p-0 label">
                            <img
                              v-b-tooltip="{placement : 'left', title : $t('message.ticketOverview.ticket.type.' + listTickets.find(x => x.id === item.ticketId).type)}"
                              :src="`/img/ticket/${listTickets.find(x => x.id === item.ticketId).type.split('TICKET_TYPE_')[1].toLowerCase()}.svg`"
                              alt="type"
                              class="mr-1"
                              height="20px"> {{ listTickets.find(x => x.id === item.ticketId).key }}
                            {{ listTickets.find(x => x.id === item.ticketId).title }}
                          </div>
                          <div class="col d-inline pr-1">
                            <coozzy-button
                              v-if="isEditor || isTicketEditor"
                              class="border float-right mb-0 ml-2"
                              design="transparent"
                              size="small"
                              @click="removeTicketLinked(item)">
                              <coozzy-delete-icon />
                            </coozzy-button>
                            <div
                              :class="`d-inline float-right status-design ${(listTickets.find(x => x.id === item.ticketId).status.split('TICKET_STATUS_')[1]).toLowerCase()}`">
                              {{
                                $t('message.ticketOverview.status.' + listTickets.find(x => x.id === item.ticketId).status)
                              }}
                            </div>
                            <img
                              v-b-tooltip="{container: '#linkedTickets', title: listTickets.find(x => x.id === item.ticketId).assignee ? listTickets.find(x => x.id === item.ticketId).assignee.name : ''}"
                              :src="listTickets.find(x => x.id === item.ticketId).assignee ? listTickets.find(x => x.id === item.ticketId).assignee.profilePictureUrl ? listTickets.find(x => x.id === item.ticketId).assignee.profilePictureUrl : '/avatar.png' : '/avatar.png'"
                              alt="assignee"
                              class="align-self-center float-right avatar mr-2">
                            <img
                              :src="`/img/priority/prio-${listTickets.find(x => x.id === item.ticketId).priority}.svg`"
                              alt="priority"
                              class="mr-2 float-right ml-2"
                              height="20px">
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="row mb-2">
              <div class="col">
                <label>{{ $t('message.ticketOverview.ticket.assignee') + '*' }}</label>
                <v-select
                  :key="listAllEmployees.length"
                  v-model="ticket.assignee"
                  :class="$v.ticket.assignee.$dirty && $v.ticket.assignee.$error ? 'is-invalid' : ''"
                  :filter="searchAssignee"
                  :options="listAllEmployees"
                  class="assignee">
                  <template
                    v-if="option.value"
                    slot="option"
                    slot-scope="option">
                    <img
                      :alt="option.label"
                      :src="option.value.profile.pictureUrl !== '' ? option.value.profile.pictureUrl : '/avatar.png'"
                      class="align-self-center avatar"
                      height="15px">
                    {{ option.label }} <b>{{ option.company ? '-' + option.company : '' }}</b>
                  </template>
                  <template
                    v-if="option.value"
                    slot="selected-option"
                    slot-scope="option">
                    <div>
                      <img
                        :alt="option.label"
                        :src="option.value.profile.pictureUrl !== '' ? option.value.profile.pictureUrl : '/avatar.png'"
                        class="align-self-center avatar"
                        height="15">
                      {{ option.label }} <b>{{ option.company ? '-' + option.company : '' }}</b>
                    </div>
                  </template>
                </v-select>
              </div>
            </div>
            <div class="row mb-2">
              <div
                v-if="ticket.type !== 'TICKET_TYPE_TASK'"
                class="col">
                <ais-instant-search
                  v-if="searchClient"
                  :routing="routing"
                  :search-client="searchClient"
                  index-name="contact">
                  <!-- eslint-disable vue/attribute-hyphenation -->
                  <ais-configure
                    :filters="filterQueryOperator"
                    :hitsPerPage="10" />
                  <!-- eslint-disable vue/attribute-hyphenation -->
                  <ais-autocomplete>
                    <template #default="{ indices, refine }">
                      <label>{{ $t('message.ticketOverview.ticket.operator') }}</label>
                      <coozzy-search-multiselect
                        v-model="correspondencesContactPerson"
                        :clear-on-select="false"
                        :close-on-select="true"
                        :custom-label="customLabel"
                        :disabled="ticket.type === 'TICKET_TYPE_ORDER' && id !== '' && source !== 'changeOperator' && ticket.status !== 'TICKET_STATUS_DRAFT'"
                        :multiple="true"
                        :options="indices[0].hits.filter(x => x && x.objectID)"
                        :placeholder="''"
                        :preselect-first="false"
                        :preserve-search="true"
                        :taggable="false"
                        label="name"
                        track-by="objectID"
                        @search-change="see(refine, $event)" />
                    </template>
                  </ais-autocomplete>
                </ais-instant-search>
                <label
                  v-if="listEmployeeCorrespondencesCP.length > 0"
                  class="sizeLabel">{{ $t('message.createOrder.contactPersonOfCompany') }} </label>
                <coozzy-form-select
                  v-if="listEmployeeCorrespondencesCP.length > 0"
                  v-model="correspondences"
                  :disabled="ticket.type === 'TICKET_TYPE_ORDER' && id !== '' && source !== 'changeOperator' && ticket.status !== 'TICKET_STATUS_DRAFT'"
                  class="select-entries float-right"
                  name="employeeCompany">
                  <option :value="null">
                    -
                  </option>
                  <option
                    v-for="(emp, index) in listEmployeeCorrespondencesCP"
                    :key="index"
                    :value="emp">
                    {{ emp.name }}
                  </option>
                </coozzy-form-select>
              </div>
            </div>
            <div
              v-if="ticket.type !== 'TICKET_TYPE_OFFER' && ticket.type !== 'TICKET_TYPE_TASK'"
              class="row mb-2">
              <div
                class="col">
                <ais-instant-search
                  v-if="searchClient"
                  :routing="routing"
                  :search-client="searchClient"
                  index-name="contact">
                  <!-- eslint-disable vue/attribute-hyphenation -->
                  <ais-configure
                    :filters="'isOnlyInterestedParty:false'"
                    :hitsPerPage="10" />
                  <!-- eslint-disable vue/attribute-hyphenation -->
                  <ais-autocomplete>
                    <template #default="{ indices, refine }">
                      <label>{{ $t('message.ticketOverview.ticket.contactPersonOnSite') }}</label>
                      <coozzy-search-multiselect
                        v-model="onSiteContactPersons"
                        :clear-on-select="false"
                        :close-on-select="true"
                        :custom-label="customLabel"
                        :internal-search="false"
                        :multiple="true"
                        :options="getSuggestion(indices[0].hits)"
                        :placeholder="''"
                        :preselect-first="false"
                        :preserve-search="true"
                        :taggable="false"
                        label="name"
                        track-by="objectID"
                        @search-change="see(refine, $event)" />
                    </template>
                  </ais-autocomplete>
                </ais-instant-search>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col">
                <label>{{ $t('message.ticketOverview.ticket.priority.title') }}</label>
                <v-select
                  v-model="ticket.priority"
                  :options="availablePriority"
                  label="title">
                  <template
                    slot="option"
                    slot-scope="option">
                    <img
                      :alt="$t('message.ticketOverview.ticket.priority.' + option.title)"
                      :src="`/img/priority/prio-${option.title}.svg`"
                      height="15px">
                    {{ $t('message.ticketOverview.ticket.priority.' + option.title) }}
                  </template>
                  <template
                    slot="selected-option"
                    slot-scope="option">
                    <div>
                      <img
                        :alt="$t('message.ticketOverview.ticket.priority.' + option.title)"
                        :src="`/img/priority/prio-${option.title}.svg`"
                        height="15">
                      {{ $t('message.ticketOverview.ticket.priority.' + option.title) }}
                    </div>
                  </template>
                </v-select>
              </div>
            </div>
            <div
              v-if="ticket.type !== 'TICKET_TYPE_OFFER' && ticket.type !== 'TICKET_TYPE_TASK'"
              class="row mb-2">
              <div class="col">
                <coozzy-form-input
                  v-model="dates.startDate"
                  :filter="'formatDate'"
                  :min="new Date().toISOString().slice(0, 10)"
                  :name="$t('message.ticketOverview.ticket.orderBeExecutedFrom')"
                  type="date"
                  @blur="dateToObject()" />
              </div>
            </div>
            <div class="row mb-2">
              <div class="col">
                <coozzy-form-input
                  v-model="dates.dueDate"
                  :filter="'formatDate'"
                  :min="new Date().toISOString().slice(0, 10)"
                  :name="$t('message.ticketOverview.ticket.dueDate')"
                  type="date"
                  @blur="dateToObject()" />
              </div>
            </div>
            <div
              v-if="ticket.costs && ticket.costs.costShareValue"
              class="row mb-2">
              <div class="col-6">
                <coozzy-form-input
                  v-model="costAbsorption"
                  :disabled="true"
                  :name="$t('message.ticketOverview.ticket.costs.costAbsorption')"
                  type="text" />
              </div>
              <div class="col-6">
                <coozzy-form-input
                  v-model="costShareUnit"
                  :disabled="true"
                  :name="$t('message.ticketOverview.ticket.costs.title')"
                  type="text" />
              </div>
            </div>
          </div>
        </div>
      </coozzy-card>
      <div class="row">
        <div
          v-if="!showTicketPreview"
          class="col-6 col-md-2">
          <coozzy-button
            :disabled="disableBtns"
            class="my-2 w-100 wizard-btn"
            @click="cancelClicked">
            {{ $t('message.generic.cancel') }}
          </coozzy-button>
        </div>
        <div
          v-if="ticket.type === 'TICKET_TYPE_ORDER' && (id === '' || ticket.status === 'TICKET_STATUS_DRAFT') && showTicketPreview"
          class="col-6 col-md-2">
          <coozzy-button
            :disabled="disableBtns"
            class="my-2 w-100 wizard-btn"
            @click="toggleListTickets">
            {{ $t('message.generic.form.modify') }}
          </coozzy-button>
        </div>
        <div
          v-if="id !== '' && ticket.status === 'TICKET_STATUS_DRAFT'"
          :class="ticket.status === 'TICKET_STATUS_DRAFT' ? 'offset-md-4' : ''"
          class="col-6 col-md-2">
          <coozzy-button
            :disabled="disableBtns"
            class="my-2 w-100"
            design="red"
            @click="deleteTicketClicked">
            {{ $t('message.generic.delete') }}
          </coozzy-button>
        </div>
        <div
          :class="ticket.status !== 'TICKET_STATUS_DRAFT' ? 'offset-md-6' : ''"
          class="col-6 col-md-2">
          <coozzy-button
            v-if="ticket.status === 'TICKET_STATUS_DRAFT'"
            :disabled="disableBtns"
            class="my-2 w-100"
            design="prop-green"
            @click="saveTicket(true)">
            {{ $t('message.marketingObjects.editAdvert.saveDraft') }}
          </coozzy-button>
        </div>
        <div
          v-if="ticket.type === 'TICKET_TYPE_ORDER' && (id === '' || ticket.status === 'TICKET_STATUS_DRAFT') && !showTicketPreview"
          class="col-6 col-md-2">
          <coozzy-button
            :disabled="disableBtns"
            class="my-2 w-100"
            design="prop-green"
            @click="toggleListTickets">
            {{ $t('message.ticketPreview') }}
          </coozzy-button>
        </div>
        <div
          v-if="id !== '' && ticket.status !== 'TICKET_STATUS_DRAFT' || (ticket.type === 'TICKET_TYPE_ORDER' && showTicketPreview) || ticket.type !== 'TICKET_TYPE_ORDER'"
          class="col-6 col-md-2">
          <coozzy-button
            :disabled="disableBtns"
            class="my-2 w-100"
            design="prop-green"
            @click="saveTicket(false)">
            {{
              ticket.type === 'TICKET_TYPE_TASK' && !ticket.id ? $t('message.generic.create') : ticket.type === 'TICKET_TYPE_ORDER' && !ticket.id || ticket.id === '' || ticket.type === 'TICKET_TYPE_ORDER' && ticket.status === 'TICKET_STATUS_DRAFT' ? $t('message.generic.form.send') : $t('message.generic.form.save')
            }}
          </coozzy-button>
        </div>
      </div>
    </div>
    <b-modal
      id="confirmationModal"
      ref="confirmationModal"
      :title="$t('message.onBoardingTabs.confirmModal.confirmationTitle')"
      hide-footer
      no-close-on-backdrop>
      <p>{{ $t('message.onBoardingTabs.confirmModal.confirmationText') }}</p>
      <div class="col">
        <coozzy-button
          class="border mb-0"
          design="transparent"
          size="small"
          @click="$bvModal.hide('confirmationModal')">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
        <coozzy-button
          class="float-right mb-0"
          design="green"
          size="small"
          @click="confirmCancellation">
          {{ $t('message.onBoardingTabs.confirmModal.confirmationButton') }}
        </coozzy-button>
      </div>
    </b-modal>
    <b-modal
      v-if="showDeleteTicketModal"
      id="deleteConfirmationModal"
      ref="deleteConfirmationModal"
      :title="$t('message.ticketOverview.deleteTicketModal.title')"
      hide-footer
      no-close-on-backdrop>
      <p>{{ $t('message.ticketOverview.deleteTicketModal.text') }}</p>
      <div class="col">
        <coozzy-button
          class="border mb-0"
          design="transparent"
          size="small"
          @click="hideDeleteConfirmationModal">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
        <coozzy-button
          class="float-right mb-0"
          design="green"
          size="small"
          @click="confirmDeletion">
          {{ $t('message.onBoardingTabs.confirmModal.confirmationButton') }}
        </coozzy-button>
      </div>
    </b-modal>
    <b-modal
      v-if="selectedObjectVideo !== ''"
      ref="show-video"
      hide-footer
      hide-header>
      <div class="row mb-2">
        <div class="col">
          <b-embed
            controls
            type="video">
            <source
              :src="selectedObjectVideo"
              type="video/webm">
            <source
              :src="selectedObjectVideo"
              type="video/mp4">
          </b-embed>
        </div>
      </div>
    </b-modal>
  </section>
</template>

<script>
import ObjectApi from '@/misc/apis/ObjectApi'
import ContactApi from '@/misc/apis/ContactApi'
import { required } from 'vuelidate/lib/validators'
import AddressText from '@/framework/components/misc/AddressText'
import Vue from 'vue'
import { algolia } from '@/mixins/algolia'
import TicketApi from '@/misc/apis/TicketApi'
import UserApi from '@/misc/apis/UserApi'
import RequestApi from '@/marketingBasic/api/RequestApi'
import ShareApi from '@/misc/apis/ShareApi'
import CoozzySearchMultiselect from '@/framework/components/multiselect/CoozzySearchMultiselect'
import CoozzyFormSelect from '@/framework/components/form/select/CoozzyFormSelect'
import CoozzyFormInput from '@/framework/components/form/input/CoozzyFormInput'
import CoozzyButton from '@/framework/components/button/CoozzyButton'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner'
import { user } from '@/mixins/user'
import { validation } from '@/mixins/validation'
import { mapActions, mapGetters, mapState } from 'vuex'
import { dateUtils } from '@/mixins/dateUtils'
import { VueAutosuggest } from 'vue-autosuggest'
import { routeChecks } from '@/mixins/routeChecks'
import MediaApi from '@/misc/apis/MediaApi'
import CoozzyCard from '@/framework/components/card/CoozzyCard'
import CoozzyFormFileInput from '@/framework/components/form/fileInput/CoozzyFormFileInput'
import CoozzyDeleteIcon from '@/framework/components/icons/CoozzyDeleteIcon'
import { media } from '@/mixins/media'
import { formatting } from '@/mixins/formatting'
import TicketDetailsView from '@/management/components/ticket/TicketDetailsView'
import CoozzyThumbnail from '@/framework/components/img/CoozzyThumbnail.vue'
import MessageApi from '@/misc/apis/MessageApi'
import CoozzyInfoCircleIcon from '@/framework/components/icons/CoozzyInfoCircleIcon.vue'
import RoomApi from '@/misc/apis/RoomApi'
import CoozzyFormTextarea from '@/framework/components/form/textarea/CoozzyFormTextarea.vue'

export default {
  name: 'TicketCreation',
  components: {
    CoozzyFormTextarea,
    CoozzyInfoCircleIcon,
    CoozzyThumbnail,
    CoozzyDeleteIcon,
    CoozzyFormFileInput,
    CoozzyCard,
    CoozzyFormInput,
    CoozzySearchMultiselect,
    CoozzySpinner,
    AddressText,
    VueAutosuggest,
    CoozzyButton,
    CoozzyFormSelect,
    TicketDetailsView
  },
  mixins: [user, validation, algolia, routeChecks, dateUtils, media, formatting],
  metaInfo() {
    return {
      title: this.pageTitle
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
  beforeRouteLeave(to, from, next) {
    this.nextToPath = to
    this.nextPath = next
    if (this.hasChanged) {
      this.$refs.confirmationModal.show()
    } else {
      this.nextPath()
    }
  },
  props: {
    id: {
      type: String,
      default: ''
    },
    fromBulk: {
      type: Boolean,
      default: false
    },
    ids: {
      type: [Array, String],
      default: () => {
        return []
      }
    },
    defaultAssignee: {
      type: [String, Number],
      default: ''
    },
    ticketType: {
      type: String,
      default: 'TICKET_TYPE_TASK'
    },
    ticketPriority: {
      type: String,
      default: ''
    },
    requestsData: { // => { title, listDocsPerRequest: [{documents, contacts, requestId}], objectOwner }
      type: String,
      default: ''
    },
    emailsData: { // => { email, media }
      type: String,
      default: ''
    },
    source: { // => changeOperator
      type: String,
      default: ''
    },
    buildingIds: {
      type: String,
      default: ''
    },
    propertyIds: {
      type: String,
      default: ''
    },
    objectIds: {
      type: String,
      default: ''
    },
    tenancyIds: {
      type: String,
      default: ''
    },
    condoOwnerIds: {
      type: String,
      default: ''
    },
    contactIds: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      selectedImageMedia: null,
      refrecheAutosuggestKey: '',
      debounceTimeout: null,
      galleryImages: [],
      emailSuggestion: '',
      ticketToLink: '',
      typeOfLink: 'TICKET_LINK_TYPE_RELATES',
      references: [],
      dates: {
        startDate: null,
        dueDate: null
      },
      loading: false,
      hasChanged: false,
      attachment: [],
      isLoaded: false,
      showLinkInputs: false,
      availableTypes: ['TICKET_TYPE_TASK', 'TICKET_TYPE_ORDER'],
      // 'TICKET_TYPE_OFFER',
      availableLinkTypes: ['TICKET_LINK_TYPE_RELATES', 'TICKET_LINK_TYPE_BLOCKS', 'TICKET_LINK_TYPE_IS_BLOCKED_BY'],
      availablePriority: ['LOW', 'DEFAULT', 'HIGH'],
      mediasList: [],
      uploading: false,
      ticketsForLink: [],
      listTickets: [],
      currentEmployeeList: [],
      listAllEmployees: [],
      ownerAccounts: [],
      searchForTickets: '',
      ticket: {
        accountId: '',
        source: 'UNDEFINED_SOURCE',
        type: 'TICKET_TYPE_TASK',
        status: 'TICKET_STATUS_OPEN',
        title: '',
        description: '',
        mediaIds: [],
        ownerId: '',
        priority: 'DEFAULT',
        startDate: null,
        dueDate: null,
        assignee: null,
        onSiteContact: null,
        onSiteContacts: [],
        watchers: [],
        implementationLead: {},
        correspondences: [],
        externalTicketWatcher: [],
        links: [],
        references: [],
        involvedPersons: []
      },
      optedPortionKeyValue: ['OPTED_PORTION_KEY_M2', 'OPTED_PORTION_KEY_M3', 'OPTED_PORTION_KEY_VALUE_RATIO', 'OPTED_PORTION_KEY_TV', 'OPTED_PORTION_KEY_ELEVATOR', 'OPTED_PORTION_KEY_LOCATION_COMPENSATION'],
      typeValue: [],
      isAddressInvalid: false,
      awaitingSearch: false,
      selectedObjectVideo: '',
      countDocs: 0,
      countImg: 0,
      countVideo: 0,
      nextToPath: null,
      listEntities: [],
      showTicketPreview: false,
      ticketPreview: {
        key: '',
        accountId: '',
        source: 'UNDEFINED_SOURCE',
        type: 'TICKET_TYPE_TASK',
        status: 'TICKET_STATUS_OPEN',
        title: '',
        description: '',
        mediaIds: [],
        ownerId: this.$store.getters['user/getAccountId'],
        priority: 'DEFAULT',
        startDate: null,
        dueDate: null,
        assignee: null,
        onSiteContact: null,
        onSiteContacts: [],
        watchers: [],
        implementationLead: {},
        correspondences: [],
        externalTicketWatcher: [],
        links: [],
        references: [],
        involvedPersons: []
      },
      disableBtns: false,
      onSiteContactPersons: null,
      correspondences: null,
      correspondencesContactPerson: null,
      listEmployeeOnSiteCP: [],
      listEmployeeCorrespondencesCP: [],
      typeOfConnection: null,
      availableConnectionTypes: [
        {
          key: 'property',
          value: 'property'
        },
        {
          key: 'building',
          value: 'building'
        },
        {
          key: 'object',
          value: 'object'
        },
        {
          key: 'condominium',
          value: 'object'
        },
        {
          key: 'tenancy',
          value: 'tenancy'
        },
        {
          key: 'condo_owner',
          value: 'condominium'
        },
        {
          key: 'contact',
          value: 'contact'
        }
      ],
      query: '',
      selectedTemplate: '',
      indexNameChanging: false,
      assignee: null,
      listRequestsMedia: [],
      listEmployeeContact: [],
      generatedDescription: '',
      linksUpdated: false,
      listLinks: [],
      linksInit: false,
      showDeleteTicketModal: false
    }
  },
  computed: {
    ...mapState('message', ['templates']),
    costAbsorption: {
      get() {
        return this.ticket.costs.costAbsorption !== 'COST_ABSORPTION_UNDEFINED' ? this.$t('message.ticketOverview.ticket.costs.costAbsorptionValues.' + this.ticket.costs.costAbsorption) : ''
      },
      set(value) {
        Vue.set(this.ticket.costs, 'costAbsorption', value)
      }
    },
    costShareUnit: {
      get() {
        if (this.ticket.costs.costShareUnit === 'COST_SHARE_UNIT_UNDEFINED') {
          return ''
        } else {
          if (this.ticket.costs.costShareUnit === 'COST_SHARE_UNIT_PERCENT') {
            return this.ticket.costs.costShareValue + '%'
          } else {
            return `${this.$options.filters.formatPrice(this.ticket.costs.costShareValue, 'CHF')}`
          }
        }
      },
      set(value) {
        Vue.set(this.ticket.costs, 'costShareUnit', value)
      }
    },
    sortedTemplate() {
      return this.templates.filter(x => x.type === 'TEMPLATE_TYPE_TICKET').slice().sort((a, b) => {
        if (a.name > b.name) {
          return 1
        }
        if (b.name > a.name) {
          return -1
        }
        return 0
      })
    },
    filterQueryOperator() {
      return 'isOwner:false AND active:true AND isEmployee:false AND isOnlyInterestedParty:false'
    },
    prevRoutePath() {
      return this.prevRoute ? this.prevRoute.path : '/'
    },
    prevRouteQuery() {
      return this.prevRoute ? this.prevRoute.query : null
    },
    filteredMediaList() {
      const array = this.mediasList
      array.sort((val1, val2) => {
        return parseInt(val2.uploadedAt) - parseInt(val1.uploadedAt)
      })
      if (array.length > 0) {
        array.forEach(element => {
          if (!(['image/jpeg', 'image/png', 'image/gif', 'video/webm', 'video/mp4'].indexOf(element.mimeType) !== -1)) {
            this.countDocs = this.countDocs + 1
          }
          if (['image/jpeg', 'image/png', 'image/gif'].indexOf(element.mimeType) !== -1) {
            this.countImg = this.countImg + 1
          }
          if (['video/webm', 'video/mp4'].indexOf(element.mimeType) !== -1) {
            this.countVideo = this.countVideo + 1
          }
        })
      }
      return array
    },
    pageTitle() {
      if (this.id && this.ticket) {
        return `${this.$t('message.ticketOverview.editTicket')}: ${this.ticket.key} - ${this.ticket.title}`
      } else {
        return this.$t('message.ticketOverview.createTicket')
      }
    },
    employeeList() {
      if (this.getEmployees().length > 0) {
        return this.getEmployees().filter(x => !x.blocked)
      }
      return []
    },
    avatar() {
      const profile = this.$store.getters['user/getCurrentUser']
      if (profile) {
        if (profile.picture.includes('gravatar')) {
          return '/avatar.png'
        }
        return profile.picture
      } else {
        return '/avatar.png'
      }
    },
    resultTicketsForLink() {
      return [{ data: this.ticketsForLink.filter(x => x && x.key && x.key !== this.ticket.key) }]
    }
  },
  watch: {
    selectedTemplate: {
      deep: true,
      handler: async function (val) {
        const template = this.sortedTemplate.find(x => x.id === val)
        const templateDescription = await this.translatePlaceholders(template.message)
        this.ticket.description = templateDescription + this.ticket.description
      }
    },
    correspondences: function (newVal) {
      if ((newVal === '' || newVal === null) && this.correspondencesContactPerson.length > 0) {
        this.ticket.correspondences = this.correspondencesContactPerson
      } else {
        this.ticket.correspondences = [newVal]
      }
    },
    correspondencesContactPerson: function (value) {
      if (value.length > 1) {
        this.correspondencesContactPerson = [value[value.length - 1]]
      }
      if (value.length > 0) {
        const item = value.length > 1 ? value[value.length - 1] : value.length > 0 ? value[0] : null
        if (item && !('contactPersons' in item)) {
          this.ticket.correspondences = [item]
          this.listEmployeeCorrespondencesCP = []
        } else {
          if (item && item.contactPersons.length > 0) {
            this.ticket.correspondences = [item]
            this.listEmployeeCorrespondencesCP = []
            const list = item.contactPersons
            this.correspondences = null
            list.forEach(element => {
              const emp = element
              emp.companyName = item.name
              emp.address = item.address
              if (emp.email === '') {
                emp.email = item.email
              }
              if (emp.phoneNumbers.length === 0) {
                emp.phoneNumbers = item.phoneNumbers
              }
              this.listEmployeeCorrespondencesCP.push(emp)
            })
          } else {
            this.ticket.correspondences = [item]
            this.listEmployeeCorrespondencesCP = []
          }
        }
      } else {
        this.correspondences = null
        this.listEmployeeCorrespondencesCP = []
        this.ticket.correspondences = []
      }
    },
    ticket: {
      deep: true,
      handler: function () {
        this.hasChanged = true
      }
    },
    mediaUpload: function (newVal) {
      if (newVal !== null) {
        this.sendFile()
      }
    },
    listAllEmployees: function (newVal) {
      if (newVal.length > 0 && this.ticket && this.ticket.assignee && this.ticket.assignee.name && !this.isLoaded) {
        this.ticket.assignee = newVal.find(x => x.value.id === this.ticket.assignee.userId || x.value.id === this.ticket.assignee.value.id)
        this.isLoaded = true
        this.$nextTick(function () {
          this.hasChanged = false
        })
      } else if (newVal.length > 0 && !this.ticket.assignee) {
        this.ticket.assignee = newVal.find(x => x.value.id === this.$store.getters['user/getUserId']) || null
      } else if (newVal.length > 0) {
        if (!newVal.find(x => x.value.id === this.ticket.assignee.userId || x.value.id === this.ticket.assignee?.value?.id)) {
          this.ticket.assignee = null
        }
      }
    },
    isLoaded: function (newVal) {
      if (this.listAllEmployees.length > 0 && this.ticket && this.ticket.assignee && this.ticket.assignee.name && newVal) {
        this.ticket.assignee = this.listAllEmployees.find(x => x.value.id === this.ticket.assignee.userId)
        this.$nextTick(function () {
          this.hasChanged = false
        })
      }
    },
    listEntities: function (newVal) {
      if (newVal.length > 0) {
        this.getListAllowedAccounts(newVal)
      } else if (this.requestsData === '') {
        this.getAllNetworkPartner()
      }
    },
    typeOfConnection: function (item) {
      // this.indexNameChanging = true
      if (item) {
        this.query = ''
        if (item.key === 'condominium') {
          this.query = 'condominiumOwnership:true'
        }
        if (item.key === 'object') {
          this.query = 'condominiumOwnership:false'
        }
        if (item.value === 'contact') {
          this.query = 'isOwner:false AND isEmployee:false AND isOnlyInterestedParty:false'
        }
      }
      setTimeout(() => {
        this.refrecheAutosuggestKey = Math.floor(Math.random() * 100)
        // this.indexNameChanging = false
      }, 200)
    },
    'ticket.assignee': function (newVal) {
      if (newVal && newVal.value) {
        ContactApi.resolveByMail(this.accountId, [newVal.value.email]).then(response => {
          if (response) {
            this.assignee = (response.companies.concat(response.persons))[0]
          }
        })
      } else {
        this.assignee = null
      }
    },
    'ticket.type': function (newVal) {
      this.generatedDescription = ''
      if (newVal === 'TICKET_TYPE_ORDER' && this.listEntities.length > 0) {
        this.listEntities.forEach((element, index) => {
          this.generateDescription(this.getReferenceById(element, index), 'add')
        })
      }
    },
    onSiteContactPersons: function (newVal) {
      if (newVal.length > 0) {
        this.ticket.onSiteContacts = newVal
      } else {
        this.ticket.onSiteContacts = []
      }
    },
    listLinks: function () {
      if (this.linksInit) {
        this.linksUpdated = true
      }
    }
  },
  mounted() {
    this.loadInstantSearch()
    if (this.isJanitorEditor) {
      this.availableTypes = ['TICKET_TYPE_TASK']
      this.ticket.type = 'TICKET_TYPE_TASK'
    }
    if (this.isOwnerModule || this.isAssetModule) {
      this.availableTypes = ['TICKET_TYPE_TASK']
    }
    if (this.fromBulk) {
      this.ticket.type = this.ticketType
    }
    if (this.ticketPriority !== '') {
      this.ticket.priority = this.ticketPriority
    }
    if (this.id) {
      TicketApi.getTicketByKey(this.id)
        .then(response => {
          this.ticket = response.ticket
          if (this.ticket.type === 'TICKET_TYPE_ORDER' && !this.availableTypes.includes('TICKET_TYPE_ORDER')) {
            this.availableTypes.push('TICKET_TYPE_ORDER')
          }
          if (this.ticket.links.length > 0) {
            this.listLinks = JSON.parse(JSON.stringify(this.ticket.links))
          }
          this.$nextTick(() => {
            this.linksInit = true
          })
          const description = JSON.parse(JSON.stringify(this.ticket.description))
          this.ticket.description = description.replace(/<br>/g, '\n')
          this.mountedReference(this.ticket.references)
          this.dates.startDate = this.ticket.startDate ? this.$options.filters.objectToDateInput(this.ticket.startDate) : ''
          this.dates.dueDate = this.ticket.dueDate ? this.$options.filters.objectToDateInput(this.ticket.dueDate) : ''
          this.hasChanged = false
          const currentTicket = JSON.parse(JSON.stringify(this.ticket))
          if ((currentTicket.correspondences?.length > 0 && currentTicket.correspondences[0].companyName !== '')) {
            let ids = []
            let hasCompanyCor = false
            if (currentTicket.correspondences?.length > 0 && currentTicket.correspondences[0].contactId) {
              ids.push(currentTicket.correspondences[0].contactId)
              hasCompanyCor = true
            }
            ContactApi.contactResolve(ids)
              .then(response => {
                const contacts = response.companies.concat(response.persons) || null
                if (hasCompanyCor) {
                  const company = contacts.find(x => x.id === currentTicket.correspondences[0].contactId || x.contactPersons.map(x => x.id).includes(currentTicket.correspondences[0].contactId))
                  if (company) {
                    this.listEmployeeCorrespondencesCP = company.contactPersons || []
                    this.correspondencesContactPerson = [company]
                    this.$nextTick(() => {
                      const item = this.listEmployeeCorrespondencesCP.find(x => x.id === currentTicket.correspondences[0].contactId)
                      if (item) {
                        item.companyName = company.name
                        this.correspondences = item
                      }
                    })
                  }
                }
              })
              .catch(e => {
                console.log(e)
                Vue.toasted.show(this.$t('message.loadingErrors.contacts'), { type: 'error' })
              })
          }
          if (currentTicket.onSiteContacts.length > 0) {
            this.onSiteContactPersons = currentTicket.onSiteContacts.map(x => {
              x.objectID = x.contactId
              return x
            })
          } else if (currentTicket.onSiteContact) {
            currentTicket.onSiteContact.objectID = currentTicket.onSiteContact.contactId
            this.onSiteContactPersons = [currentTicket.onSiteContact]
          }
          if (currentTicket.correspondences?.length > 0 && currentTicket.correspondences[0].companyName === '') {
            if (currentTicket.correspondences.length > 0) {
              this.correspondencesContactPerson = currentTicket.correspondences
            }
          }
          if (this.listLinks.length > 0) {
            TicketApi.getTicketByIds(this.listLinks.map(x => x.ticketId))
              .then(response => {
                this.listTickets = response.tickets
              })
          }
          if (this.ticket.mediaIds.length > 0) {
            MediaApi.getListMedia(this.ticket.mediaIds)
              .then(response => {
                for (const media of response.media) {
                  this.mediasList.push(media)
                  this.initCount()
                }
              })
              .catch(() => {
                Vue.toasted.show(this.$t('message.loadingErrors.media'), { type: 'error' })
              })
          }
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.ticket'), { type: 'error' })
        }).finally(() => {
        this.hasChanged = false
      })
    } else {
      if (this.listAllEmployees.length > 0 && !this.ticket.assignee) {
        this.ticket.assignee = this.listAllEmployees.find(x => x.value.id === this.$store.getters['user/getUserId'])
      }
    }
    if (this.requestsData !== '') {
      this.loadRequestsData()
    } else {
      if (this.defaultAssignee !== '') {
        this.ticket.assignee = this.listAllEmployees.find(x => x.value.id === this.defaultAssignee)
      } else if (this.ids.length > 0) {
        this.ticket.assignee = null
      } else if (this.availableEmployeeList && this.availableEmployeeList.length > 0 && !this.ticket.assignee) {
        this.ticket.assignee = this.availableEmployeeList.find(x => x.value.id === this.$store.getters['user/getUserId'])
      }
      if (this.ticketType !== '') {
        this.ticket.type = this.ticketType
      }
    }
    // if (((typeof this.ids === 'string' || this.ids instanceof String) && this.ids !== '') || this.ids.length > 0) {
    //   let arr = []
    //   if (typeof this.ids === 'string' || this.ids instanceof String) {
    //     arr = [this.ids]
    //   } else {
    //     arr = this.ids
    //   }
    //   if (arr[0].startsWith('device')) {
    //     ObjectApi.listDevicesByIds(arr)
    //       .then(response => {
    //         response.devices.forEach(element => {
    //           this.addToReferences({ item: element })
    //         })
    //       })
    //   } else if (arr[0].startsWith('condominium')) {
    //     ObjectApi.getCondominiumByIds(arr)
    //       .then(response => {
    //         response.condominiums.forEach(element => {
    //           this.addToReferences({ item: element })
    //         })
    //       })
    //   } else if (arr[0].startsWith('tenancy')) {
    //     ObjectApi.getTenanciesByIds(arr)
    //       .then(response => {
    //         response.tenancies.forEach(element => {
    //           this.addToReferences({ item: element })
    //         })
    //       })
    //   } else if (arr[0].startsWith('obj')) {
    //     ObjectApi.listObjectsByIds(arr)
    //       .then(response => {
    //         response.objects.forEach(element => {
    //           this.addToReferences({ item: element })
    //         })
    //       })
    //   } else if (arr[0].startsWith('building')) {
    //     ObjectApi.getBuildingsListByIds(arr)
    //       .then(response => {
    //         response.buildings.forEach(element => {
    //           this.addToReferences({ item: element })
    //         })
    //       })
    //   } else if (arr[0].startsWith('prop')) {
    //     ObjectApi.getPropertyListByIds(arr)
    //       .then(response => {
    //         response.properties.forEach(element => {
    //           this.addToReferences({ item: element })
    //         })
    //       })
    //   } else if (arr[0].startsWith('req')) {
    //     RequestApi.listByIds(arr)
    //       .then(response => {
    //         response.requests.forEach(element => {
    //           this.addToReferences({ item: element })
    //         })
    //       })
    //   } else if (arr[0].startsWith('room')) {
    //     RoomApi.listById(arr)
    //       .then(response => {
    //         response.rooms.forEach(element => {
    //           this.addToReferences({ item: element })
    //         })
    //       })
    //   } else if (arr[0].startsWith('contact')) {
    //     ContactApi.contactResolve(arr)
    //       .then(response => {
    //         response.persons.concat(response.companies).filter(x => arr.includes(x.id)).forEach(element => {
    //           this.addToReferences({ item: element })
    //         })
    //       })
    //   }
    // }
    this.loadData()
    this.$nextTick(async function () {
      if (this.requestsData === '') {
        await this.loadEmployees()
        if (this.references.length === 0 && this.listEntities.length === 0) {
          this.getAllNetworkPartner()
        }
      }
      this.hasChanged = false
    })
  },
  methods: {
    ...mapActions('employee', ['loadEmployees']),
    ...mapGetters('employee', ['getEmployees']),
    deleteTicketClicked() {
      this.showDeleteTicketModal = true
      this.$nextTick(() => {
        this.$refs.deleteConfirmationModal.show()
      })
    },
    hideDeleteConfirmationModal() {
      this.$bvModal.hide('deleteConfirmationModal')
      this.showDeleteTicketModal = false
    },
    confirmDeletion() {
      TicketApi.deleteTicket(this.ticket.id)
        .then(() => {
          this.redirectToDraftOverview()
          Vue.toasted.show(this.$t('message.successMessages.deleteTicket'), { type: 'success' })
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.deleteErrors.ticket'), { type: 'error' })
        })
    },
    redirectToDraftOverview() {
      let query = null
      if (this.prevRoutePath.includes('/tickets/')) {
        query = this.prevRouteQuery
      }
      if (this.isOwnerModule) {
        this.$router.push({
          name: 'OwnerDraftTicketOverview',
          query: query
        }, () => {
        })
      } else if (this.isAdminModule) {
        this.$router.push({
          name: 'AdminDraftTicketOverview',
          query: query
        }, () => {
        })
      } else if (this.isAssetModule) {
        this.$router.push({
          name: 'AssetDraftTicketOverview',
          query: query
        }, () => {
        })
      } else if (this.isAccountingModule) {
        this.$router.push({
          name: 'AccountingDraftTicketOverview',
          query: query
        }, () => {
        })
      } else {
        this.$router.push({
          name: 'MarketingDraftTicketOverview',
          query: query
        }, () => {
        })
      }
    },
    async loadData() {
      let arr = []
      if (((typeof this.ids === 'string' || this.ids instanceof String) && this.ids !== '') || this.ids.length > 0) {
        if (typeof this.ids === 'string' || this.ids instanceof String) {
          arr = [this.ids]
        } else {
          arr = this.ids
        }
      }
      if (this.objectIds || (arr.length > 0 && arr[0].startsWith('obj'))) {
        const objectIds = this.objectIds ? typeof this.objectIds === 'string' ? this.objectIds.split(',') : this.objectIds : arr
        await ObjectApi.listObjectsByIds(objectIds)
          .then(response => {
            response.objects.forEach(element => {
              this.addToReferences({ item: element })
            })
          })
      }
      if (this.buildingIds || (arr.length > 0 && arr[0].startsWith('building'))) {
        const buildingIds = this.buildingIds ? typeof this.buildingIds === 'string' ? this.buildingIds.split(',') : this.buildingIds : arr
        await ObjectApi.getBuildingsListByIds(buildingIds)
          .then(response => {
            response.buildings.forEach(element => {
              this.addToReferences({ item: element })
            })
          })
      }
      if (this.propertyIds || (arr.length > 0 && arr[0].startsWith('prop'))) {
        const propertyIds = this.propertyIds ? typeof this.propertyIds === 'string' ? this.propertyIds.split(',') : this.propertyIds : arr
        await ObjectApi.getPropertyListByIds(propertyIds)
          .then(response => {
            response.properties.forEach(element => {
              this.addToReferences({ item: element })
            })
          })
      }
      if (this.tenancyIds || (arr.length > 0 && arr[0].startsWith('tenancy'))) {
        const tenancyIds = this.tenancyIds ? typeof this.tenancyIds === 'string' ? this.tenancyIds.split(',') : this.tenancyIds : arr
        await ObjectApi.getTenanciesByIds(tenancyIds)
          .then(response => {
            response.tenancies.forEach(element => {
              this.addToReferences({ item: element })
            })
          })
      }
      if (this.condoOwnerIds || (arr.length > 0 && arr[0].startsWith('condominium'))) {
        const condoOwnerIds = this.condoOwnerIds ? typeof this.condoOwnerIds === 'string' ? this.condoOwnerIds.split(',') : this.condoOwnerIds : arr
        await ObjectApi.getCondominiumByIds(condoOwnerIds)
          .then(response => {
            response.condominiums.forEach(element => {
              this.addToReferences({ item: element })
            })
          })
      }
      if (this.contactIds || (arr.length > 0 && arr[0].startsWith('contact'))) {
        const contactIds = this.contactIds ? typeof this.contactIds === 'string' ? this.contactIds.split(',') : this.contactIds : arr
        await ContactApi.contactResolve(contactIds)
          .then(response => {
            response.persons.concat(response.companies).filter(x => contactIds.includes(x.id)).forEach(element => {
              this.addToReferences({ item: element })
            })
          })
      }
      if (arr.length > 0 && arr[0].startsWith('room')) {
        RoomApi.listById(arr)
          .then(response => {
            response.rooms.forEach(element => {
              this.addToReferences({ item: element })
            })
          })
      }
      if (arr.length > 0 && arr[0].startsWith('req')) {
        RequestApi.listByIds(arr)
          .then(response => {
            response.requests.forEach(element => {
              this.addToReferences({ item: element })
            })
          })
      }
      if (arr.length > 0 && arr[0].startsWith('device')) {
        ObjectApi.listDevicesByIds(arr)
          .then(response => {
            response.devices.forEach(element => {
              this.addToReferences({ item: element })
            })
          })
      }
      this.listEntities = arr.filter(x => !x.startsWith('req'))
    },
    getSuggestion(hits) {
      return hits.filter(x => x.objectID).concat(hits.flatMap(hit => {
        if ('contactPersons' in hit && hit.contactPersons.length > 0) {
          return hit.contactPersons.map(cp => {
            cp.objectID = cp.id
            return cp
          })
        } else {
          return []
        }
      }))
    },
    translatePlaceholders(message = '') {
      return new Promise((resolve) => {
        const list = []
        let contactId = ''
        let description = ''
        if (this.ticket.type === 'TICKET_TYPE_ORDER' && this.ticket.correspondences.length > 0) {
          contactId = this.correspondences ? this.correspondences.id : this.correspondencesContactPerson[0].objectID
        } else if (this.ticket.type === 'TICKET_TYPE_TASK' && this.assignee) {
          contactId = this.assignee.id
        }
        if (this.references.length > 0) {
          this.references.forEach(element => {
            if (element.object) {
              list.push({
                contactId: contactId,
                objectId: element.object.id || element.object.objectID
              })
            }
            if (element.building && !element.object) {
              list.push({
                contactId: contactId,
                buildingId: element.building.id || element.building.objectID
              })
            }
            if (element.property && !element.building) {
              list.push({
                contactId: contactId,
                propertyId: element.property.id || element.property.objectID
              })
            }
            if (element.contact) {
              list.push({
                contactId: element.contact.id || element.contact.objectID,
                objectId: ''
              })
            }
          })
        } else if (contactId !== '') {
          list.push({
            contactId: contactId,
            objectId: ''
          })
        }
        if (list.length > 0) {
          MessageApi.bulkRenderText(list, message, '').then(response => {
            if (response?.entries.length > 0) {
              response?.entries.forEach(element => {
                if (element?.text) {
                  description += element.text + '\n\n'
                }
              })
              resolve(description)
            }
          }).catch(e => {
            console.log(e)
            resolve(message)
          })
        } else {
          resolve(message)
        }
      })
    },
    // Description function
    // in this function we get the data from the requests and try to find the assignee if there's one
    // then we get the documents from the requests and we add them to the list of documents
    async loadRequestsData() {
      const data = JSON.parse(this.requestsData)
      const promises = []
      this.listRequestsMedia = []
      this.ticket.title = data.title
      await this.getListEmployees([data.objectOwner])
      if (this.defaultAssignee !== '') {
        ContactApi.listEmployees(data.objectOwner)
          .then(response => {
            this.listEmployeeContact = response.persons
            this.assignee = response.persons.find(x => x.id === this.defaultAssignee)
          })
      }
      data.listDocsPerRequest.forEach(docs => {
        promises.push(MediaApi.listDocuments(docs.documents))
      })
      Promise.all(promises).then(response => {
        response.forEach((rsp, index) => {
          const idsAndNames = rsp.documents.map(x => ({
            id: x.versions[x.versions.length - 1].mediaId,
            mediumName: x.name
          }))
          const nameOfMergedFile = data.listDocsPerRequest[index].contacts.map(x => x.name).join(', ')
          MediaApi.mergeAllDocuments(idsAndNames, nameOfMergedFile).then(async response => {
            const file = response.media
            const binary = window.atob(file)
            const len = binary.length
            const buffer = new ArrayBuffer(len)
            const view = new Uint8Array(buffer)
            for (let i = 0; i < len; i++) {
              view[i] = binary.charCodeAt(i)
            }
            const blob = new Blob([view])
            // check if file type is pdf
            const pdfBytePattern = '25504446'
            const fileHeader = await this.getFileHeader(blob)
            const isPdf = fileHeader === pdfBytePattern

            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            this.listRequestsMedia.push({
              filename: nameOfMergedFile + (isPdf ? '.pdf' : '.zip'),
              blob: blob,
              requestId: data.listDocsPerRequest[index].requestId
            })
          }).catch(e => {
            console.log(e)
            Vue.toasted.show(this.$t('message.applicationForm.creditTrust.certificateOrderError'), { type: 'error' })
          })
        })
      })
    },
    // adaptEmailData() {
    //   const data = JSON.parse(this.emailsData)
    //   this.ticket.type = this.ticketType
    //   if (data.media?.length > 0) {
    //     for (const media of data.media) {
    //       this.mediasList.push(media)
    //       this.ticket.mediaIds.push(media.id)
    //     }
    //   }
    // },
    async downloadRequestsFile(blob, name) {
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = name
      link.click()
    },
    deleteRequestMedia(requestId) {
      this.listRequestsMedia.forEach((doc, index) => {
        if (doc.requestId === requestId) {
          this.listRequestsMedia.documentIds.splice(index, 1)
        }
      }, this)
    },
    setRoomId(source, id) {
      if (source === 'building') {
        localStorage.setItem('expandBuildingRoomId', id)
      } else if (source === 'object') {
        localStorage.setItem('expandObjectRoomId', id)
      }
    },
    setMortgageId(source, id) {
      if (source === 'building') {
        localStorage.setItem('expandBuildingMortgageId', id)
      } else if (source === 'property') {
        localStorage.setItem('expandPropertyMortgageId', id)
      }
    },
    setInsuranceId(source, id) {
      if (source === 'building') {
        localStorage.setItem('expandBuildingInsuranceId', id)
      } else if (source === 'property') {
        localStorage.setItem('expandPropertyInsuranceId', id)
      }
    },
    // generate description for 'property'
    getPropertyDescription(property) {
      let description = ''
      description = description + '\n' + this.$t('message.createOrder.property') + ' ' + property.name
      description = description + '\n' + this.$t('message.createOrder.city') + ' ' + property.address.zip + ' ' + property.address.city + '\n'
      return description
    },
    // generate description for 'building'
    getBuildingDescription(building) {
      let description = ''
      description = description + '\n' + this.$t('message.createOrder.address') + ' ' + this.convertAddress(building.address) + '\n'
      return description
    },
    // generate description for 'object', 'condominium', 'tenancy' and 'condoOwner'
    getObjectDescription(object) {
      let description = ''
      description = description + '\n' + this.$t('message.createOrder.address') + ' ' + this.convertAddress(object.address)
      description = description + '\n' + this.$t('message.createOrder.location') + ' ' + object.name + ', ' + this.$t('message.onBoarding.buildings.objects.category.' + object.category.toLowerCase()) + (object.characteristics?.floor !== -10000 ? ' - ' + this.formatFloor(object.characteristics?.floor) : '') + '\n'
      return description
    },
    generateDescription(item, status) {
      let description = ''
      if (item?.id?.startsWith('prop') || item?.objectID?.startsWith('prop')) {
        description = this.getPropertyDescription(item)
      } else if (item?.id?.startsWith('building') || item?.objectID?.startsWith('building')) {
        description = this.getBuildingDescription(item)
      } else if ((item?.id?.startsWith('obj') || item?.objectID?.startsWith('obj')) || (item?.id?.startsWith('condominium') || item?.objectID?.startsWith('condominium')) || (item?.id?.startsWith('tenancy') || item?.objectID?.startsWith('tenancy'))) {
        description = this.getObjectDescription(item)
      }
      if (description !== '') {
        if (status === 'add') {
          this.generatedDescription += description
        } else if (status === 'remove') {
          this.generatedDescription = this.generatedDescription.replace(description, '')
        }
      }
    },
    toggleListTickets() {
      if (!this.showTicketPreview) {
        this.$v.ticket.$touch()
        if (!this.$v.ticket.$invalid) {
          this.createTickets()
        }
      } else {
        this.showTicketPreview = !this.showTicketPreview
      }
    },
    async createTickets() {
      this.disableBtns = true
      const promises = []
      const ticket = JSON.parse(JSON.stringify(this.ticket))
      this.ticketPreview = JSON.parse(JSON.stringify(this.ticket))
      this.ticketPreview.links = this.listLinks
      this.ticketPreview.status = 'TICKET_STATUS_OPEN '
      this.ticketPreview.description = await this.translatePlaceholders(ticket.description)
      const creator = this.listAllEmployees.find(x => x.value.id === this.userId) || null
      promises.push(ContactApi.resolveByMail(this.accountId, [creator.value.email]))
      Promise.all(promises).then(response => {
        let contacts = []
        response.forEach(element => {
          contacts = contacts.concat(element.companies).concat(element.persons)
        })
        let currentAssigne = null
        if (ticket.assignee && this.assignee) {
          const employee = ticket.assignee?.value
          currentAssigne = {
            accountId: employee.accountId,
            accountName: ticket.assignee.company,
            name: employee.profile?.firstName + ' ' + employee.profile?.lastName,
            profilePictureUrl: employee.profile?.pictureUrl,
            userId: employee.id,
            address: this.assignee.address,
            email: employee.email,
            phone: employee.profile?.phoneNumber
          }
        }
        const creatorContact = contacts.find(x => x.ownerId === creator?.value?.accountId && x.email === creator?.value?.email) || null
        const creatorCompany = this.ownerAccounts.find(x => parseInt(x.id) === this.accountId) || null
        const creatorDetail = creator.value
        let currentCreator = null
        if (creatorDetail) {
          currentCreator = {
            accountId: this.accountId,
            accountName: creatorCompany?.company?.name || null,
            name: creatorDetail.profile?.firstName + ' ' + creatorDetail.profile?.lastName,
            profilePictureUrl: creatorDetail.profile?.pictureUrl,
            userId: creatorDetail.id,
            address: creatorContact?.address || '',
            email: creatorDetail.email,
            phone: creatorDetail.profile?.phoneNumber
          }
        }
        const ticketReferences = []
        this.references.forEach(element => {
          if (element.contact) {
            const id = element.contact.id ? element.contact.id : element.contact.objectID
            ticketReferences.push({
              id: id,
              relatedIds: []
            })
          } else if (element.tenancy) {
            const id = element.tenancy.id ? element.tenancy.id : element.tenancy.objectID
            const idProperty = element.property?.id ? element.property.id : element.property.objectID
            const idBuilding = element.building?.id ? element.building.id : element.building.objectID
            const idObject = element.object.id ? element.object.id : element.object.objectID
            ticketReferences.push({
              id: id,
              relatedIds: [idProperty, idBuilding, idObject]
            })
          } else if (element.request) {
            const id = element.request.id ? element.request.id : element.request.objectID
            ticketReferences.push({
              id: id,
              relatedIds: []
            })
          } else if (element.condominium) {
            const id = element.condominium.id ? element.condominium.id : element.condominium.objectID
            const idProperty = element.property?.id ? element.property.id : element.property.objectID
            const idBuilding = element.building?.id ? element.building.id : element.building.objectID
            const idObject = element.object.id ? element.object.id : element.object.objectID
            ticketReferences.push({
              id: id,
              relatedIds: [idProperty, idBuilding, idObject]
            })
          } else if (element.device) {
            const id = element.device.id ? element.device.id : element.device.objectID
            let idProperty
            let idBuilding = null
            let idObject = null
            let ids = []
            if (element.object) {
              idProperty = element.property?.id ? element.property.id : element.property.objectID
              idBuilding = element.building?.id ? element.building.id : element.building.objectID
              idObject = element.object?.id ? element.object.id : element.object.objectID

              ids = [idProperty, idBuilding, idObject]
            } else if (element.building) {
              idProperty = element.property.id ? element.property.id : element.property.objectID
              idBuilding = element.building?.id ? element.building.id : element.building.objectID

              ids = [idProperty, idBuilding]
            } else {
              idProperty = element.property.id ? element.property.id : element.property.objectID

              ids = [idProperty]
            }
            ticketReferences.push({
              id: id,
              relatedIds: ids
            })
          } else if (element.object) {
            const id = element.object.id ? element.object.id : element.object.objectID
            const idProperty = element.property.id ? element.property.id : element.property.objectID
            const idBuilding = element.building.id ? element.building.id : element.building.objectID
            ticketReferences.push({
              id: id,
              relatedIds: [idProperty, idBuilding]
            })
          } else if (element.building) {
            const id = element.building.id ? element.building.id : element.building.objectID
            const idProperty = element.property.id ? element.property.id : element.property.objectID
            ticketReferences.push({
              id: id,
              relatedIds: [idProperty]
            })
          } else if (element.property) {
            const id = element.property.id ? element.property.id : element.property.objectID
            ticketReferences.push({
              id: id,
              relatedIds: []
            })
          }
        })
        this.ticketPreview.references = ticketReferences
        this.ticketPreview.creator = currentCreator
        this.ticketPreview.assignee = currentAssigne
        this.ticketPreview.description += this.generatedDescription
        this.disableBtns = false
        this.showTicketPreview = !this.showTicketPreview
      })
    },
    getListEmployees(listIds) {
      TicketApi.listTicketingEnabled(listIds).then(rsp => {
        UserApi.listAllEmployees(rsp.enabledAccountIds).then(response1 => {
          this.currentEmployeeList = response1.employees
          if (this.currentEmployeeList.length > 0) {
            const ids = [...new Set(this.currentEmployeeList.map(x => x.accountId).concat(this.employeeList.map(x => x.accountId)))]
            UserApi.listAccountsByIds(ids)
              .then(accounts => {
                this.ownerAccounts = accounts.accounts
                this.addAvailableEmployees()
              })
          } else {
            this.addAvailableEmployees()
          }
        })
      })
    },
    getCommonElements(array) {
      return array.reduce((a, b) => a.filter(c => b.includes(c)))
    },
    getListAllowedAccounts(listIds) {
      ShareApi.listAllowedAccountIds(listIds).then(response => {
        // after getting the allowed accounts we get the common elements between the allowed accounts
        // then get the employees of these accounts
        const listAllowedAccountIds = response.result.map(x => x.allowedAccountIds)
        const accountIds = listAllowedAccountIds?.length ? this.getCommonElements(listAllowedAccountIds) : []
        if (accountIds.length > 0) {
          this.getListEmployees(accountIds)
        } else {
          this.getListEmployees([this.accountId])
        }
      })
    },
    getAllNetworkPartner() {
      if (this.subType && this.subType === 'owner') {
        ShareApi.listPartners(this.$store.getters['user/getAccountId'])
          .then(response => {
            if (response.targetAccountIds.length > 0) {
              this.getListEmployees(response.targetAccountIds)
            } else {
              this.addAvailableEmployees()
            }
          })
          .catch(e => {
            console.log(e)
            Vue.toasted.show(this.$t('message.loadingErrors.contacts'), { type: 'error' })
          })
      } else if (this.subType && this.subType.toLowerCase().includes('asset')) {
        ShareApi.listPartnersOfAsset(this.$store.getters['user/getAccountId'])
          .then(response => {
            if (response.partnerAccountIds.length > 0) {
              this.getListEmployees(response.partnerAccountIds)
            } else {
              this.addAvailableEmployees()
            }
          })
          .catch(e => {
            console.log(e)
            Vue.toasted.show(this.$t('message.loadingErrors.contacts'), { type: 'error' })
          })
      } else {
        ShareApi.listOwners(this.$store.getters['user/getAccountId'])
          .then(response => {
            if (response.sourceAccountIds.length > 0) {
              this.getListEmployees(response.sourceAccountIds)
            } else {
              this.addAvailableEmployees()
            }
          })
          .catch(e => {
            console.log(e)
            Vue.toasted.show(this.$t('message.loadingErrors.contacts'), { type: 'error' })
          })
      }
    },
    redirectToDeviceDetail(referenceId, deviceId) {
      if (referenceId.startsWith('obj') || referenceId.startsWith('building')) {
        let id = null
        if (referenceId.startsWith('building')) {
          id = referenceId
        } else {
          const item = this.references.filter(x => x.device && x.device.id === deviceId)
          if (item.length > 0) {
            id = item[0].building?.id
          }
        }
        if (id && deviceId) {
          if (this.isOwnerModule) {
            return {
              name: 'OwnerBuildingDetailsView',
              params: { id: id },
              query: {
                view: 'devices',
                elementSelected: deviceId
              }
            }
          } else if (this.isAdminModule) {
            return {
              name: 'AdminBuildingDetailsView',
              params: { id: id },
              query: {
                view: 'devices',
                elementSelected: deviceId
              }
            }
          } else if (this.isAssetModule) {
            return {
              name: 'AssetBuildingDetailsView',
              params: { id: id },
              query: {
                view: 'devices',
                elementSelected: deviceId
              }
            }
          } else if (this.isAccountingModule) {
            return {
              name: 'AccountingBuildingDetailsView',
              params: { id: id },
              query: {
                view: 'devices',
                elementSelected: deviceId
              }
            }
          }
        }
      } else {
        let id = null
        id = referenceId
        if (this.isOwnerModule) {
          return {
            name: 'OwnerPropertyDetailsView',
            params: { id: id },
            query: {
              view: 'devices',
              elementSelected: deviceId
            }
          }
        } else if (this.isAdminModule) {
          return {
            name: 'AdminPropertyDetailsView',
            params: { id: id },
            query: {
              view: 'devices',
              elementSelected: deviceId
            }
          }
        } else if (this.isAssetModule) {
          return {
            name: 'AssetPropertyDetailsView',
            params: { id: id },
            query: {
              view: 'devices',
              elementSelected: deviceId
            }
          }
        } else if (this.isAccountingModule) {
          return {
            name: 'AccountingPropertyDetailsView',
            params: { id: id },
            query: {
              view: 'devices',
              elementSelected: deviceId
            }
          }
        }
      }
    },
    openVideo(media) {
      this.selectedObjectVideo = media.url
      this.$nextTick(() => {
        this.$refs['show-video'].show()
      })
    },
    searchAssignee(options, search) {
      return search.length
        ? options.filter(item => item.label.toLowerCase().includes(search.toLowerCase()) || item.company.toLowerCase().includes(search.toLowerCase()))
        : options
    },
    itemChanged(selected) {
      if (selected && selected.item) {
        this.searchForTickets = selected.item.key + ' ' + selected.item.title
      }
    },
    getSuggestionValue(suggestion) {
      return suggestion.item.key + ' ' + suggestion.item.title
    },
    addAvailableEmployees() {
      const array = []
      if (this.employeeList.length !== 0 && this.requestsData === '') {
        this.employeeList.filter(x => (x.blocked === false && x.deleted === false)).forEach((employee) => {
          const accountName = this.ownerAccounts.find(x => x.id === employee.accountId)
          array.push({
            label: (employee.profile.firstName ? employee.profile.firstName + ' ' : '') + employee.profile.lastName,
            value: employee,
            company: (accountName ? ' ' + (accountName.company ? accountName.company?.name : accountName.individual.firstName + ' ' + accountName.individual.lastName) : '')
          })
        })
      }
      if (this.currentEmployeeList.length !== 0) {
        this.currentEmployeeList.filter(x => (x.blocked === false && x.deleted === false)).forEach((employee) => {
          const accountName = this.ownerAccounts.find(x => x.id === employee.accountId)
          array.push({
            label: ((employee.profile.firstName ? employee.profile.firstName + ' ' : '') + employee.profile.lastName),
            value: employee,
            company: (accountName ? ' ' + (accountName.company ? accountName.company?.name : accountName.individual.firstName + ' ' + accountName.individual.lastName) : '')
          })
        })
      }
      const ids = array.map(o => o.value.id)
      const filtered = array.filter(({ value }, index) => !ids.includes(value.id, index + 1) && !(value.email.startsWith('support.') && value.email.endsWith('@my-prop.ch')))
      const accountUsers = filtered.filter(x => parseInt(x.value.accountId) === this.accountId).sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1))
      const otherUsers = filtered.filter(x => parseInt(x.value.accountId) !== this.accountId).sort((a, b) => (a.company > b.company ? 1 : -1))
      this.listAllEmployees = accountUsers.concat(otherUsers)

      // Interested party selection owner
      // in the case if we have only one employee in the list
      if (this.requestsData !== '' && this.listAllEmployees.length === 1) {
        this.ticket.assignee = this.listAllEmployees[0]
      }

      // Interested party selection owner
      // in the case if we have more than one employee in the list ans the object has 'object.contacts.contactIdAssigneeOwner'
      if (this.requestsData !== '' && this.listAllEmployees.length > 1 && this.defaultAssignee !== '') {
        const contactAssignee = this.listEmployeeContact.find(x => x.id === this.defaultAssignee)
        this.ticket.assignee = this.listAllEmployees.find(y => y.value.email === contactAssignee.email)
      }
      this.isLoaded = true
    },
    checkIsDisabled(item) {
      if (this.typeOfLink) {
        if (this.typeOfLink === 'TICKET_LINK_TYPE_IS_BLOCKED_BY' && this.filteredTicketLinked('TICKET_LINK_TYPE_IS_BLOCKED_BY').length > 0) {
          for (const ticket of this.filteredTicketLinked('TICKET_LINK_TYPE_IS_BLOCKED_BY')) {
            if (item.id === ticket.ticketId) {
              return true
            }
          }
        } else if (this.typeOfLink === 'TICKET_LINK_TYPE_BLOCKS' && this.filteredTicketLinked('TICKET_LINK_TYPE_BLOCKS').length > 0) {
          for (const ticket of this.filteredTicketLinked('TICKET_LINK_TYPE_BLOCKS')) {
            if (item.id === ticket.ticketId) {
              return true
            }
          }
        } else if (this.typeOfLink === 'TICKET_LINK_TYPE_RELATES' && this.filteredTicketLinked('TICKET_LINK_TYPE_RELATES').length > 0) {
          for (const ticket of this.filteredTicketLinked('TICKET_LINK_TYPE_RELATES')) {
            if (item.id === ticket.ticketId) {
              return true
            }
          }
        } else {
          return false
        }
      }
      return false
    },
    getMediaUrl(media) {
      if (media.thumbnails === undefined || media.thumbnails === null || typeof media.thumbnails === 'undefined') {
        return null
      }
      for (const thumbnail of media.thumbnails) {
        if (thumbnail.type === 1 || thumbnail.type === 'M') {
          return thumbnail.url
        }
      }
      return media.url
    },
    initCount() {
      this.countDocs = 0
      this.countImg = 0
      this.countVideo = 0
    },
    getTickets() {
      TicketApi.ListTicketByQuery(this.searchForTickets)
        .then(response => {
          this.ticketsForLink = response.tickets
        })
    },
    filteredTicketLinked(type) {
      return this.listLinks.filter(x => x.type === type)
    },
    redirectToContactDetail(contactId) {
      if (contactId !== null) {
        if (this.isOwnerModule) {
          return {
            name: 'OwnerContactDetailsView',
            params: { id: contactId }
          }
        } else if (this.isAssetModule) {
          return {
            name: 'AssetContactDetailsView',
            params: { id: contactId }
          }
        } else if (this.isAccountingModule) {
          return {
            name: 'AccountingContactDetailsView',
            params: { id: contactId }
          }
        } else if (this.isMarketingModule) {
          return {
            name: 'MarketingContactDetailsView',
            params: { id: contactId }
          }
        } else {
          return {
            name: 'AdminContactDetailsView',
            params: { id: contactId }
          }
        }
      }
    },
    redirectToBuildingDetail(buildingId) {
      if (this.isOwnerModule) {
        return {
          name: 'OwnerBuildingDetailsView',
          params: { id: buildingId },
          query: { view: 'building' }
        }
      } else if (this.isAssetModule) {
        return {
          name: 'AssetBuildingDetailsView',
          params: { id: buildingId },
          query: { view: 'building' }
        }
      } else if (this.isAccountingModule) {
        return {
          name: 'AccountingBuildingDetailsView',
          params: { id: buildingId },
          query: { view: 'building' }
        }
      } else if (this.isAdminModule) {
        return {
          name: 'AdminBuildingDetailsView',
          params: { id: buildingId },
          query: { view: 'building' }
        }
      }
    },
    redirectToPropertyDetail(propertyId) {
      if (this.isOwnerModule) {
        return {
          name: 'OwnerPropertyDetailsView',
          params: { id: propertyId }
        }
      } else if (this.isAdminModule) {
        return {
          name: 'AdminPropertyDetailsView',
          params: { id: propertyId }
        }
      } else if (this.isAssetModule) {
        return {
          name: 'AssetPropertyDetailsView',
          params: { id: propertyId }
        }
      } else if (this.isAccountingModule) {
        return {
          name: 'AccountingPropertyDetailsView',
          params: { id: propertyId }
        }
      }
    },
    redirectToRequestDetail(objectId, requestId) {
      return {
        name: 'MarketingObjectDetailsView',
        params: { id: objectId },
        query: {
          view: 0,
          elementSelected: requestId
        }
      }
    },
    pushToRequestDetail(objectId, requestId) {
      this.$router.push({
        name: 'MarketingObjectDetailsView',
        params: { id: objectId },
        query: {
          view: 0,
          elementSelected: requestId
        }
      })
    },
    redirectToDetail(buildingId, objectId, view) {
      if (this.isOwnerModule) {
        return {
          name: 'OwnerBuildingDetailsView',
          params: { id: buildingId },
          query: {
            view: view,
            elementSelected: objectId
          }
        }
      } else if (this.isAdminModule) {
        return {
          name: 'AdminBuildingDetailsView',
          params: { id: buildingId },
          query: {
            view: view,
            elementSelected: objectId
          }
        }
      } else if (this.isAssetModule) {
        return {
          name: 'AssetBuildingDetailsView',
          params: { id: buildingId },
          query: {
            view: view,
            elementSelected: objectId
          }
        }
      } else if (this.isAccountingModule) {
        return {
          name: 'AccountingBuildingDetailsView',
          params: { id: buildingId },
          query: {
            view: view,
            elementSelected: objectId
          }
        }
      }
    },
    checkSelectedElement(id) {
      if (id.startsWith('condominium')) {
        if (this.references.find(x => x.condominium && (x.condominium.id === id || x.condominium.objectID === id))) {
          return 'selected'
        }
      }
      if (id.startsWith('tenancy')) {
        if (this.references.find(x => x.tenancy && (x.tenancy.id === id || x.tenancy.objectID === id))) {
          return 'selected'
        }
      }
      if (id.startsWith('obj')) {
        if (this.references.find(x => x.object && (x.object.id === id || x.object.objectID === id) && (!x.tenancy && !x.condominium))) {
          return 'selected'
        }
      }
      if (id.startsWith('building')) {
        if (this.references.find(x => x.building && (x.building.id === id || x.building.objectID === id) && !x.object)) {
          return 'selected'
        }
      }
      if (id.startsWith('prop')) {
        if (this.references.find(x => x.property && (x.property.id === id || x.property.objectID === id) && !x.building)) {
          return 'selected'
        }
      }
      if (id.startsWith('contact')) {
        if (this.references.find(x => x.contact && (x.contact.id === id || x.contact.objectID === id))) {
          return 'selected'
        }
      }
      return ''
    },
    onSelect(selected) {
      if (selected && this.checkSelectedElement(selected.item.objectID) !== 'selected') {
        let alreadyExist = false
        const id = selected.item.objectID
        this.listEntities.push(id)
        if (selected.item.objectID.startsWith('condominium')) {
          if (this.references.find(x => x.condominium && (x.condominium.id === id || x.condominium.objectID === id))) {
            this.references = this.references.filter(x => !x.condominium || (x.condominium && ((x.condominium.objectID && x.condominium.objectID !== id) || (x.condominium.id && x.condominium.id !== id))))
            alreadyExist = true
          }
        }
        if (selected.item.objectID.startsWith('tenancy')) {
          if (this.references.find(x => x.tenancy && (x.tenancy.id === id || x.tenancy.objectID === id))) {
            this.references = this.references.filter(x => !x.tenancy || (x.tenancy && ((x.tenancy.objectID && x.tenancy.objectID !== id) || (x.tenancy.id && x.tenancy.id !== id))))
            alreadyExist = true
          }
        }
        if (selected.item.objectID.startsWith('obj')) {
          if (this.references.find(x => x.object && (x.object.id === id || x.object.objectID === id) && (!x.tenancy && !x.condominium))) {
            this.references = this.references.filter(x => !x.object || (x.object && ((x.object.objectID && x.object.objectID !== id) || (x.object.id && x.object.id !== id) || (x.tenancy || x.condominium))))
            alreadyExist = true
          }
        }
        if (selected.item.objectID.startsWith('building')) {
          if (this.references.find(x => x.building && (x.building.id === id || x.building.objectID === id) && !x.object)) {
            this.references = this.references.filter(x => !x.building || (x.building && ((x.building.objectID && x.building.objectID !== id) || (x.building.id && x.building.id !== id) || x.object)))
            alreadyExist = true
          }
        }
        if (selected.item.objectID.startsWith('prop')) {
          if (this.references.find(x => x.property && (x.property.id === id || x.property.objectID === id) && !x.building)) {
            this.references = this.references.filter(x => !x.property || (x.property && ((x.property.objectID && x.property.objectID !== id) || (x.property.id && x.property.id !== id) || x.building)))
            alreadyExist = true
          }
        }
        if (selected.item.objectID.startsWith('contact')) {
          if (this.references.find(x => x.contact && (x.contact.id === id || x.contact.objectID === id))) {
            this.references = this.references.filter(x => !x.contact || (x.contact && ((x.contact.objectID && x.contact.objectID !== id) || (x.contact.id && x.contact.id !== id))))
            alreadyExist = true
          }
        }
        if (alreadyExist === false) {
          this.addToReferences(selected, true)
        }
        this.$nextTick(function () {
          this.emailSuggestion = ''
          this.refrecheAutosuggestKey = Math.floor(Math.random() * 100)
        })
      } else {
        this.$refs.autoSuggest_connections.internalValue = ''
      }
    },
    imageClicked(item) {
      if (item.url && item.url !== '') {
        this.galleryImages = [
          item.url
        ]
        this.$nextTick(function () {
          this.$viewerApi({
            images: this.galleryImages,
            options: this.viewerOptions
          })
        })
      }
    },
    addToReferences(selected, withDescription = false) {
      this.emailSuggestion = null
      const lengthOfConnections = this.references.length
      this.references.push({
        request: null,
        object: null,
        building: null,
        property: null,
        condominium: null,
        tenancy: null,
        contact: null,
        device: null,
        room: null,
        mortgage: null,
        insurance: null,
        listContact: []
      })
      if (selected.item.objectID?.startsWith('condominium') || selected.item.id?.startsWith('condominium') || selected.item.objectID?.startsWith('tenancy') || selected.item.id?.startsWith('tenancy')) {
        if (selected.item.objectID?.startsWith('condominium') || selected.item.id?.startsWith('condominium')) {
          this.references[lengthOfConnections].condominium = selected.item
          if (selected.item.id || selected.item.objectID) {
            ContactApi.contactResolve(selected.item.owner.contactIds)
              .then(responseCon => {
                this.references[lengthOfConnections].listContact = responseCon.persons.concat(responseCon.companies).filter(c => selected.item.owner.contactIds.includes(c.id))
              })
          }
        } else if (selected.item.objectID?.startsWith('tenancy') || selected.item.id?.startsWith('tenancy')) {
          this.references[lengthOfConnections].tenancy = selected.item
          if (selected.item.id || selected.item.objectID) {
            const tenant = selected.item.tenant ? selected.item.tenant.contactIds : selected.item.tenants[0].contactIds
            ContactApi.contactResolve(tenant)
              .then(responseCon => {
                this.references[lengthOfConnections].listContact = responseCon.persons.concat(responseCon.companies).filter(c => tenant.includes(c.id))
              })
          }
        }
        ObjectApi.getObject(selected.item.obj?.id || selected.item.objectId)
          .then(response => {
            this.references[lengthOfConnections].object = response.object
            ObjectApi.getBuildingByObjectId(this.references[lengthOfConnections].object.id).then(response => {
              this.references[lengthOfConnections].building = response.building
              ObjectApi.getPropertiesByBuildingIds([this.references[lengthOfConnections].building.id])
                .then(response => {
                  this.references[lengthOfConnections].property = response.properties[0]
                  if (withDescription && this.ticket.type === 'TICKET_TYPE_ORDER') {
                    this.generateDescription(this.getReferenceById(selected.item.objectID, this.references.length - 1), 'add')
                  }
                })
            }).catch(e => {
              console.log(e)
              Vue.toasted.show(this.$t('message.loadingErrors.advertisement'), { type: 'error' })
            })
          })
      }
      if (selected.item.objectID?.startsWith('obj') || selected.item.id?.startsWith('obj')) {
        this.references[lengthOfConnections].object = selected.item
        // add address of object to description
        if (withDescription && this.ticket.type === 'TICKET_TYPE_ORDER') {
          this.generateDescription(this.getReferenceById(selected.item.objectID, this.references.length - 1), 'add')
        }
        ObjectApi.getBuildingByObjectId(selected.item.objectID || selected.item.id).then(response => {
          this.references[lengthOfConnections].building = response.building
          ObjectApi.getPropertiesByBuildingIds([this.references[lengthOfConnections].building.id])
            .then(response => {
              this.references[lengthOfConnections].property = response.properties[0]
            })
        }).catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.advertisement'), { type: 'error' })
        })
      }
      if (selected.item.objectID?.startsWith('building') || selected.item.id?.startsWith('building')) {
        this.references[lengthOfConnections].building = selected.item
        // add address of building to description
        if (withDescription && this.ticket.type === 'TICKET_TYPE_ORDER') {
          this.generateDescription(this.getReferenceById(selected.item.objectID, this.references.length - 1), 'add')
        }
        ObjectApi.getPropertiesByBuildingIds([selected.item.objectID || selected.item.id])
          .then(response => {
            this.references[lengthOfConnections].property = response.properties[0]
          })
      }
      if (selected.item.objectID?.startsWith('prop') || selected.item.id?.startsWith('prop')) {
        this.references[lengthOfConnections].property = selected.item
        // add the addresses of all the buildings of a property to the description
        if (withDescription && this.ticket.type === 'TICKET_TYPE_ORDER') {
          this.generateDescription(this.getReferenceById(selected.item.objectID, this.references.length - 1), 'add')
        }
      }
      if (selected.item.objectID?.startsWith('contact') || selected.item.id?.startsWith('contact')) {
        this.references[lengthOfConnections].contact = selected.item
      }
      if (selected.item.objectID?.startsWith('device') || selected.item.id?.startsWith('device')) {
        this.references[lengthOfConnections].device = selected.item

        if (selected.item.referenceId?.startsWith('obj')) {
          ObjectApi.getObject(selected.item.referenceId)
            .then(response => {
              this.references[lengthOfConnections].object = response.object
              ObjectApi.getBuildingByObjectId(this.references[lengthOfConnections].object.id).then(response => {
                this.references[lengthOfConnections].building = response.building
                ObjectApi.getPropertiesByBuildingIds([this.references[lengthOfConnections].building.id])
                  .then(response => {
                    this.references[lengthOfConnections].property = response.properties[0]
                  })
              }).catch(e => {
                console.log(e)
                Vue.toasted.show(this.$t('message.loadingErrors.advertisement'), { type: 'error' })
              })
            })
        }
        if (selected.item.referenceId?.startsWith('building')) {
          ObjectApi.getBuilding(selected.item.referenceId)
            .then(response => {
              this.references[lengthOfConnections].building = response.building
              ObjectApi.getPropertiesByBuildingIds([response.building.id])
                .then(response => {
                  this.references[lengthOfConnections].property = response.properties[0]
                })
            })
        }
        if (selected.item.referenceId?.startsWith('prop')) {
          ObjectApi.getPropertyListByIds([selected.item.referenceId])
            .then(response => {
              this.references[lengthOfConnections].property = response.properties[0]
            })
        }
      }
      if (selected.item.objectID?.startsWith('req') || selected.item.id?.startsWith('req')) {
        this.references[lengthOfConnections].request = selected.item
        if (selected.item.id || selected.item.objectID) {
          const cont = selected.item.requesters[0].contactId
          ContactApi.contactResolve([cont])
            .then(response => {
              this.references[lengthOfConnections].listContact = response.persons.concat(response.companies).filter(c => cont.includes(c.id))
            })
          ObjectApi.getObject(selected.item.objectId)
            .then(response => {
              this.references[lengthOfConnections].object = response.object
              ObjectApi.getBuildingByObjectId(this.references[lengthOfConnections].object.id).then(response => {
                this.references[lengthOfConnections].building = response.building
                ObjectApi.getPropertiesByBuildingIds([this.references[lengthOfConnections].building.id])
                  .then(response => {
                    this.references[lengthOfConnections].property = response.properties[0]
                  })
              }).catch(e => {
                console.log(e)
                Vue.toasted.show(this.$t('message.loadingErrors.advertisement'), { type: 'error' })
              })
            })
        }
      }
      if (selected.item.id?.startsWith('room')) {
        this.references[lengthOfConnections].room = selected.item
        if (selected.item && selected.item.referenceId.startsWith('obj')) {
          ObjectApi.getObject(selected.item.referenceId)
            .then(response => {
              this.references[lengthOfConnections].object = response.object
              ObjectApi.getBuildingByObjectId(this.references[lengthOfConnections].object.id).then(response => {
                this.references[lengthOfConnections].building = response.building
                ObjectApi.getPropertiesByBuildingIds([this.references[lengthOfConnections].building.id])
                  .then(response => {
                    this.references[lengthOfConnections].property = response.properties[0]
                  })
              }).catch(e => {
                console.log(e)
                Vue.toasted.show(this.$t('message.loadingErrors.advertisement'), { type: 'error' })
              })
            })
        }
        if (selected.item && selected.item.referenceId.startsWith('building')) {
          ObjectApi.getBuilding(selected.item.referenceId).then(response => {
            this.references[lengthOfConnections].building = response.building
            ObjectApi.getPropertiesByBuildingIds([this.references[lengthOfConnections].building.id])
              .then(response => {
                this.references[lengthOfConnections].property = response.properties[0]
              })
          }).catch(e => {
            console.log(e)
            Vue.toasted.show(this.$t('message.loadingErrors.advertisement'), { type: 'error' })
          })
        }
      }
    },
    convertAddress(address) {
      if (!address) {
        return '-'
      }

      const addr = (address.careOf ? address.careOf : '') +
        (address.streetAddition ? address.streetAddition : '') +
        (address.street ? address.street + (address.streetNumber ? ' ' + address.streetNumber : '') : '') +
        (address.zip ? ' ' + address.zip : '') +
        (address.city ? ' ' + address.city : '')

      if (addr) {
        return addr
      } else {
        return ''
      }
    },
    getDeviceDescription() {
      let description = ''
      const devices = this.references.map(x => x.device)
      devices.forEach(device => {
        description = description + '\n'
        if (device.referenceId.startsWith('prop')) {
          this.references.forEach(item => {
            const property = item.property
            if (item.device.id === device.id && property && device.referenceId === property?.id) {
              description = description + '\n' + this.$t('message.createOrder.address') + ' ' + this.convertAddress(property.address)
              description = description + '\n' + this.$t('message.createOrder.location') + ' ' + property.name
            }
          })
        }
        if (device.referenceId.startsWith('building')) {
          this.references.forEach(item => {
            const building = item.building
            if (item.device.id === device.id && building && device.referenceId === building?.id) {
              description = description + '\n' + this.$t('message.createOrder.address') + ' ' + this.convertAddress(building.address)
              description = description + '\n' + this.$t('message.createOrder.location') + ' ' + building.name
            }
          })
        }
        if (device.referenceId.startsWith('obj')) {
          this.references.forEach(item => {
            const object = item.object
            if (object && device.referenceId === object.id) {
              description = description + '\n' + this.$t('message.createOrder.address') + ' ' + this.convertAddress(object.address)
              description = description + '\n' + this.$t('message.createOrder.location') + ' ' + object.name + ', ' + this.$t('message.onBoarding.buildings.objects.category.' + object.category.toLowerCase()) + (object.characteristics?.floor !== -10000 ? ' - ' + this.formatFloor(object.characteristics?.floor) : '')
            }
          })
        }
        if (device.type !== '' && device.type !== 'DEVICE_TYPE_UNDEFINED') {
          description = description + '\n' + this.$t('message.createOrder.componentDevice') + ' ' + this.$t('message.onBoarding.devices.typeValues.' + device.type)
        }
        if (device.manufacturer !== '') {
          description = description + '\n' + this.$t('message.createOrder.manufacturer') + ' ' + device.manufacturer
        }
        if (device.brand !== '') {
          description = description + '\n' + this.$t('message.createOrder.brand') + ' ' + device.brand
        }
        if (device.model !== '') {
          description = description + '\n' + this.$t('message.createOrder.model') + ' ' + device.model
        }
        if (device.serialNumber !== '') {
          description = description + '\n' + this.$t('message.createOrder.serialNumber') + ' ' + device.serialNumber
        }
        if (device.orderDescription) {
          description = description + '\n\n' + this.$t('message.createOrder.orderDescription') + ':' + '\n' + device.orderDescription + '\n'
        }
      })
      return description
    },
    mountedReference(references) {
      for (let index = 0; index < references.length; index++) {
        this.references.push({
          object: null,
          building: null,
          property: null,
          condominium: null,
          tenancy: null,
          contact: null,
          device: null,
          request: null,
          room: null,
          mortgage: null,
          insurance: null,
          listContact: []
        })
        const element = references[index].id
        this.listEntities.push(element)
        if (element.startsWith('condominium') || element.startsWith('tenancy')) {
          if (element.startsWith('condominium')) {
            ObjectApi.getCondominiumByIds([element])
              .then(response => {
                this.references[index].condominium = response.condominiums[0]
                ContactApi.contactResolve(this.references[index].condominium.owner.contactIds)
                  .then(responseCon => {
                    this.references[index].listContact = responseCon.persons.concat(responseCon.companies)
                  })
                ObjectApi.getObject(this.references[index].condominium.objectId)
                  .then(responseObj => {
                    this.references[index].object = responseObj.object
                    ObjectApi.getBuildingByObjectId(this.references[index].object.id).then(responseBuild => {
                      this.references[index].building = responseBuild.building
                      ObjectApi.getPropertiesByBuildingIds([this.references[index].building.id])
                        .then(responseProp => {
                          this.references[index].property = responseProp.properties[0]
                        })
                    }).catch(e => {
                      console.log(e)
                      Vue.toasted.show(this.$t('message.loadingErrors.advertisement'), { type: 'error' })
                    })
                  })
              })
          } else if (element.startsWith('tenancy')) {
            ObjectApi.getTenanciesByIds([element])
              .then(response => {
                this.references[index].tenancy = response.tenancies[0]
                ContactApi.contactResolve(this.references[index].tenancy.tenant.contactIds)
                  .then(responseCon => {
                    this.references[index].listContact = responseCon.persons.concat(responseCon.companies).filter(c => this.references[index].tenancy.tenant.contactIds.includes(c.id))
                  })
                ObjectApi.getObject(this.references[index].tenancy.objectId)
                  .then(response => {
                    this.references[index].object = response.object
                    ObjectApi.getBuildingByObjectId(this.references[index].object.id).then(response => {
                      this.references[index].building = response.building
                      ObjectApi.getPropertiesByBuildingIds([this.references[index].building.id])
                        .then(response => {
                          this.references[index].property = response.properties[0]
                        })
                    }).catch(e => {
                      console.log(e)
                      Vue.toasted.show(this.$t('message.loadingErrors.advertisement'), { type: 'error' })
                    })
                  })
              })
          }
        }
        if (element.startsWith('device') || element.startsWith('device')) {
          ObjectApi.listDevicesByIds([element])
            .then(response => {
              if (response.devices.length > 0) {
                this.references[index].device = response.devices[0]
                if (response.devices[0].referenceId?.startsWith('obj')) {
                  ObjectApi.getObject(response.devices[0].referenceId)
                    .then(response => {
                      this.references[index].object = response.object
                      ObjectApi.getBuildingByObjectId(this.references[index].object.id).then(response => {
                        this.references[index].building = response.building
                        ObjectApi.getPropertiesByBuildingIds([this.references[index].building.id])
                          .then(response => {
                            this.references[index].property = response.properties[0]
                          })
                      }).catch(e => {
                        console.log(e)
                        Vue.toasted.show(this.$t('message.loadingErrors.advertisement'), { type: 'error' })
                      })
                    })
                }
                if (response.devices[0].referenceId?.startsWith('building')) {
                  ObjectApi.getBuilding(response.devices[0].referenceId)
                    .then(response => {
                      this.references[index].building = response.building
                      ObjectApi.getPropertiesByBuildingIds([response.building.id])
                        .then(response => {
                          this.references[index].property = response.properties[0]
                        })
                    })
                }
                if (response.devices[0].referenceId?.startsWith('prop')) {
                  ObjectApi.getPropertyListByIds([response.devices[0].referenceId])
                    .then(response => {
                      this.references[index].property = response.properties[0]
                    })
                }
              }
            })
        }
        if (element.startsWith('request')) {
          RequestApi.listByIds([element])
            .then(response => {
              this.references[index].request = response.requests[0]
              const cont = response.requests[0].requesters[0].contactId
              ContactApi.contactResolve([cont])
                .then(response => {
                  this.references[index].listContact = response.persons.concat(response.companies).filter(c => cont.includes(c.id))
                })
            })
        }
        if (element.startsWith('obj')) {
          ObjectApi.getObject(element)
            .then(response => {
              this.references[index].object = response.object
              ObjectApi.getBuildingByObjectId(this.references[index].object.id).then(response => {
                this.references[index].building = response.building
                ObjectApi.getPropertiesByBuildingIds([this.references[index].building.id])
                  .then(response => {
                    this.references[index].property = response.properties[0]
                  })
              }).catch(e => {
                console.log(e)
                Vue.toasted.show(this.$t('message.loadingErrors.advertisement'), { type: 'error' })
              })
            })
        }
        if (element.startsWith('room')) {
          RoomApi.listById([element]).then(roomResponse => {
            const room = roomResponse.rooms[0]
            this.references[index].room = room
            if (room && room.referenceId.startsWith('obj')) {
              ObjectApi.getObject(room.referenceId)
                .then(response => {
                  this.references[index].object = response.object
                  ObjectApi.getBuildingByObjectId(this.references[index].object.id).then(response => {
                    this.references[index].building = response.building
                    ObjectApi.getPropertiesByBuildingIds([this.references[index].building.id])
                      .then(response => {
                        this.references[index].property = response.properties[0]
                      })
                  }).catch(e => {
                    console.log(e)
                    Vue.toasted.show(this.$t('message.loadingErrors.advertisement'), { type: 'error' })
                  })
                })
            }
            if (room && room.referenceId.startsWith('building')) {
              ObjectApi.getBuilding(room.referenceId).then(response => {
                this.references[index].building = response.building
                ObjectApi.getPropertiesByBuildingIds([this.references[index].building.id])
                  .then(response => {
                    this.references[index].property = response.properties[0]
                  })
              }).catch(e => {
                console.log(e)
                Vue.toasted.show(this.$t('message.loadingErrors.advertisement'), { type: 'error' })
              })
            }
          })
        }
        if (element.startsWith('building')) {
          ObjectApi.getBuildingsListByIds([element]).then(response => {
            this.references[index].building = response.buildings[0]
            ObjectApi.getPropertiesByBuildingIds([this.references[index].building.id])
              .then(response => {
                this.references[index].property = response.properties[0]
              })
          })
        }
        if (element.startsWith('prop')) {
          ObjectApi.getPropertyListByIds([element])
            .then(response => {
              this.references[index].property = response.properties[0]
            })
        }
        if (element.startsWith('contact')) {
          ContactApi.contactResolve([element])
            .then(response => {
              this.references[index].contact = (response.persons.concat(response.companies)).find(x => x.id === element)
            })
        }
        if (element.startsWith('mortgage')) {
          ObjectApi.getMortgagesByIds([element])
            .then(response => {
              if (response.mortgages.length > 0) {
                this.references[index].mortgage = response.mortgages[0]
                if (response.mortgages[0].referenceId?.startsWith('building')) {
                  ObjectApi.getBuilding(response.mortgages[0].referenceId)
                    .then(buildingResponse => {
                      this.references[index].building = buildingResponse.building
                      ObjectApi.getPropertiesByBuildingIds([buildingResponse.building.id])
                        .then(propertResponse => {
                          this.references[index].property = propertResponse.properties[0]
                        })
                    })
                }
                if (response.mortgages[0].referenceId?.startsWith('prop')) {
                  ObjectApi.getPropertyListByIds([response.mortgages[0].referenceId])
                    .then(propertyResponse => {
                      this.references[index].property = propertyResponse.properties[0]
                    })
                }
              }
            })
        }
        if (element.startsWith('ins')) {
          ObjectApi.getInsurancesByIds([element])
            .then(response => {
              if (response.insurances.length > 0) {
                this.references[index].insurance = response.insurances[0]
                if (response.insurances[0].referenceId?.startsWith('building')) {
                  ObjectApi.getBuilding(response.insurances[0].referenceId)
                    .then(buildingResponse => {
                      this.references[index].building = buildingResponse.building
                      ObjectApi.getPropertiesByBuildingIds([buildingResponse.building.id])
                        .then(propertResponse => {
                          this.references[index].property = propertResponse.properties[0]
                        })
                    })
                }
                if (response.insurances[0].referenceId?.startsWith('prop')) {
                  ObjectApi.getPropertyListByIds([response.insurances[0].referenceId])
                    .then(propertyResponse => {
                      this.references[index].property = propertyResponse.properties[0]
                    })
                }
              }
            })
        }
      }
    },
    removeReference(index) {
      const id = this.listEntities[index]
      this.listEntities = this.listEntities.filter((element, i) => i !== index)
      if (this.ticket.type === 'TICKET_TYPE_ORDER') {
        this.generateDescription(this.getReferenceById(id, index), 'remove')
      }
      this.$delete(this.references, index)
    },
    getReferenceById(id, index) {
      const item = this.references[index]
      if (id.startsWith('prop')) {
        return item.property
      } else if (id.startsWith('building')) {
        return item.building
      } else if (id.startsWith('obj') || id.startsWith('condominium') || id.startsWith('tenancy')) {
        return item.object
      }
    },
    onSelectLinked(selected) {
      if (selected) {
        if (!this.checkIsDisabled(selected.item)) {
          this.ticketToLink = selected.item.id
          this.searchForTickets = null
          this.$nextTick(function () {
            this.refrecheAutosuggestKey = Math.floor(Math.random() * 100)
          })
        } else {
          this.$nextTick(function () {
            this.searchForTickets = null
            this.ticketToLink = ''
            this.ticketsForLink = []
          })
        }
        this.linkToTicket()
      }
    },
    deleteMedia(e, id) {
      e.stopPropagation()
      if (this.ticket.mediaIds) {
        this.ticket.mediaIds = this.ticket.mediaIds.filter(e => e !== id)
      }
      if (this.mediasList) {
        this.mediasList = this.mediasList.filter(el => el.id !== id)
        this.initCount()
      }
    },
    async addFile(event) {
      this.attachment = await this.getFilesFromEvent(event)
      if (this.attachment?.length > 0) {
        await this.sendFile()
        this.$store.dispatch('user/setChangedModal', true).then().catch(e => console.log(e))
      }
    },
    async sendFile() {
      this.uploading = true
      const response = await this.uploadMedia(this.attachment)
      if (response.length > 0) {
        for (const media of response) {
          this.mediasList.push(media)
          this.ticket.mediaIds.push(media.id)
        }
        this.$nextTick(() => {
          this.attachment = []
          this.uploading = false
          this.initCount()
          this.$nextTick(() => {
            if (this.$refs['coozzy-file-input']) {
              this.$refs['coozzy-file-input'].resetPlaceholder()
            }
          })
        })
      } else {
        this.uploading = false
      }
    },
    cancelClicked() {
      if (this.hasChanged === true) {
        this.$refs.confirmationModal.show()
      } else {
        this.confirmCancellation()
      }
    },
    confirmCancellation() {
      this.hasChanged = false
      if (this.nextToPath !== null) {
        this.$router.push({
          name: this.nextToPath.name,
          params: this.nextToPath.params
        }, () => {
        })
      } else {
        if ((this.prevRoutePath.includes('buildings/building_') || this.prevRoutePath.includes('properties/prop_')) && this.references.length > 0 && this.references[0].device) {
          this.redirectDevicePage(this.references[0])
        } else if (this.prevRoutePath !== '') {
          this.$router.replace({
            path: this.prevRoutePath,
            query: this.prevRouteQuery
          }, () => {
          })
        } else {
          this.redirectToDetailPage(this.ticket.key)
        }
      }
    },
    removeBuilding(buildingIndex) {
      this.property.buildings.splice(buildingIndex, 1)
    },
    getListRole(contact) {
      if (contact !== null && contact.types !== undefined) {
        if (contact.types && contact.types.length === 1 && contact.types[0] === 'NONE') {
          return '-'
        } else {
          const array = []
          contact.types.forEach(role => {
            if (role !== 'NONE') {
              array.push(this.$t('message.contact.types.' + role.toUpperCase()))
            }
          })
          return array.join(', ')
        }
      }
      return '-'
    },
    indicesToSuggestions(indices) {
      return indices.map(({ hits }) => ({
        data: hits
      }))
    },
    onChange(refine, email) {
      if (email && email.length > 0) {
        if (email !== '') clearTimeout(this.debounceTimeout)
        this.debounceTimeout = setTimeout(() => {
          this.debounceTimeout = null
          refine(email)
        }, 300)
      }
    },
    dateToObject() {
      this.ticket.startDate = this.toObject(this.dates.startDate)
      this.ticket.dueDate = this.toObject(this.dates.dueDate)
    },
    linkToTicket() {
      if (this.ticketToLink !== '') {
        this.listLinks.push({
          ticketId: this.ticketToLink,
          type: this.typeOfLink
        })
        this.listTickets.push(this.ticketsForLink.find(w => w.id === this.ticketToLink))
        this.typeOfLink = 'TICKET_LINK_TYPE_RELATES'
        this.ticketToLink = ''
        this.searchForTickets = ''
      }
    },
    removeTicketLinked(ticket) {
      this.listLinks = this.listLinks.filter(x => !(x.ticketId === ticket.ticketId && x.type === ticket.type))
    },
    see(refine, value) {
      refine(value)
    },
    customLabel(item) {
      return item.name
    },
    redirectDevicePage(item) {
      if (this.prevRoutePath.includes('buildings/building_')) {
        const idBuilding = item.building?.id ? item.building.id : item.building.objectID
        const view = this.prevRouteQuery.view || ''
        if (this.isOwnerModule) {
          this.$router.push({
            name: 'OwnerBuildingDetailsView',
            params: { id: idBuilding },
            query: { view: view }
          }, () => {
          })
        } else if (this.isAdminModule) {
          this.$router.push({
            name: 'AdminBuildingDetailsView',
            params: { id: idBuilding },
            query: { view: view }
          }, () => {
          })
        } else if (this.isAssetModule) {
          this.$router.push({
            name: 'AssetBuildingDetailsView',
            params: { id: idBuilding },
            query: { view: view }
          }, () => {
          })
        } else if (this.isAccountingModule) {
          this.$router.push({
            name: 'AccountingBuildingDetailsView',
            params: { id: idBuilding },
            query: { view: view }
          }, () => {
          })
        }
      } else if (this.prevRoutePath.includes('properties/prop_')) {
        const idProperty = item.property.id ? item.property.id : item.property.objectID
        const view = this.prevRouteQuery.view || ''
        if (this.isOwnerModule) {
          this.$router.push({
            name: 'OwnerPropertyDetailsView',
            params: { id: idProperty },
            query: { view: view }
          }, () => {
          })
        } else if (this.isAdminModule) {
          this.$router.push({
            name: 'AdminPropertyDetailsView',
            params: { id: idProperty },
            query: { view: view }
          }, () => {
          })
        } else if (this.isAssetModule) {
          this.$router.push({
            name: 'AssetPropertyDetailsView',
            params: { id: idProperty },
            query: { view: view }
          }, () => {
          })
        } else if (this.isAccountingModule) {
          this.$router.push({
            name: 'AccountingPropertyDetailsView',
            params: { id: idProperty },
            query: { view: view }
          }, () => {
          })
        }
      }
    },
    redirectToDetailPage(ticketKey = null) {
      if (ticketKey) {
        if (this.isOwnerModule) {
          this.$router.replace({
            name: 'OwnerTicketDetailsView',
            params: { id: ticketKey }
          }, () => {
          })
        } else if (this.isAdminModule) {
          this.$router.replace({
            name: 'AdminTicketDetailsView',
            params: { id: ticketKey }
          }, () => {
          })
        } else if (this.isAssetModule) {
          this.$router.replace({
            name: 'AssetTicketDetailsView',
            params: { id: ticketKey }
          }, () => {
          })
        } else if (this.isAccountingModule) {
          this.$router.replace({
            name: 'AccountingTicketDetailsView',
            params: { id: ticketKey }
          }, () => {
          })
        } else {
          this.$router.replace({
            name: 'MarketingTicketDetailsView',
            params: { id: ticketKey }
          }, () => {
          })
        }
      } else {
        if (this.isOwnerModule) {
          this.$router.push({ name: 'OwnerTicketOverview' }, () => {
          })
        } else if (this.isAdminModule) {
          this.$router.push({ name: 'AdminTicketOverview' }, () => {
          })
        } else if (this.isAssetModule) {
          this.$router.push({ name: 'AssetTicketOverview' }, () => {
          })
        } else if (this.isAccountingModule) {
          this.$router.push({ name: 'AccountingTicketOverview' }, () => {
          })
        } else {
          this.$router.push({ name: 'MarketingTicketOverview' }, () => {
          })
        }
      }
    },
    async saveTicket(draft = false) {
      this.$v.ticket.$touch()
      if (!this.$v.ticket.$invalid) {
        this.loading = true
        this.ticket.assignee = this.ticket.assignee && this.ticket.assignee.value
          ? {
            accountId: this.ticket.assignee.value.accountId,
            userId: this.ticket.assignee.value.id
          }
          : null
        const corres = []
        if (this.ticket.type !== 'TICKET_TYPE_ORDER') {
          this.ticket.correspondences.forEach(element => {
            corres.push({ contactId: element.objectID || element.contactId })
          })
          this.ticket.correspondences = corres
        } else {
          const cor = this.ticket.correspondences.length > 0 ? { contactId: this.ticket.correspondences[0].objectID || this.ticket.correspondences[0].id || this.ticket.correspondences[0].contactId } : null
          this.ticket.correspondences = cor ? [cor] : []
          this.ticket.description += this.generatedDescription
        }
        const ticketReferences = []
        if (this.references.length > 0) {
          this.references.forEach((element, index) => {
            if (element.mortgage) {
              if (index === 0) {
                this.ticket.ownerId = 'ownerId' in element.mortgage ? element.mortgage.ownerId : element.mortgage.owner?.id
              }
              const id = element.mortgage.id ? element.mortgage.id : element.mortgage.objectID
              const referenceId = element.mortgage.referenceId
              ticketReferences.push({
                id: id,
                relatedIds: [referenceId]
              })
            } else if (element.insurance) {
              if (index === 0) {
                this.ticket.ownerId = 'ownerId' in element.insurance ? element.insurance.ownerId : element.insurance.owner?.id
              }
              const id = element.insurance.id ? element.insurance.id : element.insurance.objectID
              const referenceId = element.insurance.referenceId
              ticketReferences.push({
                id: id,
                relatedIds: [referenceId]
              })
            } else if (element.room) {
              if (index === 0) {
                this.ticket.ownerId = 'ownerId' in element.room ? element.room.ownerId : element.room.owner?.id
              }
              const id = element.room.id ? element.room.id : element.room.objectID
              let idProperty = null
              let idBuilding = null
              let idObject = null
              let ids = []
              if (element.object) {
                idProperty = element.property.id ? element.property.id : element.property.objectID
                idBuilding = element.building?.id ? element.building.id : element.building.objectID
                idObject = element.object?.id ? element.object.id : element.object.objectID

                ids = [idProperty, idBuilding, idObject]
              } else if (element.building) {
                idProperty = element.property.id ? element.property.id : element.property.objectID
                idBuilding = element.building?.id ? element.building.id : element.building.objectID

                ids = [idProperty, idBuilding]
              } else {
                ids = [element.room.referenceId]
              }
              ticketReferences.push({
                id: id,
                relatedIds: ids
              })
            } else if (element.contact) {
              if (index === 0) {
                this.ticket.ownerId = 'ownerId' in element.contact ? element.contact.ownerId : element.contact.owner?.id
              }
              const id = element.contact.id ? element.contact.id : element.contact.objectID
              ticketReferences.push({
                id: id,
                relatedIds: []
              })
            } else if (element.tenancy) {
              if (index === 0) {
                this.ticket.ownerId = 'ownerId' in element.tenancy ? element.tenancy.ownerId : element.tenancy.owner?.id
              }
              const id = element.tenancy.id ? element.tenancy.id : element.tenancy.objectID
              const idProperty = element.property.id ? element.property.id : element.property.objectID
              const idBuilding = element.building.id ? element.building.id : element.building.objectID
              const idObject = element.object.id ? element.object.id : element.object.objectID
              ticketReferences.push({
                id: id,
                relatedIds: [idProperty, idBuilding, idObject]
              })
            } else if (element.request) {
              if (index === 0) {
                this.ticket.ownerId = 'ownerId' in element.request ? element.request.ownerId : element.request.owner?.id
              }
              const id = element.request.id ? element.request.id : element.request.objectID
              const idProperty = element.property.id ? element.property.id : element.property.objectID
              const idBuilding = element.building?.id ? element.building.id : element.building.objectID
              const idObject = element.object?.id ? element.object.id : element.object.objectID

              ticketReferences.push({
                id: id,
                relatedIds: [idProperty, idBuilding, idObject]
              })
            } else if (element.condominium) {
              if (index === 0) {
                this.ticket.ownerId = 'ownerId' in element.condominium ? element.condominium.ownerId : element.condominium.owner?.id
              }
              const id = element.condominium.id ? element.condominium.id : element.condominium.objectID
              const idProperty = element.property.id ? element.property.id : element.property.objectID
              const idBuilding = element.building.id ? element.building.id : element.building.objectID
              const idObject = element.object.id ? element.object.id : element.object.objectID
              ticketReferences.push({
                id: id,
                relatedIds: [idProperty, idBuilding, idObject]
              })
            } else if (element.device) {
              if (index === 0) {
                this.ticket.ownerId = element.device.ownerId
              }
              const id = element.device.id ? element.device.id : element.device.objectID
              let idProperty = null
              let idBuilding = null
              let idObject = null
              let ids = []
              if (element.object) {
                idProperty = element.property.id ? element.property.id : element.property.objectID
                idBuilding = element.building?.id ? element.building.id : element.building.objectID
                idObject = element.object?.id ? element.object.id : element.object.objectID

                ids = [idProperty, idBuilding, idObject]
              } else if (element.building) {
                idProperty = element.property.id ? element.property.id : element.property.objectID
                idBuilding = element.building?.id ? element.building.id : element.building.objectID

                ids = [idProperty, idBuilding]
              } else {
                idProperty = element.property.id ? element.property.id : element.property.objectID

                ids = [idProperty]
              }
              ticketReferences.push({
                id: id,
                relatedIds: ids
              })
            } else if (element.object) {
              if (index === 0) {
                this.ticket.ownerId = 'ownerId' in element.object ? element.object.ownerId : element.object.owner?.id
              }
              const id = element.object.id ? element.object.id : element.object.objectID
              const idProperty = element.property.id ? element.property.id : element.property.objectID
              const idBuilding = element.building.id ? element.building.id : element.building.objectID
              ticketReferences.push({
                id: id,
                relatedIds: [idProperty, idBuilding]
              })
            } else if (element.building) {
              if (index === 0) {
                this.ticket.ownerId = 'ownerId' in element.building ? element.building.ownerId : element.building.owner?.id
              }
              const id = element.building.id ? element.building.id : element.building.objectID
              const idProperty = element.property.id ? element.property.id : element.property.objectID
              ticketReferences.push({
                id: id,
                relatedIds: [idProperty]
              })
            } else if (element.property) {
              if (index === 0) {
                this.ticket.ownerId = 'ownerId' in element.property ? element.property.ownerId : element.property.owner?.id
              }
              const id = element.property.id ? element.property.id : element.property.objectID
              ticketReferences.push({
                id: id,
                relatedIds: []
              })
            }
          })
        } else {
          this.ticket.ownerId = this.$store.getters['user/getAccountId']
        }
        this.ticket.references = ticketReferences
        if (this.ticket.onSiteContacts.length > 0) {
          const list = []
          this.onSiteContactPersons.forEach(contact => {
            list.push({
              contactId: contact.objectID || contact.id || contact.contactId,
              notify: false,
              notifyOnlyStatusChanges: false,
              includeCorrespondenceContact: false
            })
          })
          this.ticket.onSiteContacts = list
          this.ticket.onSiteContact = null
        } else if (this.ticket.onSiteContact) {
          this.ticket.onSiteContacts.push({
            contactId: this.ticket.onSiteContact.objectID || this.ticket.onSiteContact.id || this.ticket.onSiteContact.contactId,
            notify: false,
            notifyOnlyStatusChanges: false,
            includeCorrespondenceContact: false
          })
        }
        this.ticket.links = this.listLinks
        if (this.ticket.id) {
          if (draft) {
            this.ticket.status = 'TICKET_STATUS_DRAFT'
          } else {
            this.ticket.status = this.ticket.status !== 'TICKET_STATUS_DRAFT' ? this.ticket.status : 'TICKET_STATUS_OPEN'
          }
          let updateMask = null
          if (this.linksUpdated) {
            updateMask = 'id,links'
          }
          TicketApi.updateTicket(this.ticket, updateMask)
            .then(() => {
              this.hasChanged = false
              if (!draft) {
                this.redirectToDetailPage(this.ticket.key)
              } else {
                this.redirectToTicketDraft()
              }
              Vue.toasted.show(this.$t('message.successMessages.ticket'), { type: 'success' })
            })
            .catch(e => {
              console.log(e)
              Vue.toasted.show(this.$t('message.savingErrors.ticket'), { type: 'error' })
            })
            .finally(() => {
              this.loading = false
            })
        } else {
          let promises = []
          if (this.listRequestsMedia.length > 0) {
            this.listRequestsMedia.forEach(element => {
              if (element !== null) {
                const formData = new FormData()
                const fileName = this.editFileName(element.filename)
                formData.append('file', element.blob, fileName)
                formData.append('filename', element.filename)
                formData.append('public', 'true')
                promises.push(MediaApi.uploadMedia(formData))
              }
            })
            await Promise.all(promises)
              .then(response => {
                for (const media of response) {
                  this.ticket.mediaIds.push(media.id)
                }
              })
          }
          if (draft) {
            this.ticket.status = 'TICKET_STATUS_DRAFT'
          } else {
            this.ticket.status = 'TICKET_STATUS_OPEN'
          }
          TicketApi.newTicket(this.ticket)
            .then((response) => {
              this.ticket = response.ticket
              if (this.emailsData !== '') {
                const data = JSON.parse(this.emailsData)
                const id = data.id
                MessageApi.setReferenceIds([id], [this.ticket.id].concat(data.referenceIds))
                  .then(() => {
                    this.hasChanged = false
                    if (!draft) {
                      this.pageRedirection()
                    } else {
                      this.redirectToTicketDraft()
                    }
                  })
              } else {
                this.hasChanged = false
                this.pageRedirection()
              }
            })
            .catch(e => {
              console.log(e)
              Vue.toasted.show(this.$t('message.savingErrors.ticket'), { type: 'error' })
            })
            .finally(() => {
              this.loading = false
            })
        }
      } else {
        this.scrollToError()
      }
    },
    redirectToTicketDraft() {
      if (this.isOwnerModule) {
        this.$router.replace({
          name: 'OwnerDraftTicketOverview'
        })
      } else if (this.isAdminModule) {
        this.$router.replace({
          name: 'AdminDraftTicketOverview'
        })
      } else if (this.isAssetModule) {
        this.$router.replace({
          name: 'AssetDraftTicketOverview'
        })
      } else if (this.isAccountingModule) {
        this.$router.replace({
          name: 'AccountingDraftTicketOverview'
        })
      } else {
        this.$router.replace({
          name: 'MarketingDraftTicketOverview'
        })
      }
    },
    pageRedirection() {
      if (this.ticket.type === 'TICKET_TYPE_ORDER') {
        Vue.toasted.show(this.$t('message.successMessages.orderSent'), { type: 'success' })
      } else {
        Vue.toasted.show(this.$t('message.successMessages.ticket'), { type: 'success' })
      }
      if (this.fromBulk === true && this.references.length > 0 && this.references[0].device && (this.prevRoutePath.includes('buildings/building_') || this.prevRoutePath.includes('properties/prop_'))) {
        this.redirectDevicePage(this.references[0])
      } else if (typeof this.ids === 'string' && this.ids.startsWith('request_')) {
        const objectId = this.references[0].object?.id
        const requestId = this.ids
        this.pushToRequestDetail(objectId, requestId)
      } else {
        this.redirectToDetailPage(this.ticket.key)
      }
    }
  },
  validations: {
    ticket: {
      type: {
        required
      },
      assignee: {
        required
      },
      title: {
        required
      }
    }
  }
}
</script>
<style lang="scss" scoped>

:deep(.textAreaHeight textarea) {
  height: calc(1.5em + 0.5rem + 2px);
}

:deep(.vs__dropdown-toggle) {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0;
  padding-left: 0.25rem;
  font-size: 0.875rem;
  vertical-align: middle;
  background-color: #fff;
  border: 1px solid #ced4da;
  box-shadow: 1px 1px 6px #ccc;
}

:deep(.vs__selected) {
  margin: 0 2px 0;

  img {
    margin-bottom: 2px;
  }
}

:deep(.vs__clear) {
  box-shadow: none;
}

.thumbnail-size-restriction {
  width: 100%;
  height: auto;
  max-height: 3rem;
}

.linked-row {
  border-radius: 0.2rem;
  background-color: white;
  border: 1px solid $color-input-border;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;

  .label {
    line-height: 1.8rem;
  }

  .status-design {
    min-width: 125px !important;
    text-align: center;
    padding: 0.35rem 0.5rem;
    text-transform: uppercase;
    font-size: small;
    border-radius: 0.2rem;

    &.open {
      background: lightgrey;
    }

    &.in_progress {
      background: lightskyblue;
    }

    &.done {
      background: darkseagreen;
    }
  }
}

.uploaded-file {
  max-height: 100px;
  background-position: center center;
  background-size: cover;
  min-height: 5em;
}

.file-block {
  display: flex;
  align-items: center;
}

.delete-icon.image {
  position: absolute;
  right: 15px;
}

.delete-icon.video {
  position: absolute;
  right: 15px;
  top: 5px;
}

.color-box.condo_owner {
  background: #f589c7;
}

.color-box.condominium {
  background: #913489;
}

.color-box.tenancy {
  background: #00a4bd;
}

.color-box.object {
  background: #f38d38;
}

.color-box.device {
  background: #767171;
}

.color-box.request {
  background: #f7b6ae;
}

.containerCircles {
  height: 21px;
  align-items: center;
  display: flex;
}

.subtitle {
  color: #495057;
  font-weight: 400;
}

.media-block {
  padding: 0.5rem;
  padding-left: 0;
}

@media (min-width: 768px) {
  .uploaded-file {
    max-height: 100px;
    min-height: 4em;
  }
}

@media (min-width: 992px) {
  .uploaded-file {
    max-height: 110px;
    min-height: 5em;
  }
}

@media (min-width: 1200px) {
  .uploaded-file {
    max-height: 120px;
    min-height: 5.5em;
  }
}

@media (min-width: 1600px) {
  .uploaded-file {
    max-height: 130px;
    min-height: 7em;
  }
}

.showSelected {
  display: none;
}

.selected {
  background-color: rgba(0, 0, 0, 0.08);
}

.selected .showSelected {
  display: block;
  color: rgba(0, 0, 0, 0.5);
  align-self: center;
  margin-right: 20px;
  font-weight: 600;
}

.disableOptionHover {
  cursor: default !important;
  color: #6c757d !important;
  opacity: 0.5;
}

:deep(#autosuggest .autosuggest__results) {
  z-index: 9998;
}

.tooltip {
  z-index: 9999;
}

// .bg-init {
//   background: initial !important;
// }
:deep(.assignee .vs__selected-options) {
  width: 70%;
}

:deep(.assignee .vs__selected-options .vs__selected > div) {
  width: calc(22vw);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.connection a.router-link-active, .connection a {
  color: inherit !important;
  text-decoration: none !important;
}

.connection a.router-link-active:hover, .connection a:hover {
  color: #0055b7 !important;
  text-decoration: underline !important;
  cursor: pointer;
}

:deep(.autosuggest__results-item.autosuggest__results-item--highlighted) {
  background-color: rgba(0, 0, 0, 0.03);
}

:deep .is-invalid .vs__dropdown-toggle {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("../../../assets/icon/is-invalid.svg");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.wrap {
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-all;
}

.sizeLabel {
  font-size: 0.875rem !important;
}

.wizard-btn {
  background-color: rgb(0, 68, 95) !important;
  border-color: rgb(0, 68, 95) !important;
  color: white !important;
}

.autosuggest__results-item .selected {
  color: rgba(0, 0, 0, 0.5) !important;
}

:deep(.tooltip.b-tooltip) {
  z-index: 9999;
}

.selectedOption {
  color: #495057;
  background-color: #e9ecef;
}
</style>
