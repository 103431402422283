<template>
  <section class="w-100">
    <h6
      :key="'single-adjustmentData'"
      v-b-toggle="`collapse-condominium-adjustedcosts-index-${index}`"
      class="cursor-pointer hover font-weight-bold mt-3 width-only-content">
      <template v-if="untilDate">
        {{ dates.adjustmentsByDate | formatDate }} - {{ untilDate | formatDate }}
      </template>
      <template v-else>
        {{ $t('message.generic.asOf') }} {{ dates.adjustmentsByDate | formatDate }}
      </template>
    </h6>
    <b-collapse
      :id="`collapse-condominium-adjustedcosts-index-${index}`"
      :key="'collapse-single-adjustmentData'"
      :visible="adjustment.visibleCollaps">
      <div
        :key="'adjustmentData'"
        class="row">
        <div class="col-12">
          <div class="row details-row-bg-1">
            <div class="col-sm-12 col-md-3">
              <coozzy-form-input
                v-model="dates.adjustmentsByDate"
                :is-read-only="readOnly"
                :filter="'formatDate'"
                type="date"
                :disabled="biggerThanDebitUntil && !isNewItem"
                :state="$v.adjustment.byDate.$dirty && $v.adjustment.byDate.$error ? false : null"
                :name="$t('message.generic.byDate') + (readOnly ? '' : '*')"
                :tooltip-text="$t('message.onBoarding.buildings.objects.tenancies.byDateTooltip')"
                @blur="dateToObject()" />
            </div>
            <div
              v-if="((!readOnly && !isFirstAdjustment) && !biggerThanDebitUntil) || isNewItem"
              class="col-md-1 mt-2">
              <coozzy-button
                design="alert"
                class="align-self-center mt-3"
                @click="openConfirmationModal()">
                {{ $t('message.generic.delete') }}
              </coozzy-button>
            </div>
          </div>
          <div class="row details-row-bg-2">
            <template
              v-if="checkFlatRateValues('FLAT_RATE_RENT_COMPONENT_BUDGET_EXPECTATIONS')">
              <div
                v-if="!readOnly && mode !== 'activate'"
                class="col-sm-12 col-md-3 mb-2">
                <coozzy-form-input-numeric
                  v-model="budgetExpectations"
                  type="number"
                  min="0"
                  :disabled="((isFirstAdjustment && !canBeEdited) || biggerThanDebitUntil) && !isNewItem"
                  :is-decimal="true"
                  :is-read-only="readOnly"
                  :name="$t('message.propertiesView.rentComponents.flatRateRentComponents.FLAT_RATE_RENT_COMPONENT_BUDGET_EXPECTATIONS')" />
              </div>
              <div
                v-else-if="readOnly"
                class="col-sm-12 col-md-3">
                <label>
                  {{ $t('message.propertiesView.rentComponents.flatRateRentComponents.FLAT_RATE_RENT_COMPONENT_BUDGET_EXPECTATIONS') }}
                </label>
                <span class="d-block">
                  {{ budgetExpectations }}
                </span>
              </div>
            </template>
            <template
              v-if="checkFlatRateValues('FLAT_RATE_RENT_COMPONENT_RENOVATION_FUND')">
              <div
                v-if="!readOnly && mode !== 'activate'"
                class="col-sm-12 col-md-3 mb-2">
                <coozzy-form-input-numeric
                  v-model="renovationFund"
                  type="number"
                  min="0"
                  :disabled="((isFirstAdjustment && !canBeEdited) || biggerThanDebitUntil) && !isNewItem"
                  :is-decimal="true"
                  :is-read-only="readOnly"
                  :name="$t('message.propertiesView.rentComponents.flatRateRentComponents.FLAT_RATE_RENT_COMPONENT_RENOVATION_FUND')" />
              </div>
              <div
                v-else-if="readOnly"
                class="col-sm-12 col-md-3">
                <label>
                  {{ $t('message.propertiesView.rentComponents.flatRateRentComponents.FLAT_RATE_RENT_COMPONENT_RENOVATION_FUND') }}
                </label>
                <span class="d-block">
                  {{ renovationFund }}
                </span>
              </div>
            </template>
            <div
              v-if="readOnly"
              class="col-6">
              <label for="dueDates">
                {{ $t('message.onBoarding.buildings.objects.tenancies.dueDates') }}
              </label>
              <p
                v-if="dueDates.length > 0"
                class="mb-0 multi-select-value">
                {{ getDueDateMonths }}
              </p>
              <p
                v-else
                class="mb-0 multi-select-value">
                -
              </p>
            </div>
            <div
              v-else
              class="col-6">
              <coozzy-multiselect
                id="dueDates"
                v-model="dueDates"
                :options="translatedMonths"
                :disabled="isFirstAdjustment && !canBeEdited && !isNewItem"
                :check-valide="$v.dueDates.$dirty && $v.dueDates.$error ? 'is-invalid' : ''"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :preselect-first="false"
                :taggable="false"
                label="label"
                track-by="label">
                {{ $t('message.onBoarding.buildings.objects.tenancies.dueDates') }} {{ (readOnly ? '' : '*') }}
              </coozzy-multiselect>
            </div>
          </div>
          <div
            v-if="checkAdditionalValues('ADDITIONAL_RENT_COMPONENT_RENT')"
            class="row details-row-bg-1 mt-2">
            <div
              v-if="!readOnly || (readOnly && rent)"
              class="col-sm-12 col-md-12">
              <coozzy-form-checkbox
                v-model="objectRentedFromCondo"
                :initial="objectRentedFromCondo"
                :disabled="readOnly">
                {{ $t('message.onBoarding.buildings.objects.ownerAgreement.condoRented') }}
              </coozzy-form-checkbox>
            </div>
            <div
              v-if="objectRentedFromCondo && mode !== 'activate'"
              class="col-sm-12 col-md-3 mb-2">
              <coozzy-form-input-numeric
                v-model="rent"
                type="number"
                min="0"
                :is-decimal="true"
                :is-read-only="readOnly"
                :name="$t('message.propertiesView.rentComponents.additionalRentComponents.ADDITIONAL_RENT_COMPONENT_RENT')" />
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <hr>
            </div>
          </div>
        </div>
      </div>
    </b-collapse>
    <b-modal
      :id="'cost-adjustments-confirmationModal'"
      ref="cost-adjustments-confirmationModal"
      no-close-on-backdrop
      hide-footer
      :title="$t('message.onBoarding.deleteModals.titleCostAdjusment')">
      <template>
        <div class="col">
          <p>{{ $t('message.onBoarding.deleteModals.bodyCostAdjusment') }}</p>
        </div>
        <div class="col">
          <coozzy-button
            size="small"
            class="mb-0 border"
            design="transparent"
            @click="$bvModal.hide('cost-adjustments-confirmationModal')">
            {{ $t('message.generic.cancel') }}
          </coozzy-button>
          <coozzy-button
            size="small"
            class="float-right mb-0"
            design="green"
            @click="deleteAdjustmentClicked">
            {{ $t('message.generic.delete') }}
          </coozzy-button>
        </div>
      </template>
    </b-modal>
  </section>
</template>

<script>

import CoozzyFormInput from '../../../framework/components/form/input/CoozzyFormInput'
import CoozzyButton from '../../../framework/components/button/CoozzyButton'
import { onboarding } from '@/mixins/onboarding'
import { validation } from '@/mixins/validation'
import { minLength, required } from 'vuelidate/lib/validators'
import Vue from 'vue'
import CoozzyMultiselect from '@/framework/components/multiselect/CoozzyMultiselect'
import { routeChecks } from '@/mixins/routeChecks'
import { tables } from '@/mixins/tables'
import { dateUtils } from '@/mixins/dateUtils'
import CoozzyFormInputNumeric from '../../../framework/components/form/input/CoozzyFormInputNumeric'
import CoozzyFormCheckbox from '@/framework/components/form/checkbox/CoozzyFormCheckbox.vue'

function moreThanStarting(value) {
  if (!value) {
    return true
  }
  return new Date(this.$options.filters.objectToDateInput(value)) >= new Date(this.$options.filters.objectToDateInput(this.condominium.startingAt))
}

function moreThanDebitUntil(value) {
  if (!value) {
    return true
  }
  if (!this.isNewItem) {
    return true
  }
  if (!this.object.debitUntil) {
    return true
  }
  if (!this.startingAtChanged) {
    return true
  }
  return new Date(this.$options.filters.objectToDateInput(value)) >= new Date(this.$options.filters.objectToDateInput(this.object.debitUntil))
}

function notEqualToOtherAdjBydate(value) {
  let flag = true
  this.condominium.rent.adjustments.filter(adj => adj.internalId !== this.adjustment.internalId).forEach(adj => {
    const isEqual = new Date(this.$options.filters.objectToDateInput(value)).getTime() === new Date(this.$options.filters.objectToDateInput(adj.byDate)).getTime()
    if (isEqual) {
      flag = false
    }
  })
  return flag
}
export default {
  name: 'CondominiumAdjustment',
  components: {
    CoozzyFormCheckbox,
    CoozzyButton,
    CoozzyFormInput,
    CoozzyFormInputNumeric,
    CoozzyMultiselect
  },
  mixins: [routeChecks, onboarding, validation, tables, dateUtils],
  props: {
    index: {
      type: Number,
      required: true
    },
    adjustment: {
      type: Object,
      default: null
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    condominium: {
      type: Object,
      default: null
    },
    untilDate: {
      type: String,
      default: ''
    },
    object: {
      type: Object,
      default: null
    },
    mode: {
      type: String,
      default: 'full',
      validator: function (value) {
        return ['full', 'adding', 'activate'].indexOf(value) !== -1
      }
    }
  },
  data() {
    return {
      dates: {
        adjustmentsByDate: null
      },
      objectRentedFromCondo: false,
      adjIndexToDelete: null,
      condominiumActivated: false,
      availableTerminationMonths: ['all', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
      byDateInit: this.adjustment.byDate,
      startingAtChanged: false,
      initialByDate: null
    }
  },
  computed: {
    canBeEdited() {
      return this.object.debitUntil && this.byDateInit ? new Date(this.$options.filters.objectToDateInput(this.byDateInit)).getTime() / 1000 > new Date(this.$options.filters.objectToDateInput(this.object.debitUntil)).getTime() / 1000 : true
    },
    isNewItem() {
      return (this.adjustment && this.adjustment.newItem) || false
    },
    translatedMonths() {
      const array = []
      for (const month of this.availableTerminationMonths) {
        array.push({
          label: this.$t('message.generic.months.' + month),
          value: month
        })
      }
      return array
    },
    getDueDateMonths() {
      const array = []
      for (const month of this.adjustment.dueDates) {
        array.push(this.$t('message.generic.months.' + month))
      }
      return array.join(', ')
    },
    isFirstAdjustment() {
      return this.condominium.rent.adjustments.length <= 1 || (new Date(this.$options.filters.objectToDateInput(this.condominium.startingAt)).getTime() / 1000 === new Date(this.$options.filters.objectToDateInput(this.adjustment.byDate)).getTime() / 1000)
    },
    rent: {
      get() {
        let result = -1
        if (this.adjustment.additionalValues) {
          this.adjustment.additionalValues.forEach(element => {
            if (element.type.includes('ADDITIONAL_RENT_COMPONENT_RENT') && element.value > -1) {
              result = element.value
            }
          })
        }
        if (result === -1) {
          return null
        }
        return result
      },
      set(value) {
        const newAdjustment = this.adjustment
        if (!(newAdjustment.additionalValues && newAdjustment.additionalValues.some(x => x.type === 'ADDITIONAL_RENT_COMPONENT_RENT'))) {
          newAdjustment.additionalValues.push({ type: 'ADDITIONAL_RENT_COMPONENT_RENT', value: -1 })
        }
        if (value == null) {
          newAdjustment.additionalValues.find(x => x.type === 'ADDITIONAL_RENT_COMPONENT_RENT').value = -1
        } else {
          newAdjustment.additionalValues.find(x => x.type === 'ADDITIONAL_RENT_COMPONENT_RENT').value = parseFloat(value)
        }
        this.$emit('change-adjustment', newAdjustment)
      }
    },

    budgetExpectations: {
      get() {
        let result = -1
        if (this.adjustment.flatRateValues) {
          this.adjustment.flatRateValues.forEach(element => {
            if (element.type.includes('FLAT_RATE_RENT_COMPONENT_BUDGET_EXPECTATIONS') && element.value > -1) {
              result = element.value
            }
          })
        }
        if (result === -1) {
          return null
        }
        return result
      },
      set(value) {
        const newAdjustment = this.adjustment
        if (!newAdjustment.flatRateValues.some(x => x.type === 'FLAT_RATE_RENT_COMPONENT_BUDGET_EXPECTATIONS')) {
          newAdjustment.flatRateValues.push({ type: 'FLAT_RATE_RENT_COMPONENT_BUDGET_EXPECTATIONS', value: -1 })
        }
        if (value == null) {
          newAdjustment.flatRateValues.find(x => x.type === 'FLAT_RATE_RENT_COMPONENT_BUDGET_EXPECTATIONS').value = -1
        } else {
          newAdjustment.flatRateValues.find(x => x.type === 'FLAT_RATE_RENT_COMPONENT_BUDGET_EXPECTATIONS').value = parseFloat(value)
        }
        this.$emit('change-adjustment', newAdjustment)
      }
    },
    renovationFund: {
      get() {
        let result = -1
        if (this.adjustment.flatRateValues) {
          this.adjustment.flatRateValues.forEach(element => {
            if (element.type.includes('FLAT_RATE_RENT_COMPONENT_RENOVATION_FUND') && element.value > -1) {
              result = element.value
            }
          })
        }
        if (result === -1) {
          return null
        }
        return result
      },
      set(value) {
        const newAdjustment = this.adjustment
        if (!newAdjustment.flatRateValues.some(x => x.type === 'FLAT_RATE_RENT_COMPONENT_RENOVATION_FUND')) {
          newAdjustment.flatRateValues.push({ type: 'FLAT_RATE_RENT_COMPONENT_RENOVATION_FUND', value: -1 })
        }
        if (value == null) {
          newAdjustment.flatRateValues.find(x => x.type === 'FLAT_RATE_RENT_COMPONENT_RENOVATION_FUND').value = -1
        } else {
          newAdjustment.flatRateValues.find(x => x.type === 'FLAT_RATE_RENT_COMPONENT_RENOVATION_FUND').value = parseFloat(value)
        }
        this.$emit('change-adjustment', newAdjustment)
      }
    },
    dueDates: {
      get() {
        const tempMonths = this.adjustment.dueDates
        tempMonths.slice().sort(function (a, b) {
          return a - b
        })
        return tempMonths.map(month => {
          return {
            label: this.$t('message.generic.months.' + month),
            value: month
          }
        })
      },
      set(value) {
        const newAdjustment = this.adjustment
        for (const month of value) {
          if (month.value === 'all') {
            newAdjustment.dueDates = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
            this.$emit('change-adjustment', newAdjustment)
            return
          }
        }

        newAdjustment.dueDates = value.map(v => v.value)
        this.$emit('change-adjustment', newAdjustment)
      }
    },
    biggerThanDebitUntil() {
      return this.object.debitUntil && this.initialByDate && new Date(this.$options.filters.objectToDateInput(this.object.debitUntil)).getTime() / 1000 > new Date(this.$options.filters.objectToDateInput(this.initialByDate)).getTime() / 1000
    }
  },
  watch: {
    objectRentedFromCondo: function (newVal) {
      if (!newVal) {
        this.rent = null
      }
    },
    'condominium.startingAt': function (newVal, oldVal) {
      if (this.isFirstAdjustment && JSON.stringify(oldVal) !== JSON.stringify(newVal)) {
        const newAdjustment = this.adjustment
        newAdjustment.byDate = newVal
        this.$emit('change-adjustment', newAdjustment)
        this.dates.adjustmentsByDate = this.$options.filters.objectToDateInput(newVal)
      }
    },
    'adjustment.byDate': function (newVal, oldVal) {
      this.startingAtChanged = true
      if (JSON.stringify(oldVal) !== JSON.stringify(newVal)) {
        this.$emit('change-adjustment', this.adjustment)
      }
    }
  },
  mounted() {
    if (this.isFirstAdjustment) {
      this.adjustment.byDate = this.condominium.startingAt
    }
    if (this.adjustment.additionalValues) {
      this.adjustment.additionalValues.forEach(element => {
        if (element.type.includes('ADDITIONAL_RENT_COMPONENT_RENT') && element.value > 0) {
          this.objectRentedFromCondo = true
        }
      })
    }
    this.getDatesFromObjects()
    if (this.condominium.activated === true) {
      this.condominiumActivated = true
    }
    this.initialByDate = this.adjustment.byDate
  },
  methods: {
    checkAdditionalValues(value) {
      if (this.adjustment.additionalValues) {
        return this.adjustment.additionalValues.some(x => x.type === value)
      }
      return false
    },
    checkFlatRateValues(value) {
      if (this.adjustment.flatRateValues) {
        return this.adjustment.flatRateValues.some(x => x.type === value)
      }
      return false
    },
    dateToObject() {
      this.adjustment.byDate = this.toObject(this.dates.adjustmentsByDate)
    },
    getDatesFromObjects() {
      this.dates.adjustmentsByDate = this.$options.filters.objectToDateInput(this.adjustment.byDate)
    },
    isInvalid() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.scrollToError()
        if (this.$v.adjustment.byDate.$dirty && this.$v.adjustment.byDate.$error && !this.$v.adjustment.byDate.notEqualToOtherAdjBydate) {
          Vue.toasted.show(this.$t('message.savingErrors.notEqualToOtherAdjBydate'), { type: 'error' })
        }
        if (this.$v.adjustment.byDate.$dirty && this.$v.adjustment.byDate.$error && !this.$v.adjustment.byDate.moreThanStarting) {
          Vue.toasted.show(this.$t('message.savingErrors.costAdjustmentAfterContractStart'), { type: 'error' })
        }
        if (this.$v.adjustment.byDate.$dirty && this.$v.adjustment.byDate.$error && !this.$v.adjustment.byDate.moreThanDebitUntil) {
          Vue.toasted.show(this.$t('message.savingErrors.debitDateAndAdjustment', { debitUntil: this.$options.filters.objectToDate(this.object.debitUntil) }), { type: 'error' })
        }
      }
      return this.$v
    },
    openConfirmationModal() {
      this.$refs['cost-adjustments-confirmationModal'].show()
    },
    removeClicked() {
      this.$refs.deleteModal.show()
    },
    deleteAdjustmentClicked() {
      this.$emit('delete-adjustment', this.adjustment.internalId)
      this.$refs['cost-adjustments-confirmationModal'].hide()
    }
  },
  validations() {
    return {
      adjustment: {
        byDate: {
          required,
          moreThanStarting,
          moreThanDebitUntil,
          notEqualToOtherAdjBydate
        }
      },
      dueDates: {
        required,
        minLength: minLength(1)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
h5 > span {
  font-size: 18px !important;
}
:deep(.mx-datepicker) {
  width: 100%;
}
</style>
