<template>
  <section>
    <b-modal
      id="digital-deposit-modal"
      ref="digital-deposit-modal"
      size="xl"
      :title="$t('message.digitalDeposit.title')"
      hide-footer
      no-close-on-backdrop
      @hide="hide()">
      <h6>{{ $t('message.digitalDeposit.description') }}</h6>
      <div
        class="row">
        <!--    Tenants Data    -->
        <div class="col-12">
          <div class="row">
            <template
              v-for="(contact, index) in listContacts">
              <div
                :key="contact.id + '_' + index"
                class="col-4 col-md-3">
                <div>
                  <template v-if="checkValidContact(contact)">
                    <coozzy-success-icon />
                    {{ $t('message.digitalDeposit.informationComplete') }}
                  </template>
                  <template v-else>
                    <coozzy-fail-icon />
                    {{ $t('message.digitalDeposit.informationIncomplete') }}
                  </template>
                </div>
                <div class="row">
                  <router-link
                    class="col"
                    :to="{ name: moduleRoutePrefix + 'ContactDetailsView', params: { id: contact.id } }">
                    {{ contact.name }}
                  </router-link>
                  <coozzy-button
                    design="transparent"
                    class="p-0 mb-0 float-right ml-2"
                    @click="editContactClicked(contact)">
                    <coozzy-edit-icon />
                  </coozzy-button>
                </div>
                <address-text :address="contact.address" />
              </div>
            </template>
          </div>
        </div>
        <!--    Provider Data    -->
        <div class="col-12 mt-2">
          <div class="row">
            <div class="col-6">
              <label>
                {{ $t('message.digitalDeposit.chooseProvider') }}
              </label>
              <coozzy-form-select
                id="provider"
                v-model="provider"
                :state="$v.provider.$dirty && $v.provider.$error ? false : null">
                <option value="EVOREST">
                  {{ $t('message.digitalDeposit.providers.EVOREST') }}
                </option>
              </coozzy-form-select>
            </div>
            <div class="col-12">
              <p class="mt-2">
                {{ $t('message.digitalDeposit.descriptionProvider') }}
              </p>
            </div>
          </div>
        </div>
        <!--    Bank Data    -->
        <div class="col-12 mt-2">
          <div class="row">
            <div class="col-6">
              <label>
                {{ $t('message.onBoarding.buildings.objects.tenancies.deposit.bankName') }}
              </label>
              <coozzy-form-input
                ref="bankName"
                v-model="bankName"
                :disabled="true"
                :placeholder="$t('message.onBoarding.buildings.objects.tenancies.deposit.bankName')" />
            </div>
          </div>
        </div>
        <!--    Deposit Data    -->
        <div class="col-12 mt-2">
          <div class="row">
            <div class="col-6">
              <label>
                {{ $t('message.onBoarding.buildings.objects.tenancies.deposit.amount') + '*' }}
              </label>
              <coozzy-form-input-numeric
                v-model="depositAmount"
                :filter="'formatPrice'"
                type="number"
                :min="0"
                placeholder="CHF"
                :is-decimal="true"
                :check-valide="$v.depositAmount.$dirty && $v.depositAmount.$error ? 'is-invalid' : ''" />
            </div>
            <div class="col-6">
              <label>
                {{ $t('message.onBoarding.buildings.objects.tenancies.deposit.dueDate') + '*' }}
              </label>
              <coozzy-form-input
                id="dueDate"
                v-model="dates.depositDueDate"
                :filter="'formatDate'"
                type="date"
                :state="($v.depositDueDate.$dirty && $v.depositDueDate.$error) ? false : null"
                @blur="dateToObject()" />
            </div>
          </div>
        </div>
      </div>
      <h5 class="mt-3">
        {{ $t('message.digitalDeposit.investment') }}
      </h5>
      <h6>{{ $t('message.digitalDeposit.descriptionInvestment') }}</h6>
      <div class="row mt-2">
        <div class="col-3">
          <coozzy-form-radio
            v-model="allowInvestment"
            :default-model="allowInvestment"
            :select-value="true"
            class=""
            :disabled="ownerAllowInvestment === 'DOES_NOT_APPLY'"
            name="allowInvestment">
            {{ $t('message.digitalDeposit.options.allowInvestment') }}
          </coozzy-form-radio>
        </div>
        <div class="col-3">
          <coozzy-form-radio
            v-model="allowInvestment"
            :default-model="allowInvestment"
            :select-value="false"
            class=""
            name="allowInvestment">
            {{ $t('message.digitalDeposit.options.notAllowInvestment') }}
          </coozzy-form-radio>
        </div>
      </div>
      <div class="col mt-3 p-0">
        <coozzy-button
          size="small"
          class="border mb-0"
          design="transparent"
          @click="hide">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
        <coozzy-button
          size="small"
          class="float-right mb-0"
          design="green"
          @click="saveDeposit">
          {{ $t('message.generic.form.send') }}
        </coozzy-button>
      </div>
    </b-modal>
    <create-contact-modal
      v-if="contactToEdit"
      :key="contactToEdit ? contactToEdit.id : null"
      ref="create-contact-modal"
      :show-search="false"
      :show-request-sources="false"
      :set-interested-party-role="false"
      :show-add-another="true"
      :from-digital-deposit="true"
      :contact-to-edit="contactToEdit"
      @contact-updated="contactUpdated" />
  </section>
</template>

<script lang="ts">

import Vue, { PropType } from 'vue'
import CoozzySuccessIcon from '@/framework/components/icons/CoozzySuccessIcon.vue'
import CoozzyFailIcon from '@/framework/components/icons/CoozzyFailIcon.vue'
import { routeChecks } from '@/mixins/routeChecks'
import CoozzyButton from '@/framework/components/button/CoozzyButton.vue'
import CoozzyEditIcon from '@/framework/components/icons/CoozzyEditIcon.vue'
import AddressText from '@/framework/components/misc/AddressText.vue'
import CreateContactModal from '@/contact/components/CreateContactModal.vue'
import {
  ApplicableApplicable,
  ObjectObject,
  ObjecttenancyTenancy,
  TypeDate,
  V2Person
} from '@/types/api'
import CoozzyFormSelect from '@/framework/components/form/select/CoozzyFormSelect.vue'
import { minValue, required } from 'vuelidate/lib/validators'
import CoozzyFormInput from '@/framework/components/form/input/CoozzyFormInput.vue'
import CoozzyFormInputNumeric from '@/framework/components/form/input/CoozzyFormInputNumeric.vue'
import { dateUtils } from '@/mixins/dateUtils'
import CoozzyFormRadio from '@/framework/components/form/radio/CoozzyFormRadio.vue'
import EvorestApi from '@/misc/apis/EvorestApi'
import ObjectApi from '@/misc/apis/ObjectApi'

export default Vue.extend({
  name: 'DigitalDepositModal',
  components: {
    CoozzyFormRadio,
    CoozzyFormInputNumeric,
    CoozzyFormInput,
    CoozzyFormSelect,
    CreateContactModal,
    AddressText,
    CoozzyEditIcon,
CoozzyButton,
    CoozzyFailIcon,
    CoozzySuccessIcon
  },
  mixins: [routeChecks, dateUtils],
  props: {
    tenancy: {
      type: Object as PropType<ObjecttenancyTenancy>,
      required: true
    },
    object: {
      type: Object as PropType<ObjectObject>,
      required: true
    },
    contacts: {
      type: Array as PropType<V2Person[]>,
      required: true
    },
    accountId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: false as boolean,
      listContacts: [] as V2Person[],
      contactToEdit: null as V2Person,
      provider: 'EVOREST' as string,
      bankName: 'Hypothekarbank Lenzburg AG' as string,
      depositAmount: null as number,
      dates: {
        depositDueDate: '' as string
      },
      depositDueDate: null as TypeDate,
      allowInvestment: true as boolean,
      ownerAllowInvestment: 'UNDEFINED_VALUE' as ApplicableApplicable
    }
  },
  watch: {
    contacts: {
      handler() {
        this.listContacts = this.contacts
      },
      deep: true
    }
  },
  mounted() {
    this.listContacts = this.contacts
    this.getEvorestSettings()
    if (this.tenancy?.deposit) {
      this.depositAmount = this.tenancy.deposit.amount !== -1 ? this.tenancy.deposit.amount : null
      this.depositDueDate = this.tenancy.deposit.dueDate
      this.dates.depositDueDate = this.$options.filters.objectToDateInput(this.tenancy.deposit.dueDate)
    }
  },
  methods: {
    getEvorestSettings() {
      EvorestApi.getOwnerConfig(this.object.ownerId).then((response) => {
        this.ownerAllowInvestment = response.allowInvestment
        if (this.ownerAllowInvestment === 'DOES_NOT_APPLY') {
          this.allowInvestment = false
        }
      })
    },
    hide() {
      this.$refs['digital-deposit-modal']?.hide()
      this.$emit('hide')
    },
    show() {
      this.$refs['digital-deposit-modal']?.show()
    },
    checkValidContact(contact: V2Person) {
      const requiredFields = [
        'firstName',
        'lastName',
        'email',
        'nationality',
        'dateOfBirth'
      ]

      const areRequiredFieldsFilled = requiredFields.every(field => contact[field] !== '' && contact[field] !== null)
      const isGenderFilled = contact.gender !== 'UNDEFINED_GENDER'
      const isCivilStatusFilled = contact.civilStatus !== 'UNDEFINED_CIVIL_STATUS'
      const isAtLeastOnePhoneFilled = contact.phoneNumbers.length > 0

      return areRequiredFieldsFilled && isAtLeastOnePhoneFilled && isGenderFilled && isCivilStatusFilled
    },
    editContactClicked(contact: V2Person) {
      this.contactToEdit = contact
      this.$nextTick(function () {
        this.$refs['create-contact-modal'].show()
      })
    },
    contactUpdated(data: any) {
      this.$emit('contact-updated', data)
    },
    dateToObject() {
      this.depositDueDate = this.toObject(this.dates.depositDueDate)
    },
    saveDeposit() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        if (this.$v.listContacts.$dirty && this.$v.listContacts.$error) {
          Vue.toasted.show(this.$t('message.savingErrors.digitalDepositValidContacts'), { type: 'error' })
        }
        return
      }
      const bankAddress = { country: 'CH', state: '', city: 'Lenzburg 1', zip: '5600', street: 'Bahnhofstrasse 2', streetNumber: '', streetAddition: '', subUnit: '', careOf: '', geo: null, mailbox: '' }
      const tenancy = this.tenancy
      tenancy.deposit.bankName = 'Hypothekarbank Lenzburg AG'
      tenancy.deposit.bic = 'HYPLCH22XXX'
      tenancy.deposit.clearingNumber = 8307
      tenancy.deposit.type = 'DEPOSIT_TYPE_DEPOT'
      tenancy.deposit.address = bankAddress
      tenancy.deposit.amount = this.depositAmount
      tenancy.deposit.dueDate = this.depositDueDate
      ObjectApi.updateTenancy(tenancy)
        .then(() => {
          this.$store.dispatch('onboarding/updateSavedTenancy', tenancy)
          EvorestApi.openDeposit(this.object.id, this.tenancy.id, this.allowInvestment).then(() => {
            this.hide()
            Vue.toasted.show(this.$t('message.successMessages.digitalDepositCreation'), { type: 'success' })
          }).catch((error) => {
            this.hide()
            const messageError = error?.response?.data?.message || this.$t('message.savingErrors.onboarding')
            Vue.toasted.show(messageError, { type: 'error' })
          })
        })
    }
  },
  validations() {
    return {
      listContacts: {
        required: (contacts) => contacts.every((contact: V2Person) => this.checkValidContact(contact))
      },
      provider: {
        required
      },
      depositAmount: {
        required,
        minValue: minValue(1)
      },
      depositDueDate: {
        required
      },
      allowInvestment: {
        required
      }
    }
  }
})
</script>

<style lang="scss" scoped>
:deep(#digital-deposit-modal .modal-xl) {
  max-width: 50% !important;
}
</style>
