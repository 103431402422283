<template>
  <svg
    :id="name"
    width="20"
    height="20"
    viewBox="0 0 50 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :class="active ? 'white-icon' : 'dark-icon'">
    <path
      class="fill1"
      d="M 4.7415358,13.803662 0,19.253034 10.07575,18.659528 11.422772,13.641817 h 3.125132 c 0,0 2.29519,-4.9679618 3.178978,-6.4745113 0.883753,-1.506516 1.34702,-1.240938 1.34702,-1.240938 0.107747,-0.7013914 0.58192,-2.1797462 1.616439,-2.4818903 1.034512,-0.3021443 7.722924,3.650892 10.937859,5.6651864 L 24.67754,19.306994 c 2.424621,-1.51073 6.939854,-2.492682 7.327826,-2.751668 0.484908,-0.323714 0.01655,-2.21803 0.431054,-2.751664 0.377161,-0.485592 2.801786,-0.431632 3.771641,-0.97115 0.444371,-0.247195 1.023812,-6.9601037 2.155306,-7.0140631 0.353038,-0.016839 1.48521,1.6526944 5.172581,4.8019461 3.059788,2.613373 8.533089,6.555962 10.830049,8.57868 L 46.822715,8.516158 44.936895,11.052027 39.225408,2.7970262 32.274747,7.2212605 25.324087,2.149575 23.438266,3.4444774 20.259288,1.5021287 17.726882,5.3328569 14.547904,4.0379738 6.9506624,14.181369 Z" />
  </svg>
</template>

<script>

export default {
  name: 'CoozzyEvorestIcon',
  props: {
    name: {
      type: String,
      required: true
    },
    active: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.dark-icon .fill1 {
  fill: #000
}
.white-icon {
  fill: #fff
}
</style>
