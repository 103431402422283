<template>
  <section>
    <div
      v-if="mainReferences.length > 0"
      class="row mb-2">
      <div class="col-12 col-md-2 offset-10">
        <coozzy-button
          design="prop-green"
          class="w-100 sort"
          @click="$emit('create-ticket')">
          {{ $t('message.ticketOverview.createTicket') }}
        </coozzy-button>
      </div>
    </div>
    <div class="row align-items-center">
      <div class="col-12 col-md-2 mb-2">
        <coozzy-form-input
          ref="searchText"
          v-model="searchText"
          :placeholder="$t('message.generic.search')"
          @keyup="searchInputText()" />
      </div>
      <div class="col-12 col-md-2 mb-2 pr-md-0">
        <div class="row">
          <div class="col-6 pl-0">
            <coozzy-multiselect
              v-model="searchPriority"
              class="filter-inputs"
              :options="availablePriorityOptions"
              :multiple="true"
              :placeholder="$tc('message.ticketOverview.ticket.priority.title')"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              :preselect-first="false"
              :taggable="false"
              :custom-label="customLabelPriority" />
          </div>
          <div class="col-6">
            <coozzy-multiselect
              v-model="searchType"
              class="filter-inputs"
              :options="availableTypeOptions"
              :multiple="true"
              :costum-class="'pl-0'"
              :placeholder="$tc('message.ticketOverview.ticket.type.title')"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              :preselect-first="false"
              :custom-label="customLabelType"
              :taggable="false" />
          </div>
        </div>
      </div>
      <div
        v-if="isFromProperty && fromHistory"
        class="col-12 col-md-2 mb-2 pr-md-0"
        :class="!closedTicket ? 'pr-md-0' : ''">
        <!--     Building Filter     -->
        <coozzy-multiselect
          v-model="searchBuilding"
          class="filter-inputs"
          :options="filtredBuildingOptions"
          :multiple="true"
          :placeholder="$tc('message.ticketOverview.filters.building')"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          :preselect-first="false"
          :taggable="false"
          :track-by="'id'"
          :label="'name'" />
      </div>
      <div
        v-if="mainReferences.length"
        class="col-12 col-md-2 mb-2"
        :class="!closedTicket ? 'pr-md-0' : ''">
        <!--     Object Filter     -->
        <coozzy-multiselect
          v-model="searchObject"
          class="filter-inputs"
          :options="filtredObjectOptions"
          :multiple="true"
          :placeholder="$tc('message.ticketOverview.filters.object')"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          :preselect-first="false"
          :taggable="false"
          :track-by="'id'"
          :label="'name'" />
      </div>
      <div
        v-if="deviceList && mainReferences.length > 0"
        class="col-12 col-md-2 mb-2 pr-md-0">
        <coozzy-multiselect
          v-model="searchDevice"
          class="filter-inputs"
          :options="deviceList"
          :multiple="true"
          :placeholder="$tc('message.onBoarding.devices.type')"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          :track-by="'value'"
          :preselect-first="false"
          :taggable="false"
          :custom-label="customLabelDevice" />
      </div>
      <div
        v-if="mainReferences.length > 0"
        class="col-12 col-md-2 mb-2"
        :class="!isFromProperty ? 'pr-md-0' : ''">
        <coozzy-multiselect
          v-model="searchStatus"
          class="filter-inputs"
          :options="availableStatusOptions"
          :multiple="true"
          :placeholder="$tc('message.ticketOverview.status.title')"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          :preselect-first="false"
          :custom-label="customLabelStatus"
          :taggable="false" />
      </div>
      <div
        class="col-12 col-md-2 mb-2"
        :class="!fromHistory ? 'pr-md-0' : ''">
        <coozzy-assignee-multiselect
          v-model="searchAssignee"
          class="filter-inputs"
          :options="employeeFilteredList"
          :multiple="true"
          :placeholder="$tc('message.tableColumnHeadings.assignee')"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          :include-unassigned="true"
          :custom-label="customLabelEmployee"
          :html-label="customLabelEmployee"
          :track-by="'value'"
          :preselect-first="false"
          :taggable="false" />
      </div>
      <div
        v-if="mainReferences.length === 0 && !closedTicket"
        class="col-12 col-md-4">
        <div class="row">
          <div
            v-if="mainReferences.length === 0"
            class="col pr-md-0"
            :class="!closedTicket ? 'mb-2' : ''">
            <coozzy-form-checkbox
              v-if="!closedTicket"
              v-model="searchDueDate"
              :initial="searchDueDate"
              :class="'p-0 w-100 as-button'"
              name="searchDueDate"
              button>
              {{ $t('message.ticketOverview.ticket.overdueTicket') }}
            </coozzy-form-checkbox>
          </div>
          <div
            v-if="mainReferences.length === 0"
            class="col pr-md-0"
            :class="!closedTicket ? 'mb-2' : ''">
            <coozzy-form-checkbox
              v-if="!closedTicket"
              v-model="searchWatcher"
              :initial="searchWatcher"
              :class="'p-0 w-100 as-button'"
              name="searchWatcher"
              button>
              {{ $t('message.ticketOverview.ticket.watchedByMe') }}
            </coozzy-form-checkbox>
          </div>
          <div
            v-if="mainReferences.length === 0"
            class="col pr-md-0"
            :class="!closedTicket ? 'mb-2' : ''">
            <coozzy-form-checkbox
              v-if="!closedTicket"
              v-model="searchNewEvent"
              :initial="searchNewEvent"
              :class="'p-0 w-100 as-button'"
              name="searchNewEvent"
              button>
              {{ $t('message.ticketOverview.ticket.newEvent') }}
            </coozzy-form-checkbox>
          </div>
        </div>
      </div>
      <div
        v-if="mainReferences.length === 0"
        class="col-12 col-md-2 mb-2"
        :class="closedTicket ? 'pr-md-0' : ''">
        <!--     Owner Filter     -->
        <coozzy-multiselect
          v-model="searchOwner"
          class="filter-inputs"
          :options="availableOwnerOptions"
          :multiple="true"
          :placeholder="$tc('message.ticketOverview.filters.owner')"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          :preselect-first="false"
          :taggable="false"
          :track-by="'value'"
          :custom-label="customLabelEstate" />
      </div>
      <div
        v-if="mainReferences.length === 0"
        class="col-12 col-md-2 mb-2">
        <!--     Property Filter     -->
        <coozzy-multiselect
          v-model="searchProperty"
          class="filter-inputs"
          :options="filtredPropertyOptions"
          :multiple="true"
          :placeholder="$t('message.ticketOverview.filters.property')"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          :preselect-first="false"
          :track-by="'id'"
          :taggable="false"
          :label="'name'" />
      </div>
      <div
        v-if="mainReferences.length === 0"
        class="col-12 col-md-2 mb-2 pl-md-0"
        :class="!closedTicket ? 'pr-md-0' : ''">
        <!--     Building Filter     -->
        <coozzy-multiselect
          v-model="searchBuilding"
          class="filter-inputs"
          :options="filtredBuildingOptions"
          :multiple="true"
          :placeholder="$tc('message.ticketOverview.filters.building')"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          :preselect-first="false"
          :taggable="false"
          :track-by="'id'"
          :label="'name'" />
      </div>
      <div
        v-if="mainReferences.length === 0"
        class="col-12 col-md-2 mb-2"
        :class="!closedTicket ? 'pr-md-0' : ''">
        <!--     Object Filter     -->
        <coozzy-multiselect
          v-model="searchObject"
          class="filter-inputs"
          :options="filtredObjectOptions"
          :multiple="true"
          :placeholder="$tc('message.ticketOverview.filters.object')"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          :preselect-first="false"
          :taggable="false"
          :track-by="'id'"
          :label="'name'" />
      </div>
      <div
        v-if="mainReferences.length === 0"
        class="col-12 col-md-2 mb-2"
        :class="closedTicket ? 'pr-md-0 pl-md-0' : 'pr-md-0'">
        <!--     Operator Filter     -->
        <ais-instant-search
          v-if="searchClient"
          :search-client="searchClient"
          :routing="routing"
          index-name="contact">
          <!-- eslint-disable vue/attribute-hyphenation -->
          <ais-configure
            :hitsPerPage="10"
            :filters="filterQueryOperator" />
          <!-- eslint-disable vue/attribute-hyphenation -->
          <ais-autocomplete>
            <template slot-scope="{ indices, refine }">
              <coozzy-search-multiselect
                v-model="involvedPeople"
                :options="indices[0].hits.filter(x => x && x.objectID)"
                :multiple="true"
                :placeholder="$tc('message.ticketOverview.filters.involvedPeople')"
                :close-on-select="true"
                :clear-on-select="false"
                :preserve-search="true"
                :preselect-first="false"
                :taggable="false"
                track-by="objectID"
                label="name"
                :custom-label="customLabelOperator"
                @search-change="see(refine, $event)" />
            </template>
          </ais-autocomplete>
        </ais-instant-search>
      </div>
      <div
        class="col-12 col-md-2 mb-2"
        :class="!fromHistory ? 'pr-md-0' : isFromProperty ? 'p-0' : ''">
        <coozzy-multiselect-html-option
          v-model="searchCreator"
          class="filter-inputs"
          :options="employeeFilteredListWithSystem"
          :multiple="true"
          :placeholder="$tc('message.ticketOverview.ticket.creator')"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          :custom-label="customLabelEmployee"
          :html-label="customLabelEmployee"
          :track-by="'value'"
          :preselect-first="false"
          :taggable="false" />
      </div>
      <!--      <div class="col-12 col-md-2" />-->
      <div
        v-if="mainReferences.length !== 0"
        :class="!isColumnView ? '' : 'offset-md-6'"
        class="col-12 col-md-2 pr-md-0">
        <coozzy-form-checkbox
          v-if="mainReferences.length > 0 && mainReferences[0].startsWith('prop')"
          v-model="showEntierProperty"
          :initial="showEntierProperty"
          :class="'w-100'"
          name="searchDueDate">
          {{ $t('message.ticketOverview.ticket.showEntierProperty') }}
        </coozzy-form-checkbox>
      </div>
    </div>
    <div
      v-if="loading"
      class="row">
      <div class="col text-center">
        <coozzy-spinner class="m-3" />
      </div>
    </div>
    <div v-else>
      <div v-if="isColumnView">
        <!-- Ticket columns -->
        <div
          id="parent"
          class="row mt-1">
          <!-- Open columns -->
          <div class="col-12 col-md-4">
            <h4 class="column-heading rounded p-2">
              {{ $t('message.ticketOverview.status.TICKET_STATUS_OPEN') }} ({{ sortedOpenTicket.length }})
            </h4>
            <div class="column-container rounded">
              <h6
                v-if="!dragging && sortedOpenTicket.length === 0"
                class="text-center text-grey p-3">
                {{ $t('message.ticketOverview.noTickets') }}
              </h6>

              <draggable
                id="status-open"
                v-model="sortedOpenTicket"
                :options="isTicketingEnabled && (isEditor || isTicketEditor) ? {} : {disabled: true}"
                :class="['draggable-container', sortedOpenTicket.length > 0 ? 'p-1' : '', dragging ? 'dragging-border' : '']"
                group="ticket"
                @start="startMoving"
                @end="endMoving">
                <ticket-card
                  v-for="element in sortedOpenTicket"
                  :key="element.id"
                  :ticket="element"
                  :mail="isTicketReferenceInMail(element)"
                  @click="openDetail"
                  @click-middle="openMiddleDetail">
                  <!-- slot of connection -->
                  <div class="col-12 d-flex">
                    <div class="mr-1">
                      {{ $t('message.ticketOverview.ticket.connections') }}:
                    </div>
                    <span v-if="element.references.length === 0"> - </span>
                    <div>
                      <template v-for="ref in element.references">
                        <p
                          v-if="getConnection(ref.id) !== undefined"
                          :key="ref.id"
                          class="mb-0">
                          <span
                            v-if="ref.id.startsWith('ins') && computedAllConnections.insurances"
                            class="external-link">
                            <span
                              v-if="findConnectionId(ref, 'prop') && computedAllConnections.properties">
                              <router-link
                                v-if="!isMarketingModule"
                                class="external-link"
                                :to="getConnection(findConnectionId(ref, 'prop')) !== undefined ? redirectToDetailPage(getConnection(findConnectionId(ref, 'prop')), 'prop') : ''">
                                {{ getConnection(findConnectionId(ref, 'prop'))?.name || '' }} &nbsp;
                              </router-link>
                              <span v-else>
                                {{ getConnection(findConnectionId(ref, 'prop'))?.name || '' }}
                              </span>
                            </span>
                            <span
                              v-if="findConnectionId(ref, 'building') && computedAllConnections.buildings">
                              >
                              <router-link
                                v-if="!isMarketingModule"
                                class="external-link"
                                :to="getConnection(findConnectionId(ref, 'building')) !== undefined ? redirectToDetailPage(getConnection(findConnectionId(ref, 'building')), 'building') : ''">
                                {{ getConnection(findConnectionId(ref, 'building'))?.name || '' }} &nbsp;
                              </router-link>
                              <span v-else>
                                {{ getConnection(findConnectionId(ref, 'building'))?.name || '' }}
                              </span>
                            </span>
                            <span
                              v-if="findConnectionId(ref, 'obj') && computedAllConnections.objects">
                              >
                              <router-link
                                v-if="!isMarketingModule"
                                class="external-link"
                                :to="getConnection(findConnectionId(ref, 'obj')) !== undefined ? redirectToDetailPage(getConnection(findConnectionId(ref, 'obj')), 'obj') : ''">
                                {{ getConnection(findConnectionId(ref, 'obj'))?.name ||
                                  getConnection(findConnectionId(ref, 'obj'))?.number ||
                                  getConnection(findConnectionId(ref, 'obj'))?.numericId ||
                                  '' }}
                              </router-link>
                              <span v-else>
                                {{ getConnection(findConnectionId(ref, 'obj'))?.name ||
                                  getConnection(findConnectionId(ref, 'obj'))?.number ||
                                  getConnection(findConnectionId(ref, 'obj'))?.numericId ||
                                  '' }}
                              </span>
                            </span>
                            <span
                              v-if="!isMarketingModule"
                              class="external-link link"
                              @click="setInsuranceId(getConnection(ref.id), false)"
                              @click.middle="setInsuranceId(getConnection(ref.id), true)">
                              <template v-if="getConnection(ref.id)">
                                {{ $t('message.onBoarding.buildings.insurances.type.' + getConnection(ref.id).type) }}
                                <template v-if="getReferenceName(ref.id) !== ''">
                                  {{ ' - ' + getReferenceName(ref.id) }}
                                </template>
                              </template>
                              <template v-else>
                                -
                              </template> &nbsp;
                            </span>
                            <span v-else>
                              {{ $t('message.onBoarding.buildings.insurances.type.' + getConnection(ref.id).type) }}
                            </span>
                          </span>
                          <span
                            v-if="ref.id.startsWith('mortgage') && computedAllConnections.mortgages"
                            class="external-link">
                            <span
                              v-if="!isMarketingModule"
                              class="external-link link"
                              @click="setMortgageId(getConnection(ref.id), false)"
                              @click.middle="setMortgageId(getConnection(ref.id), true)">
                              <template v-if="getConnection(ref.id)">
                                {{ getConnection(ref.id).bankName + ' - ' +
                                  $t('message.onBoarding.mortgage.mortgageDesignation.' +
                                    getConnection(ref.id).mortgageDesignation) }}
                                <template v-if="getReferenceName(ref.id) !== ''">
                                  {{ ' - ' + getReferenceName(ref.id) }}
                                </template>
                              </template>
                              <template v-else>
                                -
                              </template> &nbsp;
                            </span>
                            <span v-else>
                              {{ getConnection(ref.id).bankName + ' - ' +
                                $t('message.onBoarding.mortgage.mortgageDesignation.' +
                                  getConnection(ref.id).mortgageDesignation) }}
                            </span>
                          </span>
                          <span v-if="ref.id.startsWith('device') && computedAllConnections.devices">
                            <span
                              v-if="findConnectionId(ref, 'prop') && computedAllConnections.properties">
                              <router-link
                                v-if="!isMarketingModule"
                                class="external-link"
                                :to="getConnection(findConnectionId(ref, 'prop')) !== undefined ? redirectToDetailPage(getConnection(findConnectionId(ref, 'prop')), 'prop') : ''">
                                {{ getConnection(findConnectionId(ref, 'prop'))?.name || '' }} &nbsp;
                              </router-link>
                              <span v-else>
                                {{ getConnection(findConnectionId(ref, 'prop'))?.name || '' }}
                              </span>
                              >
                            </span>
                            <span
                              v-if="findConnectionId(ref, 'building') && computedAllConnections.buildings">
                              <router-link
                                v-if="!isMarketingModule"
                                class="external-link"
                                :to="getConnection(findConnectionId(ref, 'building')) !== undefined ? redirectToDetailPage(getConnection(findConnectionId(ref, 'building')), 'building') : ''">
                                {{ getConnection(findConnectionId(ref, 'building'))?.name || '' }} &nbsp;
                              </router-link>
                              <span v-else>
                                {{ getConnection(findConnectionId(ref, 'building'))?.name || '' }}
                              </span>
                              >
                            </span>
                            <span
                              v-if="findConnectionId(ref, 'obj') && computedAllConnections.objects">
                              <router-link
                                v-if="!isMarketingModule"
                                class="external-link"
                                :to="getConnection(findConnectionId(ref, 'obj')) !== undefined ? redirectToDetailPage(getConnection(findConnectionId(ref, 'obj')), 'obj') : ''">
                                {{ getConnection(findConnectionId(ref, 'obj'))?.name ||
                                  getConnection(findConnectionId(ref, 'obj'))?.number ||
                                  getConnection(findConnectionId(ref, 'obj'))?.numericId ||
                                  '' }}
                              </router-link>
                              <span v-else>
                                {{ getConnection(findConnectionId(ref, 'obj'))?.name ||
                                  getConnection(findConnectionId(ref, 'obj'))?.number ||
                                  getConnection(findConnectionId(ref, 'obj'))?.numericId ||
                                  '' }}
                              </span>
                              >
                            </span>
                            <router-link
                              v-if="!isMarketingModule"
                              class="external-link"
                              :to="getConnection(ref.id) !== undefined ? redirectToDeviceDetail(getConnection(ref.id)) : ''">
                              <template
                                v-if="getConnection(ref.id).type && getConnection(ref.id).type !== 'DEVICE_TYPE_UNDEFINED'">
                                {{ $t('message.onBoarding.devices.typeValues.' + getConnection(ref.id).type).length > 50
                                  ? $t('message.onBoarding.devices.typeValues.' + getConnection(ref.id).type).slice(0,50)+
                                    '...' : $t('message.onBoarding.devices.typeValues.' + getConnection(ref.id).type) }}
                              </template>
                              <template v-else>
                                -
                              </template> &nbsp;
                            </router-link>
                            <span v-else>
                              <template
                                v-if="getConnection(ref.id).type && getConnection(ref.id).type !== 'DEVICE_TYPE_UNDEFINED'">
                                {{ $t('message.onBoarding.devices.typeValues.' + getConnection(ref.id).type).length > 50
                                  ? $t('message.onBoarding.devices.typeValues.' + getConnection(ref.id).type).slice(0,50)+
                                    '...' : $t('message.onBoarding.devices.typeValues.' + getConnection(ref.id).type) }}
                              </template>
                              <template v-else>
                                -
                              </template>
                            </span>
                          </span>
                          <span v-if="ref.id.startsWith('obj') && computedAllConnections.objects">
                            <span
                              v-if="findConnectionId(ref, 'prop') && computedAllConnections.properties">
                              <router-link
                                v-if="!isMarketingModule"
                                class="external-link"
                                :to="getConnection(findConnectionId(ref, 'prop')) !== undefined ? redirectToDetailPage(getConnection(findConnectionId(ref, 'prop')), 'prop') : ''">
                                {{ getConnection(findConnectionId(ref, 'prop'))?.name || '' }} &nbsp;
                              </router-link>
                              <span v-else>
                                {{ getConnection(findConnectionId(ref, 'prop'))?.name || '' }}
                              </span>
                              >
                            </span>
                            <span
                              v-if="findConnectionId(ref, 'building') && computedAllConnections.buildings">
                              <router-link
                                v-if="!isMarketingModule"
                                class="external-link"
                                :to="getConnection(findConnectionId(ref, 'building')) !== undefined ? redirectToDetailPage(getConnection(findConnectionId(ref, 'building')), 'building') : ''">
                                {{ getConnection(findConnectionId(ref, 'building'))?.name || '' }} &nbsp;
                              </router-link>
                              <span v-else>
                                {{ getConnection(findConnectionId(ref, 'building'))?.name || '' }}
                              </span>
                              >
                            </span>
                            <router-link
                              v-if="!isMarketingModule"
                              class="external-link"
                              :to="getConnection(ref.id) !== undefined ? redirectToDetailPage(getConnection(ref.id), 'obj') : ''">
                              {{ getConnection(ref.id)?.name ||
                                getConnection(ref.id)?.number ||
                                getConnection(ref.id)?.numericId ||
                                '' }} &nbsp;
                            </router-link>
                            <span v-else>
                              {{ getConnection(ref.id)?.name ||
                                getConnection(ref.id)?.number ||
                                getConnection(ref.id)?.numericId ||
                                '' }}
                            </span>
                          </span>
                          <span v-if="ref.id.startsWith('building') && computedAllConnections.buildings">
                            <span
                              v-if="findConnectionId(ref, 'prop') && computedAllConnections.properties">
                              <router-link
                                v-if="!isMarketingModule"
                                class="external-link"
                                :to="getConnection(findConnectionId(ref, 'prop')) !== undefined ? redirectToDetailPage(getConnection(findConnectionId(ref, 'prop')), 'prop') : ''">
                                {{ getConnection(findConnectionId(ref, 'prop'))?.name || '' }} &nbsp;
                              </router-link>
                              <span v-else>
                                {{ getConnection(findConnectionId(ref, 'prop'))?.name || '' }}
                              </span>
                              >
                            </span>
                            <router-link
                              v-if="!isMarketingModule"
                              class="external-link"
                              :to="getConnection(ref.id) !== undefined ? redirectToDetailPage(getConnection(ref.id), 'building') : ''">
                              {{ getConnection(ref.id)?.name || '' }} &nbsp;
                            </router-link>
                            <span v-else>
                              {{ getConnection(ref.id)?.name || '' }}
                            </span>
                          </span>
                          <span v-if="ref.id.startsWith('prop') && computedAllConnections.properties">
                            <router-link
                              v-if="!isMarketingModule"
                              class="external-link"
                              :to="getConnection(ref.id) !== undefined ? redirectToDetailPage(getConnection(ref.id), 'prop') : ''">
                              {{ getConnection(ref.id)?.name || '' }} &nbsp;
                            </router-link>
                            <span v-else>
                              {{ getConnection(ref.id)?.name || '' }}
                            </span>
                          </span>
                          <span v-if="ref.id.startsWith('contact') && computedAllConnections.contacts">
                            <router-link
                              v-if="!isMarketingModule"
                              class="external-link"
                              :to="getConnection(ref.id) !== undefined ? redirectToDetailPage(getConnection(ref.id), 'contact') : ''">
                              {{ getConnection(ref.id)?.name || '' }} &nbsp;
                            </router-link>
                            <span v-else>
                              {{ getConnection(ref.id)?.name || '' }}
                            </span>
                          </span>
                          <span v-if="ref.id.startsWith('request')">
                            <router-link
                              v-if="getConnection(ref.id).requesters.length > 0"
                              class="external-link"
                              :to="redirectToRequestDetail(getConnection(ref.id).objectId, ref.id)">
                              {{ getContactName(getConnection(ref.id).requesters[0].contactId) }}
                            </router-link>
                          </span>
                          <span v-if="ref.id.startsWith('tenancy')">
                            <router-link
                              v-if="!isMarketingModule"
                              class="external-link"
                              :to="getConnection(ref.id) !== undefined ? redirectToDetail(getConnection(ref.id), ref.id, 'tenants') : ''">
                              <template>
                                <span
                                  v-for="(contact, j) in getConnection(ref.id).tenant.contactIds"
                                  :key="j">
                                  {{ getContactName(contact) }} &nbsp;
                                </span>
                              </template>
                            </router-link>
                            <span v-else>
                              <template>
                                <span
                                  v-for="(contact, j) in getConnection(ref.id).tenant.contactIds"
                                  :key="j">
                                  {{ getContactName(contact) }}
                                </span>
                              </template>
                            </span>
                          </span>
                          <span v-if="ref.id.startsWith('condo')">
                            <router-link
                              v-if="!isMarketingModule"
                              class="external-link"
                              :to="getConnection(ref.id) !== undefined ? redirectToDetail(getConnection(ref.id), ref.id, 'condominium') : ''">
                              <span
                                v-for="(contact, j) in getConnection(ref.id).owner.contactIds"
                                :key="j">
                                {{ getContactName(contact) }}
                              </span>
                            </router-link>
                            <span v-else>
                              <span
                                v-for="(contact, j) in getConnection(ref.id).owner.contactIds"
                                :key="j">
                                {{ getContactName(contact) }}
                              </span>
                            </span>
                          </span>
                          <span v-if="ref.id.startsWith('room')">
                            <span
                              v-if="findConnectionId(ref, 'prop') && computedAllConnections.properties">
                              <router-link
                                v-if="!isMarketingModule"
                                class="external-link"
                                :to="getConnection(findConnectionId(ref, 'prop')) !== undefined ? redirectToDetailPage(getConnection(findConnectionId(ref, 'prop')), 'prop') : ''">
                                {{ getConnection(findConnectionId(ref, 'prop'))?.name || '' }} &nbsp;
                              </router-link>
                              <span v-else>
                                {{ getConnection(findConnectionId(ref, 'prop'))?.name || '' }}
                              </span>
                            </span>
                            <span
                              v-if="findConnectionId(ref, 'building') && computedAllConnections.buildings">
                              >
                              <router-link
                                v-if="!isMarketingModule"
                                class="external-link"
                                :to="getConnection(findConnectionId(ref, 'building')) !== undefined ? redirectToDetailPage(getConnection(findConnectionId(ref, 'building')), 'building') : ''">
                                {{ getConnection(findConnectionId(ref, 'building'))?.name || '' }} &nbsp;
                              </router-link>
                              <span v-else>
                                {{ getConnection(findConnectionId(ref, 'building'))?.name || '' }}
                              </span>
                            </span>
                            <span
                              v-if="findConnectionId(ref, 'obj') && computedAllConnections.objects">
                              >
                              <router-link
                                v-if="!isMarketingModule"
                                class="external-link"
                                :to="getConnection(findConnectionId(ref, 'obj')) !== undefined ? redirectToDetailPage(getConnection(findConnectionId(ref, 'obj')), 'obj') : ''">
                                {{ getConnection(findConnectionId(ref, 'obj'))?.name ||
                                  getConnection(findConnectionId(ref, 'obj'))?.number ||
                                  getConnection(findConnectionId(ref, 'obj'))?.numericId ||
                                  '' }}
                              </router-link>
                              <span v-else>
                                {{ getConnection(findConnectionId(ref, 'obj'))?.name ||
                                  getConnection(findConnectionId(ref, 'obj'))?.number ||
                                  getConnection(findConnectionId(ref, 'obj'))?.numericId ||
                                  '' }}
                              </span>
                            </span>
                            <span v-if="!isMarketingModule">
                              >
                              <router-link
                                class="external-link"
                                :to="getConnection(ref.id) !== undefined ? redirectToDetail(getConnection(ref.id), getConnection(ref.id)?.referenceId, 'objects') : ''">
                                {{ getConnection(ref.id) !== null ? getConnection(ref.id).name : '-' }}
                              </router-link>
                            </span>
                            <span v-else>{{ getConnection(ref.id) !== null ? getConnection(ref.id).name : '-' }}</span>
                          </span>
                        </p>
                        <p
                          v-else
                          :key="'else-open-' + ref.id">
                          -
                        </p>
                      </template>
                    </div>
                  </div>
                </ticket-card>
              </draggable>
            </div>
          </div>
          <!-- In progress column -->
          <div class="col-12 col-md-4 mt-5 mt-md-0">
            <h4 class="column-heading rounded p-2">
              {{ $t('message.ticketOverview.status.TICKET_STATUS_IN_PROGRESS') }} ({{ sortedProgressTicket.length }})
            </h4>
            <div class="column-container rounded">
              <h6
                v-if="!dragging && sortedProgressTicket.length === 0"
                class="text-center text-grey p-3">
                {{ $t('message.ticketOverview.noTickets') }}
              </h6>

              <draggable
                id="status-in_progress"
                v-model="sortedProgressTicket"
                :class="['draggable-container', sortedProgressTicket.length > 0 ? 'p-1' : '', dragging ? 'dragging-border' : '']"
                group="ticket"
                @start="startMoving"
                @end="endMoving">
                <ticket-card
                  v-for="element in sortedProgressTicket"
                  :key="element.id"
                  :ticket="element"
                  :mail="isTicketReferenceInMail(element)"
                  @click="openDetail"
                  @click-middle="openMiddleDetail">
                  <!-- slot of connection -->
                  <div class="col-12 d-flex">
                    <div class="mr-1">
                      {{ $t('message.ticketOverview.ticket.connections') }}:
                    </div>
                    <span v-if="element.references.length === 0"> - </span>
                    <div>
                      <template v-for="ref in element.references">
                        <p
                          v-if="getConnection(ref.id) !== undefined"
                          :key="ref.id"
                          class="mb-0">
                          <span
                            v-if="ref.id.startsWith('ins') && computedAllConnections.insurances"
                            class="external-link">
                            <span
                              v-if="findConnectionId(ref, 'prop') && computedAllConnections.properties">
                              <router-link
                                v-if="!isMarketingModule"
                                class="external-link"
                                :to="getConnection(findConnectionId(ref, 'prop')) !== undefined ? redirectToDetailPage(getConnection(findConnectionId(ref, 'prop')), 'prop') : ''">
                                {{ getConnection(findConnectionId(ref, 'prop'))?.name || '' }} &nbsp;
                              </router-link>
                              <span v-else>
                                {{ getConnection(findConnectionId(ref, 'prop'))?.name || '' }}
                              </span>
                            </span>
                            <span
                              v-if="findConnectionId(ref, 'building') && computedAllConnections.buildings">
                              >
                              <router-link
                                v-if="!isMarketingModule"
                                class="external-link"
                                :to="getConnection(findConnectionId(ref, 'building')) !== undefined ? redirectToDetailPage(getConnection(findConnectionId(ref, 'building')), 'building') : ''">
                                {{ getConnection(findConnectionId(ref, 'building'))?.name || '' }} &nbsp;
                              </router-link>
                              <span v-else>
                                {{ getConnection(findConnectionId(ref, 'building'))?.name || '' }}
                              </span>
                            </span>
                            <span
                              v-if="findConnectionId(ref, 'obj') && computedAllConnections.objects">
                              >
                              <router-link
                                v-if="!isMarketingModule"
                                class="external-link"
                                :to="getConnection(findConnectionId(ref, 'obj')) !== undefined ? redirectToDetailPage(getConnection(findConnectionId(ref, 'obj')), 'obj') : ''">
                                {{ getConnection(findConnectionId(ref, 'obj'))?.name ||
                                  getConnection(findConnectionId(ref, 'obj'))?.number ||
                                  getConnection(findConnectionId(ref, 'obj'))?.numericId ||
                                  '' }}
                              </router-link>
                              <span v-else>
                                {{ getConnection(findConnectionId(ref, 'obj'))?.name ||
                                  getConnection(findConnectionId(ref, 'obj'))?.number ||
                                  getConnection(findConnectionId(ref, 'obj'))?.numericId ||
                                  '' }}
                              </span>
                            </span>
                            <span
                              v-if="!isMarketingModule"
                              class="external-link link"
                              @click="setInsuranceId(getConnection(ref.id), false)"
                              @click.middle="setInsuranceId(getConnection(ref.id), true)">
                              <template v-if="getConnection(ref.id)">
                                {{ $t('message.onBoarding.buildings.insurances.type.' + getConnection(ref.id).type) }}
                                <template v-if="getReferenceName(ref.id) !== ''">
                                  {{ ' - ' + getReferenceName(ref.id) }}
                                </template>
                              </template>
                              <template v-else>
                                -
                              </template> &nbsp;
                            </span>
                            <span v-else>
                              {{ $t('message.onBoarding.buildings.insurances.type.' + getConnection(ref.id).type) }}
                            </span>
                          </span>
                          <span
                            v-if="ref.id.startsWith('mortgage') && computedAllConnections.mortgages"
                            class="external-link">
                            <a
                              v-if="!isMarketingModule"
                              class="external-link link"
                              @click="setMortgageId(getConnection(ref.id), false)"
                              @click.middle="setMortgageId(getConnection(ref.id), true)">
                              <template v-if="getConnection(ref.id)">
                                {{ getConnection(ref.id).bankName + ' - ' +
                                  $t('message.onBoarding.mortgage.mortgageDesignation.' +
                                    getConnection(ref.id).mortgageDesignation) }}
                                <template v-if="getReferenceName(ref.id) !== ''">
                                  {{ ' - ' + getReferenceName(ref.id) }}
                                </template>
                              </template>
                              <template v-else>
                                -
                              </template> &nbsp;
                            </a>
                            <span v-else>
                              {{ getConnection(ref.id).bankName + ' - ' +
                                $t('message.onBoarding.mortgage.mortgageDesignation.' +
                                  getConnection(ref.id).mortgageDesignation) }}
                            </span>
                          </span>
                          <span v-if="ref.id.startsWith('device') && computedAllConnections.devices">
                            <span
                              v-if="findConnectionId(ref, 'prop') && computedAllConnections.properties">
                              <router-link
                                v-if="!isMarketingModule"
                                class="external-link"
                                :to="getConnection(findConnectionId(ref, 'prop')) !== undefined ? redirectToDetailPage(getConnection(findConnectionId(ref, 'prop')), 'prop') : ''">
                                {{ getConnection(findConnectionId(ref, 'prop'))?.name || '' }} &nbsp;
                              </router-link>
                              <span v-else>
                                {{ getConnection(findConnectionId(ref, 'prop'))?.name || '' }}
                              </span>
                              >
                            </span>
                            <span
                              v-if="findConnectionId(ref, 'building') && computedAllConnections.buildings">
                              <router-link
                                v-if="!isMarketingModule"
                                class="external-link"
                                :to="getConnection(findConnectionId(ref, 'building')) !== undefined ? redirectToDetailPage(getConnection(findConnectionId(ref, 'building')), 'building') : ''">
                                {{ getConnection(findConnectionId(ref, 'building'))?.name || '' }} &nbsp;
                              </router-link>
                              <span v-else>
                                {{ getConnection(findConnectionId(ref, 'building'))?.name || '' }}
                              </span>
                              >
                            </span>
                            <span
                              v-if="findConnectionId(ref, 'obj') && computedAllConnections.objects">
                              <router-link
                                v-if="!isMarketingModule"
                                class="external-link"
                                :to="getConnection(findConnectionId(ref, 'obj')) !== undefined ? redirectToDetailPage(getConnection(findConnectionId(ref, 'obj')), 'obj') : ''">
                                {{ getConnection(findConnectionId(ref, 'obj'))?.name ||
                                  getConnection(findConnectionId(ref, 'obj'))?.number ||
                                  getConnection(findConnectionId(ref, 'obj'))?.numericId ||
                                  '' }}
                              </router-link>
                              <span v-else>
                                {{ getConnection(findConnectionId(ref, 'obj'))?.name ||
                                  getConnection(findConnectionId(ref, 'obj'))?.number ||
                                  getConnection(findConnectionId(ref, 'obj'))?.numericId ||
                                  '' }}
                              </span>
                              >
                            </span>
                            <router-link
                              v-if="!isMarketingModule"
                              class="external-link"
                              :to="getConnection(ref.id) !== undefined ? redirectToDeviceDetail(getConnection(ref.id)) : ''">
                              <template
                                v-if="getConnection(ref.id).type && getConnection(ref.id).type !== 'DEVICE_TYPE_UNDEFINED'">
                                {{ $t('message.onBoarding.devices.typeValues.' + getConnection(ref.id).type).length > 50
                                  ? $t('message.onBoarding.devices.typeValues.' + getConnection(ref.id).type).slice(0,50)+
                                    '...' : $t('message.onBoarding.devices.typeValues.' + getConnection(ref.id).type) }}
                              </template>
                              <template v-else>
                                -
                              </template> &nbsp;
                            </router-link>
                            <span v-else>
                              <template
                                v-if="getConnection(ref.id).type && getConnection(ref.id).type !== 'DEVICE_TYPE_UNDEFINED'">
                                {{ $t('message.onBoarding.devices.typeValues.' + getConnection(ref.id).type).length > 50
                                  ? $t('message.onBoarding.devices.typeValues.' + getConnection(ref.id).type).slice(0,50)+
                                    '...' : $t('message.onBoarding.devices.typeValues.' + getConnection(ref.id).type) }}
                              </template>
                              <template v-else>
                                -
                              </template>
                            </span>
                          </span>
                          <span v-if="ref.id.startsWith('obj') && computedAllConnections.objects">
                            <span
                              v-if="findConnectionId(ref, 'prop') && computedAllConnections.properties">
                              <router-link
                                v-if="!isMarketingModule"
                                class="external-link"
                                :to="getConnection(findConnectionId(ref, 'prop')) !== undefined ? redirectToDetailPage(getConnection(findConnectionId(ref, 'prop')), 'prop') : ''">
                                {{ getConnection(findConnectionId(ref, 'prop'))?.name || '' }} &nbsp;
                              </router-link>
                              <span v-else>
                                {{ getConnection(findConnectionId(ref, 'prop'))?.name || '' }}
                              </span>
                              >
                            </span>
                            <span
                              v-if="findConnectionId(ref, 'building') && computedAllConnections.buildings">
                              <router-link
                                v-if="!isMarketingModule"
                                class="external-link"
                                :to="getConnection(findConnectionId(ref, 'building')) !== undefined ? redirectToDetailPage(getConnection(findConnectionId(ref, 'building')), 'building') : ''">
                                {{ getConnection(findConnectionId(ref, 'building'))?.name || '' }} &nbsp;
                              </router-link>
                              <span v-else>
                                {{ getConnection(findConnectionId(ref, 'building'))?.name || '' }}
                              </span>
                              >
                            </span>
                            <router-link
                              v-if="!isMarketingModule"
                              class="external-link"
                              :to="getConnection(ref.id) !== undefined ? redirectToDetailPage(getConnection(ref.id), 'obj') : ''">
                              {{ getConnection(ref.id)?.name ||
                                getConnection(ref.id)?.number ||
                                getConnection(ref.id)?.numericId ||
                                '' }} &nbsp;
                            </router-link>
                            <span v-else>
                              {{ getConnection(ref.id)?.name ||
                                getConnection(ref.id)?.number ||
                                getConnection(ref.id)?.numericId ||
                                '' }}
                            </span>
                          </span>
                          <span v-if="ref.id.startsWith('building') && computedAllConnections.buildings">
                            <span
                              v-if="findConnectionId(ref, 'prop') && computedAllConnections.properties">
                              <router-link
                                v-if="!isMarketingModule"
                                class="external-link"
                                :to="getConnection(findConnectionId(ref, 'prop')) !== undefined ? redirectToDetailPage(getConnection(findConnectionId(ref, 'prop')), 'prop') : ''">
                                {{ getConnection(findConnectionId(ref, 'prop'))?.name || '' }} &nbsp;
                              </router-link>
                              <span v-else>
                                {{ getConnection(findConnectionId(ref, 'prop'))?.name || '' }}
                              </span>
                              >
                            </span>
                            <router-link
                              v-if="!isMarketingModule"
                              class="external-link"
                              :to="getConnection(ref.id) !== undefined ? redirectToDetailPage(getConnection(ref.id), 'building') : ''">
                              {{ getConnection(ref.id)?.name || '' }} &nbsp;
                            </router-link>
                            <span v-else>
                              {{ getConnection(ref.id)?.name || '' }}
                            </span>
                          </span>
                          <span v-if="ref.id.startsWith('prop') && computedAllConnections.properties">
                            <router-link
                              v-if="!isMarketingModule"
                              class="external-link"
                              :to="getConnection(ref.id) !== undefined ? redirectToDetailPage(getConnection(ref.id), 'prop') : ''">
                              {{ getConnection(ref.id)?.name || '' }} &nbsp;
                            </router-link>
                            <span v-else>
                              {{ getConnection(ref.id)?.name || '' }}
                            </span>
                          </span>
                          <span v-if="ref.id.startsWith('contact') && computedAllConnections.contacts">
                            <router-link
                              v-if="!isMarketingModule"
                              class="external-link"
                              :to="getConnection(ref.id) !== undefined ? redirectToDetailPage(getConnection(ref.id), 'contact') : ''">
                              {{ getConnection(ref.id)?.name || '' }} &nbsp;
                            </router-link>
                            <span v-else>
                              {{ getConnection(ref.id)?.name || '' }}
                            </span>
                          </span>
                          <span v-if="ref.id.startsWith('request')">
                            <router-link
                              v-if="getConnection(ref.id).requesters.length > 0"
                              class="external-link"
                              :to="redirectToRequestDetail(ref.objectId, ref.id)">
                              {{ getContactName(getConnection(ref.id).requesters[0].contactId) }}
                            </router-link>
                          </span>
                          <span v-if="ref.id.startsWith('tenancy')">
                            <router-link
                              v-if="!isMarketingModule"
                              class="external-link"
                              :to="getConnection(ref.id) !== undefined ? redirectToDetail(getConnection(ref.id), ref.id, 'tenants') : ''">
                              <template>
                                <span
                                  v-for="(contact, j) in getConnection(ref.id).tenant.contactIds"
                                  :key="j">
                                  {{ getContactName(contact) }} &nbsp;
                                </span>
                              </template>
                            </router-link>
                            <span v-else>
                              <template>
                                <span
                                  v-for="(contact, j) in getConnection(ref.id).tenant.contactIds"
                                  :key="j">
                                  {{ getContactName(contact) }}
                                </span>
                              </template>
                            </span>
                          </span>
                          <span v-if="ref.id.startsWith('condo')">
                            <router-link
                              v-if="!isMarketingModule"
                              class="external-link"
                              :to="getConnection(ref.id) !== undefined ? redirectToDetail(getConnection(ref.id), ref.id, 'condominium') : ''">
                              <span
                                v-for="(contact, j) in getConnection(ref.id).owner.contactIds"
                                :key="j">
                                {{ getContactName(contact) }}
                              </span>
                            </router-link>
                            <span v-else>
                              <span
                                v-for="(contact, j) in getConnection(ref.id).owner.contactIds"
                                :key="j">
                                {{ getContactName(contact) }}
                              </span>
                            </span>
                          </span>
                          <span v-if="ref.id.startsWith('room')">
                            <span
                              v-if="findConnectionId(ref, 'prop') && computedAllConnections.properties">
                              <router-link
                                v-if="!isMarketingModule"
                                class="external-link"
                                :to="getConnection(findConnectionId(ref, 'prop')) !== undefined ? redirectToDetailPage(getConnection(findConnectionId(ref, 'prop')), 'prop') : ''">
                                {{ getConnection(findConnectionId(ref, 'prop'))?.name || '' }} &nbsp;
                              </router-link>
                              <span v-else>
                                {{ getConnection(findConnectionId(ref, 'prop'))?.name || '' }}
                              </span>
                            </span>
                            <span
                              v-if="findConnectionId(ref, 'building') && computedAllConnections.buildings">
                              >
                              <router-link
                                v-if="!isMarketingModule"
                                class="external-link"
                                :to="getConnection(findConnectionId(ref, 'building')) !== undefined ? redirectToDetailPage(getConnection(findConnectionId(ref, 'building')), 'building') : ''">
                                {{ getConnection(findConnectionId(ref, 'building'))?.name || '' }} &nbsp;
                              </router-link>
                              <span v-else>
                                {{ getConnection(findConnectionId(ref, 'building'))?.name || '' }}
                              </span>
                            </span>
                            <span
                              v-if="findConnectionId(ref, 'obj') && computedAllConnections.objects">
                              >
                              <router-link
                                v-if="!isMarketingModule"
                                class="external-link"
                                :to="getConnection(findConnectionId(ref, 'obj')) !== undefined ? redirectToDetailPage(getConnection(findConnectionId(ref, 'obj')), 'obj') : ''">
                                {{ getConnection(findConnectionId(ref, 'obj'))?.name ||
                                  getConnection(findConnectionId(ref, 'obj'))?.number ||
                                  getConnection(findConnectionId(ref, 'obj'))?.numericId ||
                                  '' }}
                              </router-link>
                              <span v-else>
                                {{ getConnection(findConnectionId(ref, 'obj'))?.name ||
                                  getConnection(findConnectionId(ref, 'obj'))?.number ||
                                  getConnection(findConnectionId(ref, 'obj'))?.numericId ||
                                  '' }}
                              </span>
                            </span>
                            <span v-if="!isMarketingModule">
                              >
                              <router-link
                                class="external-link"
                                :to="getConnection(ref.id) !== undefined ? redirectToDetail(getConnection(ref.id), getConnection(ref.id)?.referenceId, 'objects') : ''">
                                {{ getConnection(ref.id) !== null ? getConnection(ref.id).name : '-' }}
                              </router-link>
                            </span>
                            <span v-else>{{ getConnection(ref.id) !== null ? getConnection(ref.id).name : '-' }}</span>
                          </span>
                        </p>
                        <p
                          v-else
                          :key="'else-inprogress-' + ref.id">
                          -
                        </p>
                      </template>
                    </div>
                  </div>
                </ticket-card>
              </draggable>
            </div>
          </div>

          <!-- Done column -->
          <div class="col-12 col-md-4 mt-5 mt-md-0">
            <h4 class="column-heading rounded p-2">
              {{ $t('message.ticketOverview.status.TICKET_STATUS_DONE') }} ({{ sortedDoneTicket.length }})
            </h4>
            <div class="column-container rounded">
              <h6
                v-if="!dragging && sortedDoneTicket.length === 0"
                class="text-center text-grey p-3">
                {{ $t('message.ticketOverview.noTickets') }}
              </h6>

              <draggable
                id="status-done"
                v-model="sortedDoneTicket"
                :class="['draggable-container', sortedDoneTicket.length > 0 ? 'p-1' : '', dragging ? 'dragging-border' : '']"
                group="ticket"
                @start="startMoving"
                @end="endMoving">
                <ticket-card
                  v-for="element in sortedDoneTicket"
                  :key="element.id"
                  :ticket="element"
                  :mail="isTicketReferenceInMail(element)"
                  @click="openDetail"
                  @click-middle="openMiddleDetail">
                  <!-- slot of connection -->
                  <div class="col-12 d-flex">
                    <div class="mr-1">
                      {{ $t('message.ticketOverview.ticket.connections') }} :
                    </div>
                    <span v-if="element.references.length === 0"> - </span>
                    <div>
                      <template v-for="ref in element.references">
                        <p
                          v-if="getConnection(ref.id) !== undefined"
                          :key="ref.id"
                          class="mb-0">
                          <span
                            v-if="ref.id.startsWith('ins') && computedAllConnections.insurances"
                            class="external-link">
                            <span
                              v-if="findConnectionId(ref, 'prop') && computedAllConnections.properties">
                              <router-link
                                v-if="!isMarketingModule"
                                class="external-link"
                                :to="getConnection(findConnectionId(ref, 'prop')) !== undefined ? redirectToDetailPage(getConnection(findConnectionId(ref, 'prop')), 'prop') : ''">
                                {{ getConnection(findConnectionId(ref, 'prop'))?.name || '' }} &nbsp;
                              </router-link>
                              <span v-else>
                                {{ getConnection(findConnectionId(ref, 'prop'))?.name || '' }}
                              </span>
                            </span>
                            <span
                              v-if="findConnectionId(ref, 'building') && computedAllConnections.buildings">
                              >
                              <router-link
                                v-if="!isMarketingModule"
                                class="external-link"
                                :to="getConnection(findConnectionId(ref, 'building')) !== undefined ? redirectToDetailPage(getConnection(findConnectionId(ref, 'building')), 'building') : ''">
                                {{ getConnection(findConnectionId(ref, 'building'))?.name || '' }} &nbsp;
                              </router-link>
                              <span v-else>
                                {{ getConnection(findConnectionId(ref, 'building'))?.name || '' }}
                              </span>
                            </span>
                            <span
                              v-if="findConnectionId(ref, 'obj') && computedAllConnections.objects">
                              >
                              <router-link
                                v-if="!isMarketingModule"
                                class="external-link"
                                :to="getConnection(findConnectionId(ref, 'obj')) !== undefined ? redirectToDetailPage(getConnection(findConnectionId(ref, 'obj')), 'obj') : ''">
                                {{ getConnection(findConnectionId(ref, 'obj'))?.name ||
                                  getConnection(findConnectionId(ref, 'obj'))?.number ||
                                  getConnection(findConnectionId(ref, 'obj'))?.numericId ||
                                  '' }}
                              </router-link>
                              <span v-else>
                                {{ getConnection(findConnectionId(ref, 'obj'))?.name ||
                                  getConnection(findConnectionId(ref, 'obj'))?.number ||
                                  getConnection(findConnectionId(ref, 'obj'))?.numericId ||
                                  '' }}
                              </span>
                            </span>
                            <span
                              v-if="!isMarketingModule"
                              class="external-link link"
                              @click="setInsuranceId(getConnection(ref.id), false)"
                              @click.middle="setInsuranceId(getConnection(ref.id), true)">
                              <template v-if="getConnection(ref.id)">
                                {{ $t('message.onBoarding.buildings.insurances.type.' + getConnection(ref.id).type) }}
                                <template v-if="getReferenceName(ref.id) !== ''">
                                  {{ ' - ' + getReferenceName(ref.id) }}
                                </template>
                              </template>
                              <template v-else>
                                -
                              </template> &nbsp;
                            </span>
                            <span v-else>
                              {{ $t('message.onBoarding.buildings.insurances.type.' + getConnection(ref.id).type) }}
                            </span>
                          </span>
                          <span
                            v-if="ref.id.startsWith('mortgage') && computedAllConnections.mortgages"
                            class="external-link">
                            <a
                              v-if="!isMarketingModule"
                              class="external-link link"
                              @click="setMortgageId(getConnection(ref.id), false)"
                              @click.middle="setMortgageId(getConnection(ref.id), true)">
                              <template v-if="getConnection(ref.id)">
                                {{ getConnection(ref.id).bankName + ' - ' +
                                  $t('message.onBoarding.mortgage.mortgageDesignation.' +
                                    getConnection(ref.id).mortgageDesignation) }}
                                <template v-if="getReferenceName(ref.id) !== ''">
                                  {{ ' - ' + getReferenceName(ref.id) }}
                                </template>
                              </template>
                              <template v-else>
                                -
                              </template> &nbsp;
                            </a>
                            <span v-else>
                              {{ getConnection(ref.id).bankName + ' - ' +
                                $t('message.onBoarding.mortgage.mortgageDesignation.' +
                                  getConnection(ref.id).mortgageDesignation) }}
                            </span>
                          </span>
                          <span v-if="ref.id.startsWith('device') && computedAllConnections.devices">
                            <span
                              v-if="findConnectionId(ref, 'prop') && computedAllConnections.properties">
                              <router-link
                                v-if="!isMarketingModule"
                                class="external-link"
                                :to="getConnection(findConnectionId(ref, 'prop')) !== undefined ? redirectToDetailPage(getConnection(findConnectionId(ref, 'prop')), 'prop') : ''">
                                {{ getConnection(findConnectionId(ref, 'prop'))?.name || '' }} &nbsp;
                              </router-link>
                              <span v-else>
                                {{ getConnection(findConnectionId(ref, 'prop'))?.name || '' }}
                              </span>
                              >
                            </span>
                            <span
                              v-if="findConnectionId(ref, 'building') && computedAllConnections.buildings">
                              <router-link
                                v-if="!isMarketingModule"
                                class="external-link"
                                :to="getConnection(findConnectionId(ref, 'building')) !== undefined ? redirectToDetailPage(getConnection(findConnectionId(ref, 'building')), 'building') : ''">
                                {{ getConnection(findConnectionId(ref, 'building'))?.name || '' }} &nbsp;
                              </router-link>
                              <span v-else>
                                {{ getConnection(findConnectionId(ref, 'building'))?.name || '' }}
                              </span>
                              >
                            </span>
                            <span
                              v-if="findConnectionId(ref, 'obj') && computedAllConnections.objects">
                              <router-link
                                v-if="!isMarketingModule"
                                class="external-link"
                                :to="getConnection(findConnectionId(ref, 'obj')) !== undefined ? redirectToDetailPage(getConnection(findConnectionId(ref, 'obj')), 'obj') : ''">
                                {{ getConnection(findConnectionId(ref, 'obj'))?.name ||
                                  getConnection(findConnectionId(ref, 'obj'))?.number ||
                                  getConnection(findConnectionId(ref, 'obj'))?.numericId ||
                                  '' }}
                              </router-link>
                              <span v-else>
                                {{ getConnection(findConnectionId(ref, 'obj'))?.name ||
                                  getConnection(findConnectionId(ref, 'obj'))?.number ||
                                  getConnection(findConnectionId(ref, 'obj'))?.numericId ||
                                  '' }}
                              </span>
                              >
                            </span>
                            <router-link
                              v-if="!isMarketingModule"
                              class="external-link"
                              :to="getConnection(ref.id) !== undefined ? redirectToDeviceDetail(getConnection(ref.id)) : ''">
                              <template
                                v-if="getConnection(ref.id).type && getConnection(ref.id).type !== 'DEVICE_TYPE_UNDEFINED'">
                                {{ $t('message.onBoarding.devices.typeValues.' + getConnection(ref.id).type).length > 50
                                  ? $t('message.onBoarding.devices.typeValues.' + getConnection(ref.id).type).slice(0,50)+
                                    '...' : $t('message.onBoarding.devices.typeValues.' + getConnection(ref.id).type) }}
                              </template>
                              <template v-else>
                                -
                              </template> &nbsp;
                            </router-link>
                            <span v-else>
                              <template
                                v-if="getConnection(ref.id).type && getConnection(ref.id).type !== 'DEVICE_TYPE_UNDEFINED'">
                                {{ $t('message.onBoarding.devices.typeValues.' + getConnection(ref.id).type).length > 50
                                  ? $t('message.onBoarding.devices.typeValues.' + getConnection(ref.id).type).slice(0,50)+
                                    '...' : $t('message.onBoarding.devices.typeValues.' + getConnection(ref.id).type) }}
                              </template>
                              <template v-else>
                                -
                              </template>
                            </span>
                          </span>
                          <span v-if="ref.id.startsWith('obj') && computedAllConnections.objects">
                            <span
                              v-if="findConnectionId(ref, 'prop') && computedAllConnections.properties">
                              <router-link
                                v-if="!isMarketingModule"
                                class="external-link"
                                :to="getConnection(findConnectionId(ref, 'prop')) !== undefined ? redirectToDetailPage(getConnection(findConnectionId(ref, 'prop')), 'prop') : ''">
                                {{ getConnection(findConnectionId(ref, 'prop'))?.name || '' }} &nbsp;
                              </router-link>
                              <span v-else>
                                {{ getConnection(findConnectionId(ref, 'prop'))?.name || '' }}
                              </span>
                              >
                            </span>
                            <span
                              v-if="findConnectionId(ref, 'building') && computedAllConnections.buildings">
                              <router-link
                                v-if="!isMarketingModule"
                                class="external-link"
                                :to="getConnection(findConnectionId(ref, 'building')) !== undefined ? redirectToDetailPage(getConnection(findConnectionId(ref, 'building')), 'building') : ''">
                                {{ getConnection(findConnectionId(ref, 'prop'))?.name || '' }} &nbsp;
                              </router-link>
                              <span v-else>
                                {{ getConnection(findConnectionId(ref, 'prop'))?.name || '' }}
                              </span>
                              >
                            </span>
                            <router-link
                              v-if="!isMarketingModule"
                              class="external-link"
                              :to="getConnection(ref.id) ? redirectToDetailPage(getConnection(ref.id), 'obj') : ''">
                              {{ getConnection(ref.id)?.name ||
                                getConnection(ref.id)?.number ||
                                getConnection(ref.id)?.numericId ||
                                '' }} &nbsp;
                            </router-link>
                            <span v-else>
                              {{ getConnection(ref.id)?.name ||
                                getConnection(ref.id)?.number ||
                                getConnection(ref.id)?.numericId ||
                                '' }}
                            </span>
                          </span>
                          <span v-if="ref.id.startsWith('building') && computedAllConnections.buildings">
                            <span
                              v-if="findConnectionId(ref, 'prop') && computedAllConnections.properties">
                              <router-link
                                v-if="!isMarketingModule"
                                class="external-link"
                                :to="getConnection(findConnectionId(ref, 'prop')) !== undefined ? redirectToDetailPage(getConnection(findConnectionId(ref, 'prop')), 'prop') : ''">
                                {{ getConnection(findConnectionId(ref, 'prop'))?.name || '' }} &nbsp;
                              </router-link>
                              <span v-else>
                                {{ getConnection(findConnectionId(ref, 'prop'))?.name || '' }}
                              </span>
                              >
                            </span>
                            <router-link
                              v-if="!isMarketingModule"
                              class="external-link"
                              :to="getConnection(ref.id) !== undefined ? redirectToDetailPage(getConnection(ref.id), 'building') : ''">
                              {{ getConnection(ref.id)?.name || '' }} &nbsp;
                            </router-link>
                            <span v-else>
                              {{ getConnection(ref.id)?.name || '' }}
                            </span>
                          </span>
                          <span v-if="ref.id.startsWith('prop') && computedAllConnections.properties">
                            <router-link
                              v-if="!isMarketingModule"
                              class="external-link"
                              :to="getConnection(ref.id) !== undefined ? redirectToDetailPage(getConnection(ref.id), 'prop') : ''">
                              {{ getConnection(ref.id)?.name || '' }} &nbsp;
                            </router-link>
                            <span v-else>
                              {{ getConnection(ref.id)?.name || '' }}
                            </span>
                          </span>
                          <span v-if="ref.id.startsWith('contact') && computedAllConnections.contacts">
                            <router-link
                              v-if="!isMarketingModule"
                              class="external-link"
                              :to="getConnection(ref.id) !== undefined ? redirectToDetailPage(getConnection(ref.id), 'contact') : ''">
                              {{ getConnection(ref.id)?.name || '' }} &nbsp;
                            </router-link>
                            <span v-else>
                              {{ getConnection(ref.id)?.name || '' }}
                            </span>
                          </span>
                          <span v-if="ref.id.startsWith('request')">
                            <router-link
                              v-if="getConnection(ref.id).requesters.length > 0"
                              class="external-link"
                              :to="redirectToRequestDetail(ref.objectId, ref.id)">
                              {{ getContactName(getConnection(ref.id).requesters[0].contactId) }}
                            </router-link>
                          </span>
                          <span v-if="ref.id.startsWith('tenancy')">
                            <router-link
                              v-if="!isMarketingModule"
                              class="external-link"
                              :to="getConnection(ref.id) !== undefined ? redirectToDetail(getConnection(ref.id), ref.id, 'tenants') : ''">
                              <template>
                                <span
                                  v-for="(contact, j) in getConnection(ref.id).tenant.contactIds"
                                  :key="j">
                                  {{ getContactName(contact) }} &nbsp;
                                </span>
                              </template>
                            </router-link>
                            <span v-else>
                              <template>
                                <span
                                  v-for="(contact, j) in getConnection(ref.id).tenant.contactIds"
                                  :key="j">
                                  {{ getContactName(contact) }}
                                </span>
                              </template>
                            </span>
                          </span>
                          <span v-if="ref.id.startsWith('condo')">
                            <router-link
                              v-if="!isMarketingModule"
                              class="external-link"
                              :to="getConnection(ref.id) !== undefined ? redirectToDetail(getConnection(ref.id), ref.id, 'condominium') : ''">
                              <span
                                v-for="(contact, j) in getConnection(ref.id).owner.contactIds"
                                :key="j">
                                {{ getContactName(contact) }}
                              </span>
                            </router-link>
                            <span v-else>
                              <span
                                v-for="(contact, j) in getConnection(ref.id).owner.contactIds"
                                :key="j">
                                {{ getContactName(contact) }}
                              </span>
                            </span>
                          </span>
                          <span v-if="ref.id.startsWith('room')">
                            <span
                              v-if="findConnectionId(ref, 'prop') && computedAllConnections.properties">
                              <router-link
                                v-if="!isMarketingModule"
                                class="external-link"
                                :to="getConnection(findConnectionId(ref, 'prop')) !== undefined ? redirectToDetailPage(getConnection(findConnectionId(ref, 'prop')), 'prop') : ''">
                                {{ getConnection(findConnectionId(ref, 'prop'))?.name || '' }} &nbsp;
                              </router-link>
                              <span v-else>
                                {{ getConnection(findConnectionId(ref, 'prop'))?.name || '' }}
                              </span>
                            </span>
                            <span
                              v-if="findConnectionId(ref, 'building') && computedAllConnections.buildings">
                              >
                              <router-link
                                v-if="!isMarketingModule"
                                class="external-link"
                                :to="getConnection(findConnectionId(ref, 'building')) !== undefined ? redirectToDetailPage(getConnection(findConnectionId(ref, 'building')), 'building') : ''">
                                {{ getConnection(findConnectionId(ref, 'building'))?.name || '' }} &nbsp;
                              </router-link>
                              <span v-else>
                                {{ getConnection(findConnectionId(ref, 'building'))?.name || '' }}
                              </span>
                            </span>
                            <span
                              v-if="findConnectionId(ref, 'obj') && computedAllConnections.objects">
                              >
                              <router-link
                                v-if="!isMarketingModule"
                                class="external-link"
                                :to="getConnection(findConnectionId(ref, 'obj')) !== undefined ? redirectToDetailPage(getConnection(findConnectionId(ref, 'obj')), 'obj') : ''">
                                {{ getConnection(findConnectionId(ref, 'obj'))?.name ||
                                  getConnection(findConnectionId(ref, 'obj'))?.number ||
                                  getConnection(findConnectionId(ref, 'obj'))?.numericId ||
                                  '' }}
                              </router-link>
                              <span v-else>
                                {{ getConnection(findConnectionId(ref, 'obj'))?.name ||
                                  getConnection(findConnectionId(ref, 'obj'))?.number ||
                                  getConnection(findConnectionId(ref, 'obj'))?.numericId ||
                                  '' }}
                              </span>
                            </span>
                            <span v-if="!isMarketingModule">
                              >
                              <router-link
                                class="external-link"
                                :to="getConnection(ref.id) !== undefined ? redirectToDetail(getConnection(ref.id), getConnection(ref.id)?.referenceId, 'objects') : ''">
                                {{ getConnection(ref.id) !== null ? getConnection(ref.id).name : '-' }}
                              </router-link>
                            </span>
                            <span v-else>{{ getConnection(ref.id) !== null ? getConnection(ref.id).name : '-' }}</span>
                          </span>
                        </p>
                        <p
                          v-else
                          :key="'else-done-' + ref.id">
                          -
                        </p>
                      </template>
                    </div>
                  </div>
                </ticket-card>
              </draggable>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        id="rowConfig"
        class="row overflow-auto align-items-center">
        <div class="col-md-2 col-sm-6 col-12">
          <coozzy-dropdown
            design="green"
            size="sm"
            class="w-100"
            :text="selectedEntriesText">
            <template v-if="!draftTicket">
              <coozzy-dropdown-item
                :disabled="selectedEntries.length === 0"
                @click="showModalChangeAssignee()">
                {{ $t('message.ticketOverview.changeAssignee') }}
              </coozzy-dropdown-item>
              <coozzy-dropdown-item
                :disabled="selectedEntries.length === 0"
                @click="showModalChangeWatchers()">
                {{ $t('message.ticketOverview.startWatching') }}
              </coozzy-dropdown-item>
            </template>
            <coozzy-dropdown-item
              v-else
              :disabled="selectedEntries.length === 0"
              @click="deleteTicketClicked">
              {{ $t('message.generic.delete') }}
            </coozzy-dropdown-item>
          </coozzy-dropdown>
        </div>
        <div class="col-md-2 col-sm-6 col-12">
          <template v-if="selectAllVisibleEntries && totalTickets > nbrPerPage && totalTickets <= 1000">
            <coozzy-form-checkbox
              v-model="selectAllEntries"
              :initial="selectAllEntries"
              :class="'p-0 w-100 btn-height-auto'"
              name="selectAllEntries"
              button>
              {{ selectAllEntries ? $t('message.generic.unselectHiddenEntries', { amount: totalTickets }) :
                $t('message.generic.selectHiddenEntries', { amount: totalTickets }) }}
            </coozzy-form-checkbox>
          </template>
        </div>
        <!--   Added only for keep the same size   -->

        <div
          v-if="isCompanyAdmin === true && !closedTicket"
          class="offset-md-5 col-md-1 d-flex justify-content-end align-items-center">
          <div @click="openAssembleListModal()">
            <coozzy-filters-icon
              v-b-tooltip="{container: '#rowConfig', title: $t('message.generic.tooltipAssembleList')}" />
          </div>
        </div>
        <div
          class="col-12 col-md-2 mb-2"
          :class="closedTicket ? 'offset-md-6' : ''">
          <coozzy-form-select
            v-if="!isColumnView"
            v-model="nbrPerPage"
            name="numberEntries">
            <option :value="5">
              5 {{ $t('message.generic.entries') }}
            </option>
            <option :value="10">
              10 {{ $t('message.generic.entries') }}
            </option>
            <option :value="15">
              15 {{ $t('message.generic.entries') }}
            </option>
            <option :value="20">
              20 {{ $t('message.generic.entries') }}
            </option>
            <option :value="50">
              50 {{ $t('message.generic.entries') }}
            </option>
            <option :value="100">
              100 {{ $t('message.generic.entries') }}
            </option>
          </coozzy-form-select>
        </div>
        <div class="col-12 mt-2">
          <b-table
            id="ticketOverviewTable"
            ref="ticketOverviewTable"
            bordered
            hover
            selectable
            select-mode="single"
            :fields="fields"
            :busy="loading"
            :items="ticketList"
            @row-clicked="showDetail"
            @row-middle-clicked="showDetailMiddle">
            <!-- Headings -->
            <template #head(checkbox)>
              <coozzy-form-checkbox
                ref="header_checkbox"
                @change="(value) => { handleCheckboxesInsideTable(value) }" />
            </template>
            <template #head(title)>
              {{ $t('message.tableColumnHeadings.title') }}
            </template>
            <template #head(ticketKey)>
              {{ $t('message.tableColumnHeadings.ticketKey') }}
            </template>
            <template #head(type)>
              {{ $t('message.tableColumnHeadings.type') }}
            </template>
            <template #head(ticketConnections)>
              {{ $t('message.tableColumnHeadings.ticketConnections') }}
            </template>
            <template #head(status)>
              {{ $t('message.tableColumnHeadings.status') }}
            </template>
            <template #head(assignee)>
              {{ $t('message.tableColumnHeadings.assignee') }}
            </template>
            <template #head(ticketPriority)>
              {{ $t('message.tableColumnHeadings.ticketPriority') }}
            </template>
            <template #head(ticketExecuteFrom)>
              {{ $t('message.tableColumnHeadings.ticketExecuteFrom') }}
            </template>
            <template #head(ticketExecuteUntil)>
              {{ $t('message.tableColumnHeadings.ticketExecuteUntil') }}
            </template>
            <template #head(receivedOpened)>
              {{ $t('message.tableColumnHeadings.receivedOpened') }}
            </template>
            <template #head(operator)>
              {{ $t('message.tableColumnHeadings.operator') }}
            </template>

            <!-- Columns -->
            <template #cell(checkbox)="data">
              <div>
                <coozzy-form-checkbox
                  :id="'check_' + data.item.id"
                  @change="(value) => { itemSelected(data.item, value) }" />
              </div>
            </template>
            <template #cell(newEvent)="data">
              <div>
                <img
                  v-if="data.item && data.item.readStatus && !data.item.readStatus.assignee"
                  height="20px"
                  class="ml-3 mb-1"
                  :src="`/img/ticket/notification-bell.svg`"
                  alt="type">
              </div>
            </template>
            <template #cell(status)="data">
              <template v-if="data.item.status === 'TICKET_STATUS_OPEN'">
                {{ $t('message.ticketOverview.status.TICKET_STATUS_OPEN') }}
              </template>
              <template v-else-if="data.item.status === 'TICKET_STATUS_IN_PROGRESS'">
                {{ $t('message.ticketOverview.status.TICKET_STATUS_IN_PROGRESS') }}
              </template>
              <template v-else-if="data.item.status === 'TICKET_STATUS_DONE'">
                {{ $t('message.ticketOverview.status.TICKET_STATUS_DONE') }}
              </template>
              <template v-else-if="data.item.status === 'TICKET_STATUS_CLOSED'">
                {{ $t('message.ticketOverview.status.TICKET_STATUS_CLOSED') }}
              </template>
              <template v-else-if="data.item.status === 'TICKET_STATUS_DRAFT'">
                {{ $t('message.ticketOverview.status.TICKET_STATUS_DRAFT') }}
              </template>
            </template>
            <template #cell(ticketKey)="data">
              {{ data.item.key }}
            </template>
            <template #cell(type)="data">
              <img
                height="20px"
                class="mr-1 mb-1"
                :src="`/img/ticket/${data.item.type.split('TICKET_TYPE_')[1].toLowerCase()}.svg`"
                alt="type">
            </template>
            <template #cell(ticketConnections)="data">
              <template v-for="ref in data.item.references">
                <p
                  v-if="getConnection(ref.id) !== undefined"
                  :key="ref.id"
                  class="mb-0">
                  <span v-if="findConnectionId(ref, 'prop') && computedAllConnections.properties">
                    <router-link
                      v-if="!isMarketingModule"
                      class="external-link"
                      :to="getConnection(findConnectionId(ref, 'prop')) !== undefined ? redirectToDetailPage(getConnection(findConnectionId(ref, 'prop')), 'prop') : ''">
                      {{ getConnection(findConnectionId(ref, 'prop'))?.name || '' }} &nbsp;
                    </router-link>
                    <span v-else>
                      {{ getConnection(findConnectionId(ref, 'prop'))?.name || '' }}
                    </span>
                    >
                  </span>
                  <span v-if="findConnectionId(ref, 'building') && computedAllConnections.buildings">
                    <router-link
                      v-if="!isMarketingModule"
                      class="external-link"
                      :to="getConnection(findConnectionId(ref, 'building')) !== undefined ? redirectToDetailPage(getConnection(findConnectionId(ref, 'building')), 'building') : ''">
                      {{ getConnection(findConnectionId(ref, 'building'))?.name || '' }} &nbsp;
                    </router-link>
                    <span v-else>
                      {{ getConnection(findConnectionId(ref, 'building'))?.name || '' }}
                    </span>
                    >
                  </span>
                  <span v-if="findConnectionId(ref, 'obj') && computedAllConnections.objects">
                    <router-link
                      v-if="!isMarketingModule"
                      class="external-link"
                      :to="getConnection(findConnectionId(ref, 'obj')) !== undefined ? redirectToDetailPage(getConnection(findConnectionId(ref, 'obj')), 'obj') : ''">
                      {{ getConnection(findConnectionId(ref, 'obj'))?.name ||
                        getConnection(findConnectionId(ref, 'obj'))?.number ||
                        getConnection(findConnectionId(ref, 'obj'))?.numericId ||
                        '' }} &nbsp;
                    </router-link>
                    <span v-else>
                      {{ getConnection(findConnectionId(ref, 'obj'))?.name ||
                        getConnection(findConnectionId(ref, 'obj'))?.number ||
                        getConnection(findConnectionId(ref, 'obj'))?.numericId ||
                        '' }}
                    </span>
                    >
                  </span>
                  <span
                    v-if="ref.id.startsWith('ins') && computedAllConnections.insurances"
                    class="external-link">
                    <a
                      v-if="!isMarketingModule"
                      class="external-link link"
                      @click="setInsuranceId(getConnection(ref.id), false)"
                      @click.middle="setInsuranceId(getConnection(ref.id), true)">
                      <template v-if="getConnection(ref.id)">
                        {{ $t('message.onBoarding.buildings.insurances.type.' + getConnection(ref.id).type) }}
                        <template v-if="getReferenceName(ref.id) !== ''">
                          {{ ' - ' + getReferenceName(ref.id) }}
                        </template>
                      </template>
                      <template v-else>
                        -
                      </template> &nbsp;
                    </a>
                    <span v-else>
                      {{ $t('message.onBoarding.buildings.insurances.type.' + getConnection(ref.id).type) }}
                    </span>
                  </span>
                  <span
                    v-if="ref.id.startsWith('mortgage') && computedAllConnections.mortgages"
                    class="external-link">
                    <a
                      v-if="!isMarketingModule"
                      class="external-link link"
                      @click="setMortgageId(getConnection(ref.id), false)"
                      @click.middle="setMortgageId(getConnection(ref.id), true)">
                      <template v-if="getConnection(ref.id)">
                        {{ getConnection(ref.id).bankName + ' - ' +
                          $t('message.onBoarding.mortgage.mortgageDesignation.' +
                            getConnection(ref.id).mortgageDesignation) }}
                        <template v-if="getReferenceName(ref.id) !== ''">
                          {{ ' - ' + getReferenceName(ref.id) }}
                        </template>
                      </template>
                      <template v-else>
                        -
                      </template> &nbsp;
                    </a>
                    <span v-else>
                      {{ getConnection(ref.id).bankName + ' - ' + $t('message.onBoarding.mortgage.mortgageDesignation.'
                        + getConnection(ref.id).mortgageDesignation) }}
                    </span>
                  </span>
                  <span v-if="ref.id.startsWith('device') && computedAllConnections.devices">
                    <router-link
                      v-if="!isMarketingModule"
                      class="external-link"
                      :to="getConnection(ref.id) !== undefined ? redirectToDeviceDetail(getConnection(ref.id)) : ''">
                      <template
                        v-if="getConnection(ref.id).type && getConnection(ref.id).type !== 'DEVICE_TYPE_UNDEFINED'">
                        {{ $t('message.onBoarding.devices.typeValues.' + getConnection(ref.id).type).length > 50 ?
                          $t('message.onBoarding.devices.typeValues.' + getConnection(ref.id).type).slice(0,50)+ '...' :
                          $t('message.onBoarding.devices.typeValues.' + getConnection(ref.id).type) }}
                      </template>
                      <template v-else>
                        -
                      </template> &nbsp;
                    </router-link>
                    <span v-else>
                      <template
                        v-if="getConnection(ref.id).type && getConnection(ref.id).type !== 'DEVICE_TYPE_UNDEFINED'">
                        {{ $t('message.onBoarding.devices.typeValues.' + getConnection(ref.id).type).length > 50 ?
                          $t('message.onBoarding.devices.typeValues.' + getConnection(ref.id).type).slice(0,50)+ '...' :
                          $t('message.onBoarding.devices.typeValues.' + getConnection(ref.id).type) }}
                      </template>
                      <template v-else>
                        -
                      </template>
                    </span>
                  </span>
                  <span v-if="ref.id.startsWith('obj') && computedAllConnections.objects">
                    <router-link
                      v-if="!isMarketingModule"
                      class="external-link"
                      :to="getConnection(ref.id) !== undefined ? redirectToDetailPage(getConnection(ref.id), 'obj') : ''">
                      {{ getConnection(ref.id)?.name ||
                        getConnection(ref.id).number ||
                        getConnection(ref.id).numericId ||
                        '' }}
                    </router-link>
                    <span v-else>
                      {{ getConnection(ref.id)?.name ||
                        getConnection(ref.id)?.number ||
                        getConnection(ref.id)?.numericId ||
                        '' }}
                    </span>
                  </span>
                  <span v-else-if="ref.id.startsWith('building') && computedAllConnections.buildings">
                    <router-link
                      v-if="!isMarketingModule"
                      :to="getConnection(ref.id) !== undefined ? redirectToDetailPage(getConnection(ref.id), 'building') : ''">
                      {{ getConnection(ref.id)?.name || '' }}
                    </router-link>
                    <span v-else>
                      {{ getConnection(ref.id)?.name || '' }}
                    </span>
                  </span>
                  <span v-else-if="ref.id.startsWith('prop') && computedAllConnections.properties">
                    <router-link
                      v-if="!isMarketingModule"
                      class="external-link"
                      :to="getConnection(ref.id) !== undefined ? redirectToDetailPage(getConnection(ref.id), 'prop') : ''">
                      {{ getConnection(ref.id)?.name || '' }}
                    </router-link>
                    <span v-else>
                      {{ getConnection(ref.id)?.name || '' }}
                    </span>
                  </span>
                  <span v-else-if="ref.id.startsWith('contact') && computedAllConnections.contacts">
                    <router-link
                      v-if="!isMarketingModule"
                      :to="getConnection(ref.id) !== undefined ? redirectToDetailPage(getConnection(ref.id), 'contact') : ''">
                      {{ getConnection(ref.id)?.name || '' }}
                    </router-link>
                    <span v-else>
                      {{ getConnection(ref.id)?.name || '' }}
                    </span>
                  </span>
                  <span v-if="ref.id.startsWith('request')">
                    <router-link
                      v-if="getConnection(ref.id).requesters.length > 0"
                      class="external-link"
                      :to="redirectToRequestDetail(ref.objectId, ref.id)">
                      {{ getContactName(getConnection(ref.id).requesters[0].contactId) }}
                    </router-link>
                  </span>
                  <span v-else-if="ref.id.startsWith('tenancy')">
                    <router-link
                      v-if="!isMarketingModule"
                      class="external-link"
                      :to="getConnection(ref.id) !== undefined ? redirectToDetail(getConnection(ref.id), ref.id, 'tenants') : ''">
                      <template>
                        <span
                          v-for="(contact, j) in getConnection(ref.id).tenant.contactIds"
                          :key="j">
                          {{ getContactName(contact) }}
                        </span>
                      </template>
                    </router-link>
                    <span v-else>
                      <template>
                        <span
                          v-for="(contact, j) in getConnection(ref.id).tenant.contactIds"
                          :key="j">
                          {{ getContactName(contact) }}
                        </span>
                      </template>
                    </span>
                  </span>
                  <span v-else-if="ref.id.startsWith('condo')">
                    <router-link
                      v-if="!isMarketingModule"
                      class="external-link"
                      :to="getConnection(ref.id) !== undefined ? redirectToDetail(getConnection(ref.id), ref.id, 'condominium') : ''">
                      <span
                        v-for="(contact, j) in getConnection(ref.id).owner.contactIds"
                        :key="j">
                        {{ getContactName(contact) }}
                      </span>
                    </router-link>
                    <span v-else>
                      <span
                        v-for="(contact, j) in getConnection(ref.id).owner.contactIds"
                        :key="j">
                        {{ getContactName(contact) }}
                      </span>
                    </span>
                  </span>
                  <span v-else-if="ref.id.startsWith('room')">
                    <router-link
                      v-if="!isMarketingModule"
                      class="external-link"
                      :to="getConnection(ref.id) !== undefined ? redirectToDetail(getConnection(ref.id), '', 'objects') : ''">
                      {{ getConnection(ref.id) !== null ? getConnection(ref.id).name : '-' }}
                    </router-link>
                    <span v-else>
                      {{ getConnection(ref.id) !== null ? getConnection(ref.id).name : '-' }}
                    </span>
                  </span>
                </p>
                <p
                  v-else
                  :key="'else-list-' + ref.id">
                  -
                </p>
              </template>
            </template>
            <template #cell(assignee)="data">
              <template v-if="data.item.assignee !== null">
                {{ data.item.assignee.name }} - <b>{{ data.item.assignee.accountName }}</b>
              </template>
            </template>
            <template #cell(ticketPriority)="data">
              <img
                :src="`/img/priority/prio-${data.item.priority}.svg`"
                :alt="$t('message.ticketOverview.priority.title')"
                height="15px">
            </template>
            <template #cell(ticketExecuteFrom)="data">
              {{ data.item.startDate | objectToDate }}
            </template>
            <template #cell(ticketExecuteUntil)="data">
              {{ data.item.dueDate | objectToDate }}
            </template>
            <template #cell(receivedOpened)="data">
              <div
                v-if="isTicketReferenceInMail(data.item) && data.item.createdAt >= 1709638200"
                class="text-center">
                <template
                  v-if="isTicketReferenceInMail(data.item).deliverData && isTicketReferenceInMail(data.item).deliverData.status && isTicketReferenceInMail(data.item).deliverData.status === 'DELIVER_STATUS_DELIVERED'">
                  <coozzy-success-icon />
                </template>
                <template
                  v-else-if="data.item.deliverData && data.item.deliverData.status === 'DELIVER_STATUS_PENDING'">
                  <coozzy-pending-icon />
                </template>
                <template v-else>
                  <coozzy-fail-icon
                    v-b-tooltip.hover.left
                    :title="isTicketReferenceInMail(data.item).deliverData && isTicketReferenceInMail(data.item).deliverData.status && isTicketReferenceInMail(data.item).deliverData.errorMessage" />
                </template>
                /
                <template
                  v-if="isTicketReferenceInMail(data.item).engagementData && isTicketReferenceInMail(data.item).engagementData.status && isTicketReferenceInMail(data.item).engagementData.status === 'ENGAGEMENT_STATUS_OPENED'">
                  <coozzy-success-icon />
                </template>
                <template v-else>
                  <coozzy-fail-icon />
                </template>
              </div>
              <div
                v-else
                class="text-center">
                -
              </div>
            </template>
            <template #cell(operator)="data">
              <template v-if="data.item.correspondences.length > 0">
                {{ data.item.correspondences[0].name }}
              </template>
              <template v-else>
                -
              </template>
            </template>
          </b-table>
          <b-pagination
            v-model="currentPage"
            align="center"
            class="mb-3"
            :total-rows="totalTickets"
            :per-page="nbrPerPage" />
        </div>
      </div>
    </div>

    <b-modal
      :id="'confirmationModalLinkTicket'"
      ref="confirmationModalLinkTicket"
      no-close-on-backdrop
      hide-footer
      :title="$t('message.marketingMessages.linkMailToTicket.title')">
      <div class="col">
        <p>{{ $t('message.marketingMessages.linkMailToTicket.text') }}</p>
      </div>
      <div class="col pl-0">
        <coozzy-button
          size="small"
          class="mb-0 border"
          design="transparent"
          @click="$bvModal.hide('confirmationModalLinkTicket')">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
        <coozzy-button
          size="small"
          class="float-right mb-0"
          design="green"
          :disabled="disabledLinkBtn"
          @click="linkTickets()">
          {{ $t('message.generic.confirm') }}
        </coozzy-button>
      </div>
    </b-modal>
    <b-modal
      :id="'changeAssigneeModal'"
      ref="changeAssigneeModal"
      no-close-on-backdrop
      hide-footer
      :title="$t('message.ticketOverview.changeAssignee')">
      <div class="col p-0 mb-3">
        <coozzy-multiselect
          v-model="newAssignee"
          :options="employeeList.filter(x=> x.blocked === false)"
          :multiple="false"
          :placeholder=" $t('message.employeesSettings.employees')"
          :close-on-select="true"
          :clear-on-select="false"
          :preserve-search="true"
          :preselect-first="false"
          :taggable="false"
          :custom-label="labelEmployee"
          track-by="id" />
      </div>
      <div class="col p-0">
        <coozzy-button
          size="small"
          class="mb-0 border"
          design="transparent"
          @click="hideChangeAssigneeModal()">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
        <coozzy-button
          size="small"
          class="float-right mb-0"
          design="green"
          @click="editTicketsAssignee()">
          {{ $t('message.generic.confirm') }}
        </coozzy-button>
      </div>
    </b-modal>
    <b-modal
      :id="'changeWatchersModal'"
      ref="changeWatchersModal"
      no-close-on-backdrop
      hide-footer
      :title="$t('message.ticketOverview.startWatching')">
      <div class="col p-0 mb-3">
        <coozzy-multiselect
          v-model="listNewWatchers"
          :options="employeeList.filter(x=> x.blocked === false)"
          :multiple="true"
          :placeholder=" $t('message.employeesSettings.employees')"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          :preselect-first="false"
          :taggable="false"
          :custom-label="labelEmployee"
          track-by="id" />
      </div>
      <div class="col p-0">
        <coozzy-button
          size="small"
          class="mb-0 border"
          design="transparent"
          @click="hideChangeWatchersModal()">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
        <coozzy-button
          size="small"
          class="float-right mb-0"
          design="green"
          @click="editTicketsWatchers()">
          {{ $t('message.generic.confirm') }}
        </coozzy-button>
      </div>
    </b-modal>
    <assemble-list-modal
      ref="assembleListModal"
      modal-id="assembleListModal"
      :fields="selectedFields"
      :default-fields="dataFields"
      @save-fields="saveFields" />
    <b-modal
      v-if="showDeleteTicketModal"
      id="deleteConfirmationModal"
      ref="deleteConfirmationModal"
      :title="$t('message.ticketOverview.deleteTicketModal.title')"
      hide-footer
      no-close-on-backdrop>
      <p>{{ $t('message.ticketOverview.deleteTicketModal.text') }}</p>
      <div class="col">
        <coozzy-button
          class="border mb-0"
          design="transparent"
          size="small"
          @click="hideDeleteConfirmationModal">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
        <coozzy-button
          class="float-right mb-0"
          design="green"
          size="small"
          @click="confirmDeletion">
          {{ $t('message.onBoardingTabs.confirmModal.confirmationButton') }}
        </coozzy-button>
      </div>
    </b-modal>
  </section>
</template>

<script>
import draggable from 'vuedraggable'
import TicketCard from './TicketCard'
import TicketApi from '@/misc/apis/TicketApi'
import { mapActions, mapGetters } from 'vuex'
import { routeChecks } from '@/mixins/routeChecks'
import CoozzyMultiselect from '@/framework/components/multiselect/CoozzyMultiselect'
import CoozzyMultiselectHtmlOption from '@/framework/components/multiselect/CoozzyMultiselectHtmlOption'
import Vue from 'vue'
import CoozzyFormInput from '@/framework/components/form/input/CoozzyFormInput'
import CoozzyFormCheckbox from '@/framework/components/form/checkbox/CoozzyFormCheckbox'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner'
import { user } from '@/mixins/user'
import ObjectApi from '@/misc/apis/ObjectApi'
import ContactApi from '@/misc/apis/ContactApi'
import MessageApi from '@/misc/apis/MessageApi'
import RequestApi from '@/marketingBasic/api/RequestApi'
import { formatting } from '@/mixins/formatting'
import RoomApi from '@/misc/apis/RoomApi'
import UserApi from '@/misc/apis/UserApi'
import CoozzyFormSelect from '@/framework/components/form/select/CoozzyFormSelect.vue'
import ShareApi from '@/misc/apis/ShareApi'
import { algolia } from '@/mixins/algolia'
import CoozzySearchMultiselect from '@/framework/components/multiselect/CoozzySearchMultiselect'
import CoozzyAssigneeMultiselect from '@/framework/components/multiselect/CoozzyAssigneeMultiselect'
import CoozzyButton from '@/framework/components/button/CoozzyButton.vue'
import { tables } from '@/mixins/tables'
import CoozzyDropdown from '@/framework/components/dropdown/CoozzyDropdown.vue'
import { mail } from '@/mixins/mail'
import CoozzyDropdownItem from '@/framework/components/dropdown/CoozzyDropdownItem.vue'
import CoozzySuccessIcon from '@/framework/components/icons/CoozzySuccessIcon.vue'
import { ticket } from '@/mixins/ticket'
import CoozzyFailIcon from '@/framework/components/icons/CoozzyFailIcon.vue'
import CoozzyPendingIcon from '@/framework/components/icons/CoozzyPendingIcon.vue'
import debounce from 'debounce'
import CoozzyFiltersIcon from '@/framework/components/icons/CoozzyFiltersIcon.vue'
import SearchApi from '@/misc/apis/SearchApi'
import AssembleListModal from '@/contact/components/AssembleListModal.vue'

export default {
  name: 'TicketOverviewData',
  components: {
    AssembleListModal,
    CoozzyFiltersIcon,
    CoozzyPendingIcon,
    CoozzyFailIcon,
    CoozzySuccessIcon,
    CoozzyDropdownItem,
    CoozzyDropdown,
    CoozzyButton,
    CoozzyFormSelect,
    CoozzySpinner,
    CoozzyFormCheckbox,
    CoozzyFormInput,
    CoozzyAssigneeMultiselect,
    draggable,
    CoozzyMultiselect,
    CoozzyMultiselectHtmlOption,
    TicketCard,
    CoozzySearchMultiselect
  },
  mixins: [routeChecks, tables, user, formatting, algolia, mail, ticket],
  props: {
    fromHistory: {
      type: Boolean,
      default: false
    },
    mainReferences: {
      type: Array,
      default: () => []
    },
    allReferences: {
      type: Array,
      default: () => []
    },
    defaultEmptyAssignee: {
      type: [Boolean, String],
      default: false
    },
    defaultText: {
      type: String,
      default: ''
    },
    defaultType: {
      type: String,
      default: ''
    },
    defaultStatus: {
      type: String,
      default: ''
    },
    defaultCreator: {
      type: String,
      default: ''
    },
    defaultAssigne: {
      type: String,
      default: ''
    },
    defaultPriority: {
      type: String,
      default: ''
    },
    defaultOverdue: {
      type: String,
      default: ''
    },
    defaultWatched: {
      type: String,
      default: ''
    },
    defaultNewEvent: {
      type: String,
      default: ''
    },
    closedTicket: {
      type: Boolean,
      default: false
    },
    defaultDevice: {
      type: Array,
      default: () => []
    },
    deviceList: {
      type: Array,
      default: () => []
    },
    linkEmail: {
      type: String,
      default: null
    },
    nameLinked: {
      type: String,
      default: null
    },
    defaultOperator: {
      type: String,
      default: ''
    },
    ownerIds: {
      type: String,
      default: ''
    },
    includeClosed: {
      type: Boolean,
      default: false
    },
    referenceIds: {
      type: String,
      default: ''
    },
    draftTicket: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      abortController: null,
      isDataLoaded: {
        object: false,
        building: false,
        property: false,
        filters: false,
        operator: false,
        assignee: false,
        employee: false
      },
      readyForLoad: false,
      mailList: [],
      debounceSearchInput: null,
      disabledLinkBtn: false,
      isFirstLoad: false,
      emptyAssignee: this.defaultEmptyAssignee === 'true' || this.defaultEmptyAssignee,
      searchStatus: [],
      searchPriority: [],
      searchAssignee: [],
      searchCreator: [],
      searchType: [],
      searchDevice: [],
      allConnections: {
        properties: [],
        buildings: [],
        objects: [],
        condominiums: [],
        tenancies: [],
        devices: [],
        contacts: [],
        requests: [],
        rooms: [],
        mortgages: [],
        insurances: []
      },
      listContacts: [],
      searchWatcher: this.defaultWatched === 'true',
      searchDueDate: this.defaultOverdue === 'true',
      searchNewEvent: this.defaultNewEvent === 'true',
      searchText: this.defaultText,
      showEntierProperty: true,
      loading: true,
      dragging: false,
      draggingCollumn: null,
      ticketList: [],
      ticketUnsortedList: [],
      openTickets: [],
      progressTickets: [],
      doneTickets: [],
      selectedTicket: null,
      isColumnView: !this.closedTicket && !this.draftTicket,
      fields: [
        {
          key: 'newEvent',
          label: '',
          thClass: 'th-newEvent',
          tdClass: 'align-middle border-right-0 newEvent-cell',
          visible: true
        },
        {
          key: 'checkbox',
          label: '',
          thClass: 'th-checkbox',
          tdClass: 'align-middle border-right-0 checkbox-cell',
          visible: true
        }
      ],
      dataFields: [
        {
          key: 'type',
          label: this.$t('message.tableColumnHeadings.type'),
          tdClass: 'align-middle d-md-table-cell',
          thClass: 'd-md-table-cell',
          selected: true,
          visible: true
        },
        {
          key: 'ticketKey',
          label: this.$t('message.tableColumnHeadings.ticketKey'),
          tdClass: 'align-middle',
          selected: true,
          visible: true
        },
        {
          key: 'title',
          label: this.$t('message.tableColumnHeadings.title'),
          tdClass: 'align-middle',
          selected: true,
          visible: true
        },
        {
          key: 'ticketConnections',
          label: this.$t('message.tableColumnHeadings.ticketConnections'),
          tdClass: 'align-middle d-md-table-cell',
          thClass: 'd-md-table-cell',
          selected: true,
          visible: true
        },
        {
          key: 'ticketPriority',
          label: this.$t('message.tableColumnHeadings.ticketPriority'),
          tdClass: 'align-middle text-center d-sm-table-cell',
          thClass: 'd-sm-table-cell text-center',
          selected: true,
          visible: true
        },
        {
          key: 'assignee',
          label: this.$t('message.tableColumnHeadings.assignee'),
          tdClass: 'align-middle d-lg-table-cell',
          thClass: 'd-lg-table-cell',
          selected: true,
          visible: true
        },
        {
          key: 'ticketExecuteFrom',
          label: this.$t('message.tableColumnHeadings.ticketExecuteFrom'),
          tdClass: 'align-middle d-md-table-cell',
          thClass: 'd-md-table-cell',
          selected: true,
          visible: true
        },
        {
          key: 'ticketExecuteUntil',
          label: this.$t('message.tableColumnHeadings.ticketExecuteUntil'),
          tdClass: 'align-middle d-md-table-cell',
          thClass: 'd-md-table-cell',
          selected: true,
          visible: true
        },
        {
          key: 'receivedOpened',
          label: this.$t('message.tableColumnHeadings.receivedOpened'),
          tdClass: 'align-middle text-center d-sm-table-cell',
          thClass: 'd-sm-table-cell text-center',
          selected: true,
          visible: true
        },
        {
          key: 'status',
          label: this.$t('message.tableColumnHeadings.status'),
          tdClass: 'align-middle',
          selected: true,
          visible: !this.closedTicket
        },
        {
          key: 'operator',
          label: this.$t('message.tableColumnHeadings.operator'),
          tdClass: 'align-middle text-center d-sm-table-cell',
          thClass: 'd-sm-table-cell text-center',
          selected: false,
          visible: true
        }
      ],
      selectedFields: [],
      searchProperty: [],
      searchBuilding: [],
      searchObject: [],
      searchOwner: [],
      involvedPeople: [],
      currentPage: 1,
      nbrPerPage: 5,
      currentEmployeeList: [],
      listAllEmployees: [],
      availableTypeOptions: ['TICKET_TYPE_TASK', 'TICKET_TYPE_ORDER'],
      availableStatusOptions: ['TICKET_STATUS_OPEN', 'TICKET_STATUS_IN_PROGRESS', 'TICKET_STATUS_DONE', 'TICKET_STATUS_CLOSED'],
      availablePriorityOptions: ['LOW', 'DEFAULT', 'HIGH'],
      ownerAccounts: [],
      totalTickets: 0,
      referencesOwners: [],
      selectedEntries: [],
      showChangeAssignee: false,
      showChangeWatchers: false,
      newAssignee: null,
      listNewWatchers: [],
      selectAllVisibleEntries: false,
      selectAllEntries: false,
      allEntries: [],
      isFromProperty: true,
      showDeleteTicketModal: false
    }
  },
  computed: {
    readyForLoadValue() {
      return this.readyForLoad
    },
    selectedEntriesText() {
      const count = this.selectAllEntries && this.selectAllVisibleEntries ? this.totalTickets : this.selectedEntries.length
      return this.$t('message.generic.selectedEntries') + ':  ' + count
    },
    filterQueryOperator() {
      return 'isOwner:false AND active:true AND isEmployee:false AND isOnlyInterestedParty:false'
    },
    computedAllConnections() {
      return this.allConnections
    },
    isFiltered() {
      return this.searchText !== '' ||
      this.searchWatcher === true ||
      this.searchDueDate === true ||
      this.searchNewEvent === true ||
      this.searchPriority.length > 0 ||
      this.searchAssignee.length > 0 ||
      this.emptyAssignee === true ||
      this.searchCreator.length > 0 ||
      this.searchType.length > 0 ||
      this.searchStatus.length > 0 ||
      this.searchDevice.length > 0 ||
      this.searchProperty.length > 0 ||
      this.searchBuilding.length > 0 ||
      this.searchObject.length > 0 ||
      this.searchOwner.length > 0 ||
      this.involvedPeople.length > 0
    },
    allListContacts() {
      return this.listContacts
    },
    employeeFilteredListWithSystem () {
      const originalList = JSON.parse(JSON.stringify(this.employeeFilteredList))
      const system = {
        label: 'system',
        value: 'system',
        active: true,
        company: '',
        profile: null,
        accountId: this.accountId
      }
      return [system].concat(originalList)
    },
    employeeFilteredList() {
      if (this.listAllEmployees.length > 0) {
        const list = []
        this.listAllEmployees.forEach(element => {
          const isSupportUser = element.value?.email ? element.value.email.startsWith('support.') && element.value.email.endsWith('@my-prop.ch') : false
          if (!isSupportUser) {
            list.push({
              label: element.label,
              value: element.value.id,
              active: !element.value.blocked,
              company: element.company,
              profile: element.value.profile,
              accountId: element.value.accountId
            })
          }
        })
        const customSort = (a, b) => {
          // Sort by 'active' status first (true comes before false)
          if (a.active !== b.active) {
            return a.active ? -1 : 1
          }
          // sort first by accountId
          if (parseInt(a.accountId) === parseInt(this.$store.getters['user/getAccountId'])) {
            return -1
          }
          if (parseInt(b.accountId) === parseInt(this.$store.getters['user/getAccountId'])) {
            return 1
          }
        }
        return list.sort((a, b) => {
          // sort by lastname and then with firstname
          if (a.profile.lastName.toLowerCase() < b.profile.lastName.toLowerCase()) { return 1 }
          if (a.profile.lastName.toLowerCase() > b.profile.lastName.toLowerCase()) { return -1 }
          if (a.profile.firstName.toLowerCase() < b.profile.firstName.toLowerCase()) { return 1 }
          if (a.profile.firstName.toLowerCase() > b.profile.firstName.toLowerCase()) { return -1 }
          // If both are active or both are inactive, sort by 'company'
          return a.company.localeCompare(b.company)
        }).sort(customSort)
      }
      return []
    },
    sortedOpenTicket: {
      get() {
        return this.openTickets
      },
      set(value) {
        if (value) {
          this.openTickets = value
        }
      }
    },
    sortedProgressTicket: {
      get() {
        return this.progressTickets
      },
      set(value) {
        if (value) {
          this.progressTickets = value
        }
      }
    },
    sortedDoneTicket: {
      get() {
        return this.doneTickets
      },
      set(value) {
        if (value) {
          this.doneTickets = value
        }
      }
    },
    availableOwnerOptions() {
      if (this.referencesOwners.length > 0) {
        const list = []
        this.referencesOwners.filter(x => x.subType?.toLowerCase() === 'owner').forEach(element => {
          let name = ''
          if (element.company) {
            name = element.company.name
          } else if (element.individual) {
            name = element.individual.firstName.substr(0, 1) + '.' + element.individual.lastName
          }
          list.push({
            value: element.id,
            company: name
          })
        })
        return list.sort((a, b) => (a.company === null ? 0 : a.company > b.company ? 1 : -1))
      }
      return []
    },
    employeeList() {
      if (this.getEmployees().length > 0) {
        return this.getEmployees()
      }
      return []
    },
    filtredPropertyOptions() {
      if (this.allConnections.properties.length > 0) {
        const listOwners = this.searchOwner.flatMap(x => x.value) || []
        return this.allConnections.properties.filter(x => listOwners.length > 0 ? listOwners.includes(x.ownerId) : x)
      }
      return []
    },
    filtredBuildingOptions() {
      if (this.allConnections.buildings.length > 0) {
        let list = this.allConnections.buildings
        if (this.searchOwner.length > 0) {
          const listOwners = this.searchOwner.flatMap(x => x.value) || []
          list = list.filter(x => listOwners.length > 0 ? listOwners.includes(x.ownerId) : x)
        }
        if (this.searchProperty.length > 0) {
          const buildingIds = this.searchProperty.flatMap(x => x.buildingIds)
          list = list.filter(x => buildingIds.includes(x.id))
        }
        if (this.fromHistory) {
          const ticketReferencesIds = this.ticketList.flatMap(x => x.references.flatMap(y => [y.id].concat(y.relatedIds)).flat())
          return list.filter(x => ticketReferencesIds.includes(x.id))
        }
        return list
      }
      return []
    },
    filtredObjectOptions() {
      if (this.allConnections.objects.length > 0) {
        let list = this.allConnections.objects
        if (this.searchOwner.length > 0) {
          const listOwners = this.searchOwner.flatMap(x => x.value) || []
          list = list.filter(x => listOwners.length > 0 ? listOwners.includes(x.ownerId) : x)
        }
        if (this.searchBuilding.length > 0) {
          const objectIds = this.searchBuilding.flatMap(x => x.objectIds)
          list = list.filter(x => objectIds.includes(x.id))
        }
        if (this.fromHistory) {
        const ticketReferencesIds = this.ticketList.flatMap(x => x.references.flatMap(y => [y.id].concat(y.relatedIds)).flat())
        // console.log('eeeee' + ticketReferencesIds)
          return list.filter(x => ticketReferencesIds.includes(x.id))
        }
        return list
      }
      return []
    },
    combinedValues() {
      return [
        this.showEntierProperty,
        this.isColumnView
      ]
    },
    isAllDataLoaded() {
      // check if every endpoint and loading with algolia is finished, so we can change in the rulr
      return this.isDataLoaded.filters && this.isDataLoaded.operator && this.isDataLoaded.assignee && this.isDataLoaded.object && this.isDataLoaded.building && this.isDataLoaded.property
    }
  },
  watch: {
    isFiltered: function (newVal) {
      this.$emit('is-filtered', newVal)
    },
    combinedValues: {
      immediate: true,
      deep: true,
      handler: function () {
        if (this.isAllDataLoaded) {
          this.loadTickets()
        }
      }
    },
    readyForLoadValue: function (newVal) {
      if (newVal === true) {
        this.loadTickets()
        this.readyForLoad = false
      }
    },
    searchAssignee: function (newVal, oldVal) {
      if (newVal.length === 0 && oldVal.length > 0) {
        this.emptyAssignee = true
      }
      const newString = newVal.map(e => e.value).join(',')
      if (this.$route.path.includes('tickets/closed')) {
        if (newVal.length > 0) {
          localStorage.setItem('filterClosedAssigneeTicketOverview', newString)
        } else {
          localStorage.removeItem('filterClosedAssigneeTicketOverview')
        }
      } else {
        if (newVal.length > 0) {
          localStorage.setItem('filterAssigneeTicketOverview', newString)
        } else {
          localStorage.removeItem('filterAssigneeTicketOverview')
        }
      }

      this.getCountMyUnreadTickets(true)
    },
    currentPage: function () {
      this.handleCheckboxesInsideTable(false)
      this.loadTickets()
    },
    nbrPerPage: function () {
      if (!this.isColumnView) {
        this.handleCheckboxesInsideTable(false)
        this.loadTickets()
      }
    },
    selectAllEntries: async function (newValue) {
      if (newValue) {
        this.handleCheckboxesInsideTable(newValue)
        if (this.totalTickets !== this.allEntries.length) {
          this.loadTickets(true)
        }
      }
    },
    ticketList: function (newVal) {
      newVal.some(ticket => ticket.readStatus && !ticket.readStatus.assignee) ? this.$emit('has-notification', true) : this.$emit('has-notification', false)
    },
    availableOwnerOptions: function (newVal) {
      if (newVal.length > 0) {
        const routeQuery = this.$route.query
        const splitOwnersId = this.ownerIds !== '' ? this.ownerIds.split(',') : routeQuery.ownerIds ? routeQuery.ownerIds : []
        this.searchOwner = this.availableOwnerOptions.filter(x => splitOwnersId.includes(x.value))
      }
    }
  },
  async beforeMount() {
    await this.loadEmployees()
    this.getAllNetworkPartner()

    this.debounceSearchInput = debounce(() => {
      this.changeTicketUrl()
      this.loadTickets()
    }, 300)
  },
  mounted() {
      if (!this.$route.name?.includes('PropertyDetailsView')) {
        this.isFromProperty = false
      }
      this.$watch(vm => [vm.searchWatcher,
      vm.searchNewEvent,
      vm.searchDueDate,
      vm.searchPriority,
      vm.searchAssignee,
      vm.searchCreator,
      vm.involvedPeople,
      vm.searchType,
      vm.searchStatus,
      vm.searchDevice,
      vm.searchOwner,
      vm.searchProperty,
      vm.searchBuilding,
      vm.searchObject,
      vm.isAllDataLoaded], {
        immediate: true,
        handler: function (newVal, oldVal) {
          if (this.isAllDataLoaded && !this.objectsAreSame(newVal, oldVal)) {
            if (!this.fromHistory && (newVal.isAllDataLoaded || newVal.isAllDataLoaded === oldVal.isAllDataLoaded)) {
              this.changeTicketUrl()
            } else {
              this.loadTickets()
            }
          }
        }
      })
    this.loadInstantSearch()
    // adapt columns table list ticket
    this.fields = this.fields.filter(field => field.visible)
    this.dataFields = this.dataFields.filter(x => x.selected).concat(this.dataFields.filter(x => !x.selected))
    const src = this.fromHistory ? 'list_ticket_history' : 'list_ticket_overview'
    SearchApi.loadSettings(this.accountId).then(response => {
      const settings = response.tableSettings
      let hasOldList = false
      if (settings.length > 0) {
        settings.forEach(setting => {
          if (setting.columns.length > 0 && setting.table === src) {
            hasOldList = true
            this.loadFields(setting)
          }
        })
      }
      if (hasOldList === false) {
        this.fields.push(...this.dataFields.filter(x => x.selected))
        this.selectedFields = JSON.parse(JSON.stringify(this.dataFields))
      }
    }).catch(e => {
      console.log(e)
    })
    if (this.mainReferences.length > 0) {
      this.isColumnView = false
    }
    this.getPropertiesOptions()
    this.getBuildingsOptions()
    this.getObjectsOptions()
    if (this.linkEmail) {
      MessageApi.listMailsByIds([this.linkEmail])
        .then(response => {
          this.email = response.mails[0]
        })
    }
    const routeQuery = this.$route.query
    this.searchType = this.availableTypeOptions.filter(x => this.defaultType.includes(x) || routeQuery.defaultType === x)
    this.searchStatus = this.availableStatusOptions.filter(x => this.defaultStatus.includes(x))
    this.searchDevice = this.deviceList.filter(x => this.defaultDevice.length > 0 ? this.defaultDevice[0].value === x.value : false)
    this.searchPriority = this.availablePriorityOptions.filter(x => this.defaultPriority.includes(x) || routeQuery.defaultPriority === x)
    this.searchDueDate = routeQuery.defaultOverdue === 'true'
    this.searchNewEvent = routeQuery.defaultNewEvent === 'true'
    this.searchWatcher = routeQuery.defaultWatched === 'true'
    this.searchText = this.defaultText !== '' ? this.defaultText : routeQuery.defaultText ? routeQuery.defaultText : ''
    this.isDataLoaded.filters = true
    if (this.defaultOperator !== '' || routeQuery.defaultOperator) {
      if (this.searchClient && (this.defaultOperator !== '' || routeQuery.defaultOperator)) {
        const index = this.searchClient.initIndex('contact')
        let filter = ''
        const splitOperatorIds = this.defaultOperator !== '' ? this.defaultOperator.split(',') : routeQuery.defaultOperator.split(',')
        splitOperatorIds.forEach(id => {
          filter += `objectID:${id}`
          if (splitOperatorIds.indexOf(id) < splitOperatorIds.length - 1) {
            filter += ' OR '
          }
        })
        index
          .search('', {
            filters: filter
          })
          .then(({ hits }) => {
            this.involvedPeople = hits
            this.isDataLoaded.operator = true
          })
      } else {
        this.isDataLoaded.operator = true
      }
    } else {
      this.isDataLoaded.operator = true
    }
    setTimeout(() => {
      this.$refs.searchText?.setFocusInput()
    }, 300)
  },
  methods: {
    ...mapActions('employee', ['loadEmployees']),
    ...mapGetters('employee', ['getEmployees']),
    deleteTicketClicked() {
      this.showDeleteTicketModal = true
      this.$nextTick(() => {
        this.$refs.deleteConfirmationModal.show()
      })
    },
    hideDeleteConfirmationModal() {
      this.$bvModal.hide('deleteConfirmationModal')
      this.showDeleteTicketModal = false
    },
    confirmDeletion() {
      const promise = []
      this.selectedEntries.forEach(element => {
        promise.push(TicketApi.deleteTicket(element.id))
      })
      Promise.all(promise)
        .then(() => {
          this.hideDeleteConfirmationModal()
          this.loadTickets()
          Vue.toasted.show(this.$t('message.successMessages.deleteTicket'), { type: 'success' })
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.deleteErrors.ticket'), { type: 'error' })
        })
    },
    openAssembleListModal() {
      this.$bvModal.show('assembleListModal')
    },
    loadFields(setting) {
      const result = this.getFields(this.dataFields, setting, true, true)
      this.fields = result?.fields
      this.selectedFields = result?.selectedFields
      this.$refs.ticketOverviewTable?.refresh()
    },
    saveFields(data) {
      const columns = data.filter(x => x.selected === true).map(y => y.key)
      const src = this.fromHistory ? 'list_ticket_history' : 'list_ticket_overview'
      SearchApi.saveSettings(this.accountId, columns, src).then(response => {
        const setting = response.tableSettings
        if (setting && setting.columns.length > 0) {
          this.loadFields(setting)
        }
        this.$bvModal.hide('assembleListModal')
      })
    },
    setMortgageId(mortgage, middleClick = false) {
      const source = mortgage.referenceId.startsWith('building') ? 'building' : 'property'
      if (source === 'building') {
        localStorage.setItem('expandBuildingMortgageId', mortgage.id)
      } else if (source === 'property') {
        localStorage.setItem('expandPropertyMortgageId', mortgage.id)
      }
      this.redirectToParentDetail(mortgage, middleClick)
    },
    setInsuranceId(insurance, middleClick = false) {
      const source = insurance.referenceId.startsWith('building') ? 'building' : 'property'
      if (source === 'building') {
        localStorage.setItem('expandBuildingInsuranceId', insurance.id)
      } else if (source === 'property') {
        localStorage.setItem('expandPropertyInsuranceId', insurance.id)
      }
      this.redirectToParentDetail(insurance, middleClick)
    },
    objectsAreSame(x, y) {
      let objectsAreSame = true
      if (x && y) {
        for (let propertyName in x) {
          if (x[propertyName] !== y[propertyName]) {
            objectsAreSame = false
            break
          }
        }
      }
      return objectsAreSame
    },
    itemSelected(element, checked) {
      if (checked && !this.selectedEntries.includes(element)) {
        this.selectedEntries.push(element)
        if (this.selectedEntries.length === this.nbrPerPage) {
          this.selectAllVisibleEntries = true
        }
      } else {
        this.selectedEntries = this.selectedEntries.filter(entry => entry.id !== element.id)
        this.selectAllEntries = false
        this.selectAllVisibleEntries = false
      }
    },
    clearCheckbox() {
      this.selectedEntries = []
      if (this.$refs.header_checkbox) {
        this.$refs.header_checkbox.checked = false
      }
      this.handleCheckboxesInsideTable(false)
    },
    handleCheckboxesInsideTable(value) {
      this.$nextTick(() => {
        if (this.$refs.ticketOverviewTable) {
          const allCheckboxesComponents = this.$refs.ticketOverviewTable.$children[1].$children
          this.handleCheckboxInSecondNode(allCheckboxesComponents, value)
          this.selectAllVisibleEntries = value
          if (value === false) {
            this.selectAllEntries = false
          }
        }
      })
    },
    showModalChangeAssignee() {
      this.newAssignee = null
      this.showChangeAssignee = true
      this.$refs.changeAssigneeModal.show()
    },
    hideChangeAssigneeModal() {
      this.newAssignee = null
      this.showChangeAssignee = false
      this.$refs.changeAssigneeModal.hide()
    },
    showModalChangeWatchers() {
      this.listNewWatchers = []
      this.showChangeWatchers = true
      this.$refs.changeWatchersModal.show()
    },
    hideChangeWatchersModal() {
      this.listNewWatchers = []
      this.showChangeWatchers = false
      this.$refs.changeWatchersModal.hide()
    },
    labelEmployee(item) {
      return item.profile.firstName + ' ' + item.profile.lastName
    },
    editTicketsAssignee() {
      let promises = []
      const listEntries = this.selectAllEntries ? this.allEntries : this.selectedEntries
      listEntries.forEach(element => {
        element.assignee = {
          accountId: this.newAssignee.accountId,
          userId: this.newAssignee.id
        }
        promises.push(TicketApi.updateTicket(element))
      })
      Promise.all(promises)
        .then(() => {
          Vue.toasted.show(this.$t('message.successMessages.ticket'), { type: 'success' })
          this.hideChangeAssigneeModal()
          this.loadTickets()
        })
        .catch(e => {
          console.log(e)
          this.hideChangeAssigneeModal()
          Vue.toasted.show(this.$t('message.savingErrors.ticket'), { type: 'error' })
        })
    },
    editTicketsWatchers() {
      let promises = []
      const listEntries = this.selectAllEntries ? this.allEntries : this.selectedEntries
      listEntries.forEach(element => {
          const listWatchers = []
          const oldWatchers = element.watchers.map(x => x.userId)
          this.listNewWatchers.forEach(element => {
            if (!oldWatchers.includes(element.id)) {
              listWatchers.push({
                accountId: element.accountId,
                userId: element.id
              })
            }
          })
          element.watchers = element.watchers.concat(listWatchers)
          promises.push(TicketApi.updateTicket(element))
        })
        Promise.all(promises)
          .then(() => {
            Vue.toasted.show(this.$t('message.successMessages.ticket'), { type: 'success' })
            this.hideChangeWatchersModal()
            this.loadTickets()
          })
          .catch(e => {
            console.log(e)
            this.hideChangeWatchersModal()
            Vue.toasted.show(this.$t('message.savingErrors.ticket'), { type: 'error' })
          })
    },
    getReferenceName(id) {
      let name = ''
      if (id.startsWith('device')) {
        const device = this.getConnection(id)
        if (device.referenceId.startsWith('prop')) {
          this.allConnections.properties.forEach(property => {
            if (property.id === device.referenceId) {
              name = property.name
            }
          })
        } else if (device.referenceId.startsWith('building')) {
          this.allConnections.buildings.forEach(building => {
            if (building.id === device.referenceId) {
              name = building.name
            }
          })
        } else if (device.referenceId.startsWith('obj')) {
          this.allConnections.objects.forEach(object => {
            if (object.id === device.referenceId) {
              name = object.name
            }
          })
        }
      }
      if (id.startsWith('mortgage') || id.startsWith('ins')) {
        const item = this.getConnection(id)
        if (item.referenceId.startsWith('prop')) {
          this.allConnections.properties.forEach(property => {
            if (property.id === item.referenceId) {
              name = property.name
            }
          })
        } else if (item.referenceId.startsWith('building')) {
          this.allConnections.buildings.forEach(building => {
            if (building.id === item.referenceId) {
              name = building.name
            }
          })
        }
      }
      return name
    },
    arrayHaveSameValues(arr1, arr2) {
      // Check if arrays have different lengths
      if (arr1.length !== arr2.length) {
          return false
      }
      // Sort the arrays
      arr1.sort()
      arr2.sort()
      // Compare each element of the sorted arrays
      for (let i = 0; i < arr1.length; i++) {
          if (arr1[i] !== arr2[i]) {
              return false
          }
      }
      // If all elements match, arrays have the same values
      return true
    },
    async loadTickets(all = false) {
      // Cancel the previous request, if there is one
      if (this.abortController) {
        this.abortController.abort()
        this.loading = !all
      }

      // Create a new AbortController
      this.abortController = new AbortController()

      try {
        if (!all) {
          this.loading = true
          this.selectedEntries = []
          this.selectAllVisibleEntries = false
          this.selectAllEntries = false
        }

        // Only keep the last element if there's more than one
        if (this.searchPriority.length > 1) {
          this.searchPriority = [this.searchPriority.pop()]
        }
        if (this.searchType.length > 1) {
          this.searchType = [this.searchType.pop()]
        }

        let referenceIds = []
        if (this.searchObject.length > 0) {
          referenceIds = [...referenceIds, ...this.searchObject]
        } else if (this.searchBuilding.length > 0) {
          referenceIds = [...referenceIds, ...this.searchBuilding]
        } else if (this.searchProperty.length > 0) {
          referenceIds = [...referenceIds, ...this.searchProperty]
        }
        const params = {
          closedOnly: this.closedTicket,
          includeClosed: this.includeClosed,
          query: this.defaultText !== '' ? this.defaultText : this.searchText || '',
          types: this.defaultType !== '' ? [this.defaultType] : this.searchType[0] || [],
          statuses: this.draftTicket ? ['TICKET_STATUS_DRAFT'] : this.defaultStatus !== '' ? [this.defaultStatus] : this.searchStatus || [],
          priorities: this.defaultPriority !== '' ? [this.defaultPriority] : this.searchPriority[0] || [],
          overdueOnly: this.defaultOverdue === 'true' ? true : this.searchDueDate === true,
          watchingOnly: this.defaultWatched === 'true' ? true : this.searchWatcher === true,
          newEvent: this.defaultNewEvent === 'true' ? true : this.searchNewEvent === true,
          ownerIds: this.ownerIds !== '' ? this.ownerIds.split(',') : this.searchOwner.flatMap(x => x.value) || [],
          assigneeUserIds: this.defaultAssigne !== '' ? this.defaultAssigne.split(',') : this.searchAssignee.flatMap(x => x.value) || [],
          creatorUserIds: this.defaultCreator !== '' ? this.defaultCreator.split(',') : this.searchCreator.flatMap(x => x.value) || [],
          involvedContactIds: this.defaultOperator !== '' ? this.defaultOperator.split(',') : this.involvedPeople.flatMap(x => x.objectID) || [],
          referenceIds: typeof this.defaultDevice === 'string' && this.defaultDevice !== '' ? this.defaultDevice.split(',') : this.defaultDevice.length > 0 && this.arrayHaveSameValues(this.searchDevice, this.defaultDevice) ? this.defaultDevice.map(x => x.value) : this.searchDevice.length > 0 ? this.searchDevice.map(x => x.value) : referenceIds.length > 0 ? referenceIds.map(x => x.id) : this.mainReferences.concat(this.allReferences.filter(v => v.startsWith('device') || v.startsWith('room'))) || []
        }
        if (!this.draftTicket && !this.closedTicket && params.statuses.length === 0) {
          params.statuses = ['TICKET_STATUS_OPEN', 'TICKET_STATUS_IN_PROGRESS', 'TICKET_STATUS_DONE']
          if (this.fromHistory || this.includeClosed) {
            params.statuses.push('TICKET_STATUS_CLOSED')
          }
        }
        // if all tickets are to be loaded, add specific parameters
        if (all) {
          params.referenceIds = referenceIds.map(x => x.id).concat(this.mainReferences).concat(this.allReferences.filter(v => v.startsWith('device') || v.startsWith('room'))) || []
        } else {
          if (!this.isColumnView) {
            params.page = this.currentPage
            params.itemsPerPage = this.nbrPerPage
          }
        }
        if (this.mainReferences.length > 0) {
          if (this.mainReferences[0].startsWith('obj')) {
            params.directReferenceIdsOnly = false
          } else {
            params.directReferenceIdsOnly = !this.showEntierProperty
          }
        }
        const response = await TicketApi.listMyTickets(params, { signal: this.abortController.signal })
        // If the request was cancelled, don't update the messages
        if (this.abortController.signal.aborted) {
          return
        }
        if (response) {
            if (!Array.isArray(response.tickets)) {
              throw new Error('Tickets is not an array')
            }
            if (all) {
              this.allEntries = response.tickets
            } else {
              const list = response.tickets
              this.mailList = []
              if (list.filter(y => y.type === 'TICKET_TYPE_ORDER').length > 0) {
                this.loadMails(list.filter(y => y.type === 'TICKET_TYPE_ORDER').map(m => m.id))
              }

              this.totalTickets = response.totalTickets
              this.ticketList = list
              this.loadConnections(list)
              this.$emit('ticket-length', this.totalTickets)
              this.ticketUnsortedList = list
              this.openTickets = this.ticketList.filter(
                entry => entry.status === 'TICKET_STATUS_OPEN'
              )
              this.progressTickets = this.ticketList.filter(
                entry => entry.status === 'TICKET_STATUS_IN_PROGRESS'
              )
              this.doneTickets = this.ticketList.filter(
                entry => entry.status === 'TICKET_STATUS_DONE'
              )
            }
          }
      } catch (error) {
        this.loading = true
        if (error.name === 'CanceledError') {
          console.log('Request cancelled:', error.message)
        } else {
          console.error(error)
          Vue.toasted.show(this.$t('message.loadingErrors.ticket'), { type: 'error' })
        }
      } finally {
        this.loading = false
      }
    },
    see(refine, value) {
      refine(value)
    },
    isTicketReferenceInMail(ticket) {
      // Check the mail recipient TO and compare it to operator email. If there are still more than 1 then use the one with lowest timestamp. That way we get the first email sent to the operator which this status should represent
      if (ticket.type === 'TICKET_TYPE_ORDER') {
        const arr = this.mailList.filter(x => x.referenceId.includes(ticket.id)).sort((a, b) => {
            return (b.timestamp * 1000) - (a.timestamp * 1000)
        })
        return arr.length > 0 ? arr[0] : null
      }
      return null
    },
    loadMails(referenceIds) {
      if (referenceIds.length > 0) {
        MessageApi.getDeliveryAndEngagementData(referenceIds)
          .then(response => {
            this.mailList = response.entries
          }).catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.message'), { type: 'error' })
        })
      }
    },
    getAllNetworkPartner() {
      if (this.subType && this.subType === 'owner') {
        ShareApi.listPartners(this.$store.getters['user/getAccountId'])
          .then(response => {
            if (response.targetAccountIds.length > 0) {
              this.getListEmployees(response.targetAccountIds)
            } else {
              this.addAvailableEmployees()
            }
          })
          .catch(e => {
            console.log(e)
            Vue.toasted.show(this.$t('message.loadingErrors.contacts'), { type: 'error' })
          })
      } else if (this.subType && this.subType.toLowerCase().includes('asset')) {
        ShareApi.listPartnersOfAsset(this.$store.getters['user/getAccountId'])
          .then(response => {
            if (response.partnerAccountIds.length > 0) {
              this.getListEmployees(response.partnerAccountIds)
            } else {
              this.addAvailableEmployees()
            }
          })
          .catch(e => {
            console.log(e)
            Vue.toasted.show(this.$t('message.loadingErrors.contacts'), { type: 'error' })
          })
      } else {
        ShareApi.listOwners(this.$store.getters['user/getAccountId'])
          .then(response => {
            if (response.sourceAccountIds.length > 0) {
              this.getListEmployees(response.sourceAccountIds)
              UserApi.listAccountsByIds(response.sourceAccountIds)
                .then(accounts => {
                  this.referencesOwners = accounts.accounts
                })
            } else {
              this.addAvailableEmployees()
            }
          })
          .catch(e => {
            console.log(e)
            Vue.toasted.show(this.$t('message.loadingErrors.contacts'), { type: 'error' })
          })
      }
    },
    getListEmployees(listIds) {
      TicketApi.listTicketingEnabled(listIds).then(rsp => {
        UserApi.listAllEmployees(rsp.enabledAccountIds).then(response1 => {
          this.currentEmployeeList = response1.employees
          if (this.currentEmployeeList.length > 0) {
            const ids = [...new Set(this.currentEmployeeList.map(x => x.accountId).concat(this.employeeList.map(x => x.accountId)))]
            UserApi.listAccountsByIds(ids)
              .then(accounts => {
                this.ownerAccounts = accounts.accounts
                this.addAvailableEmployees()
              })
          } else {
            this.addAvailableEmployees()
          }
        })
      })
    },
    addAvailableEmployees() {
      const array = []
      if (this.employeeList.length !== 0) {
        this.employeeList.filter(x => (x.deleted === false)).forEach((employee) => {
          const accountName = this.ownerAccounts.find(x => x.id === employee.accountId)
          array.push({
            label: (employee.profile.firstName ? employee.profile.firstName + ' ' : '') + employee.profile.lastName,
            value: employee,
            company: (accountName ? ' ' + (accountName.company ? accountName.company?.name : accountName.individual.firstName + ' ' + accountName.individual.lastName) : '')
          })
        })
      }
      if (this.currentEmployeeList.length !== 0) {
        this.currentEmployeeList.filter(x => (x.deleted === false)).forEach((employee) => {
          const accountName = this.ownerAccounts.find(x => x.id === employee.accountId)
          array.push({
            label: ((employee.profile.firstName ? employee.profile.firstName + ' ' : '') + employee.profile.lastName),
            value: employee,
            company: (accountName ? ' ' + (accountName.company ? accountName.company?.name : accountName.individual.firstName + ' ' + accountName.individual.lastName) : '')
          })
        })
      }
      const ids = array.map(o => o.value.id)
      const filtered = array.filter(({ value }, index) => !ids.includes(value.id, index + 1))
      const accountUsers = filtered.filter(x => parseInt(x.value.accountId) === this.accountId).sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1))
      const otherUsers = filtered.filter(x => parseInt(x.value.accountId) !== this.accountId).sort((a, b) => (a.company > b.company ? 1 : -1))
      this.listAllEmployees = accountUsers.concat(otherUsers)
      /**
           * Sets the value of `searchAssignee` based on the conditions.
           * If `defaultEmptyAssignee` is true, `searchAssignee` is set to an empty array.
           * If `defaultAssigne` is not empty, `searchAssignee` is filtered based on the values in `defaultAssigne`.
           * If `isOwnerModule` is true, `searchAssignee` is set to an empty array.
           * If `employeeFilteredList` is not empty, `searchAssignee` is set to an array containing the employee with `userId`.
           * Otherwise, `searchAssignee` is set to an empty array.
           */

      if (localStorage.getItem('filterAssigneeTicketOverview') || localStorage.getItem('filterClosedAssigneeTicketOverview')) {
        const list = this.$route.path.includes('tickets/closed') ? localStorage.getItem('filterClosedAssigneeTicketOverview')?.split(',') : localStorage.getItem('filterAssigneeTicketOverview')?.split(',')
        if (list) {
          list.forEach(userId => {
            if (userId === 'unassigned') {
              this.searchAssignee.push({
                accountId: '',
                active: true,
                company: '',
                label: 'unassigned',
                profile: null,
                value: 'unassigned'
              })
            } else {
              const user = this.employeeFilteredList.find(x => x.value === userId)
              if (user) {
                this.searchAssignee.push(user)
              }
            }
          })
        }
      } else if (this.defaultEmptyAssignee) {
        this.searchAssignee = []
      } else if (this.defaultAssigne !== '') {
        this.searchAssignee = this.employeeFilteredList.filter(x => this.defaultAssigne.split(',').includes(x.value))
      } else if (this.isOwnerModule) {
        this.searchAssignee = []
      } else if (this.employeeFilteredList.length > 0) {
        const user = this.employeeFilteredList.find(x => x.value === this.userId)
        if (user) {
          this.searchAssignee.push(user)
        }
      } else {
        this.searchAssignee = []
      }
      const routeQuery = this.$route.query
      const defaultCreator = routeQuery.defaultCreator ? routeQuery.defaultCreator.split(',') : []
      this.searchCreator = this.employeeFilteredListWithSystem.filter(x => this.defaultCreator.includes(x.value) || defaultCreator.includes(x.value))
      this.isDataLoaded.assignee = true
    },
    getPropertiesOptions() {
      ObjectApi.propertyListFilterOptions()
        .then(response => {
          this.allConnections.properties = response.options
          this.searchProperty = this.allConnections.properties.filter(x => this.allReferences.includes(x.id) || this.referenceIds.split(',').includes(x.id))
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.generic'), { type: 'error' })
        })
        .finally(() => {
          this.isDataLoaded.property = true
        })
    },
    getBuildingsOptions() {
      ObjectApi.buildingListFilterOptions()
        .then(response => {
          this.allConnections.buildings = response.options
          this.searchBuilding = this.allConnections.buildings.filter(x => this.allReferences.includes(x.id) || this.referenceIds.split(',').includes(x.id))
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.generic'), { type: 'error' })
        })
        .finally(() => {
          this.isDataLoaded.building = true
        })
    },
    getObjectsOptions() {
      ObjectApi.objectListFilterOptions(this.$store.getters['user/getAccountId'])
        .then(response => {
          this.allConnections.objects = response.options
          this.searchObject = this.allConnections.objects.filter(x => this.allReferences.includes(x.id) || this.referenceIds.split(',').includes(x.id))
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.generic'), { type: 'error' })
        })
        .finally(() => {
          this.isDataLoaded.object = true
        })
    },
    redirectToRequestDetail(objectId, requestId) {
      return {
        name: 'MarketingObjectDetailsView',
        params: { id: objectId },
        query: {
          view: 0,
          elementSelected: requestId
        }
      }
    },
    searchInputText() {
      this.debounceSearchInput()
    },
    loadConnections(tickets) {
      const arrCondo = []
      const arrTenancy = []
      const arrContact = []
      const arrDevice = []
      const arrRequest = []
      const arrOwner = []
      const arrRoom = []
      const arrMortgage = []
      const arrInsurance = []

      tickets.forEach(element => {
        for (let index = 0; index < element.references.length; index++) {
          const ref = element.references[index]
          if (ref.id.startsWith('condominium')) {
            arrCondo.push(ref.id)
          } else if (ref.id.startsWith('tenancy')) {
            arrTenancy.push(ref.id)
          } else if (ref.id.startsWith('contact')) {
            arrContact.push(ref.id)
          } else if (ref.id.startsWith('device')) {
            arrDevice.push(ref.id)
          } else if (ref.id.startsWith('request')) {
            arrRequest.push(ref.id)
          } else if (ref.id.startsWith('room')) {
            arrRoom.push(ref.id)
          } else if (ref.id.startsWith('mortgage')) {
            arrMortgage.push(ref.id)
          } else if (ref.id.startsWith('ins')) {
            arrInsurance.push(ref.id)
          }
        }
        if (element.ownerId && !arrOwner.includes(element.ownerId)) {
          arrOwner.push(element.ownerId)
        }
      })
      const promises = []
      if (arrCondo.length > 0) {
        promises.push(ObjectApi.getCondominiumByIds(arrCondo))
      }
      if (arrTenancy.length > 0) {
        promises.push(ObjectApi.getTenanciesByIds(arrTenancy))
      }
      if (arrContact.length > 0) {
        promises.push(ContactApi.contactResolve(arrContact))
      }
      if (arrDevice.length > 0) {
        promises.push(ObjectApi.listDevicesByIds(arrDevice))
      }
      if (arrRequest.length > 0) {
        promises.push(RequestApi.listByIds(arrRequest))
      }
      if (arrRoom.length > 0) {
        promises.push(RoomApi.listById(arrRoom))
      }
      if (arrMortgage.length > 0) {
        promises.push(ObjectApi.getMortgagesByIds(arrMortgage))
      }
      if (arrInsurance.length > 0) {
        promises.push(ObjectApi.getInsurancesByIds(arrInsurance))
      }
      Promise.all(promises)
        .then(values => {
          let contactIds = []
          values.forEach(element => {
            if (element.tenancies) {
              this.allConnections.tenancies = element.tenancies
              contactIds = contactIds.concat([...new Set([].concat(...element.tenancies.map((o) => o.tenant.contactIds)))])
            } else if (element.condominiums) {
              this.allConnections.condominiums = element.condominiums
              contactIds = contactIds.concat([...new Set([].concat(...element.condominiums.map((o) => o.owner.contactIds)))])
            } else if (element.devices) {
              this.allConnections.devices = element.devices
              let roomIds = []
              element.devices.forEach(element => {
                if (element.roomId !== '') {
                  roomIds.push(element.roomId)
                }
              })
              if (roomIds.length > 0) {
                roomIds = [...new Set(roomIds)]
                RoomApi.listById(roomIds).then(responseRoom => {
                  const rooms = this.allConnections.rooms.concat(responseRoom.rooms)
                  this.allConnections.rooms = [...new Set(rooms.map(o => JSON.stringify(o)))].map(s => JSON.parse(s))
                })
              }
            } else if (element.requests) {
              this.allConnections.requests = element.requests
              contactIds = contactIds.concat([...new Set([].concat(...element.requests.map((req) => req.requesters[0]?.contactId)))])
            } else if (element.rooms) {
              const rooms = this.allConnections.rooms.concat(element.rooms)
              this.allConnections.rooms = [...new Set(rooms.map(o => JSON.stringify(o)))].map(s => JSON.parse(s))
            } else if (element.mortgages) {
              this.allConnections.mortgages = element.mortgages
            } else if (element.insurances) {
              this.allConnections.insurances = element.insurances
            } else {
              this.allConnections.contacts = element.persons?.concat(element.companies)
            }
          })
          ContactApi.contactResolve(contactIds)
            .then(responseCon => {
              this.listContacts = this.listContacts.concat(responseCon.persons.concat(responseCon.companies)).filter(c => contactIds.includes(c.id))
            })
          this.loading = false
        })
    },
    customLabelEmployee(item) {
      if ('option' in item) {
        if (item.option?.label === 'system') {
          return this.$t('message.ticketOverview.ticket.createdBySystem')
        } else {
          return item.option?.label + (item.option?.company ? (' -' + item.option.company) : '')
        }
      } else {
        if (item.label === 'system') {
          return this.$t('message.ticketOverview.ticket.createdBySystem')
        } else {
          return item.label + (item.company ? (' -' + item.company) : '')
        }
      }
    },
    customLabelEstate(item) {
      return item.company
    },
    customLabelOperator(item) {
      return item.name
    },
    customLabelType(item) {
      return this.$t('message.ticketOverview.ticket.type.' + item)
    },
    customLabelPriority(item) {
      return this.$t('message.ticketOverview.ticket.priority.' + item)
    },
    customLabelStatus(item) {
      return this.$t('message.ticketOverview.status.' + item)
    },
    customLabelDevice(item) {
      if (item.value === null) {
        return `${this.$t('message.generic.unassigned')}`
      }
      return item.label
    },
    startMoving(evt) {
      this.dragging = true
      this.draggingCollumn = evt.from.id
      document
        .getElementById('status-open')
        .setAttribute('style', 'padding-bottom: 100px !important; min-height: ' + document.getElementById('parent').offsetHeight + 'px')
      document
        .getElementById('status-in_progress')
        .setAttribute('style', 'padding-bottom: 100px !important; min-height: ' + document.getElementById('parent').offsetHeight + 'px')
      document
        .getElementById('status-done')
        .setAttribute('style', 'padding-bottom: 100px !important; min-height: ' + document.getElementById('parent').offsetHeight + 'px')
    },
    endMoving(evt) {
      this.dragging = false
      document
        .getElementById('status-open')
        .setAttribute('style', '')
      document
        .getElementById('status-in_progress')
        .setAttribute('style', '')
      document
        .getElementById('status-done')
        .setAttribute('style', '')
      if (evt.to.id !== evt.from.id) {
        const newStatus = evt.to.id.slice(7).toUpperCase()
        const ticketToUpdate = this.ticketList.find(x => x.id === evt.item.id)
        ticketToUpdate.status = 'TICKET_STATUS_' + newStatus
        let updateMask = null
        if (!this.isAuthenticated) {
          updateMask = { paths: ['Id', 'Status'] }
        }
        TicketApi.updateTicket(ticketToUpdate, updateMask)
          .catch(e => {
            console.log(e)
            Vue.toasted.show(this.$t('message.savingErrors.ticket'), { type: 'error' })
          })
      }
    },
    showDetail(item, index, event) {
      if (item.status === 'TICKET_STATUS_DRAFT') {
        this.editTicket(item)
      } else {
        this.openDetail(item, event)
      }
    },
    showDetailMiddle(item, index, event) {
      this.openMiddleDetail(item, event)
    },
    editTicket(item) {
      if (this.isOwnerModule) {
        this.$router.push({
          name: 'OwnerTicketEdition',
          params: { id: item.key }
        })
      } else if (this.isAdminModule) {
        this.$router.push({
          name: 'AdminTicketEdition',
          params: { id: item.key }
        })
      } else if (this.isAssetModule) {
        this.$router.push({
          name: 'AssetTicketEdition',
          params: { id: item.key }
        })
      } else if (this.isAccountingModule) {
        this.$router.push({
          name: 'AccountingTicketEdition',
          params: { id: item.key }
        })
      } else {
        this.$router.push({
          name: 'MarketingTicketEdition',
          params: { id: item.key }
        })
      }
    },
    routeToDetail() {
      const ticket = this.selectedTicket
      if (this.isOwnerModule) {
        this.$router.push({
          name: 'OwnerTicketDetailsView',
          params: { id: ticket.key }
        }, () => {
        })
      } else if (this.isAdminModule) {
        this.$router.push({
          name: 'AdminTicketDetailsView',
          params: { id: ticket.key }
        }, () => {
        })
      } else if (this.isAssetModule) {
        this.$router.push({
          name: 'AssetTicketDetailsView',
          params: { id: ticket.key }
        }, () => {
        })
      } else if (this.isAccountingModule) {
        this.$router.push({
          name: 'AccountingTicketDetailsView',
          params: { id: ticket.key }
        }, () => {
        })
      } else {
        this.$router.push({
          name: 'MarketingTicketDetailsView',
          params: { id: ticket.key }
        }, () => {
        })
      }
    },
    openDetail(ticket, e) {
      if (e.srcElement.classList.contains('external-link')) {
        return false
      } else {
        this.selectedTicket = ticket
        if (this.linkEmail) {
          this.$refs.confirmationModalLinkTicket.show()
        } else {
          this.routeToDetail()
        }
      }
    },
    openMiddleDetail(ticket, e) {
      if (e.srcElement.classList.contains('external-link')) {
        return false
      } else {
        let routeData = ''
        if (this.isOwnerModule) {
          routeData = this.$router.resolve({
            name: 'OwnerTicketDetailsView',
            params: { id: ticket.key }
          }, () => {
          })
        } else if (this.isAdminModule) {
          routeData = this.$router.resolve({
            name: 'AdminTicketDetailsView',
            params: { id: ticket.key }
          }, () => {
          })
        } else if (this.isAssetModule) {
          routeData = this.$router.resolve({
            name: 'AssetTicketDetailsView',
            params: { id: ticket.key }
          }, () => {
          })
        } else if (this.isAccountingModule) {
          routeData = this.$router.resolve({
            name: 'AccountingTicketDetailsView',
            params: { id: ticket.key }
          }, () => {
          })
        } else {
          routeData = this.$router.resolve({
            name: 'MarketingTicketDetailsView',
            params: { id: ticket.key }
          }, () => {
          })
        }
        window.open(routeData.href, '_blank')
      }
    },
    linkTickets() {
      this.disabledLinkBtn = true
      MessageApi.setReferenceIds([this.linkEmail], [this.selectedTicket.id].concat(this.email.referenceIds.filter(x => !x.includes('ticket_'))))
        .then(() => {
          this.loading = false
          Vue.toasted.show(this.$t('message.successMessages.emailLinked'), { type: 'success' })
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.savingErrors.reference'), { type: 'error' })
        })
        .finally(() => {
          this.disabledLinkBtn = false
          this.$refs.confirmationModalLinkTicket.hide()
          this.routeToDetail()
        })
    },
    statusChanged() {
      this.openTickets = this.ticketList.filter(
        entry => entry.status === 'TICKET_STATUS_OPEN'
      )
      this.progressTickets = this.ticketList.filter(
        entry => entry.status === 'TICKET_STATUS_IN_PROGRESS'
      )
      this.doneTickets = this.ticketList.filter(
        entry => entry.status === 'TICKET_STATUS_DONE'
      )
    },
    getConnectionName(ticket) {
      const names = []
      if (ticket?.references.length > 0) {
        ticket?.references.forEach(ref => {
          const id = ref.id
          if (id.startsWith('condominium')) {
            const connection = this.getConnection(id)
            if (connection?.owner?.contactIds.length > 0) {
              connection.owner.contactIds.forEach(contact => {
                names.push((this.getContactName(contact))?.toLowerCase())
              })
            }
          } else if (id.startsWith('tenancy')) {
            const connection = this.getConnection(id)
            if (connection?.tenant?.contactIds.length > 0) {
              connection.tenant.contactIds.forEach(contact => {
                names.push((this.getContactName(contact))?.toLowerCase())
              })
            }
          } else if (id.startsWith('obj')) {
            const connection = this.getConnection(id)
            if (connection) {
              names.push((connection.name !== '' ? connection.name : connection.number ? connection.number : connection.numericId)?.toLowerCase())
            }
          } else if (id.startsWith('building')) {
            names.push((this.getConnection(id)?.name)?.toLowerCase())
          } else if (id.startsWith('prop')) {
            names.push((this.getConnection(id)?.name)?.toLowerCase())
          } else if (id.startsWith('contact')) {
            names.push((this.getConnection(id)?.name)?.toLowerCase())
          } else if (id.startsWith('device')) {
            names.push(this.getConnection(id) ? this.$t('message.onBoarding.devices.typeValues.' + (this.getConnection(id)?.type))?.toLowerCase() : '')
          } else if (id.startsWith('request')) {
            const connection = this.getConnection(id)
            if (connection?.requesters.length > 0) {
              names.push((this.getContactName(connection.requesters[0].contactId))?.toLowerCase())
            }
          }
        })
      }
      return names
    },
    changeFilter(val) {
      this.$router.push({
        name: 'TicketOverview',
        query: { filter: val }
      })
    },
    initFilter() {
      this.searchText = ''
      this.searchCreator = []
      this.searchDueDate = false
      this.searchWatcher = false
      this.searchNewEvent = false
      this.emptyAssignee = false
      this.searchAssignee = []
      this.searchPriority = []
      this.searchType = []
      this.searchStatus = []
      this.searchDevice = []
      this.searchProperty = []
      this.searchBuilding = []
      this.searchObject = []
      this.searchOwner = []
      this.involvedPeople = []
      this.loadTickets()
    },
    changeTicketUrl() {
      this.readyForLoad = false
      let checkRoute = ''
      const query = {}
      if (this.mainReferences.length === 0 && !this.$route.name.includes('ActivitiesTicketView')) {
        if (this.isOwnerModule) {
          checkRoute = this.closedTicket ? 'OwnerClosedTicketOverview' : this.draftTicket ? 'OwnerDraftTicketOverview' : 'OwnerTicketOverview'
        } else if (this.isMarketingModule) {
          checkRoute = this.closedTicket ? 'MarketingClosedTicketOverview' : this.draftTicket ? 'MarketingDraftTicketOverview' : 'MarketingTicketOverview'
        } else if (this.isAdminModule) {
          checkRoute = this.closedTicket ? 'AdminClosedTicketOverview' : this.draftTicket ? 'AdminDraftTicketOverview' : 'AdminTicketOverview'
        } else if (this.isAccountingModule) {
          checkRoute = this.closedTicket ? 'AccountingClosedTicketOverview' : this.draftTicket ? 'AccountingDraftTicketOverview' : 'AccountingTicketOverview'
        } else if (this.isAssetModule) {
          checkRoute = this.closedTicket ? 'AssetClosedTicketOverview' : this.draftTicket ? 'AssetDraftTicketOverview' : 'AssetTicketOverview'
        }
      } else {
        if (this.isOwnerModule) {
          checkRoute = 'OwnerActivitiesTicketView'
        } else if (this.isMarketingModule) {
          checkRoute = 'MarketingActivitiesTicketView'
        } else if (this.isAdminModule) {
          checkRoute = 'AdminActivitiesTicketView'
        } else if (this.isAccountingModule) {
          checkRoute = 'AccountingActivitiesTicketView'
        } else if (this.isAssetModule) {
          checkRoute = 'AssetActivitiesTicketView'
        }
      }
      if (!this.isFirstLoad) {
          if (this.searchAssignee.length > 0) {
            query.defaultAssigne = this.searchAssignee.filter(z => z && 'value' in z).map(x => x.value).join(',')
          } else {
            query.defaultEmptyAssignee = 'true'
          }
          if (this.searchText !== '') {
            query.defaultText = this.searchText
          }
          if (this.searchType.length > 0) {
            query.defaultType = this.searchType.join(',')
          }
          if (this.searchStatus.length > 0) {
            query.defaultStatus = this.searchStatus.join(',')
          }
          if (this.searchDevice.length > 0) {
            query.defaultDevice = this.searchDevice.map(x => x.value).join(',')
          }
          if (this.searchCreator.length > 0) {
            query.defaultCreator = this.searchCreator.map(x => x.value).join(',')
          }
          if (this.involvedPeople.length > 0) {
            query.defaultOperator = this.involvedPeople.map(x => x.objectID).join(',')
          }
          if (this.searchPriority.length > 0) {
            query.defaultPriority = this.searchPriority.join(',')
          }
          if (this.searchDueDate) {
            query.defaultOverdue = this.searchDueDate ? 'true' : 'false'
          }
          if (this.searchWatcher) {
            query.defaultWatched = this.searchWatcher ? 'true' : 'false'
          }
          if (this.searchNewEvent) {
            query.defaultNewEvent = this.searchNewEvent ? 'true' : 'false'
          }
          if (this.linkEmail !== null) {
            query.linkEmail = this.linkEmail
          }
          if (this.nameLinked !== null) {
            query.nameLinked = this.nameLinked
          }
          if (this.searchOwner.length > 0) {
            query.ownerIds = this.searchOwner.map(x => x.value).join(',')
          }
          const references = this.searchProperty.concat(this.searchBuilding).concat(this.searchObject)
          if (references.length > 0) {
            query.referenceIds = references.map(x => x.id).join(',') || []
          }
      }
      // console.log(JSON.stringify(this.$route.query), JSON.stringify(query))
      if (JSON.stringify(this.$route.query) !== JSON.stringify(query) && !this.isFirstLoad) {
        this.$router.replace({
          name: checkRoute,
          query: query
        })
      }
      this.readyForLoad = true
    },
    setColumnView(value) {
      this.isColumnView = value
    },
    getConnection(id) {
      if (id.startsWith('condominium')) {
        return this.allConnections.condominiums?.find(x => x.id === id)
      } else if (id.startsWith('tenancy')) {
        return this.allConnections.tenancies?.find(x => x.id === id)
      } else if (id.startsWith('obj')) {
        return this.allConnections.objects?.find(x => x.id === id)
      } else if (id.startsWith('building')) {
        return this.allConnections.buildings?.find(x => x.id === id)
      } else if (id.startsWith('prop')) {
        return this.allConnections.properties?.find(x => x.id === id)
      } else if (id.startsWith('contact')) {
        return this.allConnections.contacts?.find(x => x.id === id)
      } else if (id.startsWith('device')) {
        return this.allConnections.devices?.find(x => x.id === id)
      } else if (id.startsWith('request')) {
        return this.allConnections.requests?.find(x => x.id === id)
      } else if (id.startsWith('room')) {
        return this.allConnections.rooms?.find(x => x.id === id) || null
      } else if (id.startsWith('mortgage')) {
        return this.allConnections.mortgages?.find(x => x.id === id) || null
      } else if (id.startsWith('ins')) {
        return this.allConnections.insurances?.find(x => x.id === id) || null
      }
    },
    findConnectionId(reference, type) {
      return reference.relatedIds.find(x => x.startsWith(type))
    },
    getContactName(contact) {
      return this.allListContacts.find(x => x.id === contact)?.name
    },
    redirectToDetail(item, objectId, view) {
      let buildingId = null
      if (item) {
        if (item.id.startsWith('room')) {
          if (item.referenceId.startsWith('obj')) {
            buildingId = this.allConnections.buildings.find(y => y.objectIds.includes(item.referenceId))?.id || null
          } else if (item.referenceId.startsWith('building')) {
            return this.redirectToDetailPage(item, 'building')
          }
        } else {
          buildingId = this.allConnections.buildings.find(y => y.objectIds.includes(item.objectId))?.id || null
        }
      }
      if (buildingId) {
        if (this.isOwnerModule) {
          return {
            name: 'OwnerBuildingDetailsView',
            params: { id: buildingId },
            query: {
              view: view,
              elementSelected: objectId
            }
          }
        } else if (this.isAdminModule) {
          return {
            name: 'AdminBuildingDetailsView',
            params: { id: buildingId },
            query: {
              view: view,
              elementSelected: objectId
            }
          }
        } else if (this.isAssetModule) {
          return {
            name: 'AssetBuildingDetailsView',
            params: { id: buildingId },
            query: {
              view: view,
              elementSelected: objectId
            }
          }
        } else if (this.isAccountingModule) {
          return {
            name: 'AccountingBuildingDetailsView',
            params: { id: buildingId },
            query: {
              view: view,
              elementSelected: objectId
            }
          }
        }
      }
      return ''
    },
    redirectToDetailPage(item, type) {
      if (type === 'contact') {
        const contactId = item.id
        if (contactId !== null) {
          if (this.isOwnerModule) {
            return {
              name: 'OwnerContactDetailsView',
              params: { id: contactId }
            }
          } else if (this.isAssetModule) {
            return {
              name: 'AssetContactDetailsView',
              params: { id: contactId }
            }
          } else if (this.isAccountingModule) {
            return {
              name: 'AccountingContactDetailsView',
              params: { id: contactId }
            }
          } else if (this.isMarketingModule) {
            return {
              name: 'MarketingContactDetailsView',
              params: { id: contactId }
            }
          } else {
            return {
              name: 'AdminContactDetailsView',
              params: { id: contactId }
            }
          }
        }
      } else if (type === 'obj') {
        const buildingId = this.allConnections.buildings.find(y => y.objectIds.includes(item.id))?.id
        const objectId = item?.id
        const view = 'objects'
        if (this.isOwnerModule) {
          return {
            name: 'OwnerBuildingDetailsView',
            params: { id: buildingId },
            query: {
              view: view,
              elementSelected: objectId
            }
          }
        } else if (this.isAdminModule) {
          return {
            name: 'AdminBuildingDetailsView',
            params: { id: buildingId },
            query: {
              view: view,
              elementSelected: objectId
            }
          }
        } else if (this.isAssetModule) {
          return {
            name: 'AssetBuildingDetailsView',
            params: { id: buildingId },
            query: {
              view: view,
              elementSelected: objectId
            }
          }
        } else if (this.isAccountingModule) {
          return {
            name: 'AccountingBuildingDetailsView',
            params: { id: buildingId },
            query: {
              view: view,
              elementSelected: objectId
            }
          }
        }
      } else if (type === 'building') {
        const buildingId = item.id.startsWith('building') ? item.id : item.id.startsWith('room') || item.id.startsWith('mortgage') ? item.referenceId : null
        if (buildingId) {
          if (this.isOwnerModule) {
            return {
              name: 'OwnerBuildingDetailsView',
              params: { id: buildingId },
              query: { view: 'building' }
            }
          } else if (this.isAssetModule) {
            return {
              name: 'AssetBuildingDetailsView',
              params: { id: buildingId },
              query: { view: 'building' }
            }
          } else if (this.isAccountingModule) {
            return {
              name: 'AccountingBuildingDetailsView',
              params: { id: buildingId },
              query: { view: 'building' }
            }
          } else if (this.isAdminModule) {
            return {
              name: 'AdminBuildingDetailsView',
              params: { id: buildingId },
              query: { view: 'building' }
            }
          }
        }
      } else if (type === 'prop') {
        const propertyId = item.id.startsWith('prop') ? item.id : item.id.startsWith('mortgage') || item.id.startsWith('ins') ? item.referenceId : null
        if (this.isOwnerModule) {
          return { name: 'OwnerPropertyDetailsView', params: { id: propertyId } }
        } else if (this.isAdminModule) {
          return { name: 'AdminPropertyDetailsView', params: { id: propertyId } }
        } else if (this.isAssetModule) {
          return { name: 'AssetPropertyDetailsView', params: { id: propertyId } }
        } else if (this.isAccountingModule) {
          return { name: 'AccountingPropertyDetailsView', params: { id: propertyId } }
        }
      }
      return ''
    },
    redirectToParentDetail(item, middleClick) {
      if (item.referenceId.startsWith('building')) {
        if (!middleClick) {
          this.$router.push(this.redirectToDetailPage(item, 'building'))
        } else {
          const route = this.$router.resolve(this.redirectToDetailPage(item, 'building'))
          window.open(route.href, '_blank')
        }
      } else if (item.referenceId.startsWith('prop')) {
        if (!middleClick) {
          this.$router.push(this.redirectToDetailPage(item, 'prop'))
        } else {
          const route = this.$router.resolve(this.redirectToDetailPage(item, 'prop'))
          window.open(route.href, '_blank')
        }
      }
    },
    redirectToDeviceDetail(device) {
      const referenceId = device.referenceId
      const deviceId = device.id
      if (referenceId.startsWith('obj') || referenceId.startsWith('building')) {
        let id = null
        if (referenceId.startsWith('building')) {
          id = referenceId
        } else {
          const item = this.allConnections.buildings.filter(x => x.objectIds && x.objectIds.includes(referenceId))
          if (item.length > 0) {
            id = item[0].id
          }
        }
        if (id && deviceId) {
          if (this.isOwnerModule) {
            return { name: 'OwnerBuildingDetailsView', params: { id: id }, query: { view: 'devices', elementSelected: deviceId } }
          } else if (this.isAdminModule) {
            return { name: 'AdminBuildingDetailsView', params: { id: id }, query: { view: 'devices', elementSelected: deviceId } }
          } else if (this.isAssetModule) {
            return { name: 'AssetBuildingDetailsView', params: { id: id }, query: { view: 'devices', elementSelected: deviceId } }
          } else if (this.isAccountingModule) {
            return { name: 'AccountingBuildingDetailsView', params: { id: id }, query: { view: 'devices', elementSelected: deviceId } }
          }
        }
      } else {
        if (this.isOwnerModule) {
          return { name: 'OwnerPropertyDetailsView', params: { id: referenceId }, query: { view: 'devices', elementSelected: deviceId } }
        } else if (this.isAdminModule) {
          return { name: 'AdminPropertyDetailsView', params: { id: referenceId }, query: { view: 'devices', elementSelected: deviceId } }
        } else if (this.isAssetModule) {
          return { name: 'AssetPropertyDetailsView', params: { id: referenceId }, query: { view: 'devices', elementSelected: deviceId } }
        } else if (this.isAccountingModule) {
          return { name: 'AccountingPropertyDetailsView', params: { id: referenceId }, query: { view: 'devices', elementSelected: deviceId } }
        }
      }
      return ''
    }
  }
}
</script>

<style lang="scss" scoped>

.column-heading {
  background-color: $color-bg-grey;
}

.column-container {
  background-color: $color-bg-grey;
}

.dragging-border {
  border: 2px dashed $color-primary;
  border-radius: 0.25rem;
  min-height: 100px;
}
.as-button :deep(.btn-secondary) {
  min-height: 31px;
  height: auto;
  padding-left: 0;
  padding-right: 0;
}
</style>
