<template>
  <div>
    <div
      v-if="showLabel"
      class="row">
      <div class="col">
        <label :for="name">
          <slot />
        </label>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <span
          v-if="limit !== -1 && !isReadOnly"
          class="limit text-grey-light">
          {{ valueTextArea ? valueTextArea.length : 0 }}/{{ limit }}
        </span>
        <textarea-autosize
          v-if="resizable"
          v-model="valueTextArea"
          class="form-control form-control-sm"
          :class="[customClass, checkValide, 'h-auto']"
          :placeholder="$t('message.generic.form.typeTextPlaceholder')"
          v-bind="$attrs"
          v-on="mountEventListeners" />
        <textarea
          v-else-if="!isReadOnly"
          :id="name"
          v-model="valueTextArea"
          class="form-control form-control-sm"
          :class="[customClass, checkValide, limit !== -1 && !isReadOnly ? 'padding-right-80px' : '']"
          v-bind="$attrs"
          @keypress="respectLimit"
          @paste="respectLimit"
          v-on="mountEventListeners" />
        <template v-else>
          <!-- eslint-disable-next-line vue/singleline-html-element-content-newline -->
          <p class="show-data mb-0">{{ valueTextArea !=='' && valueTextArea !== null ? valueTextArea : '-' }}</p>
          <!-- eslint-disable-next-line vue/singleline-html-element-content-newline -->
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import VueTextareaAutosize from 'vue-textarea-autosize'
Vue.use(VueTextareaAutosize)

export default {
  name: 'CoozzyFormTextarea',
  inheritAttrs: false,
  props: {
    name: {
      type: String,
      default: ''
    },
    showLabel: {
      type: Boolean,
      default: true
    },
    initial: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    customClass: {
      type: String,
      default: ''
    },
    isReadOnly: {
      type: Boolean,
      default: false
    },
    checkValide: {
      type: String,
      default: ''
    },
    resizable: {
      type: Boolean,
      default: false
    },
    limit: {
      type: Number,
      default: -1
    }
  },
  data() {
    return {
      valueTextArea: this.initial
    }
  },
  computed: {
    mountEventListeners() {
      return Object.assign({},
        this.$listeners,
        {
          input: (value) => {
            this.$emit('input', this.valueTextArea)
          }
        }
      )
    }
  },
  watch: {
    text: function (val) {
      this.valueTextArea = val
    }
  },
  methods: {
    emptyTextArea() {
      this.valueTextArea = ''
    },
    respectLimit ($event) {
      if (this.limit === -1) return

      if ($event.type === 'paste') {
        $event.preventDefault()
        const pastedText = $event.clipboardData.getData('text')
        const currentText = this.valueTextArea || ''
        const selectionStart = $event.target.selectionStart
        const selectionEnd = $event.target.selectionEnd

        const remainingSpace = this.limit - (currentText.length - (selectionEnd - selectionStart))

        const truncatedPastedText = pastedText.substring(0, remainingSpace)

        const newText = currentText.substring(0, selectionStart) +
                       truncatedPastedText +
                       currentText.substring(selectionEnd)

        this.valueTextArea = newText

        return
      }

      if (this.valueTextArea && this.valueTextArea.length >= this.limit &&
          window.getSelection().toString() === '') {
        $event?.preventDefault()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.show-data{
  white-space: pre-wrap;
}
textarea{
  padding: 0.2em 8px;
}
.limit {
    position: absolute;
    right: 2rem;
    top: 0;
    line-height: 30px;
  }
</style>
