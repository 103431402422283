<template>
  <section class="w-100">
    <div
      :id="`tenancy-${index}`"
      :ref="tenancy.id"
      v-b-toggle="`collapse-tenancy-${index}-${tenancy.internalId || tenancy.id}`"
      class="col pt-3 mb-0">
      <div class="row">
        <div class="col-8 d-flex align-items-center">
          <h5 :class="[titleBold ? 'cursor-pointer hover width-only-content font-weight-normal' : 'cursor-pointer hover width-only-content']">
            <span v-if="tenancy.tenant.contactIds.length === 0">
              n/a
            </span>
            <span v-else>
              {{ getTenantsName }}
            </span>
            <span>
              :
              <template v-if="tenancy.startingAt && tenancy.startingAt !== -1 && tenancy.startingAt !== '-1'">
                {{ tenancy.startingAt | objectToDate }}
              </template>
              <template v-else>
                n/a
              </template>
              -
              <template v-if="tenancy.endingAt && tenancy.endingAt.year !== -1 && tenancy.endingAt.year !== '-1'">
                {{ tenancy.endingAt | objectToDate }}
              </template>
              <template v-else-if="endOfContractFromEnterTermination">
                {{ $options.filters.objectToDate(endOfContractFromEnterTermination) }}
              </template>
              <template v-else>
                n/a
              </template>
            </span>
          </h5>
          <a
            v-if="!isMarketingPath && !isAddTenancyPath && !isMarkForMartektingPath && !isAddVacancyPath &&!isTermination"
            tabindex="0"
            class="lh-1 link-activity "
            @click="openActivities()"
            @click.middle="openActivitiesMiddle()">
            <img
              height="15px"
              class="ml-3 mb-1"
              :src="`/img/ticket/new-window.svg`"
              alt="type">
            {{ $t('message.ticketOverview.activities.title') }}
          </a>
          <coozzy-info-circle-icon
            v-if="ifMarketingAndTenancyNotActive"
            v-b-tooltip.hover.html="$t('message.onBoarding.buildings.objects.tenancies.tenancyOnMarketing')"
            class="ml-2 mb-2" />
        </div>
        <div
          class="col mr-5">
          <coozzy-dropdown
            v-if="!hideActions && (tenancy.tenant.contactIds.length > 0 || tenancy.id || tenancy.activated || isFirst) && isOwnerCondition"
            size="sm"
            class="w-20 sort float-right"
            :text="$t('message.generic.actions')">
            <coozzy-dropdown-item
              v-if="!userWithoutRole && tenancy.id && isTicketingEnabled && (isEditor || isTicketEditor)"
              @click="createTicket();$event.stopPropagation()">
              {{ $t('message.ticketOverview.createTicket') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-item
              v-if="!userWithoutRole && !isOwnerModule && !isAssetModule"
              @click="navigateToCreateOrder()">
              {{ $t('message.onBoarding.devices.createOrder') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-item
              v-if="tenancy.tenant.contactIds.length > 0"
              @click="addActivityClicked(); $event.stopPropagation()">
              {{ $t('message.contact.activity.create') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-item
              v-if="userWithoutRole && (!isAssetModule && !isOwnerModule) && hasDigitalDepositRole && digitalDepositEnabled && isAllTenantsIndividualContacts && tenancyStatusDigitalDeposit"
              @click="showDigitalDepositModal();$event.stopPropagation()">
              {{ $t('message.onBoarding.buildings.objects.tenancies.digitalDeposit') }}
            </coozzy-dropdown-item>
            <template
              v-if="!userWithoutRole && $route.path.startsWith('/admin/buildings/') > 0 || $route.path.startsWith('/accounting/buildings/') > 0">
              <coozzy-dropdown-item
                v-if="tenancy.id && (contractType === 'LIMITED' || dates.endingAt === null) && tenancy.activated && tenancy.status === 'TENANCY_STATUS_CURRENT'"
                @click="redirectToTerminationView">
                {{ $t('message.onBoarding.buildings.objects.tenancies.termination') }}
              </coozzy-dropdown-item>
              <coozzy-dropdown-item
                v-if="tenancy.id && isExtendTenancy"
                @click="openExtendTenancy();$event.stopPropagation()">
                {{ $t('message.onBoarding.buildings.objects.tenancies.extendTenancy') }}
              </coozzy-dropdown-item>
              <coozzy-dropdown-item
                v-if="tenancy.activated && object && ((object.debitUntil && new Date($options.filters.objectToDateInput(object.debitUntil)) < new Date($options.filters.objectToDateInput(tenancy.startingAt))) || !object.debitUntil)"
                @click="removeActiveTenancy();$event.stopPropagation()">
                {{ $t('message.onBoarding.buildings.objects.tenancies.delete') }}
              </coozzy-dropdown-item>
              <coozzy-dropdown-item
                v-if="!tenancy.activated && !ifMarketingAndTenancyNotActive && object && ((object.debitUntil && new Date($options.filters.objectToDateInput(object.debitUntil)) < new Date($options.filters.objectToDateInput(tenancy.startingAt))) || !object.debitUntil)"
                @click="removeClicked();$event.stopPropagation()">
                {{ $t('message.onBoarding.buildings.objects.tenancies.delete') }}
              </coozzy-dropdown-item>
              <coozzy-dropdown-item
                v-if="(isAdminModule || isAccountingModule) && hasDigitalSignatureRole && deepSignEnabled"
                @click="showDigitalSignatureModal();$event.stopPropagation()">
                {{ $t('message.deepSign.titleAction') }}
              </coozzy-dropdown-item>
              <coozzy-dropdown-item
                v-if="(!isAssetModule && !isOwnerModule) && hasDigitalDepositRole && digitalDepositEnabled && isAllTenantsIndividualContacts && tenancyStatusDigitalDeposit"
                @click="showDigitalDepositModal();$event.stopPropagation()">
                {{ $t('message.onBoarding.buildings.objects.tenancies.digitalDeposit') }}
              </coozzy-dropdown-item>
              <coozzy-dropdown-item
                v-if="!tenancy.activated && !ifMarketingAndTenancyNotActive"
                @click="activateTenancyClicked();$event.stopPropagation()">
                {{ $t('message.interestedParty.action.activateTenancy') }}
              </coozzy-dropdown-item>
              <coozzy-dropdown-item
                v-if="isFirst && !tenancy.vacancy && tenancy.endingAt"
                @click="deleteTerminationClicked();$event.stopPropagation()">
                {{ $t('message.onBoarding.buildings.objects.tenancies.deleteTermination.title') }}
              </coozzy-dropdown-item>
              <add-document-modal
                :suffix="'tenancies-doc' + index"
                :owner-id="tenancy.ownerId"
                @document-created="documentCreatedAction" />
              <coozzy-dropdown-item
                @click="addDocumentClicked('tenancies-doc' + index);$event.stopPropagation()">
                {{ $t('message.manageDocuments.addDocument') }}
              </coozzy-dropdown-item>
              <coozzy-dropdown-item
                @click="$bvModal.show(`create-contract-confirmationModal-${index}`);$event.stopPropagation()">
                {{ $t('message.addTenancy.createDocument') }}
              </coozzy-dropdown-item>
            </template>
            <coozzy-dropdown-item
              v-if="!userWithoutRole && tenancy.tenant.contactIds.length > 0"
              @click="addReminderClicked();$event.stopPropagation()">
              {{ $t('message.onBoarding.buildings.objects.addReminder') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-item
              v-if="!userWithoutRole && ($route.path.startsWith('/admin/buildings/') > 0 || $route.path.startsWith('/accounting/buildings/') > 0) && tenancy.tenant.contactIds.length > 0 && tenantContacts.filter(tenant => tenant.email !== '' && tenant.email !== null).length > 0 "
              @click="openSendMailModal();$event.stopPropagation()">
              {{ $t('message.generic.writeEmail') }}
            </coozzy-dropdown-item>
          </coozzy-dropdown>
        </div>
      </div>
    </div>
    <b-collapse
      :id="`collapse-tenancy-${index}-${tenancy.internalId || tenancy.id}`"
      ref="collapse"
      :visible="expandByDefault || openedCollapses.includes(`collapse-tenancy-${index}`)"
      class="col">
      <div class="row">
        <div class="col">
          <h6
            v-b-toggle="`collapse-tenancy-${index}-tenant`"
            class="font-weight-bold mt-3"
            :class="[readOnly ? 'none': 'cursor-pointer', 'width-only-content hover']">
            {{ $t('message.onBoarding.buildings.objects.tenancies.tenancy') }}
          </h6>
        </div>
      </div>
      <b-collapse
        :id="`collapse-tenancy-${index}-tenant`"
        :visible="true">
        <div
          :ref="`objtenancyrow-${tenancy.id}`"
          class="row objtenancyrow py-01rem">
          <div
            v-if="readOnly || $parent.$route.name === 'AdminAddTenancyView' || $parent.$route.name === 'AccountingAddTenancyView' || $parent.$route.name === 'MarketingAddTenancyView' || $parent.$route.name === 'AdminActivateTenancy' || $parent.$route.name === 'AccountingActivateTenancy' || $parent.$route.name === 'AssetActivateTenancy' || $parent.$route.name === 'MarketingActivateTenancy' || !enableTenantSelection"
            class="col-sm-12 col-md-3">
            <label>
              {{ $t('message.onBoarding.buildings.objects.tenancies.debtor') }}
            </label>
            <div v-if="getContact(tenancy.tenant.billingContactId)">
              <router-link
                :to="redirectToDetailPage(tenancy.tenant.billingContactId)">
                {{ getContact(tenancy.tenant.billingContactId).name }}
              </router-link>
              <template v-if="getContact(tenancy.tenant.billingContactId)">
                <br>
                <address-text
                  :show-short-country="true"
                  :address="getContact(tenancy.tenant.billingContactId).address" />
              </template>
            </div>
            <span
              v-else
              class="d-block">-</span>
          </div>
          <div
            v-else
            class="col-sm-12 col-md-3">
            <ais-instant-search
              v-if="searchClientInternal"
              :search-client="searchClientInternal"
              :routing="routing"
              index-name="contact">
              <!-- eslint-disable vue/attribute-hyphenation -->
              <ais-configure
                :hitsPerPage="10"
                :filters="'active:true AND isEmployee:false AND isOnlyInterestedParty:false AND owner.id:' + building.ownerId" />
              <!-- eslint-disable vue/attribute-hyphenation -->
              <ais-autocomplete>
                <template slot-scope="{ indices, refine }">
                  <label>{{ $t('message.onBoarding.buildings.objects.tenancies.debtor') + (readOnly ? '' : '*') }}</label>
                  <coozzy-search-multiselect
                    v-model="billingContactOption"
                    :options="editObject(indices[0].hits)"
                    :placeholder="''"
                    :close-on-select="true"
                    :clear-on-select="false"
                    :preserve-search="true"
                    :preselect-first="false"
                    :check-valide="$v.billingContactOption.$dirty && $v.billingContactOption.$error ? 'is-invalid' : ''"
                    :state="$v.billingContactOption.$dirty && $v.billingContactOption.$error ? false : null"
                    :disabled="isInactive || ((!tenancy.internalId && activated && !isAccountingModule) && !activeAndEditable) || ((!tenancy.internalId && activated && isAccountingModule) && !activeAndEditable) || ifMarketingAndTenancyNotActive"
                    :taggable="false"
                    track-by="id"
                    label="name"
                    :with-address="true"
                    :with-numeric-id="true"
                    @search-change="see(refine, $event)" />
                </template>
              </ais-autocomplete>
            </ais-instant-search>
          </div>
          <div
            v-if="readOnly || !enableTenantSelection"
            class="col-sm-12 col-md-3">
            <label for="tenants">
              {{ $t('message.onBoarding.buildings.objects.tenancies.tenants.title') }}
            </label>
            <div v-if="tenancy.tenant.contactIds.length > 0">
              <template
                v-for="contactId in tenancy.tenant.contactIds">
                <div
                  v-if="getContact(contactId)"
                  :key="contactId">
                  <router-link
                    :to="redirectToDetailPage(contactId)">
                    {{ getContact(contactId).name }}
                  </router-link>
                  <template v-if="getContact(contactId)">
                    <br>
                    <address-text
                      :show-short-country="true"
                      :hide-if-empty="true"
                      :address="getContact(contactId).address" />
                  </template>
                </div>
              </template>
            </div>
            <span
              v-else
              class="d-block">-</span>
          </div>
          <div
            v-else
            class="col-sm-12 col-md-3">
            <ais-instant-search
              v-if="searchClientInternal"
              :search-client="searchClientInternal"
              :routing="routing"
              index-name="contact">
              <!-- eslint-disable vue/attribute-hyphenation -->
              <ais-configure
                :hitsPerPage="10"
                :filters="'active:true AND isEmployee:false AND isOnlyInterestedParty:false AND owner.id:' + building.ownerId" />
              <!-- eslint-disable vue/attribute-hyphenation -->
              <ais-autocomplete>
                <template slot-scope="{ indices, refine }">
                  <label>{{ $t('message.onBoarding.buildings.objects.tenancies.tenants.title') + (readOnly ? '' : '*') }}</label>
                  <coozzy-search-multiselect
                    v-model="tenants"
                    :options="editObject(indices[0].hits)"
                    :placeholder="''"
                    :close-on-select="true"
                    :clear-on-select="false"
                    :preserve-search="true"
                    :preselect-first="false"
                    :disabled="isInactive || ((!tenancy.internalId && activated && !isAccountingModule) && !activeAndEditable) || ((!tenancy.internalId && activated && isAccountingModule) && !activeAndEditable) || ifMarketingAndTenancyNotActive"
                    :check-valide="$v.tenants.$dirty && (hasNoAddress.length > 0 || $v.tenants.$error) ? 'is-invalid' : ''"
                    :taggable="false"
                    track-by="id"
                    label="name"
                    :multiple="true"
                    :with-address="true"
                    :with-numeric-id="true"
                    @search-change="see(refine, $event)" />
                </template>
              </ais-autocomplete>
            </ais-instant-search>
            <!-- <coozzy-contact-multiselect
              id="tenants"
              v-model="tenants"
              :options="availableTenantsWithAddress"
              :clear-on-select="true"
              :check-valide="$v.tenants.$dirty && (hasNoAddress.length > 0 || $v.tenants.$error) ? 'is-invalid' : ''"
              :disabled="isInactive || (!tenancy.internalId && activated && !isAccountingModule) || ifMarketingAndTenancyNotActive"
              label="label"
              track-by="label">
              {{ $t('message.onBoarding.buildings.objects.tenancies.tenants.title') + (readOnly ? '' : '*') }}
            </coozzy-contact-multiselect> -->
          </div>
          <div
            v-if="readOnly || $parent.$route.name === 'AdminAddTenancyView' || $parent.$route.name === 'MarketingAddTenancyView' || $parent.$route.name === 'AdminActivateTenancy' || $parent.$route.name === 'AssetActivateTenancy' || $parent.$route.name === 'MarketingActivateTenancy' || !enableTenantSelection"
            class="col-sm-12 col-md-3">
            <label>
              {{ $t('message.onBoarding.buildings.objects.tenancies.correspondenceAddress') }}
            </label>
            <div v-if="(Array.isArray(correspondenceContactOption) && correspondenceContactOption.length > 0) || (!Array.isArray(correspondenceContactOption) && correspondenceContactOption)">
              <div v-if="correspondenceContactOption && getContact(getIdCorrespondenceContact(correspondenceContactOption))">
                <router-link
                  :to="redirectToDetailPage(getIdCorrespondenceContact(correspondenceContactOption))">
                  {{ getContact(getIdCorrespondenceContact(correspondenceContactOption)).name }}
                </router-link>
                <template v-if="getContact(getIdCorrespondenceContact(correspondenceContactOption))">
                  <br>
                  <address-text
                    :show-short-country="true"
                    :address="getContact(getIdCorrespondenceContact(correspondenceContactOption)).address" />
                </template>
              </div>
            </div>
            <span
              v-else
              class="d-block">-</span>
          </div>
          <div
            v-else
            class="col-sm-12 col-md-3">
            <ais-instant-search
              v-if="searchClientInternal"
              :search-client="searchClientInternal"
              :routing="routing"
              index-name="contact">
              <!-- eslint-disable vue/attribute-hyphenation -->
              <ais-configure
                :hitsPerPage="10"
                :filters="'active:true AND isEmployee:false AND isOnlyInterestedParty:false AND owner.id:' + building.ownerId" />
              <!-- eslint-disable vue/attribute-hyphenation -->
              <ais-autocomplete>
                <template slot-scope="{ indices, refine }">
                  <label>{{ $t('message.onBoarding.buildings.objects.tenancies.correspondenceAddress') }}</label>
                  <coozzy-search-multiselect
                    v-model="correspondenceContactOption"
                    :options="editObject(indices[0].hits)"
                    :placeholder="''"
                    :close-on-select="true"
                    :clear-on-select="false"
                    :preserve-search="true"
                    :preselect-first="false"
                    :disabled="isInactive || ifMarketingAndTenancyNotActive"
                    :taggable="false"
                    track-by="id"
                    label="name"
                    :with-address="true"
                    :with-numeric-id="true"
                    @search-change="see(refine, $event)" />
                </template>
              </ais-autocomplete>
            </ais-instant-search>
            <!-- <coozzy-contact-multiselect
              id="copyOfContactId"
              v-model="correspondenceContactOption"
              :options="availableTenantsWithAddress"
              :multiple="false"
              :close-on-select="true"
              :disabled="ifMarketingAndTenancyNotActive"
              label="label"
              track-by="label">
              {{ $t('message.onBoarding.buildings.objects.tenancies.correspondenceAddress') }}
            </coozzy-contact-multiselect> -->
          </div>
          <div
            v-if="readOnly || $parent.$route.name === 'AdminAddTenancyView' || $parent.$route.name === 'AccountingAddTenancyView' || $parent.$route.name === 'MarketingAddTenancyView' || $parent.$route.name === 'AdminActivateTenancy' || $parent.$route.name === 'AccountingActivateTenancy' || $parent.$route.name === 'AssetActivateTenancy' || $parent.$route.name === 'MarketingActivateTenancy' || !enableTenantSelection"
            class="col-sm-12 col-md-3">
            <label for="guarantor">
              {{ $t('message.onBoarding.buildings.objects.tenancies.guarantor') }}
            </label>
            <div v-if="tenancy.tenant.guarantorContactIds && tenancy.tenant.guarantorContactIds.length > 0">
              <template
                v-for="contactId in tenancy.tenant.guarantorContactIds">
                <div
                  v-if="getContact(contactId)"
                  :key="contactId">
                  <router-link
                    :to="redirectToDetailPage(contactId)">
                    {{ getContact(contactId).name }}
                  </router-link>
                  <template v-if="getContact(contactId)">
                    <br>
                    <address-text
                      :show-short-country="true"
                      :hide-if-empty="true"
                      :address="getContact(contactId).address" />
                  </template>
                </div>
              </template>
            </div>
            <span
              v-else
              class="d-block">-</span>
          </div>
          <div
            v-else
            class="col-sm-12 col-md-3">
            <!-- <coozzy-contact-multiselect
              id="guarantor"
              v-model="guarantor"
              :options="availableGuarantor"
              :disabled="(!tenancy.internalId && activated && !isAccountingModule) || ifMarketingAndTenancyNotActive"
              label="label"
              track-by="label">
              {{ $t('message.onBoarding.buildings.objects.tenancies.guarantor') }}
            </coozzy-contact-multiselect> -->
            <ais-instant-search
              v-if="searchClientInternal"
              :search-client="searchClientInternal"
              :routing="routing"
              index-name="contact">
              <!-- eslint-disable vue/attribute-hyphenation -->
              <ais-configure
                :hitsPerPage="10"
                :filters="'active:true AND isEmployee:false AND isOnlyInterestedParty:false AND owner.id:' + building.ownerId" />
              <!-- eslint-disable vue/attribute-hyphenation -->
              <ais-autocomplete>
                <template slot-scope="{ indices, refine }">
                  <label>{{ $t('message.onBoarding.buildings.objects.tenancies.guarantor') }}</label>
                  <coozzy-search-multiselect
                    v-model="guarantor"
                    :options="editObject(indices[0].hits).filter(contact => contact && contact.id !== billingContactOption?.id)"
                    :placeholder="''"
                    :close-on-select="true"
                    :clear-on-select="false"
                    :preserve-search="true"
                    :preselect-first="false"
                    :disabled="isInactive || ((!tenancy.internalId && activated && !isAccountingModule) && !activeAndEditable) || ((!tenancy.internalId && activated && isAccountingModule) && !activeAndEditable) || ifMarketingAndTenancyNotActive"
                    :taggable="false"
                    track-by="id"
                    label="name"
                    :with-address="true"
                    :with-numeric-id="true"
                    @search-change="see(refine, $event)" />
                </template>
              </ais-autocomplete>
            </ais-instant-search>
          </div>
          <div class="col-sm-12 col-md-3">
            <label for="typeOfUse">
              {{ $t('message.onBoarding.buildings.objects.tenancies.typeOfUse.title') }} {{ readOnly ? '' : '*' }}
            </label>
            <coozzy-form-select
              v-if="!readOnly"
              id="typeOfUse"
              v-model="typeOfUse"
              :disabled="isInactive || ((activated && !isAccountingModule) && !activeAndEditable) || ((activated && isAccountingModule) && !activeAndEditable) || ifMarketingAndTenancyNotActive"
              :state="$v.typeOfUse.$dirty && $v.typeOfUse.$error ? false : null">
              <option value="">
                -
              </option>
              <option
                v-for="(item, i) in typeOfUseData"
                :key="i"
                :value="item">
                {{ $t(`message.onBoarding.buildings.objects.tenancies.typeOfUse.${item}`) }}
              </option>
            </coozzy-form-select>
            <span
              v-else
              class="d-block">
              <span v-if="typeOfUse === 'TYPE_OF_USE_VACATION'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.typeOfUse.TYPE_OF_USE_VACATION') }}
              </span>
              <span v-else-if="typeOfUse === 'TYPE_OF_USE_COMMERCIAL'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.typeOfUse.TYPE_OF_USE_COMMERCIAL') }}
              </span>
              <span v-else-if="typeOfUse === 'TYPE_OF_USE_PARKING'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.typeOfUse.TYPE_OF_USE_PARKING') }}
              </span>
              <span v-else-if="typeOfUse === 'TYPE_OF_USE_PARKING_EXTERNAL'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.typeOfUse.TYPE_OF_USE_PARKING_EXTERNAL') }}
              </span>
              <span v-else-if="typeOfUse === 'TYPE_OF_USE_AGRICULTURAL'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.typeOfUse.TYPE_OF_USE_AGRICULTURAL') }}
              </span>
              <span v-else-if="typeOfUse === 'TYPE_OF_USE_CONSTRUCTION'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.typeOfUse.TYPE_OF_USE_CONSTRUCTION') }}
              </span>
              <span v-else-if="typeOfUse === 'TYPE_OF_USE_GASTRONOMY'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.typeOfUse.TYPE_OF_USE_GASTRONOMY') }}
              </span>
              <span v-else-if="typeOfUse === 'TYPE_OF_USE_INDUSTRIAL'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.typeOfUse.TYPE_OF_USE_INDUSTRIAL') }}
              </span>
              <span v-else-if="typeOfUse === 'TYPE_OF_USE_INVESTMENT'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.typeOfUse.TYPE_OF_USE_INVESTMENT') }}
              </span>
              <span v-else-if="typeOfUse === 'TYPE_OF_USE_STORAGE'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.typeOfUse.TYPE_OF_USE_STORAGE') }}
              </span>
              <span v-else-if="typeOfUse === 'TYPE_OF_USE_RESIDENTIAL'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.typeOfUse.TYPE_OF_USE_RESIDENTIAL') }}
              </span>
              <span v-else-if="typeOfUse === 'TYPE_OF_USE_SECONDARY_ROOM'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.typeOfUse.TYPE_OF_USE_SECONDARY_ROOM') }}
              </span>
              <span v-else-if="typeOfUse === 'TYPE_OF_USE_OFFICE'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.typeOfUse.TYPE_OF_USE_OFFICE') }}
              </span>
              <span v-else-if="typeOfUse === 'TYPE_OF_USE_STORE'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.typeOfUse.TYPE_OF_USE_STORE') }}
              </span>
              <span v-else-if="typeOfUse === 'TYPE_OF_USE_ADVERTISING_AREA'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.typeOfUse.TYPE_OF_USE_ADVERTISING_AREA') }}
              </span>
              <span v-else-if="typeOfUse === 'TYPE_OF_USE_RADIO_ANTENNA'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.typeOfUse.TYPE_OF_USE_RADIO_ANTENNA') }}
              </span>
              <span v-else> - </span>
            </span>
          </div>
          <div class="col-sm-12 col-md-3">
            <label for="rentType">
              {{ $t('message.onBoarding.buildings.objects.tenancies.rentType.title') }} {{ readOnly ? '' : '*' }}
            </label>
            <coozzy-form-select
              v-if="!readOnly"
              id="rentType"
              v-model="rentType"
              :disabled="isInactive || ifMarketingAndTenancyNotActive"
              :state="$v.rentType.$dirty && $v.rentType.$error ? false : null">
              <option value="">
                -
              </option>
              <option value="RENT_TYPE_VMWG">
                {{ $t('message.onBoarding.buildings.objects.tenancies.rentType.RENT_TYPE_VMWG') }}
              </option>
              <option value="RENT_TYPE_INDEX">
                {{ $t('message.onBoarding.buildings.objects.tenancies.rentType.RENT_TYPE_INDEX') }}
              </option>
              <option value="RENT_TYPE_TURNOVER">
                {{ $t('message.onBoarding.buildings.objects.tenancies.rentType.RENT_TYPE_TURNOVER') }}
              </option>
              <!-- <option value="RENT_TYPE_CONDOMINIUM_OWNERSHIP">
                {{ $t('message.onBoarding.buildings.objects.tenancies.rentType.RENT_TYPE_CONDOMINIUM_OWNERSHIP') }}
              </option>
              <option value="RENT_TYPE_GRADUATED">
                {{ $t('message.onBoarding.buildings.objects.tenancies.rentType.RENT_TYPE_GRADUATED') }}
              </option>
              <option value="RENT_TYPE_SUBSIDIZES">
                {{ $t('message.onBoarding.buildings.objects.tenancies.rentType.RENT_TYPE_SUBSIDIZES') }}
              </option> -->
              <option value="RENT_TYPE_NOT_VMWG">
                {{ $t('message.onBoarding.buildings.objects.tenancies.rentType.RENT_TYPE_NOT_VMWG') }}
              </option>
            </coozzy-form-select>
            <span
              v-else
              class="d-block">
              <span v-if="rentType === 'RENT_TYPE_VMWG'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.rentType.RENT_TYPE_VMWG') }}
              </span>
              <span v-else-if="rentType === 'RENT_TYPE_CONDOMINIUM_OWNERSHIP'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.rentType.RENT_TYPE_CONDOMINIUM_OWNERSHIP') }}
              </span>
              <span v-else-if="rentType === 'RENT_TYPE_INDEX'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.rentType.RENT_TYPE_INDEX') }}
              </span>
              <span v-else-if="rentType === 'RENT_TYPE_GRADUATED'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.rentType.RENT_TYPE_GRADUATED') }}
              </span>
              <span v-else-if="rentType === 'RENT_TYPE_TURNOVER'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.rentType.RENT_TYPE_TURNOVER') }}
              </span>
              <span v-else-if="rentType === 'RENT_TYPE_SUBSIDIZES'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.rentType.RENT_TYPE_SUBSIDIZES') }}
              </span>
              <span v-else-if="rentType === 'RENT_TYPE_NOT_VMWG'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.rentType.RENT_TYPE_NOT_VMWG') }}
              </span>
              <span v-else> - </span>
            </span>
          </div>
          <div class="col-sm-12 col-md-3">
            <label for="contractType">
              {{ $t('message.onBoarding.buildings.objects.tenancies.contractType.title') }}
            </label>
            <coozzy-form-select
              v-if="!readOnly"
              id="contractType"
              v-model="contractType"
              :disabled="isInactive || ifMarketingAndTenancyNotActive">
              <option value="UNDEFINED_CONTRACT_TYPE" />
              <option value="INDEFINITE">
                {{ $t('message.onBoarding.buildings.objects.tenancies.contractType.permanent') }}
              </option>
              <option value="LIMITED">
                {{ $t('message.onBoarding.buildings.objects.tenancies.contractType.temporary') }}
              </option>
            </coozzy-form-select>
            <span
              v-else
              class="d-block">
              <span v-if="contractType === 'INDEFINITE'"> {{
                $t('message.onBoarding.buildings.objects.tenancies.contractType.permanent')
              }} </span>
              <span v-else-if="contractType === 'LIMITED'"> {{
                $t('message.onBoarding.buildings.objects.tenancies.contractType.temporary')
              }} </span>
              <span v-else-if="contractType === 'INDIVIDUAL'"> {{
                $t('message.onBoarding.buildings.objects.tenancies.contractType.customized')
              }} </span>
              <span v-else> - </span>
            </span>
          </div>
          <div class="col-sm-12 col-md-3">
            <label for="tenantType">
              {{ $t('message.onBoarding.buildings.objects.tenancies.tenantType.title') }}
            </label>
            <coozzy-form-select
              v-if="!readOnly"
              id="tenantType"
              v-model="tenantType"
              :disabled="isInactive || ifMarketingAndTenancyNotActive">
              <option value="UNDEFINED_TENANT_TYPE" />
              <option value="EXTERNAL_TENANT_TYPE">
                {{ $t('message.onBoarding.buildings.objects.tenancies.tenantType.external') }}
              </option>
              <option value="OWN_TENANT_TYPE">
                {{ $t('message.onBoarding.buildings.objects.tenancies.tenantType.own') }}
              </option>
              <option value="CORPORATE_TENANT_TYPE">
                {{ $t('message.onBoarding.buildings.objects.tenancies.tenantType.corporate') }}
              </option>
            </coozzy-form-select>
            <span
              v-else
              class="d-block">
              <span v-if="tenantType === 'EXTERNAL_TENANT_TYPE'"> {{
                $t('message.onBoarding.buildings.objects.tenancies.tenantType.external')
              }} </span>
              <span v-else-if="tenantType === 'OWN_TENANT_TYPE'"> {{
                $t('message.onBoarding.buildings.objects.tenancies.tenantType.own')
              }} </span>
              <span v-else-if="tenantType === 'CORPORATE_TENANT_TYPE'"> {{
                $t('message.onBoarding.buildings.objects.tenancies.tenantType.corporate')
              }} </span>
              <span v-else> - </span>
            </span>
          </div>
          <div class="col-sm-12 col-md-3">
            <coozzy-form-input
              v-model="dates.contractCreationDate"
              :is-read-only="readOnly"
              :filter="'formatDate'"
              type="date"
              :disabled="isInactive || ifMarketingAndTenancyNotActive"
              :name="$t('message.onBoarding.buildings.objects.tenancies.contractCreationDate')"
              @blur="dateToObject()" />
          </div>
          <div class="col-sm-12 col-md-3">
            <coozzy-form-input
              v-model="contractNumber"
              :disabled="true"
              :is-read-only="readOnly"
              :name="$t('message.onBoarding.buildings.objects.tenancies.contractNumber')" />
          </div>
          <div class="col-sm-12 col-md-3">
            <coozzy-form-input
              v-model="dates.startingAt"
              :is-read-only="readOnly"
              :filter="'formatDate'"
              :disabled="isInactive || fromMassRentalProcess || ((!tenancy.internalId && activated && !isAccountingModule) && !activeAndEditable) || ((!tenancy.internalId && activated && isAccountingModule) && !activeAndEditable) || ifMarketingAndTenancyNotActive"
              type="date"
              :state="errorStartAt || ($v.startingAt.$dirty && $v.startingAt.$error) ? false : null"
              :name="$t('message.onBoarding.buildings.objects.tenancies.startAt') + (readOnly ? '' : '*')"
              @blur="dateToObject('startingAt')" />
          </div>
          <div class="col-sm-12 col-md-3">
            <coozzy-form-input
              v-if="endOfContractFromEnterTermination"
              type="date"
              :value="$options.filters.objectToDateInput(endOfContractFromEnterTermination)"
              :is-read-only="true"
              :filter="'formatDate'"
              :disabled="isInactive || ifMarketingAndTenancyNotActive"
              :name="$t('message.onBoarding.buildings.objects.tenancies.endAt')" />
            <coozzy-form-input
              v-else-if="mode === 'full' || ( tenancy.contract.type !== 'INDEFINITE' && mode !== 'full' )"
              ref="endingAt"
              v-model="dates.endingAt"
              type="date"
              :state="$v.endingAt.$dirty && $v.endingAt.$error ? false : null"
              :is-read-only="readOnly"
              :filter="'formatDate'"
              :disabled="isInactive || ifMarketingAndTenancyNotActive || endingAtBeforeDebitUntil"
              :name="$t('message.onBoarding.buildings.objects.tenancies.endAt') + (!readOnly && (contractType === 'LIMITED' || isTerminationEntred() === true) || mandatoryTerminationFields === true ? '*' : '')"
              @blur="dateToObject('endingAt')" />
          </div>
          <div
            v-if="objectCategory === 'PARKING_SPACE'"
            class="col-sm-12 col-md-3">
            <coozzy-form-input
              v-model="licensePlate"
              :is-read-only="readOnly"
              :disabled="isInactive || ifMarketingAndTenancyNotActive"
              :name="$t('message.onBoarding.buildings.objects.tenancies.licensePlate')" />
          </div>
          <div class="col-sm-12 col-md-3">
            <label for="additionalCostsBillingType">
              {{ $t('message.onBoarding.buildings.objects.tenancies.additionalCostsBillingTypes.title') }} {{ readOnly ? '' : '*' }}
              <coozzy-info-circle-icon
                v-b-tooltip.hover.html="$t('message.onBoarding.buildings.objects.tenancies.additionalCostsBillingTypes.tooltip')" />
            </label>
            <coozzy-form-select
              v-if="!readOnly"
              id="additionalCostsBillingType"
              v-model="additionalCostsBillingType"
              :disabled="isInactive || ifMarketingAndTenancyNotActive">
              <option value="UNDEFINED_ADDITIONAL_COSTS_BILLING_TYPE">
                {{ $t('message.onBoarding.buildings.objects.tenancies.additionalCostsBillingTypes.options.UNDEFINED_ADDITIONAL_COSTS_BILLING_TYPE') }}
              </option>
              <option value="ADVANCE_PAYMENT_ADDITIONAL_COSTS_BILLING_TYPE">
                {{ $t('message.onBoarding.buildings.objects.tenancies.additionalCostsBillingTypes.options.ADVANCE_PAYMENT_ADDITIONAL_COSTS_BILLING_TYPE') }}
              </option>
              <option value="FLATRATE_ADDITIONAL_COSTS_BILLING_TYPE">
                {{ $t('message.onBoarding.buildings.objects.tenancies.additionalCostsBillingTypes.options.FLATRATE_ADDITIONAL_COSTS_BILLING_TYPE') }}
              </option>
            </coozzy-form-select>
            <span
              v-else
              class="d-block">
              {{ $t(`message.onBoarding.buildings.objects.tenancies.additionalCostsBillingTypes.options.${additionalCostsBillingType}`) }}
            </span>
          </div>
          <div
            v-if="objectCategory === 'APARTMENT' || objectCategory === 'HOUSE'"
            class="col-sm-12 col-md-3">
            <coozzy-form-input
              v-model="numberOfPersons"
              type="number"
              :min="0"
              :is-read-only="readOnly"
              :is-interger="true"
              :disabled="isInactive || ifMarketingAndTenancyNotActive"
              :state="$v.numberOfPersons.$dirty && $v.numberOfPersons.$error ? false : null"
              :name="$t('message.onBoarding.buildings.objects.tenancies.numberOfPersons')" />
          </div>
          <div
            v-if="objectCategory !== 'GARDEN' && objectCategory !== 'HOUSE' && objectCategory !== 'APARTMENT' && objectCategory !== 'PARKING_SPACE' && objectCategory !== 'SECONDARY_ROOM'"
            class="col-sm-12 col-md-3">
            <coozzy-form-input
              v-model="industryName"
              :is-read-only="readOnly"
              :disabled="isInactive || ifMarketingAndTenancyNotActive"
              :name="$t('message.onBoarding.buildings.objects.tenancies.industryCode.title')" />
          </div>
          <div
            v-if="objectCategory === 'COMMERCIAL' || objectCategory === 'SECONDARY_ROOM'"
            class="col-sm-12 col-md-3">
            <coozzy-form-input
              v-model="intendedUse"
              :is-read-only="readOnly"
              :disabled="isInactive || ifMarketingAndTenancyNotActive"
              :name="$t('message.onBoarding.buildings.objects.tenancies.intendedUse')" />
          </div>
          <div class="col-sm-12 col-md-3">
            <coozzy-form-textarea
              v-model="contractAmendments"
              :is-read-only="readOnly"
              :initial="contractAmendments"
              :text="contractAmendments"
              class="contractAmendments"
              :disabled="isInactive || ifMarketingAndTenancyNotActive"
              :placeholder="$t('message.onBoarding.buildings.objects.tenancies.contractAmendmentsPlaceholder')">
              {{ $t('message.onBoarding.buildings.objects.tenancies.contractAmendments') }}
            </coozzy-form-textarea>
          </div>
          <div class="col-sm-12 col-md-3">
            <label for="tenancyStatus">
              {{ $t('message.onBoarding.buildings.objects.tenancies.tenancyStatus.title') + (readOnly ? '' : '*') }}
            </label>
            <coozzy-form-select
              v-if="!readOnly"
              id="tenancyStatus"
              v-model="tenancyStatus"
              :disabled="isInactive || isInprogress || ifMarketingAndTenancyNotActive"
              :state="$v.tenancyStatus.$dirty && $v.tenancyStatus.$error ? false : null">
              <option
                v-if="!hideInprogress || tenancy.statusEdited"
                :value="null">
                -
              </option>
              <option
                v-if="!hideInprogress || tenancy.statusEdited"
                value="inProgress">
                {{ $t('message.onBoarding.buildings.objects.tenancies.tenancyStatus.inProgress') }}
              </option>
              <option
                value="active">
                {{ $t('message.onBoarding.buildings.objects.tenancies.tenancyStatus.active') }}
              </option>
              <option
                v-if="endingDatebeforeNow"
                value="activeClosed">
                {{ $t('message.onBoarding.buildings.objects.tenancies.tenancyStatus.activeClosed') }}
              </option>
            </coozzy-form-select>
            <span
              v-else
              class="d-block">
              <span v-if="tenancyStatus === null">
                -
              </span>
              <span v-else-if="tenancyStatus === 'inProgress'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.tenancyStatus.inProgress') }}
              </span>
              <span v-else-if="tenancyStatus === 'active'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.tenancyStatus.active') }}
              </span>
              <span v-else-if="tenancyStatus === 'activeClosed'">
                {{ $t('message.onBoarding.buildings.objects.tenancies.tenancyStatus.activeClosed') }}
              </span>
              <span v-else> - </span>
            </span>
          </div>

          <template v-if="objectCategory === 'HOUSE' || objectCategory === 'APARTMENT'">
            <div
              v-if="!readOnly"
              class="col-sm-12 col-md-3">
              <coozzy-form-checkbox
                v-model="familyFlat"
                :initial="familyFlat"
                name="familyFlat"
                :disabled="isInactive || ifMarketingAndTenancyNotActive"
                class="mt-4">
                {{ $t('message.onBoarding.buildings.objects.tenancies.familyFlat') }}
              </coozzy-form-checkbox>
            </div>
            <div
              v-else
              class="col-sm-12 col-md-3">
              <label>
                {{ $t('message.onBoarding.buildings.objects.tenancies.familyFlat') }}
              </label>
              <span class="d-block">
                {{ familyFlat ? $t('message.generic.selectField.yes') : $t('message.generic.selectField.no') }}
              </span>
            </div>
            <div
              v-if="!readOnly && (mode === 'adding' || mode === 'activate')"
              class="col-sm-12 col-md-3">
              <coozzy-form-checkbox
                v-model="secondaryResidence"
                :initial="secondaryResidence"
                name="secondaryResidence"
                :disabled="isInactive || ifMarketingAndTenancyNotActive"
                class="mt-4">
                {{ $t('message.onBoarding.buildings.objects.tenancies.secondaryResidence') }}
              </coozzy-form-checkbox>
            </div>
            <div
              v-else-if="readOnly && (mode === 'adding' || mode === 'activate')"
              class="col-sm-12 col-md-3">
              <label>
                {{ $t('message.onBoarding.buildings.objects.tenancies.secondaryResidence') }}
              </label>
              <span class="d-block">
                {{ secondaryResidence ? $t('message.generic.selectField.yes') : $t('message.generic.selectField.no') }}
              </span>
            </div>
            <div
              v-if="!readOnly && mode !== 'adding' && mode !== 'activate'"
              class="col-sm-12 col-md-3">
              <coozzy-form-checkbox
                v-model="secondaryResidence"
                :initial="secondaryResidence"
                :disabled="isInactive || ifMarketingAndTenancyNotActive"
                name="secondaryResidence"
                class="mt-4">
                {{ $t('message.onBoarding.buildings.objects.tenancies.secondaryResidence') }}
              </coozzy-form-checkbox>
            </div>
            <div
              v-else-if="readOnly && mode !== 'adding' && mode !== 'activate'"
              class="col-sm-12 col-md-3">
              <label>
                {{ $t('message.onBoarding.buildings.objects.tenancies.secondaryResidence') }}
              </label>
              <span class="d-block">
                {{ secondaryResidence ? $t('message.generic.selectField.yes') : $t('message.generic.selectField.no') }}
              </span>
            </div>
          </template>
          <div
            v-if="(property && property.opted === true) || (['TYPE_OF_USE_PARKING_EXTERNAL', 'TYPE_OF_USE_SECONDARY_ROOM', 'TYPE_OF_USE_STORAGE', 'TYPE_OF_USE_ADVERTISING_AREA', 'TYPE_OF_USE_RADIO_ANTENNA'].includes(typeOfUse))"
            class="col-sm-12 col-md-3">
            <label for="tax">
              {{ $t('message.onBoarding.buildings.objects.tenancies.tax.title') }}
            </label>
            <coozzy-form-select
              v-if="!readOnly"
              id="tax"
              v-model="taxable"
              :disabled="(object.debitUntil && new Date($options.filters.objectToDateInput(object.debitUntil)) > new Date($options.filters.objectToDateInput(tenancy.startingAt)))">
              <option
                :value="null">
                -
              </option>
              <option
                :disabled="(property && property.opted === false)"
                :value="false">
                {{ $t('message.onBoarding.buildings.objects.tenancies.tax.opted') }}
              </option>
              <option
                v-if="(property && property.opted === false) && (['TYPE_OF_USE_PARKING_EXTERNAL', 'TYPE_OF_USE_SECONDARY_ROOM', 'TYPE_OF_USE_STORAGE', 'TYPE_OF_USE_ADVERTISING_AREA', 'TYPE_OF_USE_RADIO_ANTENNA'].includes(typeOfUse))"
                :value="true">
                {{ $t('message.onBoarding.buildings.objects.tenancies.tax.taxable') }}
              </option>
            </coozzy-form-select>
            <span
              v-else
              class="d-block">
              <span v-if="taxable === null">
                -
              </span>
              <span v-else-if="taxable === false">
                {{ $t('message.onBoarding.buildings.objects.tenancies.tax.opted') }}
              </span>
              <span v-else-if="taxable === true">
                {{ $t('message.onBoarding.buildings.objects.tenancies.tax.taxable') }}
              </span>
              <span v-else> - </span>
            </span>
          </div>
          <div :class="readOnly ? 'col-sm-12 col-md-3' : 'col'">
            <coozzy-form-textarea
              v-model="note"
              :is-read-only="readOnly"
              :initial="note"
              :text="note"
              :disabled="isInactive || ifMarketingAndTenancyNotActive">
              {{ $t('message.onBoarding.buildings.objects.tenancies.note') }}
            </coozzy-form-textarea>
          </div>
        </div>
      </b-collapse>
      <div
        v-if="!readOnly || adjustments.length > 0"
        class="row">
        <div class="col-12">
          <hr class="mt-0">
        </div>
        <div class="col-12">
          <h6
            v-b-toggle="`collapse-tenancy-${index}-adjustedcosts`"
            class="cursor-pointer hover font-weight-bold mt-3 width-only-content">
            {{ $t('message.onBoarding.buildings.objects.pricing.rents') }}
          </h6>
        </div>
      </div>
      <b-collapse
        :id="`collapse-tenancy-${index}-adjustedcosts`"
        :visible="expandAllChild || openedCollapses.includes(`collapse-tenancy-${index}-adjustedcosts`)">
        <template v-for="(adjustment, adjIndex) in adjustments">
          <tenancy-adjustment
            ref="tenancy-adjustment"
            :key="adjustment.internalId"
            :index="adjIndex"
            :adjustment="adjustment"
            :tenancy="tenancy"
            :read-only="readOnly"
            :tenancy-mode="mode"
            :object="currentObject"
            :opted="opted || taxable"
            :active-and-editable="activeAndEditableAdjustment"
            :disabled-field="ifMarketingAndTenancyNotActive"
            :is-most-recent="adjustments[0] === adjustment"
            :until-date="convertTimestampToISODate(adjustments[adjIndex - 1] ? getPreviousDayOfTimestamp(adjustments[adjIndex - 1].byDate) : -1)"
            :request-id="requestId"
            :from-mass-rental-process="fromMassRentalProcess"
            @delete-adjustment="deleteAdjustmentClicked"
            @change-adjustment="changeAdjustment" />
        </template>
        <div class="col-12 px-0 mt-2">
          <hr
            v-if="!readOnly && !isInactive"
            class="mt-0">
        </div>
        <div class="col-2 my-2 pr-2 pl-0 ml-0">
          <coozzy-button
            v-if="!readOnly && !isInactive"
            class="w-100"
            design="prop-green"
            @click="addAdjustmentClicked">
            {{ $t('message.onBoarding.buildings.objects.tenancies.addNewRent') }}
          </coozzy-button>
        </div>
      </b-collapse>
      <div
        v-if="rentType === 'RENT_TYPE_INDEX' || rentType === 'RENT_TYPE_TURNOVER'"
        class="row">
        <div class="col-12">
          <hr class="mt-0">
        </div>
        <div class="col">
          <h6
            v-b-toggle="`collapse-tenancy-${index}-index`"
            class="cursor-pointer hover font-weight-bold mt-3 width-only-content">
            {{ $t('message.onBoarding.buildings.objects.tenancies.indexData.title') }}
          </h6>
        </div>
      </div>
      <b-collapse
        v-if="rentType === 'RENT_TYPE_INDEX' || rentType === 'RENT_TYPE_TURNOVER'"
        :id="`collapse-tenancy-${index}-index`"
        :visible="expandAllChild || openedCollapses.includes(`collapse-tenancy-${index}-index`)">
        <div class="row">
          <div class="col">
            <h6 class="font-weight-bold mt-3">
              {{ $t('message.onBoarding.buildings.objects.tenancies.indexData.general') }}
            </h6>
          </div>
        </div>
        <div
          class="row py-01rem"
          :class="readOnly ? 'details-row-bg-1' : 'details-row-bg-2'">
          <div class="col-sm-12 col-md-3">
            <coozzy-form-input
              v-model="dates.firstRentAdjustmentPossibleAt"
              :is-read-only="readOnly"
              :filter="'formatDate'"
              type="date"
              :disabled="isInactive || ifMarketingAndTenancyNotActive"
              :name="$t('message.onBoarding.buildings.objects.tenancies.indexData.firstRentAdjustmentPossibleOn')"
              :state="$v.firstRentAdjustmentPossibleAt.$dirty && $v.firstRentAdjustmentPossibleAt.$error ? false : null"
              @blur="dateToObject()" />
          </div>
          <div class="col-sm-12 col-md-3">
            <coozzy-form-input-numeric
              v-model="rentAdjustmentByMonths"
              type="number"
              :min="0"
              :disabled="isInactive || ifMarketingAndTenancyNotActive"
              :is-read-only="readOnly"
              :is-decimal="true"
              :name="$t('message.onBoarding.buildings.objects.tenancies.indexData.rentAdjustmentByMonth')" />
          </div>
          <div class="col-sm-12 col-md-3">
            <coozzy-form-input-numeric
              v-model="rentAdjustmentByPoints"
              type="number"
              :min="0"
              :disabled="isInactive || ifMarketingAndTenancyNotActive"
              :is-read-only="readOnly"
              :is-decimal="true"
              :name="$t('message.onBoarding.buildings.objects.tenancies.indexData.rentAdjustmentByPoints')" />
          </div>
          <div class="col-sm-12 col-md-3">
            <coozzy-form-input-numeric
              v-model="contractPercentImputedAtIndex"
              type="number"
              :min="0"
              :is-read-only="readOnly"
              :is-decimal="true"
              :disabled="isInactive || ifMarketingAndTenancyNotActive"
              :name="$t('message.onBoarding.buildings.objects.tenancies.indexData.contractImputedAtIndex')" />
          </div>
        </div>
        <div class="row details-row-bg-2 py-01rem">
          <div class="col-sm-12 col-md-3">
            <label for="referenceMonthForIndex">
              {{ $t('message.onBoarding.buildings.objects.tenancies.indexData.referenceMonthForIndex') }}
            </label>
            <coozzy-form-select
              v-if="!readOnly"
              id="referenceMonthForIndex"
              v-model="referenceMonthForIndex"
              :disabled="isInactive || ifMarketingAndTenancyNotActive">
              <option :value="null || -1">
                -
              </option>
              <option
                v-for="m in valueMonth"
                :key="m"
                :value="m">
                {{ m }}
              </option>
            </coozzy-form-select>
            <span
              v-else
              class="d-block">
              {{ referenceMonthForIndex }}
            </span>
          </div>
          <div class="col-sm-12 col-md-3">
            <coozzy-form-input-numeric
              v-model="maximumRate"
              type="number"
              :min="0"
              :disabled="isInactive || ifMarketingAndTenancyNotActive"
              :is-read-only="readOnly"
              :is-decimal="true"
              :name="$t('message.onBoarding.buildings.objects.tenancies.indexData.maximumRate')" />
          </div>
          <div class="col-sm-12 col-md-3">
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <coozzy-form-input-numeric
                  v-model="rentAdjustmentNotificationValue"
                  type="number"
                  :min="0"
                  :disabled="isInactive || ifMarketingAndTenancyNotActive"
                  :is-read-only="readOnly"
                  :is-integer="true"
                  :name="$t('message.onBoarding.buildings.objects.tenancies.indexData.rentAdjustmentNotification.title')" />
              </div>
              <div class="col-sm-12 col-md-6 pl-0 mt-4">
                <coozzy-form-select
                  v-if="!readOnly"
                  id="rentAdjustmentNotificationUnit"
                  v-model="rentAdjustmentNotificationUnit"
                  :disabled="isInactive || ifMarketingAndTenancyNotActive">
                  <option value="RENT_ADJUSTMENT_NOTIFICATION_DAYS">
                    {{ $t('message.onBoarding.buildings.objects.tenancies.indexData.rentAdjustmentNotification.RENT_ADJUSTMENT_NOTIFICATION_DAYS') }}
                  </option>
                  <option value="RENT_ADJUSTMENT_NOTIFICATION_MONTHS">
                    {{ $t('message.onBoarding.buildings.objects.tenancies.indexData.rentAdjustmentNotification.RENT_ADJUSTMENT_NOTIFICATION_MONTHS') }}
                  </option>
                </coozzy-form-select>
                <span
                  v-else
                  class="d-block">
                  {{ $t('message.onBoarding.buildings.objects.tenancies.indexData.rentAdjustmentNotification.' + rentAdjustmentNotificationUnit) }}
                </span>
              </div>
            </div>
          </div>
          <div
            v-if="readOnly"
            class="col-sm-12 col-md-3">
            <label for="rentAdjustmentMonths">
              {{ $t('message.onBoarding.buildings.objects.tenancies.indexData.rentAdjustmentMonths') }}
            </label>
            <p
              v-if="getRentAdjustmentMonths.length > 0"
              class="mb-0 multi-select-value">
              {{ getRentAdjustmentMonths }}
            </p>
            <p
              v-else
              class="mb-0 multi-select-value">
              -
            </p>
          </div>
          <div
            v-else
            class="col-sm-12 col-md-3">
            <coozzy-multiselect
              id="rentAdjustmentMonths"
              v-model="rentAdjustmentMonths"
              :options="translatedMonths"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              :preselect-first="false"
              :taggable="false"
              :disabled="isInactive || ifMarketingAndTenancyNotActive"
              label="label"
              track-by="label">
              {{ $t('message.onBoarding.buildings.objects.tenancies.indexData.rentAdjustmentMonths') }}
            </coozzy-multiselect>
          </div>
        </div>
        <div
          class="row py-01rem"
          :class="readOnly ? 'details-row-bg-1' : 'details-row-bg-2'">
          <div
            v-if="!readOnly"
            class="col-sm-6 col-md-3 col-6 mt-4">
            <coozzy-form-checkbox
              v-model="rentAdjustmentAdditionalBenefit"
              :initial="rentAdjustmentAdditionalBenefit"
              :disabled="isInactive || ifMarketingAndTenancyNotActive"
              name="rentAdjustmentAdditionalBenefit">
              {{ $t('message.onBoarding.buildings.objects.tenancies.indexData.rentAdjustmentAdditionalBenefit') }}
            </coozzy-form-checkbox>
          </div>
          <div
            v-else
            class="col-sm-6 col-md-3 col-6">
            <label for="rentAdjustmentAdditionalBenefit">{{ $t('message.onBoarding.buildings.objects.tenancies.indexData.rentAdjustmentAdditionalBenefit')
            }}</label>
            <span
              class="show-data">{{ rentAdjustmentAdditionalBenefit === null || rentAdjustmentAdditionalBenefit === false ? $t('message.generic.selectField.no') : $t('message.generic.selectField.yes') }}</span>
          </div>
          <div
            v-if="!readOnly"
            class="col-sm-6 col-md-3 col-6 mt-4">
            <coozzy-form-checkbox
              v-model="belowInitialRentAllowed"
              :initial="belowInitialRentAllowed"
              :disabled="isInactive || ifMarketingAndTenancyNotActive"
              name="belowInitialRentAllowed">
              {{ $t('message.onBoarding.buildings.objects.tenancies.indexData.belowInitialRentAllowed') }}
            </coozzy-form-checkbox>
          </div>
          <div
            v-else
            class="col-sm-6 col-md-3 col-6">
            <label for="belowInitialRentAllowed">{{ $t('message.onBoarding.buildings.objects.tenancies.indexData.belowInitialRentAllowed')
            }}</label>
            <span
              class="show-data">{{ belowInitialRentAllowed === null || belowInitialRentAllowed === false ? $t('message.generic.selectField.no') : $t('message.generic.selectField.yes') }}</span>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col">
            <h6 class="font-weight-bold mt-4">
              {{ $t('message.onBoarding.buildings.objects.tenancies.indexData.additionalCosts') }}
            </h6>
          </div>
        </div>
        <div
          class="row py-01rem"
          :class="readOnly ? 'details-row-bg-1' : 'details-row-bg-2'">
          <div
            v-if="!readOnly"
            class="col-sm-6 col-md-3 col-6">
            <coozzy-form-checkbox
              v-model="additionalCostsAdjustmentAllowed"
              :initial="additionalCostsAdjustmentAllowed"
              :disabled="isInactive || ifMarketingAndTenancyNotActive"
              name="additionalCostsAdjustmentAllowed">
              {{ $t('message.onBoarding.buildings.objects.tenancies.indexData.additionalCostsAdjustmentAllowed') }}
            </coozzy-form-checkbox>
          </div>
          <div
            v-else
            class="col-sm-6 col-md-3 col-6">
            <label for="additionalCostsAdjustmentAllowed">{{ $t('message.onBoarding.buildings.objects.tenancies.indexData.additionalCostsAdjustmentAllowed')
            }}</label>
            <span
              class="show-data">{{ additionalCostsAdjustmentAllowed === null || additionalCostsAdjustmentAllowed === false ? $t('message.generic.selectField.no') : $t('message.generic.selectField.yes') }}</span>
          </div>
        </div>
      </b-collapse>
      <div
        v-if="(checkOptions && !readOnly) || (checkOptions && readOnly && (hasOneOrMore(renewalOptions) || hasOneOrMore(earlyRightOfTerminationOptions)))"
        class="row">
        <div class="col-12">
          <hr class="mt-0">
        </div>
        <div class="col">
          <h6
            v-b-toggle="`collapse-tenancy-${index}-option`"
            class="cursor-pointer hover font-weight-bold mt-3 width-only-content">
            {{ $t('message.onBoarding.buildings.objects.tenancies.option.title') }}
          </h6>
        </div>
      </div>
      <b-collapse
        v-if="checkOptions"
        :id="`collapse-tenancy-${index}-option`"
        :visible="expandAllChild || openedCollapses.includes(`collapse-tenancy-${index}-option`)">
        <div
          v-if="(hasOneOrMore(renewalOptions) && readOnly) || !readOnly"
          class="row">
          <div class="col">
            <h6 class="font-weight-bold mt-3 mb-2">
              {{ $t('message.onBoarding.buildings.objects.tenancies.option.renewalOption') }}
            </h6>
          </div>
        </div>
        <template
          v-for="(renewalOption, i) in renewalOptions">
          <template
            v-if="(dates.renewalOptions[i].from || dates.renewalOptions[i].exerciseUntil || dates.renewalOptions[i].until || dates.renewalOptions[i].type || dates.renewalOptions[i].automaticExtending) || !readOnly">
            <div
              :key="'optionFrom'+i"
              class="row py-01rem"
              :class="i % 2 && readOnly ? 'details-row-bg-1' : 'details-row-bg-2'">
              <div class="col-sm-12 col-md-3">
                <label
                  class="mb-2"
                  for="optionFrom">{{ i + 1 }}. {{ $t('message.onBoarding.buildings.objects.tenancies.option.optionFrom') }}</label>
                <template v-if="readOnly">
                  <label
                    class="mb-2"
                    for="optionUntil">{{ i + 1 }}. {{ $t('message.onBoarding.buildings.objects.tenancies.option.optionUntil') }}</label>
                  <label
                    class="mb-2"
                    for="exerciseUntil">{{ $t('message.onBoarding.buildings.objects.tenancies.option.exerciseUntil') }}</label>
                </template>
              </div>
              <div class="col-sm-12 col-md-3">
                <coozzy-form-input
                  v-model="dates.renewalOptions[i].from"
                  class="mb-2"
                  type="date"
                  :is-read-only="readOnly"
                  :disabled="isInactive || (i > 0 && !dates.renewalOptions[i - 1].from) || ifMarketingAndTenancyNotActive || renewalOptionsReadOnly[i].used"
                  :filter="'formatDate'"
                  @blur="optionDateToObject()" />
                <p
                  v-if="renewalOption.used && !readOnly"
                  class="already-used">
                  {{ $t('message.onBoarding.buildings.objects.tenancies.option.optionExercised') }}
                </p>
                <template v-if="readOnly">
                  <coozzy-form-input
                    v-model="dates.renewalOptions[i].until"
                    class="mb-2"
                    type="date"
                    :is-read-only="readOnly"
                    :disabled="isInactive || (i > 0 && !dates.renewalOptions[i - 1].until) || ifMarketingAndTenancyNotActive || renewalOptionsReadOnly[i].used"
                    :state="($v.renewalOptions.$each[i].until.$dirty && $v.renewalOptions.$each[i].until.$error) ? false : null"
                    :filter="'formatDate'"
                    @blur="optionDateToObject()" />
                  <coozzy-form-input
                    v-model="dates.renewalOptions[i].exerciseUntil"
                    class="mb-2"
                    type="date"
                    :is-read-only="readOnly"
                    :filter="'formatDate'"
                    :disabled="isInactive || (i > 0 && !dates.renewalOptions[i-1].exerciseUntil) || ifMarketingAndTenancyNotActive || renewalOptionsReadOnly[i].used"
                    :state="($v.renewalOptions.$each[i].exerciseUntil.$dirty && $v.renewalOptions.$each[i].exerciseUntil.$error) ? false : null"
                    @blur="optionDateToObject()" />
                </template>
              </div>
              <div
                class="col-sm-12 col-md-3"
                :class="!readOnly ? 'optionType-position' : ''">
                <label for="renewalType">
                  {{ $t('message.onBoarding.buildings.objects.tenancies.optionType.title') }}
                  <coozzy-info-circle-icon
                    v-b-tooltip.hover.html="$t('message.onBoarding.buildings.objects.tenancies.optionType.toolTip')"
                    class="mt-2 tooltip-wide" />
                </label>

                <coozzy-form-select
                  v-if="!readOnly"
                  id="renewalType"
                  v-model="tenancy.renewalOptions[i].type"
                  :disabled="isInactive || ifMarketingAndTenancyNotActive"
                  @change="() => { dispatchTenancyUpdate(tenancy) }">
                  <option value="RENEWAL_OPTION_TYPE_UNDEFINED">
                    -
                  </option>
                  <option value="RENEWAL_OPTION_TYPE_REAL">
                    {{ $t(`message.onBoarding.buildings.objects.tenancies.option.realOption`) }}
                  </option>
                  <option value="RENEWAL_OPTION_TYPE_UNREAL">
                    {{ $t(`message.onBoarding.buildings.objects.tenancies.option.unrealOption`) }}
                  </option>
                </coozzy-form-select>
                <span
                  v-else
                  class="d-block">
                  <span v-if="tenancy.renewalOptions[i].type === 'RENEWAL_OPTION_TYPE_REAL'">
                    {{ $t('message.onBoarding.buildings.objects.tenancies.option.realOption') }}
                  </span>
                  <span v-else-if="tenancy.renewalOptions[i].type === 'RENEWAL_OPTION_TYPE_UNREAL'">
                    {{ $t('message.onBoarding.buildings.objects.tenancies.option.unrealOption') }}
                  </span>
                  <span v-else> - </span>
                </span>
              </div>

              <div class="col-sm-12 col-md-3">
                <template v-if="!readOnly">
                  <coozzy-form-checkbox
                    v-model="tenancy.renewalOptions[i].automaticExtending"
                    :initial="tenancy.renewalOptions[i].automaticExtending"
                    :disabled="isInactive || ifMarketingAndTenancyNotActive"
                    name="depositPaidOut"
                    class="automaticExtending-margin-left"
                    @change="() => { dispatchTenancyUpdate(tenancy) }">
                    {{ $t('message.onBoarding.buildings.objects.tenancies.automaticExtending.title') }}
                    <coozzy-info-circle-icon
                      v-b-tooltip.hover.html="$t('message.onBoarding.buildings.objects.tenancies.automaticExtending.toolTip')"
                      class="tooltip-wide" />
                  </coozzy-form-checkbox>
                </template>
                <template v-else>
                  <label for="optionUntil">{{ $t('message.onBoarding.buildings.objects.tenancies.automaticExtending.title') }}</label>
                  <coozzy-form-input
                    v-model="renewalOptionsReadOnly[i].automaticExtending"
                    type="date"
                    :is-read-only="true" />
                </template>
              </div>
            </div>
          </template>
          <template
            v-if="!readOnly">
            <div
              :key="'optionUntil'+i"
              class="row py-01rem pb-2"
              :class="i % 2 && readOnly ? 'details-row-bg-1' : 'details-row-bg-2'">
              <div class="col-sm-12 col-md-3">
                <label for="optionUntil">{{ i + 1 }}. {{ $t('message.onBoarding.buildings.objects.tenancies.option.optionUntil') }}</label>
              </div>
              <div class="col-sm-12 col-md-3">
                <coozzy-form-input
                  v-model="dates.renewalOptions[i].until"
                  type="date"
                  :is-read-only="readOnly"
                  :disabled="isInactive || (i > 0 && !dates.renewalOptions[i - 1].until) || ifMarketingAndTenancyNotActive || renewalOptionsReadOnly[i].used"
                  :state="($v.renewalOptions.$each[i].until.$dirty && $v.renewalOptions.$each[i].until.$error) ? false : null"
                  :filter="'formatDate'"
                  @blur="optionDateToObject()" />
                <p
                  v-if="renewalOption.used && !readOnly"
                  class="already-used">
                  {{ $t('message.onBoarding.buildings.objects.tenancies.option.optionExercised') }}
                </p>
              </div>
            </div>
            <div
              :key="'exerciseUntil'+i"
              class="row py-01rem mb-3"
              :class="i % 2 && readOnly ? 'details-row-bg-1' : 'details-row-bg-2'">
              <div class="col-sm-12 col-md-3">
                <label for="exerciseUntil">{{ $t('message.onBoarding.buildings.objects.tenancies.option.exerciseUntil') }}</label>
              </div>
              <div class="col-sm-12 col-md-3">
                <coozzy-form-input
                  v-model="dates.renewalOptions[i].exerciseUntil"
                  type="date"
                  :is-read-only="readOnly"
                  :filter="'formatDate'"
                  :disabled="isInactive || (i > 0 && !dates.renewalOptions[i-1].exerciseUntil) || ifMarketingAndTenancyNotActive || renewalOptionsReadOnly[i].used"
                  :state="($v.renewalOptions.$each[i].exerciseUntil.$dirty && $v.renewalOptions.$each[i].exerciseUntil.$error) ? false : null"
                  @blur="optionDateToObject()" />
                <p
                  v-if="renewalOption.used && !readOnly"
                  class="already-used">
                  {{ $t('message.onBoarding.buildings.objects.tenancies.option.optionExercised') }}
                </p>
              </div>
            </div>
          </template>
        </template>
        <div
          v-if="(hasOneOrMore(earlyRightOfTerminationOptions) && readOnly) || !readOnly"
          class="row">
          <div class="col">
            <h6 class="font-weight-bold mt-3 mb-2">
              {{ $t('message.onBoarding.buildings.objects.tenancies.option.earlyRightTermination') }}
            </h6>
          </div>
        </div>
        <template
          v-for="(arlyRightOfTerminationOption, i) in earlyRightOfTerminationOptions">
          <template
            v-if="(dates.earlyRightOfTerminationOptions[i].until || dates.earlyRightOfTerminationOptions[i].exerciseUntil) || !readOnly">
            <div
              :key="'optionUntilEarly'+i"
              class="row py-01rem mb-2"
              :class="i % 2 && readOnly ? 'details-row-bg-1' : 'details-row-bg-2'">
              <div class="col-sm-12 col-md-3">
                <label for="optionUntil">{{ i + 1 }}. {{ $t('message.onBoarding.buildings.objects.tenancies.option.optionPer')
                }}</label>
              </div>
              <div class="col-sm-12 col-md-3">
                <coozzy-form-input
                  v-model="dates.earlyRightOfTerminationOptions[i].until"
                  type="date"
                  :is-read-only="readOnly"
                  :disabled="isInactive || (i > 0 && !dates.earlyRightOfTerminationOptions[i-1].until) || ifMarketingAndTenancyNotActive"
                  :state="($v.earlyRightOfTerminationOptions.$each[i].until.$dirty && $v.earlyRightOfTerminationOptions.$each[i].until.$error) ? false : null"
                  :filter="'formatDate'"
                  @blur="optionDateToObject()" />
              </div>
            </div>
            <div
              :key="'exerciseUntilErly'+i"
              class="row py-01rem mb-3"
              :class="i % 2 && readOnly ? 'details-row-bg-1' : 'details-row-bg-2'">
              <div class="col-sm-12 col-md-3">
                <label for="exerciseUntil">{{ $t('message.onBoarding.buildings.objects.tenancies.option.exerciseUntil')
                }}</label>
              </div>
              <div class="col-sm-12 col-md-3">
                <coozzy-form-input
                  v-model="dates.earlyRightOfTerminationOptions[i].exerciseUntil"
                  type="date"
                  :is-read-only="readOnly"
                  :filter="'formatDate'"
                  :disabled="isInactive || (i > 0 && !dates.earlyRightOfTerminationOptions[i-1].exerciseUntil) || ifMarketingAndTenancyNotActive"
                  :state="($v.earlyRightOfTerminationOptions.$each[i].exerciseUntil.$dirty && $v.earlyRightOfTerminationOptions.$each[i].exerciseUntil.$error) ? false : null"
                  @blur="optionDateToObject()" />
              </div>
            </div>
          </template>
        </template>
      </b-collapse>
      <div
        class="row">
        <div class="col-12">
          <hr class="mt-0">
        </div>
        <div class="col">
          <h6
            v-b-toggle="`collapse-tenancy-${index}-termination`"
            class="cursor-pointer hover font-weight-bold mt-3 width-only-content">
            {{ $t('message.onBoarding.buildings.objects.tenancies.terminationTitle') }}
          </h6>
        </div>
      </div>
      <b-collapse
        :id="`collapse-tenancy-${index}-termination`"
        :visible="expandAllChild || openedCollapses.includes(`collapse-tenancy-${index}-termination`)">
        <div class="row">
          <div class="col">
            <h6 class="font-weight-bold mt-3">
              {{ $t('message.onBoarding.buildings.objects.tenancies.terminationTenant') }}
            </h6>
          </div>
        </div>
        <div
          class="row py-01rem"
          :class="readOnly ? 'details-row-bg-1' : 'details-row-bg-2'">
          <div class="col-sm-12 col-md-3">
            <coozzy-form-input-numeric
              v-model="tenantPeriodInMonths"
              type="number"
              :min="0"
              :is-read-only="readOnly"
              :is-integer="true"
              :disabled="isInactive || ifMarketingAndTenancyNotActive"
              :check-valide="$v.tenantPeriodInMonths.$dirty && $v.tenantPeriodInMonths.$error ? 'is-invalid' : ''"
              :name="$t('message.onBoarding.buildings.objects.tenancies.noticePeriod')" />
          </div>
          <div class="col-sm-12 col-md-3">
            <coozzy-form-input
              v-model="tenantEarliestDateCom"
              type="date"
              :is-read-only="readOnly"
              :state="($v.tenantEarliestDate.$dirty && $v.tenantEarliestDate.$error && dates.tenantEarliestDate) ? false : null"
              :filter="'formatDate'"
              :disabled="isInactive || ifMarketingAndTenancyNotActive"
              :name="$t('message.onBoarding.buildings.objects.tenancies.earliestQuit')"
              @blur="dateToObject()" />
          </div>
          <div
            v-if="readOnly"
            class="col-sm-12 col-md-6">
            <label for="tenantPossibleMonths">
              {{ $t('message.onBoarding.buildings.objects.tenancies.noticeToQuit') }}
            </label>
            <p
              v-if="getTenantPossibleMonths.length > 0"
              class="mb-0 multi-select-value">
              {{ getTenantPossibleMonths }}
            </p>
            <p
              v-else
              class="mb-0 multi-select-value">
              -
            </p>
          </div>
          <div
            v-else
            class="col-sm-12 col-md-6">
            <coozzy-multiselect
              id="tenantPossibleMonths"
              v-model="tenantPossibleMonths"
              :options="translatedMonths"
              :check-valide="$v.tenantPossibleMonths.$dirty && $v.tenantPossibleMonths.$error ? 'is-invalid' : ''"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              :preselect-first="false"
              :taggable="false"
              :disabled="isInactive || ifMarketingAndTenancyNotActive"
              label="label"
              track-by="label">
              {{ $t('message.onBoarding.buildings.objects.tenancies.noticeToQuit') + '*' }}
            </coozzy-multiselect>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <h6 class="d-inline-block font-weight-bold mt-3">
              {{ $t('message.onBoarding.buildings.objects.tenancies.terminationLandlord') }}
            </h6>
            <coozzy-button
              v-if="!readOnly"
              class="ml-3"
              @click="sameAsTenantTermination">
              {{ $t('message.onBoarding.buildings.objects.tenancies.sameAsTenantTermination') }}
            </coozzy-button>
          </div>
        </div>
        <div
          class="row py-01rem mb-3"
          :class="readOnly ? 'details-row-bg-1' : 'details-row-bg-2'">
          <div class="col-sm-12 col-md-3">
            <coozzy-form-input-numeric
              v-model="landlordPeriodInMonths"
              type="number"
              :min="0"
              :is-read-only="readOnly"
              :is-integer="true"
              :disabled="isInactive || ifMarketingAndTenancyNotActive"
              :check-valide="$v.landlordPeriodInMonths.$dirty && $v.landlordPeriodInMonths.$error ? 'is-invalid' : ''"
              :name="$t('message.onBoarding.buildings.objects.tenancies.noticePeriod')" />
          </div>
          <div class="col-sm-12 col-md-3">
            <coozzy-form-input
              v-model="landlordEarliestDateCom"
              type="date"
              :is-read-only="readOnly"
              :state="($v.landlordEarliestDate.$dirty && $v.landlordEarliestDate.$error && dates.landlordEarliestDate) ? false : null"
              :filter="'formatDate'"
              :disabled="isInactive || ifMarketingAndTenancyNotActive"
              :name="$t('message.onBoarding.buildings.objects.tenancies.earliestQuit')"
              @blur="dateToObject()" />
          </div>
          <div
            v-if="readOnly"
            class="col-sm-12 col-md-6">
            <label for="tenantPossibleMonths">
              {{ $t('message.onBoarding.buildings.objects.tenancies.noticeToQuit') }}
            </label>
            <p
              v-if="getLandlordPossibleMonths.length > 0"
              class="mb-0 multi-select-value">
              {{ getLandlordPossibleMonths }}
            </p>
            <p
              v-else
              class="mb-0 multi-select-value">
              -
            </p>
          </div>
          <div
            v-else
            class="col-sm-12 col-md-6">
            <coozzy-multiselect
              id="landlordPossibleMonths"
              v-model="landlordPossibleMonths"
              :options="translatedMonths"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              :preselect-first="false"
              :taggable="false"
              :disabled="isInactive || ifMarketingAndTenancyNotActive"
              label="label"
              track-by="label">
              {{ $t('message.onBoarding.buildings.objects.tenancies.noticeToQuit') }}
            </coozzy-multiselect>
          </div>
        </div>
        <div
          v-if="mode !=='adding'"
          class="row details-row-bg-2 py-01rem">
          <div
            class="col-sm-12 col-md-3">
            <label for="terminatedBy">
              {{ $t('message.onBoarding.buildings.objects.tenancies.terminationResponsible') }} {{ mandatoryTerminationFields && !readOnly ? '*' : '' }}
            </label>
            <coozzy-form-select
              v-if="!readOnly"
              id="terminatedBy"
              v-model="terminatedBy"
              :disabled="isInactive || ifMarketingAndTenancyNotActive"
              :state="$v.terminatedBy && $v.terminatedBy.$dirty && $v.terminatedBy.$error ? false : null">
              <option value="TERMINATED_BY_UNDEFINED" />
              <option value="TERMINATED_BY_LANDLORD">
                {{ $t('message.contact.types.LANDLORD') }}
              </option>
              <option value="TERMINATED_BY_TENANT">
                {{ $t('message.contact.types.TENANT') }}
              </option>
            </coozzy-form-select>
            <template v-else>
              <template v-if="terminatedBy === 'TERMINATED_BY_LANDLORD'">
                {{ $t('message.contact.types.LANDLORD') }}
              </template>
              <template v-else-if="terminatedBy === 'TERMINATED_BY_TENANT'">
                {{ $t('message.contact.types.TENANT') }}
              </template>
              <template v-else>
                -
              </template>
            </template>
          </div>
          <div
            class="col-sm-12 col-md-3">
            <label for="terminationType">
              {{ $t('message.enterTermination.terminationType') }} {{ mandatoryTerminationFields && !readOnly ? '*' : '' }}
              <coozzy-info-circle-icon
                v-if="tenancy.terminatedBy && tenancy.terminatedBy !== 'TERMINATED_BY_UNDEFINED'"
                v-b-tooltip.hover.html="tenancy.terminatedBy === 'TERMINATED_BY_LANDLORD' ? $t('message.enterTermination.reasonOfTerminationTooltipLandlord') : $t('message.enterTermination.reasonOfTerminationTooltipTenant') "
                class="tooltip-wide" />
            </label>
            <coozzy-form-select
              v-if="!readOnly"
              id="terminationType"
              v-model="terminationType"
              :disabled="isInactive || ifMarketingAndTenancyNotActive"
              :state="$v.terminationType && $v.terminationType.$dirty && $v.terminationType.$error ? false : null">
              <option value="TERMINATION_TYPE_UNDEFINED" />
              <option value="TERMINATION_TYPE_TERM">
                {{ $t('message.enterTermination.types.term') }}
              </option>
              <option value="TERMINATION_TYPE_OFF_TERM">
                {{ $t('message.enterTermination.types.offTerm') }}
              </option>
              <option value="TERMINATION_TYPE_EXCEPTIONAL">
                {{ $t('message.enterTermination.types.exceptional') }}
              </option>
            </coozzy-form-select>
            <template v-else>
              <template v-if="terminationType === 'TERMINATION_TYPE_TERM'">
                {{ $t('message.enterTermination.types.term') }}
              </template>
              <template v-else-if="terminationType === 'TERMINATION_TYPE_OFF_TERM'">
                {{ $t('message.enterTermination.types.offTerm') }}
              </template>
              <template v-else-if="terminationType === 'TERMINATION_TYPE_EXCEPTIONAL'">
                {{ $t('message.enterTermination.types.exceptional') }}
              </template>
              <template v-else>
                <span class="d-block"> - </span>
              </template>
            </template>
          </div>
          <div
            v-if="readOnly"
            class="col-sm-12 col-md-6">
            <label for="terminationReason">
              {{ $t('message.enterTermination.reasonOfTermination') }}
            </label>
            <p
              v-if="getTerminationReasons.length > 0"
              class="mb-0 multi-select-value">
              {{ getTerminationReasons }}
            </p>
            <p
              v-else
              class="mb-0 multi-select-value">
              -
            </p>
          </div>
          <div
            v-else
            class="col-sm-12 col-md-3">
            <coozzy-multiselect
              id="terminationReason"
              v-model="terminationReason"
              :check-valide="$v.terminationReason && $v.terminationReason.$dirty && $v.terminationReason.$error ? 'is-invalid' : ''"
              :options="reasonOptions"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              :preselect-first="false"
              :taggable="false"
              :disabled="isInactive || ifMarketingAndTenancyNotActive"
              :placeholder="''"
              label="label"
              track-by="label">
              {{ $t('message.enterTermination.reasonOfTermination') }}{{ mandatoryTerminationFields && !readOnly ? '*' : '' }}
            </coozzy-multiselect>
          </div>
          <div
            class="col-sm-12 col-md-3">
            <coozzy-form-textarea
              v-model="terminationJustification"
              :is-read-only="readOnly"
              :disabled="isInactive || ifMarketingAndTenancyNotActive"
              :initial="terminationJustification"
              :text="terminationJustification">
              {{ $t('message.enterTermination.terminationJustification') }}
            </coozzy-form-textarea>
          </div>
        </div>
        <div
          v-if="mode !=='adding'"
          class="row py-01rem"
          :class="readOnly ? 'details-row-bg-1' : 'details-row-bg-2'">
          <div class="col-sm-12 col-md-3">
            <coozzy-form-input
              ref="terminationCreatedAt"
              v-model="dates.terminationCreatedAt"
              type="date"
              :is-read-only="readOnly"
              :disabled="isInactive || ifMarketingAndTenancyNotActive"
              :filter="'formatDate'"
              :name="$t('message.enterTermination.terminationCreatedOn')"
              @blur="dateToObject()" />
          </div>
          <div
            v-if="terminatedBy === 'TERMINATED_BY_TENANT'"
            class="col-sm-12 col-md-3">
            <coozzy-form-input
              v-model="dates.terminationSentByTenantAt"
              type="date"
              :is-read-only="readOnly"
              :disabled="isInactive || ifMarketingAndTenancyNotActive"
              :filter="'formatDate'"
              :name="$t('message.enterTermination.terminationSentByTenantAt')"
              @blur="dateToObject()" />
          </div>
          <div class="col-sm-12 col-md-3">
            <coozzy-form-input
              ref="terminatedOn"
              v-model="dates.terminatedOn"
              type="date"
              :is-read-only="readOnly"
              :disabled="isInactive || ifMarketingAndTenancyNotActive"
              :state="$v.terminatedOn && $v.terminatedOn.$dirty && $v.terminatedOn.$error ? false : null"
              :filter="'formatDate'"
              :name="$t('message.enterTermination.terminatedOn') + (mandatoryTerminationFields && !readOnly ? '*' : '')"
              @blur="dateToObject()" />
          </div>
        </div>
        <div
          v-if="mode !=='adding'"
          class="row details-row-bg-2 py-01rem">
          <div class="col-sm-12 col-md-3">
            <coozzy-form-input
              ref="movedOutAt"
              v-model="dates.movedOutAt"
              type="date"
              :is-read-only="readOnly"
              :disabled="isInactive || ifMarketingAndTenancyNotActive"
              :state="$v.movedOutAt && $v.movedOutAt.$dirty && $v.movedOutAt.$error ? false : null"
              :filter="'formatDate'"
              :name="$t('message.enterTermination.moveOutDate') + (mandatoryTerminationFields && !readOnly ? '*' : '')"
              @blur="dateToObject()" />
          </div>
          <div class="col-sm-12 col-md-3">
            <coozzy-form-input
              ref="liabilityEndsAt"
              v-model="dates.liabilityEndsAt"
              type="date"
              :state="$v.liabilityEndsAt && $v.liabilityEndsAt.$dirty && $v.liabilityEndsAt.$error ? false : null"
              :is-read-only="readOnly"
              :disabled="isInactive || ifMarketingAndTenancyNotActive"
              :filter="'formatDate'"
              :name="$t('message.onBoarding.buildings.objects.tenancies.liabilityEndsAt') + (mandatoryTerminationFields && !readOnly ? '*' : '')"
              @blur="dateToObject()" />
          </div>
          <div class="col-sm-12 col-md-3">
            <coozzy-form-input
              ref="nextPossibleMoveInAt"
              v-model="dates.nextPossibleMoveInAt"
              type="date"
              :is-read-only="readOnly"
              :disabled="isInactive || ifMarketingAndTenancyNotActive"
              :state="$v.nextPossibleMoveInAt && $v.nextPossibleMoveInAt.$dirty && $v.nextPossibleMoveInAt.$error ? false : null"
              :filter="'formatDate'"
              :name="$t('message.enterTermination.possibleMoveInDateNextTenant')"
              @blur="dateToObject('nextPossibleMoveInAt')" />
          </div>
        </div>
        <div
          v-if="mode !=='adding'"
          class="row details-row-bg-1 py-01rem">
          <div
            v-if="!readOnly"
            class="col-sm-12 col-md-3">
            <coozzy-form-checkbox
              v-model="unjustifiedUse"
              :initial="unjustifiedUse"
              :disabled="isInactive || ifMarketingAndTenancyNotActive"
              name="unjustifiedUse"
              class="mt-4">
              {{ $t('message.enterTermination.unjustifiedUse.title') }}
            </coozzy-form-checkbox>
          </div>
          <div
            v-else
            class="col-sm-12 col-md-3">
            <label>{{ $t('message.enterTermination.unjustifiedUse.title') }}</label>
            <span class="d-block">
              {{ unjustifiedUse ? $t('message.generic.selectField.yes') : $t('message.generic.selectField.no') }}
            </span>
          </div>
          <div
            v-if="unjustifiedUse"
            class="col-sm-12 col-md-3">
            <coozzy-form-input
              v-model="dates.unjustifiedUseFrom"
              type="date"
              :is-read-only="readOnly"
              :disabled="isInactive || ifMarketingAndTenancyNotActive"
              :filter="'formatDate'"
              :name="$t('message.enterTermination.unjustifiedUse.from')"
              :state="$v.unjustifiedUseFrom && $v.unjustifiedUseFrom.$dirty && $v.unjustifiedUseFrom.$error ? false : null"
              @blur="dateToObject()" />
          </div>
          <div
            v-if="unjustifiedUse"
            class="col-sm-12 col-md-3">
            <coozzy-form-input
              v-model="dates.unjustifiedUseTo"
              type="date"
              :is-read-only="readOnly"
              :disabled="isInactive || ifMarketingAndTenancyNotActive"
              :filter="'formatDate'"
              :name="$t('message.enterTermination.unjustifiedUse.to')"
              :state="$v.unjustifiedUseTo && $v.unjustifiedUseTo.$dirty && $v.unjustifiedUseTo.$error ? false : null"
              @blur="dateToObject()" />
          </div>
        </div>
      </b-collapse>
      <div class="row">
        <div
          v-if="!(depositType === 'DEPOSIT_TYPE_NO_DEPOSIT' && readOnly)"
          class="col-12">
          <hr class="mt-0">
        </div>
        <div
          v-if="!(depositType === 'DEPOSIT_TYPE_NO_DEPOSIT' && readOnly)"
          class="col-12">
          <h6
            v-b-toggle="`collapse-tenancy-${index}-deposit`"
            class="cursor-pointer hover font-weight-bold mt-3 width-only-content">
            {{ $t('message.onBoarding.buildings.objects.tenancies.deposit.title') }}
          </h6>
          <b-collapse
            :id="`collapse-tenancy-${index}-deposit`"
            :visible="expandAllChild || openedCollapses.includes(`collapse-tenancy-${index}-deposit`)">
            <div
              class="row py-01rem"
              :class="readOnly ? 'details-row-bg-1' : 'details-row-bg-2'">
              <div class="col-sm-12 col-md-3">
                <label for="depositType">
                  {{ $t('message.onBoarding.buildings.objects.tenancies.deposit.type.title') }}
                </label>
                <coozzy-form-select
                  v-if="!readOnly"
                  id="depositType"
                  v-model="depositType"
                  :disabled="isInactive || ifMarketingAndTenancyNotActive">
                  <option value="DEPOSIT_TYPE_NO_DEPOSIT">
                    {{ $t('message.onBoarding.buildings.objects.tenancies.deposit.type.noDeposit') }}
                  </option>
                  <option
                    v-for="(item, x) in sortedAvailableDepositType"
                    :key="x"
                    :value="item">
                    {{ $t(`message.onBoarding.buildings.objects.tenancies.deposit.type.${item}`) }}
                  </option>
                </coozzy-form-select>
                <span
                  v-else
                  class="d-block">
                  <span v-if="depositType === 'DEPOSIT_TYPE_NO_DEPOSIT'">
                    {{ $t('message.onBoarding.buildings.objects.tenancies.deposit.type.noDeposit') }}</span>
                  <span v-if="depositType === 'DEPOSIT_TYPE_CASH_PAYMENT'">
                    {{ $t('message.onBoarding.buildings.objects.tenancies.deposit.type.DEPOSIT_TYPE_CASH_PAYMENT') }}</span>
                  <span v-else-if="depositType === 'DEPOSIT_TYPE_INSURANCE_GUARANTEE'">
                    {{ $t('message.onBoarding.buildings.objects.tenancies.deposit.type.DEPOSIT_TYPE_INSURANCE_GUARANTEE') }}
                  </span>
                  <span v-else-if="depositType === 'DEPOSIT_TYPE_DEPOT'">
                    {{ $t('message.onBoarding.buildings.objects.tenancies.deposit.type.DEPOSIT_TYPE_DEPOT') }}
                  </span>
                  <span v-else-if="depositType === 'DEPOSIT_TYPE_COOPERATIVE_SHARE'">
                    {{ $t('message.onBoarding.buildings.objects.tenancies.deposit.type.DEPOSIT_TYPE_COOPERATIVE_SHARE') }}
                  </span>
                  <span v-else-if="depositType === 'DEPOSIT_TYPE_COMPULSORY_SHARE_CERTIFICATES'">
                    {{ $t('message.onBoarding.buildings.objects.tenancies.deposit.type.DEPOSIT_TYPE_COMPULSORY_SHARE_CERTIFICATES') }}
                  </span>
                  <span v-else-if="depositType === 'DEPOSIT_TYPE_BANK_GUARANTEE'">
                    {{ $t('message.onBoarding.buildings.objects.tenancies.deposit.type.DEPOSIT_TYPE_BANK_GUARANTEE') }}
                  </span>
                  <span v-else-if="depositType === 'DEPOSIT_TYPE_COLLECTIVE_INSURANCE'">
                    {{ $t('message.onBoarding.buildings.objects.tenancies.deposit.type.DEPOSIT_TYPE_COLLECTIVE_INSURANCE') }}
                  </span>
                  <span v-else> - </span>
                </span>
              </div>
              <div
                v-if="isShowen('insuranceCompany') && !readOnly"
                class="col-sm-12 col-md-3">
                <label>{{ depositType === 'DEPOSIT_TYPE_COLLECTIVE_INSURANCE' ? $t('message.onBoarding.buildings.objects.tenancies.deposit.insuranceNameCollective') : $t('message.onBoarding.buildings.objects.tenancies.deposit.insuranceName') }}</label>
                <coozzy-search-multiselect
                  v-model="insuranceCompanyValue"
                  :options="insuranceNameList"
                  :multiple="true"
                  :placeholder="''"
                  :tag-placeholder="'Add this as new tag'"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :preselect-first="false"
                  :taggable="true"
                  :is-email="false"
                  :with-address="false"
                  :email-with-name="false"
                  label="name"
                  track-by="name"
                  :is-read-only="readOnly"
                  :disabled="ifMarketingAndTenancyNotActive"
                  @input="selectInsurance" />
                <!-- <coozzy-form-input
                  v-model="insuranceCompany"
                  :is-read-only="readOnly"
                  :disabled="isInactive || ifMarketingAndTenancyNotActive"
                  :name="$t('message.onBoarding.buildings.objects.tenancies.deposit.insuranceName')" /> -->
              </div>
              <div
                v-if="isShowen('insuranceCompany') && readOnly"
                class="col-sm-12 col-md-3">
                <label>{{ depositType === 'DEPOSIT_TYPE_COLLECTIVE_INSURANCE' ? $t('message.onBoarding.buildings.objects.tenancies.deposit.insuranceNameCollective') : $t('message.onBoarding.buildings.objects.tenancies.deposit.insuranceName') }}*</label>
                <span
                  class="d-block">{{ tenancy.deposit.insuranceCompany | displayOptionalValue }}</span>
              </div>
              <div
                v-if="isShowen('policyNumber')"
                class="col-sm-12 col-md-3">
                <coozzy-form-input
                  v-model="policyNumber"
                  :is-read-only="readOnly"
                  :disabled="isInactive || ifMarketingAndTenancyNotActive"
                  :name="$t('message.onBoarding.buildings.objects.tenancies.deposit.policyNumber')" />
              </div>
              <div
                v-if="isShowen('bankName') && !readOnly"
                class="col-sm-12 col-md-3">
                <label for="bankName">{{ $t('message.onBoarding.buildings.objects.tenancies.deposit.bankName') }}{{ + isBankNameRequired ? '*' : '' }}</label>
                <ais-instant-search
                  v-if="searchClient"
                  :search-client="searchClient"
                  :routing="routing"
                  index-name="banks">
                  <!-- eslint-disable vue/attribute-hyphenation -->
                  <ais-configure
                    :hitsPerPage="10"
                    filters="countryCode:CH OR countryCode:DE OR countryCode:AT OR countryCode:LI" />
                  <!-- eslint-enable vue/attribute-hyphenation -->
                  <ais-autocomplete>
                    <template slot-scope="{ indices, refine }">
                      <vue-autosuggest
                        id="autosuggest"
                        :ref="`autosuggest-${tenancy.id}`"
                        v-model="bankName"
                        :disabled="isInactive || ifMarketingAndTenancyNotActive"
                        :suggestions="indicesToSuggestions(indices)"
                        :get-suggestion-value="s => s.item.bankOrInstitutionName"
                        :input-props="{id:'autosuggest__input', class : 'form-control form-control-sm', placeholder: $t('message.generic.search'),}"
                        :class="isBankNameRequired && $v.bankName.$dirty && $v.bankName.$error ? 'is-invalid' : ''"
                        @selected="selectHandler"
                        @item-changed="selectHandler"
                        @input="refine">
                        <template slot-scope="{ suggestion }">
                          <span class="my-suggestion-item">{{
                            suggestion.item.newIid ? suggestion.item.newIid : suggestion.item.iid
                          }}-{{ suggestion.item.bankOrInstitutionName }}-{{
                            suggestion.item.domicileAddress
                          }}-{{ suggestion.item.zipCode }}-{{ suggestion.item.place }}</span>
                        </template>
                      </vue-autosuggest>
                    </template>
                  </ais-autocomplete>
                </ais-instant-search>
              </div>
              <div
                v-if="isShowen('bankName') && readOnly"
                class="col-sm-12 col-md-3">
                <label for="bankName">{{
                  $t('message.onBoarding.buildings.objects.tenancies.deposit.bankName') }}</label>
                {{ bankName }}
              </div>
            </div>
            <div
              v-if="(depositType === 'DEPOSIT_TYPE_DEPOT' || depositType === 'DEPOSIT_TYPE_BANK_GUARANTEE')"
              class="row details-row-bg-2 py-2">
              <div
                v-if="isShowen('bankAccount')"
                class="col-sm-12 col-md-3">
                <coozzy-form-input
                  v-model="bankAccount"
                  :is-read-only="readOnly"
                  :disabled="isInactive || ifMarketingAndTenancyNotActive"
                  :name="$t('message.onBoarding.buildings.objects.tenancies.deposit.bankAccount')" />
              </div>
              <div
                v-if="isShowen('clearingNumber')"
                class="col-sm-12 col-md-3">
                <coozzy-form-input
                  v-model="clearingNumber"
                  :disabled="isInactive || depositType === 'DEPOSIT_TYPE_BANK_GUARANTEE' || depositType === 'DEPOSIT_TYPE_DEPOT' || ifMarketingAndTenancyNotActive"
                  :is-read-only="readOnly"
                  :state="$v.clearingNumber && $v.clearingNumber.$dirty && $v.clearingNumber.$error ? false : null"
                  :name="$t('message.onBoarding.buildings.objects.tenancies.deposit.clearingNumber') + ((['DEPOSIT_TYPE_BANK_GUARANTEE', 'DEPOSIT_TYPE_DEPOT'].includes(depositType) && bankName !== '') ? '*' : '')" />
              </div>
              <div
                v-if="isShowen('bic')"
                class="col-sm-12 col-md-3">
                <coozzy-form-input
                  v-model="bic"
                  :is-read-only="readOnly"
                  :disabled="isInactive || depositType === 'DEPOSIT_TYPE_BANK_GUARANTEE' || depositType === 'DEPOSIT_TYPE_DEPOT' || ifMarketingAndTenancyNotActive"
                  :name="$t('message.onBoarding.buildings.objects.tenancies.deposit.bic')" />
              </div>
            </div>
            <div
              v-if="isShowen('depositZip') || isShowen('depositCity') || isShowen('depositStreet') || isShowen('depositStreetNumber') || isShowen('depositCountry')"
              class="row py-2"
              :class="(depositType !== 'DEPOSIT_TYPE_DEPOT' && depositType !== 'DEPOSIT_TYPE_BANK_GUARANTEE') || !readOnly ? 'details-row-bg-2' : 'details-row-bg-1'">
              <div
                v-if="isShowen('depositZip')"
                class="col-sm-12 col-md-3">
                <coozzy-form-input
                  v-model="depositZip"
                  :is-read-only="readOnly"
                  :disabled="isInactive || depositType === 'DEPOSIT_TYPE_DEPOT' || depositType === 'DEPOSIT_TYPE_BANK_GUARANTEE' || ifMarketingAndTenancyNotActive"
                  :name="$t('message.generic.zip')" />
              </div>
              <div
                v-if="isShowen('depositCity')"
                class="col-sm-12 col-md-3">
                <coozzy-form-input
                  v-model="depositCity"
                  :is-read-only="readOnly"
                  :disabled="isInactive || depositType === 'DEPOSIT_TYPE_DEPOT' || depositType === 'DEPOSIT_TYPE_BANK_GUARANTEE' || ifMarketingAndTenancyNotActive"
                  :name="$t('message.generic.city')" />
              </div>
              <div
                v-if="isShowen('depositStreet')"
                class="col-sm-12 col-md-3">
                <coozzy-form-input
                  v-model="depositStreet"
                  :is-read-only="readOnly"
                  :disabled="isInactive || depositType === 'DEPOSIT_TYPE_DEPOT' || depositType === 'DEPOSIT_TYPE_BANK_GUARANTEE' || ifMarketingAndTenancyNotActive"
                  :name="$t('message.generic.street')" />
              </div>
              <div
                v-if="isShowen('depositStreetNumber')"
                class="col-sm-12 col-md-3">
                <coozzy-form-input
                  v-model="depositStreetNumber"
                  :disabled="isInactive || depositType === 'DEPOSIT_TYPE_DEPOT' || depositType === 'DEPOSIT_TYPE_BANK_GUARANTEE'|| ifMarketingAndTenancyNotActive"
                  :is-read-only="readOnly"
                  :name="$t('message.generic.streetNumber')" />
              </div>
              <div
                v-if="isShowen('depositCountry')"
                class="col-sm-12 col-md-3">
                <coozzy-form-country-select
                  v-model="depositCountry"
                  :disabled="isInactive || depositType === 'DEPOSIT_TYPE_DEPOT' || depositType === 'DEPOSIT_TYPE_BANK_GUARANTEE' || ifMarketingAndTenancyNotActive"
                  :required="false"
                  :initial-value="depositCountry"
                  :is-read-only="readOnly" />
              </div>
            </div>
            <div
              class="row py-2"
              :class="(depositType !== 'DEPOSIT_TYPE_DEPOT' && depositType !== 'DEPOSIT_TYPE_BANK_GUARANTEE') && readOnly ? 'details-row-bg-1' : 'details-row-bg-2'">
              <div
                v-if="isShowen('depositAmount')"
                class="col-sm-12 col-md-3">
                <coozzy-form-input-numeric
                  v-model="depositAmount"
                  :is-read-only="readOnly"
                  :filter="'formatPrice'"
                  type="number"
                  :min="0"
                  placeholder="CHF"
                  :is-decimal="true"
                  :disabled="isInactive || ifMarketingAndTenancyNotActive"
                  :check-valide="$v.depositAmount.$dirty && $v.depositAmount.$error ? 'is-invalid' : ''"
                  :name="$t('message.onBoarding.buildings.objects.tenancies.deposit.amount')" />
              </div>
              <div
                v-if="isShowen('depositDueDate')"
                class="col-sm-12 col-md-3">
                <label>{{ $t('message.onBoarding.buildings.objects.tenancies.deposit.dueDate') }}</label>
                <coozzy-form-input
                  id="dueDate"
                  v-model="dates.depositDueDate"
                  :is-read-only="readOnly"
                  :filter="'formatDate'"
                  :disabled="isInactive || ifMarketingAndTenancyNotActive"
                  type="date"
                  @blur="dateToObject()" />
              </div>
              <div
                v-if="isShowen('depositPaidAt')"
                class="col-sm-12 col-md-3">
                <coozzy-form-input
                  v-if="depositType === 'DEPOSIT_TYPE_INSURANCE_GUARANTEE' || depositType === 'DEPOSIT_TYPE_COLLECTIVE_INSURANCE'"
                  v-model="dates.certificateReceivedAt"
                  :is-read-only="readOnly"
                  :filter="'formatDate'"
                  :disabled="isInactive || ifMarketingAndTenancyNotActive"
                  type="date"
                  :name="$t('message.onBoarding.buildings.objects.tenancies.deposit.paidDateInsurance') "
                  @blur="dateToObject()" />
                <coozzy-form-input
                  v-else-if="isShowen('validFrom')"
                  v-model="dates.depositValidFrom"
                  :is-read-only="readOnly"
                  :filter="'formatDate'"
                  :disabled="isInactive || ifMarketingAndTenancyNotActive"
                  type="date"
                  :name="$t('message.onBoarding.buildings.objects.tenancies.deposit.validFrom')"
                  @blur="dateToObject()" />
                <coozzy-form-input
                  v-else
                  v-model="dates.depositPaidAt"
                  :is-read-only="readOnly"
                  :filter="'formatDate'"
                  :disabled="isInactive || ifMarketingAndTenancyNotActive"
                  type="date"
                  :state="$v.depositPaidAt && $v.depositPaidAt.$dirty && $v.depositPaidAt.$error ? false : null"
                  :name="(depositType === 'DEPOSIT_TYPE_CASH_PAYMENT' && depositPaidAmount && !readOnly) ? $t('message.onBoarding.buildings.objects.tenancies.deposit.paidDate') + '*' : $t('message.onBoarding.buildings.objects.tenancies.deposit.paidDate')"
                  @blur="dateToObject()" />
              </div>
              <div
                v-if="isShowen('validUntil')"
                class="col-sm-12 col-md-3">
                <coozzy-form-input
                  v-model="dates.depositValidUntil"
                  :is-read-only="readOnly"
                  :filter="'formatDate'"
                  :disabled="isInactive || ifMarketingAndTenancyNotActive"
                  type="date"
                  :name="$t('message.onBoarding.buildings.objects.tenancies.deposit.validUntil')"
                  @blur="dateToObject()" />
              </div>
              <div
                v-if="isShowen('depositPaidAmount')"
                class="col-sm-12 col-md-3">
                <coozzy-form-input-numeric
                  v-model="depositPaidAmount"
                  :is-read-only="readOnly"
                  :disabled="isInactive || ifMarketingAndTenancyNotActive"
                  :filter="'formatPrice'"
                  type="number"
                  :min="0"
                  placeholder="CHF"
                  :is-decimal="true"
                  :check-valide="$v.depositPaidAmount.$dirty && $v.depositPaidAmount.$error ? 'is-invalid' : ''"
                  :name="(depositType === 'DEPOSIT_TYPE_BANK_GUARANTEE') ? $t('message.onBoarding.buildings.objects.tenancies.deposit.guaranteedAmount') : (depositType !== 'DEPOSIT_TYPE_INSURANCE_GUARANTEE' && depositType !== 'DEPOSIT_TYPE_COLLECTIVE_INSURANCE') ? $t('message.onBoarding.buildings.objects.tenancies.deposit.paidAmount') + (dates.depositPaidAt && !readOnly ? '*' : '') : $t('message.onBoarding.buildings.objects.tenancies.deposit.insuredAmount') + (dates.certificateReceivedAt && !readOnly ? '*' : '')" />
              </div>
            </div>
            <div
              v-if="depositType === 'DEPOSIT_TYPE_COMPULSORY_SHARE_CERTIFICATES'"
              class="row py-2"
              :class="(depositType !== 'DEPOSIT_TYPE_DEPOT' && depositType !== 'DEPOSIT_TYPE_BANK_GUARANTEE') || !readOnly ? 'details-row-bg-2' : 'details-row-bg-1'">
              <template v-if="mode !== 'adding' && mode !== 'activate'">
                <div
                  v-if="!readOnly"
                  class="col-sm-12 col-md-3">
                  <coozzy-form-checkbox
                    v-model="dividedBySeveralPersons"
                    :initial="dividedBySeveralPersons"
                    :disabled="isInactive || ifMarketingAndTenancyNotActive"
                    name="dividedBySeveralPersons"
                    class="mt-4">
                    {{ $t('message.onBoarding.buildings.objects.tenancies.dividedBySeveralPersons') }}
                  </coozzy-form-checkbox>
                </div>
                <div
                  v-else
                  class="col-sm-12 col-md-3">
                  <label>{{ $t('message.onBoarding.buildings.objects.tenancies.dividedBySeveralPersons') }}</label>
                  <span class="d-block">
                    {{ dividedBySeveralPersons ? $t('message.generic.selectField.yes') : $t('message.generic.selectField.no') }}
                  </span>
                </div>
                <template v-if="dividedBySeveralPersons">
                  <div class="col-12">
                    <template v-for="(share, x) in depositShares">
                      <tenancy-deposit-shares
                        :ref="`tenancy-depositShares-${tenancy.id}`"
                        :key="x + '-' + new Date()"
                        :index="x"
                        :deposit-share="share"
                        :tenancy="tenancy"
                        :contacts-loaded="removeDuplicatedItems(objectOwnerContacts)"
                        :read-only="readOnly"
                        @delete-depositShare="deleteDepositShareClicked(x)"
                        @change-depositShare="changeDepositShare" />
                    </template>
                  </div>
                  <div class="col-12">
                    <div class="row">
                      <div class="col-2 mt-1 ml-0">
                        <coozzy-button
                          v-if="!readOnly && !ifMarketingAndTenancyNotActive && !isInactive"
                          class="w-100 mt-2 mb-2"
                          design="prop-green"
                          @click="addDepositShareClicked">
                          {{ $t('message.generic.addAnother') }}
                        </coozzy-button>
                      </div>
                    </div>
                  </div>
                </template>
              </template>
            </div>
            <div
              v-if="depositType !== 'DEPOSIT_TYPE_NO_DEPOSIT'"
              class="row py-2"
              :class="(depositType !== 'DEPOSIT_TYPE_DEPOT' && depositType !== 'DEPOSIT_TYPE_BANK_GUARANTEE') || !readOnly ? 'details-row-bg-2' : 'details-row-bg-1'">
              <template v-if="mode !== 'adding' && mode !== 'activate'">
                <div
                  v-if="!readOnly"
                  class="col-sm-12 col-md-3">
                  <coozzy-form-checkbox
                    v-model="depositPaidOut"
                    :initial="depositPaidOut"
                    :disabled="isInactive || ifMarketingAndTenancyNotActive"
                    name="depositPaidOut"
                    class="mt-4">
                    {{ $t('message.onBoarding.buildings.objects.tenancies.depositPaidOut') }}
                  </coozzy-form-checkbox>
                </div>
                <div
                  v-else
                  class="col-sm-12 col-md-3">
                  <label>{{ $t('message.onBoarding.buildings.objects.tenancies.depositPaidOut') }}</label>
                  <span class="d-block">
                    {{ depositPaidOut ? $t('message.generic.selectField.yes') : $t('message.generic.selectField.no') }}
                  </span>
                </div>
                <template v-if="depositPaidOut">
                  <div class="col-sm-12 col-md-3">
                    <coozzy-form-input
                      v-model="dates.depositPaidOutAt"
                      :is-read-only="readOnly"
                      :disabled="isInactive || ifMarketingAndTenancyNotActive"
                      :filter="'formatDate'"
                      type="date"
                      :name="$t('message.onBoarding.buildings.objects.tenancies.deposit.refundedOn')"
                      @blur="dateToObject()" />
                  </div>
                  <div
                    class="col-sm-12 col-md-3">
                    <coozzy-form-input-numeric
                      v-model="depositPaidOutAmount"
                      :is-read-only="readOnly"
                      :filter="'formatPrice'"
                      :disabled="isInactive || ifMarketingAndTenancyNotActive"
                      type="number"
                      :min="0"
                      placeholder="CHF"
                      :is-decimal="true"
                      :name="$t('message.onBoarding.buildings.objects.tenancies.deposit.refundAmount')"
                      @input="refundAmountChanged" />
                  </div>
                  <div
                    class="col-sm-12 col-md-3">
                    <coozzy-form-textarea
                      v-model="depositNote"
                      :is-read-only="readOnly"
                      :disabled="isInactive || ifMarketingAndTenancyNotActive"
                      :initial="depositNote"
                      :text="depositNote">
                      {{ $t('message.generic.note') }}
                    </coozzy-form-textarea>
                  </div>
                </template>
              </template>
            </div>
          </b-collapse>
        </div>
        <template v-if="objectCategory === 'APARTMENT' || objectCategory === 'HOUSE'">
          <div
            v-if="(tenancy.roommates.length > 0 || !readOnly)"
            class="col-12">
            <hr class="mt-0">
          </div>
          <div
            v-if="(tenancy.roommates.length > 0 || !readOnly)"
            class="col-12 test">
            <h6
              v-b-toggle="`collapse-tenancy-${index}-roommates`"
              class="cursor-pointer hover font-weight-bold mt-3 width-only-content">
              {{ $t('message.onBoarding.buildings.objects.tenancies.roommates') }}
            </h6>
          </div>
          <div class="col-12">
            <b-collapse
              :id="`collapse-tenancy-${index}-roommates`"
              :visible="expandAllChild || openedCollapses.includes(`collapse-tenancy-${index}-roommates`)">
              <template v-for="(roommate, x) in tenancy.roommates">
                <tenancy-roommate
                  :ref="`tenancy-roommates-${roommate.id}`"
                  :key="x"
                  :index="x"
                  :roommate="roommate"
                  :tenancy="tenancy"
                  :read-only="readOnly"
                  :disabled-field="ifMarketingAndTenancyNotActive"
                  @delete-roommate="deleteRoommateClicked(x)"
                  @change-roommate="changeRoommate" />
              </template>
              <div class="col-2 mt-1 pr-0 pl-0 ml-0">
                <coozzy-button
                  v-if="!readOnly && !ifMarketingAndTenancyNotActive && !isInactive"
                  class="w-100 mt-2 mb-2"
                  design="prop-green"
                  @click="addRoommateClicked">
                  {{ $t('message.onBoarding.buildings.objects.tenancies.addRoommate') }}
                </coozzy-button>
              </div>
            </b-collapse>
          </div>
        </template>
        <div
          v-if="(tenancy.subTenants.length > 0 || !readOnly) && mode !== 'adding'"
          class="col-12">
          <hr class="mt-0">
        </div>
        <div
          v-if="(tenancy.subTenants.length > 0 || !readOnly) && mode !== 'adding'"
          class="col-12 test">
          <h6
            v-b-toggle="`collapse-tenancy-${index}-subTenants`"
            class="cursor-pointer hover font-weight-bold mt-3 width-only-content">
            {{ $t('message.onBoarding.buildings.objects.tenancies.subTenant') }}
          </h6>
        </div>
        <div class="col-12">
          <b-collapse
            :id="`collapse-tenancy-${index}-subTenants`"
            :visible="expandAllChild || openedCollapses.includes(`collapse-tenancy-${index}-subTenants`)">
            <template v-for="(subTenant, x) in tenancy.subTenants">
              <tenancy-subtenant
                :ref="`tenancy-subTenants-${tenancy.id}`"
                :key="x"
                :index="x"
                :sub-tenant="subTenant"
                :tenancy="tenancy"
                :read-only="readOnly"
                :disabled-field="ifMarketingAndTenancyNotActive"
                @delete-subtenant="deleteSubTenantClicked(x)"
                @change-subtenant="changeSubTenant" />
            </template>
            <div class="col-2 mt-1 pr-0 pl-0 ml-0">
              <coozzy-button
                v-if="!readOnly && !ifMarketingAndTenancyNotActive && !isInactive"
                class="w-100 mt-2 mb-2"
                design="prop-green"
                @click="addSubTenantClicked">
                {{ $t('message.onBoarding.buildings.objects.tenancies.addSubTenant') }}
              </coozzy-button>
            </div>
          </b-collapse>
        </div>
        <div
          v-if="mode !== 'adding'"
          class="col-12">
          <hr class="mt-0">
        </div>
        <div
          v-if="(documents.length > 0 || !readOnly) && mode !== 'adding'"
          class="col-12">
          <h6
            v-b-toggle="`collapse-tenancy-${index}-document`"
            class="cursor-pointer hover font-weight-bold mt-3 width-only-content">
            {{ $t('message.generic.documents') }}
          </h6>
        </div>
        <b-collapse
          v-if="mode !== 'adding'"
          :id="`collapse-tenancy-${index}-document`"
          class="w-100 position-relative"
          :visible="expandAllChild || mode === 'activate' || openedCollapses.includes(`collapse-tenancy-${index}-document`)">
          <span
            v-if="documents.length > 0"
            class="link downloadAllDocuments"
            @click="downloadAllDocuments($event)">{{ $t('message.marketingMessages.mail.downloadAll') }} <coozzy-file-download-icon /></span>
          <div
            v-if="!readOnly && !ifMarketingAndTenancyNotActive && !isInactive"
            class="col-2 mt-1">
            <add-document-modal
              :suffix="'tenancies' + index"
              :owner-id="tenancy.ownerId"
              @document-created="documentCreated" />
            <coozzy-button
              class="w-100"
              design="prop-green"
              @click="addDocumentClicked('tenancies' + index)">
              {{ $t('message.manageDocuments.addDocument') }}
            </coozzy-button>
          </div>
          <div
            v-if="documents.length > 0"
            class="col-12 mt-2">
            <documents-list
              :documents-list="documents"
              :is-read-only="readOnly"
              @version-added="versionAdded"
              @document-deleted="deleteDocument" />
          </div>
        </b-collapse>
      </div>
      <div
        v-if="!tenancy.activated && mode !== 'adding' && mode !== 'activate' && activeAndEditable"
        class="row">
        <div class="col-12 mt-3">
          <coozzy-button
            v-if="((!readOnly && mode !== 'adding' && mode !== 'activate') || !readOnly) && !ifMarketingAndTenancyNotActive && !isInactive"
            size="small"
            design="red"
            class="float-right"
            @click="removeClicked">
            {{ $t('message.onBoarding.buildings.objects.tenancies.delete') }}
          </coozzy-button>
        </div>
      </div>
      <div
        v-if="tenancy.activated && object && ((object.debitUntil && new Date($options.filters.objectToDateInput(object.debitUntil)) < new Date($options.filters.objectToDateInput(tenancy.startingAt))) || !object.debitUntil)"
        class="row">
        <div class="col-12 mt-3">
          <coozzy-button
            v-if="(!readOnly && mode !== 'adding' && mode !== 'activate') || !readOnly"
            size="small"
            design="red"
            class="float-right"
            @click="removeActiveTenancy">
            {{ $t('message.onBoarding.buildings.objects.tenancies.delete') }}
          </coozzy-button>
        </div>
      </div>
    </b-collapse>
    <b-modal
      :id="'extend-tenancy-modal'"
      :ref="`extend-tenancy-modal`"
      no-close-on-backdrop
      hide-footer
      :title="$t('message.onBoarding.buildings.objects.tenancies.extend.title')">
      <template #modal-title>
        {{ $t('message.onBoarding.buildings.objects.tenancies.extend.title') }}
        <coozzy-info-circle-icon
          v-b-tooltip.hover.html="$t('message.onBoarding.buildings.objects.tenancies.extend.titleTooltip')"
          class="mt-2" />
      </template>
      <div class="col">
        <b-table
          ref="table-real-option"
          bordered
          hover
          responsive="true"
          stacked="md"
          selectable
          select-mode="single"
          no-select-on-click
          :fields="fieldsExtend"
          :items="AllRealOption">
          <div
            slot="table-busy"
            class="text-center text-danger my-2">
            <coozzy-spinner />
          </div>

          <!-- Headings -->
          <template #head(optionFrom)>
            {{ $t('message.onBoarding.buildings.objects.tenancies.option.optionFrom') }}
          </template>
          <template #head(optionUntil)>
            {{ $t('message.onBoarding.buildings.objects.tenancies.option.optionUntil') }}
          </template>
          <template #head(type)>
            {{ $t('message.onBoarding.buildings.objects.tenancies.optionType.title') }}
          </template>

          <!-- Data cells -->
          <template #cell(checkbox)="data">
            <coozzy-form-checkbox
              :id="'check_' + data.item.id"
              :disabled="selectedEntriesExtend.length > 0 && !selectedEntriesExtend.find(x => x.id === data.item.id)"
              @change="(value) => { itemExtendSelected(data.item, value) }" />
          </template>
          <template
            #cell(optionFrom)="data">
            {{ data.item.from | objectToDate }}
          </template>
          <template
            #cell(optionUntil)="data">
            {{ data.item.until | objectToDate }}
          </template>
          <template
            #cell(type)="data">
            <span v-if="data.item.type === 'RENEWAL_OPTION_TYPE_REAL'">
              {{ $t('message.onBoarding.buildings.objects.tenancies.option.realOption') }}
            </span>
          </template>
        </b-table>
      </div>
      <div class="col">
        <coozzy-button
          size="small"
          class="mb-0 border"
          design="transparent"
          @click="$bvModal.hide('extend-tenancy-modal')">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
        <coozzy-button
          size="small"
          class="float-right mb-0"
          design="green"
          :disabled="selectedEntriesExtend.length === 0"
          @click="extendConfirmed">
          {{ $t('message.onBoarding.buildings.objects.tenancies.extend.extend') }}
        </coozzy-button>
      </div>
    </b-modal>
    <b-modal
      :id="'delete-tenancy-modal'"
      :ref="`delete-tenancy-modal`"
      no-close-on-backdrop
      hide-footer
      :title="$t('message.onBoarding.deleteModals.confirmation')">
      <div class="col">
        <p
          v-if="hasSignatureRequestIncomplete">
          {{ $t('message.interestedParty.confirmModal.tenancyWithSignConfirmationTextDelete') }}
        </p>
        <p
          v-else>
          {{ $t('message.interestedParty.confirmModal.tenancyConfirmationTextDelete') }}
        </p>
      </div>
      <div class="col">
        <coozzy-button
          size="small"
          class="mb-0 border"
          design="transparent"
          @click="$bvModal.hide('delete-tenancy-modal')">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
        <coozzy-button
          size="small"
          class="float-right mb-0"
          design="green"
          @click="removeConfirmed(hasSignatureRequestIncomplete)">
          {{ $t('message.generic.delete') }}
        </coozzy-button>
        <coozzy-button
          v-if="hasSignatureRequestIncomplete"
          size="small"
          class="float-right mr-2 mb-0"
          design="green"
          @click="removeConfirmed">
          {{ $t('message.interestedParty.confirmModal.deleteTenancyOnly') }}
        </coozzy-button>
      </div>
    </b-modal>
    <b-modal
      :id="`after-delete-tenancy-modal-${index}`"
      :ref="`after-delete-tenancy-modal-${index}`"
      no-close-on-backdrop
      hide-footer
      :title="$t('message.generic.attention')">
      <div class="col">
        <p>{{ $t('message.onBoarding.buildings.objects.tenancies.afterDeleteTenancyVacancy1') }}</p>
        <p>{{ $t('message.onBoarding.buildings.objects.tenancies.afterDeleteTenancyVacancy2') }}</p>
      </div>
      <div class="col">
        <coozzy-button
          size="small"
          class="mb-0 border"
          design="transparent"
          @click="$bvModal.hide(`after-delete-tenancy-modal-${index}`)">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
        <coozzy-button
          size="small"
          class="float-right mb-0"
          design="green"
          @click="removeConfirmed">
          {{ $t('message.generic.delete') }}
        </coozzy-button>
      </div>
    </b-modal>
    <b-modal
      :id="`create-contract-confirmationModal-${index}`"
      :ref="`create-contract-confirmationModal-${index}`"
      :size="'xl'"
      no-close-on-backdrop
      hide-footer
      :title="$t('message.addTenancy.createDocument')"
      @close="hideRenderContractModal">
      <template>
        <div class="col pl-0 mb-1">
          <label>{{ $t('message.settings.template.textTemplate') }}</label>
          <coozzy-form-select
            v-model="selectedTemplate"
            name="numberEntries">
            <option
              value="">
              -
            </option>
            <option
              v-for="template in sortedTemplate"
              :key="template.id"
              :value="template.id">
              {{ template.name }}
            </option>
          </coozzy-form-select>
        </div>
        <div class="col pl-0 mb-1">
          <label>{{ $t('message.serialLetterProcess.subject') }}</label>
          <coozzy-form-textarea
            id="title"
            v-model="title"
            :initial="title"
            :value-text-area="title"
            :text="title"
            :show-label="false"
            :rows="3" />
        </div>
        <div class="col pl-0 mb-2">
          <label>{{ $t('message.serialLetterProcess.letterText') }}</label>
          <coozzy-form-textarea
            id="description"
            v-model="description"
            :initial="description"
            :value-text-area="description"
            :text="description"
            :show-label="false"
            :rows="3" />
        </div>
        <div class="col pl-0">
          <template-table
            :source="'objectTenancy'"
            :by-owners-ids="templateOwnersIds"
            :render-contract-loading="renderContractLoading"
            @contracts-loaded="contractsLoaded"
            @selected-contract="itemSelected" />
        </div>
        <div class="col pl-0">
          <coozzy-button
            size="small"
            class="mb-0 border"
            design="transparent"
            :disabled="renderContractLoading"
            @click="hideRenderContractModal">
            {{ $t('message.generic.cancel') }}
          </coozzy-button>
          <coozzy-button
            size="small"
            class="float-right mb-0"
            :disabled="renderContractLoading || contractTemplateSelected.length === 0"
            design="green"
            @click="renderContract">
            {{ $t('message.addTenancy.createDocument') }}
          </coozzy-button>
          <coozzy-form-checkbox
            v-if="isEditor && hasDocumentsPortal && addDocumentToContact"
            v-model="showInPortal"
            class="float-right pr-3 mt-1 mr-0 display-none-xs font-weight-lighter">
            {{ $t('message.generic.showInPortal') }}
            <coozzy-info-circle-icon
              v-if="!isPortalLinkEnabled"
              v-b-tooltip.hover.html="$t('message.manageDocuments.tooltipShowInPortal')"
              class="ml-1" />
          </coozzy-form-checkbox>
          <coozzy-form-checkbox
            v-model="addDocumentToContact"
            class="float-right pr-3 mt-1 mr-0 display-none-xs font-weight-lighter">
            {{ $t('message.generic.addDocumentToTenancy') }}
          </coozzy-form-checkbox>
        </div>
      </template>
    </b-modal>
    <b-modal
      :id="`delete-termination-confirmation-modal`"
      :ref="`delete-termination-confirmation-modal`"
      no-close-on-backdrop
      hide-footer
      :title="$t('message.onBoarding.buildings.objects.tenancies.deleteTermination.title')">
      <div class="col">
        <p>{{ $t('message.onBoarding.buildings.objects.tenancies.deleteTermination.text') }}</p>
      </div>
      <div class="col">
        <coozzy-button
          size="small"
          class="mb-0 border"
          design="transparent"
          @click="$bvModal.hide('delete-termination-confirmation-modal')">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
        <coozzy-button
          size="small"
          class="float-right mb-0"
          design="green"
          @click="deleteTerminationConfirmed">
          {{ $t('message.generic.confirm') }}
        </coozzy-button>
      </div>
    </b-modal>
    <activate-tenancy-modal
      v-if="showActivateTenancyModal"
      ref="activateTenancyModal"
      :owner-id="tenancy.ownerId"
      :selected-entries-ids="[tenancy.id]"
      :is-same-tenant="false"
      :object="object"
      :tenancy="tenancy"
      :tenancies="tenanciesList"
      :contacts="contactsList"
      :objects="objectsList"
      :last-tenancy="lastTenancy"
      @hide="hideActivateTenancyModal"
      @tenancy-activated="tenancyActivated" />
    <create-activity-modal
      :ref="`create-activity${tenancy.id}`"
      :single-tenant="tenancy.tenant"
      :references-ids="[tenancy.id]"
      :ids="tenancy.id ? [tenancy.id] : []" />
    <reminder-creation-modal
      v-if="tenancy.id"
      :ref="`reminder-creation-modal${tenancy.id}`"
      :source="'tenancies'"
      :references-ids="[tenancy.id]"
      :available-contacts="tenantContacts" />
    <digital-signature-modal
      v-if="showDigitalSignature"
      ref="digitalSignatureModal"
      :tenancy="tenancy ? tenancy : null"
      :object-id="object ? object.id : null"
      :contacts="removeDuplicatedItems(objectOwnerContacts)"
      @contact-updated="contactUpdated"
      @hide="hideDigitalSignatureModal" />
    <digital-deposit-modal
      v-if="showDigitalDeposit"
      ref="digitalDepositModal"
      :tenancy="tenancy ? tenancy : null"
      :object="object ? object : null"
      :contacts="contactsDigitalDeposit"
      :account-id="accountId.toString()"
      @contact-updated="contactUpdated"
      @hide="hideDigitalDepositModal" />
  </section>
</template>

<script>
import CoozzyFormInput from '../../../framework/components/form/input/CoozzyFormInput'
import CoozzyFormInputNumeric from '../../../framework/components/form/input/CoozzyFormInputNumeric'
import CoozzySearchMultiselect from '@/framework/components/multiselect/CoozzySearchMultiselect'
import CoozzyFormSelect from '../../../framework/components/form/select/CoozzyFormSelect'
import CoozzyMultiselect from '../../../framework/components/multiselect/CoozzyMultiselect'
import CoozzyButton from '../../../framework/components/button/CoozzyButton'
import ContactApi from '@/misc/apis/ContactApi'
import AddDocumentModal from '@/properties/components/AddDocumentModal'
import { onboarding } from '@/mixins/onboarding'
import { validation } from '@/mixins/validation'
import { maxValue, minValue, numeric, required } from 'vuelidate/lib/validators'
import Vue from 'vue'
import CoozzyFormCheckbox from '@/framework/components/form/checkbox/CoozzyFormCheckbox'
import ObjectApi from '@/misc/apis/ObjectApi'
import { mapGetters, mapMutations, mapState } from 'vuex'
import ActivateTenancyModal from '@/addTenancy/ActivateTenancyModal'
import CreateActivityModal from '@/contact/components/CreateActivityModal'
import ReminderCreationModal from '@/calendar/components/ReminderCreationModal'
import { routeChecks } from '@/mixins/routeChecks'
import { tables } from '@/mixins/tables'
import CoozzyDropdown from '@/framework/components/dropdown/CoozzyDropdown'
import CoozzyDropdownItem from '@/framework/components/dropdown/CoozzyDropdownItem'
import AddressText from '@/framework/components/misc/AddressText'
import TenancyAdjustment from './TenancyAdjustment.vue'
import TenancySubtenant from './TenancySubtenant.vue'
import TenancyDepositShares from './TenancyDepositShares.vue'
import TenancyRoommate from './TenancyRoommate.vue'
import { algolia } from '@/mixins/algolia'
import { VueAutosuggest } from 'vue-autosuggest'
import CoozzyFormCountrySelect from '@/framework/components/form/select/CoozzyFormCountrySelect'
import { user } from '@/mixins/user'
import TemplateTable from '@/settings/components/TemplateTable'
import { toastError } from '@/mixins/toastError'
import { dateUtils } from '@/mixins/dateUtils'
import JSZip from 'jszip'
import { obj } from '@/mixins/object'
import { saveAs } from 'file-saver'
import MediaApi from '@/misc/apis/MediaApi'
import DocumentsList from '@/properties/components/DocumentsList.vue'
import DigitalSignatureModal from '@/user/DigitalSignatureModal.vue'
import { deepSign } from '@/mixins/deepSign'
import CoozzyInfoCircleIcon from '@/framework/components/icons/CoozzyInfoCircleIcon.vue'
import CoozzyFormTextarea from '@/framework/components/form/textarea/CoozzyFormTextarea.vue'
import DeepSignApi from '@/misc/apis/DeepSignApi'
import CoozzyFileDownloadIcon from '@/framework/components/icons/CoozzyFileDownloadIcon.vue'
import { media } from '@/mixins/media'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner.vue'
import { countryIndex } from '@/mixins/countryIndex'
import { digitalDeposit } from '@/mixins/digitalDeposit'
import DigitalDepositModal from '@/user/DigitalDepositModal.vue'

function lessThanEnding(value, vm) {
  if ((!value)) {
    return true
  }
  if (!vm.endingAt) {
    return true
  }

  return new Date(this.$options.filters.objectToDateInput(value)) <= new Date(this.$options.filters.objectToDateInput(vm.endingAt))
}
function moreUnjustifiedUseFrom(value, vm) {
  if (!value) {
    return true
  }
  if (!vm.unjustifiedUseFrom) {
    return true
  }
  return new Date(this.$options.filters.objectToDateInput(value)) >= new Date(this.$options.filters.objectToDateInput(vm.unjustifiedUseFrom))
}

function moreThanStarting(value, vm) {
  if (!value) {
    return true
  }
  if (!vm.startingAt) {
    return true
  }
  return new Date(this.$options.filters.objectToDateInput(value)) >= new Date(this.$options.filters.objectToDateInput(vm.startingAt))
}
function moreThanMoveOutDate(value, vm) {
  if (!value) {
    return true
  }
  if (!vm.movedOutAt) {
    return true
  }
  return new Date(this.$options.filters.objectToDateInput(value)) > new Date(this.$options.filters.objectToDateInput(vm.movedOutAt))
}
function moreThanEnding(value, vm) {
  if (!value) {
    return true
  }
  if (!vm.endingAt) {
    return true
  }
  return new Date(this.$options.filters.objectToDateInput(value)) >= new Date(this.$options.filters.objectToDateInput(vm.endingAt))
}
function moreThanDebitUntil(value) {
  if (!value) {
    return true
  }
  if (!this.object.debitUntil) {
    return true
  }
  if (!this.endingAtChanged) {
    return true
  }
  return new Date(this.$options.filters.objectToDateInput(value)) >= new Date(this.$options.filters.objectToDateInput(this.object.debitUntil))
}
function startingMoreThanDebitUntil(value) {
  if (!value) {
    return true
  }
  if (!this.object.debitUntil) {
    return true
  }
  if (!this.startingAtChanged && !this.tenancy.internalId) {
    return true
  }
  return new Date(this.$options.filters.objectToDateInput(value)) >= new Date(this.$options.filters.objectToDateInput(this.object.debitUntil))
}

function checkChanging(value) {
  if (!value || !this.property.transferredToAccounting) {
    return true
  }
  return new Date(this.$options.filters.objectToDateInput(this.endingAtInit)).getTime() === new Date(this.$options.filters.objectToDateInput(value)).getTime()
}

function isTenantsEmpty(value) {
  return (value.length > 0)
}

function isTenantsNull(value) {
  return !!value
}

function validRenewalOptionUntil(value, vm) {
  const idx = this.tenancy.renewalOptions.findIndex(x => x.id === vm.id)
  return !(idx > 0 && (this.tenancy.renewalOptions[idx].until && this.tenancy.renewalOptions[idx - 1].until) && new Date(this.$options.filters.objectToDateInput(this.tenancy.renewalOptions[idx].until)).getTime() / 1000 <= new Date(this.$options.filters.objectToDateInput(this.tenancy.renewalOptions[idx - 1].until)).getTime() / 1000)
}
function validMoreThanFrom(value, vm) {
  const idx = this.tenancy.renewalOptions.findIndex(x => x.id === vm.id)
  return !(this.tenancy.renewalOptions[idx].from && this.tenancy.renewalOptions[idx].until && new Date(this.$options.filters.objectToDateInput(this.tenancy.renewalOptions[idx].from)).getTime() / 1000 > new Date(this.$options.filters.objectToDateInput(this.tenancy.renewalOptions[idx].until)).getTime() / 1000)
}
function validRenewalOptionExerciceUntil(value, vm) {
  const idx = this.tenancy.renewalOptions.findIndex(x => x.id === vm.id)
  if ((this.tenancy.renewalOptions[idx].exerciseUntil && this.tenancy.renewalOptions[idx].until) && new Date(this.$options.filters.objectToDateInput(this.tenancy.renewalOptions[idx].exerciseUntil)).getTime() / 1000 >= new Date(this.$options.filters.objectToDateInput(this.tenancy.renewalOptions[idx].until)).getTime() / 1000) {
    return false
  }
  return !(idx > 0 && (this.tenancy.renewalOptions[idx].exerciseUntil && this.tenancy.renewalOptions[idx - 1].exerciseUntil) && new Date(this.$options.filters.objectToDateInput(this.tenancy.renewalOptions[idx].exerciseUntil)).getTime() / 1000 <= new Date(this.$options.filters.objectToDateInput(this.tenancy.renewalOptions[idx - 1].exerciseUntil)).getTime() / 1000)
}
function validEarlyRightOfTerminationOptionUntil(value, vm) {
  const idx = this.tenancy.earlyRightOfTerminationOptions.findIndex(x => x.id === vm.id)
  if ((!this.tenancy.earlyRightOfTerminationOptions[idx].exerciseUntil && value) || (this.tenancy.earlyRightOfTerminationOptions[idx].exerciseUntil && !value)) {
    return false
  }
  return !(idx > 0 && (this.tenancy.earlyRightOfTerminationOptions[idx].until && this.tenancy.earlyRightOfTerminationOptions[idx - 1].until) && new Date(this.$options.filters.objectToDateInput(this.tenancy.earlyRightOfTerminationOptions[idx].until)).getTime() / 1000 <= new Date(this.$options.filters.objectToDateInput(this.tenancy.earlyRightOfTerminationOptions[idx - 1].until)).getTime() / 1000)
}
function validEarlyRightOfTerminationOptionExerciceUntil(value, vm) {
  const idx = this.tenancy.earlyRightOfTerminationOptions.findIndex(x => x.id === vm.id)
  if ((value && !this.tenancy.earlyRightOfTerminationOptions[idx].until) || (!value && this.tenancy.earlyRightOfTerminationOptions[idx].until)) {
    return false
  }
  if ((this.tenancy.earlyRightOfTerminationOptions[idx].exerciseUntil && this.tenancy.earlyRightOfTerminationOptions[idx].until) && new Date(this.$options.filters.objectToDateInput(this.tenancy.earlyRightOfTerminationOptions[idx].exerciseUntil)).getTime() / 1000 >= new Date(this.$options.filters.objectToDateInput(this.tenancy.earlyRightOfTerminationOptions[idx].until)).getTime() / 1000) {
    return false
  }
  return !(idx > 0 && (this.tenancy.earlyRightOfTerminationOptions[idx].exerciseUntil && this.tenancy.earlyRightOfTerminationOptions[idx - 1].exerciseUntil) && new Date(this.$options.filters.objectToDateInput(this.tenancy.earlyRightOfTerminationOptions[idx].exerciseUntil)).getTime() / 1000 <= new Date(this.$options.filters.objectToDateInput(this.tenancy.earlyRightOfTerminationOptions[idx - 1].exerciseUntil)).getTime() / 1000)
}
function validMoreThanContractDate(value) {
  if (this.tenancy.startingAt) {
    if ((value && new Date(this.$options.filters.objectToDateInput(value)) < new Date(this.$options.filters.objectToDateInput(this.tenancy.startingAt)))) {
      return false
    }
  }
  if (this.tenancy.contract.creationDate) {
    if ((value && new Date(this.$options.filters.objectToDateInput(value)) < new Date(this.$options.filters.objectToDateInput(this.tenancy.contract.creationDate)))) {
      return false
    }
  }
  return true
}
function isTerminationEntred(value) {
  return !(this.isTerminationEntred() && !value)
}
export default {
  name: 'ObjectTenancy',
  components: {
    DigitalDepositModal,
    CoozzySpinner,
    CoozzyFileDownloadIcon,
    CoozzyFormTextarea,
    CoozzyInfoCircleIcon,
    DigitalSignatureModal,
    DocumentsList,
    CoozzySearchMultiselect,
    AddressText,
    TemplateTable,
    CoozzyDropdownItem,
    CoozzyDropdown,
    CoozzyFormCheckbox,
    CoozzyButton,
    CoozzyMultiselect,
    CoozzyFormSelect,
    CoozzyFormInput,
    CoozzyFormInputNumeric,
    AddDocumentModal,
    ActivateTenancyModal,
    CreateActivityModal,
    ReminderCreationModal,
    TenancyAdjustment,
    TenancyDepositShares,
    TenancySubtenant,
    VueAutosuggest,
    CoozzyFormCountrySelect,
    TenancyRoommate
  },
  mixins: [routeChecks, onboarding, validation, tables, algolia, user, toastError, dateUtils, obj, deepSign, media, countryIndex, digitalDeposit],
  props: {
    titleBold: {
      type: Boolean,
      default: false
    },
    isFirst: {
      type: Boolean,
      default: true
    },
    expandByDefault: {
      type: Boolean,
      default: true
    },
    expandAllChild: {
      type: Boolean,
      default: false
    },
    tenantNotEditable: {
      type: Boolean,
      default: false
    },
    index: {
      type: [String, Number],
      default: ''
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    enableTenantSelection: {
      type: Boolean,
      default: true
    },
    mode: {
      type: String,
      default: 'full',
      validator: function (value) {
        return ['full', 'adding', 'activate'].indexOf(value) !== -1
      }
    },
    tenancy: {
      type: Object,
      default: null
    },
    target: {
      type: String,
      default: 'tenants'
    },
    billingContact: {
      type: Object,
      default: null
    },
    correspondenceContact: {
      type: Object,
      default: null
    },
    guarantorContacts: {
      type: Array,
      default: () => {
        return []
      }
    },
    tenantContacts: {
      type: Array,
      default: () => {
        return []
      }
    },
    object: {
      type: Object,
      default: null
    },
    endOfContractFromEnterTermination: {
      type: [Object, Number],
      default: null
    },
    isInprogress: {
      type: Boolean,
      default: false
    },
    hideActions: {
      type: Boolean,
      default: false
    },
    requestId: {
      type: String,
      default: ''
    },
    fromMassRentalProcess: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      uniqueId: null,
      startingAtChanged: false,
      endingAtChanged: false,
      activeAndEditable: false,
      activeAndEditableAdjustment: false,
      addDocumentToContact: false,
      showInPortal: false,
      tenancyStatus: null,
      tenancyChanged: false,
      endingAtInit: false,
      hideInprogress: false,
      insuranceCompanyValue: [],
      blobs: [],
      unjustifiedUse: false,
      renewalOptionsReadOnly: [
        { automaticExtending: '' },
        { automaticExtending: '' },
        { automaticExtending: '' },
        { automaticExtending: '' }
      ],
      dates: {
        startingAt: null,
        endingAt: null,
        unjustifiedUseTo: null,
        unjustifiedUseFrom: null,
        contractCreationDate: null,
        tenantEarliestDate: null,
        landlordEarliestDate: null,
        depositDueDate: null,
        depositPaidAt: null,
        depositPaidOutAt: null,
        depositValidFrom: null,
        depositValidUntil: null,
        certificateReceivedAt: null,
        terminationCreatedAt: null,
        terminationSentByTenantAt: null,
        terminatedOn: null,
        movedOutAt: null,
        liabilityEndsAt: null,
        nextPossibleMoveInAt: null,
        firstRentAdjustmentPossibleAt: null,
        renewalOptions: [
          { exerciseUntil: null, from: null, until: null },
          { exerciseUntil: null, from: null, until: null },
          { exerciseUntil: null, from: null, until: null },
          { exerciseUntil: null, from: null, until: null }
        ],
        earlyRightOfTerminationOptions: [
          { exerciseUntil: null, until: null },
          { exerciseUntil: null, until: null },
          { exerciseUntil: null, until: null },
          { exerciseUntil: null, until: null }
        ]
      },
      selectedTenant: [],
      availableTerminationMonths: ['all', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
      availableDepositType: ['DEPOSIT_TYPE_CASH_PAYMENT', 'DEPOSIT_TYPE_INSURANCE_GUARANTEE', 'DEPOSIT_TYPE_DEPOT', 'DEPOSIT_TYPE_COOPERATIVE_SHARE', 'DEPOSIT_TYPE_COMPULSORY_SHARE_CERTIFICATES', 'DEPOSIT_TYPE_BANK_GUARANTEE'],
      contractTemplate: [],
      contractTemplateSelected: [],
      isLoaded: false,
      ignoreResetValues: false,
      fields: [
        {
          key: 'checkbox',
          label: '',
          thClass: 'th-checkbox',
          tdClass: 'align-middle border-right-0 checkbox-cell'
        },
        {
          key: 'title',
          label: 'Title',
          thClass: 'align-middle d-sm-table-cell',
          tdClass: 'align-middle d-sm-table-cell'
        }
      ],
      selectedEntriesExtend: [],
      fieldsExtend: [
        {
          key: 'checkbox',
          label: '',
          thClass: 'th-checkbox',
          tdClass: 'align-middle border-right-0 checkbox-cell'
        },
        {
          key: 'optionFrom',
          label: this.$t('message.onBoarding.buildings.objects.tenancies.option.optionFrom'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'optionUntil',
          label: this.$t('message.onBoarding.buildings.objects.tenancies.option.optionUntil'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'type',
          label: this.$t('message.onBoarding.buildings.objects.tenancies.optionType.title'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        }
      ],
      isBankNameRequired: false,
      renderContractLoading: false,
      title: '',
      description: '',
      initialContractType: '',
      errorStartAt: false,
      onlyErrorStartingAt: false,
      editedTenant: null,
      depositPaidOutAmountChanged: false,
      tenanciesList: [],
      contactsList: [],
      objectsList: [],
      lastTenancy: [],
      initEndingAt: null,
      showDigitalSignature: false,
      hasSignatureRequestIncomplete: false,
      signatureRequests: [],
      showActivateTenancyModal: false,
      selectedTemplate: '',
      showDigitalDeposit: false
    }
  },
  computed: {
    ...mapGetters('message', ['getSentMails']),
    ...mapState('message', ['templates']),
    isPortalLinkEnabled() {
      return this.$store.getters['onboarding/getIsPortalLinkEnabled']
    },
    isOwnerCondition() {
      return this.isOwnerModule ? (this.tenancy.id && this.isTicketingEnabled && (this.isEditor || this.isTicketEditor)) : true
    },
    sortedTemplate() {
      return this.templates.filter(x => x.type === 'TEMPLATE_TYPE_LETTER').slice().sort((a, b) => {
        if (a.name > b.name) {
          return 1
        }
        if (b.name > a.name) {
          return -1
        }
        return 0
      })
    },
    currentObject() {
      return this.objects ? (this.objects.filter(x => x.id === this.tenancy.objectId).length > 0 ? this.objects.find(x => x.id === this.tenancy.objectId) : this.object) : this.object
    },
    isExtendTenancy() {
      return (this.rentType === 'RENT_TYPE_INDEX' || this.rentType === 'RENT_TYPE_TURNOVER') && this.tenancy.activated && this.renewalOptions.find(x => (x.from || x.until) && x.type === 'RENEWAL_OPTION_TYPE_REAL')
    },
    AllRealOption() {
      return this.renewalOptions.filter(x => (x.from || x.until) && x.type === 'RENEWAL_OPTION_TYPE_REAL' && !x.used)
    },
    ifMarketingAndTenancyNotActive() {
      return !this.isMarketingModule && this.currentObject ? this.getMarketingStatus(this.currentObject) !== 'no' && this.tenancy.activated === false && this.tenancy.closed === false : false
    },
    endingAtBeforeDebitUntil() {
      return this.object?.debitUntil && this.tenancy?.id && this.initEndingAt ? new Date(this.$options.filters.objectToDateInput(this.initEndingAt)) < new Date(this.$options.filters.objectToDateInput(this.object.debitUntil)) : false
    },
    objectCategory() {
      return this.currentObject ? this.currentObject.category : ''
    },
    endingDatebeforeNow() {
      return this.endingAt && this.endingAt.year !== -1 && new Date(this.$options.filters.objectToDateInput(this.endingAt)) < new Date()
    },
    sortedAvailableDepositType() {
      const array = this.availableDepositType
      array.sort((a, b) => {
        if (this.$t(`message.onBoarding.buildings.objects.tenancies.deposit.type.${a}`) < this.$t(`message.onBoarding.buildings.objects.tenancies.deposit.type.${b}`)) {
          return -1
        }
        if (this.$t(`message.onBoarding.buildings.objects.tenancies.deposit.type.${a}`) > this.$t(`message.onBoarding.buildings.objects.tenancies.deposit.type.${b}`)) {
          return 1
        }
        return 0
      })
      return array
    },
    mandatoryTerminationFields() {
      return !((this.dates.endingAt === null || this.dates.endingAt === '') &&
        (this.terminationType === null || this.terminationType === 'TERMINATION_TYPE_UNDEFINED') &&
        this.terminationReason.length === 0 &&
        (this.dates.terminatedOn === null || this.dates.terminatedOn === '') &&
        (this.dates.movedOutAt === null || this.dates.movedOutAt === '') &&
        (this.dates.liabilityEndsAt === null || this.dates.liabilityEndsAt === '') &&
        (this.dates.nextPossibleMoveInAt === null || this.dates.nextPossibleMoveInAt === '') &&
        (this.terminatedBy === null || this.terminatedBy === 'TERMINATED_BY_UNDEFINED'))
    },
    checkDepositIfEmpty() {
      return this.tenancy.deposit.amount === -1 &&
        this.tenancy.deposit.bankAccount === '' &&
        this.tenancy.deposit.bankName === '' &&
        this.tenancy.deposit.dueDate === '-1' &&
        this.tenancy.deposit.insuranceCompany === '' &&
        this.tenancy.deposit.paidAmount === -1 &&
        this.tenancy.deposit.paidAt === '-1'
    },
    adjustments() {
      this.tenancy.rent.adjustments.forEach(element => {
        if (!('internalId' in element)) {
          element.internalId = new Date(this.$options.filters.objectToDateInput(element.byDate)).getTime()
        }
        if (!('possibleInflation' in element.countryIndex)) {
          element.countryIndex.possibleInflation = -1
        }
      })
      return this.tenancy.rent.adjustments.slice().sort((a, b) => {
        const aDate = new Date(this.$options.filters.objectToDateInput(a.byDate)).getTime()
        const bDate = new Date(this.$options.filters.objectToDateInput(b.byDate)).getTime()
        if (bDate === 0 && b.newItem) {
          return 1
        }
        if (bDate === 0) {
          return 1
        }
        if (aDate === bDate) {
          return 0
        }
        if (aDate > bDate) {
          return -1
        }
        if (aDate < bDate) {
          return 1
        }
        return 0
      })
    },
    activated: {
      get() {
        return this.tenancy.activated
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.activated = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    opted: {
      get() {
        return this.tenancy.opted
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.opted = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    note: {
      get() {
        return this.tenancy.note
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.note = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    familyFlat: {
      get() {
        return this.tenancy.familyFlat
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.familyFlat = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    secondaryResidence: {
      get() {
        return this.tenancy.secondaryResidence
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.secondaryResidence = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    activatedOnLoad: {
      get() {
        if (this.tenancy.activatedOnLoad !== true && this.tenancy.activatedOnLoad !== false) {
          return null
        } else {
          return this.tenancy.activatedOnLoad
        }
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.activatedOnLoad = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    bankAccount: {
      get() {
        return this.tenancy.deposit.bankAccount
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.deposit.bankAccount = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    bankName: {
      get() {
        return this.tenancy.deposit.bankName
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.deposit.bankName = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    bic: {
      get() {
        return this.tenancy.deposit.bic
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.deposit.bic = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    clearingNumber: {
      get() {
        return this.tenancy.deposit.clearingNumber !== -1 ? this.tenancy.deposit.clearingNumber : ''
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.deposit.clearingNumber = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    closed: {
      get() {
        return this.tenancy.closed
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.closed = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    billingContactOption: {
      get() {
        return this.objectOwnerContacts.find(t => {
          return t?.id === this.tenancy.tenant.billingContactId || t?.objectID === this.tenancy.tenant.billingContactId
        })
      },
      set(value) {
        const newTenancy = this.tenancy
        if (value.length > 0) {
          this.tenancy.tenant.billingContactId = value[value.length - 1].objectID ? value[value.length - 1].objectID : value[value.length - 1].id
          if (this.guarantor.length > 0) {
            this.guarantor = this.guarantor.filter(x => x && x.id !== value[value.length - 1].id)
          }
          if (value[value.length - 1]) {
            let newContacts = this.objectOwnerContacts
            newContacts.push(value[value.length - 1])
            newContacts = [...this.removeDuplicatesContact(newContacts)]
            this.setObjectOwnerContacts(newContacts)
          }
        } else {
          this.tenancy.tenant.billingContactId = null
        }
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    guarantor: {
      get() {
        if (this.tenancy.tenant.guarantorContactIds && this.tenancy.tenant.guarantorContactIds.length > 0) {
          return this.tenancy.tenant.guarantorContactIds.map(tenantId => {
            return this.objectOwnerContacts.find(t => {
              if (t) {
                return t.objectID === tenantId || t.id === tenantId
              }
              return false
            })
          }).filter(t => {
            return t
          })
        }
        return []
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.tenant.guarantorContactIds = value.map(v => v.objectID || v.id)
        if (value[value.length - 1]) {
          let newContacts = this.objectOwnerContacts
          newContacts.push(value[value.length - 1])
          newContacts = [...this.removeDuplicatesContact(newContacts)]
          this.setObjectOwnerContacts(newContacts)
        }
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    correspondenceContactOption: {
      get() {
        const correspondenceContact = this.objectOwnerContacts.find(t => {
          return t?.objectID === this.tenancy.tenant.correspondenceContactId || t?.id === this.tenancy.tenant.correspondenceContactId
        })
        return this.tenancy.tenant.correspondenceContactId !== '' && correspondenceContact ? correspondenceContact : []
      },
      set(value) {
        const newTenancy = this.tenancy
        if (value.length > 0) {
          newTenancy.tenant.correspondenceContactId = value[value.length - 1].objectID ? value[value.length - 1].objectID : value[value.length - 1].id
          if (value[value.length - 1]) {
            let newContacts = this.objectOwnerContacts
            newContacts.push(value[value.length - 1])
            newContacts = [...this.removeDuplicatesContact(newContacts)]
            this.setObjectOwnerContacts(newContacts)
          }
        } else {
          newTenancy.tenant.correspondenceContactId = null
        }
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    terminatedBy: {
      get() {
        return this.tenancy.terminatedBy
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.terminatedBy = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    terminationType: {
      get() {
        return this.tenancy.terminationType
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.terminationType = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    terminationReason: {
      get() {
        return this.tenancy.terminationReason.map(terminationReason => {
          return {
            label: this.$t('message.enterTermination.reasons.' + terminationReason),
            value: terminationReason
          }
        })
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.terminationReason = value.map(v => v.value)
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    terminationJustification: {
      get() {
        return this.tenancy.terminationJustification
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.terminationJustification = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    terminationCreatedAt: {
      get() {
        return this.tenancy.terminationCreatedAt !== -1 ? this.tenancy.terminationCreatedAt : null
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.terminationCreatedAt = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    terminationSentByTenantAt: {
      get() {
        return this.tenancy.terminationSentByTenantAt !== -1 ? this.tenancy.terminationSentByTenantAt : null
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.terminationSentByTenantAt = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    terminatedOn: {
      get() {
        return this.tenancy.terminatedOn !== -1 ? this.tenancy.terminatedOn : null
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.terminatedOn = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    movedOutAt: {
      get() {
        return this.tenancy.movedOutAt !== -1 ? this.tenancy.movedOutAt : null
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.movedOutAt = value
                this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    liabilityEndsAt: {
      get() {
        return this.tenancy.liabilityEndsAt !== -1 ? this.tenancy.liabilityEndsAt : null
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.liabilityEndsAt = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    nextPossibleMoveInAt: {
      get() {
        return this.tenancy.nextPossibleMoveInAt !== -1 ? this.tenancy.nextPossibleMoveInAt : null
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.nextPossibleMoveInAt = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    contractCreationDate: {
      get() {
        const now = new Date()
        return this.tenancy.internalId ? this.toObject(now.toJSON().slice(0, 10)) : this.tenancy.contract.creationDate
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.contract.creationDate = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    contractNumber() {
      return this.tenancy.numericId ? this.tenancy.numericId : this.$t('message.generic.generatedAfterSave')
    },
    checkOptions() {
      return this.rentType === 'RENT_TYPE_INDEX' || this.rentType === 'RENT_TYPE_TURNOVER'
    },
    contractType: {
      get() {
        return this.tenancy.contract.type
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.contract.type = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    depositAmount: {
      get() {
        return this.tenancy.deposit.amount !== -1 ? this.tenancy.deposit.amount : null
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.deposit.amount = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    depositCity: {
      get() {
        return this.tenancy.deposit.address.city
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.deposit.address.city = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    depositCountry: {
      get() {
        return this.tenancy.deposit.address.country ? this.tenancy.deposit.address.country : '-'
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.deposit.address.country = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    depositDueDate: {
      get() {
        return this.tenancy.deposit.dueDate !== '-1' ? this.tenancy.deposit.dueDate : null
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.deposit.dueDate = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    depositPaidAmount: {
      get() {
        return this.tenancy.deposit.paidAmount !== -1 ? this.tenancy.deposit.paidAmount : null
      },
      set(value) {
        if (this.depositPaidOutAmountChanged === false) {
          this.depositPaidOutAmount = value
        }
        const newTenancy = this.tenancy
        newTenancy.deposit.paidAmount = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    depositPaidAt: {
      get() {
        return this.tenancy.deposit.paidAt !== '-1' ? this.tenancy.deposit.paidAt : null
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.deposit.paidAt = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    depositValidFrom: {
      get() {
        return this.tenancy.deposit.validFrom !== '-1' ? this.tenancy.deposit.validFrom : null
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.deposit.validFrom = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    depositValidUntil: {
      get() {
        return this.tenancy.deposit.validUntil !== '-1' ? this.tenancy.deposit.validUntil : null
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.deposit.validUntil = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    unjustifiedUseFrom: {
      get() {
        return this.tenancy.unjustifiedUse?.from !== null ? this.tenancy.unjustifiedUse?.from : null
      },
      set(value) {
        const newTenancy = this.tenancy
        if (newTenancy.unjustifiedUse === null) {
          newTenancy.unjustifiedUse = {}
        }
        if (newTenancy.unjustifiedUse) {
          newTenancy.unjustifiedUse.from = value
        }
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    unjustifiedUseTo: {
      get() {
        return this.tenancy.unjustifiedUse?.until !== null ? this.tenancy.unjustifiedUse?.until : null
      },
      set(value) {
        const newTenancy = this.tenancy
        if (newTenancy.unjustifiedUse === null) {
          newTenancy.unjustifiedUse = {}
        }
        if (newTenancy.unjustifiedUse) {
          newTenancy.unjustifiedUse.until = value
        }
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    depositPaidOutAt: {
      get() {
        return this.tenancy.deposit.paidOutAt !== null ? this.tenancy.deposit.paidOutAt : null
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.deposit.paidOutAt = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    depositPaidOutAmount: {
      get() {
        return this.tenancy.deposit.paidOutAmount !== -1 ? this.tenancy.deposit.paidOutAmount : null
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.deposit.paidOutAmount = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    depositNote: {
      get() {
        return this.tenancy.deposit.note !== '' ? this.tenancy.deposit.note : null
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.deposit.note = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    certificateReceivedAt: {
      get() {
        return this.tenancy.deposit.certificateReceivedAt !== '-1' ? this.tenancy.deposit.certificateReceivedAt : null
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.deposit.certificateReceivedAt = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    depositPaidOut: {
      get() {
        return this.tenancy.deposit.paidOut
      },
      set(value) {
        if (value === true && this.depositPaidOutAmountChanged === false) {
          this.depositPaidOutAmount = this.depositPaidAmount
        }
        const newTenancy = this.tenancy
        newTenancy.deposit.paidOut = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    depositStreet: {
      get() {
        return this.tenancy.deposit.address.street
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.deposit.address.street = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    hasNoAddress() {
      return this.tenants.filter(x => !(x.address.zip && x.address.city && x.address.country))
    },
    depositStreetNumber: {
      get() {
        return this.tenancy.deposit.address.streetNumber
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.deposit.address.streetNumber = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    depositType: {
      get() {
        return this.tenancy.deposit.type
      },
      set(value) {
        this.insuranceCompanyValue = []
        const newTenancy = this.tenancy
        newTenancy.deposit.type = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    dividedBySeveralPersons: {
      get() {
        return this.tenancy.deposit.dividedBySeveralPersons
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.deposit.dividedBySeveralPersons = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    depositShares: {
      get() {
        return this.tenancy.deposit?.depositShares || []
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.deposit.depositShares = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    depositZip: {
      get() {
        return this.tenancy.deposit.address.zip
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.deposit.address.zip = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    documents() {
      const adjustmentsDocuments = this.adjustments ? this.tenancyDocuments.filter(d => this.adjustments.map(x => x.documentIds).flat().includes(d.id)) : []
      return this.getFilteredTenancyDocuments(this.tenancy.documentIds).concat(adjustmentsDocuments).sort((a, b) => (a.name > b.name ? 1 : -1))
    },
    endingAt: {
      get() {
        return this.tenancy.endingAt !== '-1' ? this.tenancy.endingAt : null
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.endingAt = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    licensePlate: {
      get() {
        return this.tenancy.contract.licensePlate
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.contract.licensePlate = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    intendedUse: {
      get() {
        return this.tenancy.contract.intendedUse
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.contract.intendedUse = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    contractAmendments: {
      get() {
        return this.tenancy.contractAmendments
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.contractAmendments = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    getLandlordPossibleMonths() {
      const array = []
      for (const month of this.tenancy.terminationLandlord.possibleMonths) {
        array.push(this.$t('message.generic.months.' + month))
      }
      return array.join(', ')
    },
    getTenantPossibleMonths() {
      const array = []
      for (const month of this.tenancy.terminationTenant.possibleMonths) {
        array.push(this.$t('message.generic.months.' + month))
      }
      return array.join(', ')
    },
    getRentAdjustmentMonths() {
      const array = []
      if (this.tenancy.indexData) {
        for (const month of this.tenancy.indexData.rentAdjustmentMonths) {
          array.push(this.$t('message.generic.months.' + month))
        }
        return array.join(', ')
      }
      return ''
    },
    getTerminationReasons() {
      const array = []
      for (const reason of this.tenancy.terminationReason) {
        array.push(this.$t('message.enterTermination.reasons.' + reason))
      }
      return array.join(', ')
    },
    industryName: {
      get() {
        return this.tenancy.tenant.industryName !== '' ? this.tenancy.tenant.industryName : null
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.tenant.industryName = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    insuranceCompany: {
      get() {
        return this.tenancy.deposit.insuranceCompany
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.deposit.insuranceCompany = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    policyNumber: {
      get() {
        return this.tenancy.deposit.policyNumber
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.deposit.policyNumber = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    landlordEarliestDateCom: {
      get() {
        return this.landlordEarliestDate !== null ? this.$options.filters.objectToDateInput(this.landlordEarliestDate) : null
      },
      set(value) {
        this.dates.landlordEarliestDate = value
      }
    },
    landlordEarliestDate: {
      get() {
        return this.tenancy.terminationLandlord.earliestDate !== '-1' ? this.tenancy.terminationLandlord.earliestDate : null
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.terminationLandlord.earliestDate = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    landlordPeriodInMonths: {
      get() {
        return this.tenancy.terminationLandlord.periodInMonths !== -1
          ? this.tenancy.terminationLandlord.periodInMonths
: null
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.terminationLandlord.periodInMonths = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    landlordPossibleMonths: {
      get() {
        const tempMonths = this.tenancy.terminationLandlord.possibleMonths
        const avoidInfiniteLoop = JSON.parse(JSON.stringify(tempMonths))
        avoidInfiniteLoop.sort(function (a, b) {
          return a - b
        })
        return avoidInfiniteLoop.map(month => {
          return {
            label: this.$t('message.generic.months.' + month),
            value: month
          }
        })
      },
      set(value) {
        const newTenancy = this.tenancy
        for (const month of value) {
          if (month.value === 'all') {
            newTenancy.terminationLandlord.possibleMonths = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
            this.dispatchTenancyUpdate(newTenancy)
            this.$emit('tenancy-updated', newTenancy)
            return
          }
        }

        newTenancy.terminationLandlord.possibleMonths = value.map(v => v.value)
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    numberOfPersons: {
      get() {
        return this.tenancy.tenant.numberOfPersons !== '-1' && this.tenancy.tenant.numberOfPersons !== -1 ? this.tenancy.tenant.numberOfPersons : null
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.tenant.numberOfPersons = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    startingAt: {
      get() {
        return this.tenancy.startingAt !== '-1' ? this.tenancy.startingAt : null
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.startingAt = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    tenantEarliestDateCom: {
      get() {
        return this.tenantEarliestDate !== null ? this.$options.filters.objectToDateInput(this.tenantEarliestDate) : null
      },
      set(value) {
        this.dates.tenantEarliestDate = value
      }
    },
    tenantEarliestDate: {
      get() {
        return this.tenancy.terminationTenant.earliestDate !== '-1' ? this.tenancy.terminationTenant.earliestDate : null
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.terminationTenant.earliestDate = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    tenantPeriodInMonths: {
      get() {
        return this.tenancy.terminationTenant.periodInMonths !== -1
          ? this.tenancy.terminationTenant.periodInMonths
: null
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.terminationTenant.periodInMonths = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    tenantPossibleMonths: {
      get() {
        const tempMonths = this.tenancy.terminationTenant.possibleMonths
        const avoidInfiniteLoop = JSON.parse(JSON.stringify(tempMonths))
        avoidInfiniteLoop.sort(function (a, b) {
          return a - b
        })
        return avoidInfiniteLoop.map(month => {
          return {
            label: this.$t('message.generic.months.' + month),
            value: month
          }
        })
      },
      set(value) {
        const newTenancy = this.tenancy
        for (const month of value) {
          if (month.value === 'all') {
            newTenancy.terminationTenant.possibleMonths = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
            this.dispatchTenancyUpdate(newTenancy)
            this.$emit('tenancy-updated', newTenancy)
            return
          }
        }

        newTenancy.terminationTenant.possibleMonths = value.map(v => v.value)
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    tenantType: {
      get() {
        return this.tenancy.tenant.type
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.tenant.type = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    rentType: {
      get() {
        return this.tenancy.contract.rentType
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.contract.rentType = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    typeOfUse: {
      get() {
        return this.tenancy.contract.typeOfUse
      },
      set(value) {
        if (value === 'TYPE_OF_USE_PARKING_EXTERNAL') {
          this.taxable = !this.properties[0].opted
        }
        const newTenancy = this.tenancy
        newTenancy.contract.typeOfUse = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    taxable: {
      get() {
        return this.tenancy.taxable
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.taxable = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    renewalType: {
      get() {
        return this.tenancy.renewalOptions.type
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.renewalOptions.type = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    tenants: {
      get() {
        return this.tenancy.tenant.contactIds.map(tenantId => {
          return this.objectOwnerContacts.find(t => {
            if (t && t.objectID) {
              return t.objectID === tenantId
            } else if (t && t.id) {
              return t.id === tenantId
            } else {
              return false
            }
          })
        }).filter(t => {
          return t
        })
      },
      set(value) {
        if (value) {
          this.tenancy.tenant.contactIds = value.filter(x => x && (x.objectID || x.id)).map(v => v.objectID || v.id)
          const newTenancy = this.tenancy
          if (value[value.length - 1]) {
            let newContacts = this.objectOwnerContacts
            newContacts.push(value[value.length - 1])
            newContacts = [...this.removeDuplicatesContact(newContacts)]
            this.setObjectOwnerContacts(newContacts)
          }
          this.dispatchTenancyUpdate(newTenancy)
          this.$emit('tenancy-updated', newTenancy)
        }
      }
    },
    additionalCostsBillingType: {
      get() {
        return this.tenancy.rent.additionalCostsBillingType
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.rent.additionalCostsBillingType = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    firstRentAdjustmentPossibleAt: {
      get() {
        return this.tenancy.rent.firstRentAdjustmentPossibleAt
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.rent.firstRentAdjustmentPossibleAt = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    additionalCostsAdjustmentAllowed: {
      get() {
        return this.tenancy.indexData ? this.tenancy.indexData.additionalCostsAdjustmentAllowed : null
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.indexData.additionalCostsAdjustmentAllowed = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    contractPercentImputedAtIndex: {
      get() {
        return this.tenancy.indexData && this.tenancy.indexData.contractPercentImputedAtIndex !== -1 ? this.tenancy.indexData.contractPercentImputedAtIndex : null
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.indexData.contractPercentImputedAtIndex = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    belowInitialRentAllowed: {
      get() {
        return this.tenancy.indexData ? this.tenancy.indexData.belowInitialRentAllowed : null
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.indexData.belowInitialRentAllowed = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    rentAdjustmentAdditionalBenefit: {
      get() {
        return this.tenancy.indexData ? this.tenancy.indexData.rentAdjustmentAdditionalBenefit : null
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.indexData.rentAdjustmentAdditionalBenefit = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    maximumRate: {
      get() {
        return this.tenancy.indexData && this.tenancy.indexData.maximumRate !== -1 ? this.tenancy.indexData.maximumRate : null
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.indexData.maximumRate = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    referenceMonthForIndex: {
      get() {
        return this.tenancy.indexData && this.tenancy.indexData.referenceMonthForIndex !== -1 ? this.tenancy.indexData.referenceMonthForIndex : null
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.indexData.referenceMonthForIndex = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    rentAdjustmentByMonths: {
      get() {
        return this.tenancy.indexData && this.tenancy.indexData.rentAdjustmentByMonths !== -1 ? this.tenancy.indexData.rentAdjustmentByMonths : null
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.indexData.rentAdjustmentByMonths = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    rentAdjustmentByPoints: {
      get() {
        return this.tenancy.indexData && this.tenancy.indexData.rentAdjustmentByPoints !== -1 ? this.tenancy.indexData.rentAdjustmentByPoints : null
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.indexData.rentAdjustmentByPoints = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    rentAdjustmentMonths: {
      get() {
        if (this.tenancy.indexData && this.tenancy.indexData.rentAdjustmentMonths) {
          const tempMonths = this.tenancy.indexData.rentAdjustmentMonths
          const avoidInfiniteLoop = JSON.parse(JSON.stringify(tempMonths))
          avoidInfiniteLoop.sort(function (a, b) {
            return a - b
          })
          return avoidInfiniteLoop.map(month => {
            return {
              label: this.$t('message.generic.months.' + month),
              value: month
            }
          })
        }
        return []
      },
      set(value) {
        const newTenancy = this.tenancy
        for (const month of value) {
          if (month.value === 'all') {
            newTenancy.indexData.rentAdjustmentMonths = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
            this.dispatchTenancyUpdate(newTenancy)
            this.$emit('tenancy-updated', newTenancy)
            return
          }
        }

        newTenancy.indexData.rentAdjustmentMonths = value.map(v => v.value)
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    rentAdjustmentNotificationUnit: {
      get() {
        return this.tenancy.indexData && this.tenancy.indexData.rentAdjustmentNotification && this.tenancy.indexData.rentAdjustmentNotification.unit ? this.tenancy.indexData.rentAdjustmentNotification.unit : null
      },
      set(value) {
        const newTenancy = this.tenancy
        if (!newTenancy.indexData.rentAdjustmentNotification) {
          newTenancy.indexData.rentAdjustmentNotification = {
            unit: 'RENT_ADJUSTMENT_NOTIFICATION_MONTHS',
            value: null
          }
        }
        newTenancy.indexData.rentAdjustmentNotification.unit = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    rentAdjustmentNotificationValue: {
      get() {
        return this.tenancy.indexData && this.tenancy.indexData.rentAdjustmentNotification && this.tenancy.indexData.rentAdjustmentNotification.value ? this.tenancy.indexData.rentAdjustmentNotification.value : null
      },
      set(value) {
        const newTenancy = this.tenancy
        if (!newTenancy.indexData.rentAdjustmentNotification) {
          newTenancy.indexData.rentAdjustmentNotification = {
            unit: 'RENT_ADJUSTMENT_NOTIFICATION_MONTHS',
            value: null
          }
        }
        newTenancy.indexData.rentAdjustmentNotification.value = value
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    getTenantsName() {
      let names = ''
      const array = this.tenancy.tenant.contactIds
      for (let index = 0; index < array.length; index++) {
        names = names + (index === 0 ? this.getContactName(array[index]).substr(2) : this.getContactName(array[index]))
      }
      return names
    },
    translatedMonths() {
      const array = []
      for (const month of this.availableTerminationMonths) {
        array.push({
          label: this.$t('message.generic.months.' + month),
          value: month
        })
      }
      return array
    },
    getLang() {
      return this.$store.getters['internationalization/getCurrentLanguage']
    },
    isInactive() {
      return this.object && this.object?.inactiveBy && new Date(this.$options.filters.objectToDateInput(this.object?.inactiveBy)) <= new Date()
    },
    renewalOptions() {
      return this.tenancy.renewalOptions
    },
    earlyRightOfTerminationOptions() {
      return this.tenancy.earlyRightOfTerminationOptions
    },
    valueMonth() {
      const array = []
      for (let index = 1; index < 13; index++) {
        array.push(index)
      }
      return array
    },
    reasonOptions() {
      const reasonOptions = []
      reasonOptions.push({ label: this.$t('message.enterTermination.reasons.TERMINATION_REASON_POSTSCRIPT'), value: 'TERMINATION_REASON_POSTSCRIPT' })
      if (this.tenancy.terminationType === 'TERMINATION_TYPE_EXCEPTIONAL') {
        reasonOptions.push({ label: this.$t('message.enterTermination.reasons.TERMINATION_REASON_IMPORTANT_REASONS'), value: 'TERMINATION_REASON_IMPORTANT_REASONS' })
        reasonOptions.push({ label: this.$t('message.enterTermination.reasons.TERMINATION_REASON_DEATH'), value: 'TERMINATION_REASON_DEATH' })
      }
      if (this.tenancy.terminationType === 'TERMINATION_TYPE_EXCEPTIONAL' && this.tenancy.terminatedBy === 'TERMINATED_BY_LANDLORD') {
        reasonOptions.push({ label: this.$t('message.enterTermination.reasons.TERMINATION_REASON_ARREARS'), value: 'TERMINATION_REASON_ARREARS' })
        reasonOptions.push({ label: this.$t('message.enterTermination.reasons.TERMINATION_REASON_BANKRUPTCY_OF_TENANT'), value: 'TERMINATION_REASON_BANKRUPTCY_OF_TENANT' })
        reasonOptions.push({ label: this.$t('message.enterTermination.reasons.TERMINATION_REASON_VIOLATION_OF_DUE_DILIGENCE'), value: 'TERMINATION_REASON_VIOLATION_OF_DUE_DILIGENCE' })
      }
      if (this.tenancy.terminationType !== 'TERMINATION_TYPE_EXCEPTIONAL' && this.tenancy.terminatedBy === 'TERMINATED_BY_TENANT') {
        reasonOptions.push({ label: this.$t('message.enterTermination.reasons.TERMINATION_REASON_UNDEFINED'), value: 'TERMINATION_REASON_UNDEFINED' })
        reasonOptions.push({ label: this.$t('message.enterTermination.reasons.TERMINATION_REASON_IMMISSIONS_TOO_HIGH'), value: 'TERMINATION_REASON_IMMISSIONS_TOO_HIGH' })
        if ((this.objectCategory !== 'COMMERCIAL' && this.objectCategory !== 'GASTRONOMY')) {
          reasonOptions.push({ label: this.$t('message.enterTermination.reasons.TERMINATION_REASON_JOB'), value: 'TERMINATION_REASON_JOB' })
          reasonOptions.push({ label: this.$t('message.enterTermination.reasons.TERMINATION_REASON_FAMILY'), value: 'TERMINATION_REASON_FAMILY' })
          reasonOptions.push({ label: this.$t('message.enterTermination.reasons.TERMINATION_REASON_TOO_EXPENSIVE'), value: 'TERMINATION_REASON_TOO_EXPENSIVE' })
          reasonOptions.push({ label: this.$t('message.enterTermination.reasons.TERMINATION_REASON_TOO_BIG'), value: 'TERMINATION_REASON_TOO_BIG' })
          reasonOptions.push({ label: this.$t('message.enterTermination.reasons.TERMINATION_REASON_TOO_SMALL'), value: 'TERMINATION_REASON_TOO_SMALL' })
        }
      }
      if (this.tenancy.terminationType !== 'TERMINATION_TYPE_EXCEPTIONAL' && this.tenancy.terminatedBy === 'TERMINATED_BY_LANDLORD') {
        reasonOptions.push({ label: this.$t('message.enterTermination.reasons.TERMINATION_REASON_RENOVATION'), value: 'TERMINATION_REASON_RENOVATION' })
        reasonOptions.push({ label: this.$t('message.enterTermination.reasons.TERMINATION_REASON_OWN_NEED'), value: 'TERMINATION_REASON_OWN_NEED' })
      }
      if (this.tenancy.terminationType !== 'TERMINATION_TYPE_EXCEPTIONAL') {
        reasonOptions.push({ label: this.$t('message.enterTermination.reasons.TERMINATION_REASON_TEMPORARY_CONTRACT'), value: 'TERMINATION_REASON_TEMPORARY_CONTRACT' })
      }
      if ((this.objectCategory === 'COMMERCIAL' || this.objectCategory === 'GASTRONOMY') && this.tenancy.terminatedBy === 'TERMINATED_BY_TENANT' && this.tenancy.terminationType !== 'TERMINATION_TYPE_EXCEPTIONAL') {
        reasonOptions.push({ label: this.$t('message.enterTermination.reasons.TERMINATION_REASON_ECONOMIC_REASONS'), value: 'TERMINATION_REASON_ECONOMIC_REASONS' })
        reasonOptions.push({ label: this.$t('message.enterTermination.reasons.TERMINATION_REASON_AREA_TOO_SMALL'), value: 'TERMINATION_REASON_AREA_TOO_SMALL' })
        reasonOptions.push({ label: this.$t('message.enterTermination.reasons.TERMINATION_REASON_AREA_TOO_LARGE'), value: 'TERMINATION_REASON_AREA_TOO_LARGE' })
      }
      if (this.tenancy.terminatedBy === 'TERMINATED_BY_LANDLORD' && this.tenancy.terminationType !== 'TERMINATION_TYPE_EXCEPTIONAL') {
        reasonOptions.push({ label: this.$t('message.enterTermination.reasons.TERMINATION_REASON_SALE'), value: 'TERMINATION_REASON_SALE' })
      }
      if (this.tenancy.terminatedBy === 'TERMINATED_BY_TENANT' && (this.objectCategory === 'HOUSE' || this.objectCategory === 'APARTMENT')) {
        reasonOptions.push({ label: this.$t('message.enterTermination.reasons.TERMINATION_REASON_HOME_PURCHASE'), value: 'TERMINATION_REASON_HOME_PURCHASE' })
      }
      if (this.tenancy.terminationType === 'TERMINATION_TYPE_TERM' && this.tenancy.terminatedBy === 'TERMINATED_BY_LANDLORD') {
        reasonOptions.push({ label: this.$t('message.enterTermination.reasons.TERMINATION_REASON_UNDEFINED'), value: 'TERMINATION_REASON_UNDEFINED' })
        reasonOptions.push({ label: this.$t('message.enterTermination.reasons.TERMINATION_REASON_UNAUTHORISED_SUBLETTING'), value: 'TERMINATION_REASON_UNAUTHORISED_SUBLETTING' })
      }
      if (this.tenancy.terminatedBy === 'TERMINATED_BY_LANDLORD') {
        reasonOptions.push({ label: this.$t('message.enterTermination.reasons.TERMINATION_REASON_PAYMENT_DEFAULT'), value: 'TERMINATION_REASON_PAYMENT_DEFAULT' })
      }
      return reasonOptions
    },
    property() {
      return this.properties[0] ? this.properties[0] : null
    },
    templateOwnersIds() {
      return [].concat(parseInt(this.tenancy.ownerId))
    },
    insuranceNameList() {
      const arr = this.depositType === 'DEPOSIT_TYPE_INSURANCE_GUARANTEE'
? [
        { email: 'AXA', name: 'AXA' },
        { email: 'FirstCaution', name: 'FirstCaution' },
        { email: 'goCaution', name: 'goCaution' },
        { email: 'Helvetia', name: 'Helvetia' },
        { email: 'Mobiliar', name: 'Mobiliar' },
        { email: 'SmartCaution', name: 'SmartCaution' },
        { email: 'SwissCaution', name: 'SwissCaution' },
        { email: 'Zürich', name: 'Zürich' }]
: [{ email: 'AXA', name: 'AXA' }]
      if (this.tenancy.deposit.insuranceCompany && !arr.find(x => x.name === this.tenancy.deposit.insuranceCompany)) {
        arr.push([{ name: this.tenancy.deposit.insuranceCompany, email: this.tenancy.deposit.insuranceCompany }])
      }
      return arr
    },
    isMarketingPath() {
      return this.$route.name.startsWith('Marketing')
    },
    isAddTenancyPath() {
      return this.$route.name.includes('AddTenancy')
    },
    isAddVacancyPath() {
      return this.$route.name.includes('AddVacancy')
    },
    isMarkForMartektingPath() {
      return this.$route.path.includes('marketing/mark/')
    },
    isTermination() {
      return this.$route.path.includes('enterTermination')
    },
    isAllTenantsIndividualContacts() {
      return !this.tenancy.tenant.contactIds.some((contactId) => {
        const contact = this.objectOwnerContacts.find(x => x.id === contactId)
        if (contact && 'contactPersons' in contact) {
          return true
        }
      })
    },
    tenancyStatusDigitalDeposit() {
      const status = ['TENANCY_STATUS_NOT_ACTIVATED', 'TENANCY_STATUS_CURRENT', 'TENANCY_STATUS_FUTURE']
      return this.tenancy?.id && status.includes(this.tenancy.status)
    },
    contactsDigitalDeposit() {
      return this.tenancy.tenant.contactIds.map((contactId) => {
        const contact = this.objectOwnerContacts.find(x => x.id === contactId)
        if (contact) {
          return contact
        }
      })
    }
  },
  watch: {
    selectedTemplate: function (newVal) {
      if (newVal !== '') {
        const template = this.sortedTemplate.find(x => x.id === newVal)
        this.title = template.subject
        this.description = template.message
      } else {
        this.title = ''
        this.description = ''
      }
    },
    mandatoryTerminationFields: function (newVal) {
      if (newVal === false) {
        this.$refs.endingAt.$v.$reset()
        this.$refs.terminatedOn.$v.$reset()
        this.$refs.movedOutAt.$v.$reset()
        this.$refs.liabilityEndsAt.$v.$reset()
      }
    },
    unjustifiedUse: function (newVal) {
      if (!newVal) {
        this.dates.unjustifiedUseFrom = null
        this.dates.unjustifiedUseTo = null
        this.dateToObject()
      }
    },
    taxable: function (newVal) {
      if (newVal === null || newVal) {
        this.opted = false
      } else if (!newVal && this.property?.opted) {
        this.opted = true
      }
    },
    insuranceCompanyValue: function (newVal) {
      if (newVal) {
        this.insuranceCompany = Array.isArray(newVal) ? newVal[0]?.name : newVal.name
      } else if (newVal === null) {
        this.insuranceCompany = ''
      }
    },
    depositType: function (newVal, oldVal) {
      this.bic = ''
      this.clearingNumber = null
      this.bankAccount = ''
      this.bankName = ''
      this.insuranceCompany = ''
      this.policyNumber = ''
      this.depositZip = ''
      this.depositCountry = 'CH'
      this.depositStreetNumber = ''
      this.depositStreet = ''
      this.depositCity = ''
      if (oldVal !== newVal) {
        this.dates.depositPaidAt = ''
        this.dates.certificateReceivedAt = ''
      }
      if (newVal !== 'DEPOSIT_TYPE_NO_DEPOSIT' && this.dates.depositDueDate === null) {
        this.dates.depositDueDate = this.dates.startingAt
        this.dateToObject()
      }
      if (newVal === 'DEPOSIT_TYPE_COMPULSORY_SHARE_CERTIFICATES') {
        if (!this.tenancy.deposit?.depositShares || this.tenancy.deposit?.depositShares?.length === 0) {
          // for every tenancy.tenant.contactIds create a new object for depositShares
          this.$set(this.tenancy.deposit, 'depositShares', [])
          this.tenancy.tenant.contactIds.forEach(element => {
            this.tenancy.deposit.depositShares.push({
              amount: null,
              contactId: element
            })
          })
        }
      }
    },
    bankAccount: function (newVal) {
      this.isBankNameRequired = !!(newVal && newVal !== '')
    },
    tenancyStatus: function (newVal, oldVal) {
      if ((oldVal === 'inProgress' || oldVal === null) && this.isLoaded) {
        this.tenancy.statusEdited = true
      }
      if (newVal === 'activeClosed') {
        this.activated = true
        this.closed = true
      } else if (newVal === 'active') {
        this.activated = true
        this.closed = false
      } else if (newVal === 'inProgress') {
        this.activated = false
        this.closed = false
      }
    },
    billingContact: function (newVal) {
      if (newVal) {
        Vue.set(this.tenancy.tenant, 'billingContactId', newVal.id)
      } else {
        Vue.set(this.tenancy.tenant, 'billingContactId', null)
      }
    },
    correspondenceContact: function (newVal) {
      if (newVal) {
        Vue.set(this.tenancy.tenant, 'correspondenceContactId', newVal.id)
      } else {
        Vue.set(this.tenancy.tenant, 'correspondenceContactId', null)
      }
    },
    guarantorContacts: function (newVal) {
      if (newVal) {
        this.tenancy.tenant.guarantorContactIds = newVal.map(c => c.id)
      }
    },
    'tenancy.startingAt': function (newVal, oldVal) {
      if (JSON.stringify(oldVal) !== JSON.stringify(newVal)) {
        if (this.$options.filters.objectToTimestamp(newVal) !== this.$options.filters.objectToTimestamp(oldVal)) {
          this.startingAtChanged = true
        }
        if ((this.tenancy.contract.type === 'LIMITED') && this.tenancy.startingAt && this.tenancy.startingAt.year !== -1 && this.tenancy.endingAt && this.tenancy.endingAt.year !== -1) {
          this.setDefaultValuesForLimitedContractType()
        } else {
          this.dates.startingAt = this.$options.filters.objectToDateInput(newVal)
        }
      }
      this.$emit('vacancy-updated-startingAt', newVal)
    },
    'tenancy.endingAt': function (newVal, oldVal) {
      if (JSON.stringify(oldVal) !== JSON.stringify(newVal)) {
        if (this.$options.filters.objectToDateInput(newVal) !== this.$options.filters.objectToDateInput(oldVal)) {
          this.endingAtChanged = true
        }
        if (this.tenancy.contract.type === 'LIMITED' && this.tenancy.startingAt && this.tenancy.startingAt.year !== -1 && this.tenancy.endingAt && this.tenancy.endingAt.year !== -1) {
          this.setDefaultValuesForLimitedContractType()
        }
        const endDate = new Date(this.dates.endingAt)
        if (this.tenancy.contract.type !== 'LIMITED' && this.tenancy.endingAt && this.tenancy.endingAt.year !== -1 && endDate instanceof Date && !isNaN(endDate.valueOf()) && new Date(this.dates.endingAt).getTime() > 0) {
          const today = new Date()
          if (this.tenancy.terminatedOn === null) {
            this.dates.terminationCreatedAt = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`
            this.dates.terminatedOn = this.dates.endingAt
          }
          if (this.tenancy.movedOutAt === null) {
            this.dates.movedOutAt = this.dates.endingAt
          }
          if (this.tenancy.liabilityEndsAt === null) {
            this.dates.liabilityEndsAt = this.dates.endingAt
          }
          if (this.tenancy.nextPossibleMoveInAt === null) {
            const dataToAdd = new Date(this.dates.endingAt)
            const datatodays = dataToAdd.setDate(new Date(dataToAdd).getDate() + 1)
            this.dates.nextPossibleMoveInAt = this.$options.filters.timestampToDateInput(datatodays / 1000)
            this.nextPossibleMoveInAt = this.toObject(this.dates.nextPossibleMoveInAt)
          }
          this.dateToObject()
        }
      }
    },
    'tenancy.tenant.billingContactId': function (newVal, oldVal) {
      if (!this.readOnly && JSON.stringify(newVal) !== JSON.stringify(oldVal) && !this.tenantNotEditable) {
        this.tenants = this.tenants.concat(this.billingContactOption)
        this.tenants = this.removeDuplicatesContact(this.tenants)
      }
      if (oldVal && !newVal) {
        this.tenants = this.tenants.filter(x => x.id !== oldVal.id)
      }
    },
    'tenancy.contract.type': function (newVal, oldVal) {
      if (oldVal !== newVal) {
        if ((this.tenancy.contract.type === 'LIMITED') && this.tenancy.startingAt && this.tenancy.startingAt.year !== -1 && this.tenancy.endingAt && this.tenancy.endingAt.year !== -1) {
          this.setDefaultValuesForLimitedContractType()
        }
      }
    },
    'tenancy.contract.rentType': function (newVal, oldVal) {
      const newTenancy = this.tenancy
      if ((newVal === 'RENT_TYPE_INDEX' || newVal === 'RENT_TYPE_TURNOVER') && !this.tenancy.indexData) {
        newTenancy.indexData = {
          additionalCostsAdjustmentAllowed: false,
          belowInitialRentAllowed: false,
          maximumRate: null,
          referenceMonthForIndex: null,
          rentAdjustmentAdditionalBenefit: false,
          rentAdjustmentByMonths: null,
          rentAdjustmentByPoints: null,
          rentAdjustmentMonths: [],
          contractPercentImputedAtIndex: 100,
          rentAdjustmentNotification: {
            unit: 'RENT_ADJUSTMENT_NOTIFICATION_MONTHS',
            value: null
          }
        }
      } else if ((oldVal === 'RENT_TYPE_INDEX' || oldVal === 'RENT_TYPE_TURNOVER') && (newVal !== 'RENT_TYPE_INDEX' && newVal !== 'RENT_TYPE_TURNOVER')) {
        newTenancy.indexData = null
      }
      this.dispatchTenancyUpdate(newTenancy)
      this.$emit('tenancy-updated', newTenancy)
    },
    'tenancy.terminatedBy': function (val, oldVal) {
      if (val !== oldVal && !this.ignoreResetValues) {
        this.terminationType = 'TERMINATION_TYPE_UNDEFINED'
      }
      if (this.contractType !== 'LIMITED') {
        this.calculateEndOfContract()
      }
    },
    'tenancy.terminationType': function (val, oldVal) {
      if (val !== oldVal && !this.ignoreResetValues) {
        this.terminationReason = []
      }
    },

    'tenancy.terminationCreatedAt': function (val, oldVal) {
      if (val && this.$options.filters.objectToDateInput(val) !== this.$options.filters.objectToDateInput(oldVal)) {
        this.calculateEndOfContract()
      }
    },
    // commented until finish tests

    // 'tenancy.terminatedOn': function (val) {
    //   if (val) {
    //     this.calculateEndOfContract()
    //   }
    // },
    // 'tenancy.nextPossibleMoveInAt': function (val, oldVal) {
    //   if (val && this.$options.filters.objectToDateInput(val) !== this.$options.filters.objectToDateInput(oldVal) && this.contractType !== 'LIMITED') {
    //     this.calculateEndOfContract()
    //   }
    // },
    'tenancy.contract.typeOfUse': function (newVal) {
      if (newVal === 'TYPE_OF_USE_RESIDENTIAL' && this.initialContractType === 'UNDEFINED_CONTRACT_TYPE') {
        this.contractType = 'INDEFINITE'
      } else if (newVal !== 'TYPE_OF_USE_RESIDENTIAL' && this.initialContractType === 'UNDEFINED_CONTRACT_TYPE') {
        this.contractType = 'UNDEFINED_CONTRACT_TYPE'
      }
    },
    // properties: function (newVal) {
    //   if (newVal && newVal.length > 0) {
    //     this.loadTenancyRentComponents(newVal)
    //   }
    // },
    'dates.endingAt': function (newVal) {
      this.endingAt = this.toObject(newVal)
    },
    'dates.liabilityEndsAt': function (newVal) {
      this.liabilityEndsAt = this.toObject(newVal)
    },
    'dates.movedOutAt': function (newVal) {
      this.movedOutAt = this.toObject(newVal)
    },
    'dates.terminatedOn': function (newVal) {
      this.terminatedOn = this.toObject(newVal)
    },
    'tenancy.liabilityEndsAt': function (val) {
      if (val) {
        this.dates.liabilityEndsAt = this.$options.filters.objectToDateInput(val)
      }
    },
    'tenancy.terminatedOn': function (val) {
      if (val) {
        this.dates.terminatedOn = this.$options.filters.objectToDateInput(val)
      }
    },
    'tenancy.movedOutAt': function (val) {
      if (val) {
        this.dates.movedOutAt = this.$options.filters.objectToDateInput(val)
      }
    },
    'tenancy.nextPossibleMoveInAt': function (val) {
      if (val) {
        this.dates.nextPossibleMoveInAt = this.$options.filters.objectToDateInput(val)
      }
    }
  },
  mounted() {
    document.body.onmousedown = function(e) {
      if (e.button === 1) {
        e.preventDefault()
        return false
      }
    }
    this.isLoaded = false
    if (this.tenancy.opted === false && this.tenancy.taxable === false) {
      this.taxable = null
    }
    if (this.tenancy.deposit.paidOutAmount !== -1 && this.tenancy.deposit.paidOutAmount !== null) {
      this.depositPaidOutAmountChanged = true
    }
    if (this.tenancy.deposit.paidOutAmount === this.tenancy.deposit.paidAmount) {
      this.depositPaidOutAmountChanged = false
    }
    this.endingAtInit = this.tenancy.endingAt
    if (this.tenancy.renewalOptions.length < 4) {
      for (let index = this.tenancy.renewalOptions.length; index < 4; index++) {
        this.tenancy.renewalOptions.push({ exerciseUntil: null, from: null, until: null, type: 'RENEWAL_OPTION_TYPE_UNDEFINED', automaticExtending: false })
        this.dates.renewalOptions.push({ exerciseUntil: null, from: null, until: null, type: 'RENEWAL_OPTION_TYPE_UNDEFINED', automaticExtending: false })
      }
    }
    if (this.tenancy.earlyRightOfTerminationOptions && this.tenancy.earlyRightOfTerminationOptions.length < 4) {
      for (let j = this.tenancy.earlyRightOfTerminationOptions.length; j < 4; j++) {
        this.tenancy.earlyRightOfTerminationOptions.push({ exerciseUntil: null, until: null })
        this.dates.earlyRightOfTerminationOptions.push({ exerciseUntil: null, until: null })
      }
    }
    for (let index = 0; index < this.tenancy.renewalOptions.length; index++) {
      this.tenancy.renewalOptions[index].id = index
      if (this.tenancy.earlyRightOfTerminationOptions) this.tenancy.earlyRightOfTerminationOptions[index].id = index
      this.dates.renewalOptions[index].until = this.$options.filters.objectToDateInput(this.tenancy.renewalOptions[index].until)
      this.dates.renewalOptions[index].from = this.$options.filters.objectToDateInput(this.tenancy.renewalOptions[index].from)
      this.dates.renewalOptions[index].exerciseUntil = this.$options.filters.objectToDateInput(this.tenancy.renewalOptions[index].exerciseUntil)
      this.renewalOptionsReadOnly[index].used = this.tenancy.renewalOptions[index].used
      this.renewalOptionsReadOnly[index].automaticExtending = this.tenancy.renewalOptions[index].automaticExtending ? this.$t('message.generic.selectField.yes') : this.$t('message.generic.selectField.no')
      if (this.tenancy.earlyRightOfTerminationOptions) this.dates.earlyRightOfTerminationOptions[index].until = this.$options.filters.objectToDateInput(this.tenancy.earlyRightOfTerminationOptions[index].until)
      if (this.tenancy.earlyRightOfTerminationOptions) this.dates.earlyRightOfTerminationOptions[index].exerciseUntil = this.$options.filters.objectToDateInput(this.tenancy.earlyRightOfTerminationOptions[index].exerciseUntil)
    }
    this.dates.earlyRightOfTerminationOptions = this.dates.earlyRightOfTerminationOptions.slice(0, 4)
    this.getDatesFromObjects()
    if (this.tenancy) {
      this.initialContractType = this.tenancy.contract.type
      if (this.activatedOnLoad === null) {
        this.activatedOnLoad = this.activated
      }
      if (this.objectCategory === 'PARKING_SPACE') {
        if (this.typeOfUse === 'TYPE_OF_USE_UNDEFINED' || this.typeOfUse === '' || this.typeOfUse === null || this.typeOfUse === undefined) {
          this.typeOfUse = 'TYPE_OF_USE_PARKING'
        }
      } else if (this.objectCategory === 'HOUSE' || this.objectCategory === 'APARTMENT') {
        if (this.typeOfUse === 'TYPE_OF_USE_UNDEFINED' || this.typeOfUse === '' || this.typeOfUse === null || this.typeOfUse === undefined) {
          this.typeOfUse = 'TYPE_OF_USE_RESIDENTIAL'
        }
        if (this.rentType === 'RENT_TYPE_UNDEFINED' || this.rentType === '' || this.rentType === null || this.rentType === undefined) {
          this.rentType = 'RENT_TYPE_VMWG'
        }
        if (this.typeOfUse === 'TYPE_OF_USE_RESIDENTIAL' && this.initialContractType === 'UNDEFINED_CONTRACT_TYPE') {
          this.contractType = 'INDEFINITE'
        }
      }

      this.insuranceCompanyValue = this.tenancy.deposit?.insuranceCompany ? [{ name: this.tenancy.deposit.insuranceCompany, email: this.tenancy.deposit.insuranceCompany }] : []
      // if (this.tenancy.ownerId !== this.accountId.toString()) {
      //   const contactsToDownload = this.tenancy.tenant.contactIds.concat(this.tenancy.tenant.guarantorContactIds).concat(this.tenancy.tenant.correspondenceContactId)
      //   ContactApi.contactResolve(contactsToDownload)
      //     .then(response => {
      //       this.tenantContacts = response.persons
      //         .concat(response.companies)
      //         .filter(contact => this.tenancy.tenant.contactIds.includes(contact.id))
      //     })
      //     .catch(e => {
      //       console.log(e)
      //       Vue.toasted.show(this.$t('message.loadingErrors.contacts'), { type: 'error' })
      //     })
      // }
      for (let index = 0; index < this.tenancy.rent.adjustments.length; index++) {
        this.tenancy.rent.adjustments[index].grossRent = null
      }
      this.loadInstantSearchInternal()
      // this.loadTenancyRentComponents(this.properties)
      let contactTenancies = []
      this.tenancies.forEach(element => {
        if (element.tenant.contactIds.length > 0) {
          contactTenancies = contactTenancies.concat(element.tenant.contactIds)
        }
      })
      const contactsToLoadAlgolia = this.tenancy.tenant.contactIds
        .concat([this.tenancy.tenant.billingContactId])
        .concat([this.tenancy.tenant.correspondenceContactId])
        .concat(this.tenancy.tenant.guarantorContactIds)
        .concat(contactTenancies)
      this.availableTenants(contactsToLoadAlgolia.filter(x => x !== null && x !== ''))
      this.initEndingAt = this.tenancy.endingAt
      this.checkSignatureRequest()
    }
    if (this.tenancy.unjustifiedUse && (this.tenancy.unjustifiedUse?.until !== null || this.tenancy.unjustifiedUse?.from !== null)) {
      this.unjustifiedUse = true
    }
    if (this.tenancy.activated === true && this.tenancy.closed === true) {
      this.tenancyStatus = 'activeClosed'
    } else if (this.tenancy.activated === true && this.tenancy.closed === false) {
      this.tenancyStatus = 'active'
      this.hideInprogress = true
    } else if (this.tenancy.activated === false && this.tenancy.closed === false) {
      this.tenancyStatus = 'inProgress'
    }

    if (this.isInprogress) {
      this.tenancy.activated = false
      this.tenancy.closed = false
      this.tenancyStatus = 'inProgress'
    }

    let i = 0
    this.tenancy.rent.adjustments.forEach(x => {
      x.internalId = Date.now() + i
      i = i + 1
    })
    if (this.bankAccount !== null && this.bankAccount !== '') {
      this.isBankNameRequired = true
    }
    this.loadInstantSearch(true)
    this.$nextTick(function () {
      this.activeAndEditable = (!this.object?.debitUntil || (this.object?.debitUntil && new Date(this.$options.filters.objectToDateInput(this.object.debitUntil)) < new Date(this.$options.filters.objectToDateInput(this.tenancy.startingAt))))
      this.activeAndEditableAdjustment = (!this.object?.debitUntil || (this.object?.debitUntil && new Date(this.$options.filters.objectToDateInput(this.object.debitUntil)) < new Date(this.$options.filters.objectToDateInput(this.tenancy.startingAt))))
      this.tenancy.modified = false
      if (!this.hasChangedObject && !this.hasChangedTenant) {
        this.resetChanged()
        this.isLoaded = true
      }
      this.tenancyChanged = true
    })
    if (this.readOnly) {
      this.fillWholesInGreyLines()
    }
    this.scrollToLatestCreatedTenancy()
  },
  beforeDestroy() {
    this.$root.$off('bv::collapse::state')
  },
  methods: {
    ...mapMutations('onboarding', ['setObjectOwnerContacts']),
    ...mapMutations('message', [
      'setSendMailContacts',
      'setSendMailPreselectedContact',
      'setSendMailReferenceIds',
      'setSendMailEmailToReply',
      'setSubject',
      'setAdditionalText',
      'setUseBcc',
      'setBccReferenceIds'
    ]),
    downloadAllDocuments(e) {
      e.preventDefault()
      const idsAndNames = this.documents.map(x => ({ id: x.versions[x.versions.length - 1].mediaId, mediumName: x.name }))
      const tenantsName = this.tenants.map(x => x.name).join(', ')
      const nameOfMergedFile = this.$tc('message.generic.documents') + ' - ' + tenantsName
      this.mergeDownloadAllDocuments(idsAndNames, nameOfMergedFile)
    },
    checkSignatureRequest() {
      if (this.tenancy?.id) {
        DeepSignApi.listSignRequestsByAttachToReferenceIds([this.tenancy.id]).then((response) => {
          this.signatureRequests = response.signRequests
          if (response.signRequests.length > 0) {
            response.signRequests.forEach((signRequest) => {
              if (signRequest.completed === false && signRequest.cancelled === false) {
                this.hasSignatureRequestIncomplete = true
              }
            })
          }
        })
      }
    },
    cancelSignRequest() {
      const promises = []
      this.signatureRequests.forEach(signRequest => {
        if (signRequest.completed === false && signRequest.cancelled === false) {
          promises.push(DeepSignApi.cancelRequest(signRequest.id))
        }
      })
      Promise.all(promises)
        .catch(e => {
          console.log(e)
          Vue.toasted.show(e.message, { type: 'error' })
        })
    },
    showDigitalSignatureModal() {
      this.showDigitalSignature = true
      this.$nextTick(() => {
        this.$refs.digitalSignatureModal?.show()
      })
    },
    showDigitalDepositModal() {
      this.showDigitalDeposit = true
      this.$nextTick(() => {
        this.$refs.digitalDepositModal?.show()
      })
    },
    hideDigitalSignatureModal() {
      this.showDigitalSignature = false
    },
    hideDigitalDepositModal() {
      this.showDigitalDeposit = false
    },
    contactUpdated(data) {
      const contact = data.contact
      const index = this.objectOwnerContacts.findIndex(x => x.id === contact.id)
      if (index !== -1) {
        this.objectOwnerContacts.splice(index, 1, contact)
      } else {
        this.objectOwnerContacts.push(contact)
      }
      this.setObjectOwnerContacts(this.objectOwnerContacts)
    },
    removeDuplicatedItems(items) {
      const uniqueIds = []
      const list = items.filter(element => {
        const isDuplicate = uniqueIds.includes(element.id)
        if (!isDuplicate) {
          uniqueIds.push(element.id)
          return true
        }
        return false
      })
      return list
    },
    openActivities() {
      const othersIds = this.objectDevices.filter(x => x.referenceId === this.object.id && (!x.installation || !this.tenancy.endingAt || (x.installation && this.tenancy.endingAt && new Date(this.$options.filters.objectToDateInput(this.tenancy.endingAt)).getTime() >= new Date(this.$options.filters.objectToDateInput(x.installation)).getTime()))).map(x => x.id)
      if (this.isOwnerModule) {
        this.$router.push({ name: 'OwnerActivitiesTicketView', params: { id: this.tenancy.id }, query: { view: 'tickets', othersIds: othersIds } }, () => {})
      } else if (this.isAdminModule) {
        this.$router.push({ name: 'AdminActivitiesTicketView', params: { id: this.tenancy.id }, query: { view: 'tickets', othersIds: othersIds } }, () => {})
      } else if (this.isAssetModule) {
        this.$router.push({ name: 'AssetActivitiesTicketView', params: { id: this.tenancy.id }, query: { view: 'tickets', othersIds: othersIds } }, () => {})
      } else if (this.isAccountingModule) {
        this.$router.push({ name: 'AccountingActivitiesTicketView', params: { id: this.tenancy.id }, query: { view: 'tickets', othersIds: othersIds } }, () => {})
      }
    },
    openActivitiesMiddle() {
      let routeData = ''
      const othersIds = this.objectDevices.filter(x => x.referenceId === this.object.id && (!x.installation || !this.tenancy.endingAt || (x.installation && this.tenancy.endingAt && new Date(this.$options.filters.objectToDateInput(this.tenancy.endingAt)).getTime() >= new Date(this.$options.filters.objectToDateInput(x.installation)).getTime()))).map(x => x.id)
      if (this.isOwnerModule) {
        routeData = this.$router.resolve({ name: 'OwnerActivitiesTicketView', params: { id: this.tenancy?.id }, query: { view: 'tickets', othersIds: othersIds } }, () => { })
      } else if (this.isAdminModule) {
        routeData = this.$router.resolve({ name: 'AdminActivitiesTicketView', params: { id: this.tenancy?.id }, query: { view: 'tickets', othersIds: othersIds } }, () => { })
      } else if (this.isAssetModule) {
        routeData = this.$router.resolve({ name: 'AssetActivitiesTicketView', params: { id: this.tenancy?.id }, query: { view: 'tickets', othersIds: othersIds } }, () => { })
      } else if (this.isAccountingModule) {
        routeData = this.$router.resolve({ name: 'AccountingActivitiesTicketView', params: { id: this.tenancy?.id }, query: { view: 'tickets', othersIds: othersIds } }, () => { })
      } else {
        routeData = this.$router.resolve({ name: 'MarketingActivitiesTicketView', params: { id: this.tenancy.id }, query: { view: 'tickets', othersIds: othersIds } }, () => { })
      }
      window.open(routeData.href, '_blank')
    },
    hideRenderContractModal() {
      this.$bvModal.hide(`create-contract-confirmationModal-${this.index}`)
      this.contractTemplateSelected = []
    },
    createTicket() {
      const defaultAssignee = this.property?.ownerId
      const list = [this.tenancy?.id]
      if (this.isOwnerModule) {
        this.$router.push({ name: 'OwnerTicketCreation', query: { fromBulk: true, ids: list, defaultAssignee: defaultAssignee } })
      } else if (this.isAdminModule) {
        this.$router.push({ name: 'AdminTicketCreation', query: { fromBulk: true, ids: list, defaultAssignee: defaultAssignee } })
      } else if (this.isAssetModule) {
        this.$router.push({ name: 'AssetTicketCreation', query: { fromBulk: true, ids: list, defaultAssignee: defaultAssignee } })
      } else if (this.isAccountingModule) {
        this.$router.push({ name: 'AccountingTicketCreation', query: { fromBulk: true, ids: list, defaultAssignee: defaultAssignee } })
      }
    },
    refundAmountChanged() {
      this.depositPaidOutAmountChanged = true
    },
    selectInsurance(value) {
      if (value.length > 1) {
        this.insuranceCompanyValue = value[value.length - 1]
      }
    },
    editObject(data) {
      for (const item of data) {
        if (item.objectID) {
          item.id = item.objectID
        }
      }
      return data
    },
    redirectToTerminationView() {
      localStorage.setItem('termination-page', this.$route.fullPath.includes('view=objects') ? 'objects' : 'tenants')
      this.$route.path.startsWith('/admin/buildings/') > 0 ? this.$router.push({ name: 'AdminEnterTerminationView', params: { objectId: this.tenancy.objectId, tenancyId: this.tenancy.id, buildingId: this.building.id } }) : this.$router.push({ name: 'AccountingEnterTerminationView', params: { objectId: this.tenancy.objectId, tenancyId: this.tenancy.id, buildingId: this.building.id } })
    },
    scrollToLatestCreatedTenancy() {
      if (localStorage.getItem('latestCreatedTenancy')) {
        if (this.$refs[localStorage.getItem('latestCreatedTenancy')]) {
          setTimeout(() => {
            this.$root.$emit('bv::toggle::collapse', 'coozzy-card-tenancy')
            setTimeout(() => {
              window.scroll(0, this.$refs[localStorage.getItem('latestCreatedTenancy')].getBoundingClientRect().y)
              localStorage.removeItem('latestCreatedTenancy')
            }, 1500)
          }, 1500)
        }
      }
    },
    hasOneOrMore(array) {
      return array.filter(x => x.until || x.exerciseUntil).length > 0
    },
    fillWholesInGreyLines() {
      if (this.$refs['objtenancyrow-' + this.tenancy.id]?.children?.length % 4 !== 0) {
        const div = document.createElement('div')
        div.classList.add('col-sm-12', 'col-md-3', 'grey-col', 'd-sm-none', 'd-md-block')
        div.style.backgroundColor = 'rgba(0, 68, 95, 0.03)'
        this.$refs['objtenancyrow-' + this.tenancy.id].appendChild(div)
        this.fillWholesInGreyLines()
      } else {
        this.paintWhiteAndGreyLines()
      }
    },
    paintWhiteAndGreyLines() {
      if (this.$refs['objtenancyrow-' + this.tenancy.id]) {
        const arrayOfChildren = this.$refs['objtenancyrow-' + this.tenancy.id].children
        for (let i = 0; i < arrayOfChildren.length; i++) {
          if (arrayOfChildren[i]) {
            if ((i === 0) || (parseInt((i / 4)) % 2) === 0) {
              arrayOfChildren[i].classList.add('grey-col')
            } else {
              arrayOfChildren[i].classList.add('white-col')
            }
          }
        }
      }
    },
    getPreviousDayOfTimestamp(timestamp) {
      return timestamp - 86400
    },
    convertTimestampToISODate(timestamp) {
      if (timestamp && timestamp >= 0) {
        const date = new Date(timestamp * 1000)
        return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`
      } else {
        return ''
      }
    },
    isShowen(field) {
      if (this.depositType === 'DEPOSIT_TYPE_NO_DEPOSIT') {
        return false
      }
      if (this.depositType === 'DEPOSIT_TYPE_INSURANCE_GUARANTEE') {
        if (field === 'bankName' || field === 'bankAccount' || field === 'clearingNumber' || field === 'bic' || field === 'validFrom' || field === 'validUntil') {
          return false
        }
        return true
      }
      if (this.depositType === 'DEPOSIT_TYPE_COLLECTIVE_INSURANCE') {
        if (field === 'bankName' || field === 'bankAccount' || field === 'clearingNumber' || field === 'bic' || field === 'validFrom' || field === 'validUntil' || field === 'depositDueDate') {
          return false
        }
        return true
      }
      if (this.depositType === 'DEPOSIT_TYPE_DEPOT') {
        if (field === 'depositStreetNumber' || field === 'insuranceCompany' || field === 'policyNumber' || field === 'validFrom' || field === 'validUntil') {
          return false
        }
        return true
      }
      if (this.depositType === 'DEPOSIT_TYPE_BANK_GUARANTEE') {
        if (field === 'depositStreetNumber' || field === 'insuranceCompany' || field === 'policyNumber' || field === 'bankAccount') {
          return false
        }
        return true
      }
      if (this.depositType === 'DEPOSIT_TYPE_COOPERATIVE_SHARE' || this.depositType === 'DEPOSIT_TYPE_CASH_PAYMENT' || this.depositType === 'DEPOSIT_TYPE_COMPULSORY_SHARE_CERTIFICATES') {
        if (field !== 'depositAmount' && field !== 'depositDueDate' && field !== 'depositPaidAt' && field !== 'depositPaidAmount') {
          return false
        }
        return true
      }
    },
    changeAdjustment(newAdjustment) {
      const index = this.tenancy.rent.adjustments.findIndex(adj => adj.internalId === newAdjustment.internalId)
      const newTenancy = this.tenancy
      newTenancy.rent.adjustments[index] = newAdjustment
      this.dispatchTenancyUpdate(newTenancy)
      this.$emit('tenancy-updated', newTenancy)
    },
    changeSubTenant() {
      const newTenancy = this.tenancy
      this.dispatchTenancyUpdate(newTenancy)
      this.$emit('tenancy-updated', newTenancy)
    },
    changeDepositShare() {
      const newTenancy = this.tenancy
      this.dispatchTenancyUpdate(newTenancy)
      this.$emit('tenancy-updated', newTenancy)
    },
    changeRoommate() {
      const newTenancy = this.tenancy
      this.dispatchTenancyUpdate(newTenancy)
      this.$emit('tenancy-updated', newTenancy)
    },
    addActivityClicked() {
      this.$refs['create-activity' + this.tenancy.id].show()
    },
    addReminderClicked() {
      this.$refs['reminder-creation-modal' + this.tenancy.id].show()
    },
    openSendMailModal() {
      const tenantContactsWithEmail = this.tenantContacts.filter(tenant => tenant.email !== '' && tenant.email !== null)
      this.setSendMailContacts(tenantContactsWithEmail)
      this.setSendMailPreselectedContact(tenantContactsWithEmail)
      this.setSendMailReferenceIds([this.tenancy.id])
      this.setSendMailEmailToReply(null)
      this.$nextTick(function () {
        this.callShowSendMailModal()
      })
    },
    activateTenancyClicked() {
      this.tenanciesList = []
      this.lastTenancy = []
      let tenant = null
      if (this.tenancy) {
        if (this.tenancies.find(x => x.status === 'TENANCY_STATUS_CURRENT' && x.endingAt < 0 && x.objectId === this.tenancy.objectId && x.id !== this.tenancy.id)) {
          tenant = this.tenancies.find(x => x.status === 'TENANCY_STATUS_CURRENT' && x.endingAt < 0 && x.objectId === this.tenancy.objectId) || null
        } else {
          tenant = this.tenancies.filter(x => x.objectId === this.tenancy.objectId && x.id !== this.tenancy.id).sort(function (a, b) {
            return b.endingAt - a.endingAt
          })[0] || null
        }
        if (tenant !== null && tenant.vacancy === false) {
          this.lastTenancy.push(tenant)
        }
        ObjectApi.getTenanciesByTenant(this.tenancy.ownerId, this.tenancy.tenant.billingContactId)
          .then((response) => {
            if (response && response.tenancies) {
              this.tenanciesList = response.tenancies.filter(x => x.status === 'TENANCY_STATUS_NOT_ACTIVATED')
              let contactIds = []
              const objectsIds = []
              const promises = []
              this.tenanciesList.forEach(element => {
                objectsIds.push(element.objectId)
                contactIds = contactIds.concat(element.tenant?.contactIds)
              })
              if (objectsIds.length > 0) {
                promises.push(ObjectApi.listObjectsByIds(objectsIds))
              }
              if (contactIds.length > 0) {
                promises.push(ContactApi.contactResolve(contactIds))
              }
              Promise.all(promises)
                .then(response => {
                  if (response.length > 0) {
                    response.forEach(element => {
                      if (element.objects) {
                        this.objectsList = this.objects.concat(element.objects)
                      }
                      if (element.persons || element.companies) {
                        this.contactsList = element.persons.concat(element.companies)
                      }
                    })
                    this.showActivateTenancyModal = true
                    this.$nextTick(() => {
                      this.$refs.activateTenancyModal.show()
                    })
                  }
                })
                .catch(e => {
                  console.log(e)
                  Vue.toasted.show(this.$t('message.loadingErrors.contacts'), { type: 'error' })
                })
            }
          })
      }
      // if (this.isAdminModule) {
      //   this.$router.push({
      //     name: 'AdminActivateTenancy',
      //     params: { objectId: this.tenancy.objectId },
      //     query: { tenancyId: this.tenancy.id, readMode: this.readOnly }
      //   })
      // } else if (this.isAccountingModule) {
      //   this.$router.push({
      //     name: 'AccountingActivateTenancy',
      //     params: { objectId: this.tenancy.objectId },
      //     query: { tenancyId: this.tenancy.id, readMode: this.readOnly }
      //   })
      // } else {
      //   this.$router.push({
      //     name: 'AssetActivateTenancy',
      //     params: { objectId: this.tenancy.objectId },
      //     query: { tenancyId: this.tenancy.id, readMode: this.readOnly }
      //   })
      // }
    },
    hideActivateTenancyModal() {
      this.showActivateTenancyModal = false
    },
    tenancyActivated(document = null) {
      if (document) {
        const newTenancy = this.tenancy
        newTenancy.documentIds.push(document.id)
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)

        // Add whole new document to vuex store to display it
        this.tenancyDocuments.push(document)
      }
      this.tenancy.activated = true
      this.activated = true
      this.activatedOnLoad = this.activated
      this.tenancy.status = 'TENANCY_STATUS_CURRENT'
      this.tenancyStatus = 'active'
    },
    addDepositShareClicked() {
      // if depositShares doesn't exist on tenancy.deposit
      if (!this.tenancy.deposit.depositShares) {
        this.$set(this.tenancy.deposit, 'depositShares', [])
      }
      this.tenancy.deposit.depositShares.push({
        contactId: '',
        amount: null
      })
      this.dispatchTenancyUpdate(this.tenancy)
      this.$emit('tenancy-updated', this.tenancy)
    },
    addSubTenantClicked() {
      this.tenancy.subTenants.push({
        contactIds: [],
        startingAt: null,
        endingAt: null
      })
      this.dispatchTenancyUpdate(this.tenancy)
      this.$emit('tenancy-updated', this.tenancy)
    },
    addRoommateClicked() {
      this.tenancy.roommates.push({
        firstName: '',
        lastName: '',
        birthday: null
      })
      this.dispatchTenancyUpdate(this.tenancy)
      this.$emit('tenancy-updated', this.tenancy)
    },
    addAdjustmentClicked() {
      this.tenancy.rent.adjustments.forEach(adj => {
        if (adj.visibleCollaps) {
          adj.visibleCollaps = false
        }
      })
      const adjustmentAdvancePayment = []
      const adjustmentFlatRate = []
      const adjustmentAdditionalRent = []
      if (this.properties && this.properties.length > 0) {
        this.properties.forEach(property => {
          // Get the adjustment to copy the values from
          const baseAdjustment = this.adjustments.length > 0 ? this.adjustments.reduce((p, c) => new Date(this.$options.filters.objectToDateInput(p.byDate)) > new Date(this.$options.filters.objectToDateInput(c.byDate)) ? p : c) : null
          property.advancePaymentRentComponents.forEach(element => {
            if (element.inactive === false) {
              const item = baseAdjustment?.advancePaymentValues.find(e => e.type === element.rentComponent)
              adjustmentAdvancePayment.push({
                type: element.rentComponent,
                value: item ? item.value : null
              })
            }
          })
          property.flatRateRentComponents.forEach(element => {
            if (element.inactive === false) {
              const item = baseAdjustment?.flatRateValues.find(e => e.type === element.rentComponent)
              adjustmentFlatRate.push({
                type: element.rentComponent,
                value: item ? item.value : null
              })
            }
          })
          property.additionalRentComponents.forEach(element => {
            if (element.inactive === false) {
              const item = baseAdjustment?.additionalValues.find(e => e.type === element.rentComponent)
              adjustmentAdditionalRent.push({
                type: element.rentComponent,
                value: item ? item.value : null
              })
            }
          })
        })
      }

      const previousRentAdjustment = this.adjustments[0]
      const internalId = Date.now()
      this.tenancy.rent.adjustments.unshift({
        internalId: internalId,
        calculationBasis: previousRentAdjustment ? previousRentAdjustment.calculationBasis : '',
        byDate: null,
        newItem: true,
        dueDates: previousRentAdjustment ? previousRentAdjustment.dueDates : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        coldRent: previousRentAdjustment ? previousRentAdjustment.coldRent : -1,
        coldRentM2PerYear: previousRentAdjustment ? previousRentAdjustment.coldRentM2PerYear : -1,
        coldRentPerMonth: previousRentAdjustment ? previousRentAdjustment.coldRentPerMonth : -1,
        additionalCosts: -1,
        grossRent: -1,
        heatingCosts: -1,
        costIncreaseType: previousRentAdjustment ? previousRentAdjustment.costIncreaseType : 'COST_INCREASE_TYPE_FLAT_RATE',
        costIncreaseDate: previousRentAdjustment ? previousRentAdjustment.costIncreaseDate : null,
        costIncreaseValue: previousRentAdjustment ? previousRentAdjustment.costIncreaseValue : null,
        costIncreasePossibleDate: previousRentAdjustment ? previousRentAdjustment.costIncreasePossibleDate : null,
        countryIndex: {
          baseDate: previousRentAdjustment && previousRentAdjustment.countryIndex ? previousRentAdjustment.countryIndex.baseDate : null,
          date: previousRentAdjustment && previousRentAdjustment.countryIndex ? previousRentAdjustment.countryIndex.date : null,
          inflation: previousRentAdjustment && previousRentAdjustment.countryIndex ? previousRentAdjustment.countryIndex.inflation : null,
          possibleDate: previousRentAdjustment && previousRentAdjustment.countryIndex ? previousRentAdjustment.countryIndex.possibleDate : null,
          possibleInflation: previousRentAdjustment && previousRentAdjustment.countryIndex ? previousRentAdjustment.countryIndex.possibleInflation : -1
        },
        referenceInterestRate: {
          date: previousRentAdjustment && previousRentAdjustment.referenceInterestRate ? previousRentAdjustment.referenceInterestRate.date : null,
          rate: previousRentAdjustment && previousRentAdjustment.referenceInterestRate ? previousRentAdjustment.referenceInterestRate.rate : -10000,
          ratePossible: previousRentAdjustment && previousRentAdjustment.referenceInterestRate ? previousRentAdjustment.referenceInterestRate.ratePossible : -10000
        },
        documentIds: [],
        monthlyRentDueBy: previousRentAdjustment ? previousRentAdjustment.monthlyRentDueBy : 1,
        inArrears: previousRentAdjustment ? previousRentAdjustment.inArrears : false,
        advancePaymentValues: adjustmentAdvancePayment,
        flatRateValues: adjustmentFlatRate,
        additionalValues: adjustmentAdditionalRent,
        finalized: false,
        visibleCollaps: true
      })
      this.dispatchTenancyUpdate(this.tenancy)
      this.$emit('tenancy-updated', this.tenancy)
      this.$nextTick(() => {
        if (this.$el) {
          const domRect = this.$el.querySelector('#collapse-tenancy-adjustedcosts-index-' + internalId)
          if (domRect) {
            domRect.scrollIntoView(true)
            this.$el.querySelector('#collapse-tenancy-adjustedcosts-index-' + internalId + ' input[type="date"]')?.focus()
          }
        }
      })
    },
    addDocumentClicked(suffix = '') {
      this.$bvModal.show('add-document-modal' + suffix)
    },
    pack(blobs) {
      const zip = new JSZip()
      const folder = zip.folder('contract')
      blobs.forEach((blob) => folder.file(blob.name, blob))
      zip.generateAsync({ type: 'blob' }).then(function(content) {
        // see FileSaver.js
        saveAs(content, 'contract.zip')
      })
    },

    navigateToCreateOrder() {
      const currentRoute = { name: this.$router.currentRoute.name, params: this.$router.currentRoute.params, query: this.$router.currentRoute.query }
      localStorage.setItem('LS_ROUTE_KEY', JSON.stringify(currentRoute))
      const tenancyIds = this.tenancy.id
      if (this.isOwnerModule) {
        this.$router.push({ name: 'OwnerCreateOrderView', query: { tenancyIds: tenancyIds, source: 'tenancy' } }, () => {})
      } else if (this.isAdminModule) {
        this.$router.push({ name: 'AdminCreateOrderView', query: { tenancyIds: tenancyIds, source: 'tenancy' } }, () => {})
      } else if (this.isAssetModule) {
        this.$router.push({ name: 'AssetCreateOrderView', query: { tenancyIds: tenancyIds, source: 'tenancy' } }, () => {})
      } else if (this.isAccountingModule) {
        this.$router.push({ name: 'AccountingCreateOrderView', query: { tenancyIds: tenancyIds, source: 'tenancy' } }, () => {})
      }
    },
    renderContract() {
      const promises = []
      this.renderContractLoading = true
      const arr = []
      const listTemplatePdf = []
      for (let index = 0; index < this.contractTemplateSelected.length; index++) {
        const signatories = []
        const contract = this.contractTemplate.find(x => x.id === this.contractTemplateSelected[index])
        if (contract && !contract.mediaId.includes('.pdf')) {
          if (contract && contract.firstSignature !== '') {
            signatories.push(contract.firstSignature)
          }
          if (contract && contract.secondSignature) {
            if (contract && contract.firstSignature === '') {
              signatories.push('')
            }
            signatories.push(contract.secondSignature)
          }
          promises.push(ObjectApi.RenderTenancyTemplate(this.contractTemplateSelected[index], this.tenancy.id, this.addDocumentToContact, this.showInPortal, this.title, this.description, '', '', signatories))
        } else if (contract) {
          listTemplatePdf.push(contract.mediaId)
        }
      }
      if (listTemplatePdf.length > 0) {
        MediaApi.getListMedia(listTemplatePdf).then(response => {
          for (const media of response.media) {
            saveAs(media.url, media.filename)
          }
        })
      }
      for (let i = 0; i < arr.length; i++) {
        this.contractTemplateSelected.splice(i, 0, this.contractTemplateSelected[i])
      }
      Promise.all(promises)
        .then(response => {
          this.$bvModal.hide(`create-contract-confirmationModal-${this.index}`)
          const renderId = []
          response.forEach((element, index) => {
            let nameBlob = ''
            if (this.contractTemplateSelected.length > 10) {
              const contract = this.contractTemplate.find(x => x.id === this.contractTemplateSelected[index])
              if (this.tenancy.vacancy === false && this.tenants.length > 0) {
                nameBlob = contract.title + '_' + this.tenants.map(x => x.name).join('_') + '_' + this.tenancy.numericId || this.currentObject.numericId
              } else {
                nameBlob = contract.title + '_' + this.tenancy.numericId || this.currentObject.numericId
              }
              const item = { renderId: element.renderId + '_' + nameBlob + '-/-' + contract.enforcePdf, contract: contract }
              renderId.push(item)
            } else {
              const contract = this.contractTemplate.find(x => x.id === this.contractTemplateSelected[index])
              let downloadName = ''
              if (this.tenancy.vacancy === false && this.tenants.length > 0) {
                downloadName = contract.title + '_' + this.tenants.map(x => x.name).join('_') + '_' + this.tenancy.numericId || this.currentObject.numericId
              } else {
                downloadName = contract.title + '_' + this.tenancy.numericId || this.currentObject.numericId
              }
              const item = { renderId: element.renderId + '_' + downloadName + '-/-' + contract.enforcePdf, contract: contract }
              renderId.push(item)
            }
          })
          localStorage.setItem('renderId', JSON.stringify(renderId))
        })
        .catch(() => {
          this.loading = false
          Vue.toasted.show(this.$t('message.applicationForm.creditTrust.certificateOrderError'), { type: 'error' })
        })
        .finally(() => {
          this.renderContractLoading = false
          this.contractTemplateSelected = []
        })
    },
    handleErrorOnFinish(e) {
      console.log(e)
      this.loading = false
      Vue.toasted.show(this.$t('message.loadingErrors.tenancy'), { type: 'error' })
    },
    getModulo(array) {
      let foo = []
      for (let i = 0; i < array.length / 4; i++) {
        foo.push(i)
      }
      return foo
    },
    advancePaymentValueChanged(adj, item, val) {
      if (adj !== null) {
        this.adjustments[adj].advancePaymentValues.find(x => x.type === item.type).value = val
      } else {
        const newTenancy = this.tenancy
        newTenancy.rent.advancePaymentValues.find(x => x.type === item.type).value = val
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    flatRateValueChanged(adj, item, val) {
      if (adj !== null) {
        this.adjustments[adj].flatRateValues.find(x => x.type === item.type).value = val
      } else {
        const newTenancy = this.tenancy
        newTenancy.rent.flatRateValues.find(x => x.type === item.type).value = val
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    additionalValueChanged(adj, item, val) {
      if (adj !== null) {
        this.adjustments[adj].additionalValues.find(x => x.type === item.type).value = val
      } else {
        const newTenancy = this.tenancy
        newTenancy.rent.additionalValues.find(x => x.type === item.type).value = val
        this.dispatchTenancyUpdate(newTenancy)
        this.$emit('tenancy-updated', newTenancy)
      }
    },
    dateToObject(field = '') {
      if (field === 'nextPossibleMoveInAt') {
        this.nextPossibleMoveInAt = this.toObject(this.dates.nextPossibleMoveInAt)
      } else if (field === 'startingAt') {
        this.startingAt = this.toObject(this.dates.startingAt)
      } else if (field === 'endingAt') {
        if (this.dates.endingAt === '') {
          this.tenancy.terminatedOn = null
          this.dates.terminatedOn = null
        }
        this.endingAt = this.toObject(this.dates.endingAt)
        this.movedOutAt = this.toObject(this.dates.movedOutAt)
        this.liabilityEndsAt = this.toObject(this.dates.liabilityEndsAt)
      } else {
        this.terminatedOn = this.toObject(this.dates.terminatedOn)
        this.contractCreationDate = this.toObject(this.dates.contractCreationDate)
        this.tenantEarliestDate = this.toObject(this.dates.tenantEarliestDate)
        this.landlordEarliestDate = this.toObject(this.dates.landlordEarliestDate)
        this.depositDueDate = this.toObject(this.dates.depositDueDate)
        this.depositPaidAt = this.toObject(this.dates.depositPaidAt)
        this.depositPaidOutAt = this.toObject(this.dates.depositPaidOutAt)
        this.certificateReceivedAt = this.toObject(this.dates.certificateReceivedAt)
        this.terminationCreatedAt = this.toObject(this.dates.terminationCreatedAt)
        this.movedOutAt = this.toObject(this.dates.movedOutAt)
        this.liabilityEndsAt = this.toObject(this.dates.liabilityEndsAt)
        this.firstRentAdjustmentPossibleAt = this.toObject(this.dates.firstRentAdjustmentPossibleAt)
        this.depositValidFrom = this.toObject(this.dates.depositValidFrom)
        this.depositValidUntil = this.toObject(this.dates.depositValidUntil)
        this.unjustifiedUseTo = this.toObject(this.dates.unjustifiedUseTo)
        this.unjustifiedUseFrom = this.toObject(this.dates.unjustifiedUseFrom)
        this.terminationSentByTenantAt = this.toObject(this.dates.terminationSentByTenantAt)
      }
    },
    optionDateToObject() {
      for (let index = 0; index < 4; index++) {
        const element = this.dates.renewalOptions[index]
        const newTenancy = this.tenancy
        if (element.from) {
          newTenancy.renewalOptions[index].from = this.toObject(element.from)
        } else {
          newTenancy.renewalOptions[index].from = null
        }
        if (element.until) {
          newTenancy.renewalOptions[index].until = this.toObject(element.until)
        } else {
          newTenancy.renewalOptions[index].until = null
        }
        if (element.exerciseUntil) {
          newTenancy.renewalOptions[index].exerciseUntil = this.toObject(element.exerciseUntil)
        } else {
          newTenancy.renewalOptions[index].exerciseUntil = null
        }
        this.dispatchTenancyUpdate(newTenancy)
      }
      for (let j = 0; j < this.dates.earlyRightOfTerminationOptions.length; j++) {
        const element = this.dates.earlyRightOfTerminationOptions[j]
        const newTenancy = this.tenancy
        if (element.until) {
          newTenancy.earlyRightOfTerminationOptions[j].until = this.toObject(element.until)
        } else {
          newTenancy.earlyRightOfTerminationOptions[j].until = null
        }
        if (element.exerciseUntil) {
          newTenancy.earlyRightOfTerminationOptions[j].exerciseUntil = this.toObject(element.exerciseUntil)
        } else {
          newTenancy.earlyRightOfTerminationOptions[j].exerciseUntil = null
        }
        this.dispatchTenancyUpdate(newTenancy)
      }
    },
    deleteAdjustmentClicked(index) {
      this.$delete(this.tenancy.rent.adjustments, this.tenancy.rent.adjustments.findIndex(adj => adj.internalId === index))
      this.dispatchTenancyUpdate(this.tenancy)
    },
    deleteSubTenantClicked(index) {
      this.$delete(this.tenancy.subTenants, index)
      this.dispatchTenancyUpdate(this.tenancy)
    },
    deleteRoommateClicked(index) {
      this.$delete(this.tenancy.roommates, index)
      this.dispatchTenancyUpdate(this.tenancy)
    },
    deleteDepositShareClicked(index) {
      this.$delete(this.tenancy.deposit.depositShares, index)
      this.dispatchTenancyUpdate(this.tenancy)
    },
    deleteDocument(document) {
      if (document.type === 'RENT_ADJUSTMENT_DOC_TYPE') {
        this.tenancy.rent.adjustments.forEach(adj => {
          adj.documentIds.forEach((doc, index) => {
            if (doc === document.id) {
              adj.documentIds.splice(index, 1)
            }
          }, this)
        })
      }
      this.tenancy.documentIds.forEach((doc, index) => {
        if (doc === document.id) {
          this.tenancy.documentIds.splice(index, 1)
        }
      }, this)
      this.dispatchTenancyUpdate(this.tenancy)
      this.$emit('tenancy-updated', this.tenancy)
      this.activateChanged()
    },
    getIdCorrespondenceContact(obj) {
      return obj.objectID ? obj.objectID : obj.id
    },
    openExtendTenancy() {
      this.$refs['extend-tenancy-modal'].show()
    },
    extendConfirmed() {
      this.dates.endingAt = this.$options.filters.objectToDateInput(this.selectedEntriesExtend[0].until)
      this.endingAt = this.selectedEntriesExtend[0].until
      const today = new Date()
      this.dates.terminationCreatedAt = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`
      this.terminationCreatedAt = this.toObject(this.dates.terminationCreatedAt)
      if (this.tenancy.terminatedOn === null) {
        this.dates.terminatedOn = this.dates.endingAt
        this.terminatedOn = this.toObject(this.dates.terminatedOn)
      }
      const dataToAdd = new Date(this.dates.endingAt)
      const datatodays = dataToAdd.setDate(new Date(dataToAdd).getDate() + 1)
      this.dates.nextPossibleMoveInAt = this.$options.filters.timestampToDateInput(datatodays / 1000)
      this.nextPossibleMoveInAt = this.toObject(this.dates.nextPossibleMoveInAt)
      const newTenancy = this.tenancy
      const index = newTenancy.renewalOptions.findIndex(x => new Date(this.$options.filters.objectToDateInput(x.until)).getTime() / 1000 === new Date(this.$options.filters.objectToDateInput(this.selectedEntriesExtend[0].until)).getTime() / 1000)
      newTenancy.renewalOptions[index].used = true
      this.dispatchTenancyUpdate(newTenancy)
      this.$emit('tenancy-updated', newTenancy)
      Vue.toasted.show(this.$t('message.successMessages.extendTenancy'), { type: 'success' })
      this.$nextTick(function () {
        this.saveOnboarding()
      })
      if (this.$refs['extend-tenancy-modal']) {
        this.$refs['extend-tenancy-modal'].hide()
      }
    },
    itemExtendSelected(item, checked) {
      if (checked) {
        this.selectedEntriesExtend.push(item)
      } else {
        this.selectedEntriesExtend = this.selectedEntriesExtend.filter(entry => entry.id !== item.id)
      }
    },
    handleCheckboxesInsideTable(value) {
      const allCheckboxesComponents = this.$refs['table-real-option'].$children[1].$children
      this.handleCheckbox(allCheckboxesComponents, value)
    },
    dispatchTenancyUpdate(newTenancy) {
      if (this.tenancyChanged) {
        this.setChangedTenant(true)
        this.$store.dispatch('onboarding/updateTenancy', newTenancy)
      }
    },
    documentCreated(document) {
      // Update tenancy in vuex store with new documentId
      const newTenancy = this.tenancy
      newTenancy.documentIds.push(document.id)
      this.dispatchTenancyUpdate(newTenancy)
      this.$emit('tenancy-updated', newTenancy)

      // Add whole new document to vuex store to display it
      this.tenancyDocuments.push(document)
    },
    versionAdded(updatedDocument) {
      this.tenancyDocuments.forEach((doc, index) => {
        if (doc.id === updatedDocument.id) {
          this.tenancyDocuments.splice(index, 1, updatedDocument)
        }
      }, this)
      this.dispatchTenancyUpdate(this.tenancy)
      this.$emit('tenancy-updated', this.tenancy)
    },
    async documentCreatedAction(document, suffix, contactId, addNewDoc) {
      // Update tenancy in vuex store with new documentId
      const newTenancy = this.tenancy
      newTenancy.documentIds.push(document.id)
      this.dispatchTenancyUpdate(newTenancy)
      this.$emit('tenancy-updated', newTenancy)

      // Add whole new document to vuex store to display it
      this.tenancyDocuments.push(document)
      if (!addNewDoc) {
        await this.saveOnboarding()
      }
    },
    getContact(contactId) {
      let allContacts = this.contacts
        .concat(this.tenantContacts)
        .concat(this.guarantorContacts)
        .concat(this.objectOwnerContacts)

      if (this.billingContact) {
        allContacts = allContacts.filter(x => x.id !== this.billingContact.id)
        allContacts.push(this.billingContact)
      }
      if (this.correspondenceContact) {
        allContacts = allContacts.filter(x => x.id !== this.correspondenceContact.id)
        allContacts.push(this.correspondenceContact)
      }
      return allContacts.find(contact => contact.id === contactId || contact.objectID === contactId)
    },
    getContactName(contactId) {
      const contact = this.getContact(contactId)
      return contact ? ', ' + contact.name : '-'
    },
    getDatesFromObjects: function () {
      this.dates.startingAt = this.$options.filters.objectToDateInput(this.startingAt)
      this.dates.endingAt = this.$options.filters.objectToDateInput(this.endingAt)
      this.dates.contractCreationDate = this.$options.filters.objectToDateInput(this.contractCreationDate)
      this.dates.tenantEarliestDate = this.$options.filters.objectToDateInput(this.tenantEarliestDate)
      this.dates.landlordEarliestDate = this.$options.filters.objectToDateInput(this.landlordEarliestDate)
      this.dates.depositDueDate = this.$options.filters.objectToDateInput(this.depositDueDate)
      this.dates.depositPaidAt = this.$options.filters.objectToDateInput(this.depositPaidAt)
      this.dates.depositPaidOutAt = this.$options.filters.objectToDateInput(this.depositPaidOutAt)
      this.dates.certificateReceivedAt = this.$options.filters.objectToDateInput(this.certificateReceivedAt)
      this.dates.terminationCreatedAt = this.$options.filters.objectToDateInput(this.terminationCreatedAt)
      this.dates.terminatedOn = this.$options.filters.objectToDateInput(this.terminatedOn)
      this.dates.movedOutAt = this.$options.filters.objectToDateInput(this.movedOutAt)
      this.dates.liabilityEndsAt = this.$options.filters.objectToDateInput(this.liabilityEndsAt)
      this.dates.nextPossibleMoveInAt = this.$options.filters.objectToDateInput(this.nextPossibleMoveInAt)
      this.dates.firstRentAdjustmentPossibleAt = this.$options.filters.objectToDateInput(this.firstRentAdjustmentPossibleAt)
      this.dates.depositValidFrom = this.$options.filters.objectToDateInput(this.depositValidFrom)
      this.dates.depositValidUntil = this.$options.filters.objectToDateInput(this.depositValidUntil)
      this.dates.unjustifiedUseFrom = this.$options.filters.objectToDateInput(this.unjustifiedUseFrom)
      this.dates.unjustifiedUseTo = this.$options.filters.objectToDateInput(this.unjustifiedUseTo)
      this.dates.terminationSentByTenantAt = this.$options.filters.objectToDateInput(this.terminationSentByTenantAt)
    },
    isInvalid() {
      let valid = true
      this.onlyErrorStartingAt = true
      const errorMsg = []
      const isTenancyUpdated = 'modified' in this.tenancy && this.tenancy.modified === true
      if (isTenancyUpdated || 'internalId' in this.tenancy) {
        this.$v.$touch()
        if (this.$v.$invalid) {
          if (this.rentType === 'RENT_TYPE_INDEX' || this.rentType === 'RENT_TYPE_TURNOVER') {
            if (this.$v.firstRentAdjustmentPossibleAt.$dirty && this.$v.firstRentAdjustmentPossibleAt.$error && this.firstRentAdjustmentPossibleAt) {
              errorMsg.push(this.$t('message.savingErrors.firstRentPossibleAtAfterEndingAt'))
            }
          }
          if (this.checkOptions && this.hasOneOrMore(this.renewalOptions)) {
            for (let index = 0; index < 4; index++) {
              const element = this.$v.renewalOptions.$each[index]
              if (element.$error) {
                if (element.until.$error || element.exerciseUntil.$error || element.until.validMoreThanFrom) {
                  if (!element.until.validMoreThanContractDate || !element.exerciseUntil.validMoreThanContractDate) {
                    errorMsg.push(this.$t('message.savingErrors.renewalValidMoreThanContractDate'))
                  }
                  if (!element.exerciseUntil.validRenewalOptionExerciceUntil || !element.until.validRenewalOptionUntil) {
                    errorMsg.push(this.$t('message.savingErrors.renewalValidRenewalOptionExerciceUntil'))
                  }
                  if (!element.until.validMoreThanFrom) {
                    errorMsg.push(this.$t('message.savingErrors.invalidMoreThanFrom'))
                  }
                }
              }
              const element2 = this.$v.earlyRightOfTerminationOptions.$each[index]
              if (element2.$error) {
                if (element2.until.$error || element2.exerciseUntil.$error) {
                  if (!element2.until.validMoreThanContractDate || !element2.exerciseUntil.validMoreThanContractDate) {
                    errorMsg.push(this.$t('message.savingErrors.earlyRightValidMoreThanContractDate'))
                  }
                  if (!element2.exerciseUntil.validRenewalOptionExerciceUntil || !element2.until.validRenewalOptionUntil) {
                    errorMsg.push(this.$t('message.savingErrors.earlyRightValidRenewalOptionExerciceUntil'))
                  }
                }
              }
            }
          }
          this.scrollToError()
          valid = false
        }
        if (this.$v.endingAt.$dirty && this.$v.endingAt.$error && !this.$v.endingAt.moreThanDebitUntil) {
          this.scrollToError()
          valid = false
          errorMsg.push(this.$t('message.savingErrors.endDateBeforeDebit', { debitUntil: this.$options.filters.objectToDate(this.object.debitUntil) }))
        } else if (this.$v.startingAt.$dirty && this.$v.startingAt.$error && !this.$v.startingAt.startingMoreThanDebitUntil) {
          this.scrollToError()
          valid = false
          errorMsg.push(this.$t('message.savingErrors.startDateBeforeDebit', { debitUntil: this.$options.filters.objectToDate(this.object.debitUntil) }))
        } else if (this.$v.endingAt.$dirty && this.$v.endingAt.$error && !this.isFirst && this.tenancy.id) {
          this.scrollToError()
          valid = false
          this.onlyErrorStartingAt = false
          errorMsg.push(this.$t('message.savingErrors.endDateCantchangeAfterwards'))
        } else if (this.$v.startingAt.$dirty && this.$v.startingAt.$error && this.startingAt === -1) {
          errorMsg.push(this.$t('message.savingErrors.validationError') + this.$t('message.onBoarding.buildings.objects.tenancies.tenancy') + this.$t('message.savingErrors.validationError2'))
        } else if (this.$v.startingAt.$dirty && this.$v.startingAt.$error && this.startingAt !== -1) {
          errorMsg.push(this.$t('message.savingErrors.tenancyStartingAtAfterEndingAt'))
        }
        if (this.$v.liabilityEndsAt.$dirty && this.$v.liabilityEndsAt.$error && this.endingAt) {
          errorMsg.push(this.$t('message.savingErrors.liabilityNotBeforeEndContract'))
        }
        if (this.$v.nextPossibleMoveInAt && this.$v.nextPossibleMoveInAt.$dirty && this.$v.nextPossibleMoveInAt.$error && !this.$v.nextPossibleMoveInAt.moreThanMoveOutDate) {
          errorMsg.push(this.$t('message.savingErrors.nextPossibleMoveInBeforeMoveOutAt'))
        }
        if (this.readOnly || !this.enableTenantSelection) {
          const tenants = this.tenancy.tenant.contactIds.map(x => this.getContact(x))
          const hasNoAddress = tenants.filter(x => !x || !x.address || !(x.address.zip && x.address.city && x.address.country))
          if (hasNoAddress.length > 0) {
            errorMsg.push(this.$t('message.savingErrors.tenantHaveAddress'))
          }
        } else {
          if (this.hasNoAddress.length > 0) {
            errorMsg.push(this.$t('message.savingErrors.tenantHaveAddress'))
          }
        }
        if ((this.$v.landlordEarliestDate.$dirty && this.$v.landlordEarliestDate.$error) || (this.$v.tenantEarliestDate.$dirty && this.$v.tenantEarliestDate.$error)) {
          errorMsg.push(this.$t('message.savingErrors.earliestTerminationDateAfterStartingDate'))
        }
        if ((this.$v.tenantPossibleMonths && this.$v.tenantPossibleMonths.$dirty && this.$v.tenantPossibleMonths.$error) ||
          (this.$v.terminatedBy && this.$v.terminatedBy.$dirty && this.$v.terminatedBy.$error) ||
          (this.$v.terminationType && this.$v.terminationType.$dirty && this.$v.terminationType.$error) ||
          (this.$v.terminationReason && this.$v.terminationReason.$dirty && this.$v.terminationReason.$error) ||
          (this.$v.nextPossibleMoveInAt && this.$v.nextPossibleMoveInAt.$dirty && this.$v.nextPossibleMoveInAt.$error) ||
          (this.$v.terminatedOn && this.$v.terminatedOn.$dirty && this.$v.terminatedOn.$error) ||
          (this.$v.movedOutAt && this.$v.movedOutAt.$dirty && this.$v.movedOutAt.$error) ||
          (this.$v.liabilityEndsAt && this.$v.liabilityEndsAt.$dirty && this.$v.liabilityEndsAt.$error)) {
          errorMsg.push(this.$t('message.savingErrors.mandatoryMissing'))
        }
        if (this.$v.terminatedOn.$dirty && this.$v.terminatedOn.$error) {
          errorMsg.push(this.$t('message.savingErrors.mandatoryMissing'))
        }
        if (this.$v.movedOutAt.$dirty && this.$v.movedOutAt.$error) {
          errorMsg.push(this.$t('message.savingErrors.mandatoryMissing'))
        }
        if (this.$v.liabilityEndsAt.$dirty && this.$v.liabilityEndsAt.$error) {
          errorMsg.push(this.$t('message.savingErrors.mandatoryMissing'))
        }
        if (this.mandatoryTerminationFields) {
          if (this.$v.terminationReason.$dirty && this.$v.terminationReason.$error) {
            errorMsg.push(this.$t('message.savingErrors.mandatoryMissing'))
          }
          if (this.$v.terminatedBy.$dirty && this.$v.terminatedBy.$error) {
            errorMsg.push(this.$t('message.savingErrors.mandatoryMissing'))
          }
          if (this.$v.terminationType.$dirty && this.$v.terminationType.$error) {
            errorMsg.push(this.$t('message.savingErrors.mandatoryMissing'))
          }
          if (this.$v.nextPossibleMoveInAt.$dirty && this.$v.nextPossibleMoveInAt.$error) {
            errorMsg.push(this.$t('message.savingErrors.mandatoryMissing'))
          }
        }
        if (this.$refs['tenancy-adjustment']) {
          this.$refs['tenancy-adjustment'].forEach(element => {
            if (element.isInvalid().$invalid) {
              // const v = element.isInvalid()
              // if (v.adjustment.byDate.$dirty && v.adjustment.byDate.$error && !v.adjustment.byDate.notEqualToOtherAdjBydate) {
              //   Vue.toasted.show(this.$t('message.savingErrors.notEqualToOtherAdjBydate'), { type: 'error' })
              // } else if (v.adjustment.byDate.$dirty && v.adjustment.byDate.$error && !v.adjustment.byDate.moreThanStarting) {
              //   Vue.toasted.show(this.$t('message.savingErrors.costAdjustmentAfterContractStart'), { type: 'error' })
              // } else if (v.adjustment.byDate.$dirty && v.adjustment.byDate.$error && !v.adjustment.byDate.moreThanDebitUntil) {
              //   Vue.toasted.show(this.$t('message.savingErrors.debitDateAndAdjustment', { debitUntil: this.$options.filters.objectToDate(this.object.debitUntil) }), { type: 'error' })
              // } else if (v.adjustment.byDate.$dirty && v.adjustment.byDate.$error && !v.adjustment.byDate.beforeEndOfContract) {
              //   Vue.toasted.show(this.$t('message.savingErrors.byDateAfterEndContract'), { type: 'error' })
              // } else {
              //   errorMsg.push(this.$t('message.savingErrors.validationError') + this.$t('message.onBoarding.buildings.objects.tenancies.tenancy') + this.$t('message.savingErrors.validationError2'))
              // }
              valid = false
              this.onlyErrorStartingAt = false
            }
          })
        }
        if (this.$refs[`tenancy-subTenants-${this.tenancy.id}`]) {
          this.$refs[`tenancy-subTenants-${this.tenancy.id}`].forEach(element => {
            if (element.isInvalid()) {
              valid = false
              this.onlyErrorStartingAt = false
            }
          })
        }

        if (this.validDatesViaTraverseChildren(this)) {
          valid = false
          this.scrollToError()
          errorMsg.push(this.$t('message.savingErrors.invalidDate'))
        }

        if (errorMsg.length > 0) {
          this.showManyToast(errorMsg)
        }
      }

      return !valid
    },
    isCollapseOpen() {
      return this.$refs.collapse.show
    },
    dateRangeOverlaps(aStart, aEnd, bStart, bEnd) {
      if (aStart <= bStart && bStart <= aEnd) return true // b starts in a
      if (aStart <= bEnd && bEnd <= aEnd) return true // b ends in a
      if (bStart < aStart && aEnd < bEnd) return true // a in b
      return false
    },
    redirectToDetailPage(contactId) {
      if (contactId !== null) {
        if (this.isOwnerModule) {
          return {
            name: 'OwnerContactDetailsView',
            params: { id: contactId }
          }
        } else if (this.isAssetModule) {
          return {
            name: 'AssetContactDetailsView',
            params: { id: contactId }
          }
        } else if (this.isAccountingModule) {
          return {
            name: 'AccountingContactDetailsView',
            params: { id: contactId }
          }
        } else {
          return {
            name: 'AdminContactDetailsView',
            params: { id: contactId }
          }
        }
      }
    },
    deleteTerminationClicked() {
      this.$refs['delete-termination-confirmation-modal'].show()
    },
    removeClicked() {
      this.$refs['delete-tenancy-modal'].show()
    },
    async deleteTerminationConfirmed() {
      const itemToUpdate = JSON.parse(JSON.stringify(this.tenancy))

      if (itemToUpdate) {
        itemToUpdate.endingAt = null
        itemToUpdate.terminatedBy = 'TERMINATED_BY_UNDEFINED'
        itemToUpdate.terminationType = 'TERMINATION_TYPE_UNDEFINED'
        itemToUpdate.terminationReason = []
        itemToUpdate.terminatedOn = null
        itemToUpdate.terminationCreatedAt = null
        itemToUpdate.terminationSentByTenantAt = null
        itemToUpdate.desiredMoveOutAt = null
        itemToUpdate.movedOutAt = null
        itemToUpdate.liabilityEndsAt = null
        itemToUpdate.nextPossibleMoveInAt = null
        this.updateTenancy(itemToUpdate)
      }
      await this.saveOnboarding()
      if (this.$refs['delete-termination-confirmation-modal']) {
        this.$refs['delete-termination-confirmation-modal'].hide()
      }
      this.resetChanged()
      const onboardingListRoute = ['OwnerOnboardingView', 'OnboardingView', 'AdminOnboardingView', 'AssetOnboardingView', 'AccountingOnboardingView', 'OwnerBuildingDetailsView', 'AdminBuildingDetailsView', 'AssetBuildingDetailsView', 'AccountingBuildingDetailsView']
      if (!onboardingListRoute.includes(this.$route.name)) {
        if (this.isOwnerModule) {
          this.$router.push({
            name: 'OwnerBuildingDetailsView',
            params: { id: this.building.id },
            query: {
              view: this.target,
              elementSelected: this.tenancy.objectId
            }
          }, () => {
          })
        } else if (this.isAssetModule) {
          this.$router.push({
            name: 'AssetBuildingDetailsView',
            params: { id: this.building.id },
            query: {
              view: this.target,
              elementSelected: this.tenancy.objectId,
              from: ''
            }
          }, () => {
          })
        } else if (this.isAccountingModule) {
          this.$router.push({
            name: 'AccountingBuildingDetailsView',
            params: { id: this.building.id },
            query: {
              view: this.target,
              elementSelected: this.tenancy.objectId,
              from: ''
            }
          }, () => {
          })
        } else {
          this.$router.push({
            name: 'AdminBuildingDetailsView',
            params: { id: this.building.id },
            query: {
              view: this.target,
              elementSelected: this.tenancy.objectId,
              from: ''
            }
          }, () => {
          })
        }
      }
    },
    async removeConfirmed(cancelRequest = false) {
      await this.removeTenancy(this.tenancy)
      if (cancelRequest) {
        this.cancelSignRequest()
      }
      const listTenancies = this.getFilteredTenancies(this.tenancy.objectId).filter(x => x.vacancy === false).sort((a, b) => new Date(this.$options.filters.objectToDateInput(a.startingAt)).getTime() / 1000 > new Date(this.$options.filters.objectToDateInput(b.startingAt)).getTime() / 1000 ? -1 : 1)
      let itemToUpdate = null
      if (listTenancies.length > 1) {
        if (listTenancies[0].id !== this.tenancy.id) {
          itemToUpdate = listTenancies[0]
        } else {
          itemToUpdate = listTenancies[1]
        }
      } else if (listTenancies.length === 1) {
        if (listTenancies[0].id !== this.tenancy.id) {
          itemToUpdate = listTenancies[0]
        }
      }
      if (itemToUpdate) {
        itemToUpdate.endingAt = itemToUpdate.liabilityEndsAt
        this.updateTenancy(itemToUpdate)
      }
      await this.saveOnboarding()
      if (this.$refs['delete-tenancy-modal']) {
        if (Array.isArray(this.$refs['delete-tenancy-modal'])) {
          this.$refs['delete-tenancy-modal'].forEach(element => {
            element.hide()
          })
        } else {
          this.$refs['delete-tenancy-modal'].hide()
        }
      }
      this.resetChanged()
      const onboardingListRoute = ['OwnerOnboardingView', 'OnboardingView', 'AdminOnboardingView', 'AssetOnboardingView', 'AccountingOnboardingView', 'OwnerBuildingDetailsView', 'AdminBuildingDetailsView', 'AssetBuildingDetailsView', 'AccountingBuildingDetailsView']
      if (!onboardingListRoute.includes(this.$route.name)) {
        if (this.isOwnerModule) {
          this.$router.push({
            name: 'OwnerBuildingDetailsView',
            params: { id: this.building.id },
            query: {
              view: this.target,
              elementSelected: this.tenancy.objectId
            }
          }, () => {
          })
        } else if (this.isAssetModule) {
          this.$router.push({
            name: 'AssetBuildingDetailsView',
            params: { id: this.building.id },
            query: {
              view: this.target,
              elementSelected: this.tenancy.objectId,
              from: ''
            }
          }, () => {
          })
        } else if (this.isAccountingModule) {
          this.$router.push({
            name: 'AccountingBuildingDetailsView',
            params: { id: this.building.id },
            query: {
              view: this.target,
              elementSelected: this.tenancy.objectId,
              from: ''
            }
          }, () => {
          })
        } else {
          this.$router.push({
            name: 'AdminBuildingDetailsView',
            params: { id: this.building.id },
            query: {
              view: this.target,
              elementSelected: this.tenancy.objectId,
              from: ''
            }
          }, () => {
          })
        }
      }
    },
    removeActiveTenancy() {
      if (this.hasSignatureRequestIncomplete) {
        this.$refs['delete-tenancy-modal'].show()
      } else {
        this.$bvModal.show(`after-delete-tenancy-modal-${this.index}`)
      }
    },
    sameAsTenantTermination() {
      this.landlordPeriodInMonths = this.tenantPeriodInMonths
      this.dates.landlordEarliestDate = this.dates.tenantEarliestDate
      this.landlordPossibleMonths = this.tenantPossibleMonths
      this.dateToObject()
    },
    itemSelected(ids, contracts) {
      this.contractTemplateSelected = ids
      this.contractTemplate = contracts
    },
    contractsLoaded(contracts) {
      this.contractTemplate = contracts
    },
    indicesToSuggestions(indices) {
      return indices.map(({ hits }) => ({
        data: hits
      }))
    },
    selectHandler(selected) {
      if (selected && selected.item) {
        this.clearingNumber = selected.item.newIid ? selected.item.newIid : selected.item.iid
        this.bic = selected.item.bic ? selected.item.bic : ''
        this.bankName = null
        this.$nextTick(function () {
          this.bankName = selected.item.bankOrInstitutionName
        })
        this.depositZip = selected.item.zipCode
        this.depositCity = selected.item.place
        this.depositCountry = selected.item.countryCode
        this.depositStreet = selected.item.domicileAddress
      }
    },
    onChange(refine, bankAccount) {
      refine(bankAccount)
    },
    getCustomeDocumentName(doc) {
      const name = doc.name.split('.').shift()
      if (this.tenancy.vacancy === false && this.billingContactOption) {
        return name + '_' + this.getContact(this.billingContactOption.value).name + '_' + this.tenancy.objectId
      } else {
        return name + '_' + this.tenancy.objectId
      }
    },
    setDefaultValuesForLimitedContractType() {
      if (!this.dates.terminationCreatedAt) this.dates.terminationCreatedAt = this.dates.startingAt
      if (!this.dates.nextPossibleMoveInAt && new Date(this.$options.filters.objectToDateInput(this.tenancy.endingAt)).getTime() > 0) {
        this.dates.nextPossibleMoveInAt = this.$options.filters.timestampToDateInput(parseInt(new Date(this.$options.filters.objectToDateInput(this.tenancy.endingAt)).getTime() / 1000, 10) + 86400)
      }
      if (this.dates.endingAt) {
        const endDate = new Date(this.dates.endingAt).getTime()
        if (!this.dates.terminatedOn || !this.disabledBefore1914(endDate)) {
          this.dates.terminatedOn = this.dates.endingAt
        }
        if (!this.dates.movedOutAt || !this.disabledBefore1914(endDate)) {
          this.dates.movedOutAt = this.dates.endingAt
        }
        if (!this.dates.liabilityEndsAt || !this.disabledBefore1914(endDate)) {
          this.dates.liabilityEndsAt = this.dates.endingAt
        }
      }
      this.dateToObject()
      const newTenancy = this.tenancy
      if (newTenancy.terminationTenant.possibleMonths.length === 11) {
        newTenancy.terminationTenant.possibleMonths = []
        newTenancy.terminationTenant.possibleMonths.push(parseInt(this.tenancy.endingAt.month))
      }
      if (newTenancy.terminationLandlord.possibleMonths.length === 11) {
        newTenancy.terminationLandlord.possibleMonths = []
        newTenancy.terminationLandlord.possibleMonths.push(parseInt(this.tenancy.endingAt.month))
      }
      this.ignoreResetValues = true
      if (this.tenancy.contract.type === 'LIMITED') {
        if (!newTenancy.terminatedBy || newTenancy.terminatedBy === 'TERMINATED_BY_UNDEFINED') {
          newTenancy.terminatedBy = 'TERMINATED_BY_LANDLORD'
        }
        if (!newTenancy.terminationType || newTenancy.terminationType === 'TERMINATION_TYPE_UNDEFINED') {
          newTenancy.terminationType = 'TERMINATION_TYPE_TERM'
        }
        if (!newTenancy.terminationReason.length) {
          newTenancy.terminationReason = ['TERMINATION_REASON_TEMPORARY_CONTRACT']
        }
      }
      this.dispatchTenancyUpdate(newTenancy)
      this.$emit('tenancy-updated', newTenancy)
      this.$nextTick(() => {
        this.ignoreResetValues = false
      })
    },
    // loadTenancyRentComponents(properties) {
    //   if (properties && properties.length > 0) {
    //     properties.forEach(element => {
    //       element.advancePaymentRentComponents.forEach(element => {
    //         this.tenancy.rent.adjustments.forEach(adj => {
    //           if (!adj.advancePaymentValues.find(x => x.type === element.rentComponent) && element.inactive === false) {
    //             adj.advancePaymentValues.push({
    //               type: element.rentComponent,
    //               value: null
    //             })
    //           }
    //         })
    //       })
    //       element.flatRateRentComponents.forEach(element => {
    //         this.tenancy.rent.adjustments.forEach(adj => {
    //           if (!adj.flatRateValues.find(x => x.type === element.rentComponent) && element.inactive === false) {
    //             adj.flatRateValues.push({
    //               type: element.rentComponent,
    //               value: null
    //             })
    //           }
    //         })
    //       })
    //       element.additionalRentComponents.forEach(element => {
    //         this.tenancy.rent.adjustments.forEach(adj => {
    //           if (!adj.additionalValues.find(x => x.type === element.rentComponent) && element.inactive === false) {
    //             adj.additionalValues.push({
    //               type: element.rentComponent,
    //               value: null
    //             })
    //           }
    //         })
    //       })
    //     })
    //   }
    // },
    // onChangeTerminationCreatedAt() {
    //   this.dateToObject()
    //   this.calculateEndOfContract()
    //   this.dates.endingAt = this.dates.terminationCreatedAt
    //   this.dates.terminatedOn = this.dates.terminationCreatedAt
    //   this.dates.movedOutAt = this.dates.terminationCreatedAt
    //   this.dates.liabilityEndsAt = this.dates.terminationCreatedAt
    //   this.dates.nextPossibleMoveInAt = this.dates.terminationCreatedAt
    //   this.dates.terminationSentByTenantAt = this.dates.terminationCreatedAt
    // },
    isTerminationEntred() {
      if (this.tenancy.nextPossibleMoveInAt || this.tenancy.movedOutAt || this.tenancy.terminatedOn || this.tenancy.terminationReason.length > 0 || this.tenancy.terminatedBy !== 'TERMINATED_BY_UNDEFINED' || this.tenancy.terminationType !== 'TERMINATION_TYPE_UNDEFINED') {
        return true
      }
      return false
    },
    calculateEndOfContract() {
      if (!this.tenancy.terminationCreatedAt || this.tenancy.terminatedBy === 'TERMINATED_BY_UNDEFINED') {
        return 0
      }
      if (this.tenancy.endingAt !== null) {
        return 0
      }
      let nextYear = 0
      let startMonth
      let possibleMonths
        const dateObject = this.tenancy.terminationCreatedAt
        if (this.tenancy.terminatedBy === 'TERMINATED_BY_TENANT') {
          const terminateWithPeriod = new Date(dateObject.year, (dateObject.month + (this.tenantPeriodInMonths ? this.tenantPeriodInMonths : 0)), 0)
          if (this.tenantEarliestDate && new Date(this.$options.filters.objectToDateInput(this.tenantEarliestDate)).getTime() / 1000 > this.toTimestamp(terminateWithPeriod)) {
            startMonth = this.tenantEarliestDate.month
            nextYear = this.calculateDifferenceYears(this.tenantEarliestDate, this.tenancy.terminationCreatedAt)
          } else {
            startMonth = dateObject.month + (this.tenantPeriodInMonths ? this.tenantPeriodInMonths : 0)
          }
          possibleMonths = this.tenancy.terminationTenant.possibleMonths
        } else if (this.tenancy.terminatedBy === 'TERMINATED_BY_LANDLORD') {
          const terminateWithPeriod = new Date(dateObject.year, (dateObject.month + (this.landlordPeriodInMonths ? this.landlordPeriodInMonths : 0)), 0)
          if (new Date(this.$options.filters.objectToDateInput(this.landlordEarliestDate)).getTime() / 1000 > this.toTimestamp(terminateWithPeriod)) {
            startMonth = this.landlordEarliestDate && this.landlordEarliestDate.month ? this.landlordEarliestDate.month : 0
            nextYear = this.calculateDifferenceYears(this.landlordEarliestDate, this.tenancy.terminationCreatedAt)
          } else {
            startMonth = dateObject.month + (this.landlordPeriodInMonths ? this.landlordPeriodInMonths : 0)
          }
          possibleMonths = this.tenancy.terminationLandlord.possibleMonths
        }
        let possibleMonth = 0
        for (let index = 0; index < 120; index += 1) {
          if (startMonth + possibleMonth > 12) {
            possibleMonth = possibleMonth - 12
            nextYear += 1
            index -= 1
            continue
          }
          if (!possibleMonths.length) {
            break
          }
          if (possibleMonths.includes(startMonth + possibleMonth)) {
            break
          } else {
            possibleMonth += 1
          }
        }
        const firstPossibleMonth = startMonth + possibleMonth
        const calculatedDate = new Date(dateObject.year + nextYear, firstPossibleMonth, 0)
        const dateTermination = `${calculatedDate.getFullYear()}-${(calculatedDate.getMonth() + 1).toString().padStart(2, '0')}-${calculatedDate.getDate().toString().padStart(2, '0')}`
        this.dates.terminationSentByTenantAt = this.$options.filters.objectToDateInput(this.tenancy.terminationCreatedAt)
        this.dates.movedOutAt = dateTermination
        this.dates.liabilityEndsAt = dateTermination
        this.dates.endingAt = dateTermination
        if (this.dates.nextPossibleMoveInAt === null) {
          const dataToAdd = new Date(dateTermination)
          const datatodays = dataToAdd.setDate(new Date(dataToAdd).getDate() + 1)
          this.dates.nextPossibleMoveInAt = this.$options.filters.timestampToDateInput(datatodays / 1000)
        }

        this.dates.terminateAt = `${calculatedDate.getFullYear()}-${(calculatedDate.getMonth() + 1).toString().padStart(2, '0')}-${calculatedDate.getDate().toString().padStart(2, '0')}`
        this.dates.terminatedOn = this.dates.terminateAt
        calculatedDate.setDate(calculatedDate.getDate() + 1)
        this.dates.nextPossibleMoveInAt = `${calculatedDate.getFullYear()}-${(calculatedDate.getMonth() + 1).toString().padStart(2, '0')}-${calculatedDate.getDate().toString().padStart(2, '0')}`

        this.dateToObject()
    },
    toTimestamp(strDate) {
      if (strDate === null || strDate === '') {
        return ''
      }
      return Date.parse(strDate) / 1000
    },
    calculateDifferenceYears(date1, date2) {
      const difference = date1.year - date2.year
      return date1?.year && date2?.year ? difference : null
    },
    see(refine, value) {
      refine(value)
    },
    availableTenants(search) {
      ContactApi.contactResolve(search)
        .then(response => {
          const contacts = response.persons.concat(response.companies)
          const objectContact = this.objectOwnerContacts
          const usedContacts = objectContact.concat(contacts)
          this.setObjectOwnerContacts(usedContacts)
        })
        .catch(e => {
          console.log(e)
        })
    }
  },
  validations() {
    const validationTenancy = {
      numberOfPersons: {
        numeric,
        maxValue: maxValue(99)
      },
      tenantPeriodInMonths: {
        numeric,
        maxValue: maxValue(999)
      },
      rentType: {
        isRequired: (value) => !['RENT_TYPE_UNDEFINED', '', undefined, null].includes(value)
      },
      typeOfUse: {
        isRequired: (value) => !['TYPE_OF_USE_UNDEFINED', '', undefined, null].includes(value)
      },
      landlordPeriodInMonths: {
        numeric,
        maxValue: maxValue(999)
      },
      depositAmount: {
        minValue: minValue(0)
      },
      tenantPossibleMonths: {
        required
      },
      depositPaidAmount: {
        minValue: minValue(0)
      },
      startingAt: {
        required,
        lessThanEnding,
        startingMoreThanDebitUntil
      },
      endingAt: {
        moreThanStarting,
        isTerminationEntred,
        moreThanDebitUntil
      },
      tenancyStatus: {
        required
      },
      tenantEarliestDate: {
        moreThanStarting
      },
      landlordEarliestDate: {
        moreThanStarting
      },
      terminatedOn: {
        moreThanStarting
      },
      movedOutAt: {
        moreThanStarting
      },
      liabilityEndsAt: {
        moreThanStarting,
        moreThanEnding
      },
      unjustifiedUseFrom: {
        moreThanStarting
      },
      unjustifiedUseTo: {
        moreUnjustifiedUseFrom
      }
    }
    if (!(this.$parent.$route.name === 'AdminAddTenancyView' || this.$parent.$route.name === 'AccountingAddTenancyView' || this.$parent.$route.name === 'MarketingAddTenancyView' || this.$parent.$route.name === 'AdminActivateTenancy' || this.$parent.$route.name === 'AccountingActivateTenancy' || this.$parent.$route.name === 'AssetActivateTenancy' || this.$parent.$route.name === 'MarketingActivateTenancy')) {
      validationTenancy.billingContactOption = {
        isTenantsNull
      }
      validationTenancy.tenants = {
        isTenantsEmpty
      }
    }
    if (this.contractType === 'LIMITED') {
      if (!this.isFirst && this.tenancy.id) {
        validationTenancy.endingAt = {
          required,
          moreThanStarting,
          checkChanging,
          moreThanDebitUntil
        }
      } else {
        validationTenancy.endingAt = {
          required,
          moreThanStarting
        }
      }
    } else if (!this.isFirst && this.tenancy.id) {
      validationTenancy.endingAt = {
        moreThanStarting,
        moreThanDebitUntil,
        checkChanging
      }
    }
    if ((this.isShowen('depositPaidAt') && this.depositType !== 'DEPOSIT_TYPE_INSURANCE_GUARANTEE' && this.dates.depositPaidAt && this.dates.depositPaidAt !== '') || (this.isShowen('depositPaidAmount') && this.depositType === 'DEPOSIT_TYPE_INSURANCE_GUARANTEE' && this.dates.certificateReceivedAt)) {
      validationTenancy.depositPaidAmount = {
        required
      }
    }
    if (this.depositType === 'DEPOSIT_TYPE_DEPOT' && this.isBankNameRequired) {
      validationTenancy.bankName = {
        required
      }
    }
    if (this.depositType === 'DEPOSIT_TYPE_CASH_PAYMENT' && this.depositPaidAmount) {
      validationTenancy.depositPaidAt = {
        required
      }
    }
    if (['DEPOSIT_TYPE_BANK_GUARANTEE', 'DEPOSIT_TYPE_DEPOT'].includes(this.depositType) && this.bankName !== '') {
      validationTenancy.clearingNumber = {
        required
      }
    }
    if (this.mandatoryTerminationFields) {
      validationTenancy.endingAt = {
        required,
        moreThanStarting,
        moreThanDebitUntil
      }
      validationTenancy.terminatedBy = {
        sourceRequired: (value) => {
          return (value !== '' && value !== 'TERMINATED_BY_UNDEFINED')
        }
      }
      validationTenancy.terminationType = {
        sourceRequired: (value) => {
          return (value !== '' && value !== 'TERMINATION_TYPE_UNDEFINED')
        }
      }
      validationTenancy.terminationReason = {
        sourceRequired: (value) => {
          return value.length > 0
        }
      }
      validationTenancy.nextPossibleMoveInAt = {
        moreThanStarting,
        moreThanMoveOutDate
      }
      validationTenancy.terminatedOn = {
        required,
        moreThanStarting
      }
      validationTenancy.movedOutAt = {
        required,
        moreThanStarting
      }
      validationTenancy.liabilityEndsAt = {
        required,
        moreThanStarting,
        moreThanEnding
      }
    } else {
      validationTenancy.terminatedOn = {
        moreThanStarting
      }
      validationTenancy.movedOutAt = {
        moreThanStarting
      }
      validationTenancy.liabilityEndsAt = {
        moreThanStarting,
        moreThanEnding
      }
    }
    if (this.rentType === 'RENT_TYPE_INDEX' || this.rentType === 'RENT_TYPE_TURNOVER') {
      validationTenancy.firstRentAdjustmentPossibleAt = {
        moreThanStarting
      }
    }
    if (this.checkOptions) {
      validationTenancy.renewalOptions = {
        $each: {
          until: {
            validRenewalOptionUntil,
            validMoreThanContractDate,
            validMoreThanFrom
          },
          exerciseUntil: {
            validRenewalOptionExerciceUntil,
            validMoreThanContractDate
          }
        }
      }
      validationTenancy.earlyRightOfTerminationOptions = {
        $each: {
          until: {
            validEarlyRightOfTerminationOptionUntil,
            validMoreThanContractDate
          },
          exerciseUntil: {
            validEarlyRightOfTerminationOptionExerciceUntil,
            validMoreThanContractDate
          }
        }
      }
    }
    return validationTenancy
  }
}
</script>

<style lang="scss" scoped>
h5 > span {
  font-size: 18px !important;
}

:deep(.mx-datepicker) {
  width: 100%;
}

.contractAmendments {
  :deep(textarea) {
    height: calc(1.5em + 0.5rem + 2px);
  }
}

:deep(.mx-icon-calendar) {
  display: none;
}
.grey-col{
  background:  $color-table-row-2;
}
.white-col{
 background:  $color-table-row-1;
}
:deep(.modal-xl) {
    max-width: 1140px !important;
}

.tooltip-wide :deep(.tooltip-inner) {
  max-width: 800px !important;
}

.already-used {
  position: absolute;
  right: 1.5rem;
  top: 0.15rem;
}
:deep(.link-activity) {
  font-size: 18px !important;
  line-height: 1.2px !important;
  font-weight: 100;
}
</style>
