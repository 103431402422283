import { mapActions } from 'vuex'

const handover = {
  computed: {
    countHandoverProtocols() {
      return this.$store.getters['handover/getCountHandovers'] || '-'
    }
  },
  mounted() {
    this.getCountOverdueHandovers(true)
  },
  beforeDestroy() {
    clearInterval(this.getNumberInterval)
  },
  methods: {
    ...mapActions('handover', ['loadCountHandovers']),
    getCountOverdueHandovers(force = false) {
      this.$nextTick(async () => {
        this.countHandoverProtocols = await this.loadCountHandovers(force)
        })
      }
  }
}
export { handover }
