
// import network from './network'
import networkTS from './networkTS'
import { HandoverProtocolServiceUpdatePayload, ObjecthandoverProtocolCreateRequest } from '@/types/api'

export default {
  hasHandoverProtocolEnabled(accountId: string) {
    return networkTS.handoverProtocolServiceHasEnabled(accountId)
      .then((response) => {
        return response.data
      })
  },
  enableHandoverProtocol(accountId: string, enable: boolean) {
    return networkTS.handoverProtocolServiceEnable(accountId, { enable: enable })
      .then((response) => {
        return response.data
      })
  },
  handoverProtocolServiceListByObjectIds(objectIds: string[]) {
    return networkTS.handoverProtocolServiceListByObjectIds({ objectIds: objectIds })
      .then((response) => {
        return response.data
      })
  },
  handoverProtocolListByIds(ids: string[]) {
    return networkTS.handoverProtocolServiceListByIds({ ids: ids })
      .then((response) => {
        return response.data
      })
  },
  handoverProtocolServiceDelete(id: string) {
    return networkTS.handoverProtocolServiceDelete(id)
      .then((response) => {
        return response.data
      })
  },
  countMyDrafts() {
    return networkTS.handoverProtocolServiceCountMyDrafts()
      .then((response) => {
        return response.data
      })
  },
  createHandover(handoverProtocol: ObjecthandoverProtocolCreateRequest) {
    return networkTS.handoverProtocolServiceCreate(handoverProtocol)
      .then(response => {
        return response.data
      })
  },
  updateHandover(id: string, handoverProtocol: HandoverProtocolServiceUpdatePayload) {
    const body = {
      assigneeContactId: handoverProtocol.assigneeContactId,
      devicesWithoutRoom: handoverProtocol.devicesWithoutRoom,
      draft: handoverProtocol.draft,
      movingInTenancyReferences: handoverProtocol.movingInTenancyReferences,
      movingOutTenancyReferences: handoverProtocol.movingOutTenancyReferences,
      note: handoverProtocol.note,
      participants: handoverProtocol.participants,
      rooms: handoverProtocol.rooms,
      scheduledDate: handoverProtocol.scheduledDate,
      scheduledTime: handoverProtocol.scheduledTime
    }
    return networkTS.handoverProtocolServiceUpdate(id, body)
      .then(response => {
        return response.data
      })
  },
  listAllProtocols(page, itemsPerPage, statuses) {
    return networkTS.handoverProtocolServiceListAllProtocols({ page: page, itemsPerPage: itemsPerPage, statuses: statuses })
      .then((response) => {
        return response.data
      })
  },
  listMyProtocols(page, itemsPerPage, statuses) {
    return networkTS.handoverProtocolServiceListMyProtocols({ page: page, itemsPerPage: itemsPerPage, statuses: statuses })
      .then((response) => {
        return response.data
      })
  }
}
