<template>
  <b-modal
    id="contract-placeholder-modal"
    no-close-on-backdrop
    hide-footer
    :title="$t('message.generic.placeholder')">
    <div class="row">
      <div class="col-4 mb-3">
        <label for="placeholderTemplateFilter">{{ $t('message.settings.template.placeholderTemplate.category') }}</label>
        <coozzy-form-select
          id="placeholderTemplateFilter"
          v-model="categoryFilter">
          <option
            v-for="(cat, index) in translatedCategories"
            :key="index"
            :value="cat.value">
            {{ cat.label }}
          </option>
        </coozzy-form-select>
      </div>
      <div class="col-12">
        <b-table
          bordered
          hover
          :fields="fields"
          :items="placeholdersFiltered"
          @row-clicked="onRowClicked">
          <!-- Header -->
          <template #head(fieldName)>
            {{ $t('message.tableColumnHeadings.fieldName') }}
          </template>
          <template #head(description)>
            {{ $t('message.tableColumnHeadings.description') }}
          </template>
          <template #head(placeholder)>
            {{ $t('message.generic.placeholder') }}
          </template>

          <!-- Data -->
          <template #cell(fieldName)="data">
            <span class="cursor-pointer">
              {{ $t(`message.placeholders.${data.item.placeholder.replace('{', '').replace('}', '').replace('#', 'hash.').replace('/', 'slash.')}.name`) }}
            </span>
          </template>
          <template #cell(placeholder)="data">
            <span class="cursor-pointer">
              {{ data.item.placeholder }}
            </span>
          </template>
          <template #cell(description)="data">
            <!-- eslint-disable vue/no-v-html -->
            <p
              :key="data.item.placeholder"
              v-html="$t(`message.placeholders.${data.item.placeholder.replace('{', '').replace('}', '').replace('#', 'hash.').replace('/', 'slash.')}.description`)" />
            <!-- eslint-enable vue/no-v-html -->
          </template>
        </b-table>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Vue from 'vue'
import CoozzyFormSelect from '@/framework/components/form/select/CoozzyFormSelect'

export default {
  name: 'ContractPlaceholdersModal',
  components: { CoozzyFormSelect },
  data() {
    return {
      categoryFilter: '',
      fields: [
        {
          key: 'fieldName',
          label: 'fieldName',
          tdCell: 'cursor-pointer'
        },
        {
          key: 'placeholder',
          label: 'Placeholder',
          tdCell: 'cursor-pointer'
        },
        {
          key: 'description',
          label: 'Description'
        }
      ],
      placeholders: [

        {
          fieldName: 'Kontakt Briefanschrift',
          placeholder: '{ko.briefanschrift}',
          description: 'Herr <br> Max Mustermann <br> Musterstrasse 1 <br> CH-8000 Zürich, ZH'
        },
        {
          fieldName: 'Kontakt Briefanschrift 1',
          placeholder: '{ko.briefanschrift_1}',
          description: 'Herr <br> Max Mustermann <br> Musterstrasse 1 <br> 8000 Zürich'
        },
        {
          fieldName: 'Kontakt Briefanschrift 2',
          placeholder: '{ko.briefanschrift_2}',
          description: 'Herr <br> Max Mustermann <br> Musterstrasse 1 <br> CH - 8000 Zürich'
        },
        {
          fieldName: 'Kontakt - Briefanrede',
          placeholder: '{ko.brief_anrede}',
          description: 'Sehr geehrte Frau Musterfrau <br> Sehr geehrter Herr Mustermann'
        },
        {
          fieldName: 'Kontakt - Firmenname',
          placeholder: '{ko.firmenname}',
          description: 'Muster AG'
        },
        {
          fieldName: 'Kontakt - Anrede',
          placeholder: '{ko.anrede}',
          description: 'Herr / Frau / X'
        },
        {
          fieldName: 'Kontakt - Name',
          placeholder: '{ko.name}',
          description: 'Max Mustermann'
        },
        {
          fieldName: 'Kontakt - Vorname',
          placeholder: '{ko.vname}',
          description: 'Max'
        },
        {
          fieldName: 'Kontakt - Nachname',
          placeholder: '{ko.nname}',
          description: 'Mustermann'
        },
        {
          fieldName: 'Kontakt - Titel',
          placeholder: '{ko.titel}',
          description: 'Dr.'
        },
        {
          fieldName: 'Kontakt - Adresszusatz (aktuell)',
          placeholder: '{ko.az}',
          description: 'c/o Muster AG'
        },
        {
          fieldName: 'Kontakt - Strasse (aktuell)',
          placeholder: '{ko.str}',
          description: 'Musterstrasse'
        },
        {
          fieldName: 'Kontakt - Hausnummer (aktuell)',
          placeholder: '{ko.nr}',
          description: '1'
        },
        {
          fieldName: 'Kontakt - Postfach (aktuell)',
          placeholder: '{ko.pf}',
          description: 'Postfach 5'
        },
        {
          fieldName: 'Kontakt - PLZ (aktuell)',
          placeholder: '{ko.plz}',
          description: '8000'
        },
        {
          fieldName: 'Kontakt - Ort (aktuell)',
          placeholder: '{ko.ort}',
          description: 'Zürich'
        },
        {
          fieldName: 'Kontakt - Kanton (aktuell)',
          placeholder: '{ko.kanton}',
          description: 'ZH'
        },
        {
          fieldName: 'Kontakt - Land (aktuell)',
          placeholder: '{ko.land}',
          description: 'CH'
        },
        {
          fieldName: 'Kontakt - Telefon',
          placeholder: '{ko.tel}',
          description: '+41 44 512 76 76'
        },
        {
          fieldName: 'Kontakt - E-Mail',
          placeholder: '{ko.mail}',
          description: 'info@example.com'
        },
        {
          fieldName: 'Kontakt - Website',
          placeholder: '{ko.site}',
          description: 'www.mieter.ch'
        },
        {
          fieldName: 'Kontakt Mobil',
          placeholder: '{ko.mobile}',
          description: '+41 76 111 22 33'
        },
        {
          fieldName: 'Kontakt Telefon Geschäft',
          placeholder: '{ko.phone_w}',
          description: '+41 44 512 76 76'
        },
        {
          fieldName: 'Kontakt Fax',
          placeholder: '{ko.fax}',
          description: '+41 44 512 76 70'
        },
        {
          fieldName: 'Kontakt MWST-Nummer',
          placeholder: '{ko.vat}',
          description: '285.246.45'
        },
        {
          fieldName: 'Kontakt Bevorzugte Sprache',
          placeholder: '{ko.pref_lang}',
          description: 'Deutsch'
        },
        {
          fieldName: 'Kontakt Bevorzugte Kommunikation',
          placeholder: '{ko.pref_com}',
          description: 'Brief'
        },
        {
          fieldName: 'Kontakt Geburtsname',
          placeholder: '{ko.geb_name}',
          description: 'Mustermann'
        },
        {
          fieldName: 'Kontakt Privat 2',
          placeholder: '{ko.home_2}',
          description: '+41 44 512 76 76'
        },
        {
          fieldName: 'Kontakt Zivilstatus',
          placeholder: '{ko.zivil}',
          description: 'ledig'
        },
        {
          fieldName: 'Kontakt Bürgerort',
          placeholder: '{ko.citiezenship}',
          description: 'Schaffhausen, SH'
        },
        {
          fieldName: 'Kontakt Nationalität',
          placeholder: '{ko.nat}',
          description: 'Schweiz'
        },
        {
          fieldName: 'Kontakt Aufenthaltsstatus ',
          placeholder: '{ko.residence}',
          description: 'B'
        },
        {
          fieldName: 'Kontakt Gültig bis',
          placeholder: '{ko.valid_until}',
          description: '31.12.2023'
        },
        {
          fieldName: 'Kontakt Geburtsdatum',
          placeholder: '{ko.geb}',
          description: '01.05.1998'
        },
        {
          fieldName: 'Kontakt Firmenname',
          placeholder: '{ko.firmenname}',
          description: 'Mustermann Ag'
        },
        {
          fieldName: 'Kontakt Beruf',
          placeholder: '{ko.beruf}',
          description: 'Sachbearbeiter'
        },
        {
          fieldName: 'Kontakt AHV-Nummer',
          placeholder: '{ko.ahv}',
          description: '756.7875.4564.45'
        },
        {
          fieldName: 'Kontakt Geschäfts-E-Mail',
          placeholder: '{ko.business_email}',
          description: 'max@mustermann.ag'
        },
        {
          fieldName: 'Kontaktperson Schleife - Start',
          placeholder: '{#kp.kontaktperson}',
          description: ''
        },
        {
          fieldName: 'Kontaktperson - Titel',
          placeholder: '{kp.titel}',
          description: 'Dr.'
        },
        {
          fieldName: 'Kontaktperson - Anrede',
          placeholder: '{kp.anrede}',
          description: 'Herr / Frau / X'
        },
        {
          fieldName: 'Kontaktperson - Vorname',
          placeholder: '{kp.vname}',
          description: 'Max'
        },
        {
          fieldName: 'Kontaktperson - Nachname',
          placeholder: '{kp.nname}',
          description: 'Mustermann'
        },
        {
          fieldName: 'Kontaktperson - Geburtsname',
          placeholder: '{kp.geb_name}',
          description: 'Hauser'
        },
        {
          fieldName: 'Kontaktperson - Telefon Geschäft',
          placeholder: '{kp.tel_g}',
          description: '+41 44 512 76 76'
        },
        {
          fieldName: 'Kontaktperson - Telefon Mobil',
          placeholder: '{kp.mobile}',
          description: '+41 79 222 48 77'
        },
        {
          fieldName: 'Kontaktperson - E-Mail',
          placeholder: '{kp.mail}',
          description: 'max@mustermann.ch'
        },
        {
          fieldName: 'Kontaktperson - Beruf',
          placeholder: '{kp.beruf}',
          description: 'Sachbearbeiter'
        },
        {
          fieldName: 'Kontaktperson Schleife - Ende',
          placeholder: '{/kp.kontaktperson}',
          description: ''
        },
        // cat
        {
          fieldName: 'Haupt Stockwerkeigentümer Briefanschrift',
          placeholder: '{hs.briefanschrift}',
          description: 'Herr <br> Max Mustermann <br> Musterstrasse 1 <br> CH-8000 Zürich, ZH'
        },
        {
          fieldName: 'Haupt Stockwerkeigentümer Briefanschrift 1',
          placeholder: '{hs.briefanschrift_1}',
          description: 'Herr <br> Max Mustermann <br> Musterstrasse 1 <br> 8000 Zürich'
        },
        {
          fieldName: 'Haupt Stockwerkeigentümer Briefanschrift 2',
          placeholder: '{hs.briefanschrift_2}',
          description: 'Herr <br> Max Mustermann <br> Musterstrasse 1 <br> CH - 8000 Zürich'
        },
        {
          fieldName: 'Haupt Stockwerkeigentümer Briefanrede',
          placeholder: '{hs.brief_anrede}',
          description: 'Sehr geehter Herr Mustermann'
        },
        {
          fieldName: 'Haupt Stockwerkeigentümer Firmenname',
          placeholder: '{hs.firmenname}',
          description: 'Muster AG'
        },
        {
          fieldName: 'Haupt Stockwerkeigentümer Anrede',
          placeholder: '{hs.anrede}',
          description: 'Herr / Frau / X'
        },
        {
          fieldName: 'Haupt Stockwerkeigentümer Name',
          placeholder: '{hs.name}',
          description: 'Max Mustermann'
        },
        {
          fieldName: 'Haupt Stockwerkeigentümer Vorname',
          placeholder: '{hs.vname}',
          description: 'Max'
        },
        {
          fieldName: 'Haupt Stockwerkeigentümer Nachname',
          placeholder: '{hs.nname}',
          description: 'Mustermann'
        },
        {
          fieldName: 'Haupt Stockwerkeigentümer Titel',
          placeholder: '{hs.titel}',
          description: 'Dr.'
        },
        {
          fieldName: 'Haupt Stockwerkeigentümer Adresszusatz (aktuell)',
          placeholder: '{hs.az}',
          description: 'c/o Muster AG'
        },
        {
          fieldName: 'Haupt Stockwerkeigentümer Strasse (aktuell)',
          placeholder: '{hs.str}',
          description: 'Musterstrasse'
        },
        {
          fieldName: 'Haupt Stockwerkeigentümer Hausnummer (aktuell)',
          placeholder: '{hs.nr}',
          description: '1'
        },
        {
          fieldName: 'Haupt Stockwerkeigentümer Postfach (aktuell)',
          placeholder: '{hs.pf}',
          description: 'Postfach 5'
        },
        {
          fieldName: 'Haupt Stockwerkeigentümer PLZ (aktuell)',
          placeholder: '{hs.plz}',
          description: '8000'
        },
        {
          fieldName: 'Haupt Stockwerkeigentümer Ort (aktuell)',
          placeholder: '{hs.ort}',
          description: 'Zürich'
        },
        {
          fieldName: 'Haupt Stockwerkeigentümer Kanton (aktuell)',
          placeholder: '{hs.kanton}',
          description: 'ZH'
        },
        {
          fieldName: 'Haupt Stockwerkeigentümer Land (aktuell)',
          placeholder: '{hs.land}',
          description: 'CH'
        },
        {
          fieldName: 'Haupt Stockwerkeigentümer Telefon',
          placeholder: '{hs.tel}',
          description: '+41 44 512 76 76'
        },
        {
          fieldName: 'Haupt Stockwerkeigentümer E-Mail',
          placeholder: '{hs.mail}',
          description: 'info@example.ch'
        },
        {
          fieldName: 'Haupt Stockwerkeigentümer Website',
          placeholder: '{hs.site}',
          description: 'www.hauptmieter.ch'
        },
        // cat STWE
        {
          fieldName: 'Alle STWE',
          placeholder: '{stwe.alle}',
          description: 'Max Mustermann, Musterstrasse 1, 8000 Zürich, ZH <br> Maxima Mustermann, Musterstrasse 1, 8000 Zürich, ZH <br> '
        },
        {
          fieldName: 'Alle STWE Anrede',
          placeholder: '{stwe.alle_anreden}',
          description: 'Frau Mustermann <br>Herr Mustermann'
        },
        {
          fieldName: 'Alle STWE Alle Namen',
          placeholder: '{stwe.alle_namen}',
          description: 'Max Mustermann, Maxima Mustermann'
        },
        {
          fieldName: 'Alle STWE Briefanrede',
          placeholder: '{stwe.brief_anrede}',
          description: 'Sehr geehte Frau Musterfrau<br> Sehr geehrter Herr Mustermann'
        },
        {
          fieldName: 'Alle STWE - Briefanschrift',
          placeholder: '{stwe.brief_briefanschrift}',
          description: 'Herr <br> Max Mustermann <br> Musterstrasse 1 <br> CH-8000 Zürich, ZH'
        },
        {
          fieldName: 'Alle STWE - Briefanschrift 1',
          placeholder: '{stwe.brief_briefanschrift_1}',
          description: 'Herr <br> Max Mustermann <br> Musterstrasse 1 <br> 8000 Zürich'
        },
        {
          fieldName: 'Alle STWE - Briefanschrift 2',
          placeholder: '{stwe.brief_briefanschrift_2}',
          description: 'Herr <br> Max Mustermann <br> Musterstrasse 1 <br> CH - 8000 Zürich'
        },
        {
          fieldName: 'STWE Schleife - Start',
          placeholder: '{#stwe.stwe}',
          description: ''
        },
        {
          fieldName: 'STWE Schleife - Briefanschrift',
          placeholder: '{s.briefanschrift}',
          description: 'Herr <br> Max Mustermann <br> Musterstrasse 1 <br> CH-8000 Zürich, ZH'
        },
        {
          fieldName: 'STWE Schleife - Briefanschrift 1',
          placeholder: '{s.briefanschrift_1}',
          description: 'Herr <br> Max Mustermann <br> Musterstrasse 1 <br> 8000 Zürich'
        },
        {
          fieldName: 'STWE Schleife - Briefanschrift 2',
          placeholder: '{s.briefanschrift_2}',
          description: 'Herr <br> Max Mustermann <br> Musterstrasse 1 <br> CH - 8000 Zürich'
        },
        {
          fieldName: 'STWE Schleife - Firmenname',
          placeholder: '{s.firmenname}',
          description: 'Muster AG'
        },
        {
          fieldName: 'STWE Schleife - Anrede',
          placeholder: '{s.anrede}',
          description: 'Herr / Frau / X'
        },
        {
          fieldName: 'STWE Schleife - Name',
          placeholder: '{s.name}',
          description: 'Max Mustermann'
        },
        {
          fieldName: 'STWE Schleife - Vorname',
          placeholder: '{s.vname}',
          description: 'Max'
        },
        {
          fieldName: 'STWE Schleife - Nachname',
          placeholder: '{s.nname}',
          description: 'Mustermann'
        },
        {
          fieldName: 'STWE Schleife - Titel',
          placeholder: '{s.titel}',
          description: 'Dr.'
        },
        {
          fieldName: 'STWE Schleife - Adresszusatz (aktuell)',
          placeholder: '{s.az}',
          description: 'c/o Muster AG'
        },
        {
          fieldName: 'STWE Schleife - Strasse (aktuell)',
          placeholder: '{s.str}',
          description: 'Musterstrasse'
        },
        {
          fieldName: 'STWE Schleife - Hausnummer (aktuell)',
          placeholder: '{s.nr}',
          description: '1'
        },
        {
          fieldName: 'STWE Schleife - Postfach (aktuell)',
          placeholder: '{s.pf}',
          description: 'Postfach 5'
        },
        {
          fieldName: 'STWE Schleife - PLZ (aktuell)',
          placeholder: '{s.plz}',
          description: '8000'
        },
        {
          fieldName: 'STWE Schleife - Ort (aktuell)',
          placeholder: '{s.ort}',
          description: 'Zürich'
        },
        {
          fieldName: 'STWE Schleife - Kanton (aktuell)',
          placeholder: '{s.kanton}',
          description: 'ZH'
        },
        {
          fieldName: 'STWE Schleife - Land (aktuell)',
          placeholder: '{s.land}',
          description: 'CH'
        },
        {
          fieldName: 'STWE Schleife - Telefon',
          placeholder: '{s.tel}',
          description: '+41 44 512 76 76'
        },
        {
          fieldName: 'STWE Schleife - E-Mail',
          placeholder: '{s.mail}',
          description: 'info@example.com'
        },
        {
          fieldName: 'STWE Schleife - Website',
          placeholder: '{s.site}',
          description: 'www.mieter.ch'
        },
        {
          fieldName: 'STWE Schleife - Ende',
          placeholder: '{/stwe.stwe}',
          description: ''
        },
        {
          fieldName: 'Korrespondenzadresse',
          placeholder: '{stwe.korrespondenz}',
          description: 'Max Mustermann, Musterstrasse 1, 8000 Zürich, ZH'
        },
        {
          fieldName: 'Stockwerkeigentum Anzahl Personen',
          placeholder: '{stwe.anz.pers}',
          description: '2'
        },
        {
          fieldName: 'Eigentumsstart',
          placeholder: '{stwe.es}',
          description: '01.01.2022'
        },
        {
          fieldName: 'Eigentumsende',
          placeholder: '{stwe.ee}',
          description: '31.12.2022'
        },
        {
          fieldName: 'Vertragsnummer',
          placeholder: '{stwe.vertrag}',
          description: '4785'
        },
        {
          fieldName: 'Vertragszusätze',
          placeholder: '{stwe.zusatz}',
          description: 'Haustiere'
        },
        {
          fieldName: 'Eigentümer Firmenname',
          placeholder: '{e.name}',
          description: 'Eigentümer AG'
        },
        {
          fieldName: 'Eigentümer Briefanschrift',
          placeholder: '{e.briefanschrift}',
          description: 'Eigentümer AG <br> c/o Max Mustermann <br> Musterstrasse 1 <br> CH-8000 Zürich, ZH'
        },
        {
          fieldName: 'Eigentümer Briefanschrift 1',
          placeholder: '{e.briefanschrift_1}',
          description: 'Eigentümer AG <br> c/o Max Mustermann <br> Musterstrasse 1 <br> 8000 Zürich'
        },
        {
          fieldName: 'Eigentümer Briefanschrift 2',
          placeholder: '{e.briefanschrift_2}',
          description: 'Eigentümer AG <br> c/o Max Mustermann <br> Musterstrasse 1 <br> CH - 8000 Zürich'
        },
        {
          fieldName: 'Eigentümer Adresszusatz',
          placeholder: '{e.az}',
          description: 'c/o Max Mustermann'
        },
        {
          fieldName: 'Eigentümer Strasse',
          placeholder: '{e.str}',
          description: 'Musterstrasse'
        },
        {
          fieldName: 'Eigentümer Hausnummer',
          placeholder: '{e.nr}',
          description: '1'
        },
        {
          fieldName: 'Eigentümer PLZ',
          placeholder: '{e.plz}',
          description: '8000'
        },
        {
          fieldName: 'Eigentümer Ort',
          placeholder: '{e.ort}',
          description: 'Zürich'
        },
        {
          fieldName: 'Eigentümer Kanton',
          placeholder: '{e.kanton}',
          description: 'ZH'
        },
        {
          fieldName: 'Eigentümer Telefon',
          placeholder: '{e.tel}',
          description: '044 444 44 44'
        },
        {
          fieldName: 'Eigentümer E-Mail',
          placeholder: '{e.mail}',
          description: 'max.mustermann@muster.ch'
        },
        {
          fieldName: 'Eigentümer Website',
          placeholder: '{e.site}',
          description: 'www.eigentuemer.ch'
        },
        {
          fieldName: 'MWST-Nummer',
          placeholder: '{e.che}',
          description: 'CHE-123.456. 789 MWST.'
        },
        {
          fieldName: 'Eigentümer Vorname',
          placeholder: '{e.vname}',
          description: 'Max'
        },
        {
          fieldName: 'Eigentümer Nachname',
          placeholder: '{e.nname}',
          description: 'Mustermann'
        },
        {
          fieldName: 'Asset Manager Firmenname',
          placeholder: '{am.name}',
          description: 'Asset Manager AG '
        },
        {
          fieldName: 'Asset Manager Briefanschrift',
          placeholder: '{am.briefanschrift}',
          description: 'Asset Manager AG  <br> c/o Max Mustermann <br> Musterstrasse 1 <br> CH-8000 Zürich, ZH'
        },
        {
          fieldName: 'Asset Manager Briefanschrift 1',
          placeholder: '{am.briefanschrift_1}',
          description: 'Asset Manager AG <br> c/o Max Mustermann <br> Musterstrasse 1 <br> 8000 Zürich'
        },
        {
          fieldName: 'Asset Manager Briefanschrift 2',
          placeholder: '{am.briefanschrift_2}',
          description: 'Asset Manager AG <br> c/o Max Mustermann <br> Musterstrasse 1 <br> CH - 8000 Zürich'
        },
        {
          fieldName: 'Asset Manager Adresszusatz',
          placeholder: '{am.az}',
          description: 'c/o Max Mustermann'
        },
        {
          fieldName: 'Asset Manager Strasse',
          placeholder: '{am.str}',
          description: 'Musterstrasse'
        },
        {
          fieldName: 'Asset Manager Hausnummer',
          placeholder: '{am.nr}',
          description: '1'
        },
        {
          fieldName: 'Asset Manager PLZ',
          placeholder: '{am.plz}',
          description: '8000'
        },
        {
          fieldName: 'Asset Manager Ort',
          placeholder: '{am.ort}',
          description: 'Zürich'
        },
        {
          fieldName: 'Asset Manager Kanton',
          placeholder: '{am.kanton}',
          description: 'ZH'
        },
        {
          fieldName: 'Asset Manager Telefon',
          placeholder: '{am.tel}',
          description: '044 444 44 44'
        },
        {
          fieldName: 'Asset Manager E-Mail',
          placeholder: '{am.mail}',
          description: 'max.mustermann@muster.ch'
        },
        {
          fieldName: 'Asset Manager Website',
          placeholder: '{am.site}',
          description: 'www.assetmanager.ch'
        },
        {
          fieldName: 'MWST-Nummer',
          placeholder: '{am.che}',
          description: 'CHE-123.456. 789 MWST.'
        },
        {
          fieldName: 'Asset Manager Ansprechpartner Name',
          placeholder: '{am.ap.name}',
          description: 'Max Mustermann'
        },
        {
          fieldName: 'Asset Manager Ansprechpartner Telefon',
          placeholder: '{am.ap.tel}',
          description: '044 444 44 44'
        },
        {
          fieldName: 'Asset Manager Ansprechpartner E-Mail',
          placeholder: '{am.ap.mail}',
          description: 'max.mustermann@muster.ch'
        },
        {
          fieldName: 'Asset Manager Ansprechpartner Position',
          placeholder: '{am.ap.pos}',
          description: 'Asset Manager'
        },
        {
          fieldName: 'Bewirtschaftung Firmenname',
          placeholder: '{bw.name}',
          description: 'Bewirtschafter AG'
        },
        {
          fieldName: 'Bewirtschaftung Briefanschrift',
          placeholder: '{bw.briefanschrift}',
          description: 'Bewirtschafter AG  <br> c/o Max Mustermann <br> Musterstrasse 1 <br> CH-8000 Zürich, ZH'
        },
        {
          fieldName: 'Bewirtschaftung Briefanschrift 1',
          placeholder: '{bw.briefanschrift_1}',
          description: 'Bewirtschafter AG <br> c/o Max Mustermann <br> Musterstrasse 1 <br> 8000 Zürich'
        },
        {
          fieldName: 'Bewirtschaftung Briefanschrift 2',
          placeholder: '{bw.briefanschrift_2}',
          description: 'Bewirtschafter AG <br> c/o Max Mustermann <br> Musterstrasse 1 <br> CH - 8000 Zürich'
        },
        {
          fieldName: 'Bewirtschaftung Adresszusatz',
          placeholder: '{bw.az}',
          description: 'c/o Max Mustermann'
        },
        {
          fieldName: 'Bewirtschaftung Strasse',
          placeholder: '{bw.str}',
          description: 'Musterstrasse'
        },
        {
          fieldName: 'Bewirtschaftung Hausnummer',
          placeholder: '{bw.nr}',
          description: '1'
        },
        {
          fieldName: 'Bewirtschaftung PLZ',
          placeholder: '{bw.plz}',
          description: '8000'
        },
        {
          fieldName: 'Bewirtschaftung Ort',
          placeholder: '{bw.ort}',
          description: 'Zürich'
        },
        {
          fieldName: 'Bewirtschaftung Kanton',
          placeholder: '{bw.kanton}',
          description: 'ZH'
        },
        {
          fieldName: 'Bewirtschaftung Telefon',
          placeholder: '{bw.tel}',
          description: '044 444 44 44'
        },
        {
          fieldName: 'Bewirtschaftung E-Mail',
          placeholder: '{bw.mail}',
          description: 'max.mustermann@muster.ch'
        },
        {
          fieldName: 'Bewirtschaftung Website',
          placeholder: '{bw.site}',
          description: 'www.bewirtschafter.ch'
        },
        {
          fieldName: 'MWST-Nummer',
          placeholder: '{bw.che}',
          description: 'CHE-123.456. 789 MWST.'
        },
        {
          fieldName: 'Bewirtschaftung Ansprechpartner Name',
          placeholder: '{bw.ap.name}',
          description: 'Max Mustermann'
        },
        {
          fieldName: 'Bewirtschaftung Ansprechpartner Telefon',
          placeholder: '{bw.ap.tel}',
          description: '044 444 44 44'
        },
        {
          fieldName: 'Bewirtschaftung Ansprechpartner E-Mail',
          placeholder: '{bw.ap.mail}',
          description: 'max.mustermann@muster.ch'
        },
        {
          fieldName: 'Bewirtschaftung Ansprechpartner Position',
          placeholder: '{bw.ap.pos}',
          description: 'Bewirtschafter'
        },
        {
          fieldName: 'Vermarkter Firmenname',
          placeholder: '{verm.name}',
          description: 'Vermarkter AG'
        },
        {
          fieldName: 'Vermarkter Briefanschrift',
          placeholder: '{verm.briefanschrift}',
          description: 'Vermarkter AG  <br> c/o Max Mustermann <br> Musterstrasse 1 <br> CH-8000 Zürich, ZH'
        },
        {
          fieldName: 'Vermarkter Briefanschrift 1',
          placeholder: '{verm.briefanschrift_1}',
          description: 'Vermarkter AG <br> c/o Max Mustermann <br> Musterstrasse 1 <br> 8000 Zürich'
        },
        {
          fieldName: 'Vermarkter Briefanschrift 2',
          placeholder: '{verm.briefanschrift_2}',
          description: 'Vermarkter AG <br> c/o Max Mustermann <br> Musterstrasse 1 <br> CH - 8000 Zürich'
        },
        {
          fieldName: 'Vermarkter Adresszusatz',
          placeholder: '{verm.az}',
          description: 'c/o Max Mustermann'
        },
        {
          fieldName: 'Vermarkter Strasse',
          placeholder: '{verm.str}',
          description: 'Musterstrasse'
        },
        {
          fieldName: 'Vermarkter Hausnummer',
          placeholder: '{verm.nr}',
          description: '1'
        },
        {
          fieldName: 'Vermarkter PLZ',
          placeholder: '{verm.plz}',
          description: '8000'
        },
        {
          fieldName: 'Vermarkter Ort',
          placeholder: '{verm.ort}',
          description: 'Zürich'
        },
        {
          fieldName: 'Vermarkter Kanton',
          placeholder: '{verm.kanton}',
          description: 'ZH'
        },
        {
          fieldName: 'Vermarkter Telefon',
          placeholder: '{verm.tel}',
          description: '044 444 44 44'
        },
        {
          fieldName: 'Vermarkter E-Mail',
          placeholder: '{verm.mail}',
          description: 'max.mustermann@muster.ch'
        },
        {
          fieldName: 'Vermarkter Website',
          placeholder: '{verm.site}',
          description: 'www.vermarkter.ch'
        },
        {
          fieldName: 'MWST-Nummer',
          placeholder: '{verm.che}',
          description: 'CHE-123.456. 789 MWST.'
        },
        {
          fieldName: 'Vermarkter Ansprechpartner Name',
          placeholder: '{verm.ap.name}',
          description: 'Max Mustermann'
        },
        {
          fieldName: 'Vermarkter Ansprechpartner Telefon',
          placeholder: '{verm.ap.tel}',
          description: '044 444 44 44'
        },
        {
          fieldName: 'Vermarkter Ansprechpartner E-Mail',
          placeholder: '{verm.ap.mail}',
          description: 'max.mustermann@muster.ch'
        },
        {
          fieldName: 'Vermarkter Ansprechpartner Position',
          placeholder: '{verm.ap.pos}',
          description: 'Vermarkter'
        },
        {
          fieldName: 'Hauptmieter Briefanschrift',
          placeholder: '{hm.briefanschrift}',
          description: 'Herr <br> Max Mustermann <br> Musterstrasse 1 <br> CH-8000 Zürich, ZH'
        },
        {
          fieldName: 'Hauptmieter Briefanschrift 1',
          placeholder: '{hm.briefanschrift_1}',
          description: 'Herr <br> Max Mustermann <br> Musterstrasse 1 <br> 8000 Zürich'
        },
        {
          fieldName: 'Hauptmieter Briefanschrift 2',
          placeholder: '{hm.briefanschrift_2}',
          description: 'Herr <br> Max Mustermann <br> Musterstrasse 1 <br> CH - 8000 Zürich'
        },
        {
          fieldName: 'Hauptmieter Briefanrede',
          placeholder: '{hm.brief_anrede}',
          description: 'Sehr geehter Herr Mustermann'
        },
        {
          fieldName: 'Hauptmieter Firmenname',
          placeholder: '{hm.firmenname}',
          description: 'Muster AG'
        },
        {
          fieldName: 'Hauptmieter Anrede',
          placeholder: '{hm.anrede}',
          description: 'Herr / Frau / X'
        },
        {
          fieldName: 'Hauptmieter Name',
          placeholder: '{hm.name}',
          description: 'Max Mustermann'
        },
        {
          fieldName: 'Hauptmieter Vorname',
          placeholder: '{hm.vname}',
          description: 'Max'
        },
        {
          fieldName: 'Hauptmieter Nachname',
          placeholder: '{hm.nname}',
          description: 'Mustermann'
        },
        {
          fieldName: 'Hauptmieter Titel',
          placeholder: '{hm.titel}',
          description: 'Dr.'
        },
        {
          fieldName: 'Hauptmieter Adresszusatz (aktuell)',
          placeholder: '{hm.az}',
          description: 'c/o Muster AG'
        },
        {
          fieldName: 'Hauptmieter Strasse (aktuell)',
          placeholder: '{hm.str}',
          description: 'Musterstrasse'
        },
        {
          fieldName: 'Hauptmieter Hausnummer (aktuell)',
          placeholder: '{hm.nr}',
          description: '1'
        },
        {
          fieldName: 'Hauptmieter Postfach (aktuell)',
          placeholder: '{hm.pf}',
          description: 'Postfach 5'
        },
        {
          fieldName: 'Hauptmieter PLZ (aktuell)',
          placeholder: '{hm.plz}',
          description: '8000'
        },
        {
          fieldName: 'Hauptmieter Ort (aktuell)',
          placeholder: '{hm.ort}',
          description: 'Zürich'
        },
        {
          fieldName: 'Hauptmieter Kanton (aktuell)',
          placeholder: '{hm.kanton}',
          description: 'ZH'
        },
        {
          fieldName: 'Hauptmieter Land (aktuell)',
          placeholder: '{hm.land}',
          description: 'CH'
        },
        {
          fieldName: 'Hauptmieter Telefon',
          placeholder: '{hm.tel}',
          description: '+41 44 512 76 76'
        },
        {
          fieldName: 'Hauptmieter E-Mail',
          placeholder: '{hm.mail}',
          description: 'info@example.ch'
        },
        {
          fieldName: 'Hauptmieter Website',
          placeholder: '{hm.site}',
          description: 'www.hauptmieter.ch'
        },
        {
          fieldName: 'Alle Mieter',
          placeholder: '{mieter.alle}',
          description: 'Max Mustermann, Musterstrasse 1, 8000 Zürich, CH <br> Maxima Mustermann, Musterstrasse 1, 8000 Zürich, CH <br> '
        },
        {
          fieldName: 'Alle Mieter',
          placeholder: '{mieter.alle_1}',
          description: 'Max Mustermann, Musterstrasse 1, 8000 Zürich <br> Maxima Mustermann, Musterstrasse 1, 8000 Zürich <br>'
        },
        {
          fieldName: 'Alle Mieter Anrede',
          placeholder: '{mieter.alle_anreden}',
          description: 'Frau Mustermann <br>Herr Mustermann'
        },
        {
          fieldName: 'Alle Mieternamen',
          placeholder: '{mieter.alle_namen}',
          description: 'Max Mustermann, Maxima Mustermann <br> '
        },
        {
          fieldName: 'Alle Mieter Briefanrede',
          placeholder: '{mieter.brief_anrede}',
          description: 'Sehr geehte Frau Musterfrau<br> Sehr geehrter Herr Mustermann'
        },
        {
          fieldName: 'Alle Mieter - Briefanschrift',
          placeholder: '{mieter.brief_briefanschrift}',
          description: 'Herr <br> Max Mustermann <br> Musterstrasse 1 <br> CH-8000 Zürich, ZH'
        },
        {
          fieldName: 'Alle Mieter - Briefanschrift 1',
          placeholder: '{mieter.brief_briefanschrift_1}',
          description: 'Herr <br> Max Mustermann <br> Musterstrasse 1 <br> 8000 Zürich'
        },
        {
          fieldName: 'Alle Mieter - Briefanschrift 2',
          placeholder: '{mieter.brief_briefanschrift_2}',
          description: 'Herr <br> Max Mustermann <br> Musterstrasse 1 <br> CH - 8000 Zürich'
        },
        {
          fieldName: 'Mieter Schleife - Start',
          placeholder: '{#mieter.mieter}',
          description: ''
        },
        {
          fieldName: 'Mieter Schleife - Briefanschrift',
          placeholder: '{m.briefanschrift}',
          description: 'Herr <br> Max Mustermann <br> Musterstrasse 1 <br> CH-8000 Zürich, ZH'
        },
        {
          fieldName: 'Mieter Schleife - Briefanschrift 1',
          placeholder: '{m.briefanschrift_1}',
          description: 'Herr <br> Max Mustermann <br> Musterstrasse 1 <br> 8000 Zürich'
        },
        {
          fieldName: 'Mieter Schleife - Briefanschrift 2',
          placeholder: '{m.briefanschrift_2}',
          description: 'Herr <br> Max Mustermann <br> Musterstrasse 1 <br> CH - 8000 Zürich'
        },
        {
          fieldName: 'Mieter Schleife - Firmenname',
          placeholder: '{m.firmenname}',
          description: 'Muster AG'
        },
        {
          fieldName: 'Mieter Schleife - Anrede',
          placeholder: '{m.anrede}',
          description: 'Herr / Frau / X'
        },
        {
          fieldName: 'Mieter Schleife - Name',
          placeholder: '{m.name}',
          description: 'Max Mustermann'
        },
        {
          fieldName: 'Mieter Schleife - Vorname',
          placeholder: '{m.vname}',
          description: 'Max'
        },
        {
          fieldName: 'Mieter Schleife - Nachname',
          placeholder: '{m.nname}',
          description: 'Mustermann'
        },
        {
          fieldName: 'Mieter Schleife - Geburtstag',
          category: 'tenancy',
          placeholder: '{m.geb}',
          description: '01.01.1990'
        },
        {
          fieldName: 'Mieter Schleife - Titel',
          placeholder: '{m.titel}',
          description: 'Dr.'
        },
        {
          fieldName: 'Mieter Schleife - Adresszusatz (aktuell)',
          placeholder: '{m.az}',
          description: 'c/o Muster AG'
        },
        {
          fieldName: 'Mieter Schleife - Strasse (aktuell)',
          placeholder: '{m.str}',
          description: 'Musterstrasse'
        },
        {
          fieldName: 'Mieter Schleife - Hausnummer (aktuell)',
          placeholder: '{m.nr}',
          description: '1'
        },
        {
          fieldName: 'Mieter Schleife - Postfach (aktuell)',
          placeholder: '{m.pf}',
          description: 'Postfach 5'
        },
        {
          fieldName: 'Mieter Schleife - PLZ (aktuell)',
          placeholder: '{m.plz}',
          description: '8000'
        },
        {
          fieldName: 'Mieter Schleife - Ort (aktuell)',
          placeholder: '{m.ort}',
          description: 'Zürich'
        },
        {
          fieldName: 'Mieter Schleife - Kanton (aktuell)',
          placeholder: '{m.kanton}',
          description: 'ZH'
        },
        {
          fieldName: 'Mieter Schleife - Land (aktuell)',
          placeholder: '{m.land}',
          description: 'CH'
        },
        {
          fieldName: 'Mieter Schleife - Telefon',
          placeholder: '{m.tel}',
          description: '+41 44 512 76 76'
        },
        {
          fieldName: 'Mieter Schleife - E-Mail',
          placeholder: '{m.mail}',
          description: 'info@example.com'
        },
        {
          fieldName: 'Mieter Schleife - Website',
          placeholder: '{m.site}',
          description: 'www.mieter.ch'
        },
        {
          fieldName: 'MWST-Nummer',
          placeholder: '{m.che}',
          description: 'CHE-123.456. 789 MWST.'
        },
        {
          fieldName: 'Mieter Schleife - Telefon Geschäft',
          placeholder: '{m.work}',
          description: '+41 44 512 76 76'
        },
        {
          fieldName: 'Mieter Schleife - Nationalität',
          placeholder: '{m.nat}',
          description: 'CH'
        },
        {
          fieldName: 'Mieter Schleife - Bevorzugte Sprache',
          placeholder: '{m.pref_language}',
          description: 'Deutsch'
        },
        {
          fieldName: ' Mieter Schleife - Telefon Geschäft',
          placeholder: '{m.tel_g}',
          description: '+41 44 512 76 76'
        },
        {
          fieldName: 'Mieter Schleife - Mobil',
          placeholder: '{m.mobil}',
          description: '+41 76 123 45 67'
        },
        {
          fieldName: 'Mieter Schleife - Zivilstand',
          placeholder: '{m.zivil}',
          description: 'ledig'
        },
        {
          fieldName: 'Mieter Schleife - Bürgerort',
          placeholder: '{m.bgo}',
          description: 'Schaffhausen'
        },
        {
          fieldName: 'Mieter Schleife - Ende',
          placeholder: '{/mieter.mieter}',
          description: ''
        },
        {
          fieldName: 'Vermietung Schleife - Start',
          placeholder: '{#vm_loop}',
          description: '',
          category: 'tenancy'
        },
        {
          fieldName: 'Vermietung Schleife - Ende',
          placeholder: '{/vm_loop}',
          description: '',
          category: 'tenancy'
        },
        {
          fieldName: 'Korrespondenzadresse',
          placeholder: '{vm.korrespondenz}',
          description: 'Max Mustermann, Musterstrasse 1, 8000 Zürich, ZH'
        },
        {
          fieldName: 'Korrespondenz- Briefanschrift',
          placeholder: '{vm.korrespondenz_briefanschrift}',
          description: 'Herr Max Mustermann Musterstrasse 1 8000 Zürich'
        },
        {
          fieldName: 'Korrespondenz- Briefanrede',
          placeholder: '{vm.korrespondenz_briefanrede}',
          description: 'Sehr geehrter Herr Mustermann'
        },
        {
          fieldName: 'Alle Solidarhafter',
          placeholder: '{vm.alle_solidarhafter}',
          description: 'Max Mustermann, Musterstrasse 1, 8000 Zürich, ZH'
        },
        {
          fieldName: 'Alle Solidarhafternamen',
          placeholder: '{sol.alle_namen}',
          description: 'Max Mustermann, Maxima Mustermann',
          category: 'tenancy'
        },
        {
          fieldName: 'Solidarhafter Schleife - Start',
          placeholder: '{#sol.haft}',
          description: '',
          category: 'tenancy'
        },
        {
          fieldName: 'Solidarhafter Schleife - Name',
          placeholder: '{sol.name}',
          description: 'Max Mustermann',
          category: 'tenancy'
        },
        {
          fieldName: 'Solidarhafter - Briefanschrift',
          placeholder: '{sol.briefanschrift}',
          description: 'Herr Max Mustermann Musterstrasse 1 8000 Zürich',
          category: 'tenancy'
        },
        {
          fieldName: 'Solidarhafter - Briefanrede',
          placeholder: '{sol.briefanrede}',
          description: 'Sehr geehrter Herr Mustermann',
          category: 'tenancy'
        },
        {
          fieldName: 'Solidarhafter Schleife - Ende',
          placeholder: '{/sol.haft}',
          description: '',
          category: 'tenancy'
        },
        {
          fieldName: 'Familienwohnung (Ja/Nein)',
          placeholder: '{vm.fam}',
          description: 'Ja'
        },
        {
          fieldName: 'Optiert (Ja/Nein)',
          placeholder: '{vm.opt}',
          description: 'Ja'
        },
        {
          fieldName: 'Steuerbar (Ja/Nein)',
          placeholder: '{vm.steuerbar}',
          description: 'Ja'
        },
        {
          fieldName: 'Vermietung Anzahl Personen',
          placeholder: '{vm.anz_pers}',
          description: '2'
        },
        {
          fieldName: 'Vermietung Vertragsdatum',
          placeholder: '{vm.vdatum}',
          description: '17.12.2020'
        },
        {
          fieldName: 'Vermietung Mietbeginn',
          placeholder: '{vm.mietbeginn}',
          description: '01.01.2021'
        },
        {
          fieldName: 'Vermietung Vertragsende',
          placeholder: '{vm.ve}',
          description: '31.12.2021'
        },
        {
          fieldName: 'Vermietung Vertragstyp',
          placeholder: '{vm.vt}',
          description: 'unbefristet'
        },
        {
          fieldName: 'Vermietung Vertragsart',
          placeholder: '{vm.vta}',
          description: 'VMWG'
        },
        {
          fieldName: 'Vermietung Nutzungsart',
          placeholder: '{vm.nutzung}',
          description: 'wohnen'
        },
        {
          fieldName: 'Vermietung Verwendungszweck',
          placeholder: '{vm.vz}',
          description: 'Büroraum',
          category: 'tenancy'
        },
        {
          fieldName: 'Vermietung Vertragsnummer',
          placeholder: '{vm.vertrag}',
          description: '1010'
        },
        {
          fieldName: 'Vermietung Monatsmiete zahlbar bis',
          placeholder: '{vm.zuzahlen_bis}',
          description: '1'
        },
        {
          fieldName: 'Vermietung Vertagszusätze',
          placeholder: '{vm.zusatz}',
          description: 'Haustiere'
        },
        {
          fieldName: 'Vermietung Kündigungsdatum',
          placeholder: '{vm.kd}',
          description: '21.10.2021'
        },
        {
          fieldName: 'Vermietung gekündigt auf',
          placeholder: '{vm.gek_auf}',
          description: '31.12.2021'
        },
        {
          fieldName: 'Vermietung Auszugsdatum',
          placeholder: '{vm.auszug}',
          description: '31.12.2021'
        },
        {
          fieldName: 'Vermietung Kündigung des Mieter gesendet am',
          placeholder: '{vm.kdm}',
          description: '20.10.2021'
        },
        {
          fieldName: 'Vermietung Haftungsende',
          placeholder: '{vm.haft}',
          description: '31.12.2021'
        },
        {
          fieldName: 'Vermietung Mögl. Bezug Nachmieter',
          placeholder: '{vm.moegl_nm}',
          description: '01.01.2022'
        },
        {
          fieldName: 'Vermietung Kündigungsart',
          placeholder: '{vm.ka}',
          description: 'Ordentlich'
        },
        {
          fieldName: 'Vermietung Kündigungsgrund',
          placeholder: '{vm.kg}',
          description: 'Eigenbedarf'
        },
        {
          fieldName: 'Vermietung Kündigung Begründung',
          placeholder: '{vm.kuend_begruendung}',
          description: 'Begründung'
        },
        {
          fieldName: 'Vermietung Kündigungsfrist Mieter',
          placeholder: '{vm.kf}',
          description: '3'
        },
        {
          fieldName: 'Vermietung Kündigungsfrist Vermieter',
          placeholder: '{vm.kfv}',
          description: '3'
        },
        {
          fieldName: 'Vermietung erstmals kündbar Mieter',
          placeholder: '{vm.erst_kb}',
          description: '31.12.2021'
        },
        {
          fieldName: 'Vermietung erstmals kündbar Vermieter',
          placeholder: '{vm.erst_kbv}',
          description: '31.12.2021'
        },
        {
          fieldName: 'Vermietung Kündigungsmonate Mieter',
          placeholder: '{vm.kuend_mnt}',
          description: 'Ende jeden Monats, ausser 31.12'
        },
        {
          fieldName: 'Vermietung Kündigungsmonate Vermieter',
          placeholder: '{vm.kuendv_mnt}',
          description: 'Ende jeden Monats, ausser 31.12'
        },
        {
          fieldName: 'Vermietung keine Kündigungsmonate Mieter',
          placeholder: '{vm.kuend_mnt_ausser}',
          description: '31.12'
        },
        {
          fieldName: 'Vermietung keine Kündigungsmonate Vermieter',
          placeholder: '{vm.kuendv_mnt_ausser}',
          description: '31.12'
        },
        {
          fieldName: 'Vermietung Netto',
          placeholder: '{vm.netto}',
          description: '1000'
        },
        {
          fieldName: 'Vermietung Berechnungsgrundlage',
          placeholder: '{vm.bg}',
          description: 'Standard'
        },
        {
          fieldName: 'Vermietung Netto m2/Jahr',
          placeholder: '{vm.netto_m2}',
          description: '1000'
        },
        {
          fieldName: 'Vermietung Nebenkosten',
          placeholder: '{vm.nk}',
          description: '100'
        },
        {
          fieldName: 'Vermietung Heizkosten',
          placeholder: '{vm.hk}',
          description: '100'
        },
        {
          fieldName: 'Vermietung Heiz- und Nebenkosten',
          placeholder: '{vm.hk_nk}',
          description: '200'
        },
        {
          fieldName: 'Vermietung Akonto',
          placeholder: '{vm.akonto}',
          description: '100'
        },
        {
          fieldName: 'Vermietung Pauschal',
          placeholder: '{vm.pauschal}',
          description: '100'
        },
        {
          fieldName: 'Vermietung MWST 8.1%',
          placeholder: '{vm.mwst}',
          description: '8.10'
        },
        {
          fieldName: 'Vermietung Brutto',
          placeholder: '{vm.brutto}',
          description: '1200'
        },
        {
          fieldName: 'Vermietung Brutto inkl. MWST 8.1%',
          placeholder: '{vm.brutto_mwst}',
          description: '108.10'
        },
        {
          fieldName: 'Vermietung Fälligkeiten',
          placeholder: '{vm.faelligkeiten}',
          description: 'Jan, Feb, Mrz, Arp, Mai, Jun, Jul, Aug, Sep, Okt, Nov, Dez'
        },
        {
          fieldName: 'Alle Mietzinskomponenten Schleife - Früheres Mietverhältnis wert',
          placeholder: '{fvm}',
          description: '100'
        },
        {
          fieldName: 'Vermietung Anzahl Fälligkeiten pro Jahr',
          placeholder: '{vm.faellig_anzahl}',
          description: '12'
        },
        {
          fieldName: 'Zweitwohnsitz (Ja/Nein)',
          placeholder: '{vm.zweitwohnsitz}',
          description: 'Ja'
        },
        {
          fieldName: 'Kautionstyp',
          placeholder: '{kaution.typ}',
          description: 'Depot'
        },
        {
          fieldName: 'Kaution Betrag',
          placeholder: '{kaution.betrag}',
          description: '5000'
        },
        {
          fieldName: 'Kaution zu zahlen bis',
          placeholder: '{kaution.zuzahlen_bis}',
          description: '31.12.2020'
        },
        {
          fieldName: 'Kaution Aufbewahrer',
          placeholder: '{kaution.aufbewahrer}',
          description: 'UBS AG'
        },
        {
          fieldName: 'Kaution Bankkonto',
          placeholder: '{kaution.konto}',
          description: 'CH11 1111 1111 1111 1111 1'
        },
        {
          fieldName: 'Referenzzins Datum',
          placeholder: '{mz.referenzzins_datum}',
          description: '01.05.2020'
        },
        {
          fieldName: 'Referenzzins Effektiv',
          placeholder: '{mz.referenzzins}',
          description: '1.25'
        },
        {
          fieldName: 'Referenzzins Möglich',
          placeholder: '{mz.referenzzins_moegl}',
          description: '1.5'
        },
        {
          fieldName: 'Referenzzins - Anpassung in %',
          placeholder: '{mz.referenzzins_proz}',
          description: '1.25'
        },
        {
          fieldName: 'Referenzzins - Anpassung in CHF',
          placeholder: '{mz.referenzzins_chf}',
          description: '1.25'
        },
        {
          fieldName: 'Referenzzins Vorbehalt in %',
          placeholder: '{mz.referenzzins_vor_proz}',
          description: '0.25'
        },
        {
          fieldName: 'Referenzzins Vorbehalt in CHF',
          placeholder: '{mz.referenzzins_vor_chf}',
          description: '23.00'
        },
        {
          fieldName: 'Landesindex Teuerung',
          placeholder: '{mz.index.teuerung}',
          description: '101.3'
        },
        {
          fieldName: 'Landesindex Datum',
          placeholder: '{mz.index.datum}',
          description: '8.2021'
        },
        {
          fieldName: 'Landesindex Basisjahr',
          placeholder: '{mz.index.jahr}',
          description: '2020'
        },
        {
          fieldName: 'Landesindex Teuerung Möglich',
          placeholder: '{mz.index.teuerung_moeglich}',
          description: '107.8'
        },
        {
          fieldName: 'Landesindex Möglich - Anpassung in %',
          placeholder: '{mz.index.teuerung_m_proz}',
          description: '0.04%'
        },
        {
          fieldName: 'Landesindex Möglich - Anpassung in CHF',
          placeholder: '{mz.index.teuerung_m_chf}',
          description: '14.50'
        },
        {
          fieldName: 'Landesindex Datum möglich',
          placeholder: '{mz.index.teuerung_datum_moeglich}',
          description: '01/2024'
        },
        {
          fieldName: 'Landesindex Vorbehalt in %',
          placeholder: '{mz.index.teuerung_vor_proz}',
          description: '0.30'
        },
        {
          fieldName: 'Landesindex Vorbehalt in CHF',
          placeholder: '{mz.index.teuerung_vor_chf}',
          description: '5.00'
        },
        {
          fieldName: 'Kostensteigerung - Typ',
          placeholder: '{vm.ks_typ}',
          description: 'Pauschal / Index'
        },
        {
          fieldName: 'Kostensteigerung - Datum',
          placeholder: '{vm.ks_datum}',
          description: '01.01.2021'
        },
        {
          fieldName: 'Kostensteigerung - Prozent',
          placeholder: '{vm.ks_proz}',
          description: '0.5'
        },
        {
          fieldName: 'Mietzinsreserve Schleife - Start',
          placeholder: '{#vm.mzr}',
          description: ''
        },
        {
          fieldName: 'Mietzinsreserve - Typ',
          placeholder: '{typ}',
          description: 'Ungenügende Bruttorendite'
        },
        {
          fieldName: 'Mietzinsreserve - Bezeichnung',
          placeholder: '{bez}',
          description: '% der Nettomiete'
        },
        {
          fieldName: 'Mietzinsreserve - Effektiv',
          placeholder: '{eff}',
          description: '1'
        },
        {
          fieldName: 'Mietzinsreserve - Möglich',
          placeholder: '{moegl}',
          description: '1'
        },
        {
          fieldName: 'Mietzinsreserve - Bemerkung',
          placeholder: '{bem}',
          description: 'Bemerkung'
        },
        {
          fieldName: 'Mietzinsreserve - Anpassung in %',
          placeholder: '{proz}',
          description: '9.00'
        },
        {
          fieldName: 'Mietzinsreserve - Anpassung in CHF',
          placeholder: '{chf}',
          description: '150.00'
        },
        {
          fieldName: 'Mietzinsreserve Schleife - Ende',
          placeholder: '{/vm.mzr}',
          description: ''
        },
        {
          fieldName: 'Erneuerungsoptionen Schleife - Start',
          placeholder: '{#vm.eopt}',
          description: ''
        },
        {
          fieldName: 'Erneuerungsoptionen - Von',
          placeholder: '{von}',
          description: '31.12.2022'
        },
        {
          fieldName: 'Erneuerungsoptionen - Bis',
          placeholder: '{bis}',
          description: '31.12.2022'
        },
        {
          fieldName: 'Erneuerungsoptionen - Auszuüben',
          placeholder: '{auszuueben}',
          description: '31.12.2021'
        },
        {
          fieldName: 'Erneuerungsoptionen - Optionstyp',
          placeholder: '{typ}',
          description: 'echte or unechte'
        },
        {
          fieldName: 'Erneuerungsoptionen - Automatische Verlängerung',
          placeholder: '{verl}',
          description: 'Ja oder Nein'
        },
        {
          fieldName: 'Erneuerungsoptionen in Monaten / Jahren',
          placeholder: '{verldauer}',
          description: '1 Jahr, 2 Jahre, 3 Jahre… Oder 1 Jahr und 1 Monat, 2 Jahre und 2 Monate etc.'
        },
        {
          fieldName: 'Erneuerungsoptionen Schleife - Ende',
          placeholder: '{/vm.eopt}',
          description: ''
        },
        {
          fieldName: 'Vorzeitiges Kündigungsrecht Schleife - Start',
          placeholder: '{#vm.vkopt}',
          description: ''
        },
        {
          fieldName: 'Vorzeitiges Kündigungsrecht - Bis',
          placeholder: '{bis}',
          description: '31.12.2022'
        },
        {
          fieldName: 'Vorzeitiges Kündigungsrecht - Auszuüben',
          placeholder: '{auszuueben}',
          description: '31.12.2022'
        },
        {
          fieldName: 'Mitbewohner Schleife - Start',
          category: 'tenancy',
          placeholder: '{#vm.mit}',
          description: ''
        },
        {
          fieldName: 'Mitbewohner Schleife - Name',
          category: 'tenancy',
          placeholder: '{name}',
          description: 'Max Mustermann'
        },
        {
          fieldName: 'Mitbewohner Schleife - Geburtstag',
          category: 'tenancy',
          placeholder: '{geb}',
          description: '01.01.1990'
        },
        {
          fieldName: 'Mitbewohner Schleife - Ende',
          category: 'tenancy',
          placeholder: '{/vm.mit}',
          description: ''
        },
        {
          fieldName: 'Untermieter Schleife - Start',
          category: 'tenancy',
          placeholder: '{#vm.unter}',
          description: ''
        },
        {
          fieldName: 'Untermieter Schleife - Name',
          category: 'tenancy',
          placeholder: '{name}',
          description: 'Max Mustermann'
        },
        {
          fieldName: 'Untermieter Schleife - Vertragsstart',
          category: 'tenancy',
          placeholder: '{beginn}',
          description: '01.01.2022'
        },
        {
          fieldName: 'Untermieter Schleife - Vertragsende',
          category: 'tenancy',
          placeholder: '{ende}',
          description: '31.12.2022'
        },
        {
          fieldName: 'Untermieter Schleife - Geburtstag',
          category: 'tenancy',
          placeholder: '{geb}',
          description: '01.01.1990'
        },
        {
          fieldName: 'Untermieter Schleife - Ende',
          category: 'tenancy',
          placeholder: '{/vm.unter}',
          description: ''
        },
        {
          fieldName: 'Kündigungsoption in Monaten / Jahren',
          placeholder: '{kuenddauer}',
          description: '1 Jahr, 2 Jahre, 3 Jahre… Oder 1 Jahr und 1 Monat, 2 Jahre und 2 Monate etc.'
        },
        {
          fieldName: 'Vorzeitiges Kündigungsrecht Schleife - Ende',
          placeholder: '{/vm.vkopt}',
          description: ''
        },
        {
          fieldName: 'Indexmiete - Mietzinsanpassung erstmals möglich per',
          placeholder: '{index.erst_per}',
          description: '01.01.2022'
        },
        {
          fieldName: 'Indexmiete - Mietzinsanpassung nach Monate',
          placeholder: '{index.nach_mnt}',
          description: '12'
        },
        {
          fieldName: 'Indexmiete - Mietzinsanpassung nach Punkten',
          placeholder: '{index.nach_pt}',
          description: '100'
        },
        {
          fieldName: 'Indexmiete - Unterstellung des Indexes in %',
          placeholder: '{index.unterst_proz}',
          description: '100'
        },
        {
          fieldName: 'Indexmiete - Referenzmonat für Index',
          placeholder: '{index.ref_mnt}',
          description: '12'
        },
        {
          fieldName: 'Indexmiete - Maximalzins',
          placeholder: '{index.max_zins}',
          description: '1000'
        },
        {
          fieldName: 'Indexmiete - Anzeigefrist',
          placeholder: '{index.anzeigefrist}',
          description: '1 Monat/e'
        },
        {
          fieldName: 'Indexmiete - Mietzinsanpassungsmonate',
          placeholder: '{index.mnt}',
          description: 'Jan, Jun'
        },
        {
          fieldName: 'Aktuelle Landesindex Teuerung',
          placeholder: '{mz.akt.index.teuerung}',
          description: '101.3'
        },
        {
          fieldName: 'Aktueller Landesindex Datum',
          placeholder: '{mz.akt.index.datum}',
          description: 'Mai 2021'
        },
        {
          fieldName: 'Aktuelles Referenzzins Datum',
          placeholder: '{mz.akt.referenzzins_datum}',
          description: '1.25%'
        },
        {
          fieldName: 'Kostensteigerungsdatum',
          placeholder: '{vm.akt.ks_datum}',
          description: '31.01.2021'
        },
        {
          fieldName: 'Vermietung Vertragszusätze',
          placeholder: '{vm.zusatz}',
          description: '2 Hunde'
        },
        {
          fieldName: 'Kostensteigerung Datum möglich',
          placeholder: '{vm.ks_datum_moeglich}',
          description: '01/2024'
        },
        {
          fieldName: 'Kostensteigerung Vorbehalt in %',
          placeholder: '{vm.ks_datum_vor_proz}',
          description: '0.5'
        },
        {
          fieldName: 'Kostensteigerung Vorbehalt in CHF',
          placeholder: '{vm.ks_datum_vor_chf}',
          description: '15.00'
        },
        {
          fieldName: 'Frühere Vermietung Netto',
          placeholder: '{ltz.vm.netto}',
          description: '1000'
        },
        {
          fieldName: 'Frühere Vermietung Nebenkosten',
          placeholder: '{ltz.vm.nk}',
          description: '100'
        },
        {
          fieldName: 'Frühere Vermietung Heizkosten',
          placeholder: '{ltz.vm.hk}',
          description: '100'
        },
        {
          fieldName: 'Frühere Vermietung Brutto',
          placeholder: '{ltz.vm.brutto}',
          description: '1200'
        },
        {
          fieldName: 'Frühere Vermietung Datum',
          placeholder: '{ltz.vm.datum}',
          description: '01.04.2022'
        },
        {
          fieldName: 'Frühere Vermietung Mieter',
          placeholder: '{ltz.vm.mieter_alle}',
          description: 'Max Mustermann, Maxima Mustermann'
        },
        {
          fieldName: 'Frühere Vermietung Referenzzins',
          placeholder: '{ltz.mz.referenzzins}',
          description: '1.25'
        },
        {
          fieldName: 'Frühere Vermietung Landesindex Teuerung',
          placeholder: '{ltz.mz.index.teuerung}',
          description: '101.3'
        },
        {
          fieldName: 'Frühere Vermietung Landesindex Datum',
          placeholder: '{ltz.mz.index.datum}',
          description: '8.2021'
        },
        {
          fieldName: 'Frühere Vermietung Landesindex Basisjahr',
          placeholder: '{ltz.mz.index.jahr}',
          description: '2020'
        },
        {
          fieldName: 'Frühere Netto',
          placeholder: '{ltz.netto}',
          description: '1000'
        },
        {
          fieldName: 'Frühere Nebenkosten',
          placeholder: '{ltz.nk}',
          description: '100'
        },
        {
          fieldName: 'Frühere Heizkosten',
          placeholder: '{ltz.hk}',
          description: '100'
        },
        {
          fieldName: 'Frühere Brutto',
          placeholder: '{ltz.brutto}',
          description: '1200'
        },
        {
          fieldName: 'Frühere Datum',
          placeholder: '{ltz.datum}',
          description: '01.04.2022'
        },
        {
          fieldName: 'Frühere Mieter',
          placeholder: '{ltz.mieter_alle}',
          description: 'Max Mustermann, Maxima Mustermann'
        },
        {
          fieldName: 'Frühere Referenzzins',
          placeholder: '{ltz.referenzzins}',
          description: '1.25'
        },
        {
          fieldName: 'Frühere Landesindex Teuerung',
          placeholder: '{ltz.index.teuerung}',
          description: '101.3'
        },
        {
          fieldName: 'Frühere Landesindex Datum',
          placeholder: '{ltz.index.datum}',
          description: '8.2021'
        },
        {
          fieldName: 'Frühere Landesindex Basisjahr',
          placeholder: '{ltz.index.jahr}',
          description: '2020'
        },
        {
          fieldName: 'Liegenschaft ID',
          placeholder: '{ls.id}',
          description: '100'
        },
        {
          fieldName: 'EGRID',
          placeholder: '{ls.egrid}',
          description: 'CH123456789'
        },
        {
          fieldName: 'Liegenschaft Bezeichnung',
          placeholder: '{ls.bez}',
          description: 'Musterstrasse 1'
        },
        {
          fieldName: 'Liegenschaft Ort',
          placeholder: '{ls.ort}',
          description: 'Zürich'
        },
        {
          fieldName: 'Liegenschaft PLZ',
          placeholder: '{ls.plz}',
          description: '8000'
        },
        {
          fieldName: 'Liegenschaft Parzelle',
          placeholder: '{ls.parzelle}',
          description: '4782'
        },
        {
          fieldName: 'Liegenschaft IBAN',
          placeholder: '{ls.iban}',
          description: 'CH11 1111 1111 1111 1111 1'
        },
        {
          fieldName: 'Finanzinstitut ',
          placeholder: '{ls.bank}',
          description: 'UBS AG'
        },
        {
          fieldName: 'Eigentümer-Name',
          placeholder: '{ls.acctname}',
          description: 'Max Mustermann, Zürich'
        },
        {
          fieldName: 'Lautend auf',
          placeholder: '{ls.lautendauf}',
          description: 'Max Mustermann, Zürich'
        },
        {
          fieldName: 'Gebäude ID',
          placeholder: '{gb.id}',
          description: '555'
        },
        {
          fieldName: 'EGID',
          placeholder: '{gb.egid}',
          description: '123456789'
        },
        {
          fieldName: 'Gebäude Bezeichnung',
          placeholder: '{gb.bez}',
          description: 'Musterstrasse 1'
        },
        {
          fieldName: 'Gebäude Strasse',
          placeholder: '{gb.str}',
          description: 'Musterstrasse'
        },
        {
          fieldName: 'Gebäude Nummer',
          placeholder: '{gb.nr}',
          description: '1'
        },
        {
          fieldName: 'Contract additions to the building',
          placeholder: '{gb.vertragszusaetze}',
          description: 'Keine Haustiere erlaubt'
        },
        {
          fieldName: 'Gebäude Bemerkungen ',
          placeholder: '{gb.bem}',
          description: 'Bemerkungen'
        },
        {
          fieldName: 'Objekt ID',
          placeholder: '{obj.id}',
          description: '4587'
        },
        {
          fieldName: 'Objekt Bezeichnung',
          placeholder: '{obj.bez}',
          description: '1.0.1 Attikawohnung'
        },
        {
          fieldName: 'Objekt Typ',
          placeholder: '{obj.typ}',
          description: 'Wohnung'
        },
        {
          fieldName: 'Objekt Unterobjektart',
          placeholder: '{obj.art}',
          description: 'Attikawohnung'
        },
        {
          fieldName: 'Objekt Zimmer',
          placeholder: '{obj.zimmer}',
          description: '3.5 Zimmer'
        },
        {
          fieldName: 'Objekt Strasse',
          placeholder: '{obj.str}',
          description: 'Musterstrasse'
        },
        {
          fieldName: 'Objekt Hausnummer',
          placeholder: '{obj.nr}',
          description: '1'
        },
        {
          fieldName: 'Objekt PLZ',
          placeholder: '{obj.plz}',
          description: '8000'
        },
        {
          fieldName: 'Objekt Ort',
          placeholder: '{obj.ort}',
          description: 'Zürich'
        },
        {
          fieldName: 'EWID',
          placeholder: '{obj.ewid}',
          description: '1'
        },
        {
          fieldName: 'Objekt Etage',
          placeholder: '{obj.etage}',
          description: '1. OG'
        },
        {
          fieldName: 'Objekt Lage',
          placeholder: '{obj.lage}',
          description: 'Süd/West'
        },
        {
          fieldName: 'Objekt Fläche',
          placeholder: '{obj.flaeche}',
          description: '120'
        },
        {
          fieldName: 'Objekt Nutzfläche',
          placeholder: '{obj.nf}',
          description: '120'
        },
        {
          fieldName: 'Objekt Nebenräume',
          placeholder: '{obj.nebenraeume}',
          description: 'Keller'
        },
        {
          fieldName: 'Objekt zugeordnete Nebenräume',
          placeholder: '{obj.zugeordnete_nebenraeume}',
          description: 'Keller 101'
        },
        {
          fieldName: 'Objekt Mitbenutzung',
          placeholder: '{obj.mitbenutzung}',
          description: 'Veloraum'
        },
        {
          fieldName: 'Objekt besondere Vereinbarung',
          placeholder: '{obj.sond_vereinbarung}',
          description: 'keine Haustiere erlaubt'
        },
        {
          fieldName: 'Objekt Besonderheiten',
          placeholder: '{obj.besonderheiten}',
          description: 'wurde neu gestrichen'
        },
        {
          fieldName: 'Objekt Bemerkungen',
          placeholder: '{obj.bemerkungen}',
          description: ''
        },
        {
          fieldName: 'Objekt Zuständiger Eigentümer',
          placeholder: '{obj.e.zust.name}',
          description: 'Max Mustermann'
        },
        {
          fieldName: 'Objekt Zuständiger Eigentümer',
          placeholder: '{obj.e.zust.tel}',
          description: '044 444 44 44'
        },
        {
          fieldName: 'Objekt Zuständiger Eigentümer',
          placeholder: '{obj.e.zust.mail}',
          description: 'max.mustermann@muster.ch'
        },
        {
          fieldName: 'Objekt Zuständiger Eigentümer',
          placeholder: '{obj.e.zust.pos}',
          description: 'CEO'
        },
        {
          fieldName: 'Objekt Zuständiger Bewirtschaftung',
          placeholder: '{obj.bw.zust.name}',
          description: 'Max Mustermann'
        },
        {
          fieldName: 'Objekt Zuständiger Bewirtschaftung',
          placeholder: '{obj.bw.zust.tel}',
          description: '044 444 44 44'
        },
        {
          fieldName: 'Objekt Zuständiger Bewirtschaftung',
          placeholder: '{obj.bw.zust.mail}',
          description: 'max.mustermann@muster.ch'
        },
        {
          fieldName: 'Objekt Zuständiger Bewirtschaftung',
          placeholder: '{obj.bw.zust.pos}',
          description: 'CEO'
        },
        {
          fieldName: 'Objekt Zuständiger Asset Manager',
          placeholder: '{obj.am.zust.name}',
          description: 'Max Mustermann'
        },
        {
          fieldName: 'Objekt Zuständiger Asset Manager',
          placeholder: '{obj.am.zust.tel}',
          description: '044 444 44 44'
        },
        {
          fieldName: 'Objekt Zuständiger Asset Manager',
          placeholder: '{obj.am.zust.mail}',
          description: 'max.mustermann@muster.ch'
        },
        {
          fieldName: 'Objekt Zuständiger Asset Manager',
          placeholder: '{obj.am.zust.pos}',
          description: 'CEO'
        },
        {
          fieldName: 'Objekt Zuständiger Buchhaltung',
          placeholder: '{obj.bh.zust.name}',
          description: 'Max Mustermann'
        },
        {
          fieldName: 'Objekt Zuständiger Buchhaltung',
          placeholder: '{obj.bh.zust.tel}',
          description: '044 444 44 44'
        },
        {
          fieldName: 'Objekt Zuständiger Buchhaltung',
          placeholder: '{obj.bh.zust.mail}',
          description: 'max.mustermann@muster.ch'
        },
        {
          fieldName: 'Objekt Zuständiger Buchhaltung',
          placeholder: '{obj.bh.zust.pos}',
          description: 'CEO'
        },
        {
          fieldName: 'Objekt Zuständiger Vermarktung',
          placeholder: '{obj.verm.zust.name}',
          description: 'Max Mustermann'
        },
        {
          fieldName: 'Objekt Zuständiger Vermarktung',
          placeholder: '{obj.verm.zust.tel}',
          description: '044 444 44 44'
        },
        {
          fieldName: 'Objekt Zuständiger Vermarktung',
          placeholder: '{obj.verm.zust.mail}',
          description: 'max.mustermann@muster.ch'
        },
        {
          fieldName: 'Objekt Zuständiger Vermarktung',
          placeholder: '{obj.verm.zust.pos}',
          description: 'CEO'
        },
        {
          fieldName: 'Objekt aWN',
          placeholder: '{obj.awn}',
          description: '12'
        },
        {
          fieldName: 'Interne Objekt Nummer',
          placeholder: '{obj.int_nr}',
          description: '5132'
        },
        {
          fieldName: 'Wertquote',
          placeholder: '{obj.wquote}',
          description: '65'
        },
        {
          fieldName: 'Gesamtwertquote',
          placeholder: '{obj.gewquote}',
          description: '1000'
        },
        {
          fieldName: 'Alle Mietzinskomponenten Schleife - Start',
          placeholder: '{#vm.mz_bestand}',
          description: '',
          category: 'rentComponent'
        },
        {
          fieldName: 'Alle Mietzinskomponenten Schleife - Name',
          placeholder: '{name}',
          description: 'Allgemeinstrom',
          category: 'rentComponent'
        },
        {
          fieldName: 'Alle Mietzinskomponenten Schleife - Frühere Währung',
          placeholder: '{fc}',
          description: 'CHF',
          category: 'rentComponent'
        },
        {
          fieldName: 'Alle Mietzinskomponenten Schleife - Früherer Wert',
          placeholder: '{fv}',
          description: '100.00',
          category: 'rentComponent'
        },
        {
          fieldName: 'Alle Mietzinskomponenten Schleife - Aktuelle Währung',
          placeholder: '{cc}',
          description: 'CHF',
          category: 'rentComponent'
        },
        {
          fieldName: 'Alle Mietzinskomponenten Schleife - Aktueller Wert',
          placeholder: '{cv}',
          description: '120.00',
          category: 'rentComponent'
        },
        {
          fieldName: 'Alle Mietzinskomponenten Schleife - Typ',
          placeholder: '{type}',
          description: 'Akonto / Pauschal',
          category: 'rentComponent'
        },
        {
          fieldName: 'Alle Mietzinskomponenten Schleife - Beschreibung',
          placeholder: '{bem}',
          description: 'Beschreibung',
          category: 'rentComponent'
        },
        {
          fieldName: 'Alle Mietzinskomponenten Schleife - Ende',
          placeholder: '{/vm.mz_bestand}',
          description: '',
          category: 'rentComponent'
        },
        {
          fieldName: 'Alle Mietzinskomponenten',
          placeholder: '{vm.komp.alle}',
          description: 'Auflistung aller Komponenten',
          category: 'rentComponent'
        },
        {
          fieldName: 'Bauteil & Gerät Schleife - Start',
          category: 'order',
          placeholder: '{#g}',
          description: ''
        },
        {
          fieldName: 'Bauteil & Gerät',
          category: 'order',
          placeholder: '{geraetetyp}',
          description: 'Kühlschrank'
        },
        {
          fieldName: 'Bauteil & Gerät - BKP Nummer',
          category: 'order',
          placeholder: '{bkph}',
          description: '258'
        },
        {
          fieldName: 'Bauteil & Gerät - Hersteller',
          category: 'order',
          placeholder: '{hersteller}',
          description: 'V-Zug'
        },
        {
          fieldName: 'Bauteil & Gerät - Marke',
          category: 'order',
          placeholder: '{marke}',
          description: 'V-Zug'
        },
        {
          fieldName: 'Bauteil & Gerät - Modell',
          category: 'order',
          placeholder: '{modell}',
          description: 'Adora'
        },
        {
          fieldName: 'Bauteil & Gerät - Seriennummer',
          category: 'order',
          placeholder: '{seriennummer}',
          description: 'SN156879845'
        },
        {
          fieldName: 'Bauteil & Gerät - Raum ',
          category: 'order',
          placeholder: '{raum}',
          description: 'Küche'
        },
        {
          fieldName: 'Bauteil & Gerät - Bemerkung',
          category: 'order',
          placeholder: '{bemerkung}',
          description: 'Bemerkung'
        },
        {
          fieldName: 'Bauteil & Gerät - Installation',
          category: 'order',
          placeholder: '{installation}',
          description: '01.01.2022'
        },
        {
          fieldName: 'Bauteil & Gerät - Deinstallation',
          category: 'order',
          placeholder: '{deinstallation}',
          description: '31.01.2022'
        },
        {
          fieldName: 'Bauteil & Gerät - Ablauf Garantie',
          category: 'order',
          placeholder: '{abl_garantie}',
          description: '31.12.2022'
        },
        {
          fieldName: 'Bauteil & Gerät - Letzte Wartung',
          category: 'order',
          placeholder: '{ltz_wartung}',
          description: '31.01.2022'
        },
        {
          fieldName: 'Bauteil & Gerät - Auftragsbeschreibung',
          placeholder: '{beschreibung}',
          category: 'order',
          description: 'Beschreibung'
        },
        {
          fieldName: 'Bauteil & Gerät Schleife - Ende',
          category: 'order',
          placeholder: '{/g}',
          description: ''
        },
        {
          fieldName: 'Auftrag - Auftragsnummer',
          placeholder: '{auftragsnummer}',
          category: 'order',
          description: '12345.678'
        },
        {
          fieldName: 'Auftrag - Auftragsdetails',
          placeholder: '{auftragsdetails}',
          category: 'order',
          description: 'Auftragdetails'
        },
        {
          fieldName: 'Auftrag - Empfänger Briefanschrift',
          placeholder: '{k.briefanschrift}',
          description: 'Herr <br> Max Mustermann <br> Musterstrasse 1 <br> CH-8000 Zürich, ZH'
        },
        {
          fieldName: 'Auftrag - Empfänger Briefanschrift 1',
          placeholder: '{k.briefanschrift_1}',
          description: 'Herr <br> Max Mustermann <br> Musterstrasse 1 <br> 8000 Zürich'
        },
        {
          fieldName: 'Auftrag - Empfänger Briefanschrift 2',
          placeholder: '{k.briefanschrift_2}',
          description: 'Herr <br> Max Mustermann <br> Musterstrasse 1 <br> CH - 8000 Zürich'
        },
        {
          fieldName: 'Auftrag - Empfänger Briefanrede',
          placeholder: '{k.brief_anrede}',
          description: 'Sehr geehrte Frau Musterfrau <br> Sehr geehrter Herr Mustermann'
        },
        {
          fieldName: 'Auftrag - Empfänger Firmenname',
          placeholder: '{k.firmenname}',
          description: 'Muster AG'
        },
        {
          fieldName: 'Auftrag - Empfänger Anrede',
          placeholder: '{k.anrede}',
          description: 'Herr / Frau / X'
        },
        {
          fieldName: 'Auftrag - Empfänger Name',
          placeholder: '{k.name}',
          description: 'Max Mustermann'
        },
        {
          fieldName: 'Auftrag - Empfänger Vorname',
          placeholder: '{k.vname}',
          description: 'Max'
        },
        {
          fieldName: 'Auftrag - Empfänger Nachname',
          placeholder: '{k.nname}',
          description: 'Mustermann'
        },
        {
          fieldName: 'Auftrag - Empfänger Titel',
          placeholder: '{k.titel}',
          description: 'Dr.'
        },
        {
          fieldName: 'Auftrag - Empfänger Adresszusatz (aktuell)',
          placeholder: '{k.az}',
          description: 'c/o Muster AG'
        },
        {
          fieldName: 'Auftrag - Empfänger Strasse (aktuell)',
          placeholder: '{k.str}',
          description: 'Musterstrasse'
        },
        {
          fieldName: 'Auftrag - Empfänger Hausnummer (aktuell)',
          placeholder: '{k.nr}',
          description: '1'
        },
        {
          fieldName: 'Auftrag - Empfänger Postfach (aktuell)',
          placeholder: '{k.pf}',
          description: 'Postfach 5'
        },
        {
          fieldName: 'Auftrag - Empfänger PLZ (aktuell)',
          placeholder: '{k.plz}',
          description: '8000'
        },
        {
          fieldName: 'Auftrag - Empfänger Ort (aktuell)',
          placeholder: '{k.ort}',
          description: 'Zürich'
        },
        {
          fieldName: 'Auftrag - Empfänger Kanton (aktuell)',
          placeholder: '{k.kanton}',
          description: 'ZH'
        },
        {
          fieldName: 'Auftrag - Empfänger Land (aktuell)',
          placeholder: '{k.land}',
          description: 'CH'
        },
        {
          fieldName: 'Auftrag - Empfänger Telefon',
          placeholder: '{k.tel}',
          description: '+41 44 512 76 76'
        },
        {
          fieldName: 'Auftrag - Empfänger E-Mail',
          placeholder: '{k.mail}',
          description: 'info@example.ch'
        },
        {
          fieldName: 'Auftrag - Empfänger Website',
          placeholder: '{k.site}',
          description: 'www.muster.ch'
        },
        {
          fieldName: 'Auftrag - Ansprechperson vor Ort Briefanschrift',
          placeholder: '{avo.briefanschrift}',
          description: 'Herr <br> Max Mustermann <br> Musterstrasse 1 <br> CH-8000 Zürich, ZH'
        },
        {
          fieldName: 'Auftrag - Ansprechperson vor Ort Briefanschrift 1',
          placeholder: '{avo.briefanschrift_1}',
          description: 'Herr <br> Max Mustermann <br> Musterstrasse 1 <br> 8000 Zürich'
        },
        {
          fieldName: 'Auftrag - Ansprechperson vor Ort Briefanschrift 2',
          placeholder: '{avo.briefanschrift_2}',
          description: 'Herr <br> Max Mustermann <br> Musterstrasse 1 <br> CH - 8000 Zürich'
        },
        {
          fieldName: 'Auftrag - Ansprechperson vor Ort Firmenname',
          placeholder: '{avo.firmenname}',
          description: 'Muster AG'
        },
        {
          fieldName: 'Auftrag - Ansprechperson vor Ort Anrede',
          placeholder: '{avo.anrede}',
          description: 'Herr / Frau / X'
        },
        {
          fieldName: 'Auftrag - Ansprechperson vor Ort Name',
          placeholder: '{avo.name}',
          description: 'Max Mustermann'
        },
        {
          fieldName: 'Auftrag - Ansprechperson vor Ort Vorname',
          placeholder: '{avo.vname}',
          description: 'Max'
        },
        {
          fieldName: 'Auftrag - Ansprechperson vor Ort Nachname',
          placeholder: '{avo.nname}',
          description: 'Mustermann'
        },
        {
          fieldName: 'Auftrag - Ansprechperson vor Ort Titel',
          placeholder: '{avo.titel}',
          description: 'Dr.'
        },
        {
          fieldName: 'Auftrag - Ansprechperson vor Ort Adresszusatz (aktuell)',
          placeholder: '{avo.az}',
          description: 'c/o Muster AG'
        },
        {
          fieldName: 'Auftrag - Ansprechperson vor Ort Strasse (aktuell)',
          placeholder: '{avo.str}',
          description: 'Musterstrasse'
        },
        {
          fieldName: 'Auftrag - Ansprechperson vor Ort Hausnummer (aktuell)',
          placeholder: '{avo.nr}',
          description: '1'
        },
        {
          fieldName: 'Auftrag - Ansprechperson vor Ort Postfach (aktuell)',
          placeholder: '{avo.pf}',
          description: 'Postfach 5'
        },
        {
          fieldName: 'Auftrag - Ansprechperson vor Ort PLZ (aktuell)',
          placeholder: '{avo.plz}',
          description: '8000'
        },
        {
          fieldName: 'Auftrag - Ansprechperson vor Ort Ort (aktuell)',
          placeholder: '{avo.ort}',
          description: 'Zürich'
        },
        {
          fieldName: 'Auftrag - Ansprechperson vor Ort Kanton (aktuell)',
          placeholder: '{avo.kanton}',
          description: 'ZH'
        },
        {
          fieldName: 'Auftrag - Ansprechperson vor Ort Land (aktuell)',
          placeholder: '{avo.land}',
          description: 'CH'
        },
        {
          fieldName: 'Auftrag - Ansprechperson vor Ort Telefon',
          placeholder: '{avo.tel}',
          description: '+41 44 512 76 76'
        },
        {
          fieldName: 'Auftrag - Ansprechperson vor Ort E-Mail',
          placeholder: '{avo.mail}',
          description: 'info@example.com'
        },
        {
          fieldName: 'Auftrag - Ansprechperson vor Ort Website',
          placeholder: '{avo.site}',
          description: 'www.mieter.ch'
        },
        {
          fieldName: 'Auftrag - Auftragsersteller Vorname',
          placeholder: '{ae.vname}',
          description: 'Max'
        },
        {
          fieldName: 'Auftrag - Auftragsersteller Nachname',
          placeholder: '{ae.nname}',
          description: 'Mustermann'
        },
        {
          fieldName: 'Auftrag - Auftragsersteller Telefon',
          placeholder: '{ae.tel}',
          description: '+41 44 512 76 76'
        },
        {
          fieldName: 'Auftrag - Auftragsersteller E-Mail',
          placeholder: '{ae.mail}',
          description: 'info@example.com'
        },
        {
          fieldName: 'Auftrag - Auftragsersteller Position',
          placeholder: '{ae.pos}',
          description: 'Sachbearbeiter'
        },
        {
          fieldName: 'Auftrag - Termin ab',
          placeholder: '{termin_ab}',
          description: '21.10.2021'
        },
        {
          fieldName: 'Auftrag - Termin bis',
          placeholder: '{termin_bis}',
          description: '21.10.2021'
        },
        {
          fieldName: 'Heutiges Datum',
          placeholder: '{datum.heute}',
          description: '21. Oktober 2021'
        },

        {
          fieldName: 'Brief Schleife - Start',
          placeholder: '{#brief}',
          description: ''
        },
        {
          fieldName: 'Brief - Titel',
          placeholder: '{brief_betreff}',
          description: 'Titel'
        },
        {
          fieldName: 'Brief - Beschreibung',
          placeholder: '{brief_brieftext}',
          description: 'Beschreibung'
        },
        {
          fieldName: 'Brief Schleife - Ende',
          placeholder: '{/brief}',
          description: ''
        },
        {
          fieldName: 'Benutzer - Name',
          placeholder: '{benutzer.name}',
          description: 'Max Mustermann'
        },
        {
          fieldName: 'Benutzer - Telefon',
          placeholder: '{benutzer.tel}',
          description: '044 444 44 44'
        },
        {
          fieldName: 'Benutzer - E-Mail',
          placeholder: '{benutzer.mail}',
          description: 'max.mustermann@muster.ch'
        },
        {
          fieldName: 'Benutzer - Job',
          placeholder: '{benutzer.pos}',
          description: 'CEO'
        },
        {
          fieldName: 'Benutzer - Ort',
          placeholder: '{benutzer.ort}',
          description: 'Musterhausen'
        },
        // category Signature
        {
          fieldName: 'Signatur Schleife - Start',
          category: 'signature',
          placeholder: '{#sign}',
          description: ''
        },
        {
          fieldName: 'Signatur Vorname',
          category: 'signature',
          placeholder: '{sign.vname}',
          description: ''
        },
        {
          fieldName: 'Signatur Nachname',
          category: 'signature',
          placeholder: '{sign.nname}',
          description: ''
        },
        {
          fieldName: 'Signatur Job',
          category: 'signature',
          placeholder: '{sign.position}',
          description: ''
        },
        {
          fieldName: 'Signatur E-Mail',
          category: 'signature',
          placeholder: '{sign.mail}',
          description: 'max@mustermann.ch'
        },
        {
          fieldName: 'Signatur tel',
          category: 'signature',
          placeholder: '{sign.tel}',
          description: '+41 79 797 79 79'
        },
        {
          fieldName: 'Signatur Schleife - Ende',
          category: 'signature',
          placeholder: '{/sign}',
          description: ''
        },
        {
          fieldName: 'Benutzer Unterschrift',
          category: 'signature',
          placeholder: '{benutzer.signatur}',
          description: ''
        },
        {
          fieldName: 'Benutzer Unterschrift schleife',
          category: 'signature',
          placeholder: '{sign.signatur}',
          description: ''
        },
        {
          fieldName: 'Eigentümer Unterschrift',
          category: 'signature',
          placeholder: '{obj.e.ap.signatur}',
          description: ''
        },
        {
          fieldName: 'Bewirtschafter Unterschrift',
          category: 'signature',
          placeholder: '{obj.bw.ap.signatur}',
          description: ''
        },
        {
          fieldName: 'Asset ManagerUnterschrift',
          category: 'signature',
          placeholder: '{obj.am.ap.signatur}',
          description: ''
        },
        {
          fieldName: 'Buchhaltung Unterschrift',
          category: 'signature',
          placeholder: '{obj.bh.ap.signatur}',
          description: ''
        },
        {
          fieldName: 'Vermarktung Unterschrift',
          category: 'signature',
          placeholder: '{obj.verm.ap.signatur}',
          description: ''
        }
      ]
    }
  },
  computed: {
    translatedCategories() {
      const translatedCategories = ['owner', 'networkPartner', 'mainTenant', 'tenancy', 'previousTenancy', 'property', 'building', 'object', 'rentComponents', 'addressBook', 'condominiumOwnership', 'mainCondominiumOwner', 'order', 'misc', 'signature']
      const array = []
      translatedCategories.forEach(cat => {
        const text = this.$t('message.settings.template.placeholderTemplate.filter.' + cat)
        array.push({
          label: text,
          value: cat
        })
      })
      array.sort((a, b) => {
        if (a.label.toLowerCase() < b.label.toLowerCase()) {
          return -1
        }
        if (a.label.toLowerCase() > b.label.toLowerCase()) {
          return 1
        }
        return 0
      })
      array.unshift({
        label: this.$t('message.settings.template.placeholderTemplate.filter.all'),
        value: ''
      })
      return array
    },
    placeholdersFiltered() {
      if (this.categoryFilter === 'addressBook') {
        return this.placeholders.filter(x => x.placeholder.startsWith('{ko.'))
      } else if (this.categoryFilter === 'mainCondominiumOwner') {
        return this.placeholders.filter(x => x.placeholder.startsWith('{hs.'))
      } else if (this.categoryFilter === 'owner') {
        return this.placeholders.filter(x => x.placeholder.startsWith('{e.'))
      } else if (this.categoryFilter === 'networkPartner') {
        return this.placeholders.filter(x => x.placeholder.startsWith('{bw.') || x.placeholder.startsWith('{am.') || x.placeholder.startsWith('{verm.'))
      } else if (this.categoryFilter === 'mainTenant') {
        return this.placeholders.filter(x => x.placeholder.startsWith('{hm.'))
      } else if (this.categoryFilter === 'tenancy') {
        return this.placeholders.filter(x => (
          (x.placeholder.startsWith('{vm.') && !x.placeholder.startsWith('{vm.komp')) ||
          x.placeholder.startsWith('{kaution.') ||
          x.placeholder.startsWith('{mz.') ||
          x.placeholder.startsWith('{#mieter') ||
          x.placeholder.startsWith('{/mieter') ||
          x.placeholder.startsWith('{m.')) ||
          (x.placeholder.startsWith('{#vm.') && !x.placeholder.startsWith('{#vm.mz_bestand')) ||
          x.placeholder.startsWith('{typ}') ||
          x.placeholder.startsWith('{bez}') ||
          x.placeholder.startsWith('{eff}') ||
          x.placeholder.startsWith('{moegl}') ||
          x.placeholder.startsWith('{proz}') ||
          x.placeholder.startsWith('{chf}') ||
          (x.placeholder.startsWith('{/vm.') && !x.placeholder.startsWith('{/vm.mz_bestand')) ||
          x.placeholder.startsWith('{mieter.') ||
          x.placeholder.startsWith('{index.') ||
          x.placeholder.startsWith('{bis}') ||
          x.placeholder.startsWith('{auszuueben}') ||
          (x.category === 'tenancy')
        )
      } else if (this.categoryFilter === 'condominiumOwnership') {
        return this.placeholders.filter(x => (
          x.placeholder.startsWith('{stwe.') ||
          x.placeholder.startsWith('{#stwe') ||
          x.placeholder.startsWith('{/stwe') ||
          x.placeholder.startsWith('{s.'))
        )
      } else if (this.categoryFilter === 'previousTenancy') {
        return this.placeholders.filter(x => x.placeholder.startsWith('{ltz.'))
      } else if (this.categoryFilter === 'property') {
        return this.placeholders.filter(x => x.placeholder.startsWith('{ls.'))
      } else if (this.categoryFilter === 'building') {
        return this.placeholders.filter(x => x.placeholder.startsWith('{gb.'))
      } else if (this.categoryFilter === 'object') {
        return this.placeholders.filter(x => x.placeholder.startsWith('{obj.'))
      } else if (this.categoryFilter === 'rentComponents') {
        return this.placeholders.filter(x => x.category === 'rentComponent')
      } else if (this.categoryFilter === 'order') {
        return this.placeholders.filter(x => x.placeholder.startsWith('{termin_') || x.placeholder.startsWith('{avo.') || x.placeholder.startsWith('{k.') || x.category === 'order' || x.placeholder.startsWith('{ae.'))
      } else if (this.categoryFilter === 'misc') {
        return this.placeholders.filter(x => x.placeholder.startsWith('{datum.heute}') ||
          x.placeholder.startsWith('{#brief}') ||
          x.placeholder.startsWith('{brief_betreff}') ||
          x.placeholder.startsWith('{brief_brieftext}') ||
          x.placeholder.startsWith('{benutzer') ||
          x.placeholder.startsWith('{/brief}'))
      } else if (this.categoryFilter === 'signature') {
        return this.placeholders.filter(x => x.category && x.category === 'signature')
      } else {
        return this.placeholders
      }
    }

  },
  methods: {
    onRowClicked(item) {
      const current = this
      navigator.clipboard.writeText(item.placeholder)
        .then(function() {
          Vue.toasted.show(current.$t('message.generic.copied'), { type: 'success' })
        }, function(err) {
          console.error(err)
        })
    }

  }
}
</script>

<style lang="scss" scoped>
:deep(.modal-dialog) {
  max-width: 1200px !important;
}
</style>
