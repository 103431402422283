// @ts-nocheck
// import MessageApi from '@/misc/apis/MessageApi'
import { user } from '@/mixins/user'
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import ContactApi from '@/misc/apis/ContactApi'
import Vue from 'vue'
import { onboarding } from '@/mixins/onboarding'
import { Mailv2Participant, V2Mail } from '@/types/api'

// event transporter
export const eventBus = new Vue()

const TICKET_DETAIL_ROUTES = {
  OWNER: 'OwnerTicketDetailsView',
  ADMIN: 'AdminTicketDetailsView',
  ASSET: 'AssetTicketDetailsView',
  ACCOUNTING: 'AccountingTicketDetailsView',
  MARKETING: 'MarketingTicketDetailsView'
}

const mail = {
  mixins: [user, onboarding],
  data() {
    return {
      numberMessage: '-',
      numberDraftMessage: '-'
    }
  },
  computed: {
    ...mapState('message', ['readMails', 'unreadMails']),
    ...mapGetters('message', ['getMailboxesForUserId']),
    mailboxesForCurrentUser() {
      return this.getMailboxesForUserId(this.userId)
    },
    isTicketAlreadyOpen() {
      const currentRoute = this.$route
      console.log('currentTicket', currentRoute)
      return Object.values(TICKET_DETAIL_ROUTES).includes(currentRoute.name) &&
        currentRoute.params.id === this.ticket?.key
    }
  },
  mounted: function () {
    this.getNumberInterval = setInterval(() => this.countDraftMails(), 30000)
  },
  beforeDestroy() {
    clearInterval(this.getNumberInterval)
  },
  methods: {
    ...mapActions('message', ['loadEmailCounts', 'loadDraftEmailCounts']),
    ...mapMutations('message', [
      'setSendMailCC',
      'setSendMailContacts',
      'setSendMailPreselectedContact',
      'setSendMailReferenceIds',
      'setSendMailEmailToReply',
      'setSendMailEmailToForward',
      'setSendMailAttachmentMediaIds',
      'setBccReferenceIds',
      'setSendMailContactsWithObject',
      'setRenderWithObjectId',
      'setSelectedEntries',
      'setToAllTo'
    ]),
    ...mapMutations('onboarding', [
      'setTenancies',
      'setCondominiumOwnership'
    ]),
    ...mapMutations('onboarding', ['setContacts']),
    callShowSendMailModal(setToAllTo = false) {
      this.setToAllTo(setToAllTo)

      if (this.$store.getters['user/isSendMailModalOpen'] === false) {
        this.$bvModal.show('modal-send-mail')
      } else {
        this.$bvModal.show('draft-writing-email-modal')
      }
    },
    tableRowClass(item, type) {
      let classes = ''
      if (item && type === 'row') {
        // First check local read and unread store
        if (this.readMails.some(e => e.mailId === (item.id || item.objectID) && parseInt(e.accountId) === parseInt(this.accountId))) {
          classes = ''
        } else if (this.unreadMails.some(e => e.mailId === (item.id || item.objectID) && parseInt(e.accountId) === parseInt(this.accountId))) {
          classes += 'unread-mail'
          // Then check "backend" value
        } else if (!item.readEntries.some(e => parseInt(e.accountId) === parseInt(this.accountId))) {
          classes += 'unread-mail'
        }
      }
      return classes
    },
    countUnreadMails(force = false) {
      if (this.mailboxesForCurrentUser.length > 0) {
        const mailboxIds = this.mailboxesForCurrentUser.map(mailbox => mailbox.id)
        this.$nextTick(async () => {
          this.numberMessage = await this.loadEmailCounts({
            mailboxIds: mailboxIds,
            force: force
          })
        })
      } else {
        this.numberMessage = '0'
      }
    },
    async countDraftMails(force = false) {
      this.numberDraftMessage = await this.loadDraftEmailCounts(force)
    },
    getRecipientCount(mail) {
      return mail.to.length + mail.cc.length + mail.bcc.length
    },
    getDirection(mail) {
      if (!mail.from) return ''

      const mailboxes = this.mailboxesForCurrentUser.map(obj => obj.outboundEmail?.toLowerCase())
      const fromEmail = mail.from.email?.toLowerCase()
      const toEmails = mail.to.map(x => x.email?.toLowerCase())
      const ccEmails = mail.cc.map(x => x.email?.toLowerCase())
      const bccEmails = mail.bcc.map(x => x.email?.toLowerCase())

      if (mailboxes.includes(fromEmail) && toEmails.some(email => mailboxes.includes(email))) return 'to'
      if (mailboxes.includes(fromEmail)) return 'from'
      if (toEmails.some(email => mailboxes.includes(email))) return 'to'
      if (ccEmails.length > 0 && ccEmails.some(email => mailboxes.includes(email))) return 'cc'
      if (bccEmails.length > 0 && bccEmails.some(email => mailboxes.includes(email))) return 'bcc'

      return ''
    },
    getToContactReferences(mail, replyAll = false) {
      const direction = this.getDirection(mail)
      // We only want to reply one
      if (!replyAll) {
        // Our mailbox is the sender of the mail, so we have to use first TO or CC here
        if (direction === 'from') {
          if (mail.to && mail.to.length > 0) {
            const emailTo = mail.to[0].email
            let c = null
            if (this.contacts?.find(c => c.email === emailTo || c.workEmail === emailTo)) {
              c = this.contacts?.find(c => c.email === emailTo || c.workEmail === emailTo)
            } else if (this.contacts?.find(c => 'contactPersons' in c && c.contactPersons.some(cp => cp.email === emailTo || cp.workEmail === emailTo))) {
              const comp = this.contacts?.find(c => 'contactPersons' in c && c.contactPersons.some(cp => cp.email === emailTo || cp.workEmail === emailTo))
              c = comp.contactPersons.find(cp => cp.email === emailTo || cp.workEmail === emailTo)
            }
            return [{
              email: mail.to[0].email,
              id: c?.id,
              name: c?.name || mail.to[0].name
            }]
          } else if (mail.cc && mail.cc.length > 0) {
            const emailCC = mail.cc[0].email
            let c = null
            if (this.contacts?.find(c => c.email === emailCC || c.workEmail === emailCC)) {
              c = this.contacts?.find(c => c.email === emailCC || c.workEmail === emailCC)
            } else if (this.contacts?.find(c => 'contactPersons' in c && c.contactPersons.some(cp => cp.email === emailCC || cp.workEmail === emailCC))) {
              const comp = this.contacts?.find(c => 'contactPersons' in c && c.contactPersons.some(cp => cp.email === emailCC || cp.workEmail === emailCC))
              c = comp.contactPersons.find(cp => cp.email === emailCC || cp.workEmail === emailCC)
            }
            return [{
              email: mail.cc[0].email,
              id: c?.id,
              name: c?.name || mail.cc[0].name
            }]
          }
        } else {
          // Our mailbox is the recipient of the mail, so we have to reply to the sender of the mail
          // const c = this.contacts?.find(c => c.email === mail.from.email || c.workEmail === mail.from.email)
          const emailFrom = mail.from.email
          let c = null
          if (this.contacts?.find(c => c.email === emailFrom || c.workEmail === emailFrom)) {
            c = this.contacts?.find(c => c.email === emailFrom || c.workEmail === emailFrom)
          } else if (this.contacts?.find(c => 'contactPersons' in c && c.contactPersons.some(cp => cp.email === emailFrom || cp.workEmail === emailFrom))) {
            const comp = this.contacts?.find(c => 'contactPersons' in c && c.contactPersons.some(cp => cp.email === emailFrom || cp.workEmail === emailFrom))
            c = comp.contactPersons.find(cp => cp.email === emailFrom || cp.workEmail === emailFrom)
          }
          return [{
            email: mail.from.email,
            id: c?.id,
            name: c?.name || mail.from.name
          }]
        }
      } else {
        // We want to reply everyone
        // Our mailbox is the sender of the mail, so we have to use first TO or CC here
        if (direction === 'from') {
          return mail.to
            .concat(mail.cc)
            .map(p => {
              const email = p.email
              let c = null
              if (this.contacts?.find(c => c.email === email || c.workEmail === email)) {
                c = this.contacts?.find(c => c.email === email || c.workEmail === email)
              } else if (this.contacts?.find(c => 'contactPersons' in c && c.contactPersons.some(cp => cp.email === email || cp.workEmail === email))) {
                const comp = this.contacts?.find(c => 'contactPersons' in c && c.contactPersons.some(cp => cp.email === email || cp.workEmail === email))
                c = comp.contactPersons.find(cp => cp.email === email || cp.workEmail === email)
              }
              return {
                email: p.email,
                id: c?.id,
                name: c?.name || p.name
              }
            })
        } else {
          // Our mailbox is the recipient of the mail, so we have to reply to the sender of the mail
          return [mail.from]
            .concat(mail.to)
            .map(p => {
              const email = p.email
              let c = null
              if (this.contacts?.find(c => c.email === email || c.workEmail === email)) {
                c = this.contacts?.find(c => c.email === email || c.workEmail === email)
              } else if (this.contacts?.find(c => 'contactPersons' in c && c.contactPersons.some(cp => cp.email === email || cp.workEmail === email))) {
                const comp = this.contacts?.find(c => 'contactPersons' in c && c.contactPersons.some(cp => cp.email === email || cp.workEmail === email))
                c = comp.contactPersons.find(cp => cp.email === email || cp.workEmail === email)
              }
              return {
                email: p.email,
                id: c?.id,
                name: c?.name || p.name
              }
            })
        }
      }

      return []
    },
    getCcContactReferences(mail, replyAll = false) {
      // We only want to reply one, so we don't have any CC
      if (!replyAll) {
        return []
      } else {
        // We want to reply everyone
        return mail.cc
          .map(p => {
            let c = null
            if (this.contacts?.find(c => c.email === p.email || c.workEmail === p.email)) {
              c = this.contacts?.find(c => c.email === p.email || c.workEmail === p.email)
            } else if (this.contacts?.find(c => 'contactPersons' in c && c.contactPersons.some(cp => cp.email === p.email || cp.workEmail === p.email))) {
              const comp = this.contacts?.find(c => 'contactPersons' in c && c.contactPersons.some(cp => cp.email === p.email || cp.workEmail === p.email))
              c = comp.contactPersons.find(cp => cp.email === p.email || cp.workEmail === p.email)
            }
            return {
            email: p.email,
            id: c?.id || null,
            name: c?.name || p.name
            }
          })
      }
    },
    prepareReplyMail(email, sendMailContacts = []) {
      if (sendMailContacts.length > 0) {
        this.setSendMailContacts(sendMailContacts)
      } else {
        this.setSendMailContacts([email.from])
      }

      this.setSendMailPreselectedContact(null)
      this.setSendMailReferenceIds(email.referenceIds)
      this.setSendMailEmailToReply(email)
      this.$nextTick(function () {
        this.callShowSendMailModal()
      })
    },
    prepareReplyAll(email) {
      const ignoreMyMail = this.$store.getters['user/getEmail']
      const to = this.getToContactReferences(email, true)
      const cc = this.getCcContactReferences(email, true)
      const recipients = [email.from, ...to].reduce((acc, recipient) => {
        if (!acc.some(accRecipient => accRecipient.email === recipient.email)) {
          acc.push(recipient)
        }
        return acc
      }, [])

      this.setSendMailContacts(recipients)
      this.setSendMailCC(cc)
      this.setSendMailEmailToReply(email)
      this.setSendMailPreselectedContact(null)
      this.setSendMailReferenceIds([])
      this.$nextTick(function () {
        this.callShowSendMailModal()
      })
    },
    prepareForwardMail(email) {
      this.setSendMailReferenceIds([])
      const forwardingMail = { ...email }
      forwardingMail.isForwarding = true
      this.setSendMailEmailToForward(forwardingMail)
      this.setSendMailPreselectedContact(null)
      this.setSendMailAttachmentMediaIds(forwardingMail.attachmentMediaIds)
      this.$nextTick(function () {
        this.callShowSendMailModal()
      })
    },
    async prepareSerialEmail(selectedEntries) {
      this.setSelectedEntries(selectedEntries)
      let contactIds = []
      const contactsWithObject = []
      const onlyObjectWithContact = []

      if (selectedEntries) {
        selectedEntries.forEach(element => {
          let objectContacts = []
          const objectId = element.obj?.id || element.objectId || element.objectID || element.id
          const tenancyOrCondominiumId = element.id || element.objectID
          if (!element.condominiumOwnership && !element.condominiumOwners && !tenancyOrCondominiumId.includes('condominium_')) {
            let currentTenancy
            if (tenancyOrCondominiumId.includes('tenancy_')) {
              currentTenancy = this.getTenancyFromState(tenancyOrCondominiumId)[0]
            } else {
              currentTenancy = this.getCurrentTenancy(objectId)
            }
            if (currentTenancy) {
              onlyObjectWithContact.push(currentTenancy.objectId || currentTenancy.id)
              objectContacts = objectContacts.concat(currentTenancy.tenant.contactIds)
              currentTenancy.tenant.contactIds.forEach(element => {
                objectContacts.push(element)
              })
              currentTenancy.tenant.guarantorContactIds.forEach(element => {
                objectContacts.push(element)
              })
              if (currentTenancy.tenant.billingContactId) {
                objectContacts.push(currentTenancy.tenant.billingContactId)
              }
              if (currentTenancy.tenant.correspondenceContactId) {
                objectContacts.push(currentTenancy.tenant.correspondenceContactId)
              }
              objectContacts = [...new Set(objectContacts)]
              contactsWithObject.push({
                contacts: contactIds,
                objectId: objectId
              })
              contactIds = contactIds.concat(objectContacts)
            } else if (element.tenancy) {
              // case of serial email from Rental Objects algolia
              const tenancy = element.tenancy
              onlyObjectWithContact.push(element.objectID)
              tenancy.tenants.forEach(element => {
                objectContacts.push(element.contactId)
              })
              if (tenancy.guarantors) {
                tenancy.guarantors.forEach(element => {
                  objectContacts.push(element.contactId)
                })
              }
              if (tenancy.billingContact) {
                objectContacts.push(tenancy.billingContact.contactId)
              }
              if (tenancy.correspondenceContact) {
                objectContacts.push(tenancy.correspondenceContact.contactId)
              }
              objectContacts = [...new Set(objectContacts)]
              contactsWithObject.push({
                contacts: objectContacts,
                objectId: objectId
              })
              contactIds = contactIds.concat(objectContacts)
            } else if (element.objectID?.startsWith('tenancy_')) {
              // case of serial email from Tenancies algolia
              onlyObjectWithContact.push(objectId)
              element.tenants.forEach(element => {
                objectContacts.push(element.contactId)
              })
              if (element.guarantors) {
                element.guarantors.forEach(element => {
                  objectContacts.push(element.contactId)
                })
              }
              if (element.billingContact) {
                objectContacts.push(element.billingContact.contactId)
              }
              if (element.correspondenceContact) {
                objectContacts.push(element.correspondenceContact.contactId)
              }
              objectContacts = [...new Set(objectContacts)]
              contactsWithObject.push({
                contacts: objectContacts,
                objectId: objectId
              })
              contactIds = contactIds.concat(objectContacts)
            }
          } else {
            let currentCondominium
            if (tenancyOrCondominiumId.includes('condominium_')) {
              currentCondominium = this.getCondominiumFromState(tenancyOrCondominiumId)[0]
            } else {
              currentCondominium = this.getCurrentCondominium(objectId)
            }
            if (currentCondominium) {
              objectContacts = objectContacts.concat(currentCondominium.owner.contactIds).concat([currentCondominium.owner.billingContactId]).concat([currentCondominium.owner.correspondenceContactId]).concat(currentCondominium.owner.roommateContactIds)
              objectContacts = [...new Set(objectContacts)]
              contactsWithObject.push({
                contacts: objectContacts,
                objectId: objectId
              })
              contactIds = contactIds.concat(objectContacts)
              onlyObjectWithContact.push(currentCondominium.objectId || currentCondominium.id)
            } else if (element.condominium) {
              // case of serial email from Condominiums algolia
              const condominium = element.condominium

              condominium.owner.forEach(element => {
                objectContacts.push(element.contactId)
              })
              if (condominium.roommates) {
                condominium.roommates.forEach(element => {
                  objectContacts.push(element.contactId)
                })
              }
              if (condominium.billingContact) {
                objectContacts.push(condominium.billingContact.contactId)
              }
              if (condominium.correspondenceContact) {
                objectContacts.push(condominium.correspondenceContact.contactId)
              }
              objectContacts = [...new Set(objectContacts)]
              contactsWithObject.push({
                contacts: objectContacts,
                objectId: objectId
              })
              contactIds = contactIds.concat(objectContacts)
              onlyObjectWithContact.push(objectId)
            } else if (element.objectID?.startsWith('condominium_')) {
              // case of serial email from CondomOwners algolia
              element.condominiumOwners.forEach(element => {
                objectContacts.push(element.contactId)
              })
              if (element.roommates) {
                element.roommates.forEach(element => {
                  objectContacts.push(element.contactId)
                })
              }
              if (element.billingContact) {
                objectContacts.push(element.billingContact.contactId)
              }
              if (element.correspondenceContact) {
                objectContacts.push(element.correspondenceContact.contactId)
              }
              objectContacts = [...new Set(objectContacts)]
              contactsWithObject.push({
                contacts: objectContacts,
                objectId: objectId
              })
              contactIds = contactIds.concat(objectContacts)
              onlyObjectWithContact.push(objectId)
            }
          }
        })
        contactIds = [...new Set(contactIds)]
        ContactApi.contactResolve(contactIds)
          .then(response => {
            const persons = response.persons
            const companies = response.companies
            const companiesWantEmail = companies.filter(x => x.includeInSerialEmails)
            const contacts = persons.concat(companiesWantEmail).filter(x => contactIds.includes(x.id))
            if (companies.length > 0) {
              companies.forEach(company => {
                const companyContacts = company.contactPersons || []
                companyContacts.forEach(contact => {
                  if (contact.includeInSerialEmails) {
                    contacts.push(contact)
                    const contactWithObject = contactsWithObject.find(cwo => cwo.contacts.includes(company.id))
                    if (contactWithObject) {
                      contactWithObject.contacts.push(contact.id)
                    }
                  }
                })
              })
            }
            const contactsWithEmail = contacts.filter(x => x && x.email !== '')
            const contactsWithoutEmail = contacts.filter(x => x && x.email === '')
            const uniqueContacts = contacts.filter(contact => !this.contacts.map(c => c.id).includes(contact.id))
            this.setContacts([...this.contacts, ...uniqueContacts])
            this.setSendMailContacts(contactsWithEmail)
            this.setSendMailContactsWithoutEmail(contactsWithoutEmail)
            this.setSendMailContactsWithObject(contactsWithObject)
            this.setRenderWithObjectId(onlyObjectWithContact.length === 1 ? onlyObjectWithContact[0] : '')
            this.setSendMailPreselectedContact(contacts)
            this.setSendMailEmailToReply(null)
            this.setSendMailReferenceIds([])
            this.setBccReferenceIds([])
            this.setSendMailSecondaryTitle(true)
            this.setUseBcc(true)
            this.setSendMailSecondaryTitle(true)
            this.$nextTick(function () {
              this.callSendMailTypeModal()
            })
          })
          .catch(e => {
            console.log(e)
            Vue.toasted.show(this.$t('message.loadingErrors.contacts'), { type: 'error' })
            this.$router.back()
          })
      }
    },
    callSendMailTypeModal() {
      this.$bvModal.show('modal-send-mail-type')
    },
    getTenancyFromState(tenancyId) {
      return this.tenancies.filter(ten => {
        return ten.id === tenancyId
      })
    },
    getCondominiumFromState(tenancyId) {
      return this.condominiumOwnership.filter(ten => {
        return ten.id === tenancyId
      })
    },
    getMailConnectedWithContacts(mail: V2Mail): { from: { contactId: string | null, name: string, email: string }, to: { contactId: string | null, name: string, email: string }[], cc: { contactId: string | null, name: string, email: string }[], bcc: { contactId: string | null, name: string, email: string }[] } {
      const recipients = {
        from: {},
        to: [],
        cc: [],
        bcc: []
      }

      const uniqueContactIds = [...new Set(mail.referenceIds?.filter(e => e.startsWith('contact_')) || [])]
      const manuallyAssignedContact = this.getManuallyAssignedContactId(mail, uniqueContactIds)

      if (manuallyAssignedContact) {
        if (mail.inbound) {
          // Link FROM to the manually assigned contact
          recipients.from = {
            contactId: manuallyAssignedContact,
            name: mail.from?.name,
            email: mail.from?.email
          }
          recipients.to = mail.to
        } else {
          // Link first TO entry to the manually assigned contact
          recipients.from = mail.from
          for (let i = 0; i < mail.to.length; i++) {
            if (i === 0) {
              recipients.to.push({
                contactId: manuallyAssignedContact,
                name: mail.to[i].name,
                email: mail.to[i].email
              })
            } else {
              recipients.to.push(mail.to[i])
            }
          }
        }
        // All others are not linked at all on manually assigned contact
        recipients.cc = mail.cc
        recipients.bcc = mail.bcc
      } else {
        // Link all participants with contacts via email address
        recipients.from = this.getContactByMailAddress(mail.from, uniqueContactIds)
        recipients.to = mail.to.map(to => this.getContactByMailAddress(to, uniqueContactIds))
        recipients.cc = mail.cc.map(cc => this.getContactByMailAddress(cc, uniqueContactIds))
        recipients.bcc = mail.bcc.map(bcc => this.getContactByMailAddress(bcc, uniqueContactIds))
      }

      return recipients
    },
    getContactByMailAddress(participant: Mailv2Participant, uniqueContactIds: string[]): { contactId: string | null, name: string, email: string } {
      const emailAddress = participant?.email.toLowerCase().trim()
      for (const contactId of uniqueContactIds) {
        const contact = this.contacts.find(
          searchContact => searchContact.id === contactId ||
          searchContact.email?.toLowerCase()?.trim() === emailAddress ||
          searchContact.contactPersons?.some(cp => cp.email?.toLowerCase()?.trim() === emailAddress)
        )
        if (contact && (contact.email?.toLowerCase()?.trim() === emailAddress || contact.workEmail?.email?.toLowerCase()?.trim() === emailAddress)) {
          return {
            contactId: contact.id,
            name: participant.name,
            email: participant.email
          }
        }
        if (contact?.contactPersons?.length) {
          for (const contactPerson of contact.contactPersons) {
            if (contactPerson.email?.toLowerCase()?.trim() === emailAddress || contactPerson.workEmail?.email?.toLowerCase()?.trim() === emailAddress) {
              return {
                contactId: contact.id,
                name: participant.name,
                email: participant.email
              }
            }
          }
        }
      }

      // If above matching failed, then instead returning data from contact, return name from mail object
      return {
        contactId: null,
        name: participant.name,
        email: participant.email
      }
    },
    getManuallyAssignedContactId(mail: V2Mail, uniqueContactIds: string[]): string | undefined {
      // If we have 1 contact reference, then we have to check if it is a manually assigned contact
      if (uniqueContactIds.length === 1) {
        const allEmailAddresses = mail.to.map(e => e.email).concat(mail.cc.map(e => e.email)).concat(mail.bcc.map(e => e.email)).concat(mail.from?.email)
        const contact = this.contacts.find(e => e.id === uniqueContactIds[0])
        if (contact) {
          // If the contact doesn't match any of the mail addresses, then it is manually assigned
          if (!allEmailAddresses.includes(contact.email) && !allEmailAddresses.includes(contact.workEmail?.email) && !allEmailAddresses.includes(contact.contactPersons?.map(e => e.email))) {
            return contact.id
          }
        }
      }
      return undefined
    },
    convertToPlainWithBreakingLines(html) {
      let htmlCleaned = html
      // remove code brakes and tabs
      htmlCleaned = htmlCleaned.replace(/\n/g, '')
      htmlCleaned = htmlCleaned.replace(/\t/g, '')

      // keep html brakes and tabs
      htmlCleaned = htmlCleaned.replace(/<\/td>/g, '\t')
      htmlCleaned = htmlCleaned.replace(/<\/table>/g, '\n')
      htmlCleaned = htmlCleaned.replace(/<\/tr>/g, '\n')
      htmlCleaned = htmlCleaned.replace(/<\/p>/g, '\n\n')
      htmlCleaned = htmlCleaned.replace(/<\/div>/g, '\n')
      htmlCleaned = htmlCleaned.replace(/<\/h>/g, '\n')
      htmlCleaned = htmlCleaned.replace(/<br>/g, '\n')
      htmlCleaned = htmlCleaned.replace(/<br( )*\/>/g, '\n')
      const tempDivElement = document.createElement('div')
      tempDivElement.innerHTML = htmlCleaned
      return tempDivElement.textContent || tempDivElement.innerText || ''
    },
    routeToDetail(ticket) {
      let routeName

      if (this.isOwnerModule) {
        routeName = TICKET_DETAIL_ROUTES.OWNER
      } else if (this.isAdminModule) {
        routeName = TICKET_DETAIL_ROUTES.ADMIN
      } else if (this.isAssetModule) {
        routeName = TICKET_DETAIL_ROUTES.ASSET
      } else if (this.isAccountingModule) {
        routeName = TICKET_DETAIL_ROUTES.ACCOUNTING
      } else {
        routeName = TICKET_DETAIL_ROUTES.MARKETING
      }

      this.$router.push({
        name: routeName,
        params: { id: ticket.key }
      }, () => {})
    },
    openMiddleDetail(ticket) {
      let routeName

      if (this.isOwnerModule) {
        routeName = TICKET_DETAIL_ROUTES.OWNER
      } else if (this.isAdminModule) {
        routeName = TICKET_DETAIL_ROUTES.ADMIN
      } else if (this.isAssetModule) {
        routeName = TICKET_DETAIL_ROUTES.ASSET
      } else if (this.isAccountingModule) {
        routeName = TICKET_DETAIL_ROUTES.ACCOUNTING
      } else {
        routeName = TICKET_DETAIL_ROUTES.MARKETING
      }

      const routeData = this.$router.resolve({
        name: routeName,
        params: { id: ticket.key }
      }, () => {})

      window.open(routeData.href, '_blank')
    }
  }
}

export { mail }
