<template>
  <coozzy-card
    :title="$t('message.settings.evorest.title')"
    class="mb-0">
    <p>
      {{ $t('message.settings.evorest.subtitle') }}
    </p>
    <div
      v-if="loading"
      style="height: 30px">
      <coozzy-spinner />
    </div>
    <div
      v-else
      class="row m-0">
      <coozzy-form-select
        id="tenancyStatus"
        v-model="allowInvestment"
        class="col-12 col-md-6">
        <option
          :value="'UNDEFINED_VALUE'">
          -
        </option>
        <option :value="'APPLIES'">
          {{ $t('message.settings.evorest.options.allowInvestment') }}
        </option>
        <option :value="'DOES_NOT_APPLY'">
          {{ $t('message.settings.evorest.options.notAllowInvestment') }}
        </option>
      </coozzy-form-select>
      <div
        class="col-12 mt-3">
        <coozzy-button
          class="float-right mt-3"
          design="green"
          @click="saveEvorestSetting">
          {{ $t('message.generic.form.save') }}
        </coozzy-button>
      </div>
    </div>
  </coozzy-card>
</template>
<script lang="ts">
import CoozzyCard from '@/framework/components/card/CoozzyCard.vue'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner.vue'
import CoozzyButton from '@/framework/components/button/CoozzyButton.vue'
import { user } from '@/mixins/user'
import CoozzyFormSelect from '@/framework/components/form/select/CoozzyFormSelect.vue'
import EvorestApi from '@/misc/apis/EvorestApi'
import Vue from 'vue'

export default Vue.extend({
  name: 'EvorestSettings',
  components: {
    CoozzyFormSelect,
    CoozzyButton,
    CoozzySpinner,
    CoozzyCard
  },
  mixins: [user],
  data() {
    return {
      loading: false,
      allowInvestment: 'UNDEFINED_VALUE'
    }
  },
  mounted: function () {
    this.loading = true
    this.getEvorestSetting()
  },
  methods: {
    getEvorestSetting() {
      EvorestApi.getOwnerConfig(this.accountId).then((response) => {
        this.allowInvestment = response.allowInvestment
        this.loading = false
      }).catch((error) => {
        console.log(error)
        this.loading = false
      })
    },
    saveEvorestSetting() {
      this.loading = true
      EvorestApi.setOwnerConfig(this.accountId, this.allowInvestment).then(() => {
        this.loading = false
      }).catch((error) => {
        this.loading = false
        console.log(error)
      })
    }
  }
})
</script>
