<template>
  <section>
    <div>
      <h1
        v-if="!loading">
        {{ $t('message.massRental.title') }}
      </h1>
    </div>
    <div
      class="row">
      <div
        v-if="loading"
        class="col-12 text-center">
        <coozzy-spinner />
      </div>
      <div
        v-else
        class="col-12">
        <form-wizard
          id="wizard"
          ref="wizard"
          color="rgb(0, 68, 95)"
          step-size="xs">
          <span slot="title" />
          <template>
            <tab-content
              :title="$t('message.massRental.selectObjects')">
              <create-mass-rental-objects-table
                :building-ids="buildingIds"
                :buildings="listBuildings"
                :contacts="listContacts"
                :network-partners="listNetworkPartners"
                :objects="listObjects"
                :owner-account="owner"
                :shares="listShares"
                :source="source"
                :tenancies="listTenancies"
                @desired-start-date-changed="loadObjects"
                @selected-objects="selectedObjects" />
            </tab-content>
            <tab-content
              :title="$t('message.addTenancy.tenants')">
              <add-tenancy-tenant-tab
                :billing-contact="billingContact"
                :correspondence-contact="correspondenceContact"
                :guarantor-contacts="guarantorContacts"
                :owner-id="owner ? owner.id : 0"
                :tenant-to-hide="!billingContact ? tenantContacts : [billingContact].concat(tenantContacts)"
                :tenants="tenantContacts"
                @tenant-changed="tenantChanged"
                @tenant-removed="tenantRemoved"
                @contact-changed="additionalContactChanged"
                @contact-removed="removeAdditionalContact" />
            </tab-content>
            <template v-if="objectsByCategory">
              <template
                v-for="(items, category, index) in objectsByCategory">
                <tab-content
                  v-if="items.length > 0"
                  :key="category"
                  :title="$t('message.massRental.editTenanciesByCat', { category: $t('message.onBoarding.buildings.objects.category.' + category) })">
                  <template
                    v-for="(item, indexObject) in items">
                    <add-tenancy-tenancy-tab
                      :id="'tenancy-tab-' + index + '-' + category"
                      :key="'tenancy_tab_' + item.id"
                      :ref="'tenancy-tab-' + index"
                      :billing-contact="billingContact"
                      :correspondence-contact="correspondenceContact"
                      :from-mass-rental-process="true"
                      :guarantor-contacts="guarantorContacts"
                      :initial-tenancy="initialTenancies.find(x => x.objectId === item.id)"
                      :last-tenancies="getLastTenancies(item.id)"
                      :list-tenancies="newTenancies"
                      :object="item"
                      :tenancy-id="tempId"
                      :tenant-contacts="tenantContacts"
                      @updated-tenancy="tenancyUpdated" />

                    <div
                      v-if="items.length > 1 && indexObject === 0"
                      :key="'button_' + item.id"
                      class="row">
                      <div class="col-12 mb-3 text-align-end">
                        <coozzy-button
                          class="mb-0 border float-right"
                          design="prop-green"
                          size="small"
                          @click="copyData(items[0], category)">
                          {{ $t('message.enterTermination.takeData') }}
                        </coozzy-button>
                      </div>
                    </div>
                  </template>
                </tab-content>
              </template>
            </template>
            <tab-content
              :title="$t('message.addTenancy.createContract')">
              <add-tenancy-contract-tab
                :owner-id="owner ? owner.id : 0"
                :is-portal-link-enabled="isPortalLinkEnabled"
                @attach-tenancy="addDocumentToTenancy"
                @show-in-portal="showInPortalMethod"
                @selected-contract="contractSelectedAction" />
            </tab-content>
          </template>
          <template
            #footer="props">
            <template>
              <div class="wizard-footer-left">
                <wizard-button
                  :disabled="disableButtons"
                  :style="props.fillButtonStyle"
                  class="mr-2"
                  @click.native="$router.back()">
                  {{ $t('message.generic.cancel') }}
                </wizard-button>
                <wizard-button
                  v-if="props.activeTabIndex > 0"
                  :disabled="disableButtons"
                  :style="props.fillButtonStyle"
                  @click.native="backClicked(props)">
                  {{ $t('message.generic.back') }}
                </wizard-button>
              </div>
              <div class="wizard-footer-right">
                <template
                  v-if="!props.isLastStep">
                  <wizard-button
                    v-if="props.activeTabIndex <= 1"
                    :disabled="!objectsByCategory || disableButtons || (props.activeTabIndex === 1 && !billingContact)"
                    :style="props.fillButtonStyle"
                    class="wizard-footer-right"
                    @click.native="nextStep(props)">
                    <template>
                      {{ $t('message.generic.next') }}
                    </template>
                  </wizard-button>
                  <wizard-button
                    v-else-if="props.activeTabIndex !== countTabs - 2"
                    :disabled="!objectsByCategory || disableButtons"
                    :style="props.fillButtonStyle"
                    class="wizard-footer-right"
                    @click.native="nextStepWithValidation(props)">
                    <template>
                      {{ $t('message.generic.next') }}
                    </template>
                  </wizard-button>
                  <wizard-button
                    v-else
                    :disabled="!objectsByCategory || disableButtons"
                    :style="props.fillButtonStyle"
                    class="wizard-footer-right"
                    @click.native="saveTenancies(props)">
                    <template>
                      {{ $t('message.enterTermination.saveAndNext') }}
                    </template>
                  </wizard-button>
                </template>
                <wizard-button
                  v-else
                  :disabled="disableButtons"
                  :style="props.fillButtonStyle"
                  class="wizard-footer-right"
                  @click.native="renderContract">
                  <template v-if="contractSelected.length > 0">
                    {{ $t('message.addTenancy.createContractAndSave') }}
                  </template>
                  <template v-else>
                    {{ $t('message.addTenancy.createWithoutContract') }}
                  </template>
                </wizard-button>
              </div>
            </template>
          </template>
        </form-wizard>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import Vue from 'vue'
import ObjectApi from '@/misc/apis/ObjectApi'
import ContactApi from '@/misc/apis/ContactApi'
import CreateMassRentalObjectsTable from '@/massRental/components/CreateMassRentalObjectsTable.vue'
import ShareApi from '@/misc/apis/ShareApi'
import NetworkPartnerAPI from '@/misc/apis/NetworkPartnerApi'
import UserApi from '@/misc/apis/UserApi'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner.vue'
import AddTenancyTenantTab from '@/addTenancy/AddTenancyTenantTab.vue'
import AddTenancyTenancyTab from '@/addTenancy/AddTenancyTenancyTab.vue'
import CoozzyButton from '@/framework/components/button/CoozzyButton.vue'
import { dateUtils } from '@/mixins/dateUtils'
import AddTenancyContractTab from '@/addTenancy/AddTenancyContractTab.vue'
import { helper } from '@/types/helper'
// import types from api.ts
import {
  Accountv2Account, BuildingBuilding,
  Contactcontactv2Company, Contactv2ResolveResponse, Contractv2ContractTemplate, GooglerpcStatus,
  ObjectObject, ObjectpropertyProperty, ObjecttenancyCreateResponse,
  ObjecttenancyTenancy,
  ShareShare, TypeDate,
  V2Person
} from '@/types/api'
import { mapMutations } from 'vuex'
import { onboarding } from '@/mixins/onboarding'
import MediaApi from '@/misc/apis/MediaApi'
// @ts-ignore
import { saveAs } from 'file-saver'
import { routeChecks } from '@/mixins/routeChecks'
import { user } from '@/mixins/user'

interface WizardParams {
  activeTabIndex: number;
  prevTab: () => void;
  nextTab: () => void;
  isLastStep: boolean;
  fillButtonStyle: string;
}

type Contact = V2Person | Contactcontactv2Company

type ObjectsByCategory = {
  [key: string]: ObjectObject[];
}

export default Vue.extend({
  name: 'CreateMassRentalView',
  components: {
    AddTenancyContractTab,
    CoozzyButton,
    AddTenancyTenancyTab,
    AddTenancyTenantTab,
    CoozzySpinner,
    CreateMassRentalObjectsTable
  },
  metaInfo() {
    return {
      title: this.$t('message.massRental.title') + (this.sourceName !== '' ? ': ' + this.sourceName : '')
    }
  },
  mixins: [dateUtils, onboarding, routeChecks, user],
  props: {
    source: {
      type: String,
      required: true
    },
    buildingIds: {
      type: String,
      required: true
    },
    tenantIds: {
      type: String,
      default: ''
    },
    requestId: {
      type: String,
      default: ''
    },
    requestObjectId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      listObjects: [] as ObjectObject[],
      loading: false as boolean,
      listTenancies: [] as ObjecttenancyTenancy[],
      listContacts: [] as Contact[],
      listShares: [] as ShareShare[],
      owner: null as Accountv2Account,
      listNetworkPartners: [] as Accountv2Account[],
      objectsByCategory: null as ObjectsByCategory,
      tenantContacts: [] as Contact[],
      billingContact: null as Contact,
      correspondenceContact: null as Contact,
      guarantorContacts: [] as Contact[],
      tenantAdded: false as boolean,
      initialTenancies: null as ObjecttenancyTenancy[],
      newTenancies: [] as ObjecttenancyTenancy[],
      createdTenancies: [] as ObjecttenancyTenancy[],
      listProperties: [] as ObjectpropertyProperty[],
      listBuildings: [] as BuildingBuilding[],
      countTabs: 0 as number,
      emptyTenancy: helper.emptyTenancy,
      disableButtons: false as boolean,
      addDocumentToContact: false as boolean,
      contractSelected: [] as Contractv2ContractTemplate[],
      contracts: [] as Contractv2ContractTemplate[]
    }
  },
  computed: {
    tempId() {
      return Math.random().toString(36).substring(2, 9)
    },
    sourceName() {
      if (this.listProperties === 1) {
        return this.listProperties[0].name
      } else if (this.listBuildings.length === 1) {
        return this.listBuildings[0].name
      }
      return ''
    }
  },
  mounted() {
    this.loadProperties()
    this.loadBuildings()
    this.loadNetworkPartners()
    if (this.tenantIds !== '') {
      const splitIds = this.tenantIds.split(',')
      this.loadContacts(splitIds)
    }
  },
  methods: {
    ...mapMutations('onboarding', ['setObjectOwnerContacts']),
    tenancyUpdated(tenancy: ObjecttenancyTenancy) {
      const index = this.initialTenancies.findIndex((t: ObjecttenancyTenancy) => t.objectId === tenancy.objectId)
      if (index !== -1) {
        this.initialTenancies[index] = tenancy
      } else {
        this.initialTenancies.push(tenancy)
      }
    },
    copyData(object?: ObjectObject, category?: string | number) {
      if (object && category) {
        const tenancy = this.initialTenancies.find((x: ObjecttenancyTenancy) => x.objectId === object.id)
        this.objectsByCategory[category].forEach((element: ObjectObject) => {
          const newTenancy: ObjecttenancyTenancy = JSON.parse(JSON.stringify(tenancy))
          newTenancy.objectId = element.id
          newTenancy.ownerId = element.ownerId
          if (!this.newTenancies.find((x: ObjecttenancyTenancy) => x.objectId === element.id)) {
            this.newTenancies.push(newTenancy)
          } else {
            let list = JSON.parse(JSON.stringify(this.newTenancies))
            list = list.filter((x: ObjecttenancyTenancy) => x.objectId !== element.id)
            list.push(newTenancy)
            this.newTenancies = list
          }
        })
      } else {
        for (let category in this.objectsByCategory) {
          const tenancy = this.initialTenancies.find((x: ObjecttenancyTenancy) => x.objectId === this.objectsByCategory[category][0].id)
          this.objectsByCategory[category].forEach((element: ObjectObject) => {
            const newTenancy: ObjecttenancyTenancy = JSON.parse(JSON.stringify(tenancy))
            newTenancy.objectId = element.id
            newTenancy.ownerId = element.ownerId
            if (!this.newTenancies.find((x: ObjecttenancyTenancy) => x.objectId === element.id)) {
              this.newTenancies.push(newTenancy)
            } else {
              const index = this.newTenancies.findIndex((x: ObjecttenancyTenancy) => x.objectId === element.id)
              this.newTenancies[index] = newTenancy
            }
          })
        }
      }
    },
    async loadObjects(startDate: string) {
      if (startDate !== '') {
        const splitIds = this.buildingIds.split(',')
        const date = new Date(startDate)
        const dateObject = {
          year: date.getFullYear(),
          month: date.getMonth() + 1,
          day: date.getDate()
        }
        const response = await ObjectApi.listFreeByDate(splitIds, dateObject)
        this.listObjects = response.objects
        const objectIds = this.listObjects.map((o: ObjectObject) => o.id)
        const responseShare = await ShareApi.listByResource(objectIds)
        this.listShares = responseShare.shares
        if (objectIds.length > 0) {
          await this.loadTenantContacts(objectIds, dateObject)
        }
        await this.loadOwnerAccount()
      }
    },
    async loadBuildings() {
      try {
        const response = await ObjectApi.getBuildingsListByIds(this.buildingIds.split(','))
        this.listBuildings = response.buildings
      } catch (e) {
        console.log(e)
      }
    },
    async loadProperties() {
      try {
        const response = await ObjectApi.getPropertiesByBuildingIds(this.buildingIds.split(','))
        this.listProperties = response.properties
      } catch (e) {
        console.log(e)
      }
    },
    async loadNetworkPartners() {
      const responseNetworkPartners = await NetworkPartnerAPI.listNetworkPartner(
        0,
        0,
        [],
        ['LANDLORD', 'MARKETING', 'LANDLORD_MARKETING', 'ASSET_MANAGEMENT', 'ASSET_LANDLORD', 'ASSET_ACCOUNTING', 'ACCOUNTING', 'LANDLORD_ACCOUNTING', 'MARKETING_ACCOUNTING', 'LANDLORD_MARKETING_ACCOUNTING', 'ASSET_LANDLORD_MARKETING_ACCOUNTING', 'ASSET_MARKETING', 'ASSET_LANDLORD_MARKETING', 'ASSET_LANDLORD_ACCOUNTING', 'ASSET_MARKETING_ACCOUNTING'],
        '',
        '',
        ''
      )
      this.listNetworkPartners = responseNetworkPartners.accounts
    },
    async loadOwnerAccount() {
      if (this.listObjects.length > 0) {
        const owner = await UserApi.getAccount(this.listObjects[0].ownerId)
        this.owner = owner.account
      }
    },
    async loadTenantContacts(objectIds: string[], startDate: TypeDate) {
      let tenantContactIds = []
      try {
        const tenancyResponse = await ObjectApi.getTenancies(objectIds)

        const list: ObjecttenancyTenancy[] = this.listTenancies.concat(tenancyResponse.tenancies)

        this.listTenancies = [...new Set(list.map((a) => a.id))].map(id => {
          return list.find((a) => a.id === id)
        })
        tenantContactIds = this.listTenancies.map((t: ObjecttenancyTenancy) => t?.tenant?.contactIds || []).flat()
        this.initialTenancies = []
        const today = new Date()
        const objDate = {
          year: today.getFullYear(),
          month: today.getMonth() + 1,
          day: today.getDate()
        }
        objectIds.forEach((element: string) => {
          if (!this.initialTenancies.find((x: ObjecttenancyTenancy) => x.objectId === element) && this.getLastTenancies(element).length > 0) {
            const lastTenancy: ObjecttenancyTenancy = this.getLastTenancies(element)[0]
            const createdTenancy = this.setDefaultValuesFromPreviousTenancy(element, lastTenancy)
            // createdTenancy.id = Math.random().toString(36).substring(2, 9)
            createdTenancy.internalId = Math.random().toString(36).substring(2, 9)
            createdTenancy.startingAt = startDate
            createdTenancy.objectId = element
            createdTenancy.rent.adjustments[0].byDate = startDate
            createdTenancy.ownerId = lastTenancy.ownerId
            this.initialTenancies.push(createdTenancy)
          } else {
            const createdTenancy = JSON.parse(JSON.stringify(this.emptyTenancy))
            // createdTenancy.id = Math.random().toString(36).substring(2, 9)
            createdTenancy.internalId = Math.random().toString(36).substring(2, 9)
            createdTenancy.startingAt = startDate
            createdTenancy.objectId = element
            createdTenancy.rent.adjustments[0].byDate = startDate
            createdTenancy.contract.creationDate = objDate
            createdTenancy.ownerId = this.listObjects.find((o: ObjectObject) => o.id === element).ownerId
            this.initialTenancies.push(createdTenancy)
          }
        })
      } catch (e) {
        console.log(e)
        Vue.toasted.show(this.$t('message.loadingErrors.tenancy'), { type: 'error' })
      }

      if (this.tenantIds !== '') {
        const splitIds = this.tenantIds.split(',')
        this.initialTenancies.forEach((t: ObjecttenancyTenancy) => {
          t.tenant.contactIds = splitIds
          t.tenant.billingContactId = splitIds[0]
        })
      }

      if (tenantContactIds && tenantContactIds.length > 0) {
        this.loadContacts(tenantContactIds)
      }
    },
    loadContacts(ids: string[]) {
      if (ids.length > 0) {
        ContactApi.contactResolve(ids).then((response: Contactv2ResolveResponse) => {
          const list: Contact[] = this.listContacts.concat(response.persons).concat(response.companies)
          this.listContacts = [...new Set(list.map((a) => a.id))].map(id => {
            return list.find((a) => a.id === id)
          })
          if (this.tenantIds !== '') {
            const splitIds = this.tenantIds.split(',')
            this.tenantContacts = this.listContacts.filter((c: Contact) => splitIds.includes(c.id))
            this.billingContact = this.tenantContacts.find((c: Contact) => c.id === this.tenantContacts[0].id)
          }
        }).catch((e: GooglerpcStatus) => {
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.contacts'), { type: 'error' })
        })
      }
    },
    selectedObjects(objects: ObjectObject[]) {
      // Group objects by category
      if (objects.length > 0) {
        const sortedObjects = objects.sort((a: ObjectObject, b: ObjectObject) => {
          return this.customSort(a.category) - this.customSort(b.category)
        })
        this.$nextTick(() => {
          this.objectsByCategory = sortedObjects.reduce((result, obj) => {
            const category = obj.category.toLowerCase()
            // Check if the category already exists in the result object
            if (!result[category]) {
              // If not, create an empty array for that category
              result[category] = []
            }
            // Push the object to the respective category array
            result[category].push(obj)
            return result
          }, {})
          objects.forEach((element: ObjectObject) => {
            const tenancy = this.initialTenancies.find((x: ObjecttenancyTenancy) => x.objectId === element.id)
            if (!this.newTenancies.find((x: ObjecttenancyTenancy) => x.objectId === element.id)) {
              this.newTenancies.push(tenancy)
            }
          })
        })
      } else {
        this.objectsByCategory = null
      }
    },
    customSort(item: string) {
      if (item.includes('COMMERCIAL')) {
        return 1
      } else if (item.includes('APARTMENT')) {
        return 2
      } else if (item.includes('SECONDARY_ROOM')) {
        return 3
      } else if (item.includes('PARKING_SPACE')) {
        return 4
      } else {
        return [5, item] // Alphabetical sorting for other types
      }
    },
    getLastTenancies(objectId: string) {
      const t = this
      return [...this.listTenancies.filter((x: ObjecttenancyTenancy) => x.objectId === objectId).sort(function (a: ObjecttenancyTenancy, b: ObjecttenancyTenancy) {
        return new Date(t.$options.filters.objectToDateInput(b.startingAt)).getTime() - new Date(t.$options.filters.objectToDateInput(a.startingAt)).getTime()
      }).slice(0, 2)]
    },
    tenantChanged(contact: Contact) {
      if (!this.objectOwnerContacts.find(x => x.id === contact.id || x.objectID === contact.id)) {
        this.setObjectOwnerContacts(this.objectOwnerContacts.concat(contact))
      }
      if (!this.listContacts.find(x => x.id === contact.id)) {
        this.listContacts.push(contact)
      }
      let updatedContact = false
      this.tenantContacts.forEach((value: Contact, index: number) => {
        if (value.id === contact.id) {
          updatedContact = true
          Vue.set(this.tenantContacts, index, contact)
        }
      })

      if (!updatedContact) {
        this.tenantContacts.push(contact)
      }
      if (this.initialTenancies.length > 0) {
        this.initialTenancies.forEach((t: ObjecttenancyTenancy) => {
          const ids: string[] = this.tenantContacts.map((value: Contact) => value.id)
          t.tenant.contactIds = [...new Set(ids)]
        })
      }
    },
    tenantRemoved(id: string) {
      this.tenantContacts = this.tenantContacts.filter((c: Contact) => c.id !== id)
      if (this.initialTenancies.length > 0) {
        this.initialTenancies.forEach((t: ObjecttenancyTenancy) => {
          const ids: string[] = this.tenantContacts.map((value: Contact) => value.id)
          t.tenant.contactIds = [...new Set(ids)]
        })
      }
    },
    additionalContactChanged(data: { type: string, contact: Contact }) {
      const type = data.type
      if (!this.objectOwnerContacts.find(x => x.id === data.contact.id || x.objectID === data.contact.id)) {
        this.setObjectOwnerContacts(this.objectOwnerContacts.concat(data.contact))
      }
      if (!this.listContacts.find(x => x.id === data.contact.id)) {
        this.listContacts.push(data.contact)
      }
      if (type === 'BILLING_CONTACT') {
        Vue.set(this, 'billingContact', data.contact)
        // Add tenant (if not existing) the same as billing contact
        let exist = false
        this.tenantContacts.forEach((value: Contact) => {
          if (value.id === data.contact.id) {
            exist = true
            this.billingContact = data.contact
          }
        })
        if (!exist && this.tenantAdded === false) {
          this.tenantAdded = true
          this.tenantContacts.push(data.contact)
        }
        if (this.initialTenancies.length > 0) {
          this.initialTenancies.forEach((t: ObjecttenancyTenancy) => {
            t.tenant.billingContactId = data.contact.id
          })
        }
      }

      if (type === 'CORRESPONDENCE_CONTACT') {
        Vue.set(this, 'correspondenceContact', data.contact)
        this.correspondenceContact = data.contact
        if (this.initialTenancies.length > 0) {
          this.initialTenancies.forEach((t: ObjecttenancyTenancy) => {
            t.tenant.correspondenceContactId = data.contact.id
          })
        }
      }

      if (type === 'GUARANTOR_CONTACT') {
        let updatedContact = false
        this.guarantorContacts.forEach((value: Contact, index: number) => {
          if (value.id === data.contact.id) {
            updatedContact = true
            Vue.set(this.guarantorContacts, index, data.contact)
          }
        })

        if (!updatedContact) {
          this.guarantorContacts.push(data.contact)
        }

        if (this.initialTenancies.length > 0) {
          this.initialTenancies.forEach((t: ObjecttenancyTenancy) => {
            const ids: string[] = this.guarantorContacts.map((value: Contact) => value.id)
            t.tenant.guarantorContactIds = [...new Set(ids)]
          })
        }
      }
    },
    removeAdditionalContact(data: { type: string, contact: Contact }) {
      const id = data.contact.id
      const type = data.type

      if (type === 'BILLING_CONTACT') {
        if (this.billingContact.id === id) {
          Vue.set(this, 'billingContact', null)
        }
      }

      if (type === 'CORRESPONDENCE_CONTACT') {
        if (this.correspondenceContact.id === id) {
          Vue.set(this, 'correspondenceContact', null)
        }
      }

      if (type === 'GUARANTOR_CONTACT') {
        const index = this.guarantorContacts.findIndex((c: Contact) => c.id === id)
        Vue.delete(this.guarantorContacts, index)
      }
    },
    nextStep(props: WizardParams) {
      props.nextTab()
      this.scrollToTop()
      this.countTabs = this.$refs.wizard?.tabs?.length || 0
    },
    nextStepWithValidation(props: WizardParams) {
      if (props.activeTabIndex > 1) {
        const index = props.activeTabIndex - 2
        if (this.$refs['tenancy-tab-' + index].length > 0) {
          for (let i = 0; i < this.$refs['tenancy-tab-' + index].length; i++) {
            if (this.$refs['tenancy-tab-' + index][i].isInvalid()) {
              return
            }
          }
          this.$nextTick(() => {
            props.nextTab()
            this.scrollToTop()
          })
        }
      }
      this.countTabs = this.$refs.wizard?.tabs?.length || 0
    },
    backClicked(props: WizardParams) {
      props.prevTab()
      this.scrollToTop()
    },
    scrollToTop() {
      this.$nextTick(() => {
        const domRect = this.$el.querySelector('#wizard')
        domRect.scrollIntoView(true)
      })
    },
    setDefaultValuesFromPreviousTenancy(id: string, lastTenancy: ObjecttenancyTenancy) {
      const createdTenancy = JSON.parse(JSON.stringify(this.emptyTenancy))
      if (lastTenancy) {
        const current = this
        createdTenancy.contract.creationDate = this.toObject(new Date())
        if (lastTenancy.rent.adjustments.length > 0) {
          const previousAdjustment = lastTenancy.rent.adjustments.length > 1
            ? lastTenancy.rent.adjustments.sort(function (a, b) {
              return (b.byDate ? new Date(current.$options.filters.objectToDateInput(b.byDate)).getTime() : 0) - (a.byDate ? new Date(current.$options.filters.objectToDateInput(a.byDate)).getTime() : 0)
            })[0]
            : lastTenancy.rent.adjustments[0]
          if (previousAdjustment) {
            for (let i = 0; i < previousAdjustment.advancePaymentValues.length; i++) {
              createdTenancy.rent.adjustments[0].advancePaymentValues.push(previousAdjustment.advancePaymentValues[i])
            }
            for (let i = 0; i < previousAdjustment.flatRateValues.length; i++) {
              createdTenancy.rent.adjustments[0].flatRateValues.push(previousAdjustment.flatRateValues[i])
            }
            for (let i = 0; i < previousAdjustment.additionalValues.length; i++) {
              createdTenancy.rent.adjustments[0].additionalValues.push(previousAdjustment.additionalValues[i])
            }

            createdTenancy.rent.adjustments[0].coldRentPerMonth = previousAdjustment.coldRentPerMonth
            createdTenancy.rent.adjustments[0].coldRent = previousAdjustment.coldRent
            createdTenancy.rent.adjustments[0].coldRentM2PerYear = previousAdjustment.coldRentM2PerYear
            createdTenancy.rent.adjustments[0].calculationBasis = previousAdjustment.calculationBasis
            createdTenancy.rent.adjustments[0].additionalCostsBillingType = previousAdjustment.additionalCostsBillingType
            const lastestTenant = this.listTenancies.filter((x) => x.objectId === id && !x.internalId).sort(function (a, b) {
              return b.endingAt - a.endingAt
            })
            if (lastTenancy.vacancy === true && lastestTenant.length > 1 && lastestTenant[1].terminationType === 'TERMINATION_TYPE_OFF_TERM') {
              const lastAdjustement = lastestTenant[1].rent.adjustments.sort(function (a, b) {
                return b.byDate - a.byDate
              })[0]
              const lastTenant = lastestTenant[1]
              createdTenancy.rent.adjustments[0].coldRentPerMonth = lastAdjustement.coldRentPerMonth
              createdTenancy.rent.adjustments[0].coldRentM2PerYear = lastAdjustement.coldRentM2PerYear
              createdTenancy.rent.adjustments[0].coldRent = lastAdjustement.coldRent
              createdTenancy.rent.adjustments[0].calculationBasis = lastAdjustement.calculationBasis
              createdTenancy.rent.adjustments[0].additionalCostsBillingType = lastAdjustement.additionalCostsBillingType

              createdTenancy.rent.adjustments[0].retentionOfRents = lastAdjustement.retentionOfRents

              createdTenancy.rent.adjustments[0].referenceInterestRate.rate = lastAdjustement.referenceInterestRate.rate
              createdTenancy.rent.adjustments[0].referenceInterestRate.date = lastAdjustement.referenceInterestRate.date
              createdTenancy.rent.adjustments[0].referenceInterestRate.ratePossible = lastAdjustement.referenceInterestRate.ratePossible
              createdTenancy.rent.adjustments[0].countryIndex.date = lastAdjustement.countryIndex.date
              createdTenancy.rent.adjustments[0].countryIndex.baseDate = lastAdjustement.countryIndex.baseDate
              createdTenancy.rent.adjustments[0].costIncreaseType = lastAdjustement.costIncreaseType
              createdTenancy.rent.adjustments[0].costIncreaseDate = lastAdjustement.costIncreaseDate
              createdTenancy.rent.adjustments[0].costIncreaseValue = lastAdjustement.costIncreaseValue
              createdTenancy.rent.adjustments[0].dueDates = lastAdjustement.dueDates
              createdTenancy.rent.adjustments[0].countryIndex.inflation = lastAdjustement.countryIndex.inflation
              createdTenancy.terminationLandlord = lastTenant?.terminationLandlord
              createdTenancy.terminationTenant = lastTenant?.terminationTenant
            }
            if (lastTenancy.terminationType === 'TERMINATION_TYPE_OFF_TERM') {
              createdTenancy.rent.adjustments[0].coldRentPerMonth = previousAdjustment.coldRentPerMonth
              createdTenancy.rent.adjustments[0].coldRent = previousAdjustment.coldRent
              createdTenancy.rent.adjustments[0].coldRentM2PerYear = previousAdjustment.coldRentM2PerYear
              createdTenancy.rent.adjustments[0].calculationBasis = previousAdjustment.calculationBasis
              createdTenancy.rent.adjustments[0].additionalCostsBillingType = previousAdjustment.additionalCostsBillingType

              createdTenancy.rent.adjustments[0].retentionOfRents = previousAdjustment.retentionOfRents

              createdTenancy.rent.adjustments[0].referenceInterestRate.rate = previousAdjustment.referenceInterestRate.rate
              createdTenancy.rent.adjustments[0].referenceInterestRate.date = previousAdjustment.referenceInterestRate.date
              createdTenancy.rent.adjustments[0].referenceInterestRate.ratePossible = previousAdjustment.referenceInterestRate.ratePossible
              createdTenancy.rent.adjustments[0].countryIndex.date = previousAdjustment.countryIndex.date
              createdTenancy.rent.adjustments[0].countryIndex.baseDate = previousAdjustment.countryIndex.baseDate
              createdTenancy.rent.adjustments[0].costIncreaseType = previousAdjustment.costIncreaseType
              createdTenancy.rent.adjustments[0].costIncreaseDate = previousAdjustment.costIncreaseDate
              createdTenancy.rent.adjustments[0].costIncreaseValue = previousAdjustment.costIncreaseValue
              createdTenancy.rent.adjustments[0].dueDates = previousAdjustment.dueDates
              createdTenancy.rent.adjustments[0].countryIndex.inflation = previousAdjustment.countryIndex.inflation
              createdTenancy.terminationLandlord = lastTenancy?.terminationLandlord
              createdTenancy.terminationTenant = lastTenancy?.terminationTenant
            }
            if (lastTenancy.vacancy === true && lastestTenant.length > 1) {
              createdTenancy.terminationLandlord = lastestTenant[1]?.terminationLandlord
              createdTenancy.terminationLandlord.earliestDate = null
              createdTenancy.terminationTenant = lastestTenant[1]?.terminationTenant
              createdTenancy.terminationTenant.earliestDate = null
            } else {
              createdTenancy.terminationLandlord = lastTenancy?.terminationLandlord
              createdTenancy.terminationLandlord.earliestDate = null
              createdTenancy.terminationTenant = lastTenancy?.terminationTenant
              createdTenancy.terminationTenant.earliestDate = null
            }
          }
        }

        createdTenancy.rent.additionalCostsBillingType = lastTenancy.rent.additionalCostsBillingType
        if (lastTenancy.contract.rentType) {
          createdTenancy.contract.rentType = lastTenancy.contract.rentType
        }
        this.$nextTick(() => {
          if (lastTenancy.contract.typeOfUse) {
            createdTenancy.contract.typeOfUse = lastTenancy.contract.typeOfUse
            if (lastTenancy.contract.typeOfUse === 'TYPE_OF_USE_PARKING_EXTERNAL') {
              const property = this.listProperties.find((p: ObjectpropertyProperty) => p.buildingIds.includes(this.listBuildings.find((b: BuildingBuilding) => b.objectIds.includes(lastTenancy.objectId)).id)) || null
              if (property) {
                createdTenancy.taxable = !property.opted
              }
            }
          }
        })
        return createdTenancy
      }
    },
    saveTenancies(props: WizardParams) {
      const index = props.activeTabIndex - 2
      if (this.$refs['tenancy-tab-' + index].length > 0) {
        for (let i = 0; i < this.$refs['tenancy-tab-' + index].length; i++) {
          if (this.$refs['tenancy-tab-' + index][i].isInvalid()) {
            return
          }
        }
        this.$nextTick(() => {
          this.disableButtons = true
          const promises: any = []
          this.newTenancies.forEach((tenancy: ObjecttenancyTenancy) => {
            promises.push(ObjectApi.createTenancy(tenancy))
          })
          Promise.all(promises).then((response) => {
            response.forEach((element: ObjecttenancyCreateResponse) => {
              this.createdTenancies.push(element.tenancy)
            })
            this.disableButtons = false
            props.nextTab()
            this.scrollToTop()
          }).catch((error) => {
            this.disableButtons = false
            console.log(error)
          })
        })
      }
    },
    addDocumentToTenancy(value: boolean) {
      this.addDocumentToContact = value
    },
    showInPortalMethod(value: boolean) {
      this.showInPortal = value
    },
    contractSelectedAction(ids, contracts) {
      this.contractSelected = ids
      this.contracts = contracts
    },
    renderContract() {
      this.disableButtons = true
      const array = []
      const promises = []
      const listTemplatePdf = []
      this.createdTenancies.forEach((tenancy: ObjecttenancyTenancy) => {
        const tenancyId = tenancy.id
        for (let index = 0; index < this.contractSelected.length; index++) {
          const signatories = []
          const contract = this.contracts.find(x => x.id === this.contractSelected[index])
          if (contract && !contract.mediaId.includes('.pdf')) {
            if (contract && contract.firstSignature !== '') {
              signatories.push(contract.firstSignature)
            }
            if (contract && contract.secondSignature) {
              if (contract && contract.firstSignature === '') {
                signatories.push('')
              }
              signatories.push(contract.secondSignature)
            }
            array.push({
              contract: this.contractSelected[index],
              tenancyId: tenancyId
            })
            promises.push(ObjectApi.RenderTenancyTemplate(this.contractSelected[index], tenancyId, this.addDocumentToContact, this.showInPortal, this.title, this.description, '', '', signatories))
          } else if (contract) {
            listTemplatePdf.push(contract.mediaId)
          }
        }
      })
      if (listTemplatePdf.length > 0) {
        MediaApi.getListMedia(listTemplatePdf).then(response => {
          for (const media of response.media) {
            saveAs(media.url, media.filename)
          }
        })
      }
      Promise.all(promises)
        .then(response => {
          const renderId = []
          let downloadName = ''
          response.forEach((element, index) => {
            const contract = this.contracts.find(x => x.id === array[index].contract)
            const currentTenancy = this.createdTenancies.find(x => x.id === array[index].tenancyId || x.objectID === array[index].tenancyId)
            const object = this.listObjects.find(x => x.id === currentTenancy.objectId)
            if (currentTenancy.tenant.contactIds.length > 0 && this.listContacts.length > 0) {
              let nameOfTenant = ''
              currentTenancy.tenant.contactIds.forEach((element, index) => {
                if (index > 0) {
                  nameOfTenant = nameOfTenant + '_'
                }
                nameOfTenant = nameOfTenant + this.listContacts.find(x => x.id === element).name
              })
              downloadName = contract.title + '_' + nameOfTenant + '_' + object.numericId
            } else {
              downloadName = contract.title + '_' + object.numericId
            }
            const item = { renderId: element.renderId + '_' + downloadName + '-/-' + contract.enforcePdf, contract: contract }
            renderId.push(item)
          })
          localStorage.setItem('renderId', JSON.stringify(renderId))

          this.redirectOnFinish()
        })
        .catch((e) => {
          this.loading = false
          this.disableButtons = false
          console.log(e)
        })
    },
    redirectOnFinish() {
      setTimeout(() => {
        const debtorName: string = this.billingContact.name !== '' ? this.billingContact.name : this.billingContact.lastName + ' ' + this.billingContact.firstName
        if (this.isOwnerModule) {
          this.$router.push({
            name: 'OwnerTenanciesView',
            query: { query: debtorName }
          })
        } else if (this.isAssetModule) {
          this.$router.push({
            name: 'AssetTenanciesView',
            query: { query: debtorName }
          })
        } else if (this.isAdminModule) {
          this.$router.push({
            name: 'AdminTenanciesView',
            query: { query: debtorName }
          })
        } else if (this.isAccountingModule) {
          this.$router.push({
            name: 'AccountingTenanciesView',
            query: { query: debtorName }
          })
        } else if (this.isMarketingModule) {
          this.$router.push({
            name: 'MarketingObjectDetailsView',
            params: { id: this.requestObjectId },
            query: { view: '0', elementSelected: this.requestId }
          })
        }
      }, 2000)
    }
  }
})
</script>

<style lang="scss" scoped>
</style>
